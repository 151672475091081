/* eslint-disable max-lines */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { takeUntil, debounce } from 'rxjs/operators';

import { WorkspaceOutcomeGetMap } from '../../../interfaces/workspaces';
import { ApiService } from '../../../services/api.service';
import { SiteService } from '../site.service';
import { ToastService } from '../../../services/toast.service';
import { ZipService } from '../../../services/zip.service';
import { BaseComponent } from '../../../components/base/base.component';
import { UtilService } from '../../../services/util.service';
import { TableHeader } from '../../../interfaces/components';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { FlatpickrOptions } from 'ng2-flatpickr';
import {
  DEBOUNCE_TIME,
  flatPickrDateFormat,
  DocumentRequestTypes,
  SiteOutcomeDocumentFilterOptions,
  StudySiteWorkspaceOutcomeDocumentsSortOptions
} from 'src/app/common/collections';
import { timer } from 'rxjs';
import * as moment from 'moment';
import { GridOptionsSearchModel } from 'src/app/admin/shared/model/grid.model';
import { GridExportType } from 'src/app/common/reportingCollections';
import { StateService } from 'src/app/services/state.service';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
  selector: 'app-site-outcome-documents',
  templateUrl: './site-outcome-documents.component.html',
  styleUrls: ['./site-outcome-documents.component.scss']
})
export class SiteOutcomeDocumentsComponent extends BaseComponent implements OnInit {
  @Input() siteId: string;
  tableData: Array<WorkspaceOutcomeGetMap>;
  loaded: boolean;
  loading: boolean;
  id: string;
  searchTerm = new FormControl('');
  documents: Array<WorkspaceOutcomeGetMap>;
  selected: Array<WorkspaceOutcomeGetMap> = [];
  headers: Array<TableHeader>;
  documentsFetched = false;
  initialDocuments: Array<WorkspaceOutcomeGetMap> = [];
  documentRequestTypes = DocumentRequestTypes;
  readonly exportType: GridExportType = GridExportType.SiteDetailsOutcomeDocuments;

  currentSort: string;
  currentSortDescending = true;
  currentPageSize = 10;

  @ViewChild('startDatePicker') startDatePicker;
  @ViewChild('endDatePicker') endDatePicker;

  startDateFilter = new FormControl('');
  endDateFilter = new FormControl('');
  filterOptions: any[] = [];
  startDateUTC: any = null;
  endDateUTC: any = null;
  datePickerOptions: FlatpickrOptions = {
    dateFormat: flatPickrDateFormat
  };

  options = {
    manualPaginationAndSort: true,
    rowsPerPage: 10,
    rowSelection: true
  };

  currentPage: number;
  totalPages: number;
  gridFilters: GridOptionsSearchModel;
  totalRecords: number;
  downloadUrl: any;
  downloadingAll = false;

  constructor(
    private stateService: StateService,
    public zipService: ZipService,
    private apiService: ApiService,
    private router: Router,
    private siteService: SiteService,
    public utilService: UtilService,
    private toastService: ToastService,
    private translatePipe: TranslatePipe,
    private loggerService: LoggerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.id = this.siteId ? this.siteId : this.router.url.split('/sites/')[1].split('/')[0];
    this.siteService.endDate.next(null);
    this.siteService.startDate.next(null);
    this.siteService.searchTerm.next('');

    this.postStudyOutcomeDocumentsGrid(this.id, {
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: ''
    });

    this.headers = this.getHeaders();

    this.searchTerm.valueChanges
      .pipe(
        debounce(() => timer(DEBOUNCE_TIME))
      )
      .subscribe((val) => {
        this.siteService.searchTerm.next(val);
        this.postStudyOutcomeDocumentsGrid(this.id, {
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: val,
          filterOptions: this.filterOptions,
          sortOptions: this.currentSort ? [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }] : null,
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });
      });

    this.startDateFilterOptions();
    this.endDateFilterOptions();
  }

  startDateFilterOptions(): void {
    this.startDateFilter.valueChanges
      .subscribe((val) => {
        this.siteService.startDate.next(val[0]);

        this.jumpStartDates(val[0]);
        [this.endDatePicker.flatpickr.config.minDate] = val;

        if (val[0]) {
          this.startDateUTC = moment(val[0]).utc(false);
        }

        this.addFilterOptions();
        this.postStudyOutcomeDocumentsGrid(this.id, {
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          filterOptions: this.filterOptions,
          sortOptions: this.currentSort ? [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }] : null,
          startDate: val[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });
      });
  }

  endDateFilterOptions(): void {
    this.endDateFilter.valueChanges
      .subscribe((val) => {
        this.siteService.endDate.next(val[0]);

        this.jumpEndDates(val[0]);
        [this.startDatePicker.flatpickr.config.maxDate] = val;

        if (val[0]) {
          this.endDateUTC = moment(val[0]).utc(false);
        }

        this.addFilterOptions();
        this.postStudyOutcomeDocumentsGrid(this.id, {
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          filterOptions: this.filterOptions,
          sortOptions: this.currentSort ? [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }] : null,
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: val[0] ? this.endDateUTC : null
        });
      });
  }



  toggleEndDate(): void {
    this.endDatePicker.flatpickr.toggle();
  }

  toggleStartDate(): void {
    this.startDatePicker.flatpickr.toggle();
  }

  handleRowSelection(event): void {
    this.selected = event.detail;
  }

  handleRowClick(event): void {
    if (event.detail.event.target.className.includes('data-download-outcome-document-button')) {
      this.zipService.startDownload([event.detail], this.documentRequestTypes.Outcome);
    }
  }

  jumpStartDates(value: any): void {
    if (value) {
      if (!this.endDateFilter.value[0]) {
        this.endDatePicker.flatpickr.jumpToDate(value);
      }
    }
    else if (this.endDateFilter.value[0]) {
      this.startDatePicker.flatpickr.jumpToDate(this.endDateFilter.value[0]);
    }
    else {
      this.endDatePicker.flatpickr.jumpToDate(new Date());
    }
  }

  jumpEndDates(value: any): void {
    if (value) {
      if (!this.startDateFilter.value[0]) {
        this.startDatePicker.flatpickr.jumpToDate(value);
      }
    }
    else if (this.startDateFilter.value[0]) {
      this.endDatePicker.flatpickr.jumpToDate(this.startDateFilter.value[0]);
    }
    else {
      this.startDatePicker.flatpickr.jumpToDate(new Date());
    }
  }

  addFilterOptions(): void {
    if (this.startDateFilter.value[0] || this.endDateFilter.value[0]) {
      if (!this.filterOptions.length) {
        const filterOption = [
          {
            field: SiteOutcomeDocumentFilterOptions.reviewedDate
          },
          {
            field: SiteOutcomeDocumentFilterOptions.transmittedDate
          }
        ];

        this.filterOptions = filterOption;
      }
    }
    else {
      this.filterOptions = [];
    }
  }

  downloadAllDocuments(): void {
    this.downloadingAll = true;
    this.apiService.requestSiteOutcomeDownload(this.id,
      {
        page: 1,
        pageSize: 0,
        searchTerm: this.searchTerm.value,
        filterOptions: this.filterOptions,
        startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
        endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
      }).subscribe(() => {
      this.downloadingAll = false;
      this.stateService.showDownloadModal.next(true);
    }, (err) => {
      this.downloadingAll = false;
      this.loggerService.error(`Data could not be downloaded: ${err.message}`);
    });
  }

  handleChangePage(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;

    this.postStudyOutcomeDocumentsGrid(this.id, {
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      filterOptions: this.filterOptions,
      sortOptions: this.currentSort ? [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }] : null,
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  handleSorting(event): void {
    this.currentSort = StudySiteWorkspaceOutcomeDocumentsSortOptions[event.detail.field];
    this.currentSortDescending = !event.detail.ascending;
    this.currentPage = 1;

    this.postStudyOutcomeDocumentsGrid(this.id, {
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      filterOptions: this.filterOptions,
      sortOptions: [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }],
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  private getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('siteOutcomeDocuments.fileName'), 'fileNameLink', true),
      this.utilService.createTableHeader(
        this.translatePipe.transform('siteOutcomeDocuments.reviewed'),
        'reviewedDate',
        true,
        'date',
        this.utilService.dateFormat
      ),
      this.utilService.createTableHeader(
        this.translatePipe.transform('siteOutcomeDocuments.transmitted'),
        'transmittedDate',
        true,
        'date',
        this.utilService.dateFormat
      ),
      this.utilService.createTableHeader(this.translatePipe.transform('siteOutcomeDocuments.documentType'), 'documentType', true)
    ];
  }

  private postStudyOutcomeDocumentsGrid(id, body): void {
    this.loading = true;
    this.gridFilters = body;
    this.selected = [];

    this.apiService.getSiteOutcome(id, body)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (data.records && data.records.length > 0) {
          this.initialDocuments = data.records;
        }

        this.documents = this.siteService.mapOutcomeDocumentsData(data.records);
        this.totalRecords = data.totalRecords;
        this.documentsFetched = true;
        this.tableData = this.documents;
        this.loaded = true;
        this.loading = false;
        this.totalPages = Math.ceil(data.totalRecords / data.pageSize);
        this.currentPage = data.currentPage;
      }, () => {
        this.loaded = true;
        this.loading = false;
        this.documentsFetched = true;

        this.toastService.add([{
          closable: true,
          id: 'getWorkspaceOutcome',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          variant: 'error'
        }]);
      });
  }
}
