/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, takeUntil } from 'rxjs/operators';
import { Apis, ApiBaseUrl, ApiServiceUrl } from '../common/apis';
import { environment } from '../../environments/environment';
import {
  SubmissionsGrid, Submission,
  SubmissionTypeGroups,
  SubmissionTypeGroup,
  SubmissionDocumentType,
  ReviewTypes,
  SubmissionDocuments,
  SubmissionSites,
  SubmissionOutcome,
  OutcomeDetailsGrid,
  OutcomeDetails
} from '../interfaces/submissions';
import {
  ContactCountries,
  ContactOrganizations,
  ContactOrganizationTypes,
  ContactStates,
  UserInfoGet,
  UserRoles,
  WorkspaceUser,
  User,
  WorkspaceUserPostRes,
  UserDetailsGet,
  WorkspaceRequestModel
} from '../interfaces/user';
import {
  StudiesGrid,
  Study,
  StudyDocuments,
  StudySites,
  StudyContacts, StudySubmissions
} from '../interfaces/studies';
import { SubmissionCreateModel, SubmissionDraftCreateModel } from '../models/submission.model';
import { WorkspacePermissions, WorkspaceAccessModel, WorkspaceOutcomeGetRes } from '../interfaces/workspaces';
import { Site, SitePi, SiteContacts, SiteSubmissions, SiteTeams } from '../interfaces/sites';
import { ConfigurationModel } from './../interfaces/common';
import { HttpCancelService } from './http-cancel.service';
import { SubmissionsDashboardPost } from '../interfaces/dashboard';
import { WorkspaceOrganizationModel } from '../models/workspace-organization.model';
import { TeamMembersGrid, TeamStudiesGrid } from '../interfaces/teams';
import { KnownScope } from '../common/collections';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private httpCancelService: HttpCancelService
  ) { }

  // -----------------------
  // DELETE ----------------
  // -----------------------

  public deleteFile(filesToRemove): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.DocumentDelete}`;

    return this.httpClient
      .post(
        url,
        filesToRemove
      )
      .pipe(
        map((res: any) => res),
        catchError(this.handleError)
      );
  }

  public deleteSubmissionsDraft(id: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionsDeleteDraft.replace('{submissionId}', id)}`;

    return this.httpClient
      .delete(
        url
      )
      .pipe(
        catchError(this.handleError)
      );
  }


  // -----------------------
  // GET -------------------
  // -----------------------

  public getAllFormioForms(): Observable<any> {
    const url = `${environment.formio.appUrl}/form?limit=70`;

    return this.httpClient.get(url, {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'x-token': environment.formio.apiKey,
        'Content-Type': 'application/json'
      })
    }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public getAllFormioFormsToDisplay(): Observable<any> {
    const url = `${environment.formio.appUrl}/formsToDisplay/submission?data.displayFormInProject=true&limit=100`;

    return this.httpClient.get(url, {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'x-token': environment.formio.apiKey,
        'Content-Type': 'application/json'
      })
    }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public getContactCountries(): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.ContactCountries}`;

    return this.httpClient
      .get(url)
      .pipe(
        map((res: ContactCountries) => res),
        catchError(this.handleError)
      );
  }

  public getContactOrganizations(): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.ContactOrganizations}`;

    return this.httpClient
      .get(url)
      .pipe(
        map((res: ContactOrganizations) => res),
        catchError(this.handleError)
      );
  }

  public getContactIRBOrganizations(): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.ContactIRBOrganizations}`;

    return this.httpClient
      .get(url)
      .pipe(
        map((res: ContactOrganizations) => res),
        catchError(this.handleError)
      );
  }

  public getContactOrganizationTypes(): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.ContactOrganizationTypes}`;

    return this.httpClient
      .get(url)
      .pipe(
        map((res: ContactOrganizationTypes) => res),
        catchError(this.handleError)
      );
  }

  public getContactStates(id: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.ContactStates}`.replace('{countryid}', id);

    return this.httpClient
      .get(url)
      .pipe(
        map((res: ContactStates) => res),
        catchError(this.handleError)
      );
  }

  public getDownloadRequestsGrid(): Observable<any> {
    const url = `${ApiServiceUrl}${Apis.UserDownloads}`;

    return this.httpClient.get<any>(url).pipe(
      map((res: any) => res),
      catchError(this.handleError)
    );
  }

  public getDraftSubmission(id: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.GetDraftSubmissionById}`.replace('{submissionId}', id);

    return this.httpClient
      .get<any>(url)
      .pipe(
        map((res: any) => res),
        catchError(this.handleError)
      );
  }

  public getFormioForm(formName: string): Observable<any> {
    const url = `${environment.formio.appUrl}/${formName}`;
    const httpParams = new HttpParams()
      .set('full', 'true');

    return this.httpClient.get(url, {
      params: httpParams,
      headers: new HttpHeaders({
        Accept: 'application/json',
        'x-token': environment.formio.apiKey,
        'Content-Type': 'application/json'
      })
    }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public getFormTypeBySubmissionTypeId(submissionTypeId: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionFormType}`.replace('{submissionTypeId}', submissionTypeId);

    return this.httpClient
      .get<any>(url)
      .pipe(
        map((res: any) => res),
        catchError(this.handleError)
      );
  }

  public getResources(): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.Resources}`;

    return this.httpClient
      .get<any>(url)
      .pipe(
        map((res: any) => res),
        catchError(this.handleError)
      );
  }

  public getSasStorageFilePath(path: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.StorageFilePath}`.replace('{storageFilePath}', path);

    return this.httpClient
      .get<any>(url)
      .pipe(
        map((res: any) => res),
        catchError(this.handleError)
      );
  }

  public getSasForDocumentUpload(): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SasForDocumentUpload}`;

    return this.httpClient
      .get<any>(url)
      .pipe(
        map((res: any) => res),
        catchError(this.handleError)
      );
  }

  public getSite(id): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.Site}`.replace('{id}', id);

    return this.httpClient
      .get<Site>(url)
      .pipe(
        map((res: Site) => res),
        catchError(this.handleError)
      );
  }


  public getSiteContacts(id, data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SiteContacts}`.replace('{id}', id);

    return this.httpClient
      .post<SiteContacts>(url, data)
      .pipe(
        map((res: SiteContacts) => res),
        catchError(this.handleError)
      );
  }

  public getSitePi(id): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SitePi}`.replace('{id}', id);

    return this.httpClient
      .get<SitePi>(url)
      .pipe(
        map((res: SitePi) => res),
        catchError(this.handleError)
      );
  }

  public getSiteSubmissions(id, data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SiteSubmissions}`.replace('{id}', id);

    return this.httpClient
      .post<SiteSubmissions>(url, data)
      .pipe(
        map((res: SiteSubmissions) => res),
        catchError(this.handleError)
      );
  }

  public getSitesList(data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SiteList}`.replace('{piName}', data.piName).replace('{irbTrackingNumber}', data.irbTrackingNumber);

    return this.httpClient.get<SiteTeams[]>(url)
      .pipe(
        map((res: SiteTeams[]) => res),
        catchError(this.handleError)
      );
  }

  public getStudyList(searchTerm: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.StudyList}`.replace('{searchTerm}', searchTerm);

    return this.httpClient.get(url)
      .pipe(
        map((res: any[]) => res),
        catchError(this.handleError)
      );
  }

  public getStudy(id): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.Study}`.replace('{id}', id);

    return this.httpClient
      .get<Study>(url)
      .pipe(
        map((res: Study) => res),
        catchError(this.handleError)
      );
  }

  public getStudyContacts(id, data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.StudyContacts}`.replace('{id}', id);

    return this.httpClient
      .post<StudyContacts>(url, data)
      .pipe(
        map((res: StudyContacts) => res),
        catchError(this.handleError)
      );
  }

  public getStudyDocuments(id): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.StudyDocuments}`.replace('{id}', id);

    return this.httpClient
      .get<StudyDocuments>(url)
      .pipe(
        map((res: StudyDocuments) => res),
        catchError(this.handleError)
      );
  }

  public postStudySites(id, model): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.StudySites}`.replace('{id}', id);

    return this.httpClient
      .post<StudySites>(url, model)
      .pipe(
        map((res: StudySites) => res),
        catchError(this.handleError)
      );
  }

  public postStudySitesSubmit(id, model): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.StudySitesSubmit}`.replace('{id}', id);

    return this.httpClient.post(url, model)
      .pipe(
        map((res: StudySites) => res),
        catchError(this.handleError)
      );
  }

  public getStudySubmissions(id, data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.StudySubmissions}`.replace('{id}', id);

    return this.httpClient
      .post<StudySubmissions>(url, data)
      .pipe(
        map((res: StudySubmissions) => res),
        catchError(this.handleError)
      );
  }

  public getSubmission(id): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.Submission}`.replace('{submissionId}', id);

    return this.httpClient
      .get<Submission>(url)
      .pipe(
        map((res: Submission) => res),
        catchError(this.handleError)
      );
  }

  public getSubmissionDocuments(id, data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionDocuments}`.replace('{submissionId}', id);

    return this.httpClient
      .post<SubmissionDocuments>(url, data)
      .pipe(
        map((res: SubmissionDocuments) => res),
        catchError(this.handleError)
      );
  }

  public getSubmissionDocumentTypes(): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.DocumentTypes}`;

    return this.httpClient
      .get<SubmissionDocumentType>(url)
      .pipe(
        map((res: SubmissionDocumentType) => res),
        catchError(this.handleError)
      );
  }

  public getSubmissionDocumentTypesBySubmissionTypeId(submissionTypeId: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.DocumentTypesBySubmissionTypeId}`
      .replace('{submissionTypeId}', submissionTypeId);

    return this.httpClient
      .get<SubmissionDocumentType>(url)
      .pipe(
        map((res: SubmissionDocumentType) => res),
        catchError(this.handleError)
      );
  }

  public getSubmissionEstimatedOutcomeDate(days: number): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionEstimatedOutcomeDate}`.replace('{days}', days.toString());

    return this.httpClient
      .get<Date>(url)
      .pipe(
        map((res: Date) => res),
        catchError(this.handleError)
      );
  }

  public getSubmissionOutcome(id, data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionOutcome}`.replace('{submissionId}', id);

    return this.httpClient
      .post<SubmissionOutcome>(url, data)
      .pipe(
        map((res: SubmissionOutcome) => res),
        catchError(this.handleError)
      );
  }

  public getSubmissionOutcomeDocumentDetails(submissionId, documentId, data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionOutcomeDocumentDetails}`
      .replace('{submissionId}', submissionId)
      .replace('{documentId}', documentId);

    return this.httpClient
      .post<OutcomeDetailsGrid>(url, data)
      .pipe(
        map((res: OutcomeDetailsGrid) => res),
        catchError(this.handleError)
      );
  }

  public getAllSubmissionOutcomeDocumentDetails(submissionId, documentIds): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.AllSubmissionOutcomeDocumentDetails}`
      .replace('{submissionId}', submissionId);

    return this.httpClient
      .post<OutcomeDetails>(url, documentIds)
      .pipe(
        map((res: OutcomeDetails) => res),
        catchError(this.handleError)
      );
  }

  public createDownloadAllRequestForSubmissionOutcomeDocument(submissionId, documentId, data): Observable<any> {
    const url = `${ApiServiceUrl}${Apis.SubmissionOutcomeDocumentDownloadRequest}`
      .replace('{submissionId}', submissionId)
      .replace('{documentId}', documentId);

    return this.httpClient
      .post<OutcomeDetailsGrid>(url, data)
      .pipe(map((response: any) => response),
        catchError(this.handleError));
  }

  public getStudyOutcomeDocumentDetails(studyId, documentId, data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.StudyOutcomeDocumentDetails}`
      .replace('{studyId}', studyId)
      .replace('{documentId}', documentId);

    return this.httpClient
      .post<OutcomeDetailsGrid>(url, data)
      .pipe(
        map((res: OutcomeDetailsGrid) => res),
        catchError(this.handleError)
      );
  }

  public createDownloadAllRequestForStudyOutcomeDocument(studyId, documentId, data): Observable<any> {
    const url = `${ApiServiceUrl}${Apis.StudyOutcomeDocumentDownloadRequest}`
      .replace('{studyId}', studyId)
      .replace('{documentId}', documentId);

    return this.httpClient
      .post<OutcomeDetailsGrid>(url, data)
      .pipe(map((response: any) => response),
        catchError(this.handleError));
  }

  public getAllStudyOutcomeDocumentDetails(studyId, documentIds): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.AllStudyOutcomeDocumentDetails}`
      .replace('{studyId}', studyId);

    return this.httpClient
      .post<OutcomeDetails>(url, documentIds)
      .pipe(
        map((res: OutcomeDetails) => res),
        catchError(this.handleError)
      );
  }

  public getSubmissionTypeGroup(groupId): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionTypeGroup}`.replace('{groupId}', groupId);

    return this.httpClient
      .get<SubmissionTypeGroup>(url)
      .pipe(
        map((res: SubmissionTypeGroup) => res),
        catchError(this.handleError)
      );
  }

  public getSubmissionTypeGroups(): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionTypeGroups}`;

    return this.httpClient
      .get<SubmissionTypeGroups>(url)
      .pipe(
        map((res: SubmissionTypeGroups) => res),
        catchError(this.handleError)
      );
  }

  public getSubmissionSites(id, data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionSites}`.replace('{submissionId}', id);

    return this.httpClient
      .post<SubmissionSites>(url, data)
      .pipe(
        map((res: SubmissionSites) => res),
        catchError(this.handleError)
      );
  }

  public getReviewTypes(): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.ReviewTypes}`;

    return this.httpClient
      .get<ReviewTypes>(url)
      .pipe(
        map((res: ReviewTypes) => res),
        catchError(this.handleError)
      );
  }

  public getUserDetails(id: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.UserDetails}`.replace('{userid}', id);

    return this.httpClient
      .get<UserDetailsGet>(url)
      .pipe(
        map((res: UserDetailsGet) => res),
        catchError(this.handleError)
      );
  }

  public getUserInfo(): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.CurrentUser}`;

    return this.httpClient
      .get<UserInfoGet>(url)
      .pipe(
        map((res: UserInfoGet) => res),
        catchError(this.handleError)
      );
  }

  public getUserRoles(): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.UserRoles}`;

    return this.httpClient
      .get(url)
      .pipe(
        map((res: UserRoles) => res),
        catchError(this.handleError)
      );
  }

  public getUsers(filter): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.Users}`;

    const body = {
      filter: filter
    };

    return this.httpClient
      .post<Array<User>>(url, body)
      .pipe(
        map((res: Array<User>) => res),
        catchError(this.handleError)
      );
  }

  public getPrimaryUsers(filter, teamExternalReferenceId = null, includeNotRegistered = false, includeName = false): Observable<any> {
    const path = `${ApiBaseUrl}${Apis.PrimaryUsers}`;

    const body = {
      mask: filter,
      teamExternalReferenceId: teamExternalReferenceId,
      includeNotRegistered: includeNotRegistered,
      includeName: includeName
    };

    return this.httpClient
      .post<Array<User>>(path, body)
      .pipe(
        map((res: Array<User>) => res),
        catchError(this.handleError)
      );
  }

  public getUserStudyPermissionsById(id): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.StudyPermissions}`.replace('{id}', id);

    return this.httpClient
      .get<WorkspacePermissions>(url)
      .pipe(
        map((res: WorkspacePermissions) => res),
        catchError(this.handleError)
      );
  }

  public getUserSitePermissionsById(id): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SitePermissions}`.replace('{id}', id);

    return this.httpClient
      .get<WorkspacePermissions>(url)
      .pipe(
        map((res: WorkspacePermissions) => res),
        catchError(this.handleError)
      );
  }

  public getUserWorkspaces(id: string, body): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.WorkspacesUser}`.replace('{userid}', id);

    return this.httpClient
      .post<any>(url, body)
      .pipe(
        map((res: any) => res),
        catchError(this.handleError)
      );
  }

  public getUserStatus(email: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.UsersStatus}`;

    const body = {
      email: email
    };

    return this.httpClient
      .post<any>(url, body)
      .pipe(
        map((res: boolean) => res),
        catchError(this.handleError)
      );
  }

  public getStudyOutcome(id, data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.StudyOutcome}`.replace('{id}', id);

    return this.httpClient
      .post<WorkspaceOutcomeGetRes>(url, data)
      .pipe(
        map((res: WorkspaceOutcomeGetRes) => res),
        catchError(this.handleError)
      );
  }

  public getSiteOutcome(id, data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SiteOutcome}`.replace('{id}', id);

    return this.httpClient
      .post<WorkspaceOutcomeGetRes>(url, data)
      .pipe(
        map((res: WorkspaceOutcomeGetRes) => res),
        catchError(this.handleError)
      );
  }

  public getWorkspaceRequests(id: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.WorkspaceRequests}`
      .replace('{workspaceId}', id);

    return this.httpClient
      .get(url)
      .pipe(
        map((res: Array<WorkspaceUser>) => res),
        catchError(this.handleError)
      );
  }

  public getWorkspaceUsersById(id): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.WorkspaceUsers}`.replace('{id}', id);

    return this.httpClient
      .get<WorkspaceRequestModel>(url)
      .pipe(
        map((res: WorkspaceRequestModel) => res),
        catchError(this.handleError)
      );
  }

  /* eslint-disable max-len */
  public getWorkspaceSearch(workspaceType: string, searchTerm: string, irbTrackingNumber: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.WorkspaceSearch}`.replace('{workspaceType}', workspaceType).replace('{searchTerm}', searchTerm).replace('{irbTrackingNumber}', irbTrackingNumber);

    return this.httpClient
      .get<WorkspaceAccessModel>(url)
      .pipe(
        map((res: WorkspaceAccessModel) => res),
        catchError(this.handleError)
      );
  }

  public getWorkspaceByExternalReferenceId(workspaceExternalReferenceId: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.getWorkspaceByExternalReferenceId}`.replace('{workspaceExternalReferenceId}', `${workspaceExternalReferenceId}`);

    return this.httpClient
      .get<WorkspaceAccessModel>(url)
      .pipe(
        map((res: WorkspaceAccessModel) => res),
        catchError(this.handleError)
      );
  }

  public getConfigurationValue(configurationKey: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.ConfigurationValue}`.replace('{configurationKey}', configurationKey);

    return this.httpClient
      .get<ConfigurationModel>(url)
      .pipe(
        map((res) => res),
        catchError(this.handleError)
      );
  }

  public updateSetting(settingName: string, value: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SettingValue}`.replace('{settingValue}', settingName);

    const model = {
      value: value
    };

    return this.httpClient
      .put(
        url,
        model
      ).pipe(
        map((res) => res),
        catchError(this.handleError)
      );
  }

  public workSpaceRequestsByAccessModel(model: WorkspaceAccessModel): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.WorkSpaceRequestsByAccessModel}`;

    return this.httpClient.post(url, model)
      .pipe(
        map((res) => res),
        catchError(this.handleError)
      );
  }

  public sendFailedRequests(model: any[]): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SendFailedRequests}`;

    return this.httpClient.post(url, model)
      .pipe(
        map((res) => res),
        catchError(this.handleError)
      );
  }

  public getTeamOrganizationTypes(): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.ContactOrganizationTypesByScope}`.replace('{scopeId}', `${KnownScope.Teams}`);

    return this.httpClient.get(url)
      .pipe(
        map((res) => res),
        catchError(this.handleError)
      );
  }

  public getTeamInfoByTeamId(teamId: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.OrganizationTeam}`.replace('{teamId}', `${teamId}`);

    return this.httpClient.get(url)
      .pipe(
        map((res) => res),
        catchError(this.handleError)
      );
  }

  public checkIfItCanRequestAccessToSubmission(submissionId: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionCheckRequestAccess}`
      .replace('{submissionId}', submissionId);

    return this.httpClient
      .get<any>(url)
      .pipe(
        map((res: any) => res),
        catchError(this.handleError)
      );
  }

  public IsValidTeamOrganizationEmail(email, teamId: string = null): Observable<any> {
    let path = `${ApiBaseUrl}${Apis.ValidateEmail}`.replace('{email}', `${encodeURIComponent(email)}`);

    if (teamId !== null && teamId.length > 0) {
      path = `${path}&teamExternalReferenceId=${teamId}`;
    }

    return this.httpClient.get(path)
      .pipe(
        map((res) => res),
        catchError(this.handleError)
      );
  }

  // -----------------------
  // PATCH -----------------
  // -----------------------

  public patchDocumentsLastDownloadDate(
    documentIds: string[],
    documentRequestType: number
  ): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.LastDownloadDateBatch}`;

    const params = new HttpParams()
      .set('documentRequestType', documentRequestType.toString());

    return this.httpClient
      .patch<string>(
        url,
        documentIds,
        {
          params
        }
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError)
      );
  }

  public patchTeamWorkspaceRequestApproveOrReject(
    workspaceId: string,
    teamId: string,
    isRejected: boolean
  ): Observable<any> {
    let url = `${ApiBaseUrl}${Apis.TeamWorkspaceRequestsApproveOrReject}`
      .replace('{workspaceId}', workspaceId)
      .replace('{teamId}', teamId);

    if (isRejected) {
      url = `${url}?isRejected=${isRejected}`;
    }

    return this.httpClient
      .patch<any>(
        url,
        null
      )
      .pipe(
        map((res: any) => res),
        catchError(this.handleError)
      );
  }

  public setTeamOrganizationStatus(teamId, data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SetTeamOrganizationStatus}`.replace('{teamId}', `${teamId}`);

    return this.httpClient
      .patch(
        url,
        data
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError)
      );
  }

  // -----------------------
  // POST ------------------
  // -----------------------

  public addWorkspaceOrganization(data: WorkspaceOrganizationModel): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.AddWorkspaceOrganization}`;
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'application/json');

    return this.httpClient
      .post<WorkspaceOrganizationModel>(
        url,
        data,
        {
          headers: httpHeaders
        }
      )
      .pipe(
        map((res: any) => res),
        catchError(this.handleError)
      );
  }

  public downloadFormioPDF(formJSON: string): Observable<any> {
    return this.httpClient.post(environment.formio.pdfUrl, formJSON, {
      headers: new HttpHeaders({
        'x-jwt-token': environment.formio.apiKey,
        'Content-Type': 'application/json',
        'x-file-token': environment.formio.fileToken
      }),
      responseType: 'blob'
    }).pipe(
      map((res) => res),
      catchError(this.handleError)
    );
  }

  public postCreateSubmissionAsync(submissionData: SubmissionCreateModel): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionsCreate}`;
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'application/json');

    return this.httpClient
      .post<SubmissionCreateModel>(
        url,
        submissionData,
        {
          headers: httpHeaders
        }
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError)
      );
  }

  public postSubmittedDocumentDownloads(payload): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmittedDocumentDownload}`;

    return this.httpClient
      .post(
        url,
        payload
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError)
      );
  }

  public postDraftSubmission(data): Observable<SubmissionDraftCreateModel> {
    const url = `${ApiBaseUrl}${Apis.SubmissionsCreateDraft}`;
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'application/json');

    return this.httpClient
      .post<SubmissionDraftCreateModel>(
        url,
        data,
        {
          headers: httpHeaders
        }
      )
      .pipe(
        map((res: any) => res),
        catchError(this.handleError)
      );
  }

  public postSitesGrid(data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SitesGrid}`;

    return this.httpClient
      .post<StudiesGrid>(
        url,
        data
      )
      .pipe(
        map((res: StudiesGrid) => res),
        catchError(this.handleError)
      );
  }

  public postStudiesGrid(data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.StudiesGrid}`;

    return this.httpClient
      .post<StudiesGrid>(
        url,
        data
      )
      .pipe(
        map((res: StudiesGrid) => res),
        catchError(this.handleError)
      );
  }

  public postStudiesSubmit(data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.StudiesSubmit}`;

    return this.httpClient
      .post<StudiesGrid>(
        url,
        data
      )
      .pipe(
        map((res: StudiesGrid) => res),
        catchError(this.handleError)
      );
  }

  public postTeamSitesGrid(organizationId, data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.OrganizationTeamSites}`
      .replace('{organizationId}', organizationId);

    return this.httpClient
      .post<any>(
        url,
        data
      )
      .pipe(
        map((res: any) => res),
        catchError(this.handleError)
      );
  }

  public postTeamStudiesGrid(organizationId, data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.TeamStudies}`
      .replace('{organizationId}', organizationId);

    return this.httpClient
      .post<TeamStudiesGrid>(
        url,
        data
      )
      .pipe(
        map((res: TeamStudiesGrid) => res),
        catchError(this.handleError)
      );
  }

  public postTeamMembersGrid(organizationId, data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.TeamMembers}`
      .replace('{teamId}', organizationId);

    return this.httpClient
      .post<TeamMembersGrid>(
        url,
        data
      )
      .pipe(
        map((res: TeamMembersGrid) => res),
        catchError(this.handleError)
      );
  }

  public postAllDashboardSubmissions(data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionsDashboard}`;

    const httpParams = new HttpParams();

    return this.httpClient
      .post<SubmissionsDashboardPost>(
        url,
        data,
        {
          params: httpParams
        }
      )
      .pipe(
        map((res: SubmissionsDashboardPost) => res),
        catchError(this.handleError)
      );
  }


  public postSubmissionsDashboard(status, filter, data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionsDashboard}`;

    const httpParams = new HttpParams()
      .set('status', status)
      .set('statusFilter', filter || '');

    return this.httpClient
      .post<SubmissionsDashboardPost>(
        url,
        data,
        {
          params: httpParams
        }
      )
      .pipe(
        map((res: SubmissionsDashboardPost) => res),
        catchError(this.handleError)
      );
  }


  public postSubmissionsDashboardDrafts(data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionsDashboardDrafts}`;

    this.httpCancelService.cancelPostSubmissionsDashboardDrafts();

    return this.httpClient
      .post<SubmissionsDashboardPost>(
        url,
        data
      )
      .pipe(
        takeUntil(this.httpCancelService.onCancelPostSubmissionsDashboardDrafts()),
        map((res: SubmissionsDashboardPost) => res),
        catchError(this.handleError)
      );
  }

  public postSubmissionsDashboardInProgress(data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionsDashboardInProgress}`;

    this.httpCancelService.cancelPostSubmissionsDashboardInProgress();

    return this.httpClient
      .post<SubmissionsDashboardPost>(
        url,
        data
      )
      .pipe(
        map((res: SubmissionsDashboardPost) => res),
        catchError(this.handleError)
      );
  }

  public postSubmissionsDashboardNeedsAction(data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionsDashboardNeedsAction}`;

    this.httpCancelService.cancelPostSubmissionsDashboardNeedsAction();

    return this.httpClient
      .post<SubmissionsDashboardPost>(
        url,
        data
      )
      .pipe(
        takeUntil(this.httpCancelService.onCancelPostSubmissionsDashboardNeedsAction()),
        map((res: SubmissionsDashboardPost) => res),
        catchError(this.handleError)
      );
  }

  public postSubmissionsGrid(data, limitAdminUserCount, adminSubmissionCount): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionsGrid}?limitAdminUserCount=${limitAdminUserCount}&
      adminSubmissionCount=${adminSubmissionCount}`;

    return this.httpClient
      .post<SubmissionsGrid>(
        url,
        data
      )
      .pipe(
        map((res: SubmissionsGrid) => res),
        catchError(this.handleError)
      );
  }

  public getEstimatedOutcomeDate(numberOfDays: number): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionEstimatedOutcomeDate}`;
    const httpParams = new HttpParams()
      .set('numberOfDays', numberOfDays.toString());

    return this.httpClient.get(url, {
      params: httpParams
    }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public postUserProfile(payload): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.UserProfile}`;

    return this.httpClient
      .post(
        url,
        payload
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError)
      );
  }

  public postWorkspacesAccessRequest(workspaceId: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.WorkspaceRequests}`
      .replace('{workspaceId}', workspaceId);

    return this.httpClient
      .post(url, null)
      .pipe(
        map(() => null),
        catchError(this.handleError)
      );
  }

  public postSubmissionAccessRequest(submissionId: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.SubmissionRequestAccess}`
      .replace('{submissionId}', submissionId);

    return this.httpClient
      .post(url, null)
      .pipe(
        map(() => null),
        catchError(this.handleError)
      );
  }

  public postWorkspaceRestrict(workspaceId: string, users: any): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.WorkspaceRestrict}`
      .replace('{workspaceId}', workspaceId);

    return this.httpClient
      .post<WorkspaceUserPostRes>(
        url,
        users
      )
      .pipe(
        map((res: WorkspaceUserPostRes) => res),
        catchError(this.handleError)
      );
  }

  public postWorkspaceRequestApprove(
    workspaceId: string,
    userId: string,
    permissionType: string,
    concurrencyVersion: string
  ): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.WorkspaceRequestsApprove}?permissionType=${permissionType}&concurrencyVersion=${encodeURIComponent(concurrencyVersion)}`
      .replace('{workspaceId}', workspaceId)
      .replace('{userId}', userId);

    return this.httpClient
      .post<WorkspaceUserPostRes>(
        url,
        null
      )
      .pipe(
        map((res: WorkspaceUserPostRes) => res),
        catchError(this.handleError)
      );
  }

  public postWorkspaceRequestReject(workspaceId: string, userId: string, concurrencyVersion: string): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.WorkspaceRequestsReject}?concurrencyVersion=${encodeURIComponent(concurrencyVersion)}`
      .replace('{workspaceId}', workspaceId)
      .replace('{userId}', userId);

    return this.httpClient
      .post<WorkspaceUserPostRes>(
        url,
        null
      )
      .pipe(
        map(() => null),
        catchError(this.handleError)
      );
  }

  public postWorkspacesInvite(id: string, permissionType: string, data: string[]): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.WorkspaceInvite}`
      .replace('{id}', id)
      .replace('{permissionType}', permissionType);

    return this.httpClient
      .post<Array<string>>(
        url,
        data
      )
      .pipe(
        map(() => null),
        catchError(this.handleError)
      );
  }

  public postWorkspaceUserById(
    workspaceId: string,
    userId: string,
    newPermissionType: string,
    concurrencyVersion: string
  ): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.WorkspaceUser}`
      .replace('{workspaceId}', workspaceId)
      .replace('{userId}', userId);
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'application/json');
    const body = {
      permissionType: Number(newPermissionType),
      concurrencyVersion
    };

    return this.httpClient
      .post<WorkspaceUserPostRes>(
        url,
        body,
        {
          headers: httpHeaders
        }
      )
      .pipe(
        map((res: WorkspaceUserPostRes) => res),
        catchError(this.handleError)
      );
  }

  public postBulkInviteWorkspaceUsers(
    workspaceIds: any[],
    userEmails: any[],
    permissionType: string
  ): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.BulkWorkspaceInvite}`
      .replace('{permissionType}', permissionType);
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'application/json');
    const body = {
      userEmails: userEmails,
      workspaceExternalReferenceIds: workspaceIds
    };

    return this.httpClient.post(url, body, { headers: httpHeaders });
  }

  public postBulkRestrictWorkspaceUsers(
    workspaceIds: any[],
    userEmails: any[]
  ): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.BulkWorkspaceRestrict}`;
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'application/json');
    const body = {
      userEmails: userEmails,
      workspaceExternalReferenceIds: workspaceIds
    };

    return this.httpClient.post(url, body, { headers: httpHeaders });
  }

  public bulkUpdateWorkspaceUsers(
    workspaceIds: any[],
    userEmails: any[],
    newPermissionType: string
  ): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.BulkWorkspaceUpdate}`
      .replace('{permissionType}', newPermissionType);
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'application/json');
    const body = {
      userEmails: userEmails,
      workspaceExternalReferenceIds: workspaceIds
    };

    return this.httpClient.post(url, body, { headers: httpHeaders });
  }

  public postFileProtectionValidation(file, fileExtension): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.DocumentProtected}`;

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    const httpParams = new HttpParams()
      .set('fileExtension', fileExtension);

    return this.httpClient
      .post(
        url,
        formData,
        {
          params: httpParams
        }
      )
      .pipe(
        map((res: any) => res),
        catchError(this.handleError)
      );
  }

  public postWorkspaceTeamMembersGrid(workspaceId, organizationId, data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.TeamMembersWorkspaceAccessRequest}`
      .replace('{workspaceExternalReferenceId}', workspaceId)
      .replace('{teamExternalReferenceId}', organizationId);

    return this.httpClient
      .post<TeamMembersGrid>(
        url,
        data
      )
      .pipe(
        map((res: TeamMembersGrid) => res),
        catchError(this.handleError)
      );
  }

  public postTeamOrganizationUsers(teamId, body): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.AddTeamUser}`.replace('{teamId}', `${teamId}`);

    return this.httpClient.post(url, body)
      .pipe(
        map((res) => res),
        catchError(this.handleError)
      );
  }

  public requestSiteOutcomeDownload(id, data): Observable<any> {
    const url = `${ApiServiceUrl}${Apis.SiteOutcomeDownload}`.replace('{id}', id);

    return this.httpClient.post(url, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError)
      );
  }

  // -----------------------
  // PUT ----------------
  // -----------------------
  public deleteTeamWorkspaces(teamId, workspaceIds): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.DeleteTeamWorkspace}`.replace('{teamId}', `${teamId}`);

    return this.httpClient
      .put(
        url,
        workspaceIds
      )
      .pipe(
        map((res: any) => res),
        catchError(this.handleError)
      );
  }

  public deleteTeamUsers(teamId, userEmails): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.DeleteTeamUser}`.replace('{teamId}', `${teamId}`);

    return this.httpClient
      .put(
        url,
        userEmails
      )
      .pipe(
        map((res: any) => res),
        catchError(this.handleError)
      );
  }

  public updateTeam(teamId, data): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.UpdateTeam}`.replace('{teamId}', `${teamId}`);

    return this.httpClient
      .put(
        url,
        data
      )
      .pipe(
        map((res: any) => res),
        catchError(this.handleError)
      );
  }

  public removeTeamsFromWorkspace(workspaceId: string, teamIds: string[]): Observable<any> {
    const url = `${ApiBaseUrl}${Apis.removeTeamsFromWorkspace}`
      .replace('{workspaceId}', workspaceId);

    return this.httpClient
      .request(
        'delete',
        url,
        { body: teamIds }
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError)
      );
  }

  public submissionOutcomeDocumentDownloadRequest(submissionId: string, body: any, isStudyOnly: boolean): Observable<any> {
    const url = `${ApiServiceUrl}${Apis.AllSubmissionOutcomeDocumentDownloadAsync}`
      .replace('{submissionId}', submissionId)
      .replace('{isStudyOnly}', `${isStudyOnly}`);

    return this.httpClient.post(url, body).pipe(map((response: any) => response),
      catchError(this.handleError));
  }

  public studyOutcomeDocumentDownloadAllRequest(studyId: string, body: any, isStudyOnly: boolean): Observable<any> {
    const url = `${ApiServiceUrl}${Apis.StudyOutcomeDownloadAll}`
      .replace('{studyId}', studyId)
      .replace('{isStudyOnly}', `${isStudyOnly}`);

    return this.httpClient.post(url, body).pipe(map((response: any) => response),
      catchError(this.handleError));
  }

  public studySubmissionDocumentBulkDownloadAllRequest(studyId: string, body: any): Observable<any> {
    const url = `${ApiServiceUrl}${Apis.StudySubmissionDocumentsBulkDownloadAll}`
      .replace('{studyId}', studyId);

    return this.httpClient.post(url, body).pipe(map((response: any) => response),
      catchError(this.handleError));
  }

  public studySubmissionDocumentBulkDownloadSelectedRequest(studyId: string, body: any): Observable<any> {
    const url = `${ApiServiceUrl}${Apis.StudySubmissionDocumentsBulkDownloadRequest}`
      .replace('{studyId}', studyId);

    return this.httpClient.post(url, body).pipe(map((response: any) => response),
      catchError(this.handleError));
  }

  public siteSubmissionDocumentDownloadAllRequest(siteId: string, body: any): Observable<any> {
    const url = `${ApiServiceUrl}${Apis.SiteSubmissionDocumentsBulkDownloadAllRequest}`
      .replace('{siteId}', siteId);

    return this.httpClient.post(url, body).pipe(map((response: any) => response),
      catchError(this.handleError));
  }

  public siteSubmissionDocumentDownloadSelectedRequest(siteId: string, body: any): Observable<any> {
    const url = `${ApiServiceUrl}${Apis.SiteSubmissionDocumentsBulkDownloadSelectedRequest}`
      .replace('{siteId}', siteId);

    return this.httpClient.post(url, body).pipe(map((response: any) => response),
      catchError(this.handleError));
  }

  public downloadDocumentFile(rowKey: any): Observable<any> {
    const apiUrl = `${ApiServiceUrl}${Apis.GetOutcomeDocumentDownloadUrl}`
      .replace('{rowKey}', rowKey);

    return this.httpClient.get(apiUrl).pipe(map((response: any) => response),
      catchError(this.handleError));
  }

  public downloadDocumentsFromEmail(outcomeDocumentExternalReferenceId: any): Observable<any> {
    const apiUrl = `${ApiServiceUrl}${Apis.OutcomeDocumentsDownloadFromEmail}`
      .replace('{outcomeDocumentExternalReferenceId}', outcomeDocumentExternalReferenceId);

    return this.httpClient.post(apiUrl, {}).pipe(map((response: any) => response),
      catchError(this.handleError));
  }

  // -----------------------
  // ERROR ----------------
  // -----------------------

  private handleError = (error: HttpErrorResponse): any => {
    const newError: any = new Error(`${error}`);
    newError.details = error;

    return throwError(newError);
  };
}
