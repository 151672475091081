/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import {
  SubmissionDocuments,
  SubmissionDocumentsMap,
  SubmissionOutcome,
  SubmissionOutcomeMap,
  SubmissionDocumentLink,
  SubmissionSites,
  SubmissionSitesMap
} from '../../interfaces/submissions';
import { Apis, BackOfficeBaseUrl } from '../../common/apis';
import { UtilService } from '../../services/util.service';
import { BehaviorSubject } from 'rxjs';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Injectable({
  providedIn: 'root'
})
export class SubmissionsDetailService {
  public endDate = new BehaviorSubject<Date>(null);
  public startDate = new BehaviorSubject<Date>(null);
  public searchTerm = new BehaviorSubject<string>('');

  constructor(private utilService: UtilService,
    private translatePipe: TranslatePipe) { }

  public mapSubmittedDocumentsData(
    data: Array<SubmissionDocuments>,
    documentData: Array<SubmissionDocumentLink>
  ): Array<SubmissionDocumentsMap> {
    return data.map((record, index) => {
      const IrisStorageType = 3;
      let link = documentData[index].sas;
      let fileNameLink = this.utilService.addTableCellWrapperWithSearchTerms(
        record.fileName,
        this.searchTerm.value,
        link,
        true
      );

      if (record.storageType === IrisStorageType) {
        const term = this.utilService.highlightSearchTerm(record.fileName, this.searchTerm.value);
        link = `${BackOfficeBaseUrl}${Apis.OutcomeDocumentDownload}/${documentData[index].sas}`;
        fileNameLink = this.utilService.getTableCellWrapper(
          record.fileName,
          '',
          `<button class="wcg-link data-download-outcome-document-button">${term}</button>`
        );
      }

      return {
        fileName: record.fileName,
        link: link,
        fileNameLink,
        type: this.utilService.addTableCellWrapperWithSearchTerms(record.type.value, this.searchTerm.value),
        documentId: record.id
      };
    });
  }

  public mapOutcomeDocumentsData(data: Array<SubmissionOutcome>): Array<SubmissionOutcomeMap> {
    return data.map((record) => {
      const link = `${BackOfficeBaseUrl}${Apis.OutcomeDocumentDownload}/${record.irbDocumentId}`;
      const term = this.utilService.highlightSearchTerm(record.fileName, this.searchTerm.value);

      return {
        documentId: record.documentId,
        link: link,
        fileName: record.fileName,
        fileNameLink: this.utilService.getTableCellWrapper(
          record.fileName,
          '',
          `<button class="wcg-link data-download-outcome-document-button" title="${record.fileName.replace(/"/g, '')}">${term}</button>`
        ),
        reviewDetailsLink: this.utilService.getTableCellWrapper(
          record.documentId,
          // eslint-disable-next-line max-len
          `<button class="wcg-link data-details-outcome-document-button">${this.translatePipe.transform('outcomeDocumentsDetailsModal.outcomeDocumentReviewDetails')}</button>`
        ),
        reviewedDate: record.transmittedDate === null && record.reviewedDate ? this.utilService.highlightDateFields(
          record.reviewedDate, this.startDate.value, this.endDate.value
        ) : this.utilService.addTableCellWrapper(this.utilService.formatDate(record.reviewedDate)),
        transmittedDate: record.transmittedDate ? this.utilService.highlightDateFields(
          record.transmittedDate, this.startDate.value, this.endDate.value
        ) : this.utilService.addTableCellWrapper(this.utilService.formatDate(record.transmittedDate)),
        documentType: this.utilService.addTableCellWrapperWithSearchTerms(record.documentType, this.searchTerm.value),
        startDateFilter: null,
        endDateFilter: null
      };
    });
  }

  public mapSitesData(
    data: Array<SubmissionSites>
  ): Array<SubmissionSitesMap> {
    return data.map((record) => ({
      investigator: this.utilService.addTableCellWrapperWithSearchTerms(
        record.investigator,
        this.searchTerm.value,
        `/sites/${record.id}`
      ),
      piOrganizationName: this.utilService.addTableCellWrapperWithSearchTerms(record.piOrganizationName, this.searchTerm.value),
      institutionalTrackingNumber: this.utilService.addTableCellWrapperWithSearchTerms(
        record.institutionalTrackingNumber, this.searchTerm.value
      ),
      countryName: this.utilService.addTableCellWrapperWithSearchTerms(record.countryName, this.searchTerm.value)
    }));
  }
}
