import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { SubmissionWorkflowService } from '../views/submission-workflow/submission-workflow.service';

const WORKFLOW_ROOT = 'submission-workflow/';

@Injectable({
  providedIn: 'root'
})
export class SubmissionWorkflowGuard implements CanActivate {
  currentUrl: string;

  constructor(
    private router: Router,
    private submissionWorkflowService: SubmissionWorkflowService
  ) {}

  // eslint-disable-next-line complexity
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const { submissionFlow, submissionRegions, submissionTypeId } = this.submissionWorkflowService;
    const firstThreeSteps = submissionFlow.value && submissionRegions.value && submissionTypeId.value;
    const formIOReady = this.submissionWorkflowService.formReady.value;
    const searchStudy = this.submissionWorkflowService.submissionFindStudy.value?.id;
    const addStudy = submissionFlow.value === 'addStudy';
    const addPI = submissionFlow.value === 'addPI' && searchStudy;
    const addDocuments = submissionFlow.value === 'addDocuments' && searchStudy;
    const afterFormioCondition = addStudy || addPI || addDocuments;
    const canReachForm = firstThreeSteps && afterFormioCondition;
    const { returnToDraft } = route.queryParams;

    this.currentUrl = route.routeConfig.path;

    const conditions = [
      this.routeCondition('choose-sites', firstThreeSteps && searchStudy),
      this.routeCondition('details', returnToDraft || canReachForm),
      this.routeCondition('review', canReachForm),
      this.routeCondition('search-irb', firstThreeSteps && submissionFlow.value === 'addPI'),
      this.routeCondition('search-study', firstThreeSteps && submissionFlow.value !== 'addStudy'),
      this.routeCondition('setup/regions', submissionFlow.value),
      this.routeCondition('setup/types', submissionFlow.value && submissionRegions.value),
      this.routeCondition('upload', canReachForm),
      formIOReady
    ];

    if (conditions.find((condition) => condition)) {
      return true;
    }

    this.submissionWorkflowService.resetSubmissionData();
    this.router.navigate([`${WORKFLOW_ROOT}`]);

    return false;
  }

  private routeCondition(urlToValidate: string, condition: any): boolean {
    return Boolean(this.currentUrl === `${WORKFLOW_ROOT}${urlToValidate}` && condition);
  }
}
