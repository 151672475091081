/* eslint-disable max-lines */
import { Component, OnInit, ViewChild } from '@angular/core';
import { takeUntil, debounce } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

import { BaseComponent } from '../../../components/base/base.component';
import { ApiService } from '../../../services/api.service';
import { SiteService } from '../site.service';
import { ToastService } from '../../../services/toast.service';
import { UtilService } from '../../../services/util.service';
import { TableHeader } from 'src/app/interfaces/components';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { DEBOUNCE_TIME, flatPickrDateFormat, SiteSubmissionFilterOptions, SiteSubmissionSortOptions } from 'src/app/common/collections';
import { timer } from 'rxjs';
import * as moment from 'moment';
import { SiteSubmissionsMap } from 'src/app/interfaces/sites';
import { HttpCancelService } from 'src/app/services/http-cancel.service';
import { GridOptionsSearchModel } from 'src/app/admin/shared/model/grid.model';
import { GridExportType } from 'src/app/common/reportingCollections';
import { ZipService } from 'src/app/services/zip.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-site-submissions',
  templateUrl: './site-submissions.component.html',
  styleUrls: ['./site-submissions.component.scss']
})
export class SiteSubmissionsComponent extends BaseComponent implements OnInit {
  tableData: Array<SiteSubmissionsMap>;
  loaded: boolean;
  loading: boolean;
  id: string;
  searchTerm = new FormControl('');
  documents: Array<SiteSubmissionsMap>;
  submissionsWithNoDocuments: Array<string> = [];
  selected: Array<SiteSubmissionsMap> = [];
  documentsFetched = false
  headers: Array<TableHeader>;
  @ViewChild('startDatePicker') startDatePicker;
  @ViewChild('endDatePicker') endDatePicker;

  startDateFilter = new FormControl('');
  endDateFilter = new FormControl('');
  filterOptions: any[] = [];
  startDateUTC: any = null;
  endDateUTC: any = null;
  datePickerOptions: FlatpickrOptions = {
    dateFormat: flatPickrDateFormat
  };

  currentPageSize = 10;
  initialDocuments: Array<SiteSubmissionsMap> = [];
  options = {
    manualPaginationAndSort: true,
    rowsPerPage: 10,
    rowSelection: true
  };

  currentSort: string;
  currentSortDescending = true;
  currentPage: number;
  totalPages: number;
  submissionIds: string[] = [];
  downloadingAll = false;
  downloadingSelected = false;
  gridFilters: GridOptionsSearchModel;
  readonly exportType: GridExportType = GridExportType.SiteDetailsSubmissions;
  totalRecords: number;

  constructor(
    public zipService: ZipService,
    private apiService: ApiService,
    private router: Router,
    private siteService: SiteService,
    public utilService: UtilService,
    private toastService: ToastService,
    private translatePipe: TranslatePipe,
    private httpCancelService: HttpCancelService,
    private stateService: StateService
  ) {
    super();
  }

  ngOnInit(): void {
    const [id] = this.router.url.split('/sites/')[1].split('/');
    this.id = id;

    this.siteService.endDate.next(null);
    this.siteService.startDate.next(null);
    this.siteService.searchTerm.next('');

    this.postSiteSubmissionsGrid(id, {
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: ''
    });
    this.headers = this.getHeaders();

    this.searchTerm.valueChanges
      .pipe(
        debounce(() => timer(DEBOUNCE_TIME))
      )
      .subscribe((val) => {
        this.siteService.searchTerm.next(val);
        this.postSiteSubmissionsGrid(this.id, {
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: val,
          filterOptions: this.filterOptions,
          sortOptions: this.currentSort ? [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }] : null,
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });
      });

    this.startDateFilterOptions();
    this.endDateFilterOptions();
  }

  startDateFilterOptions(): void {
    this.startDateFilter.valueChanges
      .subscribe((val) => {
        this.siteService.startDate.next(val[0]);

        this.jumpStartDates(val[0]);
        [this.endDatePicker.flatpickr.config.minDate] = val;

        if (val[0]) {
          this.startDateUTC = moment(val[0]).utc(false);
        }

        this.addFilterOptions();
        this.postSiteSubmissionsGrid(this.id, {
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          filterOptions: this.filterOptions,
          sortOptions: this.currentSort ? [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }] : null,
          startDate: val[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });
      });
  }

  endDateFilterOptions(): void {
    this.endDateFilter.valueChanges
      .subscribe((val) => {
        this.siteService.endDate.next(val[0]);

        this.jumpEndDates(val[0]);
        [this.startDatePicker.flatpickr.config.maxDate] = val;

        if (val[0]) {
          this.endDateUTC = moment(val[0]).utc(false);
        }

        this.addFilterOptions();
        this.postSiteSubmissionsGrid(this.id, {
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          filterOptions: this.filterOptions,
          sortOptions: this.currentSort ? [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }] : null,
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: val[0] ? this.endDateUTC : null
        });
      });
  }

  handleRowClicked(event): void {
    if (event.detail.event.target.hasAttribute('href')) {
      event.detail.event.preventDefault();

      this.router.navigateByUrl(event.detail.event.target.getAttribute('href'));
    }
  }

  jumpStartDates(value: any): void {
    if (value) {
      if (!this.endDateFilter.value[0]) {
        this.endDatePicker.flatpickr.jumpToDate(value);
      }
    }
    else if (this.endDateFilter.value[0]) {
      this.startDatePicker.flatpickr.jumpToDate(this.endDateFilter.value[0]);
    }
    else {
      this.endDatePicker.flatpickr.jumpToDate(new Date());
    }
  }

  jumpEndDates(value: any): void {
    if (value) {
      if (!this.startDateFilter.value[0]) {
        this.startDatePicker.flatpickr.jumpToDate(value);
      }
    }
    else if (this.startDateFilter.value[0]) {
      this.endDatePicker.flatpickr.jumpToDate(this.startDateFilter.value[0]);
    }
    else {
      this.startDatePicker.flatpickr.jumpToDate(new Date());
    }
  }

  toggleEndDate(): void {
    this.endDatePicker.flatpickr.toggle();
  }

  toggleStartDate(): void {
    this.startDatePicker.flatpickr.toggle();
  }

  addFilterOptions(): void {
    if (this.startDateFilter.value[0] || this.endDateFilter.value[0]) {
      if (!this.filterOptions.length) {
        const filterOption = [
          {
            field: SiteSubmissionFilterOptions.submittedDate
          }
        ];

        this.filterOptions = filterOption;
      }
    }
    else {
      this.filterOptions = [];
    }
  }

  handleRowSelection(event): void {
    this.selected = event.detail;
  }

  handleChangePage(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;

    this.postSiteSubmissionsGrid(this.id, {
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      filterOptions: this.filterOptions,
      sortOptions: this.currentSort ? [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }] : null,
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  handleSorting(event): void {
    this.currentSort = SiteSubmissionSortOptions[event.detail.field];
    this.currentSortDescending = !event.detail.ascending;
    this.currentPage = 1;

    this.postSiteSubmissionsGrid(this.id, {
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      filterOptions: this.filterOptions,
      sortOptions: [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }],
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  downloadSelectedSubmissionsDocuments(): void {
    this.downloadingSelected = true;
    this.submissionIds = [];
    this.selected.forEach((element) => {
      this.submissionIds.push(element.titleLink);
    });

    const convertedSubmissionIds = this.convertHtmlStringIntoSubmissionIds();
    const filterData = {
      page: 1,
      pageSize: 0,
      searchTerm: this.searchTerm.value,
      filterOptions: this.filterOptions,
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null,
      submissionIds: convertedSubmissionIds
    };
    this.apiService.siteSubmissionDocumentDownloadSelectedRequest(this.id, filterData)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (data && data.length > 0) {
          this.stateService.submissionsWithoutSubmittedDocumentsList.next(data);
          this.submissionsWithNoDocuments = data;
        }
        this.downloadingSelected = false;
        this.stateService.showDownloadModal.next(true);
      }, (err) => {
        this.downloadingSelected = false;
        this.toastService.add([{
          closable: true,
          id: 'downloadSelectedSubmissionsDocuments',
          message: err !== null && err.details.error.message === this.translatePipe.transform('submissions.noSubmissionDocuments') ?
            this.translatePipe.transform('serverErrors.downloadSubmissionDocumentsNoFilesError') :
            this.translatePipe.transform('serverErrors.internalServerError'),
          variant: 'error'
        }]);
      });
  }

  downloadAllSubmissionsDocuments(): void {
    this.downloadingAll = true;

    const filterdata = {
      page: 1,
      pageSize: 0,
      searchTerm: this.searchTerm.value,
      filterOptions: this.filterOptions,
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    };
    this.apiService.siteSubmissionDocumentDownloadAllRequest(this.id, filterdata)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (data && data.length > 0) {
          this.stateService.submissionsWithoutSubmittedDocumentsList.next(data);
          this.submissionsWithNoDocuments = data;
        }
        this.downloadingAll = false;
        this.stateService.showDownloadModal.next(true);
      }, (err) => {
        this.downloadingAll = false;
        this.toastService.add([{
          closable: true,
          id: 'downloadAllSubmissionsDocuments',
          message: err !== null && err.details.error.message === this.translatePipe.transform('submissions.noSubmissionDocuments') ?
            this.translatePipe.transform('serverErrors.downloadSubmissionDocumentsNoFilesError') :
            this.translatePipe.transform('serverErrors.internalServerError'),
          variant: 'error'
        }]);
      });
  }

  public postSiteSubmissionsGrid(id, body): void {
    this.loading = true;
    this.gridFilters = body;
    this.httpCancelService.cancelPostSiteSubmissionDetails();
    this.selected = [];

    this.apiService.getSiteSubmissions(id, body)
      .pipe(takeUntil(this.httpCancelService.onCancelPostSiteSubmissionDetails()))
      .subscribe((data) => {
        if (data.records && data.records.length > 0) {
          this.initialDocuments = data.records;
        }

        this.documents = this.siteService.mapSubmissionsData(data.records);

        this.totalPages = Math.ceil(data.totalRecords / data.pageSize);
        this.currentPage = data.currentPage;
        this.totalRecords = data.totalRecords;

        this.documentsFetched = true;
        this.tableData = this.documents;
        this.loaded = true;
        this.loading = false;
      }, () => {
        this.loaded = true;
        this.loading = false;
        this.documentsFetched = true;

        this.toastService.add([{
          closable: true,
          id: 'getSiteSubmissions',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          variant: 'error'
        }]);
      });
  }

  private getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('tableHeaders.submissionName'), 'titleLink', true),
      this.utilService.createTableHeader(this.translatePipe.transform('tableHeaders.submissionType'), 'type', true),
      this.utilService.createTableHeader(
        this.translatePipe.transform('tableHeaders.submitted'),
        'submittedDate',
        true,
        'date',
        this.utilService.dateFormat
      ),
      this.utilService.createTableHeader(this.translatePipe.transform('tableHeaders.status'), 'status', true),
      this.utilService.createTableHeader(
        this.translatePipe.transform('tableHeaders.reviewOutcome'),
        'reviewOutcome',
        true
      )
    ];
  }

  private convertHtmlStringIntoSubmissionIds(): Array<string> {
    const result = [];
    const firstString = 'href="/submissions/';
    const secondString = '">';
    this.submissionIds.forEach((element) => {
      const firstPosition = element.indexOf(firstString, 0);
      const intermediaryResult = element.slice(firstPosition);
      const secondPosition = intermediaryResult.indexOf(secondString, 0);
      const submissionRefId = intermediaryResult.slice(firstString.length, secondPosition);
      result.push(submissionRefId);
    });

    return result;
  }
}
