import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from './pipes/translate.pipe';
import { WcgAngularModule } from '@epharmasolutions/ui-kit-angular';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { RequestAccessModalComponent } from './components/modals/request-access-modal/request-access-modal.component';
import { OutcomeDocumentDetailsModalComponent }
  from './components/modals/outcome-document-details-modal/outcome-document-details-modal.component';
import { TeamWorkspaceRequestModalComponent } from
  './components/modals/team-workspace-request-modal/team-workspace-request-modal.component';
import { ExportActionButtonComponent } from './components/shared/export-action-buttom/export-action-button.component';
import { ExportConfirmationModalComponent } from './components/modals/export-confirmation-modal/export-confirmation-modal.component';

@NgModule({
  declarations: [
    TranslatePipe,
    RequestAccessModalComponent,
    OutcomeDocumentDetailsModalComponent,
    TeamWorkspaceRequestModalComponent,
    ExportActionButtonComponent,
    ExportConfirmationModalComponent
  ],
  imports: [
    CommonModule,
    WcgAngularModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    Ng2FlatpickrModule
  ],
  providers: [
    TranslatePipe
  ],
  exports: [
    TranslatePipe,
    CommonModule,
    WcgAngularModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    Ng2FlatpickrModule,
    RequestAccessModalComponent,
    OutcomeDocumentDetailsModalComponent,
    TeamWorkspaceRequestModalComponent,
    ExportActionButtonComponent,
    ExportConfirmationModalComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule { }
