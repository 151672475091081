/* eslint-disable max-lines */
import { Formio, FormioBaseComponent, FormioUtils } from 'angular-formio';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { ApiService } from '../../services/api.service';
import { AvailableForms, Countries, Country, States } from '../../interfaces/common';
import { BehaviorSubject } from 'rxjs';
import { ContactOrganization } from '../../interfaces/user';
import {
  CountryCodes,
  FlowType,
  FlowTypeReadable,
  AnswerOptions,
  Forms,
  regexRules
} from '../../common/collections';
import { DashboardService } from '../dashboard/dashboard.service';
import {
  FileUploadGridRecordMap,
  ReviewTypes,
  SubmissionFindStudy,
  SubmissionMetadata,
  SubmissionType,
  Acknowledgements
} from '../../interfaces/submissions';
import { FormType } from '../../admin/shared/model/submission-type';
import { SubmissionStudiesGrid, StudiesGridNewSubmission, StudySitesSubmissionMap } from '../../interfaces/studies';
import { SubmissionCreateModel, SubmissionDraftCreateModel, SubmissionStudy, SubmissionSelectedSite } from '../../models/submission.model';
import { ToastService } from '../../services/toast.service';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { UtilService } from '../../services/util.service';
import {
  protocolInfo2,
  protocolInfoSite,
  piForm,
  acknowledgements,
  translationRequestForm,
  translationRequestSubmissionDetails,
  whatChangesAreYouMaking,
  promptlyReportableDisclosure,
  siteClosureForm
} from '../../common/formio-map';
import { UploadParams } from 'angular-azure-blob-service';

// 10 MB Block size
const AZURE_BLOB_SIZE = 10485760;
const DEFAULT_DAYS = 6;
const EMPTY_STUDY: SubmissionFindStudy = {
  name: '',
  protocol: '',
  sponsor: '',
  id: '',
  status: ''
};
const PDF_FILENAME_DATE_FORMAT = 'DDMMMYYYY';
const SAVE_INTERVAL = 15000;

@Injectable({
  providedIn: 'root'
})
export class SubmissionWorkflowService {
  public availableForms = new BehaviorSubject<AvailableForms>([]);
  public azureBlobSize = AZURE_BLOB_SIZE;
  public canadaProvinces = new BehaviorSubject<States>([]);
  public consolidatedPdf = new BehaviorSubject<any>({});
  public consolidatedPdfDisabled = false;
  public countries = new BehaviorSubject<Countries>([]);
  public defaultEstimatedOutcomeDaysFromToday = DEFAULT_DAYS;
  public documentModels = new BehaviorSubject<any>([]);
  public documentUploadSubmissionGuid = new BehaviorSubject<string>('');
  public files$: BehaviorSubject<Array<File>> = new BehaviorSubject<Array<File>>([]);
  public filesData$: BehaviorSubject<Array<FileUploadGridRecordMap>> = new BehaviorSubject<Array<FileUploadGridRecordMap>>([]);
  public formData = new BehaviorSubject<any>({});
  public formId = new BehaviorSubject<string>(null);
  public formInfo = new BehaviorSubject<any>({});
  public formName = new BehaviorSubject<string>(null);
  public formPages = new BehaviorSubject<any>([]);
  public formReady = new BehaviorSubject<boolean>(false);
  public formSubmissionId = new BehaviorSubject<string>('');
  public investigators = new BehaviorSubject<string>(null);
  public irbOrganization = new BehaviorSubject<ContactOrganization>(null);
  public pdfFilenameDateFormat = PDF_FILENAME_DATE_FORMAT;
  public pdfFormDocumentIndexes = new BehaviorSubject<Array<string>>([]);
  public pdfFormDocuments = new BehaviorSubject<any>({});
  public reviewDisabled = true;
  public saved = new BehaviorSubject<boolean>(false);
  public saveInterval = SAVE_INTERVAL;
  public saveState = new BehaviorSubject<string>('');
  public saveSubmissionClicked = new BehaviorSubject<boolean>(false);
  public separatePdfs = new BehaviorSubject<any>({});
  public separatePdfsDisabled = false;
  public showPiModal = new BehaviorSubject<boolean>(false);
  public showWorkflowCancel = new BehaviorSubject<boolean>(false);
  public showSubmissionDetails = new BehaviorSubject<boolean>(false);
  public siteRequired = new BehaviorSubject<boolean>(true);
  public siteSingleSelect = new BehaviorSubject<boolean>(false);
  public siteSelectionResponse= new BehaviorSubject<string>('');
  public statusComplete = {
    status: 'submissionStatus.complete',
    statusClass: 'complete'
  };

  public statusInProgress = {
    status: 'submissionStatus.partiallyComplete',
    statusClass: 'partially-complete'
  };

  public statusNotStarted = {
    status: 'submissionStatus.notStarted',
    statusClass: 'not-started'
  };

  public submissionDocumentStatus = new BehaviorSubject<any>({
    status: 'submissionStatus.notStarted',
    statusClass: 'not-started'
  });

  public sponsor = new BehaviorSubject<string>('');
  public studyName = new BehaviorSubject<string>('');
  public submissionDocumentTypes = new BehaviorSubject<any>([]);
  public submissionDraftId = new BehaviorSubject<string>(null);
  public submissionFindStudy = new BehaviorSubject<SubmissionFindStudy>(EMPTY_STUDY);
  public submissionFlow = new BehaviorSubject<string>('');
  public submissionFormType = new BehaviorSubject<any>('');
  public submissionMetadata = new BehaviorSubject<SubmissionMetadata>({});
  public submissionRegions = new BehaviorSubject<any>([]);
  public submissionReviewTypes = new BehaviorSubject<ReviewTypes>([]);
  public submissionSponsorProtocolId = new BehaviorSubject<string>('');
  public submissionName = new BehaviorSubject<string>('');
  public submissionStudySites = new BehaviorSubject<Array<SubmissionSelectedSite>>([]);
  public submissionStudySitesTotal = new BehaviorSubject<number>(0);
  public submissionTypeGroup = new BehaviorSubject<SubmissionType>(null);
  public submissionTypeGroups = new BehaviorSubject<any>([]);
  public submissionTypeId = new BehaviorSubject<string>('');
  public submissionTypeValue = new BehaviorSubject<string>('');
  public successObject = new BehaviorSubject<any>({});
  public unitedStates = new BehaviorSubject<States>([]);
  public saveDraftInProgress = false;
  public defaultDocumentTypeId = new BehaviorSubject<any>(null);
  public searchTerm = new BehaviorSubject<string>('');
  public changeInResearch = null;
  public financialInterest = null;
  public initialReview = null;
  public promptlyReportable = null;
  public recruitmentBonus = null;
  public siteClosure = null;
  public translationRequest = null;
  public typeOfSubmission = null;

  public originUrl = new BehaviorSubject<string>('');
  public excluded = null;
  public canSetSiteSingleSelect = new BehaviorSubject<boolean>(false);
  public canCreateFullStudySubmission = new BehaviorSubject<boolean>(false);
  public submissionStudySiteRegion = new Array<string>('');

  constructor(
    public router: Router,
    private apiService: ApiService,
    private dashboardService: DashboardService,
    private toastService: ToastService,
    private translatePipe: TranslatePipe,
    private utilService: UtilService
  ) {
    this.separatePdfs.subscribe(() => {
      this.separatePdfsDisabled = this.separatePdfsNotDownloaded();
    });
  }

  public static determinReadOnlyFields(data: SubmissionStudiesGrid): Array<boolean> {
    const returnValue = [];
    data.records.forEach((element) => {
      if (element.status.value === 'Closed') {
        returnValue[element.id] = true;
      }
      else {
        returnValue[element.id] = false;
      }
    });

    return returnValue;
  }

  public isStudySelected(): boolean {
    return this.submissionFindStudy.value &&
      this.submissionFindStudy.value.id !== '';
  }

  public isSiteSelected(): boolean {
    return this.submissionStudySites.value && this.submissionStudySites.value.length > 0;
  }

  public isRegionSelected(): boolean {
    return this.submissionRegions.value && this.submissionRegions.value.length > 0;
  }

  public isSiteOriginSubmission(): boolean {
    return this.originUrl.value.indexOf('sites/SIT-') > 0;
  }

  public isStudyOriginSubmission(): boolean {
    return this.originUrl.value.indexOf('studies/STU-') > 0;
  }

  public addAlphanumericRegexPattern(formIOForm: FormioBaseComponent['formio']): void {
    FormioUtils.eachComponent(formIOForm.components, (component) => {
      // for nested forms
      if (component.type === 'form') {
        FormioUtils.eachComponent(component.formObj.components, (elem) => {
          if (typeof elem.tags !== 'undefined') {
            this.applyValidation(elem);
          }
        });
      }
      // for components
      else if (typeof component.component.tags !== 'undefined') {
        this.applyValidation(component.component);
      }
    });
  }

  public setNestedAlphaNumericRegexPattern(changed: any): void {
    if (changed.component.type === 'textarea' || changed.component.type === 'textfield') {
      if (typeof changed.component.tags !== 'undefined') {
        this.applyValidation(changed.component);
      }
    }
  }

  public applyValidation(component: any): void {
    const [validationTag] = component.tags.filter((tag: any) => regexRules.map((rule) => rule.tag).includes(tag));
    if (validationTag) {
      component.validate.custom = this.getCustomValidation(validationTag);
    }
  }

  public addFiles(file: File): void {
    const newFiles = this.files$.getValue();

    const idx = this.filesData$.getValue().findIndex((existingfiles) => existingfiles.document.fileName === file.name);

    if (idx < 0) {
      newFiles.unshift(file);
    }
    else {
      newFiles.splice(idx, 1);
      newFiles.unshift(file);
    }

    this.files$.next(newFiles);
    this.setDocumentStatus();
  }

  public addFilesData(files: FileUploadGridRecordMap[]): void {
    const newFiles = this.filesData$.getValue();

    for (const file of files) {
      const idx = this.filesData$.getValue().findIndex((existingfiles) => existingfiles.document.fileName === file.document.fileName);

      if (idx < 0) {
        newFiles.unshift(file);
      }
      else {
        newFiles[idx] = file;
      }
    }

    this.filesData$.next(newFiles);
  }

  public separatePdfsNotDownloaded(): boolean {
    return Boolean(
      this.pdfFormDocumentIndexes.value.find((value) => this.utilService.isEmptyObject(this.separatePdfs.value[value]))
    );
  }

  public closeWorkflow(): void {
    this.showWorkflowCancel.next(true);
  }

  public disabledCondition(): any {
    const submissionFlow = this.submissionFlow?.value || this.formInfo.value.submissionFlow;
    const newStudySubmissionType = submissionFlow === 'addStudy';
    const newStudyData = this.formInfo.value[protocolInfo2.form];
    const newSiteData = this.formInfo.value[protocolInfoSite.form];

    const checkEmpty = (value): boolean => {
      const theValue = typeof value === 'string' ? value.toString().trim() : '';

      return theValue === '';
    };

    if (!newStudyData && !newSiteData) {
      return true;
    }

    if (newStudySubmissionType) {
      const requiredValues = [
        newStudyData?.data?.submissionName,
        newStudyData?.data?.studyName,
        newStudyData?.data?.sponsor,
        newStudyData?.data?.sponsorsProtocolId
      ];

      return requiredValues.some(checkEmpty);
    }

    const { submissionName } = newSiteData.data;

    return [submissionName].some(checkEmpty);
  }

  public getAllFormioFormsToDisplay(): void {
    this.apiService.getAllFormioFormsToDisplay().subscribe((res) => {
      const availableForms = res.map((form) => ({
        title: form.data.formTitle,
        url: form.data.formName,
        pdfFileName: form.data.pdfFileName
      }));

      this.availableForms.next(availableForms);
    });
  }

  public getCustomValidation = (tag: string): string => {
    const rules = regexRules.filter((rule) => rule.tag === tag);
    const [regexRule] = rules;
    const customValidation = `
    var rule = /${regexRule.rule}/;

    if (rule.test(input)) {
      valid = true;
    } else {
      valid = "${regexRule.message}";
    }
    `;

    return customValidation;
  }

  public getAzureConfig = (sasToken: string, storageFilePath: string): UploadParams => ({
    sas: sasToken.substring(sasToken.indexOf('?'), sasToken.length),
    storageAccount: sasToken.split('/')[2],
    containerName: storageFilePath
  })

  public getContactCountries(): void {
    this.apiService.getContactCountries()
      .subscribe((res) => {
        this.countries.next(res);

        const usa = this.countries.value.find((country) => country.alpha3Code === CountryCodes.UNITED_STATES);
        const canada = this.countries.value.find((country) => country.alpha3Code === CountryCodes.CANADA);

        this.apiService.getContactStates(usa.id.toString())
          .subscribe((response) => {
            this.unitedStates.next(response);
          }, (err) => {
            this.toastService.add([{
              closable: true,
              id: 'getReviewTypes',
              message: `Could not load countries: ${err.details.message}`,
              timeout: 5000,
              variant: 'error'
            }]);
          });

        this.apiService.getContactStates(canada.id.toString())
          .subscribe((response) => {
            this.canadaProvinces.next(response);
          }, (err) => {
            this.toastService.add([{
              closable: true,
              id: 'getReviewTypes',
              message: `Could not load countries: ${err.details.message}`,
              timeout: 5000,
              variant: 'error'
            }]);
          });
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'getReviewTypes',
          message: `Could not load countries: ${err.details.error.message}`,
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  public getFlowTypeIdByName = (name: string): number => FlowType[name?.replace(' ', '')];

  public getFlowTypeNameById = (id: number): string => FlowType[id];

  public getFlowTypeNameReadableById = (id: number): string => FlowTypeReadable[id];

  public getSubmissionGroupByFlowType(flowType: number): SubmissionType {
    return this.submissionTypeGroups.value?.find((group) => group.value?.replace(' ', '') === this.getFlowTypeNameById(flowType));
  }

  public getSubmissionFlowByFlowTypeId = (flowType: number): string => {
    if (flowType === 1) {
      return 'addStudy';
    }
    else if (flowType === 2) {
      return 'addPI';
    }

    return 'addDocuments';
  }

  public leaveWorkflow(): void {
    this.showWorkflowCancel.next(false);
    this.resetSubmissionData();
    setTimeout(() => this.router.navigate(['/dashboard']), 0);
  }

  public mapStudiesGridData(data: SubmissionStudiesGrid): Array<StudiesGridNewSubmission> {
    this.excluded = SubmissionWorkflowService.determinReadOnlyFields(data);

    return data.records.map((record, index) => ({
      accessLevel: record.accessLevel,
      nameData: record.name,
      sponsorData: record.sponsor,
      protocolData: record.protocolNumber,
      id: record.id,
      nameLink:
      /* eslint-disable max-len */
        `<wcg-radio value="${index}" name="study-selection" attr-id="option-${index}" disabled="${this.excluded[record.id]}">
          ${this.excluded[record.id] ? this.utilService.addTableCellWrapperReadOnly(record.name) : this.utilService.addTableCellWrapper(record.name)}
        </wcg-radio>`,
      /* eslint-enable max-len */
      sponsor: `<label for="option-${index}">${this.utilService.addTableCellWrapper(record.sponsor)}</label>`,
      protocolNumber: `<label for="option-${index}">${this.utilService.addTableCellWrapper(record.protocolNumber)}</label>`,
      irbTrackingNumber: `<label for="option-${index}">${this.utilService.addTableCellWrapper(record.irbTrackingNumber)}</label>`,
      status: `<label for="option-${index}">${this.utilService.addTableCellWrapper(record.status.value)}</label>`
    }));
  }

  public mapSitesDataTable(data: Array<StudySitesSubmissionMap>): Array<StudySitesSubmissionMap> {
    return data.map((record) => ({
      countryName: record.countryName,
      countryNameWrap: this.utilService.addTableCellWrapper(record.countryName),
      expirationDate: record.expirationDate,
      firstName: record.firstName,
      id: record.id,
      initialApprovalDate: record.initialApprovalDate,
      institutionalTrackingNumber: record.institutionalTrackingNumber,
      institutionalTrackingNumberWrap: this.utilService.addTableCellWrapper(record.institutionalTrackingNumber),
      investigator: record.investigator,
      irbTrackingNumber: record.irbTrackingNumber,
      isMedicalOrInstitutional: record.isMedicalOrInstitutional,
      lastName: record.lastName,
      lastReviewDate: record.lastReviewDate,
      piNameWrap: this.siteSingleSelect.value ?
        this.addSingleSelectWrapper(record.investigator, record.id) :
        this.utilService.addTableCellWrapper(record.investigator),
      piOrganizationName: record.piOrganizationName,
      piOrganizationNameWrap: this.utilService.addTableCellWrapper(record.piOrganizationName),
      protocolNumber: record.protocolNumber,
      selected: false,
      sponsor: record.sponsor,
      status: record.status,
      study: record.study,
      userId: record.userId
    }));
  }

  public removeFile(file: FileUploadGridRecordMap): void {
    const newFiles = [...this.files$.value].filter((existingFile) => existingFile.name !== file.file.name);

    this.files$.next(newFiles);

    this.removeFileData(file);
  }

  public removeFileData(file: FileUploadGridRecordMap): void {
    const newFiles = [...this.filesData$.value]
      .filter((existingFile) => {
        if (file.file.name) {
          return existingFile.document.fileName !== file.file.name;
        }

        return existingFile.document.fileName !== file.document.fileName;
      });

    this.filesData$.next(newFiles);
  }

  public resetSaveStatus(): void {
    this.saved.next(false);
    this.saveState.next('');
  }

  public resetSubmissionData(): void {
    this.consolidatedPdf.next({});
    this.documentModels.next([]);
    this.documentUploadSubmissionGuid.next('');
    this.files$.next([]);
    this.filesData$.next([]);
    this.formData.next({});
    this.formId.next(null);
    this.formInfo.next({});
    this.formName.next('');
    this.formPages.next([]);
    this.formReady.next(false);
    this.formSubmissionId.next('');
    this.irbOrganization.next(null);
    this.pdfFormDocumentIndexes.next([]);
    this.pdfFormDocuments.next({});
    this.resetSaveStatus();
    this.reviewDisabled = false;
    this.saveDraftInProgress = false;
    this.saveSubmissionClicked.next(false);
    this.separatePdfs.next({});
    this.showWorkflowCancel.next(false);
    this.showSubmissionDetails.next(false);
    this.siteRequired.next(false);
    this.siteSingleSelect.next(false);
    this.sponsor.next('');
    this.studyName.next('');
    this.submissionDocumentTypes.next([]);
    this.submissionFindStudy.next(EMPTY_STUDY);
    this.submissionFlow.next('');
    this.submissionFormType.next('');
    this.submissionMetadata.next({});
    this.submissionName.next('');
    this.submissionRegions.next([]);
    this.submissionReviewTypes.next([]);
    this.countries.next([]);
    this.canadaProvinces.next([]);
    this.unitedStates.next([]);
    this.submissionSponsorProtocolId.next('');
    this.submissionStudySites.next([]);
    this.submissionStudySitesTotal.next(0);
    this.submissionTypeGroup.next(null);
    this.submissionTypeGroups.next([]);
    this.submissionTypeId.next('');
    this.submissionTypeValue.next('');
    this.successObject.next({});
    this.resetAcknowledgementsData();
    this.siteSelectionResponse.next('');
    this.resetSubmissionDetailsData();
  }

  public saveChanges(): void {
    if (!this.saveDraftInProgress) {
      this.saveDraftInProgress = true;
      const submissionDraftData = this.setSubmissionDraftModel();
      if (this.formSubmissionId.value) {
        submissionDraftData.id = this.formSubmissionId.value;
        this.submissionDraftId.next(this.formSubmissionId.value);
      }

      if (this.formSubmissionId.value) {
        this.saveState.next(this.translatePipe.transform('submissionWorkflow.autosaveSaving'));
      }

      if (!this.saveSubmissionClicked.value) {
        this.apiService.postDraftSubmission(submissionDraftData)
          .subscribe((res) => {
            if (res.id) {
              this.setFormSubmissionId(res.id);
              this.saveState.next(this.translatePipe.transform('submissionWorkflow.autosaveAutosaved'));
              this.saved.next(true);
            }
            this.saveDraftInProgress = false;
          }, () => {
            this.saveState.next(this.translatePipe.transform('submissionWorkflow.autosaveError'));
            this.saveDraftInProgress = false;
          });
      }
    }
  }

  public saveDraft(): void {
    const submissionDraftData = this.setSubmissionDraftModel();
    submissionDraftData.id = this.formSubmissionId.value;

    this.apiService.postDraftSubmission(submissionDraftData)
      .subscribe(() => {
        this.toastService.add([{
          closable: true,
          id: 'postCreateSubmissionDraftSuccess',
          message: 'Submission Saved',
          timeout: 5000,
          variant: 'success'
        }]);

        this.dashboardService.postDrafts();

        this.leaveWorkflow();
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'postCreateSubmissionDraftError',
          message: `Data could not be saved: ${err.details.error.message}`,
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  public setDocumentStatus(): void {
    this.submissionDocumentStatus.next(this.setSubmissionDocumentsStatus());
    this.submissionDocErrors();
  }

  public setFormIOHeaders = (env): void => {
    const formIoHeaders = {
      priority: 0,
      request: (requestArgs): void => {
        requestArgs.opts.headers = {
          Accept: 'application/json',
          'x-token': env.formio.apiKey,
          'Content-Type': 'application/json'
        };
      }
    };

    Formio.registerPlugin(formIoHeaders, 'request');
  }

  public setFormSubmissionId(id: string): void {
    this.formSubmissionId.next(id);
  }

  public setFormName(submissionType: string): void {
    const selectedForm = this.availableForms.value.filter((form) => form.title.toLowerCase() === submissionType.toLowerCase());

    this.formId.next(selectedForm[0]?.formId);
    this.formName.next(selectedForm[0]?.url);
  }

  public setSubmissionDocumentsStatus(): any {
    const documents = this.filesData$.value;
    const documentTypes = documents.map((doc) => doc.document.type);
    const validDocuments = documentTypes.filter((docType) => docType.value);

    if (documents && documents.length > 0) {
      if (documents.length === validDocuments.length) {
        return this.statusComplete;
      }

      return this.statusInProgress;
    }

    return this.statusNotStarted;
  }

  public setSubmissionDraftModel(): SubmissionDraftCreateModel {
    const submissionDraftData = new SubmissionDraftCreateModel();

    if (this.formInfo?.value?.formSubmissionId) {
      submissionDraftData.id = this.formInfo?.value?.formSubmissionId;
    }

    submissionDraftData.formIOData = JSON.stringify(this.formInfo?.value);
    submissionDraftData.formName = this.formName.value;

    submissionDraftData.submissionType = {
      value: this.submissionTypeValue.value,
      id: parseInt(this.submissionTypeId.value, 10)
    };

    submissionDraftData.study = this.getStudy();
    submissionDraftData.title = this.getSubmissionTitle();
    submissionDraftData.documents = this.filesData$.value.map((doc) => doc.document);
    submissionDraftData.fileUploadGridData = JSON.stringify(this.filesData$.value);
    submissionDraftData.flowType = this.getFlowTypeIdByName(this.submissionTypeGroup.value?.value);
    submissionDraftData.boardReviewTypeIds = this.submissionRegions.value;
    submissionDraftData.formVersion = this.formData?.value?._form?._vid;

    if (this.formInfo.value[piForm.form]) {
      submissionDraftData.principalInvestigator = this.piObject(false);
    }

    if (this.submissionStudySites.value?.length) {
      submissionDraftData.selectedSites = this.submissionStudySites.value;
    }

    if (this.siteSelectionResponse.value?.length > 0) {
      submissionDraftData.siteSelectionResponse = this.siteSelectionResponse.value;
    }

    return submissionDraftData;
  }

  public setSubmissionModel(): SubmissionCreateModel {
    const submissionData = new SubmissionCreateModel();

    submissionData.id = this.formSubmissionId.value;

    submissionData.submissionType = {
      value: this.submissionTypeValue.value,
      id: parseInt(this.submissionTypeId.value, 10)
    };

    submissionData.study = this.getStudy();
    submissionData.title = this.getSubmissionTitle();
    submissionData.documents = this.filesData$.value.map((doc) => doc.document);
    submissionData.submittedDate = moment(new Date()).format();
    submissionData.boardReviewTypeIds = this.submissionRegions.value;
    submissionData.flowType = this.getFlowTypeIdByName(this.submissionTypeGroup.value?.value);
    submissionData.formDefinition = JSON.stringify(this.formData?.value?._form);
    submissionData.submissionData = JSON.stringify(this.formInfo?.value);

    if (this.submissionFlow.value === 'addStudy') {
      submissionData.numberOfDays = DEFAULT_DAYS;
    }

    if (this.formInfo.value[piForm.form]) {
      submissionData.principalInvestigator = this.piObject();
    }

    if (this.submissionStudySites.value?.length) {
      submissionData.selectedSites = this.submissionStudySites.value;
    }

    return submissionData;
  }

  public submissionDocErrors(): void {
    if (this.filesData$.value.length) {
      this.reviewDisabled = this.isDocumentTypeEmpty();
    }
    else {
      this.reviewDisabled = this.submissionFormType.value === FormType[FormType.UploadForm];
    }
  }

  // moved here so that it can be used to generate acknowledgements pdf
  public getAcknowledgementsData(): Acknowledgements {
    const {
      [acknowledgements.fields.recruitmentBonuses]: recruitmentBonuses,
      [acknowledgements.fields.financialDisclosure]: financialDisclosure,
      [translationRequestForm.fields.translatedDocuments]: translatedDocuments
    } = this.formInfo.value;

    this.recruitmentBonus = recruitmentBonuses;
    this.financialInterest = financialDisclosure;
    this.typeOfSubmission = this.getTypeOfSubmission();

    switch (this.formInfo.value?.formType) {
    case Forms.translationRequestForm:
      this.translationRequest = AnswerOptions.yes;
      break;
    case Forms.initialReviewForm:
      this.translationRequest = translatedDocuments;
      this.initialReview = AnswerOptions.yes;
      break;
    case Forms.promptlyReportableForm:
      this.promptlyReportable = AnswerOptions.yes;
      this.hasAcknowledgementPromptlyReportable();
      break;
    case Forms.siteClosureForm:
      this.siteClosure = AnswerOptions.yes;
      this.hasAcknowledgementSiteClosure();
      break;
    case Forms.changeInResearchForm:
      this.changeInResearch = AnswerOptions.yes;
      this.translationRequest = translatedDocuments;
      this.hasAcknowledgementCIR();
      break;
    default:
    }

    return this.handleAcknowedgementData();
  }

  private addressDataPopulated(): boolean {
    const allValues = [
      this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.addressLine1],
      this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.addressLine2],
      this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.postalCode],
      this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.country],
      this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.state],
      this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.city]
    ];

    if (allValues.every((value) => Boolean(value) === false)) {
      return false;
    }

    return true;
  }

  private addressObject(): any {
    return {
      addressLine1: this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.addressLine1] || ' ',
      addressLine2: this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.addressLine2],
      postalCode: this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.postalCode],
      country: this.findCountry(this.getCountryCode()),
      state: this.findState(
        this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.country],
        this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.state] ||
        this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.province]
      ) || '',
      province: this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.stateProvince],
      city: this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.city] || ' '
    };
  }

  private addSingleSelectWrapper(piOrg, siteId): string {
    const piSelect = `<wcg-radio value="${siteId}" name="site-selection" attr-id="option-${siteId}"">
      ${this.utilService.addTableCellWrapper(piOrg)}
    </wcg-radio>`;

    return piSelect;
  }

  private checkAddressRequiredFields(): any {
    const checkAddressRequiredFields = [
      this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.addressLine1],
      this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.city] || '',
      this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.country]
    ];

    if (checkAddressRequiredFields.some((value) => value === '')) {
      if (this.formInfo.value[piForm.form].data?.addressForm) {
        this.formInfo.value[piForm.form].data[piForm.addressForm.form].data[piForm.addressForm.fields.addressLine1] =
          this.getAddressLine1();
        this.formInfo.value[piForm.form].data[piForm.addressForm.form].data[piForm.addressForm.fields.country] = this.getCountryCode();
        this.formInfo.value[piForm.form].data[piForm.addressForm.form].data[piForm.addressForm.fields.city] =
          this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.city] ||
          ' ';
      }
    }
  }

  private findCountry(alpha3Code: string): Country {
    return this.countries.value.find((country) => country.alpha3Code === alpha3Code);
  }

  private findState(countryCode: string, stateCode: string): any {
    let states;
    if (countryCode === CountryCodes.UNITED_STATES) {
      states = this.unitedStates;
    }
    else if (countryCode === CountryCodes.CANADA) {
      states = this.canadaProvinces;
    }
    else {
      return '';
    }

    return states.value.find((state) => state.code === stateCode);
  }

  private getAddressLine1(): string {
    return this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.addressLine1] || ' ';
  }

  private getCountryCode(): string {
    const codeLength = 3;
    const countryCode = this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data[piForm.addressForm.fields.country];

    if (countryCode?.length === codeLength) {
      return countryCode;
    }

    return CountryCodes.UNITED_STATES;
  }

  private getStudy(): SubmissionStudy {
    return {
      name: this.formInfo.value[protocolInfo2.form]?.data[protocolInfo2.fields.studyName] || this.submissionFindStudy.value.name,
      protocolNumber: this.formInfo.value[protocolInfo2.form]?.data[protocolInfo2.fields.sponsorsProtocolId] ||
        this.submissionFindStudy.value.protocol,
      sponsor: this.formInfo.value[protocolInfo2.form]?.data[protocolInfo2.fields.sponsor] ||
        this.submissionFindStudy.value.sponsor,
      irbOrganization: this.irbOrganization.value,
      id: this.submissionFindStudy.value.id
    };
  }

  private getSubmissionTitle(): string {
    return this.formInfo.value[protocolInfo2.form]?.data[protocolInfo2.fields.submissionName] ||
      this.formInfo.value[protocolInfoSite.form]?.data[protocolInfoSite.fields.submissionName];
  }

  private isDocumentTypeEmpty(): boolean {
    const documentTypes = this.filesData$.value.map((document) => document?.document?.type);
    let isEmpty = false;

    documentTypes.forEach((type) => {
      if (this.utilService.isEmptyObject(type)) {
        isEmpty = true;
      }
    });

    return isEmpty;
  }

  // eslint-disable-next-line complexity
  private piObject(checkRequired: boolean = true): any {
    const { email, phone } = this.formInfo.value[piForm.form].data;

    if (this.addressDataPopulated() && checkRequired) {
      this.checkAddressRequiredFields();
    }

    return {
      address: this.addressObject(),
      email,
      phone,
      firstName: this.formInfo.value[piForm.form].data?.[piForm.fields.first] || ' ',
      lastName: this.formInfo.value[piForm.form].data?.[piForm.fields.last] || ' ',
      middleName: this.formInfo.value[piForm.form].data?.[piForm.fields.middleName] || ' ',
      degrees: this.formInfo.value[piForm.form].data?.[piForm.fields.degrees] || ' ',
      suffix: this.formInfo.value[piForm.form].data?.[piForm.fields.suffix] || ' ',
      organization: {
        name: (this.formInfo.value[piForm.form].data[piForm.addressForm.form]?.data
        [piForm.addressForm.fields.companyInstitutionOrganization] || '').trim(),
        organizationType: {
          id: 1,
          value: 'site'
        }
      }
    };
  }

  private handleAcknowedgementData(): Acknowledgements {
    const {
      setupPiPleaseSelectYesOrNo,
      populationTransferForm
    } = this.formInfo.value;

    const { theResearchInvolvesSelectAllThatApply } = populationTransferForm?.data || {};

    const {
      nonViableNeonates,
      neonatesOfUncertainViability,
      subjectsWhoArePrisoners,
      subjectsWhoArePregnantAtEnrollment,
      subjectsWhoBecomePregnantWhileOnStudy
    } = theResearchInvolvesSelectAllThatApply || {};

    return {
      submissionTypeValue: this.submissionTypeValue.value,
      siteOnlySubmission: this.submissionFlow.value,
      newStudyPi: setupPiPleaseSelectYesOrNo,
      recruitmentBonus: this.recruitmentBonus,
      financialInterest: this.financialInterest,
      translationRequest: this.translationRequest,
      nonViableNeonates: nonViableNeonates,
      neonatesViability: neonatesOfUncertainViability,
      prisonerSubjects: subjectsWhoArePrisoners,
      pregnantSubjects: subjectsWhoArePregnantAtEnrollment || subjectsWhoBecomePregnantWhileOnStudy,
      promptlyReportable: this.promptlyReportable,
      initialReview: this.initialReview,
      siteClosure: this.siteClosure,
      changeInResearch: this.changeInResearch,
      typeOfSubmission: this.typeOfSubmission
    };
  }

  private hasAcknowledgementCIR(): void {
    const { [whatChangesAreYouMaking.form]: whatChanges = {} } = this.formInfo.value;
    const {
      [whatChangesAreYouMaking.fields.recruitmentBonus]: recruitmentBonus
    } = whatChanges;

    if (recruitmentBonus) {
      this.recruitmentBonus = AnswerOptions.yes;
    }
    if (whatChanges && whatChanges[Forms.financialInterestDisclosure] === true) {
      this.financialInterest = AnswerOptions.yes;
    }
    if (this.hasAcknowledgementTranslation(whatChangesAreYouMaking.fields.translationRequest)) {
      this.translationRequest = AnswerOptions.yes;
    }
  }

  private hasAcknowledgementPromptlyReportable(): void {
    const {
      [promptlyReportableDisclosure.fields.problemCategory]: whatCategory = {},
      [promptlyReportableDisclosure.fields.consentChange]: consentFormChange = {}
    } = this.formInfo.value;

    if (whatCategory === Forms.changeInFinancialInterestDisclosure) {
      this.financialInterest = AnswerOptions.yes;
    }
    if (consentFormChange === AnswerOptions.yes) {
      this.changeInResearch = AnswerOptions.yes;
    }
  }

  private hasAcknowledgementSiteClosure(): void {
    if (this.hasSiteClosureForms()) {
      this.promptlyReportable = AnswerOptions.yes;
    }
  }

  private hasAcknowledgementTranslation(value): boolean {
    const { [whatChangesAreYouMaking.form]: form } = this.formInfo.value;

    return form && form[value];
  }

  private hasSiteClosureForms(): boolean {
    const {
      [siteClosureForm.fields.protocolConsent]: changeToProtocol,
      [siteClosureForm.fields.policyReporting]: reporting
    } = this.formInfo.value;

    return changeToProtocol === AnswerOptions.yes || reporting === AnswerOptions.yes;
  }

  private resetAcknowledgementsData(): void {
    this.changeInResearch = null;
    this.financialInterest = null;
    this.initialReview = null;
    this.promptlyReportable = null;
    this.recruitmentBonus = null;
    this.siteClosure = null;
    this.translationRequest = null;
    this.typeOfSubmission = null;
  }

  private resetSubmissionDetailsData(): void {
    this.originUrl.next('');
    this.canSetSiteSingleSelect.next(false);
    this.submissionStudySiteRegion = new Array<string>('');
    this.canCreateFullStudySubmission.next(false);
  }

  private getTypeOfSubmission(): any {
    const {
      translationRequestSubmissionNoPi,
      translatedDocuments,
      translationRequestSubmission
    } = this.formInfo.value;

    const translationRequestData = translationRequestSubmissionNoPi?.data ||
      translatedDocuments?.data ||
      translationRequestSubmission?.data;

    const { [translationRequestSubmissionDetails.fields.typeOfSubmission]: typeOfSubmission } =
      translationRequestData?.translationRequestSubmissionDetails?.data || {};

    return typeOfSubmission;
  }
}
