import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Apis, BackOfficeBaseUrl } from 'src/app/common/apis';
import { OutcomeDocumentsDetailsMap, OutcomeDetails } from 'src/app/interfaces/submissions';
import { UtilService } from 'src/app/services/util.service';

@Injectable({
  providedIn: 'root'
})
export class OutcomeDocumentDetailsModalService {
  public endDate = new BehaviorSubject<Date>(null);
  public startDate = new BehaviorSubject<Date>(null);
  public searchTerm = new BehaviorSubject<string>('');

  constructor(private utilService: UtilService) { }

  public mapOutcomeSitesData(data: Array<OutcomeDetails>, fileName: string): Array<OutcomeDocumentsDetailsMap> {
    return data.map((record) => {
      const link = `${BackOfficeBaseUrl}${Apis.OutcomeDocumentDownload}/${record.irbDocumentId}`;
      const piName = record.piFullName ? record.piFullName : 'Study';

      return this.getOutcomeDocumentMapped(record, link, piName, fileName);
    });
  }

  public mapAllOutcomeSitesData(data: Array<OutcomeDetails>): Array<OutcomeDocumentsDetailsMap> {
    return data.map((record) => {
      const link = `${BackOfficeBaseUrl}${Apis.OutcomeDocumentDownload}/${record.irbDocumentId}`;
      const piName = record.piFullName ? record.piFullName : 'Study';

      return this.getOutcomeDocumentMapped(record, link, piName, record.fileName);
    });
  }

  private getOutcomeDocumentMapped(record: OutcomeDetails, link: string, piName: string, fileName: string): OutcomeDocumentsDetailsMap {
    return {
      piFullName: this.formatTableCell(piName),
      piOrganizationName: this.formatTableCell(record.piOrganizationName),
      institutionalTrackingNumber: this.formatTableCell(record.institutionalTrackingNumber),
      countryName: this.formatTableCell(record.countryName),
      reviewedDate: record.transmittedDate === null && record.reviewedDate ? this.utilService.highlightDateFields(
        record.reviewedDate, this.startDate.value, this.endDate.value
      ) : this.utilService.addTableCellWrapper(this.utilService.formatDate(record.reviewedDate)),
      transmittedDate: record.transmittedDate ? this.utilService.highlightDateFields(
        record.transmittedDate, this.startDate.value, this.endDate.value
      ) : this.utilService.addTableCellWrapper(this.utilService.formatDate(record.transmittedDate)),
      link: link,
      documentId: record.documentId,
      fileName: record.piFullName ? `${record.piFullName} - ${fileName}` : fileName,
      documentName: fileName
    };
  }

  private formatTableCell(recordParameter): string {
    return this.utilService.addTableCellWrapperWithSearchTerms(recordParameter, this.searchTerm.value);
  }
}
