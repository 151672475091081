/* eslint-disable max-lines */
/* eslint-disable quotes */
/* eslint-disable prefer-template */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
/* eslint-disable max-lines, complexity*/
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Component, OnInit, ViewChild } from '@angular/core';
import { TableHeader } from 'src/app/interfaces/components';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { UtilService } from 'src/app/services/util.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { BaseComponent } from 'src/app/components/base/base.component';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { flatPickrDateFormat, AdminSubmissionsSortOptions, MAX_LENGTH, SubmissionSources, regexRules, RegexTags } from 'src/app/common/collections';
import { takeUntil } from 'rxjs/operators';
import { SubmissionAdminService } from '../../shared/services/submission-admin.service';
import { AdminSubmissionGrid } from '../../shared/model/submissionAdminInfo';
import { IdValueModel } from '../../shared/model/sharedmodels';
import { GridOptionsModel, FilterOptionModel, SortOptionModel } from '../../shared/model/grid.model';
import { ToastService } from 'src/app/services/toast.service';
import { GridExportType } from 'src/app/common/reportingCollections';

@Component({
  selector: 'app-admin-submission',
  templateUrl: './admin-submission.component.html',
  styleUrls: ['./admin-submission.component.scss']
})

export class AdminSubmissionComponent extends BaseComponent implements OnInit {
  headers: Array<TableHeader>;
  tableData: Array<any>;
  loading: boolean;
  currentPage = 1;
  currentPageSize = 50;
  totalRecords: number;
  showTable: boolean;
  disabledButton = true;
  loaded = false;
  submissionTypeFilter: IdValueModel[] = [];
  submissionSource = new FormControl('');
  submissionsForm: FormGroup;

  readonly exportType: GridExportType = GridExportType.ManageSubmissions;
  gridFilters: any

  @ViewChild('startDatePicker') startDatePicker;
  @ViewChild('endDatePicker') endDatePicker;

  startDateFilter = new FormControl('');
  endDateFilter = new FormControl('');

  datePickerOptions: FlatpickrOptions = {
    dateFormat: flatPickrDateFormat
  };

  options = {
    manualPaginationAndSort: true,
    rowPerPage: 50
  };

  gridOptionsModel: GridOptionsModel = {
    page: 1,
    pageSize: 50,
    sortOptions: [],
    filterOptions: []
  }

  currentSort: Array<SortOptionModel> = [{
    field: AdminSubmissionsSortOptions.submittedDate,
    isDescending: true
  }]

  submissionSourceViewValues = SubmissionSources;
  constructor(public utilService: UtilService,
    private translatePipe: TranslatePipe,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private submissionAdminService: SubmissionAdminService) {
    super();
  }

  ngOnInit(): void {
    this.showTable = false;
    this.headers = this.getHeaders();
    this.initializeForm();
    this.submissionAdminService.getSubmissionTypes()
      .subscribe((data) => {
        this.submissionTypeFilter = data;
      });
    this.submissionsForm.controls.startDateFilter.valueChanges
      .subscribe((val) => {
        [this.endDatePicker.flatpickr.config.minDate] = val;
      });
    this.submissionsForm.controls.endDateFilter.valueChanges
      .subscribe((val) => {
        [this.startDatePicker.flatpickr.config.maxDate] = val;
      });
    this.submissionsForm.valueChanges.subscribe(() => {
      this.disabledButton = this.getCurrentFilters().length === 0 || this.verifyFilterPattern();
      this.showTable = this.showTable && this.getCurrentFilters().length > 0;
    });
  }

  public verifyFilterPattern(): boolean {
    let invalidPattern = false;
    for (const element in this.submissionsForm.controls) {
      if (this.submissionsForm.controls[element].hasError('maxlength')) {
        invalidPattern = true;
      }
    }
    if (this.submissionsForm.controls.confirmationId.hasError('pattern')) {
      invalidPattern = true;
    }

    return invalidPattern;
  }

  public initializeForm(): void {
    const rules = regexRules.filter((rule) => rule.tag === RegexTags.NumericRegex);
    const [regexRule] = rules;
    this.submissionsForm = this.formBuilder.group({
      irbTrackingID: new FormControl(null, this.addValidators(MAX_LENGTH, null)),
      submitterName: new FormControl(null, this.addValidators(MAX_LENGTH, null)),
      submitterCompanyName: new FormControl(null, this.addValidators(MAX_LENGTH, null)),
      sponsor: new FormControl(null, this.addValidators(MAX_LENGTH, null)),
      piName: new FormControl(null, this.addValidators(MAX_LENGTH, null)),
      envelopeId: new FormControl(null, this.addValidators(MAX_LENGTH, null)),
      confirmationId: new FormControl(null, this.addValidators(MAX_LENGTH, regexRule.rule)),
      submissionName: new FormControl(null, this.addValidators(MAX_LENGTH, null)),
      specialInstructions: new FormControl(null, this.addValidators(MAX_LENGTH, null)),
      submissionType: new FormControl(null),
      submissionSource: new FormControl(null),
      startDateFilter: new FormControl(null),
      endDateFilter: new FormControl(null)
    });
  }

  public getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('adminSubmissions.submitted'), 'submittedDate', true),
      this.utilService.createTableHeader(this.translatePipe.transform('adminSubmissions.confirmation'), 'confirmationId', true),
      this.utilService.createTableHeader(this.translatePipe.transform('adminSubmissions.envelope'), 'envelopeId', true),
      this.utilService.createTableHeader(this.translatePipe.transform('adminSubmissions.type'), 'submissionType', true),
      this.utilService.createTableHeader(this.translatePipe.transform('adminSubmissions.irbTracking'), 'irbTrackingId', true),
      this.utilService.createTableHeader(this.translatePipe.transform('adminSubmissions.sponsor'), 'sponsor', true),
      this.utilService.createTableHeader(this.translatePipe.transform('adminSubmissions.piName'), 'piName', true),
      this.utilService.createTableHeader(this.translatePipe.transform('adminSubmissions.name'), 'submitterName', true),
      this.utilService.createTableHeader(this.translatePipe.transform('adminSubmissions.company'), 'submitterCompanyName', true),
      this.utilService.createTableHeader(this.translatePipe.transform('adminSubmissions.submissionName'), 'submissionName', true),
      this.utilService.createTableHeader(this.translatePipe.transform('adminSubmissions.source'), 'source', true),
      this.utilService.createTableHeader(this.translatePipe.transform('adminSubmissions.specialInstructions'), 'specialInstructions', true)
    ];
  }

  public search(): void {
    this.loading = true;
    this.showTable = true;
    const startDate = this.submissionsForm.get('startDateFilter').value?.[0];
    const endDate = this.submissionsForm.get('endDateFilter').value?.[0];
    this.postAdminSubmissionGrid({
      page: 1,
      pageSize: this.currentPageSize,
      filterOptions: this.getCurrentFilters(),
      sortOptions: this.currentSort,
      startDate: startDate ? moment(startDate).utc(false) : null,
      endDate: endDate ? moment(endDate).utc(false).add(1, 'day') : null
    });
  }

  public handlePageChange(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;
    const startDate = this.submissionsForm.get('startDateFilter').value?.[0];
    const endDate = this.submissionsForm.get('endDateFilter').value?.[0];
    this.postAdminSubmissionGrid({
      page: this.currentPage,
      pageSize: this.currentPageSize,
      sortOptions: this.currentSort,
      filterOptions: this.getCurrentFilters(),
      startDate: startDate ? moment(startDate).utc(false) : null,
      endDate: endDate ? moment(endDate).utc(false).add(1, 'day') : null
    });
  }

  public handleSort(event): void {
    this.gridOptionsModel.page = 1;
    this.gridOptionsModel.sortOptions = [{
      field: AdminSubmissionsSortOptions[event.detail.field],
      isDescending: !event.detail.ascending
    }];
    const startDate = this.submissionsForm.get('startDateFilter').value?.[0];
    const endDate = this.submissionsForm.get('endDateFilter').value?.[0];
    this.postAdminSubmissionGrid({
      page: this.gridOptionsModel.page,
      pageSize: this.currentPageSize,
      sortOptions: this.gridOptionsModel.sortOptions,
      filterOptions: this.getCurrentFilters(),
      startDate: startDate ? moment(startDate).utc(false) : null,
      endDate: endDate ? moment(endDate).utc(false).add(1, 'day') : null
    });
  }

  toggleEndDate(): void {
    this.endDatePicker.flatpickr.toggle();
  }

  toggleStartDate(): void {
    this.startDatePicker.flatpickr.toggle();
  }

  private getCurrentFilters(): Array<FilterOptionModel> {
    const filters: Array<FilterOptionModel> = [];
    const irbTrackingID = this.submissionsForm.get('irbTrackingID').value;
    if (irbTrackingID !== null && irbTrackingID.trim().length > 0) {
      const irbTrackingValue = this.submissionsForm.controls.irbTrackingID.value;
      filters.push({
        field: 'StudyIrbTrackingNumber',
        operator: 'Equals',
        values: [irbTrackingValue]
      });
    }
    const submissionType = this.submissionsForm.get('submissionType').value;
    if (submissionType !== null && submissionType.trim().length > 0) {
      const typeValue = this.submissionTypeFilter.find((item) => item.value === submissionType).id;
      filters.push({
        field: 'SubmissionType',
        operator: 'Equals',
        values: [typeValue]
      });
    }
    const submitterName = this.submissionsForm.get('submitterName').value;
    if (submitterName !== null && submitterName.trim().length > 0) {
      const submitterValue = this.submissionsForm.controls.submitterName.value;
      filters.push({
        field: 'SubmissionSubmittedBy',
        operator: 'Equals',
        values: [submitterValue]
      });
    }
    const confirmationId = this.submissionsForm.get('confirmationId').value;
    if (confirmationId !== null && confirmationId.trim().length > 0) {
      const confirmationValue = this.submissionsForm.controls.confirmationId.value;
      filters.push({
        field: 'IrbConfirmationId',
        operator: 'Equals',
        values: [confirmationValue]
      });
    }
    const submitterCompanyName = this.submissionsForm.get('submitterCompanyName').value;
    if (submitterCompanyName !== null && submitterCompanyName.trim().length > 0) {
      const companyValue = this.submissionsForm.controls.submitterCompanyName.value;
      filters.push({
        field: 'SubmissionSubmitterCompanyName',
        operator: 'Equals',
        values: [companyValue]
      });
    }
    const sponsor = this.submissionsForm.get('sponsor').value;
    if (sponsor !== null && sponsor.trim().length > 0) {
      const sponsorValue = this.submissionsForm.controls.sponsor.value;
      filters.push({
        field: 'Sponsor',
        operator: 'Equals',
        values: [sponsorValue]
      });
    }
    const piName = this.submissionsForm.get('piName').value;
    if (piName !== null && piName.trim().length > 0) {
      const piNameValue = this.submissionsForm.controls.piName.value;
      filters.push({
        field: 'PIName',
        operator: 'Equals',
        values: [piNameValue]
      });
    }
    const envelopeId = this.submissionsForm.get('envelopeId').value;
    if (envelopeId !== null && envelopeId.trim().length > 0) {
      const envelopeValue = this.submissionsForm.controls.envelopeId.value;
      filters.push({
        field: 'IrisId',
        operator: 'Equals',
        values: [envelopeValue]
      });
    }
    const submissionName = this.submissionsForm.get('submissionName').value;
    if (submissionName !== null && submissionName.trim().length > 0) {
      const titleValue = this.submissionsForm.controls.submissionName.value;
      filters.push({
        field: 'SubmissionTitle',
        operator: 'Equals',
        values: [titleValue]
      });
    }
    const submissionSource = this.submissionsForm.get('submissionSource').value;
    if (submissionSource !== null && submissionSource.trim().length > 0) {
      const sourceValueId = SubmissionSources.find((item) => item.viewValue === submissionSource).value;
      filters.push({
        field: 'SearchSubmissionSource',
        operator: 'Equals',
        values: [sourceValueId]
      });
    }
    const specialInstructions = this.submissionsForm.get('specialInstructions').value;
    if (specialInstructions !== null && specialInstructions.trim().length > 0) {
      const specialInstructionsValue = this.submissionsForm.controls.specialInstructions.value;
      filters.push({
        field: 'SubmissionSpecialInstructions',
        operator: 'Equals',
        values: [specialInstructionsValue]
      });
    }
    const startDate = this.submissionsForm.get('startDateFilter').value?.[0];
    const endDate = this.submissionsForm.get('endDateFilter').value?.[0];
    if (typeof startDate !== 'undefined' || typeof endDate !== 'undefined') {
      filters.push({
        field: 'SubmissionSubmittedDate',
        operator: 'Equals',
        values: null
      });
    }

    return filters;
  }

  // eslint-disable-next-line class-methods-use-this
  private addValidators(length: number, pattern: any): any {
    return Validators.compose([
      Validators.maxLength(length),
      Validators.pattern(pattern)
    ]);
  }

  private postAdminSubmissionGrid(gridFilterOption): void {
    this.loaded = false;
    this.loading = true;
    this.gridFilters = gridFilterOption;
    const irbSearchTerm = this.submissionsForm.controls.irbTrackingID.value === null ? '' : this.submissionsForm.controls.irbTrackingID.value.trim().toLowerCase();
    const nameSearchTerm = this.submissionsForm.controls.submitterName.value === null ? '' : this.submissionsForm.controls.submitterName.value.trim().toLowerCase();
    const companySearchTerm = this.submissionsForm.controls.submitterCompanyName.value === null ? '' : this.submissionsForm.controls.submitterCompanyName.value.trim().toLowerCase();
    const sponsorSearchTerm = this.submissionsForm.controls.sponsor.value === null ? '' : this.submissionsForm.controls.sponsor.value.trim().toLowerCase();
    const piNameSearchTerm = this.submissionsForm.controls.piName.value === null ? '' : this.submissionsForm.controls.piName.value.trim().toLowerCase();
    const envelopeSearchTerm = this.submissionsForm.controls.envelopeId.value === null ? '' : this.submissionsForm.controls.envelopeId.value.trim().toLowerCase();
    const confirmationSearchTerm = this.submissionsForm.controls.confirmationId.value === null ? '' : this.submissionsForm.controls.confirmationId.value.trim().toLowerCase();
    const titleSearchTerm = this.submissionsForm.controls.submissionName.value === null ? '' : this.submissionsForm.controls.submissionName.value.trim().toLowerCase();
    const notesSearchTerm = this.submissionsForm.controls.specialInstructions.value === null ? '' : this.submissionsForm.controls.specialInstructions.value.trim().toLowerCase();
    this.submissionAdminService.postAdminSubmissionsInfo(gridFilterOption)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: AdminSubmissionGrid) => {
        this.loaded = true;
        this.loading = false;
        this.totalRecords = data.totalRecords;
        this.tableData = this.submissionAdminService.mapAdminSubmissionsData(data.records, {
          irbSearchTerm: irbSearchTerm,
          nameSearchTerm: nameSearchTerm,
          companySearchTerm: companySearchTerm,
          sponsorSearchTerm: sponsorSearchTerm,
          piNameSearchTerm: piNameSearchTerm,
          envelopeSearchTerm: envelopeSearchTerm,
          confirmationSearchTerm: confirmationSearchTerm,
          titleSearchTerm: titleSearchTerm,
          notesSearchTerm: notesSearchTerm
        });
        this.currentPage = data.currentPage;
      }, () => {
        this.loaded = true;
        this.loading = false;
        this.toastService.add([{
          closable: true,
          id: 'getAdminSubmissions',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          variant: 'error'
        }]);
      });
  }
}
