/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars-experimental */
/* eslint-disable no-trailing-spaces */
import { Component, OnInit } from '@angular/core';
import { Formio } from 'angular-formio';
import { Router } from '@angular/router';
import * as uuid from 'uuid';

import {
  AllowedExtensions,
  AllowedPasswordProtectedExtensions,
  AnswerOptions,
  CountryCodes,
  Forms,
  passwordProtectedFileSizeLimit,
  sizeLimit,
  SubmissionTypeNames
} from '../../../common/collections';
import { ApiService } from '../../../services/api.service';
import { FileUploadGridRecordMap, ErrorFile, SubmissionDocumentType } from '../../../interfaces/submissions';
import { FormType } from '../../../admin/shared/model/submission-type';
import { SubmissionWorkflowService } from '../submission-workflow.service';
import {
  administrativeActionForm3,
  clinicalTrialInformation,
  federalFundingForm,
  populationTransferForm,
  principalInvestigatorLicensure,
  consent,
  promptlyReportableDisclosure,
  researchLocationFo3,
  researchPersonnel,
  translationRequestForm,
  translationRequestSubmissionDetails,
  whatChangesAreYouMaking
} from '../../../common/formio-map';
import { TableHeader } from '../../../interfaces/components';
import { ToastService } from '../../../services/toast.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { UtilService } from '../../../services/util.service';
import { BlobService, UploadConfig } from 'angular-azure-blob-service';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnInit {
  duplicateFiles: Array<File>;
  filesToRemove: Array<FileUploadGridRecordMap> = [];
  fileUploadArray = [];
  fileUploadData: Array<FileUploadGridRecordMap> = [];
  headers: Array<TableHeader>;
  isSelectedBtnDisabled = true;
  isUploadForm = false;
  options = {
    rowSelection: true,
    rowsPerPage: 3000,
    manualPaginationAndSort: true
  };

  prevPageName: string;
  saveState = '';
  showFileExistModal = false;
  showRemoveLoader = false;
  showRemoveModal = false;

  private currentTimeout: any = null;
  private errorMessage = '';
  private selectedFiles: Array<FileUploadGridRecordMap> = [];
  private successMessage = '';
  private uploadedFiles: Array<File> = [];
  private userExternalReferenceId: string;
  private config: UploadConfig;

  constructor(
    public submissionWorkflowService: SubmissionWorkflowService,
    private router: Router,
    private apiService: ApiService,
    private toastService: ToastService,
    private utilService: UtilService,
    private translatePipe: TranslatePipe,
    private blob: BlobService
  ) { }

  ngOnInit(): void {
    this.headers = this.getTableHeaders();
    this.isUploadForm = this.submissionWorkflowService.submissionFormType.value === FormType[FormType.UploadForm];

    this.setDocumentChecklist(this.submissionWorkflowService.formInfo.value);
    this.submissionWorkflowService.setDocumentStatus();

    if (!this.submissionWorkflowService.documentUploadSubmissionGuid.value.length) {
      this.submissionWorkflowService.documentUploadSubmissionGuid.next(uuid.v4());
    }

    if (this.submissionWorkflowService.submissionDocumentTypes.value.length) {
      this.setFileUploadData(this.submissionWorkflowService.filesData$.value, this.userExternalReferenceId);
    }

    this.submissionWorkflowService.saveState.subscribe((value) => {
      this.saveState = value;
    });

    this.submissionWorkflowService.formPages.subscribe((value) => {
      this.prevPageName = value[value.length - 1]?.title;
    });

    this.submissionWorkflowService.filesData$
      .subscribe((value) => {
        this.fileUploadData = [];

        setTimeout(() => {
          this.fileUploadData = [...value];
        });
      });

    this.submissionWorkflowService.files$
      .subscribe((value) => {
        this.setFileUploadData(value.map((file) => ({
          file
        })), this.userExternalReferenceId);
      });

    this.apiService.getUserInfo().subscribe((res) => {
      this.userExternalReferenceId = res.externalReferenceId;

      this.setFileUploadData(this.submissionWorkflowService.filesData$.value, this.userExternalReferenceId);
    }, (err) => {
      this.toastService.add([{
        closable: true,
        id: 'getUserInfo',
        message: err.details.message,
        timeout: 5000,
        variant: 'error'
      }]);
    });
  }

  public continueClick(): void {
    const formType = this.submissionWorkflowService.submissionFormType.value;

    if (this.submissionWorkflowService.reviewDisabled) {
      let toastMessage = 'submissionWorkflow.reviewDisabled';
      if (formType === FormType[FormType.UploadForm] && !this.submissionWorkflowService.filesData$.value.length) {
        toastMessage = 'submissionWorkflow.reviewDisabledNoFiles';
      }
      this.toastService.add([{
        closable: true,
        timeout: 5000,
        id: 'navigationDisabled',
        message: this.translatePipe.transform(toastMessage),
        variant: 'info'
      }]);

      return;
    }

    this.router.navigateByUrl('/submission-workflow/details/review');
  }

  public disableRemoveSelectedButton(): void {
    if (this.selectedFiles.length > 0) {
      this.isSelectedBtnDisabled = false;
    }
    else {
      this.isSelectedBtnDisabled = true;
    }
  }

  public handleChange(event): void {
    this.uploadedFiles = [...event.target.files];

    if (this.uploadedFiles.length) {
      this.isSelectedBtnDisabled = true;
    }

    this.checkFilesExist(this.uploadedFiles);
    this.submissionWorkflowService.setDocumentStatus();
  }

  public handleFileUploadError(event): void {
    this.toastService.add([{
      closable: true,
      id: 'browserSupportError',
      message: event.detail,
      timeout: 5000,
      variant: 'error'
    }]);
  }

  public handleRowClickedEvents(event): void {
    const rowData = event.detail;
    const row = event.detail.event.currentTarget;

    const changeDocumentTypeSelection = (changeEvent): void => {
      const documentType = changeEvent.target.value;

      if (documentType.length) {
        row.querySelector('wcg-error-message').classList.add('wcg-error');
      }
      else {
        row.querySelector('wcg-error-message').classList.remove('wcg-error');
      }

      this.updateSubmissionDocumentType(rowData, documentType);

      row.querySelector('select').removeEventListener('change', changeDocumentTypeSelection);
    };

    row.querySelector('select').addEventListener('change', changeDocumentTypeSelection);

    const composedPath = event.detail.event.composedPath && event.detail.event.composedPath();
    const eventPath = event.detail.event.path || composedPath;
    const removeBtn = eventPath.find((path) => path.attributes && path.attributes.getNamedItem('data-file-remove'));

    if (removeBtn) {
      this.filesToRemove = [];
      row.querySelector('select').removeEventListener('change', changeDocumentTypeSelection);
      const fileToRemove = this.submissionWorkflowService.filesData$.value
        .find((doc) => doc.document.fileName === rowData.document.fileName);
      this.filesToRemove.push(fileToRemove);
      this.showRemoveModal = true;
    }

    this.submissionWorkflowService.setDocumentStatus();
  }

  public handleRowSelected(event): void {
    this.selectedFiles = event.detail;

    this.disableRemoveSelectedButton();
  }

  public modalCancelClick(): void {
    this.showRemoveModal = false;

    this.disableRemoveSelectedButton();
  }

  public replaceFile(): void {
    this.duplicateFiles.forEach((file) => {
      this.submissionWorkflowService.removeFile({ file });
    });

    this.checkFileValidations(this.uploadedFiles);

    this.showFileExistModal = false;
  }

  public removeSelectedFiles(): void {
    this.filesToRemove = [];
    const fileUploadTable = document.getElementById('fileUploadTable').getElementsByClassName('wcg-table-body');
    const selectedDocuments = fileUploadTable[0].querySelectorAll('.wcg-selected');

    selectedDocuments.forEach((row) => {
      const fileNameSpan = row.getElementsByClassName('wcg-table-cell');
      const fileName = fileNameSpan[1].firstElementChild?.innerHTML || fileNameSpan[1].children[0].innerHTML;
      const fileToRemove = this.submissionWorkflowService.filesData$.value.find((doc) => doc.document.fileName === fileName);
      this.filesToRemove.push(fileToRemove);
    });

    this.showRemoveModal = true;
    this.submissionWorkflowService.setDocumentStatus();
  }

  public removeFilesFromStorage(): void {
    this.showRemoveLoader = true;

    this.apiService.deleteFile(this.filesToRemove.map((file) => file.document)).subscribe(() => {
      this.filesToRemove.forEach((file) => {
        this.submissionWorkflowService.removeFile(file);
      });

      this.successMessage = `${this.translatePipe.transform('documentUpload.filesRemovedSuccessMessage')}`;
      const fileNames = this.filesToRemove.map((removeFile) => removeFile.fileName);

      this.showRemoveModal = false;
      this.showRemoveLoader = false;

      fileNames.forEach((name) => {
        this.toastService.add([{
          closable: true,
          id: `removeFilesFromStorageSuccess${name}`,
          message: `${this.successMessage}: ${name}`,
          timeout: 5000,
          variant: 'success'
        }]);
      });

      this.filesToRemove = [];
      this.saveDraft();
      this.submissionWorkflowService.setDocumentStatus();
    }, (err) => {
      this.errorMessage = `${this.translatePipe.transform('documentUpload.filesRemovedFailedMessage')}: ${err.details.message}`;
      this.showRemoveModal = false;
      this.showRemoveLoader = false;

      this.toastService.add([{
        closable: true,
        id: 'removeFilesFromStorageError',
        message: this.errorMessage,
        timeout: 5000,
        variant: 'error'
      }]);

      this.filesToRemove = [];
    });
  }

  public skipFile(duplicateFiles): void {
    const existingFilenames = new Set(duplicateFiles.map((prevFile) => prevFile.name));
    this.uploadedFiles = this.uploadedFiles.filter((file) => !existingFilenames.has(file.name));
    this.checkFileValidations(this.uploadedFiles);
    this.showFileExistModal = false;
  }

  private checkFilesExist(files: File[]): void {
    const existingFileNames = this.submissionWorkflowService.filesData$.value.map((prevFile) => prevFile.document.fileName);

    this.duplicateFiles = [...files].map((file) => {
      if (existingFileNames.includes(file.name)) {
        return file;
      }

      return null;
    }).filter(Boolean);

    if (this.duplicateFiles.length) {
      this.showFileExistModal = true;
    }
    else {
      this.checkFileValidations(files);
    }
  }

  private checkFileValidations(newUploadFiles): void {
    newUploadFiles.forEach((file) => {
      const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
      const fileSize = file.size;
      const errorFile = {} as ErrorFile;
      errorFile.fileName = file.name;
      errorFile.errorMessage = [];

      if (!AllowedExtensions.includes(fileExtension)) {
        errorFile.errorMessage.push(this.translatePipe.transform('documentUpload.fileTypeError'));
      }
      if (fileSize > Number(sizeLimit)) {
        errorFile.errorMessage.push(this.translatePipe.transform('documentUpload.fileSizeError'));
      }
      if (fileSize === 0) {
        errorFile.errorMessage.push(this.translatePipe.transform('documentUpload.fileZeroByteError'));
      }
      if (errorFile.errorMessage.length) {
        this.setFileValidationsErrorMessage(errorFile);
      }
      else {
        this.uploadFile(file);
      }
    });
  }

  private getDocumentChecklistData(data): object {
    const changeInResearchData = data?.changeInResearch?.data ?? data;
    const promptlyReportableData = data?.[promptlyReportableDisclosure.form]?.data ?? data;
    const problemCategory = promptlyReportableData?.[promptlyReportableDisclosure.fields.problemCategory];
    const submissionType = this.submissionWorkflowService.submissionTypeValue?.value;

    const { translationRequestSubmission, translationRequestSubmissionNoPi, translatedDocuments } = data;

    const { [translationRequestSubmissionDetails.fields.typeOfSubmission]: typeOfSubmission } =
      translationRequestSubmission?.data.translationRequestSubmissionDetails?.data ||
      translationRequestSubmissionNoPi?.data.translationRequestSubmissionDetails?.data ||
      translatedDocuments?.data.translationRequestSubmissionDetails?.data || {};

    return {
      consentFormTypeOfChange: this.showChecklistConsentFormTypeOfChange(changeInResearchData),
      doesALocalIrbHaveJurisdictionOverResearch: data?.[researchLocationFo3.form]?.data[researchLocationFo3.fields.jurisdiction],
      newStudyPi: this.showNewStudyPIOrNoPI(submissionType, data?.setupPiPleaseSelectYesOrNo),
      problemCategory: problemCategory,
      productLabellingForInvestigationalDevices: data?.[clinicalTrialInformation.form]?.data
      [clinicalTrialInformation.fields.productLabeling],
      showAddendumForDod: data?.[federalFundingForm.form]?.data?.[federalFundingForm.fields.federalDepartment],
      showAdministrativeAction: data?.[administrativeActionForm3.form]?.data[administrativeActionForm3.fields.relatedDocs],
      showCanadianSupplement: this.showCanadianSupplement(changeInResearchData),
      showFdaDocumentingInd: data?.[clinicalTrialInformation.form]?.data[clinicalTrialInformation.fields.ind],
      showFinancialDisclosureInterest: this.showFinancialDisclorueName(data, problemCategory),
      showIdeNumber: data?.[clinicalTrialInformation.form]?.data[clinicalTrialInformation.fields.ide],
      showInvestigatorBrochure: data?.[clinicalTrialInformation.form]?.data[clinicalTrialInformation.fields.investigatorBrochure],
      showIrbTransfer: data?.[populationTransferForm.form]?.data[populationTransferForm.fields.transferOversight],
      showMedicalLicense: data?.[principalInvestigatorLicensure.form]?.data[principalInvestigatorLicensure.fields.license],
      showAnyConsentFormsOrInformationSheets: this.showAnyConsentFormsOrInformationSheets(data, submissionType),
      showConsentFormDocumentation: this.showConsentFormDocumentation(data, submissionType),
      showPlannedProtocolDeviation: this.showPlannedProtocolDeviation(data),
      showRecruitmentBonus: this.showRecruitmentBonusName(data),
      showRelianceAgreement: this.showRelianceAgreement(changeInResearchData),
      showSiteOnly: this.showSiteOnlyMessage(submissionType),
      showSrNsrDetermination: data?.[clinicalTrialInformation.form]?.data[clinicalTrialInformation.fields.showSrNsrDeterminationName],
      showTranslationRequest: this.showTranslationRequest(data),
      subjectMaterialsTypeOfChange: changeInResearchData?.subjectMaterialsForm?.data?.typeOfChange,
      writtenDocumentationOfFwa: data?.[federalFundingForm.form]?.data[federalFundingForm.fields.fundedResearch],
      showHUDClinicalUseClosure: this.showChecklistOfflineForms(submissionType, SubmissionTypeNames.hudClinicalUseClosure),
      showContactUpdate: this.showChecklistOfflineForms(submissionType, SubmissionTypeNames.contactUpdate),
      showContinuingReview: this.showChecklistOfflineForms(submissionType, SubmissionTypeNames.continuingReview),
      showNotListed: this.showChecklistOfflineForms(submissionType, SubmissionTypeNames.notListed),
      showAdditionalDocumentationSection: typeOfSubmission,
      showClinicalHudIR: this.showChecklistOfflineForms(submissionType, SubmissionTypeNames.clinicalHudIR),
      showSinglePatientExpanded: this.showChecklistOfflineForms(submissionType, SubmissionTypeNames.singlePatientExpanded),
      showSubmitTheFollowingDocumentation: this.showSubmitTheFollowingDocumentation(typeOfSubmission),
      showAuthFromSponsorOrCRO: this.showChangeInResearchMessage(submissionType),
      showChangeInAddress: this.showChangeInAddress(data)
    };
  }

  private getTableHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('documentUpload.fileName'), 'fileName', false),
      this.utilService.createTableHeader(this.translatePipe.transform('documentUpload.documentType'), 'documentType', false),
      this.utilService.createTableHeader('', 'documentActions', false)
    ];
  }

  private setDocumentChecklist(data): void {
    // TODO: BOW-509 - Set document checklist based on questions answered in IR form
    const formData = this.getDocumentChecklistData(data);
    this.apiService.getFormioForm('documentchecklist')
      .subscribe((formResult) => {
        Formio.createForm(document.getElementById('formIODoc'), formResult)
          .then((form) => {
            form.submission = {
              data: formData
            };
          });
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'setDocumentChecklist',
          message: err.details.message,
          timeout: 3000,
          variant: 'error'
        }]);
      });
  }

  private saveDraft(): void {
    const { saveInterval } = this.submissionWorkflowService;

    if (this.currentTimeout === null) {
      this.currentTimeout = setTimeout(() => {
        clearTimeout(this.currentTimeout);
        this.currentTimeout = null;
        this.submissionWorkflowService.saveChanges();
        this.submissionWorkflowService.setDocumentStatus();
      }, saveInterval);
    }
  }

  private setFileValidationsErrorMessage(errorFile: ErrorFile): void {
    this.errorMessage = `${errorFile.fileName}: ${errorFile.errorMessage.join('-')}`;

    this.toastService.add([{
      closable: true,
      id: `setFileValidationsErrorMessage${errorFile.fileName}`,
      message: this.errorMessage,
      variant: 'error'
    }]);

    this.disableRemoveSelectedButton();
  }

  private setFileUploadData(files: FileUploadGridRecordMap[], userExternalReferenceId: string): void {
    if (!files || !userExternalReferenceId) {
      return;
    }

    this.submissionWorkflowService.addFilesData([...files].map((file: FileUploadGridRecordMap) => {
      let currentFileData;

      if (file.file) {
        currentFileData = this.submissionWorkflowService.filesData$.value.find((fileData) => fileData.document.fileName === file.file.name);
      }

      currentFileData = currentFileData || file;

      const iconEl = '<wcg-icon src="../assets/icons/action/ic_delete_forever_24px.svg"></wcg-icon>';
      const fileName = currentFileData.file?.name || currentFileData.document.fileName;

      const refId = file.userExternalReferenceId || userExternalReferenceId;

      const blobFileName =
        `${refId}/${this.submissionWorkflowService.documentUploadSubmissionGuid.value}/${fileName}`;

      const documentType = currentFileData.document?.type || {} as SubmissionDocumentType;

      const fileUpload: FileUploadGridRecordMap = {
        file: file.file,
        fileName: this.utilService.addTableCellWrapper(fileName),
        document: {
          blobFileName,
          storageFilePath: `draft/${blobFileName}`,
          fileName,
          isLinked: false,
          type: documentType
        },
        documentType: `<wcg-select>
          ${this.getDocumentOptions(currentFileData.document?.type?.value)}
          </wcg-select>
        ${this.getRequiredDocumentTypeErrorMessage(currentFileData.document?.type?.value)}`,
        documentActions: `<button data-file-remove class="wcg-btn wcg-btn-icon file-remove-btn">${iconEl}</button>`,
        userExternalReferenceId: refId
      };

      return fileUpload;
    }));

    this.isSelectedBtnDisabled = true;
  }

  private showCanadianSupplement = (data): string => {
    let showCanadianSupplement = '';
    if (data?.formType === Forms.initialReviewForm) {
      if (data?.researchLocationFo3?.data?.locationDataGrid) {
        data.researchLocationFo3.data.locationDataGrid.forEach((item) => {
          if (item.form?.data.addressForm.data) {
            if (item.form.data.addressForm.data.country === CountryCodes.CANADA) {
              showCanadianSupplement = AnswerOptions.yes;
            }
          }
        });
      }
    }

    if (data?.formType === Forms.changeInResearchForm) {
      if (data?.researchLocation?.data?.locationDataGrid) {
        data.researchLocation.data.locationDataGrid.forEach((item) => {
          if (item.form?.data.addressForm.data) {
            if (item.form.data.addressForm.data.country === CountryCodes.CANADA) {
              showCanadianSupplement = AnswerOptions.yes;
            }
          }
        });
      }
    }

    return showCanadianSupplement;
  }

  private showFinancialDisclorueName = (data, problemCategory): string => {
    let showFinancialDisclorueName = data?.setupFinancialDisclosurePrincipalInvestigatorRadio;


    if (data?.formType === Forms.promptlyReportableForm && problemCategory === 'changeInFinancialInterestDisclosure') {
      // Rules that apply only for the Promptly Reportable main flow
      showFinancialDisclorueName = AnswerOptions.yes;
    }

    if (
      data?.[whatChangesAreYouMaking.form]?.financialInterestDisclosure ||
      data?.[researchPersonnel.form]?.data?.[researchPersonnel.fields.training] === AnswerOptions.yes
    ) {
      showFinancialDisclorueName = AnswerOptions.yes;
    }

    return showFinancialDisclorueName;
  }

  private showPlannedProtocolDeviation = (data): string => {
    let showPlannedProtocolDeviation = '';
    if (data?.[whatChangesAreYouMaking.form]?.plannedProtocolDeviation) {
      showPlannedProtocolDeviation = AnswerOptions.yes;
    }

    return showPlannedProtocolDeviation;
  };

  private showChangeInAddress = (data): string => {
    let showChangeInAddress = '';
    if (data?.[whatChangesAreYouMaking.form]?.changeInAddressOrPhoneOfAResearchContact) {
      showChangeInAddress = AnswerOptions.yes;
    }

    return showChangeInAddress;
  };

  private showRecruitmentBonusName = (data): string => {
    let showRecruitmentBonusName = data?.willThePrincipalInvestigatorPiOrResearchTeamBeOfferedRecruitmentBonuses;
    if (data?.[whatChangesAreYouMaking.form]?.[whatChangesAreYouMaking.fields.recruitmentBonus]) {
      showRecruitmentBonusName = AnswerOptions.yes;
    }

    return showRecruitmentBonusName;
  }

  private showRelianceAgreement = (changeInResearchData): string => {
    const changeOfAddressOfResearchLocation = changeInResearchData?.changeOfAddressOfResearchLocation?.data;
    if (changeOfAddressOfResearchLocation?.dataGrid &&
      changeOfAddressOfResearchLocation?.dataGrid.some((item) => item.doesALocalIrbHaveJurisdictionOverResearch === AnswerOptions.yes)) {
      return AnswerOptions.yes;
    }
    if (changeInResearchData?.researchLocation?.data?.doesALocalIrbHaveJurisdictionOverResearch === AnswerOptions.yes) {
      return AnswerOptions.yes;
    }

    return '';
  }

  private showTranslationRequest = (data): string => {
    let showTranslationRequestName = data?.formType === Forms.translationRequestForm ?
      AnswerOptions.yes :
      data?.[translationRequestForm.fields.translatedDocuments];

    const whatChanges = data?.[whatChangesAreYouMaking.form];
    if (whatChanges && whatChanges[whatChangesAreYouMaking.fields.translationRequest] === true) {
      showTranslationRequestName = AnswerOptions.yes;
    }

    return showTranslationRequestName;
  }

  private showChecklistOfflineForms = (submissionType: string, submissionTypeName: string): string => {
    let showChecklistForOfflineForms = '';

    if (submissionType?.toLowerCase() === submissionTypeName?.toLowerCase()) {
      showChecklistForOfflineForms = AnswerOptions.yes;
    }

    return showChecklistForOfflineForms;
  }

  private showNewStudyPIOrNoPI = (submissionType: string, setupPIAnswer): string => {
    let showPINoPI = '';

    if (submissionType?.toLowerCase() === SubmissionTypeNames.newStudyForIR.toLowerCase() ||
      submissionType?.toLowerCase() === SubmissionTypeNames.exemptionRequest.toLowerCase()) {
      if (setupPIAnswer === AnswerOptions.yes) {
        showPINoPI = AnswerOptions.yes;
      }
      else {
        showPINoPI = AnswerOptions.no;
      }
    }

    return showPINoPI;
  }

  private showSiteOnlyMessage = (submissionType: string): string => {
    if (submissionType?.toLowerCase() === SubmissionTypeNames.newSiteForIR?.toLowerCase() ||
      submissionType?.toLowerCase() === SubmissionTypeNames.changeInInvestigator?.toLowerCase()) {
      return AnswerOptions.yes;
    }

    return '';
  }

  private showChangeInResearchMessage = (submissionType: string): string => {
    if (submissionType?.toLowerCase() === SubmissionTypeNames.changeInInvestigator?.toLowerCase()) {
      return AnswerOptions.yes;
    }

    return AnswerOptions.no;
  }

  private showAnyConsentFormsOrInformationSheets = (changeInInvestigatorData, submissionType): string => {
    if (changeInInvestigatorData?.[consent.form]?.data[consent.fields.provideConsent] &&
      submissionType?.toLowerCase() === SubmissionTypeNames.changeInInvestigator?.toLowerCase()) {
      return AnswerOptions.yes;
    }

    return AnswerOptions.no;
  }

  private showConsentFormDocumentation = (newSiteForInitialReviewData, submissionType): string => {
    if (newSiteForInitialReviewData?.[consent.form]?.data[consent.fields.provideConsent] &&
      submissionType?.toLowerCase() === SubmissionTypeNames.newSiteForIR?.toLowerCase()) {
      return AnswerOptions.yes;
    }

    return AnswerOptions.no;
  }

  private showChecklistConsentFormTypeOfChange = (changeInResearchData): string => {
    let showChecklistConsentFormTypeOfChange = '';
    showChecklistConsentFormTypeOfChange = changeInResearchData?.consentForm?.data?.typeOfChange ??
      changeInResearchData?.consentForm1?.data?.typeOfChange ??
      changeInResearchData?.researchPersonnel?.data.consentFormResearchPersonnel?.data?.typeOfChange;

    return showChecklistConsentFormTypeOfChange;
  }

  private getRequiredDocumentTypeErrorMessage(documentTypeValue): any {
    if (!documentTypeValue) {
      return `<wcg-error-message>
      ${this.translatePipe.transform('documentUpload.requiredDocumentType')}
    </wcg-error-message>`;
    }

    return `<wcg-error-message class="wcg-error">
      ${this.translatePipe.transform('documentUpload.requiredDocumentType')}
    </wcg-error-message>`;
  }

  private getDocumentOptions(documentType: string): any {
    return [{ value: '' }, ...this.submissionWorkflowService.submissionDocumentTypes.value].map((option, index) => {
      if (index === 0 && !documentType) {
        return `<option selected value="${option.value}">${option.value}</option>`;
      }

      return `<option ${option.value === documentType && 'selected'} value="${option.value}">${option.value}</option>`;
    });
  }

  private updateSubmissionDocumentType(rowData: FileUploadGridRecordMap, documentTypeValue: string): void {
    const newRow = rowData;

    newRow.document.type = this.submissionWorkflowService.submissionDocumentTypes.value.find((type) => type.value === documentTypeValue);

    this.setFileUploadData([newRow], this.userExternalReferenceId);

    this.saveDraft();
    this.submissionWorkflowService.setDocumentStatus();
  }

  private uploadFile(newFile): void {
    const fileExtension = newFile.name.substring(newFile.name.lastIndexOf('.') + 1).toLowerCase();
    const fileSize = newFile.size;

    this.fileUploadArray.push(newFile.name);

    // is file password protected and less than the 100 MB limit
    if (AllowedPasswordProtectedExtensions.includes(fileExtension) && fileSize < Number(passwordProtectedFileSizeLimit)) {
      this.apiService.postFileProtectionValidation(newFile, fileExtension)
        .subscribe((data) => {
          if (!data) {
            // get the sas token and upload file to azure
            this.getSasForDocumentUpload(newFile);
          }
        },
        (err) => {
          this.handleError(err, newFile.name);
          this.removeFromFileUploadArray(newFile.name);
        });
    }
    else {
      // get the sas token and upload file to azure
      this.getSasForDocumentUpload(newFile);
    }
  }

  private getSasForDocumentUpload(file: any): void {
    this.apiService.getSasForDocumentUpload()
      .subscribe((data) => {
        this.uploadToAzure(data, file);
      },
      (err) => {
        this.handleError(err, file.name);
        this.removeFromFileUploadArray(file.name);
      });
  }

  private uploadToAzure(sasToken: string, file: File): void {
    const config = this.submissionWorkflowService.getAzureConfig(sasToken, 'draft'.concat('/',
      this.userExternalReferenceId, '/',
      this.submissionWorkflowService.documentUploadSubmissionGuid.value));
    this.config = {
      baseUrl: this.utilService.replaceAzureBlobBaseUrl(
        this.blob.generateBlobUrl(
          config,
          encodeURIComponent(file.name)
        )
      ),
      sasToken: config.sas,
      blockSize: this.submissionWorkflowService.azureBlobSize,
      file: file,
      complete: (): void => {
        this.submissionWorkflowService.addFiles(file);
        this.saveDraft();
        this.removeFromFileUploadArray(file.name);
      },
      error: (err): void => {
        this.handleError(err, file.name);
        this.removeFromFileUploadArray(file.name);
      }
    };
    this.blob.upload(this.config);
  }

  // Cannot do array.pop as azureblobstorage doesnt return back as expected
  private removeFromFileUploadArray(fileName: string): void {
    const index = this.fileUploadArray.indexOf(fileName, 0);
    if (index > -1) {
      this.fileUploadArray.splice(index, 1);
    }
  }

  private handleError(err: any, fileName: string): void {
    let apiError = err?.details?.message;

    if (err?.details?.error?.error) {
      apiError = err.details.error.error;
    }

    if (apiError) {
      this.errorMessage = `${this.translatePipe.transform('documentUpload.fileUploadError')} : ${fileName} - ${apiError}`;
    }
    else {
      this.errorMessage = `${this.translatePipe.transform('documentUpload.fileUploadError')} : ${fileName}`;
    }

    this.toastService.add([{
      closable: true,
      id: `uploadFilesError${fileName}`,
      message: this.errorMessage,
      timeout: 3000,
      variant: 'error'
    }]);
  }

  private showSubmitTheFollowingDocumentation = (typeOfSubmission: any): boolean => {
    let showSubmitTheFollowingDocumentation = true;
    const iHaveAlreadyTranslatedDocuments = typeOfSubmission?.iHaveAlreadyTranslatedDocumentS;
    const iWantTheIrbToFacilitateTranslations = typeOfSubmission?.iWantTheIrbToFacilitateTranslationSThroughTheirTranslationVendor;
    if (iWantTheIrbToFacilitateTranslations === true && iHaveAlreadyTranslatedDocuments === false) {
      showSubmitTheFollowingDocumentation = false;
    }

    return showSubmitTheFollowingDocumentation;
  }
}
