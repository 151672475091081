import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
  supportEmail = '';
  constructor(
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.getSupportEmail();
  }

  private getSupportEmail(): void {
    this.apiService.getConfigurationValue('settings/SupportEmail')
      .subscribe((setting) => {
        this.supportEmail = setting.value;
      });
  }
}
