/* eslint-disable max-lines */
import { Component, OnInit, ViewChild } from '@angular/core';
import { takeUntil, debounce } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { timer } from 'rxjs';

import { BaseComponent } from '../../../components/base/base.component';
import { StudyMap, StudySites, StudySitesMap } from '../../../interfaces/studies';
import { ApiService } from '../../../services/api.service';
import { StudyService } from '../study.service';
import { ToastService } from '../../../services/toast.service';
import { UtilService } from '../../../services/util.service';
import { TableHeader } from 'src/app/interfaces/components';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { SitesSortOptions, DEBOUNCE_TIME, flatPickrDateFormat, StudySiteFilterOptions, ServerErrorCodes } from 'src/app/common/collections';
import { FlatpickrOptions } from 'ng2-flatpickr';
import * as moment from 'moment';
import { GridExportType } from 'src/app/common/reportingCollections';


@Component({
  selector: 'app-study-sites',
  templateUrl: './study-sites.component.html',
  styleUrls: ['./study-sites.component.scss']
})
export class StudySitesComponent extends BaseComponent implements OnInit {
  tableData: Array<StudySitesMap>;
  loaded: boolean;
  loading: boolean;
  id: string;
  searchTerm = new FormControl('');
  sites: Array<StudySitesMap>;
  sitesFetched = false;
  initialSites: Array<StudySites> = [];

  headers: Array<TableHeader>;
  @ViewChild('startDatePicker') startDatePicker;
  @ViewChild('endDatePicker') endDatePicker;

  startDateFilter = new FormControl('');
  endDateFilter = new FormControl('');
  filterOptions: any[] = [];
  startDateUTC: any = null;
  endDateUTC: any = null;

  currentPage: number;
  currentPageSize: number;
  totalPages: number;
  totalRecords: number;

  currentSort: string;
  currentSortDescending = true;

  datePickerOptions: FlatpickrOptions = {
    dateFormat: flatPickrDateFormat
  };

  options = {
    manualPaginationAndSort: true,
    rowsPerPage: 10
  };

  pageUnauthorized = false;
  currentTab: string;
  showOutcomeDocumentModal = false;
  siteId: string;
  siteDetails: StudySites;
  study: StudyMap;

  readonly exportType: GridExportType = GridExportType.StudyDetailsSites;
  gridFilters: any;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private studyService: StudyService,
    public utilService: UtilService,
    private toastService: ToastService,
    private translatePipe: TranslatePipe
  ) {
    super();

    const [id, currentTab] = this.router.url.split('/studies/')[1].split('/');
    this.id = id;
    this.currentTab = currentTab;
    this.currentPageSize = 10;
    this.headers = this.getHeaders();
    this.getStudy(this.id);

    this.postStudySitesGrid(id, {
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: ''
    });
  }

  ngOnInit(): void {
    this.studyService.endDate.next(null);
    this.studyService.startDate.next(null);
    this.studyService.searchTerm.next('');

    this.searchTerm.valueChanges
      .pipe(
        debounce(() => timer(DEBOUNCE_TIME))
      )
      .subscribe((val) => {
        this.studyService.searchTerm.next(val);
        this.postStudySitesGrid(this.id, {
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: val,
          filterOptions: this.filterOptions,
          sortOptions: this.currentSort ? [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }] : null,
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });
      });

    this.startDateFilterOptions();

    this.endDateFilterOptions();
  }

  handleRowClicked(event): void {
    const outcomeDocTargetId = event.detail.event.target.id;
    if (outcomeDocTargetId === 'outcomeDoc') {
      event.detail.event.preventDefault();

      this.showOutcomeDocumentModal = true;
      this.siteId = event.detail.event.target.getAttribute('href');
      this.siteDetails = this.initialSites.find((rsp) => rsp.id === this.siteId);
    }
    else if (event.detail.event.target.hasAttribute('href')) {
      event.detail.event.preventDefault();

      this.router.navigateByUrl(event.detail.event.target.getAttribute('href'));
    }
  }

  startDateFilterOptions(): void {
    this.startDateFilter.valueChanges
      .subscribe((val) => {
        this.jumpStartDates(val[0]);
        [this.endDatePicker.flatpickr.config.minDate] = val;

        this.studyService.startDate.next(val[0]);
        if (val[0]) {
          this.startDateUTC = moment(val[0]).utc(false);
        }

        this.addFilterOptions();
        this.postStudySitesGrid(this.id, {
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          filterOptions: this.filterOptions,
          sortOptions: this.currentSort ? [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }] : null,
          startDate: val[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });
      });
  }

  endDateFilterOptions(): void {
    this.endDateFilter.valueChanges
      .subscribe((val) => {
        this.jumpEndDates(val[0]);
        [this.startDatePicker.flatpickr.config.maxDate] = val;

        this.studyService.endDate.next(val[0]);
        if (val[0]) {
          this.endDateUTC = moment(val[0]).utc(false);
        }

        this.addFilterOptions();
        this.postStudySitesGrid(this.id, {
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          filterOptions: this.filterOptions,
          sortOptions: this.currentSort ? [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }] : null,
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: val[0] ? this.endDateUTC : null
        });
      });
  }

  toggleEndDate(): void {
    this.endDatePicker.flatpickr.toggle();
  }

  toggleStartDate(): void {
    this.startDatePicker.flatpickr.toggle();
  }

  jumpStartDates(value: any): void {
    if (value) {
      if (!this.endDateFilter.value[0]) {
        this.endDatePicker.flatpickr.jumpToDate(value);
      }
    }
    else if (this.endDateFilter.value[0]) {
      this.startDatePicker.flatpickr.jumpToDate(this.endDateFilter.value[0]);
    }
    else {
      this.endDatePicker.flatpickr.jumpToDate(new Date());
    }
  }

  jumpEndDates(value: any): void {
    if (value) {
      if (!this.startDateFilter.value[0]) {
        this.startDatePicker.flatpickr.jumpToDate(value);
      }
    }
    else if (this.startDateFilter.value[0]) {
      this.endDatePicker.flatpickr.jumpToDate(this.startDateFilter.value[0]);
    }
    else {
      this.startDatePicker.flatpickr.jumpToDate(new Date());
    }
  }

  addFilterOptions(): void {
    if (this.startDateFilter.value[0] || this.endDateFilter.value[0]) {
      if (!this.filterOptions.length) {
        const filterOption = [
          {
            field: StudySiteFilterOptions.expirationDate
          }
        ];

        this.filterOptions = filterOption;
      }
    }
    else {
      this.filterOptions = [];
    }
  }

  public modalCancelClick(): void {
    this.showOutcomeDocumentModal = false;
  }

  handleChangePage(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;

    this.postStudySitesGrid(this.id, {
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      filterOptions: this.filterOptions,
      sortOptions: this.currentSort ? [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }] : null,
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  handleSorting(event): void {
    this.currentSort = SitesSortOptions[event.detail.field];
    this.currentSortDescending = !event.detail.ascending;
    this.currentPage = 1;

    this.postStudySitesGrid(this.id, {
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      filterOptions: this.filterOptions,
      sortOptions: [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }],
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  private getStudy(id): void {
    this.apiService.getStudy(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.study = this.studyService.mapStudyData(data);
      }, () => {
        this.toastService.add([{
          closable: true,
          id: 'getStudy',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          variant: 'error'
        }]);
      });
  }

  private postStudySitesGrid(id, body): void {
    this.loading = true;
    this.gridFilters = body;

    this.apiService.postStudySites(id, body)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (data.records && data.records.length > 0) {
          this.initialSites = data.records;
        }

        this.sites = this.studyService.mapSitesData(data.records);
        this.tableData = this.sites;
        this.loaded = true;
        this.loading = false;
        this.sitesFetched = true;
        this.totalRecords = data.totalRecords;
        this.totalPages = Math.ceil(data.totalRecords / data.pageSize);
        this.currentPage = data.currentPage;
      }, (error) => {
        this.loaded = true;
        this.loading = false;
        this.sitesFetched = true;
        if (error.details.status === ServerErrorCodes.unauthorizedStatusNumber) {
          if (this.initialSites?.length === 0) {
            this.pageUnauthorized = true;
          }
          else {
            this.tableData = [];
          }
        }
        else {
          this.toastService.add([{
            closable: true,
            id: 'getStudySites',
            message: this.translatePipe.transform('serverErrors.internalServerError'),
            variant: 'error'
          }]);
        }
      });
  }

  private getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('studySites.piName'), 'piNameLink', true),
      this.utilService.createTableHeader(this.translatePipe.transform('studySites.piOrganization'), 'piOrganizationName', true),
      this.utilService.createTableHeader(
        this.translatePipe.transform('studySites.institutionTrackingId'),
        'institutionalTrackingNumber',
        true
      ),
      this.utilService.createTableHeader(this.translatePipe.transform('studySites.country'), 'countryName', true),
      this.utilService.createTableHeader(
        this.translatePipe.transform('studySites.siteExpiration'), 'expirationDate', true, 'date', this.utilService.dateFormat
      ),
      this.utilService.createTableHeader(this.translatePipe.transform('studySites.siteStatus'), 'status', true),
      this.utilService.createTableHeader(this.translatePipe.transform('studySites.outcomeDocuments'), 'outcomeDocumentsDetails', false)
    ];
  }
}
