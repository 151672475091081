import { Component, OnInit } from '@angular/core';
import { takeUntil, debounce } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

import { BaseComponent } from '../../../components/base/base.component';
import { ApiService } from '../../../services/api.service';
import { SiteService } from '../site.service';
import { ToastService } from '../../../services/toast.service';
import { UtilService } from '../../../services/util.service';
import { TableHeader } from 'src/app/interfaces/components';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { DEBOUNCE_TIME, SiteWorkspaceContactSortOptions } from 'src/app/common/collections';
import { timer } from 'rxjs';
import { SiteContactsMap } from 'src/app/interfaces/sites';
import { GridOptionsSearchModel } from 'src/app/admin/shared/model/grid.model';
import { GridExportType } from 'src/app/common/reportingCollections';

@Component({
  selector: 'app-site-contacts',
  templateUrl: './site-contacts.component.html',
  styleUrls: ['./site-contacts.component.scss']
})

export class SiteContactsComponent extends BaseComponent implements OnInit {
  tableData: Array<SiteContactsMap>;
  loaded: boolean;
  loading: boolean;
  id: string;
  searchTerm = new FormControl('');
  contacts: Array<SiteContactsMap>;
  contactsFetched = false;
  headers: Array<TableHeader>;
  initialContacts: Array<SiteContactsMap> = [];
  gridFilters: GridOptionsSearchModel;
  totalRecords: number;
  readonly exportType: GridExportType = GridExportType.SiteDetailsContacts;

  currentPage: number;
  totalPages: number;
  currentPageSize = 10;

  currentSort: string;
  currentSortDescending = true;

  options = {
    manualPaginationAndSort: true,
    rowsPerPage: 10
  };

  constructor(
    private apiService: ApiService,
    private router: Router,
    private siteService: SiteService,
    public utilService: UtilService,
    private toastService: ToastService,
    private translatePipe: TranslatePipe
  ) {
    super();

    [this.id] = this.router.url.split('/sites/')[1].split('/');

    this.siteService.searchTerm.next('');

    this.postSiteContactsGrid(this.id, {
      searchTerm: '',
      page: 1,
      pageSize: this.currentPageSize
    });
    this.headers = this.getHeaders();
  }

  ngOnInit(): void {
    this.searchTerm.valueChanges
      .pipe(
        debounce(() => timer(DEBOUNCE_TIME))
      )
      .subscribe((val) => {
        this.siteService.searchTerm.next(val);
        this.postSiteContactsGrid(this.id, {
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: val
        });
      });
  }

  handleChangePage(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;

    this.postSiteContactsGrid(this.id, {
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: this.currentSort ? [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }] : null
    });
  }

  handleSorting(event): void {
    this.currentSort = SiteWorkspaceContactSortOptions[event.detail.field];
    this.currentSortDescending = !event.detail.ascending;
    this.currentPage = 1;

    this.postSiteContactsGrid(this.id, {
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }]
    });
  }

  private getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('siteContacts.contactName'), 'contactName', true),
      this.utilService.createTableHeader(this.translatePipe.transform('siteContacts.permissionType'), 'permissionType', true),
      this.utilService.createTableHeader(this.translatePipe.transform('siteContacts.email'), 'email', true),
      this.utilService.createTableHeader(this.translatePipe.transform('siteContacts.organizationName'), 'organizationName', true),
      this.utilService.createTableHeader(this.translatePipe.transform('siteContacts.organizationType'), 'organizationType', true)
    ];
  }

  private postSiteContactsGrid(id, body): void {
    this.loading = true;
    this.gridFilters = body;
    this.apiService.getSiteContacts(id, body)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (data.records && data.records.length > 0) {
          this.initialContacts = data.records;
        }

        this.totalRecords = data.totalRecords;
        this.contacts = this.siteService.mapContactsData(data.records);
        this.contactsFetched = true;
        this.tableData = this.contacts;
        this.loaded = true;
        this.loading = false;
        this.totalPages = Math.ceil(data.totalRecords / data.pageSize);
        this.currentPage = data.currentPage;
      }, () => {
        this.loaded = true;
        this.loading = false;
        this.contactsFetched = true;

        this.toastService.add([{
          closable: true,
          id: 'getSiteContacts',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          variant: 'error'
        }]);
      });
  }
}
