<div *ngIf="study">
  <div class="wcg-container">
    <div class="wcg-row wcg-between-md cnxs-page-header">
      <div class="wcg-col-xs-4 wcg-col-md-6 wcg-col-lg-10">
        <h1 class="wcg-h2 wcg-rhythm-5 cnxs-wrap-content">{{study?.name}}</h1>
      </div>
      <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-2 wcg-end-md">
        <a *ngIf="permissions?.canManageWorkspaceUsers" routerLink="/studies/{{id}}/manage-contacts" class="wcg-btn wcg-btn-large cnxs-page-header-btn">{{'manageContacts.manageContacts' | translate}}</a>
      </div>
    </div>

    <dl class="wcg-rhythm-3">
      <div class="wcg-row">
        <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
          <dt class="wcg-body-label">{{ 'labels.sponsor' | translate }}</dt>
          <dd class="cnxs-wrap-content">{{ utilService.replaceNull(study?.sponsor) }}</dd>
        </div>

        <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
          <dt class="wcg-body-label">{{ 'labels.sponsorProtocolId' | translate }}</dt>
          <dd class="cnxs-wrap-content">{{ utilService.replaceNull(study?.sponsorProtocolID) }}</dd>
        </div>

        <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
          <dt class="wcg-body-label">{{ 'labels.initialApproval' | translate }}</dt>
          <dd class="cnxs-wrap-content">{{ utilService.replaceNull(study?.initialApproval) }}</dd>
        </div>

        <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
          <dt class="wcg-body-label">{{ 'labels.lastReview' | translate }}</dt>
          <dd class="cnxs-wrap-content">{{ utilService.replaceNull(study?.lastReview) }}</dd>
        </div>
      </div>

      <div class="wcg-row">
        <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
          <dt class="wcg-body-label">{{ 'labels.irbTrackingId' | translate }}</dt>
          <dd class="cnxs-wrap-content">{{ utilService.replaceNull(study?.irbTrackingNumber) }}</dd>
        </div>

        <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
          <dt class="wcg-body-label">{{ 'labels.studyExpiration' | translate }}</dt>
          <dd class="cnxs-wrap-content">{{ utilService.formatDate(study?.studyExpiration) ||  utilService.replaceNull(study?.studyExpiration) }}</dd>
        </div>

        <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
          <dt class="wcg-body-label">{{ 'labels.status' | translate }}</dt>
          <dd class="cnxs-wrap-content">{{ utilService.replaceNull(study?.status) + (study?.status === closedStatus && study?.studyClosedDate ? ": " + study?.studyClosedDate :'')}}</dd>
        </div>
      </div>
    </dl>

    <div class="wcg-row wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-12">
      <button type="button" (click)="onAddDocumentsSelect()" [disabled]="!this.allDataFetched" *ngIf="canAddDocuments | async"
        class="wcg-btn wcg-btn-x-large cnxs-page-header-btn" style="margin-left: 0px;">{{ 'submissionLanding.addTo' | translate }}</button>
      <button type="button" (click)="onAddPISelect()" [disabled]="!this.allDataFetched" *ngIf="canAddPi | async"
        class="wcg-btn wcg-btn-x-large cnxs-page-header-btn">{{ 'submissionLanding.addPrincipal' | translate }}</button>
    </div>
  </div>

  <section class="cnxs-secondary-section">
    <div class="wcg-container">
      <wcg-tab-bar-section>
        <wcg-tab-bar>
          <a [routerLink]="['sites']" routerLinkActive="wcg-active" class="wcg-link">
            {{ 'tabs.sites' | translate }}
          </a>
          <a [routerLink]="['submissions']" routerLinkActive="wcg-active" class="wcg-link">
            {{ 'tabs.submissions' | translate }}
          </a>
          <a [routerLink]="['outcome-documents']" routerLinkActive="wcg-active" class="wcg-link">
            {{ 'tabs.outcomeDocuments' | translate }}
          </a>
          <a [routerLink]="['contacts']" routerLinkActive="wcg-active" class="wcg-link">
            {{ 'tabs.contacts' | translate }}
          </a>
        </wcg-tab-bar>
        <div>
          <router-outlet></router-outlet>
        </div>
      </wcg-tab-bar-section>
    </div>
  </section>
  <wcg-loader [show]="!allDataFetched"></wcg-loader>
</div>
