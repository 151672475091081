/* eslint-disable max-lines */
export enum CountryCodes {
  'UNITED_STATES' = 'USA',
  'CANADA' = 'CAN'
}

export enum FlowType {
  None = 0,
  NewStudy = 1,
  NewSite = 2,
  ExistingStudy = 3,
  ExistingSite = 4
}

export enum FlowTypeName {
  NewStudy = 'New Study',
  NewSite = 'New Site',
  ExistingStudy = 'Existing Study',
  ExistingSite = 'Existing Site'
}

export const FlowTypeReadable = {
  1: 'New Study',
  2: 'New Site',
  3: 'Existing Study, Existing Site',
  4: 'Existing Study, Existing Site'
};

export const ExampleDocuments = [
  'Protocol Amendment',
  'Investigator’s Brochure (Drug or Device Brochure)',
  'Consent Form Updates',
  'Product Safety Update',
  'Continuing Review Report',
  'Promptly Reportable Information Report',
  'Site Closure',
  'Ads/Subject Materials',
  'Translations Requests',
  'Change In Investigator'
];

export enum DocumentRequestTypes {
  None = 0,
  Submission = 1,
  Study = 2,
  Site = 3,
  Outcome = 4,
}


export enum PermissionType {
  'None' = 0,
  'View' = 1,
  'Edit' = 2,
  'Manage' = 3,
  'SiteParticipant' = 4
}

export enum PermissionTypeViewValues {
  'None' = '',
  'Manage' = 'Manager',
  'Edit' = 'Submitter',
  'View' = 'Read only',
  'SiteParticipant' = 'Site Participant'
}

export enum UsersSortOptions {
  lastName = 'UserLastName',
  firstName = 'UserFirstName',
  createdDateTimeOffset = 'UserCreatedDateTimeOffset',
  lastActivityDate = 'UserLastActivityDate',
  email = 'UserEmail',
  organizationName = 'UserOrganizationName',
  organizationType = 'UserOrganizationType',
  enabled = 'UserStatus'
}

export enum UsersFilterOptions {
  UserCreatedDate = 'UserCreatedDate',
  UserLastActivityDate = 'UserLastActivityDate',
  UserOrganizationType = 'UserOrganizationType',
  UserStatus = 'UserStatus'
}

export enum SubmissionsSortOptions {
  irbTrackingNumber = 'StudyIrbTrackingNumber',
  nameLink = 'SubmissionTitle',
  piName = 'SubmissionSiteTitle',
  sponsor = 'Sponsor',
  protocolNumber = 'SponsorProtocolNumber',
  status = 'SubmissionStatus',
  submittedDate = 'SubmissionSubmittedDate',
  type = 'SubmissionType',
  createdDate = 'SubmissionCreatedDate',
  title = 'SubmissionTitle',
  principalInvestigators = 'SubmissionSiteTitle',
  reviewOutcome = 'SubmissionReviewOutcome',
}

export enum StudiesSortOptions {
  nameLink = 'StudyTitle',
  sponsor = 'Sponsor',
  protocolNumber = 'SponsorProtocolNumber',
  irbTrackingNumber = 'StudyIrbTrackingNumber',
  lastReviewDate = 'StudyLastReviewDate',
  expirationDate = 'StudyExpirationDate',
  status = 'StudyStatus',
}

export enum TeamStudiesSortOptions {
  nameLink = 'StudyTitle',
  sponsor = 'Sponsor',
  protocolNumber = 'SponsorProtocolNumber',
  irbTrackingNumber = 'StudyIrbTrackingNumber',
  lastReviewDate = 'StudyLastReviewDate',
  expirationDate = 'StudyExpirationDate',
  status = 'StudyStatus',
  numberOfSitesLink = 'NumberOfSites',
  requestStatus = 'RequestStatus'
}

export enum SitesSortOptions {
  nameLink = 'StudySiteTitle',
  sponsor = 'Sponsor',
  sponsorProtocolNumber = 'StudySiteStudySponsorProtocolNumber',
  irbTrackingNumber = 'StudySiteStudyIrbTrackingNumber',
  institutionalTrackingNumber = 'StudySiteInstitutionalTrackingNumber',
  status = 'StudySiteStatus',
  piOrganizationName = 'StudySiteOrganizationName',
  countryName = 'StudySiteCountry',
  piNameLink = 'StudySiteTitle',
  expirationDate = 'StudySiteExpirationDate',
  requestStatus = 'RequestStatus'
}

export enum SubmissionsDashboardStatistic {
  actionRequired = 'ActionRequired',
  boardReview = 'Board Review',
  finalizingDocuments = 'Finalizing Documents',
  notFullyApproved = 'See Outcome Documents',
  noActionNeeded = 'No Action Needed',
  fullyApproved = 'Fully Approved',
  outcomeComplete = 'No Action Needed,Fully Approved',
  preparingForBoardReview = 'Preparing for Board Review',
  received = 'Received'
}

export const DEBOUNCE_TIME = 300;
export const MAX_LENGTH = 255;
export const COMPANYID_MAX_LIMIT = 2147483647;
export const POSTAL_LENGTH = 30;
export const CITY_LENGTH = 75;
export const PROVINCE_LENGTH = 150;
export const EMAIL_LENGTH = 450;

export const Prefixes = [
  {
    key: 'Mr.',
    value: 'Mr.'
  },
  {
    key: 'Mrs.',
    value: 'Mrs.'
  },
  {
    key: 'Ms.',
    value: 'Ms.'
  },
  {
    key: 'Dr.',
    value: 'Dr.'
  }
];

export const Suffixes = [
  {
    key: 'Jr.',
    value: 'Jr.'
  },
  {
    key: 'Sr.',
    value: 'Sr.'
  },
  {
    key: 'II',
    value: 'II'
  },
  {
    key: 'III',
    value: 'III'
  },
  {
    key: 'IV',
    value: 'IV'
  },
  {
    key: 'V',
    value: 'V'
  },
  {
    key: 'VI',
    value: 'VI'
  }
];

/* eslint-disable max-len */
export const regexRules = [
  {
    tag: 'AlphaNumericRegex',
    rule: '^[a-zA-Zα-ωΑ-Ω0-9`–_,.!%@#&|{}()$[\\]\'\u2070\u00b9\u00b2\u00b3\u2074\u2075\u2076\u2077\u2078\u2079\\s\\n-]*$',
    message: 'This field may not contain special characters such as *, ?, +, “, or >.'
  },
  {
    tag: 'PermissiveAlphaNumericRegex',
    rule: '^[^éĔëöò\\\\*?\\"<>|;]*$',
    message: 'This field may not contain special characters such as \\\\, *, ?, \\", <, >, |, ;, é, Ĕ, ë, ö or ò.'
  },
  {
    tag: 'FileNameRegex',
    rule: '[<>:"/\\\\|?*\t\n\r]',
    message: 'This field may not contain special characters such as \\\\, ?, /, <, >, :, ", |, ?, *.'
  },
  {
    tag: 'NumericRegex',
    rule: '^[0-9]*$',
    message: 'This field may contain only numeric values'
  }
];
/* eslint-enable max-len */

export enum RegexTags {
  AlphaNumericRegex = 'AlphaNumericRegex',
  PermissiveAlphaNumericRegex = 'PermissiveAlphaNumericRegex',
  FileNameRegex = 'FileNameRegex',
  NumericRegex = 'NumericRegex'
}

/* eslint-disable prefer-named-capture-group */
/* eslint-disable-next-line max-len */
export const emailRegex = /^[A-Za-z0-9'"_+-]+(\.[A-Za-z0-9_'+-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

export const AllowedExtensions = ['doc',
  'docx',
  'xls',
  'xlsx',
  'pdf',
  'ppt',
  'pptx',
  'msg',
  'htm',
  'html',
  'pub',
  'pubx',
  'png',
  'bmp',
  'tif',
  'gif',
  'jpeg',
  'jpg',
  'avi',
  'm4v',
  'mov',
  'mp3',
  'wav',
  'wma',
  'mp4',
  'mpg',
  'mpeg',
  'swf',
  'wmv',
  'rtf',
  'tiff',
  'txt'];

export const AllowedPasswordProtectedExtensions = [
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'pdf'
];

export const sizeLimit = '1073741824';
export const passwordProtectedFileSizeLimit = '104857600';

export class AnswerOptions {
  public static readonly yes = 'yes';
  public static readonly no = 'no';
}

export class Forms {
  public static readonly translationRequestForm = 'Translation Request';
  public static readonly initialReviewForm = 'Initial Review';
  public static readonly promptlyReportableForm = 'Promptly Reportable Disclosure';
  public static readonly changeInFinancialInterestDisclosure = 'changeInFinancialInterestDisclosure';
  public static readonly siteClosureForm = 'Site Closure Form';
  public static readonly changeInResearchForm = 'Change In Research';
  public static readonly financialInterestDisclosure = 'financialInterestDisclosure';
}

export class SubmissionTypeNames {
  public static readonly hudClinicalUseClosure = 'Closure - HUD Clinical Use';
  public static readonly contactUpdate = 'Contact Update';
  public static readonly continuingReview = 'Continuing Review';
  public static readonly notListed = 'Not Listed';
  public static readonly clinicalHudIR = 'Clinical Use of Humanitarian Use Device (HUD)';
  public static readonly singlePatientExpanded = 'Single Patient Expanded Access Program/Compassionate or Emergency Use'
  public static readonly newStudyForIR = 'A New Study for Initial Review';
  public static readonly newSiteForIR = 'A New Site for Initial Review';
  public static readonly exemptionRequest = 'Exemption Request';
  public static readonly changeInInvestigator = 'Change In Investigator';
  public static readonly siteClosure = 'Closure - Site'
}

export const flatPickrDateFormat = 'd-M-Y';

export enum StudyWorkspaceOutcomeDocumentsSortOptions {
  fileNameLink = 'WorkspaceOutcomeDocumentFileName',
  reviewedDate = 'WorkspaceOutcomeDocumentReviewed',
  transmittedDate = 'WorkspaceOutcomeDocumentTransmitted',
  documentType = 'WorkspaceOutcomeDocumentDocumentType'
}

export enum StudyWorkspaceContactSortOptions {
  contactName = 'WorkspaceUserName',
  permissionType = 'WorkspaceUserPermissionType',
  email = 'WorkspaceUserEmail',
  organizationName = 'WorkspaceUserOrganizationName',
  organizationType = 'WorkspaceUserOrganizationType'
}

export enum TeamMembersFilterOptions {
  joinedDate = 'CreatedDate'
}


export enum TeamMembersSortOptions{
  name = 'UserName',
  email = 'UserEmail',
  permissionType = 'PermissionType',
  workspacePermissionType = 'WorkspaceUserPermissionType',
  organizationName = 'OrganizationName',
  joinedDate = 'CreatedDate',
  requestStatus = 'RequestStatus'
}

export enum StudiesFilterOptions {
  lastReviewDate = 'StudyLastReviewDate',
  expirationDate = 'StudyExpirationDate',
}

export enum SubmissionsFilterOptions {
  submissionSubmittedDate = 'SubmissionSubmittedDate',
}

export enum StudySiteFilterOptions {
  expirationDate = 'StudySiteExpirationDate'
}

export enum StudySubmissionFilterOptions {
  submittedDate = 'SubmissionSubmittedDate',
}

export enum StudySubmissionSortOptions {
  title = 'SubmissionTitle',
  type = 'SubmissionType',
  principalInvestigators = 'SubmissionSiteTitle',
  submittedDate = 'SubmissionSubmittedDate',
  status = 'SubmissionStatus',
  reviewOutcome = 'SubmissionReviewOutcome'
}

export enum StudyOutcomeDocumentFilterOptions {
  reviewedDate = 'WorkspaceOutcomeDocumentReviewed',
  transmittedDate = 'WorkspaceOutcomeDocumentTransmitted',
  workspaceType = 'WorkspaceType'
}

export enum SubmissionSitesSortOptions {
  investigator = 'StudySiteTitle',
  institutionalTrackingNumber = 'StudySiteInstitutionalTrackingNumber',
  piOrganizationName = 'StudySiteOrganizationName',
  countryName = 'StudySiteCountry'
}

export enum SubmissionSubmittedDocumentSortOptions {
  fileNameLink = 'SubmittedDocumentFileName',
  type = 'SubmittedDocumentFileType'
}

export enum SubmissionWorkspaceOutcomeDocumentsSortOptions {
  fileNameLink = 'WorkspaceOutcomeDocumentFileName',
  transmittedDate = 'WorkspaceOutcomeDocumentTransmitted',
  reviewedDate = 'WorkspaceOutcomeDocumentReviewed',
  documentType = 'WorkspaceOutcomeDocumentDocumentType'
}

export enum WorkspaceOutcomeDocumentDetailsModalSortOptions {
  piFullName = 'PIName',
  piOrganizationName = 'OrganizationName',
  reviewedDate = 'WorkspaceOutcomeDocumentReviewed',
  transmittedDate = 'WorkspaceOutcomeDocumentTransmitted',
  institutionalTrackingNumber = 'StudySiteInstitutionalTrackingNumber',
  countryName = 'StudySiteCountry'
}

export enum WorkspaceOutcomeDocumentDetailsModalFilterOptions {
  reviewDate = 'WorkspaceOutcomeDocumentReviewed',
  transmittedDate = 'WorkspaceOutcomeDocumentTransmitted',
  workspaceType = 'WorkspaceType'
}

export enum SubmissionWorkspaceOutcomeDocumentsFilterOptions {
  transmittedDate = 'WorkspaceOutcomeDocumentTransmitted',
  reviewedDate = 'WorkspaceOutcomeDocumentReviewed',
  workspaceType = 'WorkspaceType'
}

export enum StudySiteWorkspaceOutcomeDocumentsSortOptions {
  fileNameLink = 'WorkspaceOutcomeDocumentFileName',
  reviewedDate = 'WorkspaceOutcomeDocumentReviewed',
  transmittedDate = 'WorkspaceOutcomeDocumentTransmitted',
  documentType = 'WorkspaceOutcomeDocumentDocumentType'
}

export enum SiteWorkspaceContactSortOptions {
  contactName = 'WorkspaceUserName',
  permissionType = 'WorkspaceUserPermissionType',
  email = 'WorkspaceUserEmail',
  organizationName = 'WorkspaceUserOrganizationName',
  organizationType = 'WorkspaceUserOrganizationType'
}

export enum SiteSubmissionFilterOptions {
  submittedDate = 'SubmissionSubmittedDate',
}

export enum SiteSubmissionSortOptions {
  titleLink = 'SubmissionTitle',
  type = 'SubmissionType',
  submittedDate = 'SubmissionSubmittedDate',
  status = 'SubmissionStatus',
  reviewOutcome = 'SubmissionReviewOutcome'
}

export enum SiteOutcomeDocumentFilterOptions {
  reviewedDate = 'WorkspaceOutcomeDocumentReviewed',
  transmittedDate = 'WorkspaceOutcomeDocumentTransmitted',
}

export enum ServerErrorCodes {
  accessDenyErrorStatusNumber = 403,
  notFoundErrorStatusNumber = 404,
  unauthorizedStatusNumber = 401,
  goneClientErrorStatusNumber = 410,
  internalServerErrorStatusNumber = 500,
  businessLayerErrorStatusNumber = 400
}

export enum KnownScope {
  None = 0,
  All = 1,
  Connexus = 2,
  BO = 3,
  Teams = 4,
}

export enum TeamFilterOptions {
  status = 'OrganizationStatus',
  type = 'OrganizationType',
  createdDate = 'CreatedDate'
}

export enum TeamSortOptions {
  name = 'OrganizationName',
  type = 'OrganizationType',
  primaryUser = 'TeamPrimaryUser',
  email = 'OrganizationEmail',
  studies = 'StudyCount',
  sites = 'SiteCount',
  teamMembers = 'TeamMemberCount',
  createdBy = 'CreatedBy',
  status = 'OrganizationStatus',
  createdDate = 'CreatedDate',
  companyId = 'CompanyId'
}

export enum FilterOperator {
  contains = 'Contains',
  notContains = 'NotContains',
  startsWith = 'StartsWith',
  endsWith = 'EndsWith',
  equals = 'Equals',
  doesntEquals = 'DoesntEquals',
  lessThan = 'LessThan',
  lessThanOrEquals = 'LessThanOrEquals',
  greaterThan = 'GreaterThan',
  greaterThanOrEquals = 'GreaterThanOrEquals'
}

export enum RequestStatus {
  None = 0,
  InProgress = 1,
  Complete = 2,
  Failed = 3,
  Requested = 4,
  Rejected = 5
}

export enum RequestStatusLabel {
  'None' = '',
  'InProgress' = 'In Progress',
  'Complete' = 'Complete',
  'Failed' = 'Failed',
  'Requested' = 'Requested',
  'Rejected' = 'Rejected'
}

export enum WorkspaceType {
  Study = 'Study',
  Site = 'Site'
}

export enum WorkspaceTypeSearchGrid {
  None = '0',
  Study = '1',
  Site = '2'
}

export enum AddWorkspaceTypeModal {
  study = 'study',
  studysite = 'studysite'
}

export enum TeamGridType {
  study = 'study',
  site = 'site',
  user = 'user'
}

export enum RequestAccessEntityType {
  'none',
  'study',
  'site',
  'submission'
}

export enum SubmissionSource {
  Online = 1,
  Tracking = 2,
  WebService = 3,
  Email = 4,
  SmartForm = 5,
  Fax = 6,
  External = 7,
  API = 8,
  IRBConnexus = 9
}

export enum SubmissionSourceViewValues {
  'Online' = 'Online',
  'Tracking' = 'Tracking',
  'WebService' = 'WebService',
  'Email' = 'Email',
  'SmartForm' = 'SmartForm',
  'Fax' = 'Fax',
  'External' = 'External',
  'API' = 'API',
  'IRBConnexus' = 'IRBConnexus'
}

export enum AdminSubmissionsFilterOptions {
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  IrbTrackingId = 'IRBTrackingId',
  SubmissionType = 'SubmissionType',
  SubmitterName = 'SubmitterName',
  CompanyName = 'CompanyName',
  Sponsor = 'Sponsor',
  PIName = 'PIName',
  EnvelopeId = 'EnvelopeID',
  ConfirmationID = 'ConfirmationID',
  SubmissionSourceField = 'SubmissionSource',
  SubmissionName = 'SubmissionName',
  SpecialInstruction = 'SpecialInstructions'
}

export enum AdminSubmissionsSortOptions {
  submittedDate = 'SubmissionSubmittedDate',
  confirmationId = 'SubmissionConfirmationId',
  envelopeId = 'SubmissionEnvelopeId',
  submissionType = 'SubmissionType',
  irbTrackingId = 'StudyIrbTrackingNumber',
  sponsor = 'Sponsor',
  piName = 'PIName',
  submitterName = 'SubmissionSubmittedBy',
  submitterCompanyName = 'SubmissionSubmitterCompanyName',
  submissionName = 'SubmissionTitle',
  specialInstructions = 'SubmissionNotes',
  source = 'SubmissionSource'
}

export const SubmissionSources = [
  {
    value: '1',
    viewValue: SubmissionSourceViewValues[SubmissionSource[1]]
  },
  {
    value: '2',
    viewValue: SubmissionSourceViewValues[SubmissionSource[2]]
  },
  {
    value: '3',
    viewValue: SubmissionSourceViewValues[SubmissionSource[3]]
  },
  {
    value: '4',
    viewValue: SubmissionSourceViewValues[SubmissionSource[4]]
  },
  {
    value: '5',
    viewValue: SubmissionSourceViewValues[SubmissionSource[5]]
  },
  {
    value: '6',
    viewValue: SubmissionSourceViewValues[SubmissionSource[6]]
  },
  {
    value: '7',
    viewValue: SubmissionSourceViewValues[SubmissionSource[7]]
  },
  {
    value: '8',
    viewValue: SubmissionSourceViewValues[SubmissionSource[8]]
  },
  {
    value: '9',
    viewValue: SubmissionSourceViewValues[SubmissionSource[9]]
  }
];

export enum KnownFileGenerationStatus {
  InProgress = 0,
  Complete = 1,
  Failed = 2,
  Incomplete = 3
}

export const SubmissionRegions = [
  {
    value: 'US/International Review (not Canada)',
    pdfViewValue: 'US/International'
  },
  {
    value: 'Canadian Review',
    pdfViewValue: 'Canada'
  }
];
