/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { TeamStudiesGridRecordMap, TeamStudiesGrid } from 'src/app/interfaces/teams';
import { UtilService } from 'src/app/services/util.service';
import { BehaviorSubject } from 'rxjs';
import { RequestStatus, RequestStatusLabel, TeamGridType } from 'src/app/common/collections';
import { TeamService } from '../team.service';


@Injectable({
  providedIn: 'root'
})
export class TeamStudiesService {
  public searchTerm = new BehaviorSubject<string>('');

  constructor(
    private utilService: UtilService,
    private teamService: TeamService
  ) { }

  public mapStudiesGridData(data: TeamStudiesGrid): Array<TeamStudiesGridRecordMap> {
    return data.records.map((record) => ({
      name: record.name,
      nameLink: this.utilService.addTableCellWrapperWithSearchTerms(
        record.name,
        this.searchTerm.value,
        `studies/${record.id}`
      ),
      sponsor: this.utilService.addTableCellWrapperWithSearchTerms(record.sponsor, this.searchTerm.value),
      protocolNumber: this.utilService.addTableCellWrapperWithSearchTerms(record.protocolNumber, this.searchTerm.value),
      initialApprovalDate: this.utilService.addTableCellWrapper(this.utilService.formatDate(record.initialApprovalDate)),
      irbTrackingNumber: this.utilService.addTableCellWrapperWithSearchTerms(record.irbTrackingNumber, this.searchTerm.value),
      status: this.utilService.addTableCellWrapperWithSearchTerms(record.status, this.searchTerm.value),
      numberOfSites: record.numberOfSites,
      numberOfSitesLink: this.utilService.addTableCellWrapperWithSearchTerms(
        record.numberOfSites,
        this.searchTerm.value,
        `studies/${record.id}/sites`
      ),
      requestStatus: `${this.utilService.addRequestStatusTableCellWrapperWithSearchTerms(
        RequestStatusLabel[RequestStatus[record.requestStatus]], this.searchTerm.value, TeamGridType.study,
        this.teamService.hasManagePermission.value || this.teamService.isAdmin.value
      )}`,
      workspaceOrganizationId: record.workspaceOrganizationId,
      workspaceExternalReferenceId: record.workspaceExternalReferenceId
    }));
  }
}
