<form class="wcg-row wcg-middle-xs" [formGroup]="inviteForm">
  <div class="wcg-col-md">
    <wcg-form-field>
      <wcg-label *ngIf="canViewUsers">{{'manageContacts.addUsersAdmin' | translate}}</wcg-label>
      <wcg-label *ngIf="!canViewUsers">{{'manageContacts.addUsers' | translate}}</wcg-label>
      <wcg-autocomplete formControlName="selectedUsers" [multiple]="true" [options]="allUsers" required>
      </wcg-autocomplete>
        <wcg-notification class="red-warning" variant="warning" *ngIf="showHasInactiveUsersNotification">
          <span>{{'manageContacts.inactiveUserWarningMessage' | translate}}</span>
        </wcg-notification>

      <wcg-error-message *ngIf="inviteFormSubmitted && !inviteForm.controls.selectedUsers.valid">
        {{'manageContacts.selectedUsersError' | translate}}
      </wcg-error-message>
    </wcg-form-field>
  </div>

  <div class="wcg-col-md">
    <wcg-form-field>
      <wcg-label>{{'manageContacts.permissions' | translate}}</wcg-label>
      <wcg-select formControlName="permissions" [value]="inviteForm.controls.permissions.value" required>
        <option hidden selected value=""></option>
        <ng-container *ngIf="!isSite">
          <option *ngFor="let item of permissionOptionsForStudy" value="{{item.value}}">{{item.viewValue}}</option>
        </ng-container>
        <ng-container *ngIf="isSite">
          <option *ngFor="let item of permissionOptionsForSite" value="{{item.value}}">{{item.viewValue}}</option>
        </ng-container>

      </wcg-select>

      <wcg-error-message *ngIf="inviteFormSubmitted && inviteForm.controls.permissions.invalid">
        {{'manageContacts.permissionsError' | translate}}
      </wcg-error-message>
    </wcg-form-field>
  </div>

  <div class="wcg-col-md">
    <button class="wcg-btn invite-btn" (click)="onSubmit()">{{'manageContacts.invite' | translate}}</button>
  </div>
</form>

<wcg-table class="wcg-rhythm-4" [headers]="headers" [data]="users" [options]="options"
  (rowSelected)="handleRowSelection($event)" (rowClicked)="handleRowClick($event)">
  <div class="cnxs-table-loader" slot="table-body">
    <wcg-loader [show]="loading"></wcg-loader>
  </div>
  <button class="wcg-btn wcg-btn-warn wcg-btn-large" [disabled]="!selected.length"
    (click)="deleteBtnClick()">{{'manageContacts.removeSelected' | translate}}</button>
</wcg-table>

<wcg-modal id="modal" [show]="showDeleteModal" (close)="showDeleteModal = false">
  <wcg-modal-header>{{'manageContacts.modalHeader' | translate}}</wcg-modal-header>
  <wcg-modal-body>
    <div>
      <h3 *ngIf="isSingleContactRemoval() && !isSite || (isSite && selectedTeamToRemoved.length && isSingleContactRemoval() )">{{'manageContacts.modalBodySingleContactRemoval' | translate}}</h3>
      <h3 *ngIf="isMultipleContactRemovals() && !isSite || (isSite && selectedTeamToRemoved.length && isMultipleContactRemovals())" >{{'manageContacts.modalBodyMultipleContactRemovals' | translate}}</h3>
      <h3 *ngIf="isSingleContactRemoval() && isSite && !selectedTeamToRemoved.length">{{'manageContacts.modalBodySingleContactRemovalForSite' | translate}}</h3>
      <h3 *ngIf="isMultipleContactRemovals() && isSite && !selectedTeamToRemoved.length">{{'manageContacts.modalBodyMultipleContactRemovalForSite' | translate}}</h3>
    </div>

    <div *ngIf="selectedTeamToRemoved.length">
      <p>{{ 'manageContacts.teamRemove' | translate}}</p>
      <div class="wcg-rhythm-2" *ngFor="let team of selectedTeamToRemoved">
        <div class="wcg-row">
          <div class="wcg-col-md-2" style="text-align: end;">{{ 'teams.teamToBeRemove' | translate}}: </div>
          <div class="wcg-col-md-6" style="font-weight: bold;"> {{team.teamName}} </div>
        </div>
        <div class="wcg-row">
          <div class="wcg-col-md-2" style="text-align: end;"> {{ 'teams.members' | translate}}: </div>
          <div class="wcg-col-md-6" style="font-weight: bold;"> {{team.members}}</div>
        </div>
      </div>
    </div>
  </wcg-modal-body>
  <wcg-modal-footer>
    <button class="wcg-btn wcg-btn-auto" (click)="showDeleteModal = false">{{'actions.cancel' | translate}}</button>
    <button class="wcg-btn wcg-btn-auto wcg-btn-primary" *ngIf="!isSite" (click)="delete(selected)">{{'actions.removeFromStudy' | translate}}</button>
    <button class="wcg-btn wcg-btn-auto wcg-btn-primary" *ngIf="isSite" (click)="delete(selected)">{{'actions.removeFromSite' | translate}}</button>
  </wcg-modal-footer>
</wcg-modal>
