import { Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { DownloadDocumentsComponent } from '../views/downloads/download-documents/download-documents.component';
import { DownloadExportsComponent } from '../views/downloads/download-exports/download-exports.component';
import { DownloadComponent } from '../views/downloads/download/download.component';

export const downloadExportsRoutes: Routes = [
  {
    canActivate: [
      AuthGuard
    ],
    path: 'download',
    component: DownloadComponent,
    children: [
      {
        path: '',
        redirectTo: 'download-documents',
        pathMatch: 'full'
      },
      {
        path: 'download-exports',
        component: DownloadExportsComponent
      },
      {
        path: 'download-documents',
        component: DownloadDocumentsComponent
      }
    ]
  }
];
