<app-workflow-container [hasIntro]="true" [header]="'submissionLanding.headerTitle' | translate"
  [ngClass]="{'impersonationTop' : stateService.isImpersonationActive.value}">
  <close-button>
    <a [routerLink]="['/dashboard']" class="wcg-btn wcg-btn-icon">
      <wcg-icon src="../../assets/icons/navigation/ic_close_24px.svg"></wcg-icon>
      <span class="wcg-visually-hidden">{{ 'submissionWorkflow.close' | translate }}</span>
    </a>
  </close-button>

  <intro-content>
    <app-workflow-intro heading="{{ 'submissionLanding.welcomeTitle' | translate }}"
      title="{{ 'submissionLanding.setup' | translate }}" details="{{ 'submissionLanding.welcomeDetails' | translate }}"
      illustration="../../assets/pictograms/researcher.png"
      illustrationDescription="{{ 'submissionWorkflow.researcherIllustration' | translate }}"
      [ngClass]="{ 'impersonationIntroTop' : stateService.isImpersonationActive.value}">
    </app-workflow-intro>
  </intro-content>

  <main-content>
    <div class="wcg-row">
      <div class="wcg-col-xs-4 wcg-col-md-8 wcg-col-lg-6 wcg-col-xl-6 cnxs-landing-card">
        <h3 class="wcg-h3 cnxs-submission-content-header">{{ 'submissionLanding.initStudyHeader' | translate }}</h3>
        <div class="wcg-card wcg-card-new">
          <div class="card-new-inner new-inner-left">
            <wcg-icon class="marketing-icon" src="../assets/icons/study-marketing.svg"></wcg-icon>
            <button type="button" (click)="onNewStudySelect()" [disabled]="!this.allDataFetched"
              class="wcg-h3 card-text">{{ 'submissionLanding.addStudy' | translate }}</button>
          </div>
          <div class="card-new-inner new-inner-right">
            <div class="oval">
              <wcg-icon class="icon" src="../assets/icons/calendar.svg"></wcg-icon>
            </div>
            <p class="wcg-h5 estimated-date">{{'submissionWorkflow.estimatedOutcome' | translate }}</p>
            <p class="wcg-h3 cnxs-add-study-date"><span class="wcg-text-highlight">{{ stateService.estimatedOutcomeDate | async }}</span></p>
            <hr class="card-new-divider" />
            <p class="wcg-body-sm card-new-text">{{ 'submissionLanding.estimatedDescription' | translate }}</p>
          </div>
        </div>
        <div class="study-details">
          <wcg-icon class="icon lightbulb-icon" src="../assets/icons/action/ic_lightbulb_outline_24px.svg"></wcg-icon>
          <p class="wcg-h5 study-details-text">{{ 'submissionLanding.tipNewDetails' | translate }}</p>
        </div>
        <wcg-accordion [open]="openOutcome" (toggle)="openOutcome = !openOutcome">
          <wcg-accordion-trigger>
            <h4 class="wcg-h4">{{ 'submissionLanding.estimatedAccordionHeader' | translate }}</h4>
          </wcg-accordion-trigger>
          <wcg-accordion-content>
            <p class="accordion-text">{{'submissionLanding.estimatedAccordionText' | translate }}</p>
          </wcg-accordion-content>
        </wcg-accordion>
      </div>
      <div class="wcg-col-xs-4 wcg-col-md-8 wcg-col-lg-6 wcg-col-xl-6 cnxs-landing-card">
        <h3 class="wcg-h3 cnxs-submission-content-header">{{ 'submissionLanding.existingStudyHeader' | translate }}</h3>
        <div class="wcg-row">
          <div class="wcg-col-xs-4 wcg-col-lg-6">
            <div>
              <div class="wcg-card wcg-card-existing">
                <wcg-icon class="marketing-icon" src="../assets/icons/siteid.svg"></wcg-icon>
                <button type="button" (click)="onAddPISelect()" [disabled]="!this.allDataFetched"
                  class="wcg-h3 card-text">{{ 'submissionLanding.addPrincipal' | translate }}</button>
              </div>
            </div>
            <div class="study-details">
              <wcg-icon class="icon lightbulb-icon" src="../assets/icons/action/ic_lightbulb_outline_24px.svg">
              </wcg-icon>
              <p class="wcg-h5 study-details-text">{{ 'submissionLanding.tipPrincipalDetails' | translate }}</p>
            </div>
          </div>
          <div class="wcg-col-xs-4 wcg-col-lg-6">
            <div>
              <div class="wcg-card wcg-card-existing">
                <wcg-icon class="icon marketing-icon" src="../assets/icons/checklist.svg"></wcg-icon>
                <button type="button" (click)="onAddDocumentsSelect()" [disabled]="!this.allDataFetched"
                  class="wcg-h3 card-text">{{ 'submissionLanding.addTo' | translate }}</button>
              </div>
            </div>
            <div class="study-details">
              <wcg-icon class="icon lightbulb-icon" src="../assets/icons/action/ic_lightbulb_outline_24px.svg">
              </wcg-icon>
              <p class="wcg-h5 study-details-text">{{ 'submissionLanding.tipDocumentDetails' | translate }}</p>
            </div>
            <wcg-accordion [open]="openExample" (toggle)="openExample = !openExample">
              <wcg-accordion-trigger>
                <h4 class="wcg-h4">{{ 'submissionLanding.exampleDocsAccordionHeader' | translate}}</h4>
              </wcg-accordion-trigger>
              <wcg-accordion-content>
                <ul class="doc-examples">
                  <li *ngFor="let example of exampleDocuments" class="accordion-text doc-example">{{ example }}</li>
                </ul>
              </wcg-accordion-content>
            </wcg-accordion>
          </div>
        </div>
      </div>
    </div>

    <wcg-loader [show]="!allDataFetched"></wcg-loader>
  </main-content>
</app-workflow-container>
