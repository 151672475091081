<div class="wcg-admin-nav">
  <ul *ngIf="userInfo$ | async as userInfo" id="menu" open="true" position="left" class="wcg-admin-nav-list">
    <!-- <wcg-menu-item *ngIf="userInfo.canViewSubmissions">
      <a class="menu-item-admin" href="#">
        <wcg-icon class="submissions-icon" src="../assets/icons/action/ic_assignment_24px.svg"></wcg-icon>
        <span>{{ 'admin.submissions' | translate }}</span>
      </a>
    </wcg-menu-item> -->

    <li class="wcg-admin-nav-list-item" *ngIf="userInfo.canViewUsers" [ngClass]="{'selected': selectedItem == 'user'}" (click)="listClick($event, 'user')">
      <a class="menu-item-admin"  routerLink="users" routerLinkActive="wcg-active">
        <wcg-icon class="users-icon" src="../assets/icons/social/ic_person_24px.svg"></wcg-icon>
        <span class="admin-nav-label">{{ 'admin.users' | translate }}</span>
      </a>
    </li>

    <li class="wcg-admin-nav-list-item" *ngIf="userInfo.canViewTeams" [ngClass]="{'selected': selectedItem == 'team'}" (click)="listClick($event, 'team')">
      <a class="menu-item-admin"  routerLink="teams" routerLinkActive="wcg-active">
        <wcg-icon class="users-icon" src="../assets/icons/social/ic_people_24px.svg"></wcg-icon>
        <span class="admin-nav-label">{{ 'admin.teams' | translate }}</span>
      </a>
    </li>

    <li class="wcg-admin-nav-list-item" *ngIf="userInfo.canViewSettings" [ngClass]="{'selected': selectedItem == 'setting'}" (click)="listClick($event, 'setting')">
      <a class="menu-item-admin" routerLink="settings" routerLinkActive="wcg-active">
        <wcg-icon class="settings-icon" src="../assets/icons/action/ic_settings_24px.svg"></wcg-icon>
        <span class="admin-nav-label">{{ 'admin.settings' | translate }}</span>
      </a>
    </li>


    <li class="wcg-admin-nav-list-item" *ngIf="userInfo.canViewAccessAuditReport" [ngClass]="{'selected': selectedItem == 'audit'}" (click)="listClick($event, 'audit')">
      <a class="menu-item-admin"  routerLink="audit" routerLinkActive="wcg-active" >
        <wcg-icon class="users-icon" src="../assets/icons/Audits.svg"></wcg-icon>
        <span class="admin-nav-label">{{ 'admin.audit' | translate }}</span>
      </a>
    </li>

    <li class="wcg-admin-nav-list-item" *ngIf="userInfo.canViewManageSubmissions" [ngClass]="{'selected': selectedItem == 'submission'}" (click)="listClick($event, 'submission')">
      <a class="menu-item-admin" routerLink="submissions-admin" routerLinkActive="wcg-active">
        <wcg-icon class="submissions-icon" src="../assets/icons/ic_insert_drive_file_24px.svg"></wcg-icon>
        <span class="admin-nav-label">{{ 'adminSubmissions.submissions' | translate  }}</span>
      </a>
    </li>
  </ul>
</div>
<section class="wcg-container">
  <router-outlet></router-outlet>
</section>

