<section class="wcg-container">
  <div class="wcg-row">
    <div class="wcg-col-xs-4 wcg-col-md-3 wcg-col-lg-5">
      <h1 class="wcg-h1">{{ 'nav.submissions' | translate }}</h1>
    </div>

    <div class="wcg-col-xs-4 wcg-col-md-5 wcg-col-lg-7 wcg-end-lg" *ngIf="isAdminUser">
      <div class="cnxs-show-all-toggle">
        <h4 class="wcg-h4">Show: </h4>
        <wcg-radio ngDefaultControl (change)="changeRadio($event)" [checked]="selectedRadio === 'mostRecent'"
          value="mostRecent" name="mostRecent">
          Most recent records
        </wcg-radio>
        <wcg-radio ngDefaultControl (change)="changeRadio($event)" [checked]="selectedRadio === 'allrecords'"
          value="allrecords" name="allrecords">All
          records
        </wcg-radio>
        <wcg-tooltip>
          <wcg-tooltip-trigger>
            <wcg-icon class="icon lightbulb-icon showHelpIcon"
              src="../assets/icons/action/ic_lightbulb_outline_24px.svg"></wcg-icon>
          </wcg-tooltip-trigger>
          <wcg-tooltip-content>
            {{searchRecordsToolTip}}
          </wcg-tooltip-content>
        </wcg-tooltip>
      </div>
    </div>
  </div>
  <div>
    <form ngForm class="wcg-row wcg-filter-row">
      <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
        <ng2-flatpickr class="wcg-input-with-icon" #startDatePicker placeholder="Start Date"
          [config]="datePickerOptions" [formControl]="startDateFilter">
          <wcg-icon class="date-icon" (click)="toggleStartDate()" src="./assets/icons/action/ic_date_range_24px.svg">
          </wcg-icon>
        </ng2-flatpickr>
      </div>
      <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
        <ng2-flatpickr class="wcg-input-with-icon" #endDatePicker placeholder="End Date" [config]="datePickerOptions"
          [formControl]="endDateFilter">
          <wcg-icon class="date-icon" (click)="toggleEndDate()" src="./assets/icons/action/ic_date_range_24px.svg">
          </wcg-icon>
        </ng2-flatpickr>
      </div>
      <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-4">
        <wcg-form-field>
          <wcg-label>{{ 'actions.search' | translate }}</wcg-label>
          <wcg-input [formControl]="searchTerm">
            <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
          </wcg-input>
        </wcg-form-field>
      </div>
    </form>
  </div>

  <div class="wcg-row">
    <div class="wcg-col-xs-4 wcg-col-md-12 wcg-col-lg-12 wcg-rhythm-2">
      <div class="export-button-wrap">
        <app-export-action-button [gridFilters]="gridFilters" [disableButton]="!tableData?.length"
          [exportType]="exportType" [limitAdminUserCount]="limitAdminUserCount"></app-export-action-button>
      </div>
    </div>
  </div>

  <wcg-table class="wcg-rhythm-4" [ngClass]="{'cnxs-table-loading': loading}" [headers]="headers" [data]="tableData"
    [options]="options" (rowClicked)="handleRowClicked($event)" (sortClicked)="handleSort($event)">

    <div class="cnxs-table-loader" slot="table-body">
      <wcg-loader [show]="!loaded || loading"></wcg-loader>
    </div>
    <div class="cnxs-pagination-wrap wcg-table-footer" *ngIf="loaded">
      <div class="wcg-total-results">
        {{ 'tables.totalResults' | translate}}: {{totalRecords}}
      </div>
      <div class="pagination" *ngIf="tableData?.length">
        <wcg-pagination [current]="currentPage" [pageSize]="currentPageSize"
          [max]="utilService.maxPages(totalRecords, currentPageSize)" (changePage)="handlePageChange($event)"></wcg-pagination>
      </div>
    </div>
  </wcg-table>
</section>