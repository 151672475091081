import { Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { LandingComponent } from '../views/submission-workflow/landing/landing.component';
import { SubmissionChooseSitesComponent } from '../views/submission-workflow/submission-choose-sites/submission-choose-sites.component';
import { SubmissionFormComponent } from '../views/submission-workflow/submission-form/submission-form.component';
import { SubmissionIRBSearchComponent } from '../views/submission-workflow/submission-irbsearch/submission-irbsearch.component';
import { SubmissionRegionComponent } from '../views/submission-workflow/submission-region/submission-region.component';
import { SubmissionReviewComponent } from '../views/submission-workflow/submission-review/submission-review.component';
import { SubmissionSuccessComponent } from '../views/submission-workflow/submission-success/submission-success.component';
import { SubmissionTypeComponent } from '../views/submission-workflow/submission-type/submission-type.component';
import { SubmissionFindComponent } from '../views/submission-workflow/submission-find/submission-find.component';
import { UploadDocumentComponent } from '../views/submission-workflow/upload-document/upload-document.component';
import { SubmissionWorkflowGuard } from '../guards/submission-workflow.guard';

export const submissionWorkflowRoutes: Routes = [
  {
    path: 'submission-workflow',
    component: LandingComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'submission-workflow/choose-sites',
    component: SubmissionChooseSitesComponent,
    canActivate: [
      AuthGuard,
      SubmissionWorkflowGuard
    ]
  },
  {
    path: 'submission-workflow/details',
    component: SubmissionFormComponent,
    canActivate: [
      AuthGuard,
      SubmissionWorkflowGuard
    ],
    children: [
      {
        path: 'review',
        component: SubmissionReviewComponent,
        canActivate: [
          AuthGuard,
          SubmissionWorkflowGuard
        ]
      },
      {
        path: 'upload',
        component: UploadDocumentComponent,
        canActivate: [
          AuthGuard,
          SubmissionWorkflowGuard
        ]
      }
    ]
  },
  {
    path: 'submission-workflow/search-irb',
    component: SubmissionIRBSearchComponent,
    canActivate: [
      AuthGuard,
      SubmissionWorkflowGuard
    ]
  },
  {
    path: 'submission-workflow/search-study',
    component: SubmissionFindComponent,
    canActivate: [
      AuthGuard,
      SubmissionWorkflowGuard
    ]
  },
  {
    path: 'submission-workflow/setup/regions',
    component: SubmissionRegionComponent,
    canActivate: [
      AuthGuard,
      SubmissionWorkflowGuard
    ]
  },
  {
    path: 'submission-workflow/setup/types',
    component: SubmissionTypeComponent,
    canActivate: [
      AuthGuard,
      SubmissionWorkflowGuard
    ]
  },
  {
    path: 'submission-workflow/success/:id',
    component: SubmissionSuccessComponent,
    canActivate: [
      AuthGuard
    ]
  }
];
