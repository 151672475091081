import { Component, OnInit, Input } from '@angular/core';
import { SubmissionsDashboardStatistic } from '../../common/collections';

@Component({
  selector: 'app-mini-status-tracker',
  templateUrl: './mini-status-tracker.component.html',
  styleUrls: ['./mini-status-tracker.component.scss']
})
export class MiniStatusTrackerComponent implements OnInit {
  @Input() status: 'Received' | 'Preparing for Board Review' | 'Board Review' | 'Finalizing Documents' | 'Complete';
  @Input() onHold: boolean;
  @Input() outcome: string;

  statusGrid = [
    {
      status: 'Received',
      active: false,
      current: false,
      needsAction: false
    },
    {
      status: 'Preparing for Board Review',
      active: false,
      current: false,
      needsAction: false
    },
    {
      status: 'Board Review',
      active: false,
      current: false,
      needsAction: false
    },
    {
      status: 'Finalizing Documents',
      active: false,
      current: false,
      needsAction: false
    },
    {
      status: 'Complete',
      active: false,
      current: false,
      needsAction: false
    }
  ];

  fullyApproved = SubmissionsDashboardStatistic.fullyApproved;

  ngOnInit(): void {
    this.mapStatus();
  }

  private mapStatus(): any {
    this.statusGrid.every((item, index) => {
      this.statusGrid[index].active = true;

      if (item.status === this.status) {
        this.statusGrid[index].current = true;
        this.statusGrid[index].needsAction = this.outcome === SubmissionsDashboardStatistic.notFullyApproved;

        return false;
      }

      return true;
    });
  }
}
