import { Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { ForbiddenComponent } from '../views/errors/forbidden/forbidden.component';
import { PageNotFoundComponent } from '../views/errors/page-not-found/page-not-found.component';
import { RedirectComponent } from '../views/errors/redirect/redirect.component';
import { SystemDowntimeComponent } from '../views/errors/system-downtime/system-downtime.component';

export const defaultRoutes: Routes = [
  {
    canActivate: [
      AuthGuard
    ],
    path: 'forbidden',
    component: ForbiddenComponent
  },
  {
    canActivate: [
      AuthGuard
    ],
    path: 'page-not-found',
    component: PageNotFoundComponent
  },
  {
    path: 'redirect',
    component: RedirectComponent
  },
  {
    path: 'system-downtime',
    component: SystemDowntimeComponent
  }
];
