import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { SubmissionInfo } from '../../../interfaces/submissions';
import { SubmissionsDashboardPost } from '../../../interfaces/dashboard';
import { BaseComponent } from '../../../components/base/base.component';
import { StateService } from '../../../services/state.service';
import { PaginationValues } from 'src/app/models/pagination-values.mode';
import { PageSizeOptions } from 'src/app/models/page-size-options.model';

@Component({
  selector: 'app-dashboard-drafts',
  templateUrl: './dashboard-drafts.component.html',
  styleUrls: ['./dashboard-drafts.component.scss']
})
export class DashboardDraftsComponent extends BaseComponent implements OnInit {
  draftsData: SubmissionsDashboardPost;
  records: Array<SubmissionInfo> = [];
  pageSizeOptions: PageSizeOptions;
  viewTypeCard: boolean;

  constructor(
    public dashboardService: DashboardService,
    public stateService: StateService
  ) {
    super();
    this.pageSizeOptions = this.dashboardService.GetPaginationConfiguration(this.dashboardService.draftsPagination);
    this.viewTypeCard = this.dashboardService.cardView.getValue();
  }

  ngOnInit(): void {
    this.dashboardService.drafts.subscribe((val) => {
      if (val) {
        this.draftsData = val;
        this.records = this.dashboardService.mapDashboardRecords(val?.submissions?.records, 'drafts');
        this.pageSizeOptions.page = val?.submissions?.currentPage;
        this.pageSizeOptions.pageSize = val?.submissions?.pageSize;
      }
    });

    this.dashboardService.viewListCardTypeUpdated.subscribe((val) => {
      if (val === null) {
        return;
      }

      if (this.viewTypeCard === this.dashboardService.cardView.getValue()) {
        return;
      }

      this.viewTypeCard = this.dashboardService.cardView.getValue();
      this.dashboardService.draftsPagination =
        new PaginationValues(1, this.dashboardService.paginationDataConfiguration.pageSize);
      this.pageSizeOptions.page = 1;
      this.pageSizeOptions.pageSize = this.dashboardService.paginationDataConfiguration.pageSize;
      this.pageSizeOptions.pageSizeDataSource = this.dashboardService.paginationDataConfiguration.pageSizeDataSource;
    });
  }

  public handlePageChange(event): void {
    this.dashboardService.draftsPagination =
      new PaginationValues(event.detail.page, event.detail.pageSize);
    this.pageSizeOptions.page = event.detail.page;
    this.pageSizeOptions.pageSize = event.detail.pageSize;
    this.dashboardService.postDrafts();
  }
}
