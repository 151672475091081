<div class="body">
    <div class="wcg-row">
        <h1 class="wcg-h1">{{'manageContacts.manageContacts' | translate}}</h1>
    </div>

    <div class="wcg-row pb-1">
        <div class="wcg-col-xs-2">
            <h4>{{ 'manageContacts.addUsersText' | translate }}</h4>
            <form [formGroup]="inviteForm">
                <wcg-form-field class="user-form-field">
                    <wcg-label>{{ 'teams.addMember' | translate }}</wcg-label>
                    <wcg-autocomplete formControlName="selectedUsers" [multiple]="true" [options]="allUsers" required>
                    </wcg-autocomplete>
                    <div class="error-message">
                        <wcg-error-message *ngIf="inviteForm.controls.selectedUsers.hasError('incativeUsers')">
                            {{ 'manageContacts.inactiveUserWarningMessage' | translate }}
                        </wcg-error-message>
                        <wcg-error-message *ngIf="inviteForm.controls.selectedUsers.hasError('invalidEmails')">
                            {{ 'manageContacts.invalidEmail' | translate }}
                        </wcg-error-message>
                    </div>
                </wcg-form-field>
            </form>
        </div>
        <div class="wcg-col-xs-1">
            <h4>{{ 'manageContacts.selectPermissionText' | translate }}</h4>
            <form [formGroup]="inviteForm">
                <wcg-form-field>
                    <wcg-label>{{'manageContacts.permissions' | translate}}</wcg-label>
                    <wcg-select formControlName="selectedPermissionType"
                        [value]="inviteForm.controls.selectedPermissionType.value">
                        <option hidden value="" selected></option>
                        <option *ngFor="let item of permissionOptions" value="{{item.value}}">{{item.viewValue}}
                        </option>
                    </wcg-select>
                    <div class="error-message">
                        <wcg-error-message *ngIf="inviteForm.controls.selectedPermissionType.hasError('onlyForStudy')">
                            {{ 'manageContacts.permissionsStudyError' | translate }}
                        </wcg-error-message>
                    </div>
                </wcg-form-field>
            </form>
        </div>
    </div>
    <form [formGroup]="workspaceFormGroup" class="wcg-row">
        <div class="wcg-col-xs-4">
            <h4>{{ 'manageContacts.searchForWorkspacesText' | translate }}</h4>
        </div>
        <div class="wcg-col-xs-1">
            <wcg-form-field>
                <wcg-label>{{ 'manageContacts.selectWorkspaceTypeText' | translate }}</wcg-label>
                <wcg-select formControlName="selectedWorkspaceType"
                    [value]="workspaceFormGroup.controls.selectedWorkspaceType.value"
                    (change)="onWorkspaceTypeChanged($event)">
                    <option hidden value="" selected></option>
                    <option *ngFor="let item of workspaceOptions" value="{{item.value}}">{{item.viewValue}}
                    </option>
                </wcg-select>
                <div class="error-message">
                    <wcg-error-message
                        *ngIf="workspaceFormGroup.controls.selectedWorkspaceType.hasError('required') && this.workspaceFormGroup.controls.selectedWorkspaceType.dirty">
                        {{ 'validation.required' | translate: { fieldname: ('Field') } }}
                    </wcg-error-message>
                </div>
            </wcg-form-field>
        </div>
        <div class="wcg-col-xs-1" *ngIf="workspaceFormGroup.controls.selectedWorkspaceType.value">
            <wcg-form-field>
                <wcg-label>
                    {{workspaceFormGroup.controls.selectedWorkspaceType.value === 'study' ?
                    ('manageContacts.studySearchLabel' | translate) :
                    ('requestAccess.searchSiteLabel' | translate)}}
                </wcg-label>
                <wcg-input formControlName="searchTerm" [value]="workspaceFormGroup.controls.searchTerm.value"
                    autocomplete="off"></wcg-input>
                <div class="error-message">
                    <wcg-error-message *ngIf="workspaceFormGroup.controls.searchTerm.hasError('required') &&
                        (workspaceFormGroup.controls.searchTerm.dirty || 
                        workspaceFormGroup.controls.searchTerm.touched)">
                        {{ 'validation.required' | translate: { fieldname: ('Field') } }}
                    </wcg-error-message>
                </div>
            </wcg-form-field>
        </div>
        <div class="wcg-col-xs-1" *ngIf="showIrbTrackingNumberField">
            <wcg-form-field>
                <wcg-label>IRB Tracking Number</wcg-label>
                <wcg-input formControlName="irbTrackingNumber"
                    [value]="workspaceFormGroup.controls.irbTrackingNumber.value" autocomplete="off">
                </wcg-input>
                <div class="error-message">
                    <wcg-error-message *ngIf="workspaceFormGroup.controls.irbTrackingNumber.hasError('required') &&
                    (workspaceFormGroup.controls.irbTrackingNumber.dirty || 
                    workspaceFormGroup.controls.irbTrackingNumber.touched)">
                        {{ 'validation.required' | translate: { fieldname: ('Field') } }}
                    </wcg-error-message>
                </div>
            </wcg-form-field>
        </div>
        <div class="col-xs-1">
            <button class="wcg-btn wcg-btn-auto mt-10" (click)="getWorkspace()"
                [disabled]="!workspaceFormGroup.valid">{{ 'manageContacts.getWorkspaceLabel' | translate }}</button>
        </div>
    </form>

    <div class="wcg-row">
        <div class="wcg-col-xs-3 workspace-table">
            <div class="wcg-row">
                <p class="table-label">{{ 'manageContacts.workspaceMatchesText' | translate }}</p>
            </div>
            <div class="wcg-row">
                <div class="wcg-col-md-1 header-id">{{ 'tableHeaders.id' | translate }}</div>
                <div class="wcg-col-md-2">{{ 'tableHeaders.piName' | translate }}</div>
                <div class="wcg-col-md-2">{{ 'tableHeaders.sponsorProtocolID' | translate }}</div>
                <div class="wcg-col-md-3">{{ 'tableHeaders.irbTrackingNumber' | translate }}</div>
            </div>
            <div class="wcg-row workspace-item"
                *ngFor="let workspace of inviteForm.controls.selectedWorkspaces.value.selectedValues">
                <div class="wcg-col-md-1 workspace-item-id">
                    <button class="btn-selected-workspace"
                        (click)="removeWorkspace(workspace.workspaceExternalReferenceId)">
                        <wcg-icon src="../../../../../assets/icons/ic_delete_forever_24px.svg"></wcg-icon>
                    </button>
                    {{ workspace.workspaceExternalReferenceId }}
                </div>
                <div class="wcg-col-md-2">
                    {{ workspace.sitePiName ? workspace.sitePiName : 'n/a' }}
                </div>
                <div class="wcg-col-md-2">
                    {{ workspace.sponsorProtocolNumber ? workspace.sponsorProtocolNumber : 'n/a'}}
                </div>
                <div class="wcg-col-md-3">
                    {{ workspace.irbTrackingNumber ? workspace.irbTrackingNumber : 'n/a' }}
                </div>
            </div>
        </div>
    </div>

    <div class="wcg-row">
        <div class="wcg-col-xs-4 col-btn">
            <button class="wcg-btn wcg-btn-auto" (click)="inviteContacts()"
                [disabled]="inviteForm.invalid || !showActionButton">{{
                'manageContacts.inviteToWorkspaces' | translate : { users:
                inviteForm.controls.selectedUsers.value.selectedValues.length } }}</button>
            <button class="wcg-btn wcg-btn-auto" (click)="updateContacts()"
                [disabled]="inviteForm.invalid || !showActionButton">{{
                'manageContacts.updatePermissions' | translate : { users:
                inviteForm.controls.selectedUsers.value.selectedValues.length } }}</button>
            <button class="wcg-btn wcg-btn-auto" (click)="removeContacts()" [disabled]="!isRemoveEnabled">{{
                'manageContacts.removeWorkspaceUsers' | translate : { users:
                inviteForm.controls.selectedUsers.value.selectedValues.length } }}</button>
        </div>
    </div>
</div>

<wcg-modal [show]="showWorkspacesList" (close)="resetWorkspaceListModal()">
    <wcg-modal-header>
        <div class="wcg-col-xs-4">
            <p>
                {{ 'requestAccess.matchesSearch' | translate }}
            </p>
        </div>
    </wcg-modal-header>
    <wcg-modal-body>
        <div class="wcg-row select-workspace-header">
            <div class="wcg-col-md-1 wcg-col-lg-1 radio-button-column">
            </div>
            <div class="wcg-col-xs-1 wcg-col-md-1 wcg-col-lg-2" *ngIf="showSites">
                {{ 'tableHeaders.irisStudyId' | translate }}
            </div>
            <div class="wcg-col-xs-1 wcg-col-md-3 wcg-col-lg-3" *ngIf="!showSites">
                {{ 'tableHeaders.irbTrackingNumber' | translate }}
            </div>
            <div class="wcg-col-md-2 wcg-col-lg-3 sponsor-column">
                {{ 'tableHeaders.sponsor' | translate }}
            </div>
            <div class="wcg-col-xs-1 wcg-col-md-2 wcg-col-lg-3">
                {{ 'tableHeaders.sponsorProtocolID' | translate }}
            </div>
            <div class="wcg-col-xs-1 wcg-col-md-2 wcg-col-lg-3" *ngIf="showSites">
                {{ 'tableHeaders.institutionTrackingId' | translate }}
            </div>
        </div>
        <div class="select-workspace-list">
            <div class="wcg-row" *ngFor="let workspace of workspaceList">
                <div class="wcg-col-md-1 wcg-col-lg-1 radio-button-column">
                    <wcg-radio name="selectWorkspace" (change)="changeRadio($event)"
                        value="{{workspace.workspaceExternalReferenceId ? workspace.workspaceExternalReferenceId : workspace.externalReferenceId}}">
                    </wcg-radio>
                </div>
                <div class="wcg-col-xs-1  wcg-col-md-1 wcg-col-lg-2" *ngIf="showSites">
                    {{workspace?.irisStudyId ? workspace?.irisStudyId : 'n/a'}}
                </div>
                <div class="wcg-col-xs-1 wcg-col-md-3 wcg-col-lg-3" *ngIf="!showSites">
                    {{workspace?.irbTrackingNumber ? workspace?.irbTrackingNumber : 'n/a'}}
                </div>
                <div class="wcg-col-md-2 wcg-col-lg-3 sponsor-column">
                    {{ workspace?.sponsorName ? workspace?.sponsorName : workspace?.sponsor }}
                </div>
                <div class="wcg-col-xs-1 wcg-col-md-2 wcg-col-lg-3">
                    {{ workspace?.sponsorProtocolId ? workspace?.sponsorProtocolId : workspace?.protocolNumber}}
                </div>
                <div class="wcg-col-xs-1 wcg-col-md-2 wcg-col-lg-3" *ngIf="showSites">
                    {{ workspace?.institutionalTrackingNumber ? workspace?.institutionalTrackingNumber : 'n/a' }}
                </div>
            </div>
        </div>
    </wcg-modal-body>
    <wcg-modal-footer class="help-modal-footer">
        <button (click)="getSelectedWorkspace()" class="wcg-btn wcg-btn-auto wcg-btn-primary"
            [disabled]="chosenWorkspace === null">
            {{ 'actions.confirm' | translate }}
        </button>
    </wcg-modal-footer>
</wcg-modal>