import { Injectable } from '@angular/core';

import { StudiesGridRecordMap, StudiesGrid } from '../../interfaces/studies';
import { UtilService } from '../../services/util.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class StudiesService {
  public endDate = new BehaviorSubject<Date>(null);
  public startDate = new BehaviorSubject<Date>(null);
  public searchTerm = new BehaviorSubject<string>('');


  constructor(private utilService: UtilService) { }

  public mapStudiesGridData(data: StudiesGrid): Array<StudiesGridRecordMap> {
    return data.records.map((record) => ({
      name: record.name,
      nameLink: this.utilService.addTableCellWrapperWithSearchTerms(
        record.name,
        this.searchTerm.value,
        `studies/${record.id}`
      ),
      sponsor: this.utilService.addTableCellWrapperWithSearchTerms(record.sponsor, this.searchTerm.value),
      protocolNumber: this.utilService.addTableCellWrapperWithSearchTerms(record.protocolNumber, this.searchTerm.value),
      initialApprovalDate: this.utilService.addTableCellWrapper(this.utilService.formatDate(record.initialApprovalDate)),
      lastReviewDate: this.utilService.highlightDateFields(record.lastReviewDate, this.startDate.value, this.endDate.value),
      expirationDate: this.utilService.highlightDateFields(record.expirationDate, this.startDate.value, this.endDate.value),
      irbTrackingNumber: this.utilService.addTableCellWrapperWithSearchTerms(record.irbTrackingNumber, this.searchTerm.value),
      status: this.utilService.addTableCellWrapperWithSearchTerms(record.status, this.searchTerm.value)
    }));
  }
}
