import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { StudyMap } from '../../interfaces/studies';
import { LoggerService } from '../../services/logger.service';
import { StudyService } from './study.service';
import { UtilService } from '../../services/util.service';
import { WorkspacePermissions } from '../../interfaces/workspaces';
import { ToastService } from 'src/app/services/toast.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { SubmissionWorkflowService } from '../submission-workflow/submission-workflow.service';
import { SubmissionFindStudy, SubmissionType } from 'src/app/interfaces/submissions';
import { StateService } from 'src/app/services/state.service';
import { BaseSubmissionComponent } from 'src/app/components/base-submission/base-submission.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-study',
  templateUrl: './study.component.html',
  styleUrls: ['./study.component.scss']
})
export class StudyComponent extends BaseSubmissionComponent implements OnInit {
  id: string;
  permissions: WorkspacePermissions;
  study: StudyMap;
  closedStatus = 'Closed';
  newSite: SubmissionType;
  existingStudy: SubmissionType;
  allDataFetched: boolean;
  exampleDocuments: Array<string>;
  canAddDocuments = new BehaviorSubject(false);
  canAddPi = new BehaviorSubject(false);

  constructor(
    apiService: ApiService,
    private loggerService: LoggerService,
    private studyService: StudyService,
    router: Router,
    public utilService: UtilService,
    toastService: ToastService,
    private translatePipe: TranslatePipe,
    submissionWorkflowService: SubmissionWorkflowService,
    stateService: StateService
  ) {
    super(router, stateService, apiService, submissionWorkflowService, toastService);
    this.canAddDocuments.next(false);
    this.canAddPi.next(false);
    [this.id] = this.router.url.split('/studies/')[1].split('/');
    this.getStudy(this.id);
    this.getUserWorkspacePermissionsById(this.id);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  private getStudy(id): void {
    this.apiService.getStudy(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.study = this.studyService.mapStudyData(data);
        const studyData: SubmissionFindStudy = {
          name: data.name,
          protocol: data.protocolNumber,
          id: id,
          sponsor: data.sponsor,
          status: data.status
        };
        this.submissionWorkflowService.submissionFindStudy.next(studyData);
        this.submissionWorkflowService.submissionSponsorProtocolId.next(studyData.protocol);
        this.submissionWorkflowService.originUrl.next(this.router.url);
      }, () => {
        this.toastService.add([{
          closable: true,
          id: 'getStudy',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          variant: 'error'
        }]);
      });
  }

  private getUserWorkspacePermissionsById(id): void {
    this.apiService.getUserStudyPermissionsById(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.permissions = data;
        this.canAddDocuments.next(data.canAddDocumentsSubmissions);
        this.canAddPi.next(data.canAddPiSubmissions);
        this.submissionWorkflowService.canSetSiteSingleSelect.next(data.canSetSiteSingleSelectSubmissions);
      }, (err) => {
        this.loggerService.error(`Data could not be fetched: ${err}`);
      });
  }
}
