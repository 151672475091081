import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserInfoMap } from 'src/app/admin/shared/model/userinfo';
import { TeamMembersGrid, TeamSitesGrid, TeamStudiesGrid } from 'src/app/interfaces/teams';
import { UserAutocompleteMap } from 'src/app/interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  public endDate = new BehaviorSubject<Date>(null);
  public startDate = new BehaviorSubject<Date>(null);
  public searchTerm = new BehaviorSubject<string>('');
  public reloadTeamStudyGrid = new BehaviorSubject<boolean>(false);
  public reloadTeamSiteGrid = new BehaviorSubject<boolean>(false);
  public reloadTeamUserGrid = new BehaviorSubject<boolean>(false);
  public reloadTeamDetails = new BehaviorSubject<boolean>(false);
  public isTeamActive = new BehaviorSubject<boolean>(true);
  public isAdmin = new BehaviorSubject<boolean>(false);
  public hasManagePermission = new BehaviorSubject<boolean>(false);
  public joinedStudies = new BehaviorSubject<TeamStudiesGrid>(null);
  public joinedSites = new BehaviorSubject<TeamSitesGrid>(null);
  public teamMembers = new BehaviorSubject<TeamMembersGrid>(null);

  constructor() {
  }

  public mapUsers = (data: Array<UserInfoMap>): Array<UserAutocompleteMap> => data.map((record) => ({
    email: record.email,
    option: `${[record.firstName, record.lastName].filter(Boolean).join(' ')} (${record.email})`,
    value: record.email
  }));
}
