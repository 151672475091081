import { Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { TeamMembersComponent } from '../views/team/team-members/team-members.component';
import { TeamSitesComponent } from '../views/team/team-sites/team-sites.component';
import { TeamStudiesComponent } from '../views/team/team-studies/team-studies.component';
import { TeamComponent } from '../views/team/team.component';

export const teamRoutes: Routes = [{
  path: 'teams/:id',
  component: TeamComponent,
  children: [
    {
      path: '',
      redirectTo: 'teamMembers',
      pathMatch: 'full'
    },
    {
      path: 'teamStudies',
      component: TeamStudiesComponent
    },
    {
      path: 'teamSites',
      component: TeamSitesComponent
    },
    {
      path: 'teamMembers',
      component: TeamMembersComponent
    }
  ],
  canActivate: [
    AuthGuard
  ]
}];
