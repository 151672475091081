import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LineTruncationLibModule } from 'ngx-line-truncation';

import { AppRoutingModule } from './app-routing.module';
import { ApiService } from './services/api.service';
import { SubmissionChooseSitesComponent } from './views/submission-workflow/submission-choose-sites/submission-choose-sites.component';
import { SubmissionInfoComponent } from './components/submissions/submission-info/submission-info.component';
import { SubmissionListComponent } from './components/submissions/submission-list/submission-list.component';
import { SubmissionHelpComponent } from './components/submissions/submission-help/submission-help.component';
import { SubmissionFindComponent } from './views/submission-workflow/submission-find/submission-find.component';
import { LandingComponent } from './views/submission-workflow/landing/landing.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SubmissionFormComponent } from './views/submission-workflow/submission-form/submission-form.component';
import { SubmissionReviewComponent } from './views/submission-workflow/submission-review/submission-review.component';
import { WorkflowContainerComponent } from './components/submissions/workflow-container/workflow-container.component';
import { FormNavItemComponent } from './components/submissions/form-nav/form-nav-item.component';
import { CancelWorkflowComponent } from './components/modals/submission-exit-modal/submission-exit-modal.component';
import { ProfileComponent } from './views/profile/profile.component';
import { StudiesComponent } from './views/studies/studies.component';
import { SubmissionsComponent } from './views/submissions/submissions.component';
import { OAuthInterceptor } from './interceptors/oauth.interceptor';
import { BaseComponent } from './components/base/base.component';
import { CoreModule } from './core/core.module';
import { AdminModule } from './admin/admin.module';
import { SitesComponent } from './views/sites/sites.component';
import { FormioModule, FormioAppConfig } from 'angular-formio';
import { FormioConfig } from './formio/formio-config';
import { ResourcesComponent } from './views/resources/resources.component';
import { SubmittedDocumentsComponent } from './views/submission/submission-submitted-documents/submission-submitted-documents.component';
import { OutcomeDocumentsComponent } from './views/submission/submission-outcome-documents/submission-outcome-documents.component';
import { SubmissionsDetailComponent } from './views/submission/submission.component';
import { NoRecordsComponent } from './components/no-records/no-records.component';
import { StatusTrackerComponent } from './components/status-tracker/status-tracker.component';
import { SharedModule } from './shared.module';
import { StudyComponent } from './views/study/study.component';
import { StudyOutcomeDocumentsComponent } from './views/study/study-outcome-documents/study-outcome-documents.component';
import { StudyContactsComponent } from './views/study/study-contacts/study-contacts.component';
import { StudySitesComponent } from './views/study/study-sites/study-sites.component';
import { StudySubmissionsComponent } from './views/study/study-submissions/study-submissions.component';
import { SiteComponent } from './views/site/site.component';
import { SiteOutcomeDocumentsComponent } from './views/site/site-outcome-documents/site-outcome-documents.component';
import { SiteContactsComponent } from './views/site/site-contacts/site-contacts.component';
import { SiteSubmissionsComponent } from './views/site/site-submissions/site-submissions.component';
import { ManageContactsComponent } from './views/manage-contacts/manage-contacts.component';
import { AppComponent } from './app.component';
import { NgIdleModule } from '@ng-idle/core';
import { ForbiddenComponent } from './views/errors/forbidden/forbidden.component';
import { PageNotFoundComponent } from './views/errors/page-not-found/page-not-found.component';
import { ManageContactsContactsComponent } from './views/manage-contacts/manage-contacts-contacts/manage-contacts-contacts.component';
import { ManageContactsRequestsComponent } from './views/manage-contacts/manage-contacts-requests/manage-contacts-requests.component';
import { DashboardNeedsActionComponent } from './views/dashboard/dashboard-needs-action/dashboard-needs-action.component';
import { SubmissionSitesComponent } from './views/submission/submission-sites/submission-sites.component';
import { SubmissionSuccessComponent } from './views/submission-workflow/submission-success/submission-success.component';
import { WorkflowIntroComponent } from './components/submissions/workflow-intro/workflow-intro.component';
import { EstimatedOutcomeCardComponent } from './components/submissions/estimated-outcome-card/estimated-outcome-card.component';
import { DashboardDraftsComponent } from './views/dashboard/dashboard-drafts/dashboard-drafts.component';
import { DashboardInProgressComponent } from './views/dashboard/dashboard-in-progress/dashboard-in-progress.component';
import { SubmissionRegionComponent } from './views/submission-workflow/submission-region/submission-region.component';
import { SubmissionTypeComponent } from './views/submission-workflow/submission-type/submission-type.component';
import { MiniStatusTrackerComponent } from './components/mini-status-tracker/mini-status-tracker.component';
import { UploadDocumentComponent } from './views/submission-workflow/upload-document/upload-document.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { SubmissionIRBSearchComponent } from './views/submission-workflow/submission-irbsearch/submission-irbsearch.component';
import { SubmissionDetailsComponent } from './components/modals/submission-details-modal/submission-details-modal.component';
import { BlobModule } from 'angular-azure-blob-service';
import { RedirectComponent } from './views/errors/redirect/redirect.component';
import { TeamMembersComponent } from './views/team/team-members/team-members.component';
import { TeamComponent } from './views/team/team.component';
import { TeamStudiesComponent } from './views/team/team-studies/team-studies.component';
import { TeamSitesComponent } from './views/team/team-sites/team-sites.component';
import { DownloadExportsComponent } from './views/downloads/download-exports/download-exports.component';
import { DownloadDocumentsComponent } from './views/downloads/download-documents/download-documents.component';
import { DownloadComponent } from './views/downloads/download/download.component';
import { SystemDowntimeComponent } from './views/errors/system-downtime/system-downtime.component';
import { OutcomeDocumentsDownloadComponent } from './components/outcome-documents-download/outcome-documents-download.component';
import { LiveChatTalkdeskComponent } from './components/live-chat-talkdesk/live-chat-talkdesk.component';

@NgModule({
  declarations: [
    AppComponent,
    SubmissionChooseSitesComponent,
    SubmissionInfoComponent,
    SubmissionListComponent,
    SubmissionHelpComponent,
    SubmissionFindComponent,
    LandingComponent,
    DashboardComponent,
    SubmissionFormComponent,
    SubmissionReviewComponent,
    WorkflowContainerComponent,
    FormNavItemComponent,
    CancelWorkflowComponent,
    ProfileComponent,
    StudiesComponent,
    SubmissionsComponent,
    BaseComponent,
    SitesComponent,
    ResourcesComponent,
    SubmissionsDetailComponent,
    SubmittedDocumentsComponent,
    OutcomeDocumentsComponent,
    StatusTrackerComponent,
    StudyComponent,
    StudyOutcomeDocumentsComponent,
    StudyContactsComponent,
    StudySitesComponent,
    StudySubmissionsComponent,
    SiteComponent,
    SiteOutcomeDocumentsComponent,
    SiteContactsComponent,
    SiteSubmissionsComponent,
    ForbiddenComponent,
    PageNotFoundComponent,
    RedirectComponent,
    SystemDowntimeComponent,
    ManageContactsComponent,
    ManageContactsContactsComponent,
    ManageContactsRequestsComponent,
    DashboardNeedsActionComponent,
    SubmissionSitesComponent,
    NoRecordsComponent,
    SubmissionSuccessComponent,
    WorkflowIntroComponent,
    EstimatedOutcomeCardComponent,
    DashboardDraftsComponent,
    DashboardInProgressComponent,
    SubmissionRegionComponent,
    SubmissionTypeComponent,
    MiniStatusTrackerComponent,
    UploadDocumentComponent,
    BarChartComponent,
    SubmissionIRBSearchComponent,
    SubmissionDetailsComponent,
    TeamComponent,
    TeamStudiesComponent,
    TeamSitesComponent,
    TeamMembersComponent,
    DownloadExportsComponent,
    DownloadDocumentsComponent,
    DownloadComponent,
    OutcomeDocumentsDownloadComponent,
    LiveChatTalkdeskComponent
  ],
  imports: [
    FormioModule,
    CoreModule,
    AdminModule,
    SharedModule,
    AppRoutingModule,
    LineTruncationLibModule,
    BlobModule.forRoot(),
    NgIdleModule.forRoot()
  ],
  providers: [
    ApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OAuthInterceptor,
      multi: true
    },
    {
      provide: FormioAppConfig,
      useValue: FormioConfig
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
