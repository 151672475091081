import { Component, ElementRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-live-chat-talkdesk',
  templateUrl: './live-chat-talkdesk.component.html'
})
export class LiveChatTalkdeskComponent {
  @ViewChild('chatIframe') iframe: ElementRef;
  iframeUrl: SafeResourceUrl;
  isChatLoaded = false;

  constructor(
    private sanitizer: DomSanitizer,
    private userService: UserService
  ) {
    const htmlFilePath = 'assets/chat-iframe.html';
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(htmlFilePath);
  }

  onload(): void {
    this.userService.getUserInfo().subscribe((data: any) => {
      if (this.isChatLoaded || !data?.isRegistered) {
        return;
      }

      const iframeElement: HTMLIFrameElement = this.iframe.nativeElement;
      // eslint-disable-next-line dot-notation
      iframeElement.contentWindow['loadLiveChat'](
        environment.talkDesk.touchPointId,
        environment.talkDesk.region
      );
      this.isChatLoaded = true;
    });
  }
}
