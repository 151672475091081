/* eslint-disable max-lines-per-function, complexity, max-len */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UtilService } from 'src/app/services/util.service';
import { PermissionType, RequestStatus, RequestStatusLabel, TeamGridType } from 'src/app/common/collections';
import { TeamMembersGrid, TeamMembersMap } from 'src/app/interfaces/teams';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { TeamService } from '../team.service';

@Injectable({
  providedIn: 'root'
})
export class TeamMembersService {
  public endDate = new BehaviorSubject<Date>(null);
  public startDate = new BehaviorSubject<Date>(null);
  public searchTerm = new BehaviorSubject<string>('');

  constructor(
    private translatePipe: TranslatePipe,
    private utilService: UtilService,
    private teamService: TeamService
  ) { }

  public mapMembersGridData(data: TeamMembersGrid): Array<TeamMembersMap> {
    const canEdit = this.teamService.hasManagePermission.value || this.teamService.isAdmin.value;
    const workspacePermissions = [PermissionType.View, PermissionType.Edit, PermissionType.Manage];
    const teamPermissions = [PermissionType.View, PermissionType.Manage];

    return data.records.map((record) => ({
      edit: `<button class="wcg-btn wcg-btn-icon read-only edit-btn" ${canEdit && !record.isPrimary ? '' : 'disabled'} data-edit-row-button>
      <wcg-icon src="../assets/icons/editor/ic_mode_edit_24px.svg"></wcg-icon>
      <span class="wcg-visually-hidden">${this.translatePipe.transform('actions.edit')}</span>
    </button>
    <div class="edit-mode-content edit-mode-actions">
      <button class="wcg-btn wcg-btn-icon submit-btn" data-submit-button>
        <wcg-icon src="../assets/icons/navigation/ic_check_24px.svg"></wcg-icon>
        <span class="wcg-visually-hidden">${this.translatePipe.transform('actions.submit')}</span>
      </button>

      <button class="wcg-btn wcg-btn-icon cancel-btn" data-cancel-button>
        <wcg-icon src="../assets/icons/navigation/ic_close_24px.svg"></wcg-icon>
        <span class="wcg-visually-hidden">${this.translatePipe.transform('actions.cancel')}</span>
      </button>
    </div>
  `,
      isPrimary: record.isPrimary,
      userEmail: record.email,
      name: record.isRegistered && record.isLicenseAccepted ?
        this.utilService.addTableCellWrapperWithSearchTerms(`${record.lastName}, ${record.firstName}`, this.searchTerm.value) :
        this.utilService.addTableCellWrapperWithSearchTerms(this.translatePipe.transform('manageContacts.invited'), this.searchTerm.value),
      id: record.externalReferenceId,
      email: this.utilService.addTableCellWrapperWithSearchTerms(record.email, this.searchTerm.value),
      organizationName: record.isRegistered && record.isLicenseAccepted ?
        this.utilService.addTableCellWrapperWithSearchTerms(record.organizationName, this.searchTerm.value) :
        this.utilService.addTableCellWrapperWithSearchTerms(this.translatePipe.transform('manageContacts.invited'), this.searchTerm.value),
      joinedDate: this.utilService.highlightDateFields(record.createdDateTimeOffset, this.startDate.value, this.endDate.value),
      workspacePermissionTypeValue: record.workspacePermissionType,
      workspacePermissionType: this.utilService.addPermissionsTableCellWrapperWithSearchTerms(
        record.workspacePermissionType,
        workspacePermissions,
        this.searchTerm.value
      ),
      permissionTypeValue: record.permissionType,
      permissionType: `${this.utilService.addPermissionsTableCellWrapperWithSearchTerms(
        record.permissionType,
        teamPermissions,
        this.searchTerm.value
      )} ${record.isPrimary ? `\n<wcg-badge> &nbsp; ${this.translatePipe.transform('teams.primaryUser')} &nbsp; </wcg-badge>` : ''}`,
      requestStatus: `${this.utilService.addRequestStatusTableCellWrapperWithSearchTerms(
        RequestStatusLabel[RequestStatus[record.requestStatus]], this.searchTerm.value, TeamGridType.user,
        this.teamService.hasManagePermission.value || this.teamService.isAdmin.value
      )}`
    }));
  }
}
