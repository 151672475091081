<ul class="wcg-row wcg-no-gutter status-tracker">
  <li class="wcg-col-xs status-tracker-item" *ngFor="let item of statuses; let i = index;" [ngClass]="{'active': item.isActive}" [ngSwitch]="i">
    <div class="status-tracker-icon">
      <div class="active-icon">
        <wcg-icon *ngSwitchCase="0" src="/assets/pictograms/draft{{ item.isActive ? '-active' : '' }}.svg"></wcg-icon>
        <wcg-icon *ngSwitchCase="1" src="/assets/pictograms/received{{ item.isActive ? '-active' : '' }}.svg"></wcg-icon>
        <wcg-icon *ngSwitchCase="2" src="/assets/pictograms/preparing{{ item.isActive ? '-active' : '' }}.svg"></wcg-icon>
        <wcg-icon *ngSwitchCase="3" src="/assets/pictograms/review{{ item.isActive ? '-active' : '' }}.svg"></wcg-icon>
        <wcg-icon *ngSwitchCase="4" src="/assets/pictograms/finalizing{{ item.isActive ? '-active' : '' }}.svg"></wcg-icon>
        <wcg-icon *ngSwitchCase="5" src="/assets/pictograms/outcome{{ item.isActive ? '-active' : '' }}.svg"></wcg-icon>
      </div>
    </div>

    <div class="status-tracker-item-value" [ngClass]="{'final-status': i === (statuses.length - 1)}">
      {{item.value}}
      <span class="status-tracker-item-date" *ngIf="item.changedOnDate">
        <span>
          {{ utilService.formatDate(item.changedOnDate) }}
        </span>
      </span>
    </div>

    <div class="progress-bar" [ngClass]="{'warning': warning}">
      <div *ngIf="item.isActive">
        <wcg-badge class="hold-badge">
          <wcg-icon *ngIf="!warning" class="hold-icon" src="/assets/icons/action/ic_check_circle_24px.svg"></wcg-icon>
          <wcg-icon *ngIf="warning" class="hold-icon" src="/assets/icons/alert/ic_warning_24px.svg"></wcg-icon>
        </wcg-badge>
      </div>
    </div>
  </li>
</ul>
