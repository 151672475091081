import { ContactOrganization } from '../interfaces/user';

export class ValueIdObject {
  id?: number;
  value?: string;
}

export class ValueIdStringObject {
  id?: string;
  value?: string;
}

export class SubmissionType {
  value?: string;
  groups?: Array<ValueIdStringObject>;
  envelopeAndEmailType?: string;
  isActive?: boolean;
  formType?: number;
  routingGroups?: ValueIdStringObject;
  documentTypes?: Array<ValueIdStringObject>;
  id?: number;
}

export class SubmissionStudy {
  id?: string;
  name: string;
  status?: ValueIdStringObject;
  secondaryIds?: string;
  protocolNumber?: string;
  isSingleReview?: boolean;
  irbOrganization?: ContactOrganization;

  hasMinimalRisk?: boolean;
  irbTrackingNumber?: string;
  sponsor?: string;
}

export class SubmissionDocType extends ValueIdObject {
  tags?: string;
}

export class SubmissionDocument {
  id?: string;
  blobFileName?: string;
  storageFilePath?: string;
  fileName: string;
  isLinked?: boolean;
  type?: SubmissionDocType;
}

export class SubmissionOrg {
  id?: string;
  name: string;
  organizationType: ValueIdObject;
  phone?: string;
}

export class SubmissionContactCountry {
  alpha2Code?: string;
  alpha3Code?: string;
  name?: string;
  id?: number;
}

export class SubmissionContactState {
  code?: string;
  name?: string;
  id?: number;
}

export class SubmissionContactAddress {
  addressLine1: string;
  addressLine2?: string;
  postalCode?: string;
  country?: SubmissionContactCountry;
  state?: SubmissionContactState;
  province?: string;
  city?: string;
}

export class SubmissionContact {
  id?: string;
  email?: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  suffix?: string;
  prefix?: string;
  degrees?: string;
  phone: string;
  isPrincipalInvestigator?: boolean;
  organization: SubmissionOrg;
  address?: SubmissionContactAddress;
}

export class SubmissionSelectedSite {
  id?: string;
  study?: ValueIdObject;
  investigator?: string;
  status?: string;
  institutionalTrackingNumber?: string;
  isMedicalOrInstitutional?: boolean;
  userId?: string;
  irbTrackingNumber?: string;
  lastReviewDate?: string;
  initialApprovalDate?: string;
  sponsor?: string;
  countryName?: string;
  protocolNumber?: string;
  expirationDate?: string;
  piOrganizationName?: string;
}

export class SubmissionPrincipalInvestigator {
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  suffix?: string;
  prefix?: string;
  degrees?: string;
  phone: string;
  organization: SubmissionOrg;
  address: SubmissionContactAddress;
  isMedicalOrInstitutional?: boolean;
}

export class SubmissionBaseModel {
  id?: string;
  submissionType?: SubmissionType;
  reviewType?: ValueIdObject;
  study?: SubmissionStudy;
  notes?: string;
  documents?: Array<SubmissionDocument>;
  contacts?: Array<SubmissionContact>;
  selectedSites?: Array<SubmissionSelectedSite>;
  title?: string;
  flowType?: number;
  concurrencyVersion?: string;
  draftConcurrencyVersion?: string;
  representationRole?: string;
  principalInvestigator?: SubmissionPrincipalInvestigator;
  submittedDate?: string;
  estimatedOutcomeDate?: string;
  numberOfDays: number;
  boardReviewTypeIds?: Array<number>;
}

export class SubmissionCreateModel extends SubmissionBaseModel {
  formDefinition: string;
  submissionData: string;
}

export class SubmissionDraftCreateModel extends SubmissionBaseModel {
  fileUploadGridData: string;
  isMedicalOrInstitutional?: boolean;
  formIOData?: string;
  formName?: string;
  formVersion?: string;
  siteSelectionResponse?: string;
}
