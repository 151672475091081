<wcg-modal [show]="show" (close)="closeRequestAccessModal()" class="access-modal">
  <wcg-modal-header>{{ 'requestAccess.title' | translate }}</wcg-modal-header>
  <wcg-modal-body>

    <span class="request-label wcg-h4">{{ 'requestAccess.label' | translate }}</span>

    <div class="wcg-rhythm-5">
    </div>

    <div class="wcg-row">
      <div class="wcg-col-xs-1 wcg-col-sm-1 wcg-col-md-3 wcg-col-lg-4">
        <wcg-radio ngDefaultControl (change)="changeRadio($event)" [checked]="selectedRadio === 'study'" value="study"
          name="study">Study</wcg-radio>
        <wcg-radio ngDefaultControl (change)="changeRadio($event)" [checked]="selectedRadio === 'studysite'"
          value="studysite" name="site">Site</wcg-radio>
      </div>

      <div class="wcg-col-xs-3 wcg-col-sm-3 wcg-col-md-5 wcg-col-lg-8">
        <div class="details">
          <wcg-icon class="icon lightbulb-icon" src="../assets/icons/action/ic_lightbulb_outline_24px.svg"></wcg-icon>
          <span class="details-text">{{ 'requestAccess.details' | translate }}</span>
        </div>
      </div>
    </div>

    <div class="wcg-rhythm-5">
    </div>

    <form [formGroup]="requestAccessForm" class="wcg-rhythm-2" autocomplete="off">
      <div class="wcg-row">
        <div class="wcg-col-xs-3 wcg-col-sm-3">
          <wcg-input class="search-term" ngDefaultControl formControlName="searchTerm" [value]="this.requestAccessForm.controls.searchTerm.value">
            <wcg-icon class="search-icon" src="../assets/icons/action/ic_search_24px.svg"></wcg-icon>
          </wcg-input>
        </div>
        <button class="wcg-btn wcg-btn-primary search-button" (click)="search(false)"
          [disabled]="requestAccessForm.invalid">Search</button>
      </div>
      <wcg-label>
        {{ selectedRadio === 'study' ? ('requestAccess.searchStudyLabel' | translate) : ('requestAccess.searchSiteLabel' | translate) }}
      </wcg-label>

      <!-- START : Search Result message -->
      <div class="wcg-col-xs-4 wcg-col-sm-4 search-result-message">
        {{searchResultMessage}}
      </div>
      <!-- END :  If found more than 1 studies in database -->


      <!-- START :  If found more than 1 studies in database -->
      <div class="multiple-choice" *ngIf="isMultipleChoices">
        <div class="wcg-row">
          <div class="wcg-col-xs-2 wcg-col-sm-2">
            <wcg-input formControlName="irbTracking" maxlength="9" placeHolder="IRB Tracking ID">
            </wcg-input>
          </div>
          <button class="wcg-btn wcg-btn-primary search-button" (click)="search(true)"
            [disabled]="requestAccessForm.invalid">{{ 'requestAccess.submitId' | translate }}</button>
        </div>
        <div *ngIf="requestAccessForm.controls.irbTracking.invalid">
          <wcg-error-message *ngIf="requestAccessForm.controls.irbTracking.errors?.minlength ||
            requestAccessForm.controls.irbTracking.errors?.maxlength">
            {{ 'requestAccess.irbTrackingLength' | translate }}
          </wcg-error-message>
          <wcg-error-message *ngIf="requestAccessForm.controls.irbTracking.errors?.pattern">Enter only number.
          </wcg-error-message>
        </div>
      </div>
      <!-- END :  If found more than 1 studies in database -->

      <!-- Warning message so (If no record or request failed-->
      <div class="warning-message" *ngIf="showNoMatches || showWarning">
        <div class="details" *ngIf="showNoMatches">
          <wcg-icon class="nomatches-icon" src="../assets/icons/social/ic_notifications_active_24px.svg">
          </wcg-icon>
          <span class="study-matches" *ngIf="!isMultipleChoices">{{ 'requestAccess.noSearchMatches' | translate }}</span>
          <span class="study-matches" *ngIf="isMultipleChoices">{{ 'requestAccess.noIrbMatches' | translate }}</span>
        </div>

        <div class="details" *ngIf="showWarning">
          <wcg-icon class="warning-icon" src="../../assets/icons/alert/ic_warning_24px.svg">
          </wcg-icon>
          <span class="study-matches">{{ 'requestAccess.notFoundWarningMessage' | translate }} </span>
        </div>
      </div>

      <div class="wcg-row search-result">
        <div class="wcg-col-xs-4 wcg-col-sm-4 result-header">
          <div class="wcg-col-xs-4 wcg-col-sm-4 search-result-message"
            *ngIf="irbResultMessage?.length > 0 && isMultipleChoices">
            {{irbResultMessage}}
          </div>

          <div class="wcg-row result-header" *ngIf="workspaceData">
            <div class="wcg-col-xs-1">
              Sponsor
            </div>
            <div class="wcg-col-xs-1">
              Sponsor Protocol ID
            </div>
            <div class="wcg-col-xs-1">
              IRB Tracking ID
            </div>
          </div>
          <div class="wcg-row result-value">
            <div class="wcg-col-xs-1">
              {{workspaceData?.sponsor}}
            </div>
            <div class="wcg-col-xs-1">
              {{workspaceData?.sponsorProtocolNumber}}
            </div>
            <div class="wcg-col-xs-1">
              {{workspaceData?.irbTrackingNumber}}
            </div>
          </div>
        </div>

      </div>
    </form>
  </wcg-modal-body>
  <wcg-modal-footer>
    <span></span>
    <div class="wcg-row">
      <button class="wcg-btn wcg-btn-auto wcg-btn-primary" [disabled]="!submitValid"
        (click)="submitAccessRequest()">{{ 'requestAccess.submitRequest' | translate }}</button>
    </div>
  </wcg-modal-footer>
</wcg-modal>

<wcg-modal [show]="showRequestAccessConfirmation" (close)="closeRequestAccessConfirmationModal()"
  class="access-modal">
  <wcg-modal-header>{{ 'requestAccess.title' | translate }}</wcg-modal-header>
  <wcg-modal-body>

    <div class="access-confirmation" *ngIf="showRequestSent || showAlreadyExist || showError">
      <div class="details">
        <wcg-icon class="circle-icon" src="../assets/icons/action/ic_check_circle_24px.svg"
          *ngIf="showRequestSent || showAlreadyExist">
        </wcg-icon>
        <wcg-icon class="error-icon" src="../../assets/icons/alert/ic_error_24px.svg" *ngIf="showError">
        </wcg-icon>
        <div class="message">
          <div class="success">{{confirmationMessage1}}</div>
          <div>{{confirmationMessage2}}</div>
          <div class="access-message" [innerHtml]="workspaceReferenceLineLink" *ngIf="showAlreadyExist"></div>
          <div class="access-message" *ngIf="showRequestSent || showError">{{confirmationMessage3}}</div>
        </div>
      </div>
    </div>

  </wcg-modal-body>
  <wcg-modal-footer>
    <span></span>
    <div class="wcg-row">
      <button class="wcg-btn wcg-btn-auto wcg-btn-primary"
        (click)="closeRequestAccessConfirmationModal()">{{ 'requestAccess.gotIt' | translate }}</button>
    </div>
  </wcg-modal-footer>
</wcg-modal>
