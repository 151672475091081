import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RequestAccessEntityType } from '../common/collections';

@Injectable({
  providedIn: 'root'
})
export class PageTransitionService {
  public canRequestAccess = new BehaviorSubject<boolean>(false);
  public lastPageEntityId = new BehaviorSubject<string>('');
  public entityType = new BehaviorSubject<RequestAccessEntityType>(RequestAccessEntityType.none);

  public setLastVisitPageInfo(canRequestAccess: boolean = false, id: string, entityType: RequestAccessEntityType): void {
    if (!id) {
      return;
    }

    this.canRequestAccess.next(canRequestAccess);
    this.lastPageEntityId.next(id);
    this.entityType.next(entityType);
  }

  public getLastVisitPageInfo(): any {
    const canRequestAccess = this.canRequestAccess.getValue();
    const lastPageEntityId = this.lastPageEntityId.getValue();
    const entityType = this.entityType.getValue();

    return {
      canRequestAccess: canRequestAccess,
      id: lastPageEntityId,
      entityType: entityType
    };
  }
}
