import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-submission-help',
  templateUrl: './submission-help.component.html',
  styleUrls: ['./submission-help.component.scss']
})

export class SubmissionHelpComponent {
  @Input() icon: string;
}
