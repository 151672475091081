<wcg-modal [show]="show" (close)="closeRequestAccessModal()" class="access-modal">
  <wcg-modal-header>{{'teams.addWorkspaceTitle' | translate: {workspaceType: workspaceType} }}</wcg-modal-header>
  <wcg-toast variant="error" position="top" *ngIf="showAddTeamsFailureMessage">{{ addTeamsFailureMessage }}</wcg-toast>
  <wcg-modal-body>

    <wcg-label *ngIf="!isRequest" class="request-label">
      {{ modalType === 'study' ? ('teams.addWorkspaceLabelStudy' | translate) : ('teams.addWorkspaceLabelSite' |
      translate) }}
    </wcg-label>
    <wcg-label *ngIf="isRequest" class="request-label">
      {{ modalType === 'study' ? ('teams.requestWorkspaceLabelStudy' | translate) : ('teams.requestWorkspaceLabelSite' |
      translate) }}
    </wcg-label>
    <br>

    <form [formGroup]="requestAccessForm" class="wcg-rhythm-2" autocomplete="off">
      <div class="wcg-row">
        <div class="wcg-col-xs-3 wcg-col-sm-3">
          <wcg-input class="search-term" ngDefaultControl formControlName="searchTerm"
            placeholder="Find a {{workspaceType}}" [value]="this.requestAccessForm.controls.searchTerm.value">
            <wcg-icon class="search-icon" src="../assets/icons/action/ic_search_24px.svg"></wcg-icon>
          </wcg-input>
        </div>
        <button class="wcg-btn wcg-btn-primary search-button" (click)="search(false)"
          [disabled]="requestAccessForm.controls.searchTerm.invalid">Search</button>
        <div *ngIf="(requestAccessForm.controls.searchTerm.dirty || requestAccessForm.controls.searchTerm.touched)">
          <wcg-error-message *ngIf="requestAccessForm.controls.searchTerm.hasError('maxlength')">
            {{ 'validation.maxChar' | translate: { fieldname: ('requestAccess.searchTerm' | translate), charLength: 400
            } }}
          </wcg-error-message>
        </div>
      </div>
      <wcg-label>
        {{ modalType === 'study' ? ('requestAccess.searchStudyLabel' | translate) : ('requestAccess.searchSiteLabel' |
        translate) }}
      </wcg-label>

      <!-- START : Search Result message -->
      <div class="wcg-col-xs-4 wcg-col-sm-4 search-result-message">
        {{searchResultMessage}}
      </div>
      <!-- END :  If found more than 1 studies in database -->


      <!-- START :  If found more than 1 studies in database -->
      <div class="multiple-choice" *ngIf="isMultipleChoices">
        <div class="wcg-row">
          <div class="wcg-col-xs-3 wcg-col-sm-3">
            <wcg-input formControlName="irbTracking" maxlength="9" placeHolder="IRB Tracking ID">
            </wcg-input>
          </div>
          <button class="wcg-btn wcg-btn-primary search-button" (click)="search(true)" [disabled]="requestAccessForm.controls.irbTracking.invalid || 
            requestAccessForm.controls.searchTerm.invalid">{{ 'requestAccess.submitId' | translate }}</button>
        </div>
        <div *ngIf="requestAccessForm.controls.irbTracking.invalid">
          <wcg-error-message *ngIf="requestAccessForm.controls.irbTracking.errors?.minlength ||
              requestAccessForm.controls.irbTracking.errors?.maxlength">
            {{ 'requestAccess.irbTrackingLength' | translate }}
          </wcg-error-message>
          <wcg-error-message *ngIf="requestAccessForm.controls.irbTracking.errors?.pattern">Enter only number.
          </wcg-error-message>
        </div>
      </div>
      <!-- END :  If found more than 1 studies in database -->

      <!-- Warning message so (If no record or request failed-->
      <div class="warning-message" *ngIf="showNoMatches || showWarning">
        <div class="details" *ngIf="showNoMatches">
          <wcg-icon class="nomatches-icon" src="../assets/icons/social/ic_notifications_active_24px.svg">
          </wcg-icon>
          <span class="study-matches" *ngIf="!isMultipleChoices">{{ 'requestAccess.noSearchMatches' | translate
            }}</span>
          <span class="study-matches" *ngIf="isMultipleChoices">{{ 'requestAccess.noIrbMatches' | translate }}</span>
        </div>

        <div class="details" *ngIf="showWarning">
          <wcg-icon class="warning-icon" src="../../assets/icons/alert/ic_warning_24px.svg">
          </wcg-icon>
          <span class="study-matches">{{ 'requestAccess.notFoundWarningMessage' | translate }} </span>
        </div>
      </div>

      <div class="wcg-row search-result" *ngIf="!showSitesList">
        <div class="wcg-col-xs-4 wcg-col-sm-4 result-header">
          <div class="wcg-col-xs-4 wcg-col-sm-4 search-result-message"
            *ngIf="irbResultMessage?.length > 0 && isMultipleChoices">
            {{irbResultMessage}}
          </div>
          <div class="wcg-row result-header" *ngIf="workspaceData && workspaceData.studyName != '[AmbiguousResults]'">
            <div class="wcg-col-xs-1">
              Sponsor
            </div>
            <div class="wcg-col-xs-1">
              Sponsor Protocol ID
            </div>
            <div class="wcg-col-xs-1">
              IRB Tracking ID
            </div>
          </div>
          <div class="wcg-row result-value">
            <div class="wcg-col-xs-1">
              {{workspaceData?.sponsor}}
            </div>
            <div class="wcg-col-xs-1">
              {{workspaceData?.sponsorProtocolNumber}}
            </div>
            <div class="wcg-col-xs-1">
              {{workspaceData?.irbTrackingNumber}}
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showSitesList">
        <div class="wcg-row site-match-text">
          <div class="wcg-col-xs-4">
            <p>
              {{ sitesListResultMessage }}
            </p>
          </div>
        </div>
        <div class="wcg-row result-site-header">
          <div class="wcg-col-md-1 wcg-col-lg-1 radio-button-column">
          </div>
          <div class="wcg-col-xs-1 wcg-col-md-1 wcg-col-lg-2">
            IRIS Study ID
          </div>
          <div class="wcg-col-md-2 wcg-col-lg-3 sponsor-column">
            Sponsor
          </div>
          <div class="wcg-col-xs-1 wcg-col-md-2 wcg-col-lg-3">
            Sponsor Protocol ID
          </div>
          <div class="wcg-col-xs-1 wcg-col-md-2 wcg-col-lg-3">
            Institution Tracking ID
          </div>
        </div>


        <div class="wcg-row result-site-row" *ngFor="let site of siteList">
          <div class="wcg-col-md-1 wcg-col-lg-1 radio-button-column">
            <wcg-radio name="selectSite" (change)="changeRadio($event)" value="{{site.externalReferenceId}}">
            </wcg-radio>
          </div>
          <div class="wcg-col-xs-1  wcg-col-md-1 wcg-col-lg-2">
            {{site?.irisStudyId ? site?.irisStudyId : this.notAvailable}}
          </div>
          <div class="wcg-col-md-2 wcg-col-lg-3 sponsor-column">
            {{site?.sponsorName}}
          </div>
          <div class="wcg-col-xs-1 wcg-col-md-2 wcg-col-lg-3">
            {{site?.sponsorProtocolId}}
          </div>
          <div class="wcg-col-xs-1 wcg-col-md-2 wcg-col-lg-3">
            {{site?.institutionalTrackingNumber ? site?.institutionalTrackingNumber : this.notAvailable}}
          </div>

        </div>

      </div>
    </form>
  </wcg-modal-body>
  <wcg-modal-footer>
    <span></span>
    <div class="wcg-row">
      <button *ngIf="!isRequest" class="wcg-btn wcg-btn-auto wcg-btn-primary" [disabled]="!submitValid || isRequesting"
        (click)="submitAccessRequest()">{{
        'teams.addTeamBtn' | translate }}</button>
      <button *ngIf="isRequest" class="wcg-btn wcg-btn-auto wcg-btn-primary" [disabled]="!submitValid || isRequesting"
        (click)="submitAccessRequest()">{{
        'teams.requestTeamBtn' | translate }}</button>
    </div>
  </wcg-modal-footer>
</wcg-modal>