/* eslint-disable max-lines */
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntil, debounce } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { timer } from 'rxjs';
import { StateService } from 'src/app/services/state.service';
import { ApiService } from 'src/app/services/api.service';
import { BaseComponent } from 'src/app/components/base/base.component';
import { TeamStudiesGridRecordMap, TeamStudiesGrid, TeamSitesGridRecordMap } from 'src/app/interfaces/teams';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { UtilService } from 'src/app/services/util.service';
import { TableHeader } from 'src/app/interfaces/components';
import { ToastService } from 'src/app/services/toast.service';
import { DEBOUNCE_TIME, AddWorkspaceTypeModal, TeamStudiesSortOptions }
  from 'src/app/common/collections';
import { WorkspaceOrganizationModel } from 'src/app/models/workspace-organization.model';
import { TeamStudiesService } from './team-studies.service';
import { TeamService } from '../team.service';

@Component({
  selector: 'app-team-studies',
  templateUrl: './team-studies.component.html',
  styleUrls: ['./team-studies.component.scss']
})
export class TeamStudiesComponent extends BaseComponent implements OnInit {
  @ViewChild('startDatePicker') startDatePicker;
  @ViewChild('endDatePicker') endDatePicker;
  public organizationId: string;
  public modalType: string;

  tableData: Array<TeamStudiesGridRecordMap>;
  currentPage = 1;
  currentPageSize = 10;
  currentSort: string = TeamStudiesSortOptions.nameLink;
  currentSortDescending: boolean;
  filteredData: Array<TeamStudiesGridRecordMap>;
  hasSelectedRows = false;
  loaded: boolean;
  loading: boolean;
  quickFilter: string | null;
  searchTerm = new FormControl('');
  selectedRows: Array<TeamSitesGridRecordMap> = [];
  showDeleteModal: boolean;
  totalPages: number;
  headers: Array<TableHeader>;
  totalRecords: number;
  studiesFetched = false;
  initialStudies: TeamStudiesGrid;
  isTeamActive = true;
  isAdmin = false;
  canManageStudies = false;

  options = {
    manualPaginationAndSort: true,
    rowSelection: true
  };

  teamId: string;
  isRemoving = false;

  constructor(
    public utilService: UtilService,
    private apiService: ApiService,
    private router: Router,
    private teamStudiesService: TeamStudiesService,
    private toastService: ToastService,
    private translatePipe: TranslatePipe,
    private route: ActivatedRoute,
    public stateService: StateService,
    private teamService: TeamService
  ) {
    super();
  }

  ngOnInit(): void {
    this.headers = this.getHeaders();
    this.currentSortDescending = false;
    this.route.params.subscribe((params) => {
      this.teamId = params.id;
    });

    const [id] = this.router.url.split('/teams/')[1].split('/');
    this.organizationId = id;
    this.modalType = AddWorkspaceTypeModal.study;
    this.teamStudiesService.searchTerm.next('');

    this.teamService.reloadTeamStudyGrid.subscribe((val) => {
      if (val) {
        this.searchTerm.reset();
        this.currentPage = 1;
        this.postSortedTeamStudiesGrid();
        this.initialStudies.records = [];
        this.studiesFetched = false;
        this.teamService.reloadTeamStudyGrid.next(false);
      }
    });

    this.teamService.isTeamActive.subscribe((val) => {
      this.isTeamActive = val;
    });

    this.teamService.isAdmin.subscribe((val) => {
      this.isAdmin = val;
      this.options.rowSelection = this.canManageStudies || this.isAdmin;
    });

    this.teamService.hasManagePermission.subscribe((val) => {
      this.canManageStudies = val;
      this.options.rowSelection = this.canManageStudies || this.isAdmin;
    });

    this.postDefaultTeamStudiesGrid();

    this.searchTerm.valueChanges
      .pipe(
        debounce(() => timer(DEBOUNCE_TIME))
      )
      .subscribe((val) => {
        this.currentPage = 1;
        this.teamStudiesService.searchTerm.next(val);
        this.postSortedTeamStudiesGrid();
      });
  }

  handlePageChange(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;
    this.postSortedTeamStudiesGrid();
  }

  handleRowClicked(event): void {
    if (event.detail.event.target.hasAttribute('href')) {
      event.detail.event.preventDefault();

      this.router.navigateByUrl(event.detail.event.target.getAttribute('href'));
    }

    const row = event.detail;
    const composedPath = event.detail.event.composedPath && event.detail.event.composedPath();
    const eventPath = event.detail.event.path || composedPath;

    if (eventPath.find((path) => path.attributes && path.attributes.getNamedItem('retry-request'))) {
      const data = {
        WorkspaceExternalReferenceId: row.workspaceExternalReferenceId,
        TeamExternalReferenceId: this.organizationId,
        IsRequested: !this.isAdmin
      } as WorkspaceOrganizationModel;

      this.retryRequest(data);
    }
  }

  handleRowSelection(event): void {
    this.selectedRows = event.detail;
    if (this.selectedRows.length > 0) {
      this.hasSelectedRows = true;
    }
    else {
      this.hasSelectedRows = false;
    }
  }

  handleSort(event): void {
    this.currentSort = TeamStudiesSortOptions[event.detail.field];
    this.currentSortDescending = !event.detail.ascending;
    this.currentPage = 1;
    this.postSortedTeamStudiesGrid();
  }

  closeDeleteModal(): void {
    this.showDeleteModal = false;
    this.searchTerm.reset();
    this.currentPage = 1;
    this.postSortedTeamStudiesGrid();
  }

  deleteSelectedRows(): void {
    const workspaceOrganizationIds = [];
    if (this.selectedRows && this.selectedRows.length) {
      this.selectedRows.forEach((site) => {
        workspaceOrganizationIds.push(site.workspaceOrganizationId);
      });
      if (workspaceOrganizationIds.length > 0) {
        this.isRemoving = true;
        this.apiService.deleteTeamWorkspaces(this.organizationId, workspaceOrganizationIds)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => {
            this.teamService.reloadTeamDetails.next(true);
            this.closeDeleteModal();
            this.isRemoving = false;
            this.initialStudies.records = [];
            this.studiesFetched = false;

            this.toastService.add([{
              closable: true,
              id: 'deleteStudiesSuccessful',
              message: this.translatePipe.transform('teams.deleteWorkspaceSuccess', { workspaceType: 'Study(ies)' }),
              timeout: this.toastService.ERROR_TIMEOUT,
              variant: 'success'
            }]);
          }, () => {
            this.closeDeleteModal();
            this.isRemoving = false;

            this.toastService.add([{
              closable: true,
              id: 'deleteStudiesFailure',
              message: this.translatePipe.transform('serverErrors.internalServerError'),
              timeout: this.toastService.ERROR_TIMEOUT,
              variant: 'error'
            }]);
          });
      }
    }
  }

  private getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('studies.name'), 'nameLink', true),
      this.utilService.createTableHeader(this.translatePipe.transform('studies.sponsor'), 'sponsor', true),
      this.utilService.createTableHeader(this.translatePipe.transform('studies.protocolNumber'), 'protocolNumber', true),
      this.utilService.createTableHeader(this.translatePipe.transform('studies.irbTrackingNumber'), 'irbTrackingNumber', true),
      this.utilService.createTableHeader(this.translatePipe.transform('studies.status'), 'status', true),
      this.utilService.createTableHeader(this.translatePipe.transform('studies.numberOfSites'), 'numberOfSitesLink', true),
      this.utilService.createTableHeader(this.translatePipe.transform('teams.requestStatus'), 'requestStatus', true)
    ];
  }

  private postTeamStudiesGrid(body): void {
    this.loading = true;
    this.hasSelectedRows = false;

    this.apiService.postTeamStudiesGrid(this.organizationId, body)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: TeamStudiesGrid) => {
        if (!this.initialStudies?.records?.length) {
          this.initialStudies = data;
        }
        this.teamService.joinedStudies.next(data);
        this.loaded = true;
        this.loading = false;
        this.totalRecords = data.totalRecords;
        this.tableData = this.teamStudiesService.mapStudiesGridData(data);
        this.currentPage = data.currentPage;
        this.studiesFetched = true;
      }, () => {
        this.loaded = true;
        this.loading = false;
        this.studiesFetched = true;

        this.toastService.add([{
          closable: true,
          id: 'postStudiesFailure',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          timeout: this.toastService.ERROR_TIMEOUT,
          variant: 'error'
        }]);
      });
  }

  private postDefaultTeamStudiesGrid(): void {
    this.postTeamStudiesGrid({
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: '',
      sortOptions: [{
        field: TeamStudiesSortOptions.nameLink,
        isDescending: false
      }]
    });
  }

  private postSortedTeamStudiesGrid(): void {
    this.postTeamStudiesGrid({
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }]
    });
  }

  private retryRequest(data: WorkspaceOrganizationModel): void {
    this.loading = true;

    this.apiService.addWorkspaceOrganization(data).subscribe(() => {
      this.searchTerm.reset();
      this.currentSort = TeamStudiesSortOptions.nameLink;
      this.currentSortDescending = false;
      this.loading = false;
      this.postSortedTeamStudiesGrid();

      this.toastService.add([{
        closable: true,
        id: 'retryRequestSuccessful',
        message: this.translatePipe.transform('teams.retryRequestSuccessful'),
        timeout: this.toastService.ERROR_TIMEOUT,
        variant: 'success'
      }]);
    }, () => {
      this.loaded = true;
      this.loading = false;
      this.toastService.add([{
        closable: true,
        id: 'retryRequestFailure',
        message: this.translatePipe.transform('serverErrors.internalServerError'),
        timeout: this.toastService.ERROR_TIMEOUT,
        variant: 'error'
      }]);
    });
  }
}
