import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';
import { BaseComponent } from '../../base/base.component';
import { SubmissionWorkflowService } from '../../../views/submission-workflow/submission-workflow.service';
import { ToastService } from '../../../services/toast.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { UtilService } from '../../../services/util.service';

const WORKFLOW_ROOT = '/submission-workflow';

@Component({
  selector: 'app-cancel-workflow-modal',
  templateUrl: './submission-exit-modal.component.html',
  styleUrls: ['./submission-exit-modal.component.scss']
})

export class CancelWorkflowComponent extends BaseComponent implements OnInit {
  currentUrl: string;
  showModal = false;

  constructor(
    public router: Router,
    public submissionWorkflowService: SubmissionWorkflowService,
    public translatePipe: TranslatePipe,
    private apiService: ApiService,
    private toastService: ToastService,
    private utilService: UtilService
  ) {
    super();
  }

  ngOnInit(): void {
    this.submissionWorkflowService.showWorkflowCancel.subscribe((res) => {
      this.showModal = res;
    });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentUrl = this.utilService.stripQueryParamsFromUrl(val.url);
      }
    });
  }

  public cancelClick(): void {
    this.submissionWorkflowService.showWorkflowCancel.next(false);
  }

  public deleteClick(): void {
    const draftId = this.submissionWorkflowService.formSubmissionId.value;

    if (draftId) {
      this.apiService.deleteSubmissionsDraft(draftId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.toastService.add([{
            closable: true,
            id: 'deleteSubmissionsDraftSuccess',
            message: 'Submission Deleted',
            timeout: 5000,
            variant: 'success'
          }]);
        }, (err) => {
          this.toastService.add([{
            closable: true,
            id: 'deleteSubmissionsDraftError',
            message: `Submission could not be deleted: ${err.details.error.message}`,
            timeout: 5000,
            variant: 'error'
          }]);
        });
    }

    this.submissionWorkflowService.leaveWorkflow();
  }

  public saveClick(): void {
    const dontSaveRoutes = [
      `${WORKFLOW_ROOT}/choose-sites`,
      `${WORKFLOW_ROOT}/search-irb`,
      `${WORKFLOW_ROOT}/search-study`,
      `${WORKFLOW_ROOT}/setup/regions`,
      `${WORKFLOW_ROOT}/setup/types`,
      `${WORKFLOW_ROOT}/success`
    ];

    const dontSaveFormio = this.currentUrl === `${WORKFLOW_ROOT}/details` && !this.submissionWorkflowService.saved.value;

    if (dontSaveRoutes.find((route) => this.currentUrl === route) || dontSaveFormio) {
      this.submissionWorkflowService.leaveWorkflow();
    }
    else {
      this.submissionWorkflowService.saveDraft();
    }
  }
}
