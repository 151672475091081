import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Toast } from '../interfaces/components';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  public alerts$: BehaviorSubject<Array<Toast>> = new BehaviorSubject<Array<Toast>>([]);

  public readonly ERROR_TIMEOUT = 5000;

  private timeouts = [];

  public add(alerts: Array<Toast>): void {
    const newAlerts = this.alerts$.getValue();

    for (const alert of alerts) {
      const idx = this.alerts$.getValue().findIndex((existingAlerts: {id: string}) => existingAlerts.id === alert.id);

      if (idx < 0) {
        newAlerts.push(alert);

        if (alert.timeout) {
          this.timeouts[idx] = setTimeout(() => {
            this.remove(alert);
          }, alert.timeout);
        }
      }
    }

    this.alerts$.next(newAlerts);
  }

  public remove(alert): void {
    const idx = this.alerts$.getValue().findIndex((existingAlerts: {id: string}) => existingAlerts.id === alert.id);

    clearTimeout(this.timeouts[idx]);

    this.alerts$.getValue().splice(idx, 1);
  }
}
