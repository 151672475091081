/* eslint-disable max-lines */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { timer } from 'rxjs';
import { debounce, takeUntil } from 'rxjs/operators';
import {
  DEBOUNCE_TIME,
  DocumentRequestTypes,
  flatPickrDateFormat,
  ServerErrorCodes,
  WorkspaceOutcomeDocumentDetailsModalFilterOptions,
  WorkspaceOutcomeDocumentDetailsModalSortOptions
}
  from 'src/app/common/collections';
import { TableHeader } from 'src/app/interfaces/components';
import { OutcomeDocumentsDetailsMap, SubmissionOutcomeMap } from 'src/app/interfaces/submissions';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { ApiService } from 'src/app/services/api.service';
import { StateService } from 'src/app/services/state.service';
import { ToastService } from 'src/app/services/toast.service';
import { UtilService } from 'src/app/services/util.service';
import { ZipService } from 'src/app/services/zip.service';
import { BaseComponent } from '../../base/base.component';
import { OutcomeDocumentDetailsModalService } from './outcome-document-details-modal.service';
import { GridExportType } from 'src/app/common/reportingCollections';

@Component({
  selector: 'app-outcome-document-details-modal',
  templateUrl: './outcome-document-details-modal.component.html',
  styleUrls: ['./outcome-document-details-modal.component.scss']
})
export class OutcomeDocumentDetailsModalComponent extends BaseComponent implements OnInit {
  @Input() submissionId: string
  @Input() studyId: string;

  loading: boolean;
  loaded: boolean;

  public headers: Array<TableHeader>;
  public outcomeDocumentToReview: SubmissionOutcomeMap;
  public tableData: Array<OutcomeDocumentsDetailsMap>;

  readonly exportType: GridExportType = GridExportType.StudyDetailsOutcomeDocumentsReview;
  gridFilters: any;

  reviewedAndTransmittedMessage: string;
  currentSort: string;
  currentSortDescending = true;
  filterOptions: any[] = [];
  startDateUTC: any = null;
  endDateUTC: any = null;
  documentRequestTypes = DocumentRequestTypes;
  selected: Array<SubmissionOutcomeMap> = [];
  documentsFetched = false;
  pageUnauthorized = false;

  startDatePickerOptions: FlatpickrOptions = {
    dateFormat: flatPickrDateFormat,
    defaultDate: null
  };

  endDatePickerOptions: FlatpickrOptions = {
    dateFormat: flatPickrDateFormat,
    defaultDate: null
  };

  @ViewChild('startDatePicker') startDatePicker;
  @ViewChild('endDatePicker') endDatePicker;

  startDateFilter = new FormControl('');
  endDateFilter = new FormControl('');
  searchTerm = new FormControl('');

  options = {
    rowSelection: true,
    rowsPerPage: 5,
    manualPaginationAndSort: true
  };

  currentPage: number;
  currentPageSize: number;
  totalRecords: number;
  totalPages: number;
  downloadingAll = false;
  pageSizeDataSource: Array<number>;

  constructor(public stateService: StateService,
    private utilService: UtilService,
    private translatePipe: TranslatePipe,
    public zipService: ZipService,
    private apiService: ApiService,
    private toastService: ToastService,
    private outcomeDocumentDetailsModalService: OutcomeDocumentDetailsModalService) {
    super();
  }

  ngOnInit(): void {
    this.stateService.selectedOutcomeDocumentToDetails.subscribe((newValue) => {
      if (newValue) {
        this.outcomeDocumentToReview = newValue;
        this.currentPage = 1;
        this.currentPageSize = 5;
        this.pageSizeDataSource = [5, 10, 25, 50];
        this.dropToDefaultValues();
        this.configureDateFilters(newValue);
        this.addFilterOptions();
        this.postOutcomeDocumentDetailsGrid({
          page: this.currentPage,
          pageSize: this.currentPageSize,
          searchTerm: '',
          filterOptions: this.filterOptions,
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });

        this.searchTerm.valueChanges
          .pipe(
            debounce(() => timer(DEBOUNCE_TIME))
          )
          .subscribe((val) => {
            this.outcomeDocumentDetailsModalService.searchTerm.next(val);
            this.currentPage = 1;

            this.postOutcomeDocumentDetailsGrid({
              page: this.currentPage,
              pageSize: this.currentPageSize,
              searchTerm: val,
              filterOptions: this.filterOptions,
              sortOptions: this.currentSort ? [{
                field: this.currentSort,
                isDescending: this.currentSortDescending
              }] : null,
              startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
              endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
            });
          });

        this.startDateFilterOptions();
        this.endDateFilterOptions();
      }
    });

    this.headers = this.getHeaders();
  }

  handleRowSelection(event): void {
    this.selected = event.detail;
  }

  public close(): void {
    this.headers.forEach((headerOptions) => {
      headerOptions._isSorted = false;
    });
    this.stateService.showOutcomeDetailsModal.next(false);
  }

  toggleEndDate(): void {
    this.endDatePicker.flatpickr.toggle();
  }

  toggleStartDate(): void {
    this.startDatePicker.flatpickr.toggle();
  }

  startDateFilterOptions(): void {
    this.startDateFilter.valueChanges
      .subscribe((val) => {
        this.jumpStartDates(val[0]);
        [this.endDatePicker.flatpickr.config.minDate] = val;

        this.currentPage = 1;

        this.outcomeDocumentDetailsModalService.startDate.next(val[0]);
        if (val[0]) {
          this.startDateUTC = moment(val[0]).utc(true);
        }

        this.addFilterOptions();
        this.postOutcomeDocumentDetailsGrid({
          page: this.currentPage,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          filterOptions: this.filterOptions,
          sortOptions: this.currentSort ? [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }] : null,
          startDate: val[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });
      });
  }

  endDateFilterOptions(): void {
    this.endDateFilter.valueChanges
      .subscribe((val) => {
        this.jumpEndDates(val[0]);
        [this.startDatePicker.flatpickr.config.maxDate] = val;

        this.currentPage = 1;

        this.outcomeDocumentDetailsModalService.endDate.next(val[0]);
        if (val[0]) {
          this.endDateUTC = moment(val[0]).utc(true);
        }

        this.addFilterOptions();
        this.postOutcomeDocumentDetailsGrid({
          page: this.currentPage,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          filterOptions: this.filterOptions,
          sortOptions: this.currentSort ? [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }] : null,
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: val[0] ? this.endDateUTC : null
        });
      });
  }

  jumpStartDates(value: any): void {
    if (value) {
      if (!this.endDateFilter.value[0]) {
        this.endDatePicker.flatpickr.jumpToDate(value);
      }
    }
    else if (this.endDateFilter.value[0]) {
      this.startDatePicker.flatpickr.jumpToDate(this.endDateFilter.value[0]);
    }
    else {
      this.endDatePicker.flatpickr.jumpToDate(new Date());
    }
  }

  jumpEndDates(value: any): void {
    if (value) {
      if (!this.startDateFilter.value[0]) {
        this.startDatePicker.flatpickr.jumpToDate(value);
      }
    }
    else if (this.startDateFilter.value[0]) {
      this.endDatePicker.flatpickr.jumpToDate(this.startDateFilter.value[0]);
    }
    else {
      this.startDatePicker.flatpickr.jumpToDate(new Date());
    }
  }

  public getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(
        this.translatePipe.transform('outcomeDocumentsDetailsModal.piName'), 'piFullName', true
      ),
      this.utilService.createTableHeader(
        this.translatePipe.transform('outcomeDocumentsDetailsModal.piOrganization'), 'piOrganizationName', true
      ),
      this.utilService.createTableHeader(
        this.translatePipe.transform('outcomeDocumentsDetailsModal.irbReviewed'), 'reviewedDate', true
      ),
      this.utilService.createTableHeader(
        this.translatePipe.transform('outcomeDocumentsDetailsModal.transmitted'), 'transmittedDate', true
      ),
      this.utilService.createTableHeader(
        this.translatePipe.transform('outcomeDocumentsDetailsModal.institutionalTrackingNumber'), 'institutionalTrackingNumber', true
      ),
      this.utilService.createTableHeader(
        this.translatePipe.transform('outcomeDocumentsDetailsModal.country'), 'countryName', true
      )
    ];
  }

  handleRowClick(event): void {
    if (event.detail.event.target.className.includes('data-download-outcome-document-button')) {
      this.zipService.startDownload([event.detail], this.documentRequestTypes.Outcome);
    }
  }

  handleChangePage(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;

    this.postOutcomeDocumentDetailsGrid({
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      filterOptions: this.filterOptions,
      sortOptions: this.currentSort ? [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }] : null,
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  handleSorting(event): void {
    this.currentSort = WorkspaceOutcomeDocumentDetailsModalSortOptions[event.detail.field];
    this.currentSortDescending = !event.detail.ascending;
    this.currentPage = 1;

    this.postOutcomeDocumentDetailsGrid({
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      filterOptions: this.filterOptions,
      sortOptions: [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }],
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  downloadAllDocuments(): void {
    this.downloadingAll = true;
    let getOutcomeDocumentsCall = null;

    if (this.submissionId) {
      getOutcomeDocumentsCall = this.apiService
        .createDownloadAllRequestForSubmissionOutcomeDocument(this.submissionId, this.outcomeDocumentToReview.documentId, {
          fileName: this.outcomeDocumentToReview.fileName,
          gridOptionsModel: {
            page: 1,
            pageSize: 0,
            searchTerm: this.searchTerm.value,
            filterOptions: this.filterOptions,
            startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
            endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
          }
        });
    }

    if (this.studyId) {
      getOutcomeDocumentsCall = this.apiService
        .createDownloadAllRequestForStudyOutcomeDocument(this.studyId, this.outcomeDocumentToReview.documentId, {
          fileName: this.outcomeDocumentToReview.fileName,
          gridOptionsModel: {
            page: 1,
            pageSize: 0,
            searchTerm: this.searchTerm.value,
            filterOptions: this.filterOptions,
            startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
            endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
          }
        });
    }

    getOutcomeDocumentsCall
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.downloadingAll = false;
        this.stateService.showDownloadModal.next(true);
      }, (error: any) => {
        this.downloadingAll = false;
        this.proccessErrorCall(error);
      });
  }

  addFilterOptions(): void {
    if (this.startDateFilter.value[0] || this.endDateFilter.value[0]) {
      if (!this.filterOptions.length) {
        const filterOption = [
          {
            field: WorkspaceOutcomeDocumentDetailsModalFilterOptions.transmittedDate
          },
          {
            field: WorkspaceOutcomeDocumentDetailsModalFilterOptions.reviewDate
          }
        ];
        this.filterOptions = filterOption;
      }
    }
    else {
      this.filterOptions = [];
    }
  }

  private dropToDefaultValues(): void {
    this.selected = [];
    this.documentsFetched = false;
    this.pageUnauthorized = false;
    this.filterOptions = [];
    this.searchTerm = new FormControl('');
    this.reviewedAndTransmittedMessage = '';
    this.currentSort = null;
    this.startDateFilter = new FormControl('');
    this.endDateFilter = new FormControl('');
    this.startDatePickerOptions.defaultDate = null;
    this.endDatePickerOptions.defaultDate = null;
    this.startDateUTC = null;
    this.endDateUTC = null;
    this.outcomeDocumentDetailsModalService.endDate.next(null);
    this.outcomeDocumentDetailsModalService.startDate.next(null);
    this.outcomeDocumentDetailsModalService.searchTerm.next('');
  }

  private configureDateFilters(newValue): void {
    if (newValue.startDateFilter !== null) {
      this.startDateFilter.setValue(newValue.startDateFilter);
      this.startDatePickerOptions.defaultDate = this.startDateFilter.value;
      this.startDateUTC = moment(this.startDateFilter.value[0]).utc(true);
      this.outcomeDocumentDetailsModalService.startDate.next(this.startDateFilter.value[0]);
    }
    if (newValue.endDateFilter !== null) {
      this.endDateFilter.setValue(newValue.endDateFilter);
      this.endDatePickerOptions.defaultDate = this.endDateFilter.value;
      this.endDateUTC = moment(this.endDateFilter.value[0]).utc(true);
      this.outcomeDocumentDetailsModalService.endDate.next(this.endDateFilter.value[0]);
    }
  }

  private postOutcomeDocumentDetailsGrid(body): void {
    this.loading = true;
    this.gridFilters = body;
    this.selected = [];

    if (this.submissionId) {
      this.apiService.getSubmissionOutcomeDocumentDetails(this.submissionId, this.outcomeDocumentToReview.documentId, body)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          this.processSuccessCallResponse(data);
        }, (error) => {
          this.proccessErrorCall(error);
        });
    }
    else if (this.studyId) {
      this.apiService.getStudyOutcomeDocumentDetails(this.studyId, this.outcomeDocumentToReview.documentId, body)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          this.processSuccessCallResponse(data);
        }, (error) => {
          this.proccessErrorCall(error);
        });
    }
  }

  private processSuccessCallResponse(data): void {
    const paginatedDocuments = data.outcomeDocumentDetailsModels;
    this.totalRecords = paginatedDocuments.totalRecords;
    this.totalPages = Math.ceil(paginatedDocuments.totalRecords / paginatedDocuments.pageSize);
    this.tableData = this.outcomeDocumentDetailsModalService
      .mapOutcomeSitesData(paginatedDocuments.records, this.outcomeDocumentToReview.fileName);

    if (data.studyReviewedDate) {
      this.reviewedAndTransmittedMessage =
        `${this.translatePipe.transform('outcomeDocumentsDetailsModal.reviewedOn')} ${this.utilService.formatDate(data.studyReviewedDate)}`;
      if (data.studyTransmittedDate) {
        const andLabel = this.translatePipe.transform('outcomeDocumentsDetailsModal.and');
        const transmittedOnLabel = this.translatePipe.transform('outcomeDocumentsDetailsModal.transmittedOn');
        const formattedTransmittedDate = this.utilService.formatDate(data.studyTransmittedDate);
        this.reviewedAndTransmittedMessage += ` ${andLabel} ${transmittedOnLabel} ${formattedTransmittedDate}`;
      }
    }
    else if (data.studyTransmittedDate) {
      const formattedTransmittedDate = this.utilService.formatDate(data.studyTransmittedDate);
      this.reviewedAndTransmittedMessage =
        `${this.translatePipe.transform('outcomeDocumentsDetailsModal.transmittedOnCapital')} ${formattedTransmittedDate}`;
    }

    this.documentsFetched = true;
    this.loaded = true;
    this.loading = false;
  }

  private proccessErrorCall(error): void {
    this.documentsFetched = true;
    this.loaded = true;
    this.loading = false;

    if (error.details.status === ServerErrorCodes.unauthorizedStatusNumber) {
      this.pageUnauthorized = true;
    }
    else {
      this.pageUnauthorized = true;
      this.documentsFetched = true;
      this.toastService.add([{
        closable: true,
        id: 'getSubmissionOutcome',
        message: this.translatePipe.transform('serverErrors.internalServerError'),
        variant: 'error'
      }]);
    }
  }
}
