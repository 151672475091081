import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { ApiService } from '../../../services/api.service';
import { BaseComponent } from '../../../components/base/base.component';
import { environment } from '../../../../environments/environment';
import { FlowTypeName } from '../../../common/collections';
import { FormioPDFService } from '../../../services/formio-pdf.service';
import { SubmissionInfo } from '../../../interfaces/submissions';
import { SubmissionWorkflowService } from '../submission-workflow.service';
import { ToastService } from '../../../services/toast.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { UtilService } from '../../../services/util.service';
import { protocolInfo2, protocolInfoSite } from '../../../common/formio-map';

@Component({
  selector: 'app-submission-success',
  templateUrl: './submission-success.component.html',
  styleUrls: ['./submission-success.component.scss']
})
export class SubmissionSuccessComponent extends BaseComponent implements OnInit {
  contactsLink: string;
  estimatedOutcomeDate: string;
  formIOForm: any;
  formSRCPath: string;
  id: string;
  outcomeDate: string;
  studyId: string;
  studyName: string;
  studySponsor: string;
  status: string;
  statuses: Array<any>;
  submission: SubmissionInfo;
  submissionData: any;
  submissionHeading: string;
  workflowDetails: string;
  workflowIntro: string;

  constructor(
    public formioPDFService: FormioPDFService,
    public router: Router,
    public submissionWorkflowService: SubmissionWorkflowService,
    public translatePipe: TranslatePipe,
    public utilService: UtilService,
    private apiService: ApiService,
    private toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this.status = this.translatePipe.transform('submissionStatus.draft');
    this.submissionData = this.submissionWorkflowService.formInfo.value;
    this.workflowIntro = this.translatePipe.transform(
      `submissionSuccess.${this.submissionWorkflowService.submissionFlow.value || 'addStudy'}IntroTitle`
    );
    this.workflowDetails = this.translatePipe.transform(
      `submissionSuccess.${this.submissionWorkflowService.submissionFlow.value || 'addStudy'}IntroDetails`
    );
    this.submissionHeading = this.translatePipe.transform(
      `submissionTypes.${this.submissionWorkflowService.submissionFlow.value || 'addStudy'}CardTitle`
    );

    this.formSRCPath = `${environment.formio.appUrl}/${this.submissionWorkflowService.formName.value}?full=true`;

    // only call getSubmission if route is visited directly and service is not populated
    if (Object.keys(this.submissionData).length) {
      this.studyId = this.submissionWorkflowService.successObject.value.study?.id;
      this.populateSubmission(this.submissionData);
      this.updateContactsLink(this.studyId);
    }
    else {
      [this.id] = this.router.url.split('/success/')[1].split('/');
      this.getSubmission(this.id);
    }
  }

  public inviteContacts(): void {
    this.submissionWorkflowService.resetSubmissionData();
    this.router.navigate([
      this.contactsLink
    ]);
  }

  public setFormIOForm(event): void {
    this.formIOForm = event.formio;
    this.formIOForm.nosubmit = true;
  }

  private getEstimatedOutcomeDate(data): string {
    if (this.submissionWorkflowService.submissionFlow.value !== 'addStudy') {
      return '';
    }

    return this.utilService.formatDate(
      this.submissionWorkflowService.successObject.value.estimatedOutcomeDate
    ) || data.estimatedOutcomeDate;
  }

  private getSubmission(id): void {
    this.apiService.getSubmission(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.studyId = data.study?.id;
        this.populateSubmission(data);
        this.updateContactsLink(this.studyId);
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'getSubmission',
          message: err,
          variant: 'error'
        }]);
      });
  }

  // TODO: optimize logic based upon submissionFlow BOW-2566
  // eslint-disable-next-line complexity
  private populateSubmission(data): void {
    const {
      id,
      name,
      protocol,
      sponsor
    } = this.submissionWorkflowService.submissionFindStudy.value;

    this.studyName = data?.study?.name || data?.[protocolInfo2.form]?.data?.[protocolInfo2.fields.studyName] || name;
    this.studySponsor = data?.study?.sponsor || data?.[protocolInfo2.form]?.data?.[protocolInfo2.fields.sponsor] || sponsor;
    this.statuses = data.statuses || this.submissionWorkflowService.successObject.value?.statusHistory;
    this.status = this.statuses.find((status) => status.isActive).value;
    this.estimatedOutcomeDate = this.getEstimatedOutcomeDate(data);
    this.submission = {
      sponsorId: data.study?.protocolNumber || data?.[protocolInfo2.form]?.data?.[protocolInfo2.fields.sponsorsProtocolId] || protocol,
      status: this.status,
      submissionName: data.title ||
        data?.[protocolInfo2.form]?.data?.[protocolInfo2.fields.submissionName] ||
        data[protocolInfoSite.form]?.data[protocolInfoSite.fields.submissionName],
      submissionType: data.type?.value || this.submissionWorkflowService.submissionTypeValue?.value,
      studyId: this.studyId || id
    };
  }

  private updateContactsLink(studyId: string): void {
    const siteId = this.submissionWorkflowService.submissionTypeGroup.value?.value === FlowTypeName.NewSite ?
      this.submissionWorkflowService.successObject.value.studySiteId : '';
    const id = siteId ? siteId : studyId;
    const url = siteId ? 'sites' : 'studies';

    this.contactsLink = `/${url}/${id}/manage-contacts/contacts`;
  }
}
