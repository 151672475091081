export enum AdminApis {
  CreateOrUpdateDocumentTypeCategory = '/api/documents/documentTypeCategory',
  CreateOrUpdateDocumentType = '/api/documents/type',
  GetDocumentTypeCategory = '/api/documents/documentTypeCategory/list',
  GetDocumentTypes = '/api/documents/types/grid',
  GetDocumentTypeCategoriesFullList = '/api/documents/type/categories',
  GetUserInfo = '/api/users/grid',
  DocumentTypes = '/api/documents/types',
  SubmissionTypeGroup = '/api/submissions/submissionTypeGroups',
  SubmissionTypesGrid = '/api/submissions/type/grid',
  SubmissionTypePost = '/api/submissions/type/create',
  IrisRoutingGroup = '/api/submissions/routingGroups',
  OrganizationTypes = '/api/contacts/organizations/types',
  Teams = '/api/contacts/teams',
  UpdateTeam = '/api/workspaces/teamOrganizations/{teamId}',
  SetTeamOrganizationStatus = '/api/workspaces/organizations/teams/{teamId}/status',
  AddTeamUser = '/api/contacts/organizations/{teamId}/addUser',
  DeleteTeamWorkspace = '/api/workspaces/delete/organizations/{teamId}',
  DeleteTeamUser = '/api/workspaces/organizations/teams/{teamId}/deleteUser',
  AdminSubmissions = '/api/submissions/admin-grid',
  SubmissionTypes = '/api/submissions/types/active',
}
