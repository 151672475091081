import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-nav-item',
  templateUrl: './form-nav-item.component.html',
  styleUrls: ['./form-nav-item.component.scss']
})

export class FormNavItemComponent {
  @Input() active: boolean;
  @Input() visible: boolean;
}
