import { Component, OnInit } from '@angular/core';
import { PageTransitionService } from 'src/app/services/page.transition.service';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base/base.component';
import { RequestAccessEntityType } from 'src/app/common/collections';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent extends BaseComponent implements OnInit {
  canRequestAccess = false;
  id: string;
  entityType: RequestAccessEntityType

  isShowLoader = false;

  private readonly internalServerErrorStatusCode = 400;

  constructor(private pageTransitionService: PageTransitionService,
    private apiService: ApiService,
    private router: Router,
    private toastService: ToastService,
    private translatePipe: TranslatePipe) {
    super();
  }

  ngOnInit(): void {
    const lastVisitPageInfo = this.pageTransitionService.getLastVisitPageInfo();
    this.canRequestAccess = lastVisitPageInfo.canRequestAccess;
    this.id = lastVisitPageInfo.id;
    this.entityType = lastVisitPageInfo.entityType;

    if (this.entityType === RequestAccessEntityType.submission) {
      this.checkCanRequestToSubmission(this.id);
    }
  }

  requestAccess(): void {
    let requestAccess;

    if (!this.canRequestAccess) {
      return;
    }

    this.isShowLoader = true;

    if (this.entityType === RequestAccessEntityType.site || this.entityType === RequestAccessEntityType.study) {
      requestAccess = this.apiService.postWorkspacesAccessRequest(this.id);
    }
    else if (this.entityType === RequestAccessEntityType.submission) {
      requestAccess = this.apiService.postSubmissionAccessRequest(this.id);
    }

    requestAccess
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.toastService.add([{
          closable: true,
          id: 'reqestAccessSuccess',
          message: this.translatePipe.transform('requestAccess.successRequest'),
          timeout: 5000,
          variant: 'success'
        }]);
        this.router.navigate(['/dashboard']);
      },
      (err: any) => {
        this.pushTostIfError(err);

        this.router.navigate(['/dashboard']);
      });
  }

  private checkCanRequestToSubmission(submissionId): void {
    this.isShowLoader = true;

    this.apiService.checkIfItCanRequestAccessToSubmission(submissionId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((isItCanRequestAccess: boolean) => {
        this.canRequestAccess = isItCanRequestAccess;

        this.isShowLoader = false;
      });
  }

  private pushTostIfError(err: any): void {
    if (err.details.error.statusCode === this.internalServerErrorStatusCode) {
      let errorMessage;

      switch (this.entityType) {
      case RequestAccessEntityType.site:
        errorMessage = 'requestAccess.siteAlreadySubmitted';
        break;
      case RequestAccessEntityType.study:
        errorMessage = 'requestAccess.studyAlreadySubmitted';
        break;
      case RequestAccessEntityType.submission:
        errorMessage = 'requestAccess.submissionAlreadySubmitted';
        break;
      default:
        errorMessage = 'serverErrors.internalServerError';
        break;
      }

      const tranlatedErrorMessage = this.translatePipe.transform(errorMessage);

      this.toastService.add([{
        closable: true,
        id: 'ErrAccessRequest',
        message: tranlatedErrorMessage,
        timeout: 5000,
        variant: 'success'
      }]);
    }
  }
}

