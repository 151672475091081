import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Component({
  selector: 'app-auto-login',
  template: ''
})
export class AutoLoginComponent implements OnInit {
  constructor(private authenticationService: AuthenticationService) {
  }

  public ngOnInit(): void {
    this.authenticationService.login();
  }
}
