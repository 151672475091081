import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ApiService } from './api.service';
import { SubmissionFlowProtocolInfo, SubmissionFindStudy } from '../interfaces/submissions';
import { SubmissionWorkflowService } from '../views/submission-workflow/submission-workflow.service';
import { ToastService } from './toast.service';
import { TranslatePipe } from '../pipes/translate.pipe';
import { UserInfoDetailsMap } from '../interfaces/user';
import { UserService } from './user.service';
import {
  protocolInfo2,
  protocolInfoSite
} from '../common/formio-map';
import { SubmissionRegions } from '../common/collections';

const PDF_HEADER_DATE_FORMAT = 'DD-MMM-YYYY-HH:mm:ss';
const PDF_HEADER_MAX = 75;
const ACKNOWLEDGEMENTS_FORM_NAME = 'Acknowledgements';

@Injectable({
  providedIn: 'root'
})
export class FormioPDFService {
  consolidatedPdfFilename: string;
  userInfo: UserInfoDetailsMap;

  constructor(
    public submissionWorkflowService: SubmissionWorkflowService,
    private apiService: ApiService,
    private userService: UserService,
    private toastService: ToastService,
    private translatePipe: TranslatePipe
  ) {
    this.userService.getUserInfo().subscribe((data) => {
      this.userInfo = data;
    });

    this.submissionWorkflowService.consolidatedPdf.subscribe((val) => {
      if (val instanceof Blob) {
        this.downloadBlob(
          this.consolidatedPdfFilename,
          this.submissionWorkflowService.consolidatedPdf.value
        );
      }
    });
  }

  public downloadConsolidatedPdf(): void {
    this.generatePdfs(true);
  }

  public downloadSeparatedPdfs(): void {
    this.submissionWorkflowService.pdfFormDocumentIndexes.value.forEach((form) => {
      this.downloadBlob(
        form,
        this.submissionWorkflowService.separatePdfs.value[form]
      );
    });
  }

  public downloadDataPDF(formioForm, requiredForSubmission?): void {
    const formioPDFData = formioForm.data;
    const rootData = this.getRootData(
      formioForm?.data?.[protocolInfo2.form]?.data,
      formioForm?.data?.[protocolInfoSite.form]?.data,
      this.submissionWorkflowService.submissionFindStudy.value
    );

    const { sponsorProtocolId, sponsorsProtocolId, submissionName } = rootData;

    const submissionStrings = [
      this.truncateHeaderString(this.userInfo.email),
      this.truncateHeaderString(this.submissionWorkflowService.submissionTypeValue.value),
      this.truncateHeaderString(sponsorProtocolId || sponsorsProtocolId),
      this.truncateHeaderString(submissionName),
      this.addSubmissionRegionType(),
      this.addUpdateSiteGroupAnswer(this.submissionWorkflowService.siteSelectionResponse.value)
    ];
    const submissionHeader = submissionStrings.filter((str) => Boolean(str) === true).join('<br/>');

    this.parseSubmissionData(formioForm.data, formioPDFData);
    this.createFormioPDF(
      submissionHeader,
      formioForm.settings,
      {
        ...formioPDFData,
        ...rootData
      },
      formioForm.components,
      requiredForSubmission
    );
  }

  public generatePdfs(isDraftSubmission: boolean = true): void {
    this.submissionWorkflowService.consolidatedPdf.next({});
    this.submissionWorkflowService.separatePdfs.next({});

    const formDataValue = this.submissionWorkflowService.formData.value;
    const exportData = {
      data: formDataValue.data || formDataValue,
      formType: formDataValue.formType || '',
      settings: formDataValue.form?.settings || this.submissionWorkflowService.formData.value.form.settings
    };

    this.consolidatedPdfFilename = this.submissionWorkflowService.availableForms.value.find(
      (form) => form.title.toLowerCase() === this.submissionWorkflowService.submissionTypeValue.value.toLowerCase()
    ).pdfFileName;

    if (isDraftSubmission === true) {
      this.submissionWorkflowService.consolidatedPdfDisabled = true;
      // Download consolidated PDF
      this.downloadDataPDF({
        ...exportData,
        ...{
          components: formDataValue.form?.components || this.submissionWorkflowService.formData.value.form.filteredPdfComponents
        }
      });

      return;
    }

    const dynamicTitles: string[] = [];
    const mainPdfComponents = [
      ...formDataValue.form?.components
    ];

    // Download separate dynamic PDFs
    formDataValue.components.forEach((component) => {
      if (component.component?.tags?.indexOf('separatePDF') >= 0 && component.visible) {
        this.downloadDataPDF({
          ...exportData,
          ...{
            components: component.components.map((comp) => comp.component)
          }
        }, component.component.title);
        dynamicTitles.push(component.component.title);
      }
    });

    // get form Json for acknowledgements
    this.downloadAcknowledgementsPDF();

    // add acknowledgements form to list of dynamic forms
    dynamicTitles.push(ACKNOWLEDGEMENTS_FORM_NAME);

    const filteredPdfComponents = mainPdfComponents.filter((component) => !dynamicTitles.includes(component.title));

    dynamicTitles.push(this.consolidatedPdfFilename);

    this.submissionWorkflowService.pdfFormDocumentIndexes.next(dynamicTitles);

    // Download consolidated PDF without separate forms
    this.downloadDataPDF({
      ...exportData,
      ...{
        components: filteredPdfComponents
      }
    },
    this.consolidatedPdfFilename);
  }

  private downloadAcknowledgementsPDF(): void {
    const formDataValue = this.submissionWorkflowService.formData.value;
    // fetch data for acknowledgements
    const data = this.submissionWorkflowService.getAcknowledgementsData();
    const combinedData = {
      ...data,
      ...formDataValue.data || formDataValue
    };

    this.apiService.getFormioForm('acknowledgementForm')
      .subscribe((form) => {
        const ackExportData = {
          data: combinedData,
          formType: form.type,
          settings: form.settings
        };
        const { components } = form;
        this.downloadDataPDF({
          ...ackExportData,
          ...{
            components
          }
        }, ACKNOWLEDGEMENTS_FORM_NAME);
      });
  }

  private downloadBlob(fileName: string, data): void {
    const filePrefix = this.translatePipe.transform('submissionPdf.pdfFilename');
    const fileSuffix = moment(new Date()).format(this.submissionWorkflowService.pdfFilenameDateFormat).toUpperCase();
    const downloadLink = document.createElement('a');

    downloadLink.setAttribute('href', window.URL.createObjectURL(data));
    downloadLink.setAttribute('download', `${filePrefix}_${fileName}_${fileSuffix}.pdf`);
    downloadLink.click();
  }

  private createFormioPDF(headerString: string, formSettings, submissionData, formJson, requiredForSubmission?): void {
    const date = `${moment(new Date()).format(PDF_HEADER_DATE_FORMAT).toUpperCase()}`;

    this.apiService.downloadFormioPDF(JSON.stringify({
      form: {
        title: `${headerString} <br/> ${date}`,
        components: formJson,
        settings: formSettings
      },
      submission: {
        data: submissionData,
        metadata: this.submissionWorkflowService.submissionMetadata.value
      }
    })).subscribe((res) => {
      if (requiredForSubmission) {
        const formObject = {};

        formObject[requiredForSubmission] = res;

        this.submissionWorkflowService.separatePdfs.next({
          ...this.submissionWorkflowService.separatePdfs.value,
          ...formObject
        });
      }
      else {
        this.submissionWorkflowService.consolidatedPdf.next(res);
        this.submissionWorkflowService.consolidatedPdfDisabled = false;
      }
    }, (err) => {
      this.toastService.add([{
        closable: true,
        id: 'downloadFormioPDF',
        message: err.details.message,
        variant: 'error'
      }]);
    });
  }

  private getRootData = (
    protocolInfo2Data: SubmissionFlowProtocolInfo,
    protocolInfoSiteData: SubmissionFlowProtocolInfo,
    studyData: SubmissionFindStudy
  ): SubmissionFlowProtocolInfo => {
    if (studyData?.id) {
      return {
        sponsor: studyData.sponsor,
        sponsorProtocolId: studyData.protocol,
        studyName: studyData.id,
        submissionName: protocolInfoSiteData.submissionName
      };
    }

    if (protocolInfo2Data?.submissionName?.length) {
      return protocolInfo2Data;
    }

    if (protocolInfoSiteData?.submissionName?.length) {
      return protocolInfoSiteData;
    }
  }

  private parseNestedSubmissionData = (nestedData, pdfData): void => {
    for (const formData in nestedData) {
      if (formData) {
        pdfData[formData] = nestedData[formData];
      }
    }
  }

  private parseSubmissionData(submissionDataToBeParsed, pdfData): void {
    if (submissionDataToBeParsed) {
      for (const submissionData in submissionDataToBeParsed) {
        if (submissionDataToBeParsed[submissionData]?.data) {
          this.parseNestedSubmissionData(submissionDataToBeParsed[submissionData]?.data, pdfData);
        }
        else {
          pdfData[submissionData] = submissionDataToBeParsed[submissionData];
        }
      }
    }
  }

  private truncateHeaderString = (value: string): string => value?.length > PDF_HEADER_MAX ? `${value?.slice(0, PDF_HEADER_MAX)}...` : value

  private addSubmissionRegionType(): string {
    const selectedSubmissionRegionIds = this.submissionWorkflowService.submissionRegions.value.map(Number);
    const reviewTypesArray = this.submissionWorkflowService.submissionReviewTypes.getValue();
    if (reviewTypesArray.length > 0 && selectedSubmissionRegionIds.length > 0) {
      const matchingTypes = reviewTypesArray.filter((type) => selectedSubmissionRegionIds.includes(Number(type.id)));
      const matchingTypeValues = matchingTypes.map((type) => type.value);
      const updatedMatchingTypeValues =
      matchingTypeValues.map((value) => SubmissionRegions.find((item) => item.value === value).pdfViewValue);
      const selectedSubmissionRegionNames = updatedMatchingTypeValues.join(', ');

      return `${this.translatePipe.transform('submissionWorkflow.addSelectedRegionsHeader')} ${selectedSubmissionRegionNames}`;
    }
  }

  private addUpdateSiteGroupAnswer(value: string): string {
    let updateSiteGroupAnswerText = '';
    if (value === '') {
      return value;
    }

    switch (value) {
    case 'all':
      updateSiteGroupAnswerText = this.translatePipe.transform('sites.updateAll');
      break;
    case 'selected':
      updateSiteGroupAnswerText = this.translatePipe.transform('sites.updateWithSelection');
      break;
    case 'studyOnly':
      updateSiteGroupAnswerText = this.translatePipe.transform('sites.updateStudyOnly');
      break;
    default:
      break;
    }

    return `${this.translatePipe.transform('submissionWorkflow.addSitesHeaderQuestion')} ${updateSiteGroupAnswerText}`;
  }
}

