/* eslint-disable class-methods-use-this */
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil, debounce } from 'rxjs/operators';
import { SubmissionDocumentsMap } from '../../../interfaces/submissions';
import { BaseComponent } from '../../../components/base/base.component';
import { ApiService } from '../../../services/api.service';
import { SubmissionsDetailService } from '../submission.service';
import { ToastService } from '../../../services/toast.service';
import { UtilService } from 'src/app/services/util.service';
import { ZipService } from 'src/app/services/zip.service';
import { DocumentRequestTypes, DEBOUNCE_TIME, ServerErrorCodes, SubmissionSubmittedDocumentSortOptions } from 'src/app/common/collections';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { TableHeader } from '../../../interfaces/components';
import { timer } from 'rxjs';
import { GridOptionsSearchModel } from 'src/app/admin/shared/model/grid.model';
import { GridExportType } from 'src/app/common/reportingCollections';

@Component({
  selector: 'app-submitted-documents',
  templateUrl: './submission-submitted-documents.component.html',
  styleUrls: ['./submission-submitted-documents.component.scss']
})
export class SubmittedDocumentsComponent extends BaseComponent implements OnInit {
  tableData: Array<SubmissionDocumentsMap>;
  id: string;
  searchTerm = new FormControl('');
  selected: Array<SubmissionDocumentsMap> = [];
  headers: Array<TableHeader>;
  documentUrlsFetched = false;
  loaded: boolean;
  loading: boolean;
  initialDocuments: Array<SubmissionDocumentsMap> = [];
  documentRequestTypes = DocumentRequestTypes;
  pageUnauthorized = false;

  currentSort: string;
  currentSortDescending = true;

  currentPage: number;
  totalPages: number;
  currentPageSize: number;
  totalRecords: number;

  options = {
    manualPaginationAndSort: true,
    rowSelection: true,
    rowsPerPage: 10
  };

  gridFilters: GridOptionsSearchModel;
  readonly exportType: GridExportType = GridExportType.SubmissionDetailsSubmittedDocuments;

  constructor(
    public zipService: ZipService,
    private apiService: ApiService,
    private router: Router,
    private submissionDetailService: SubmissionsDetailService,
    private toastService: ToastService,
    public utilService: UtilService,
    private translatePipe: TranslatePipe
  ) {
    super();

    [this.id] = this.router.url.split('/submissions/')[1].split('/');
    this.currentPageSize = 10;
    this.headers = this.getHeaders();
    this.postSubmissionDocumentsGrid(this.id, {
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: ''
    });
  }

  ngOnInit(): void {
    this.searchTerm.valueChanges
      .pipe(
        debounce(() => timer(DEBOUNCE_TIME))
      )
      .subscribe((val) => {
        this.submissionDetailService.searchTerm.next(val);
        this.postSubmissionDocumentsGrid(this.id, {
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: val,
          sortOptions: this.currentSort ? [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }] : null
        });
      });
  }

  getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(
        this.translatePipe.transform('submissionDetails.submittedDocumentFilename'), 'fileNameLink', true
      ),
      this.utilService.createTableHeader(
        this.translatePipe.transform('submissionDetails.submittedDocumentType'), 'type', true
      )
    ];
  }

  handleRowSelection(event): void {
    this.selected = event.detail;
  }

  handleRowClick(event): void {
    if (event.detail.event.target.hasAttribute('href')) {
      this.apiService.patchDocumentsLastDownloadDate(
        [event.detail.documentId],
        this.documentRequestTypes.Submission
      ).subscribe();
    }
    else if (event.detail.event.target.className.includes('data-download-outcome-document-button')) {
      this.zipService.startDownload([event.detail], this.documentRequestTypes.Submission);
    }
  }

  handleChangePage(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;

    this.postSubmissionDocumentsGrid(this.id, {
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: this.currentSort ? [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }] : null
    });
  }

  handleSorting(event): void {
    this.currentSort = SubmissionSubmittedDocumentSortOptions[event.detail.field];
    this.currentSortDescending = !event.detail.ascending;
    this.currentPage = 1;

    this.postSubmissionDocumentsGrid(this.id, {
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }]
    });
  }

  public postSubmissionDocumentsGrid(id, body): void {
    this.loading = true;
    this.gridFilters = body;
    this.selected = [];

    this.apiService.getSubmissionDocuments(id, body)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        // TODO: confirm if this is due to bad data and will be resolved
        data.records.forEach((doc) => {
          doc.requestType = DocumentRequestTypes.Submission;
        });
        this.apiService.postSubmittedDocumentDownloads(data.records)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((documentData) => {
            if (data.records && data.records.length > 0) {
              this.initialDocuments = data.records;
            }

            this.tableData = this.submissionDetailService.mapSubmittedDocumentsData(data.records, documentData);
            this.documentUrlsFetched = true;
            this.loaded = true;
            this.loading = false;
            this.totalPages = Math.ceil(data.totalRecords / data.pageSize);
            this.totalRecords = data.totalRecords;
            this.currentPage = data.currentPage;
          }, (err) => {
            this.loaded = true;
            this.loading = false;
            this.toastService.add([{
              closable: true,
              id: 'postSubmittedDocumentDownloads',
              message: err,
              variant: 'error'
            }]);
          });
      }, (err) => {
        this.loaded = true;
        this.loading = false;
        this.documentUrlsFetched = true;
        if (err.details.status === ServerErrorCodes.unauthorizedStatusNumber) {
          this.pageUnauthorized = true;
        }
        else {
          this.toastService.add([{
            closable: true,
            id: 'getSubmissionDocuments',
            message: this.translatePipe.transform('serverErrors.internalServerError'),
            variant: 'error'
          }]);
        }
      });
  }
}
