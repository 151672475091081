<div class="wcg-row mt-60">
  <div class="wcg-col-xs-1">
  </div>
  <div class="wcg-col-xs-2">

    <div class="wizard-holder">
      <ul class="steps nav nav-pills nav-justified">
        <li class="nav-item" *ngFor="let step of steps; let i = index"
          [ngClass]="{'active': i === selectedStep, 'passed': i < selectedStep, 'clickable': (i <= selectedStep + 1) && step.isClickable }"
          (click)="allowNextStep(i)">
          <span>{{ step.title }}</span>
        </li>
      </ul>

      <div class="wizard">
        <form #basicForm="ngForm" (ngSubmit)="nextStep()" [formGroup]="basicInfoForm">
          <div class="user-card wcg-rhythm-5" *ngIf="selectedStep === 0">

            <div class="wcg-row">
              <div class="wcg-col-xs-4">
                <wcg-form-field>
                  <wcg-label>{{ 'profile.profileFormPrefix' | translate }}</wcg-label>
                  <wcg-select formControlName="prefix" (change)="prefixChange($event)" ngDefaultControl>
                    <option *ngFor="let prefix of prefixes" [selected]="prefix.key === basicInfoForm.value.prefix"
                      [value]="prefix.key">
                      {{ prefix?.value }}
                    </option>
                  </wcg-select>
                </wcg-form-field>
              </div>
            </div>
            <div class="wcg-row">
              <div class="wcg-col-xs-4">
                <wcg-form-field>
                  <wcg-label>{{ 'profile.profileFormFirstName' | translate }}</wcg-label>
                  <wcg-input formControlName="firstName" maxlength="100" required></wcg-input>
                  <div
                    *ngIf="(basicInfoForm.controls.firstName.invalid && basicForm.submitted) ||
               (basicInfoForm.controls.firstName.invalid && (basicInfoForm.controls.firstName.dirty || basicInfoForm.controls.firstName.touched))">
                    <wcg-error-message *ngIf="basicInfoForm.controls.firstName.errors.required">
                      {{ 'validation.required' | translate: { fieldname: ('profile.profileFormFirstName' | translate) }
                      }}
                    </wcg-error-message>
                  </div>
                </wcg-form-field>
              </div>
            </div>
            <div class="wcg-row">
              <div class="wcg-col-xs-4">
                <wcg-form-field>
                  <wcg-label>{{ 'profile.profileFormMiddleName' | translate }} {{'userRegistration.optionalFieldMarker'
                    | translate}}</wcg-label>
                  <wcg-input formControlName="middleName" maxlength="100"></wcg-input>
                </wcg-form-field>
              </div>
            </div>
            <div class="wcg-row">
              <div class="wcg-col-xs-4">
                <wcg-form-field>
                  <wcg-label>{{ 'profile.profileFormLastName' | translate }}</wcg-label>
                  <wcg-input formControlName="lastName" maxlength="100" required></wcg-input>
                  <div
                    *ngIf="(basicInfoForm.controls.lastName.invalid && basicForm.submitted) ||
                 (basicInfoForm.controls.lastName.invalid && (basicInfoForm.controls.lastName.dirty || basicInfoForm.controls.lastName.touched))">
                    <wcg-error-message *ngIf="basicInfoForm.controls.lastName.errors.required">
                      {{ 'validation.required' | translate: { fieldname: ('profile.profileFormLastName' | translate) }
                      }}
                    </wcg-error-message>
                  </div>
                </wcg-form-field>
              </div>
            </div>
            <div class="wcg-row">
              <div class="wcg-col-xs-4">
                <wcg-form-field>
                  <wcg-label>{{ 'profile.profileFormSuffix' | translate }} {{'userRegistration.optionalFieldMarker' |
                    translate}}</wcg-label>
                  <wcg-select formControlName="suffix" (change)="suffixChange($event)" ngDefaultControl>
                    <option *ngFor="let suffix of suffixes" [selected]="suffix.key === basicInfoForm.value.suffix"
                      [value]="suffix.key">
                      {{ suffix?.value }}
                    </option>
                  </wcg-select>
                </wcg-form-field>
              </div>
            </div>
            <div class="wcg-row">
              <div class="wcg-col-xs-4">
                <wcg-form-field>
                  <wcg-label>{{ 'profile.profileFormPhone' | translate }}</wcg-label>
                  <wcg-input formControlName="phone" maxlength="20" required></wcg-input>
                  <div
                    *ngIf="(basicInfoForm.controls.phone.invalid && basicForm.submitted) ||
                   (basicInfoForm.controls.phone.invalid && (basicInfoForm.controls.phone.dirty || basicInfoForm.controls.phone.touched))">
                    <wcg-error-message *ngIf="basicInfoForm.controls.phone.errors.required">
                      {{ 'validation.required' | translate: { fieldname: ('profile.profileFormPhone' | translate) } }}
                    </wcg-error-message>
                  </div>
                  <div *ngIf="basicInfoForm.controls.phone.value && basicInfoForm.controls.phone.value.length > 0">
                    <label>{{20 - basicInfoForm.controls.phone.value.length}} characters remaining</label>
                  </div>
                </wcg-form-field>
              </div>
            </div>
            <div class="wcg-row">
              <div class="wcg-col-xs-4">
                <wcg-form-field>
                  <wcg-label>{{ 'profile.profileFormDegrees' | translate }} {{'userRegistration.optionalFieldMarker' |
                    translate}}</wcg-label>
                  <wcg-input formControlName="degrees" maxlength="50"></wcg-input>
                </wcg-form-field>
              </div>
            </div>

            <div class="wcg-row wcg-rhythm-1 action">
              <button class="wcg-btn wcg-btn-primary" *ngIf="!isLastStep" (click)="nextStep()">Continue
              </button>
            </div>
          </div>

        </form>

        <form #personalForm="ngForm" (ngSubmit)="nextStep()" [formGroup]="personalInfoForm">
          <div class="user-card wcg-rhythm-5" *ngIf="selectedStep === 1">

            <div class="wcg-row">
              <div class="wcg-col-xs-4">
                <wcg-form-field *ngIf="countries">
                  <wcg-label>{{ 'profile.profileFormCountry' | translate }}</wcg-label>
                  <wcg-select formControlName="country" (change)="handleCountryChange($event)" ngDefaultControl>
                    <option *ngFor="let country of countries" [selected]="country.id == personalInfoForm.value.country"
                      [value]="country?.id" required>
                      {{ country?.name }}
                    </option>
                  </wcg-select>
                  <div
                    *ngIf="(personalInfoForm.controls.country.invalid && personalForm.submitted) ||
                 (personalInfoForm.controls.country.invalid && (personalInfoForm.controls.country.dirty || personalInfoForm.controls.country.touched))">
                    <wcg-error-message *ngIf="personalInfoForm.controls.country.errors.required">
                      {{ 'validation.required' | translate: { fieldname: ('profile.profileFormCountry' | translate) } }}
                    </wcg-error-message>
                  </div>
                </wcg-form-field>
              </div>
            </div>
            <div class="wcg-row">
              <div class="wcg-col-xs-4">
                <wcg-form-field>
                  <wcg-label>{{ 'profile.profileFormAddress1' | translate }}</wcg-label>
                  <wcg-input formControlName="addressLine1" maxlength="255" required></wcg-input>
                  <div
                    *ngIf="(personalInfoForm.controls.addressLine1.invalid && personalForm.submitted) ||
                 (personalInfoForm.controls.addressLine1.invalid && (personalInfoForm.controls.addressLine1.dirty || personalInfoForm.controls.addressLine1.touched))">
                    <wcg-error-message *ngIf="personalInfoForm.controls.addressLine1.errors.required">
                      {{ 'validation.required' | translate: { fieldname: ('profile.profileFormAddress1' | translate) }
                      }}
                    </wcg-error-message>
                  </div>
                </wcg-form-field>
              </div>
            </div>
            <div class="wcg-row">
              <div class="wcg-col-xs-4">
                <wcg-form-field>
                  <wcg-label>{{ 'profile.profileFormAddress2' | translate }} {{'userRegistration.optionalFieldMarker' |
                    translate}}</wcg-label>
                  <wcg-input formControlName="addressLine2" maxlength="255"></wcg-input>
                </wcg-form-field>
              </div>
            </div>
            <div class="wcg-row">
              <div class="wcg-col-xs-4">
                <wcg-form-field>
                  <wcg-label>{{ 'profile.profileFormCity' | translate }}</wcg-label>
                  <wcg-input formControlName="city" maxlength="75" required></wcg-input>
                  <div
                    *ngIf="(personalInfoForm.controls.city.invalid && personalForm.submitted) ||
                 (personalInfoForm.controls.city.invalid && (personalInfoForm.controls.city.dirty || personalInfoForm.controls.city.touched))">
                    <wcg-error-message *ngIf="personalInfoForm.controls.city.errors.required">
                      {{ 'validation.required' | translate: { fieldname: ('profile.profileFormCity' | translate) } }}
                    </wcg-error-message>
                  </div>
                </wcg-form-field>
              </div>
            </div>
            <div class="wcg-row">
              <div class="wcg-col-xs-4">
                <wcg-form-field *ngIf="states?.length">
                  <wcg-label>{{ stateLabel | translate }}</wcg-label>
                  <wcg-select formControlName="state" (change)="stateChange($event)" ngDefaultControl>
                    <option value="-1" selected>{{ 'userRegistration.select' | translate }}</option>
                    <option *ngFor="let state of states" [selected]="state.id == personalInfoForm.value.state"
                      [value]="state.id" required>
                      {{ state?.name }}
                    </option>
                  </wcg-select>
                  <div
                    *ngIf="(personalInfoForm.controls.state.invalid && personalForm.submitted) ||
                 (personalInfoForm.controls.state.invalid && (personalInfoForm.controls.state.dirty || personalInfoForm.controls.state.touched))">
                    <wcg-error-message *ngIf="personalInfoForm.controls.state.errors.required">
                      {{ 'validation.required' | translate: { fieldname: (stateLabel | translate) } }}
                    </wcg-error-message>
                  </div>
                </wcg-form-field>
                <wcg-form-field *ngIf="states?.length === 0">
                  <wcg-label>{{ 'profile.profileFormStateProvince' | translate }}</wcg-label>
                  <wcg-input formControlName="province" maxlength="150" required></wcg-input>
                  <div
                    *ngIf="(personalInfoForm.controls.province.invalid && personalForm.submitted) ||
                 (personalInfoForm.controls.province.invalid && (personalInfoForm.controls.province.dirty || personalInfoForm.controls.province.touched))">
                    <wcg-error-message *ngIf="personalInfoForm.controls.province.invalid">
                      {{ 'validation.required' | translate: { fieldname: ('profile.profileFormStateProvince' |
                      translate) } }}
                    </wcg-error-message>
                  </div>
                </wcg-form-field>
              </div>
            </div>
            <div class="wcg-row">
              <div class="wcg-col-xs-4">
                <wcg-form-field>
                  <wcg-label>{{ 'profile.profileFormPostalCode' | translate }}</wcg-label>
                  <wcg-input formControlName="postalCode" maxlength="15" required></wcg-input>
                  <div
                    *ngIf="(personalInfoForm.controls.postalCode.invalid && personalForm.submitted) ||
                 (personalInfoForm.controls.postalCode.invalid && (personalInfoForm.controls.postalCode.dirty || personalInfoForm.controls.postalCode.touched))">
                    <wcg-error-message *ngIf="personalInfoForm.controls.postalCode.errors.required">
                      {{ 'validation.required' | translate: { fieldname: ('profile.profileFormPostalCode' | translate) }
                      }}
                    </wcg-error-message>
                  </div>
                </wcg-form-field>
              </div>
            </div>

            <div class="wcg-row wcg-rhythm-1 action">
              <button class="wcg-btn wcg-btn-primary" *ngIf="!isLastStep" (click)="nextStep()">Continue
              </button>
            </div>
          </div>

        </form>

        <form #companyForm="ngForm" (ngSubmit)="onSubmit()" [formGroup]="companyInfoForm">
          <div class="user-card wcg-rhythm-5" *ngIf="selectedStep === 2">

            <div class="wcg-row">
              <div class="wcg-col-xs-4">
                <wcg-form-field>
                  <wcg-label>{{ 'userRegistration.company' | translate }}</wcg-label>
                  <wcg-input formControlName="company" maxlength="255" required></wcg-input>
                  <div
                    *ngIf="(companyInfoForm.controls.company.invalid && companyForm.submitted) ||
                 (companyInfoForm.controls.company.invalid && (companyInfoForm.controls.company.dirty || companyInfoForm.controls.company.touched))">
                    <wcg-error-message *ngIf="companyInfoForm.controls.company.errors.required">
                      {{ 'validation.required' | translate: { fieldname: ('userRegistration.company' | translate) } }}
                    </wcg-error-message>
                  </div>
                </wcg-form-field>
              </div>
            </div>
            <div class="wcg-row">
              <div class="wcg-col-xs-4">
                <wcg-form-field>
                  <wcg-label>{{ 'userRegistration.organizationLabel' | translate }}</wcg-label>
                  <wcg-select formControlName="organization" (change)="organizationChange($event)" ngDefaultControl>
                    <option value="-1" selected>{{ 'userRegistration.select' | translate }}</option>
                    <option *ngFor="let type of organizationTypes" value={{type.value}}>
                      {{type.value}}</option>
                  </wcg-select>
                  <div
                    *ngIf="(companyInfoForm.controls.organization.invalid && companyForm.submitted) ||
                 (companyInfoForm.controls.organization.invalid && (companyInfoForm.controls.organization.dirty || companyInfoForm.controls.organization.touched))">
                    <wcg-error-message *ngIf="companyInfoForm.controls.organization.errors.required">
                      {{ 'validation.required' | translate: { fieldname: ('userRegistration.organizationType' |
                      translate) } }}
                    </wcg-error-message>
                  </div>
                </wcg-form-field>
              </div>
            </div>
            <div class="wcg-row">
              <div class="wcg-col-xs-4">
                <wcg-form-field>
                  <wcg-label>{{ 'userRegistration.companyPhone' | translate }} {{'userRegistration.optionalFieldMarker'
                    | translate}}</wcg-label>
                  <wcg-input formControlName="phoneAdditional" maxlength="25"></wcg-input>
                </wcg-form-field>
              </div>
            </div>

            <div class="wcg-row wcg-rhythm-1 action">
              <button class="wcg-btn wcg-btn-primary wcg-btn-auto" *ngIf="isLastStep"
                [disabled]="basicForm.invalid || personalInfoForm.invalid || companyForm.invalid"
                (click)="onSubmit()">Complete Registration
              </button>
            </div>

          </div>
        </form>

      </div>
    </div>
  </div>
  <div class="wcg-col-xs-1">
  </div>
</div>