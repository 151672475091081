import { Routes } from '@angular/router';
import { DashboardComponent } from '../views/dashboard/dashboard.component';
import { AuthGuard } from '../guards/auth.guard';
import { DashboardNeedsActionComponent } from '../views/dashboard/dashboard-needs-action/dashboard-needs-action.component';
import { DashboardDraftsComponent } from '../views/dashboard/dashboard-drafts/dashboard-drafts.component';
import { DashboardInProgressComponent } from '../views/dashboard/dashboard-in-progress/dashboard-in-progress.component';
import { OutcomeDocumentsDownloadComponent } from '../components/outcome-documents-download/outcome-documents-download.component';

export const dashboardRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [
      AuthGuard
    ],
    children: [
      {
        path: '',
        redirectTo: 'needs-action',
        pathMatch: 'full'
      },
      {
        path: 'needs-action',
        component: DashboardNeedsActionComponent
      },
      {
        path: 'drafts',
        component: DashboardDraftsComponent
      },
      {
        path: 'in-progress',
        component: DashboardInProgressComponent
      }
    ]
  },
  {
    path: 'dashboard/:id',
    component: OutcomeDocumentsDownloadComponent,
    canActivate: [
      AuthGuard
    ]
  }
];
