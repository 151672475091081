<div class="wcg-container cnxs-loader-container">
  <div *ngIf="(documentsFetched && initialDocuments?.length)">
    <form ngForm class="wcg-row wcg-filter-row">
      <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
        <ng2-flatpickr class="wcg-input-with-icon" #startDatePicker placeholder="Start Date"
          [config]="datePickerOptions" [formControl]="startDateFilter">
          <wcg-icon class="date-icon" (click)="toggleStartDate()" src="./assets/icons/action/ic_date_range_24px.svg">
          </wcg-icon>
        </ng2-flatpickr>
      </div>
      <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
        <ng2-flatpickr class="wcg-input-with-icon" #endDatePicker placeholder="End Date" [config]="datePickerOptions"
          [formControl]="endDateFilter">
          <wcg-icon class="date-icon" (click)="toggleEndDate()" src="./assets/icons/action/ic_date_range_24px.svg">
          </wcg-icon>
        </ng2-flatpickr>
      </div>
      <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-4">
        <wcg-form-field>
          <wcg-label>{{ 'actions.search' | translate }}</wcg-label>
          <wcg-input [formControl]="searchTerm">
            <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
          </wcg-input>
        </wcg-form-field>
      </div>
    </form>

    <div class="wcg-row">
      <div class="wcg-col-xs-4 wcg-col-md-12 wcg-col-lg-12 wcg-rhythm-2">
        <div class="export-button-wrap">
          <app-export-action-button [gridFilters]="gridFilters" [disableButton]="!tableData?.length"
            [exportType]="exportType" [entityId]="id"></app-export-action-button>
        </div>
      </div>
    </div>

    <wcg-table class="wcg-rhythm-4" [ngClass]="{'cnxs-table-loading': loading}" [headers]="headers" [data]="tableData"
      [options]="options" (rowClicked)="handleRowClicked($event)" (sortClicked)="handleSorting($event)" (rowSelected)="handleRowSelection($event)">

      <div class="cnxs-table-loader" slot="table-body">
        <wcg-loader [show]="!loaded || loading"></wcg-loader>
      </div>

      <div *ngIf="loaded" class="cnxs-pagination-wrap wcg-table-footer wcg-rhythm-2">
        <div class="wcg-total-results">
          {{ 'tables.totalResults' | translate}}: {{totalRecords}}
        </div>
        <div class="pagination">
          <wcg-pagination *ngIf="tableData?.length"
            [current]="currentPage" [pageSize]="currentPageSize"
            [max]="totalPages" (changePage)="handleChangePage($event)">
          </wcg-pagination>
        </div>
      </div>
    </wcg-table>
    <div class="wcg-row wcg-rhythm-2">
      <div class="wcg-col-md-2">
        <button class="wcg-btn wcg-btn-fluid"
          [disabled]="tableData?.length === 0 || selected?.length > 0"
          (click)="downloadAllSubmissionsDocuments()">
          {{ 'actions.downloadAll' | translate }}
          <wcg-loader [show]="downloadingAll"></wcg-loader>
        </button>
      </div>
      <div class="wcg-col-md-2">
        <button class="wcg-btn wcg-btn-fluid"
          [disabled]="selected?.length === 0"
          (click)="downloadSelectedSubmissionsDocuments()">
          {{ 'actions.downloadSelected' | translate }}
          <wcg-loader [show]="downloadingSelected"></wcg-loader>
        </button>
      </div>
      <div class="wcg-col-md-2"></div>
    </div>
  </div>
  <wcg-loader [show]="!documentsFetched"></wcg-loader>
  <app-no-records *ngIf="documentsFetched && !initialDocuments?.length">
    <p class="wcg-h2">{{ 'siteDetails.noSubmissions' | translate }}</p>
  </app-no-records>
</div>