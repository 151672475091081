import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TableHeader } from 'src/app/interfaces/components';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { ToastService } from 'src/app/services/toast.service';
import { UtilService } from 'src/app/services/util.service';
import { FilterOptionModel } from '../../shared/model/grid.model';
import { UserAuditGrid } from '../../shared/model/userinfo';
import { UserAdminService } from '../../shared/services/user-admin.service';
import { RegexPatterns } from 'src/app/admin/shared/helpers/regexPatterns';
import { GridExportType } from 'src/app/common/reportingCollections';

@Component({
  selector: 'app-audit-access',
  templateUrl: './audit-access.component.html',
  styleUrls: ['./audit-access.component.scss']
})

export class AuditAccessComponent implements OnInit {
  headers: Array<TableHeader>;
  tableData: Array<any>;
  auditForm: FormGroup;
  loading: boolean;
  currentPage = 1;
  currentPageSize = 50;
  totalRecords: number;
  showTable: boolean;
  id: string;
  options = {
    manualPaginationAndSort: true
  };

  readonly exportType: GridExportType = GridExportType.UserAccessAudit;
  gridFilters: any;
  constructor(public utilService: UtilService,
    private translatePipe: TranslatePipe,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private userService: UserAdminService) { }

  ngOnInit(): void {
    this.showTable = false;
    this.headers = this.getHeaders();
    this.auditForm = this.formBuilder.group({
      irbTracking: [null, Validators.required],
      emailAddress: [null, Validators.compose([
        Validators.required,
        Validators.pattern(RegexPatterns.emailPattern)
      ])]
    });
    this.auditForm.controls.emailAddress.valueChanges.subscribe(() => {
      this.showTable = false;
      this.tableData = [];
    });

    this.auditForm.controls.irbTracking.valueChanges.subscribe(() => {
      this.showTable = false;
      this.tableData = [];
    });
  }

  public getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('audit.entityId'), 'entityId', false),
      this.utilService.createTableHeader(this.translatePipe.transform('audit.siteName'), 'siteName', false),
      this.utilService.createTableHeader(this.translatePipe.transform('audit.accessLevel'), 'accessLevel', false),
      this.utilService.createTableHeader(this.translatePipe.transform('audit.auditAction'), 'auditAction', false),
      this.utilService.createTableHeader(this.translatePipe.transform('audit.modifiedBy'), 'modifiedBy', false),
      this.utilService.createTableHeader(this.translatePipe.transform('audit.modifiedDate'), 'modifiedDate', false),
      this.utilService.createTableHeader(this.translatePipe.transform('audit.downloadDocs'), 'downloadDocs', false)
    ];
  }

  public run(): void {
    this.loading = true;
    this.showTable = true;
    this.postAuditGrid({
      page: 1,
      pageSize: this.currentPageSize,
      filterOptions: this.getCurrentFilters()
    });
  }

  public handlePageChange(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;

    this.postAuditGrid({
      page: this.currentPage,
      pageSize: this.currentPageSize,
      filterOptions: this.getCurrentFilters()
    });
  }

  private getCurrentFilters(): Array<FilterOptionModel> {
    const filters: Array<FilterOptionModel> = [];
    const emailAddressValues: Array<string> = [];
    emailAddressValues.push(this.auditForm.controls.emailAddress.value);
    filters.push({
      field: 'UserEmail',
      operator: 'Equals',
      values: emailAddressValues
    });

    return filters;
  }

  private postAuditGrid(body): void {
    this.loading = true;
    this.gridFilters = body;
    this.id = this.auditForm.controls.irbTracking.value;
    this.userService.getUserAuditData(this.id, body)
      .subscribe((data: UserAuditGrid) => {
        this.loading = false;
        this.totalRecords = data.totalRecords;
        this.tableData = this.userService.mapUserAuditGridData(data.records);
        this.currentPage = data.currentPage;
      }, (err) => {
        this.loading = false;
        this.toastService.add([{
          closable: true,
          id: 'getAudit',
          message: err.error.message,
          variant: 'error'
        }]);
      });
  }
}
