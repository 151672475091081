/* eslint-disable max-lines, complexity*/
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { timer } from 'rxjs';
import { debounce, takeUntil } from 'rxjs/operators';
import {
  CITY_LENGTH, COMPANYID_MAX_LIMIT, DEBOUNCE_TIME, emailRegex, EMAIL_LENGTH, FilterOperator, flatPickrDateFormat, MAX_LENGTH,
  POSTAL_LENGTH, PROVINCE_LENGTH, regexRules, RegexTags, TeamFilterOptions, TeamSortOptions
} from 'src/app/common/collections';
import { BaseComponent } from 'src/app/components/base/base.component';
import { TableHeader } from 'src/app/interfaces/components';
import { ContactCountries, ContactStates } from 'src/app/interfaces/user';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { ToastService } from 'src/app/services/toast.service';
import { UtilService } from 'src/app/services/util.service';
import { FilterOptionModel, SortOptionModel } from '../../shared/model/grid.model';
import { IdValueModel } from '../../shared/model/sharedmodels';
import { TeamOrganizationModel } from '../../shared/model/team-organization';
import { TeamsGrid } from '../../shared/model/teamInfo';
import { TeamAdminService } from '../../shared/services/team-admin.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { numericValidator } from 'src/app/validators/numeric.validator';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent extends BaseComponent implements OnInit {
  showAddTeamModal = false;
  createTeamForm: FormGroup;
  teamOrganizationTypes: IdValueModel[] = [];
  countries: ContactCountries = [];
  states: ContactStates = [];
  stateLabel: string;
  allUsers: any = [];
  saveTeamsFailure = false;
  saveTeamsFailureMessage: string;
  loaded = false;
  loading = true;

  currentPage = 1;
  currentPageSize = 10;
  totalRecords: number;
  tableData: Array<any>;
  headers: Array<TableHeader>;

  @ViewChild('startDatePicker') startDatePicker;
  @ViewChild('endDatePicker') endDatePicker;

  startDateFilter = new FormControl('');
  endDateFilter = new FormControl('');

  startDateUTC: any = null;
  endDateUTC: any = null;

  datePickerOptions: FlatpickrOptions = {
    dateFormat: flatPickrDateFormat
  };

  sortOptionsDefaultSort: Array<SortOptionModel> = [
    {
      field: TeamSortOptions.status,
      isDescending: true
    },
    {
      field: TeamSortOptions.name,
      isDescending: false
    }
  ];

  currentSort: Array<SortOptionModel>;

  statusFilter: FilterOptionModel;
  typeFilter: FilterOptionModel;

  options = {
    manualPaginationAndSort: true,
    rowsPerPage: 10
  };

  searchTerm = new FormControl('');
  teamStatus = new FormControl('');
  teamType = new FormControl('');
  teamStatusFilter: any[] = [];
  filterTeamForm: FormGroup;
  isSaving = false;

  constructor(private formBuilder: FormBuilder,
    private teamAdminService: TeamAdminService,
    public utilService: UtilService,
    private toastService: ToastService,
    private router: Router,
    private apiService: ApiService,
    private translatePipe: TranslatePipe) {
    super();
  }

  ngOnInit(): void {
    this.initializeForm();

    this.filterTeamForm = this.formBuilder.group({
      teamTypeFilter: new FormControl()
    });

    this.teamAdminService.getTeamOrganizationTypes().subscribe((result) => {
      this.teamOrganizationTypes = result;
    });

    this.teamAdminService.endDate.next(null);
    this.teamAdminService.startDate.next(null);
    this.teamAdminService.searchTerm.next('');
    this.typeFilter = null;
    this.statusFilter = null;

    this.headers = this.getHeaders();
    this.teamStatusFilter = [
      {
        text: 'Active',
        value: true
      },
      {
        text: 'Inactive',
        value: false
      }
    ];
    this.startDateFilterOptions();
    this.endDateFilterOptions();
    this.currentSort = this.sortOptionsDefaultSort;
    this.postTeamGrid({
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: '',
      sortOptions: this.currentSort
    });

    this.searchTerm.valueChanges
      .pipe(
        debounce(() => timer(DEBOUNCE_TIME))
      )
      .subscribe((val) => {
        this.teamAdminService.searchTerm.next(val);
        this.postTeamGrid({
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: val,
          sortOptions: this.currentSort,
          filterOptions: this.getCurrentFilters(),
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });
      });
  }

  public getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('teams.teamName'), 'name', true),
      this.utilService.createTableHeader(this.translatePipe.transform('teams.teamType'), 'type', true),
      this.utilService.createTableHeader(this.translatePipe.transform('teams.teamEmail'), 'email', true),
      this.utilService.createTableHeader(this.translatePipe.transform('teams.primaryUser'), 'primaryUser', true),
      this.utilService.createTableHeader(this.translatePipe.transform('teams.studies'), 'studies', true),
      this.utilService.createTableHeader(this.translatePipe.transform('teams.sites'), 'sites', true),
      this.utilService.createTableHeader(this.translatePipe.transform('teams.members'), 'teamMembers', true),
      this.utilService.createTableHeader(this.translatePipe.transform('teams.status'), 'status', true),
      this.utilService.createTableHeader(this.translatePipe.transform('teams.companyId'), 'companyId', true),
      this.utilService.createTableHeader(this.translatePipe.transform('teams.created'), 'createdDate', true),
      this.utilService.createTableHeader(this.translatePipe.transform('teams.createdBy'),
        'createdBy', true, 'date', this.utilService.dateFormat)
    ];
  }

  public statusChange(event: Event): void {
    event.preventDefault();

    this.postTeamGrid({
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: this.currentSort,
      filterOptions: this.getCurrentFilters(),
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  public teamTypeFilterChange(event: Event): void {
    event.preventDefault();

    this.postTeamGrid({
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: this.currentSort,
      filterOptions: this.getCurrentFilters(),
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  toggleEndDate(): void {
    this.endDatePicker.flatpickr.toggle();
  }

  toggleStartDate(): void {
    this.startDatePicker.flatpickr.toggle();
  }

  startDateFilterOptions(): void {
    this.startDateFilter.valueChanges
      .subscribe((val) => {
        this.jumpStartDates(val[0]);
        [this.endDatePicker.flatpickr.config.minDate] = val;

        this.teamAdminService.startDate.next(val[0]);
        if (val[0]) {
          this.startDateUTC = moment(val[0]).utc(false);
        }

        this.postTeamGrid({
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          sortOptions: this.currentSort,
          filterOptions: this.getCurrentFilters(),
          startDate: val[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });
      });
  }

  endDateFilterOptions(): void {
    this.endDateFilter.valueChanges
      .subscribe((val) => {
        this.jumpEndDates(val[0]);
        [this.startDatePicker.flatpickr.config.maxDate] = val;

        this.teamAdminService.endDate.next(val[0]);
        if (val[0]) {
          this.endDateUTC = moment(val[0]).utc(false);
        }

        this.postTeamGrid({
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          sortOptions: this.currentSort,
          filterOptions: this.getCurrentFilters(),
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: val[0] ? this.endDateUTC : null
        });
      });
  }

  jumpStartDates(value: any): void {
    if (value) {
      if (!this.endDateFilter.value[0]) {
        this.endDatePicker.flatpickr.jumpToDate(value);
      }
    }
    else if (this.endDateFilter.value[0]) {
      this.startDatePicker.flatpickr.jumpToDate(this.endDateFilter.value[0]);
    }
    else {
      this.endDatePicker.flatpickr.jumpToDate(new Date());
    }
  }

  jumpEndDates(value: any): void {
    if (value) {
      if (!this.startDateFilter.value[0]) {
        this.startDatePicker.flatpickr.jumpToDate(value);
      }
    }
    else if (this.startDateFilter.value[0]) {
      this.endDatePicker.flatpickr.jumpToDate(this.startDateFilter.value[0]);
    }
    else {
      this.startDatePicker.flatpickr.jumpToDate(new Date());
    }
  }

  handlePageChange(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;

    this.postTeamGrid({
      page: event.detail.page,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: this.currentSort,
      filterOptions: this.getCurrentFilters(),
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  handleSort(event): void {
    this.currentSort = [
      {
        field: TeamSortOptions[event.detail.field],
        isDescending: !event.detail.ascending
      }
    ];

    this.postTeamGrid({
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: this.currentSort,
      filterOptions: this.getCurrentFilters(),
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  public initializeForm(): void {
    const rules = regexRules
      .filter((rule) => rule.tag === RegexTags.AlphaNumericRegex || rule.tag === RegexTags.NumericRegex);

    const [alphaNumericRegexRule] = rules;

    this.createTeamForm = this.formBuilder.group({
      teamName: new FormControl(null, this.addValidators(MAX_LENGTH, alphaNumericRegexRule.rule, true)),
      teamEmail: new FormControl(null, this.addValidators(MAX_LENGTH, emailRegex, true)),
      companyId: new FormControl(null, [numericValidator(COMPANYID_MAX_LIMIT)]),
      teamType: new FormControl(null, Validators.min(0)),
      country: new FormControl(null, Validators.min(0)),
      state: ['', Validators.required],

      province: new FormControl(null, this.addValidators(PROVINCE_LENGTH, alphaNumericRegexRule.rule, true)),
      addressLine1: new FormControl(null, this.addValidators(MAX_LENGTH, alphaNumericRegexRule.rule, true)),
      addressLine2: new FormControl(null, this.addValidators(MAX_LENGTH, alphaNumericRegexRule.rule, false)),
      city: new FormControl(null, this.addValidators(CITY_LENGTH, alphaNumericRegexRule.rule, true)),
      postalCode: new FormControl(null, this.addValidators(POSTAL_LENGTH, alphaNumericRegexRule.rule, true)),
      selectedUsers: new FormControl({
        value: '',
        selectedValues: []
      }, [
        (control): any => {
          if (control.value?.selectedValues?.length > 0) {
            return;
          }

          return {
            validateSelectedUser: {
              valid: false
            }
          };
        }
      ])
    });
  }

  public addTeam(): void {
    this.initializeForm();
    this.closeErrorMessage();
    this.resetForm();
    this.populateForm();
    this.showAddTeamModal = true;

    this.createTeamForm.controls.selectedUsers.valueChanges.pipe(
      debounce(() => timer(DEBOUNCE_TIME))
    ).subscribe((val) => {
      if (val?.value?.length > 2) {
        // email column length in db is 450, trim if above
        const searchValue = val.value.substring(0, EMAIL_LENGTH);
        this.getUsers(searchValue);
      }
    });

    this.createTeamForm.controls.country.valueChanges.subscribe((value) => {
      this.updateStates(parseInt(value, this.currentPageSize), true);
    });
  }

  public closeClick(): void {
    this.closeErrorMessage();
    this.resetForm();
    this.createTeamForm.controls.selectedUsers = new FormControl();
    this.states = [];
    const form = document.getElementsByName('createTeamForm')[0] as HTMLFormElement;
    form.reset();
    this.showAddTeamModal = false;
  }

  public closeErrorMessage(): void {
    this.saveTeamsFailure = false;
    this.saveTeamsFailureMessage = '';
  }

  public saveTeam(): void {
    this.closeErrorMessage();
    if (this.createTeamForm.valid) {
      this.isSaving = true;
      const email = this.createTeamForm.controls.teamEmail.value;
      this.teamAdminService.IsValidTeamOrganizationEmail(email.trim()).subscribe((res) => {
        if (res === true) {
          const orgModel = this.mapTeamOrganizationToCreateModel();
          this.teamAdminService.saveTeamInfo(orgModel).subscribe((teamId) => {
            this.isSaving = false;
            this.router.navigate(['teams', teamId]);
          }, (err) => {
            this.saveTeamsFailureMessage = err?.error?.message;
            this.saveTeamsFailure = true;
            this.isSaving = false;
          });
        }
        else {
          this.saveTeamsFailureMessage = `${this.translatePipe.transform('teams.emailExists', { email: email })}`;
          this.saveTeamsFailure = true;
          this.isSaving = false;
        }
      }, (err) => {
        this.saveTeamsFailureMessage = err?.error?.message;
        this.saveTeamsFailure = true;
        this.isSaving = false;
      });
    }
  }

  public populateForm(): void {
    this.teamAdminService.getTeamOrganizationTypes().subscribe((result) => {
      this.teamOrganizationTypes = result;
    });

    this.teamAdminService.getCountries().subscribe((res) => {
      this.countries = res;
    });
  }

  public resetForm(): void {
    this.allUsers = [];
    this.createTeamForm.controls.selectedUsers.setValue({
      value: '',
      selectedValues: []
    });
    this.createTeamForm.controls.teamName.setValue(null);
    this.createTeamForm.controls.teamEmail.setValue(null);
    this.createTeamForm.controls.teamType.setValue(-1);
    this.createTeamForm.controls.country.setValue(-1);
    this.createTeamForm.controls.addressLine1.setValue(null);
    this.createTeamForm.controls.addressLine2.setValue(null);
    this.createTeamForm.controls.city.setValue(null);
    this.createTeamForm.controls.state.setValue('');
    this.createTeamForm.controls.postalCode.setValue(null);
    this.createTeamForm.controls.province.setValue(null);
  }

  public handleTeamTypeChange(event): void {
    this.createTeamForm.controls.teamType.setValue(event.target.value);
  }


  public handleCountryChange(event): void {
    this.createTeamForm.patchValue({ country: event.target.value });
    this.updateStates(parseInt(event.target.value, this.currentPageSize), false);
  }

  public stateChange(event): void {
    if (this.createTeamForm.controls.state.value === 'Select') {
      this.createTeamForm.patchValue({ province: ' ' });
      this.createTeamForm.patchValue({ state: '' });
    }
    else {
      this.createTeamForm.patchValue({ state: event.target.value });
    }
  }

  public updateStates(value: number, change?: boolean): void {
    if (this.countries && value > 0) {
      const usa = this.countries.find((country) => country.alpha2Code === 'US');
      const canada = this.countries.find((country) => country.alpha2Code === 'CA');
      const found = [usa.id, canada.id].find((country) => country === value);

      this.stateLabel = value === usa.id ? 'teams.teamState' : 'teams.teamProvince';
      this.states = [];
      if (found) {
        this.getStates(value);
        // String with a N|A to pass required field validation
        this.createTeamForm.patchValue({ province: 'N|A' });
        if (change) {
          this.createTeamForm.patchValue({ state: '' });
        }
      }
      else {
        if (change) {
          this.updateProvinceValidation();
          this.createTeamForm.patchValue({ state: ' ' });
        }
        this.states = [];
      }
    }
  }

  public getCountries(): void {
    this.teamAdminService.getCountries()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.countries = data;
        this.updateStates(this.createTeamForm.value.country, false);
      });
  }

  public getStates(countryId: number): void {
    this.teamAdminService.getStates(countryId.toString())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.states = data;
      });
  }

  public updateUser(event): void {
    if (event.detail.option) {
      const orgType = this.allUsers.filter((org) => org.value === event.detail.value);
      if (orgType.length > 0) {
        this.createTeamForm.controls.selectedUsers.setValue({
          value: '',
          selectedValues: [new Option(orgType[0].value, orgType[0].option)]
        });
      }
    }
  }

  public noWhitespaceValidator = (control: FormControl): any => {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;

    return isValid ? null : { whitespace: true };
  }

  private getUsers(filter): void {
    this.apiService.getPrimaryUsers(filter, null, false, true)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.allUsers = this.teamAdminService.mapUsers(data);
      });
  }

  private mapTeamOrganizationToCreateModel(): TeamOrganizationModel {
    const data = this.createTeamForm.value;
    const teamOrganization = new TeamOrganizationModel();
    const country = this.countries?.find((item) => item.id === parseInt(data.country, this.currentPageSize));
    const state = this.states?.find((item) => item.id === parseInt(data.state, this.currentPageSize));
    const pageSize = this.currentPageSize;
    const organizationType = this.teamOrganizationTypes?.find((item) => parseInt(item.id, pageSize) === parseInt(data.teamType, pageSize));

    teamOrganization.name = data.teamName;
    teamOrganization.email = data.teamEmail;
    teamOrganization.companyId = data.companyId ?? null;
    teamOrganization.organizationType = organizationType;

    teamOrganization.address = {
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      postalCode: data.postalCode,
      country: country,
      state: state,
      province: data.province === 'N|A' ? null : data.province,
      isPrimary: true
    };

    teamOrganization.primaryTeamUserEmail = data.selectedUsers.selectedValues[0].innerText;

    return teamOrganization;
  }

  private addValidators(length: number, pattern: any, required: boolean): any {
    if (required) {
      return Validators.compose([
        Validators.required,
        Validators.maxLength(length),
        Validators.pattern(pattern),
        this.noWhitespaceValidator
      ]);
    }

    return Validators.compose([
      Validators.maxLength(length),
      Validators.pattern(pattern)
    ]);
  }

  private postTeamGrid(gridFilterOption): void {
    this.loaded = false;
    this.loading = true;
    this.teamAdminService.getfilteredTeams(gridFilterOption)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: TeamsGrid) => {
        this.loaded = true;
        this.loading = false;
        this.totalRecords = data.totalRecords;

        this.tableData = this.teamAdminService.mapTeamData(data.records);
        this.currentPage = data.currentPage;
      }, () => {
        this.loaded = true;
        this.loading = false;

        this.toastService.add([{
          closable: true,
          id: 'getTeams',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          variant: 'error'
        }]);
      });
  }

  private getCurrentFilters(): Array<FilterOptionModel> {
    const filters: Array<FilterOptionModel> = [];

    const teamType = this.filterTeamForm.controls.teamTypeFilter.value;
    if (teamType === null || teamType === 'All' || teamType === '') {
      this.typeFilter = null;
    }
    else {
      this.typeFilter = {
        field: TeamFilterOptions.type,
        values: [teamType],
        operator: FilterOperator.equals
      };
      filters.push(this.typeFilter);
    }

    const teamStatus = this.teamStatus.value;
    if (teamStatus === null || teamStatus === 'All' || teamStatus === '') {
      this.statusFilter = null;
    }
    else {
      this.statusFilter = {
        field: TeamFilterOptions.status,
        values: [teamStatus],
        operator: FilterOperator.equals
      };
      filters.push(this.statusFilter);
    }

    if (this.startDateFilter.value[0] || this.endDateFilter.value[0]) {
      filters.push({
        field: TeamFilterOptions.createdDate,
        operator: FilterOperator.equals,
        values: []
      });
    }

    return filters.length > 0 ? filters : null;
  }

  private updateProvinceValidation(): void {
    if (!this.createTeamForm.controls.province.value) {
      this.createTeamForm.patchValue({ province: '' });
    }
    if (this.createTeamForm.controls.province.value === 'N|A') {
      this.createTeamForm.patchValue({ province: '' });
    }
  }
}
