import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../components/base/base.component';
import { DashboardService } from '../dashboard.service';
import { SubmissionInfo } from '../../../interfaces/submissions';
import { SubmissionsDashboardPost } from '../../../interfaces/dashboard';
import { StateService } from '../../../services/state.service';
import { BarChartOption } from '../../../interfaces/components';
import * as cssVars from '../../../../assets/scss-variables.json';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { SubmissionsDashboardStatistic } from '../../../common/collections';
import { UtilService } from 'src/app/services/util.service';
import { PageSizeOptions } from 'src/app/models/page-size-options.model';
import { PaginationValues } from 'src/app/models/pagination-values.mode';

@Component({
  selector: 'app-dashboard-needs-action',
  templateUrl: './dashboard-needs-action.component.html',
  styleUrls: ['./dashboard-needs-action.component.scss']
})

export class DashboardNeedsActionComponent extends BaseComponent implements OnInit {
  public barChartData: Array<BarChartOption>;
  public needsActionData: SubmissionsDashboardPost;
  public records: Array<SubmissionInfo> = [];
  pageSizeOptions: PageSizeOptions;
  viewTypeCard: boolean;

  constructor(
    public dashboardService: DashboardService,
    public stateService: StateService,
    public utilService: UtilService,
    private translatePipe: TranslatePipe
  ) {
    super();
    this.pageSizeOptions = this.dashboardService.GetPaginationConfiguration(this.dashboardService.needsActionPagination);
    this.viewTypeCard = this.dashboardService.cardView.getValue();
  }

  ngOnInit(): void {
    this.dashboardService.needsAction.subscribe((val) => {
      if (val) {
        this.needsActionData = val;
        this.records = this.dashboardService.mapDashboardRecords(val?.submissions?.records, 'needsAction');
        this.barChartData = this.getBarChartData(val);
        this.pageSizeOptions.page = val?.submissions?.currentPage;
        this.pageSizeOptions.pageSize = val?.submissions?.pageSize;
      }
    });

    this.dashboardService.viewListCardTypeUpdated.subscribe((val) => {
      if (val === null) {
        return;
      }

      if (this.viewTypeCard === this.dashboardService.cardView.getValue()) {
        return;
      }

      this.viewTypeCard = this.dashboardService.cardView.getValue();
      this.dashboardService.needsActionPagination =
        new PaginationValues(1, this.dashboardService.paginationDataConfiguration.pageSize);
      this.pageSizeOptions.page = 1;
      this.pageSizeOptions.pageSize = this.dashboardService.paginationDataConfiguration.pageSize;
      this.pageSizeOptions.pageSizeDataSource = this.dashboardService.paginationDataConfiguration.pageSizeDataSource;
    });
  }

  public filter(event): void {
    if (event.id === 'all') {
      this.dashboardService.statusFilterNeedsAction.next(null);
      this.setPaginationAndLoad(1, this.dashboardService.paginationDataConfiguration.pageSize);
    }
    else if (event.id !== this.dashboardService.statusFilterNeedsAction) {
      this.dashboardService.statusFilterNeedsAction.next(event.id);
      this.setPaginationAndLoad(1, this.dashboardService.paginationDataConfiguration.pageSize);
    }
  }

  public maxPages = (data): number => Math.ceil(data.totalRecords / data.pageSize);

  public handlePageChange(event): void {
    this.setPaginationAndLoad(event.detail.page, event.detail.pageSize);
  }

  private setPaginationAndLoad(page, pageSize): void{
    this.dashboardService.needsActionPagination =
      new PaginationValues(page, pageSize);
    this.pageSizeOptions.page = page;
    this.pageSizeOptions.pageSize = pageSize;
    this.dashboardService.postNeedsAction();
  }

  private getBarChartData(data: SubmissionsDashboardPost): Array<BarChartOption> {
    // eslint-disable-next-line max-len
    const fullyApproved = data.statistic[this.utilService.ignoreCaseComparison(data.statistic, SubmissionsDashboardStatistic.fullyApproved)] || 0;
    // eslint-disable-next-line max-len
    const noActionNeeded = data.statistic[this.utilService.ignoreCaseComparison(data.statistic, SubmissionsDashboardStatistic.noActionNeeded)] || 0;

    return [
      {
        id: 'all',
        title: this.translatePipe.transform('dashboard.all')
      },
      {
        id: SubmissionsDashboardStatistic.actionRequired,
        title: this.translatePipe.transform('dashboard.actionRequired'),
        value: data.statistic[this.utilService.ignoreCaseComparison(data.statistic, SubmissionsDashboardStatistic.actionRequired)] || 0,
        color: cssVars['$orange-500'],
        iconColor: cssVars.$white,
        iconBgColor: cssVars['$orange-500'],
        iconHeight: '26px',
        iconWidth: '26px',
        icon: './assets/icons/alert/ic_warning_24px.svg'
      },
      {
        id: SubmissionsDashboardStatistic.notFullyApproved,
        title: this.translatePipe.transform('dashboard.notFullyApproved'),
        value: data.statistic[this.utilService.ignoreCaseComparison(data.statistic, SubmissionsDashboardStatistic.notFullyApproved)] || 0,
        color: cssVars['$red-500'],
        iconColor: cssVars.$white,
        iconBgColor: cssVars['$red-500'],
        iconHeight: '32px',
        iconWidth: '32px',
        icon: './assets/icons/content/ic_report_24px.svg'

      },
      {
        id: SubmissionsDashboardStatistic.outcomeComplete,
        title: this.translatePipe.transform('dashboard.fullyApproved'),
        value: fullyApproved + noActionNeeded || 0,
        iconColor: cssVars.$white,
        iconBgColor: cssVars['$sea-foam-500'],
        iconHeight: '30px',
        iconWidth: '30px',
        icon: './assets/icons/content/ic_save_alt_24px.svg'
      }
    ];
  }
}
