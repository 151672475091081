import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { BaseComponent } from '../../../components/base/base.component';
import { SubmissionFindStudy } from '../../../interfaces/submissions';
import { SubmissionWorkflowService } from '../submission-workflow.service';
import { ToastService } from '../../../services/toast.service';

const MIN_LENGTH = 8;
const MAX_LENGTH = 9;

@Component({
  selector: 'app-submission-irbsearch',
  templateUrl: './submission-irbsearch.component.html',
  styleUrls: ['./submission-irbsearch.component.scss']
})
export class SubmissionIRBSearchComponent extends BaseComponent {
  foundStudy: SubmissionFindStudy;
  searched = false;
  searchTerm = new FormControl('', [Validators.required, Validators.minLength(MIN_LENGTH), Validators.maxLength(MAX_LENGTH)])

  constructor(
    public submissionWorkflowService: SubmissionWorkflowService,
    public toastService: ToastService,
    private apiService: ApiService,
    private router: Router
  ) {
    super();
  }

  public continueClick(): void {
    this.submissionWorkflowService.submissionFindStudy.next(this.foundStudy);
    this.router.navigate(['/submission-workflow/details']);
  }

  public search(): void {
    const irbTrackingNumber = this.searchTerm.value.trim();
    this.foundStudy = null;

    this.apiService.getWorkspaceSearch('study', '', irbTrackingNumber)
      .pipe().subscribe((data) => {
        if (data) {
          const studyData = {
            name: data.studyName,
            protocol: data.sponsorProtocolNumber,
            sponsor: data.sponsor,
            id: data.studyExternalReferenceId,
            status: data.status
          };
          this.searched = true;
          if (data.studyName !== '[EntityNotFound]') {
            this.foundStudy = studyData;
          }
        }
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'postStudiesFailure',
          message: err.details.message,
          timeout: this.toastService.ERROR_TIMEOUT,
          variant: 'error'
        }]);
      });
  }
}
