/* eslint-disable max-lines */
/* eslint-disable no-extra-parens */
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit } from '@angular/core';
import {
  NavItemObject,
  NavItems,
  NavItemStatus,
  PageFieldObject,
  PageFields,
  SubmissionFlowProtocolInfo,
  SubmissionInfo
} from '../../../interfaces/submissions';
import { ApiService } from '../../../services/api.service';
import { BaseComponent } from '../../../components/base/base.component';
import { BehaviorSubject } from 'rxjs';
import { FormioBaseComponent } from 'angular-formio';
import { FormioPDFService } from '../../../services/formio-pdf.service';
import { StateService } from '../../../services/state.service';
import { SubmissionWorkflowService } from '../submission-workflow.service';
import { ToastService } from '../../../services/toast.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { UtilService } from '../../../services/util.service';
import { environment } from '../../../../environments/environment';
import { protocolInfo2, protocolInfoSite } from '../../../common/formio-map';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { FormType } from 'src/app/admin/shared/model/submission-type';
import { TableHeader } from '../../../interfaces/components';
import { FormControl } from '@angular/forms';
import { SubmissionSelectedSite } from '../../../models/submission.model';
import { SubmissionTypeNames } from 'src/app/common/collections';

@Component({
  selector: 'app-submission-form',
  templateUrl: './submission-form.component.html',
  styleUrls: ['./submission-form.component.scss']
})

export class SubmissionFormComponent extends BaseComponent implements OnInit {
  documentsButton = false;
  draftExternalReferenceId: string;
  draftId = '';
  draftResponse = new BehaviorSubject<any>({});
  estimatedOutcomeDate: string;
  filteredPIs: Array<SubmissionSelectedSite>;
  footerButtonDisabled = true;
  formIncomplete = true;
  formIOForm: FormioBaseComponent['formio'];
  formReady = false;
  formSRCPath: string;
  formType: string;
  hasNav = false;
  hideForm = false;
  loading = true;
  loadingError = false;
  nextButton = false;
  nextPage = 1;
  nextPageName: string;
  piTableheaders: Array<TableHeader>;
  prevButton = false;
  prevPage = -1;
  prevPageName: string;
  protocolID: string;
  protocolInfo: SubmissionFlowProtocolInfo;
  returnToDraft = false;
  saveState = '';
  searchTerm = new FormControl('');
  submission: SubmissionInfo;
  submissionData: any;
  formRequiredNotMet = false;

  tableOptions = {
    rowsPerPage: 10
  };

  private currentPage: number = null;
  private currentTimeout: any = null;
  private isSetup = true;
  private lastSetupIndex: number = null;
  private referralPage: number;
  private referralStep: number;
  private prevNavItem: NavItemObject = null;

  constructor(
    public formioPDFService: FormioPDFService,
    public router: Router,
    public stateService: StateService,
    public submissionWorkflowService: SubmissionWorkflowService,
    private apiService: ApiService,
    private el: ElementRef,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private translatePipe: TranslatePipe,
    private utilService: UtilService
  ) {
    super();
  }

  ngOnInit(): void {
    this.piTableheaders = this.getTableHeaders();
    this.submissionWorkflowService.resetSaveStatus();
    this.stateService.estimatedOutcomeDate.subscribe((data) => {
      this.estimatedOutcomeDate = data;
    });

    this.filterPITable();
    this.getQueryParams();
    this.setFormReady();

    this.submissionWorkflowService.submissionDraftId.next(this.draftId);
    this.submissionWorkflowService.submissionTypeId.subscribe((value) => {
      if (value) {
        this.getDocumentTypesList(value);
      }
    });

    this.submissionWorkflowService.submissionStudySites.subscribe((val) => {
      this.filteredPIs = val;
    });

    if (!this.submissionWorkflowService.submissionTypeGroups.value?.length) {
      this.getSubmissionTypes();
    }

    if (!this.submissionWorkflowService.irbOrganization.value) {
      this.getContactIRBOrganization();
    }

    if (!this.submissionWorkflowService.countries.value?.length) {
      this.submissionWorkflowService.getContactCountries();
    }

    if (this.returnToDraft) {
      this.stateService.formNavVisible.next(false);
      this.hasNav = false;
      this.setupDraft();
    }

    if (this.submissionWorkflowService.formName.value) {
      this.formSRCPath = `${environment.formio.appUrl}/${this.submissionWorkflowService.formName.value}?full=true`;
    }

    this.submissionWorkflowService.saveState.subscribe((value) => {
      this.saveState = value;
    });

    this.submissionWorkflowService.setFormIOHeaders(environment);
    this.setNavDisplay();
    this.submissionWorkflowService.setDocumentStatus();

    this.submissionWorkflowService.showPiModal.subscribe(() => {
      this.searchTerm.setValue('');
    });

    if (!this.submissionWorkflowService.submissionReviewTypes.value?.length) {
      this.getReviewTypes();
    }
  }

  public continueClick(navItemDisabled: boolean = false): void {
    if (navItemDisabled) {
      this.showNavDisabledToast();

      return;
    }
    this.submissionWorkflowService.saveChanges();
    this.goToUpload();
  }

  public goToUpload(navItemDisabled: boolean = false): void {
    if (navItemDisabled) {
      this.showNavDisabledToast();

      return;
    }
    this.router.navigate(['/submission-workflow/details/upload']);
  }

  public formIoNextDisabled(): void {
    setTimeout(() => {
      const activeNavItem = this.submissionWorkflowService.formPages.value[this.currentPage];

      if (activeNavItem?.step === 0 && this.submissionWorkflowService.disabledCondition()) {
        this.footerButtonDisabled = true;

        return;
      }

      if (activeNavItem?.requiredNotMet) {
        this.footerButtonDisabled = true;

        return;
      }

      this.footerButtonDisabled = false;
    }, 0);
  }

  public goToReview(): void {
    let toastMessage: string = null;
    const formType = this.submissionWorkflowService.submissionFormType.value;

    if (!this.formRequiredNotMet && this.submissionWorkflowService.reviewDisabled) {
      toastMessage = 'submissionWorkflow.documentReviewDisabled';
      if (formType === FormType[FormType.UploadForm] && !this.submissionWorkflowService.filesData$.value.length) {
        toastMessage = 'submissionWorkflow.documentReviewDisabledNoFiles';
      }
    }
    if (this.formRequiredNotMet || toastMessage) {
      this.showNavDisabledToast(toastMessage);

      return;
    }

    this.router.navigate(['/submission-workflow/details/review']);
  }

  public navItemClick(pageNumber: number, navItemDisabled: boolean = false): void {
    if (navItemDisabled) {
      this.showNavDisabledToast();

      return;
    }
    this.submissionWorkflowService.saveChanges();

    this.router.navigate([], {
      queryParams: {
        referralPage: null,
        returnToDraft: null,
        formStep: pageNumber
      },
      queryParamsHandling: 'merge'
    });

    this.el.nativeElement.querySelector('.cnxs-submission-container').scrollTop = 0;
    this.prevPage = pageNumber - 1;
    this.nextPage = pageNumber + 1;
    this.setFormPage(pageNumber);
    this.submissionWorkflowService.formData.next(this.formIOForm);
    this.checkNavigation();
    this.formIoNextDisabled();
    this.calculateFormRequiredNotMet();

    this.submissionWorkflowService.formPages.next(
      this.submissionWorkflowService.formPages.value.map((page, idx) => {
        page.active = this.lastSetupIndex >= idx ? this.lastSetupIndex >= pageNumber : idx === pageNumber;

        return page;
      })
    );
  }

  public onFormChange(event): void {
    if (!event.changed || event.type === 'change') {
      if (this.formIOForm && this.submissionWorkflowService.formPages?.value?.length !== this.formIOForm.pages?.length) {
        this.checkNavigation();
      }

      return;
    }

    if (this.formIOForm) {
      this.submissionWorkflowService.formReady.next(true);
      this.checkNavigation();
    }

    if (this.isSetup) {
      this.isSetup = false;

      return;
    }

    // add metadata including timezone for datepickers
    this.formIOForm.setMetadata(this.formIOForm.submission);
    this.submissionWorkflowService.submissionMetadata.next(this.formIOForm.submission.metadata);

    // add it again on formChange to capture any conditional hidden form fields
    this.submissionWorkflowService.setNestedAlphaNumericRegexPattern(event.changed);
    this.submissionWorkflowService.formInfo.next({
      ...this.submissionWorkflowService.formInfo.value,
      ...this.formIOForm.data
    });

    this.submissionWorkflowService.submissionSponsorProtocolId.next(this.getProtocolId());
    this.submissionWorkflowService.submissionName.next(this.getSubmissionName());
    this.submissionWorkflowService.studyName.next(this.getStudyName());
    this.submissionWorkflowService.sponsor.next(this.getSponsor());
    this.formIoNextDisabled();
    this.calculateFormRequiredNotMet();

    this.saveChangesWithTimeout();
  }

  public returnToDetails(page: number, navItemDisabled: boolean = false): void {
    if (navItemDisabled) {
      this.showNavDisabledToast();

      return;
    }
    this.stateService.formNavVisible.next(true);
    this.hasNav = true;
    this.navItemClick(page);
  }

  public setUpForm(event: FormioBaseComponent): void {
    this.formIOForm = event.formio;
    this.formReady = false;
    this.formIOForm.nosubmit = true;
    this.formType = this.formIOForm.wizard ? this.formIOForm.wizard.title : this.formIOForm.title;

    this.submissionWorkflowService.formReady.next(false);
    this.submissionWorkflowService.addAlphanumericRegexPattern(this.formIOForm);

    this.submissionWorkflowService.formInfo.next({
      ...this.submissionWorkflowService.formInfo.value,
      formType: this.formType,
      formId: this.submissionWorkflowService.formId?.value || '',
      submissionFlow: this.submissionWorkflowService.submissionFlow.value || this.submissionWorkflowService.formInfo.value.submissionFlow,
      submissionFormType: this.submissionWorkflowService.submissionFormType.value,
      submissionTypeValue: this.submissionWorkflowService.submissionTypeValue?.value
    });

    if (this.submissionWorkflowService.submissionFlow.value !== 'addStudy') {
      const data = {
        ...this.submissionWorkflowService.formInfo.value,
        protocolInfoSite: {
          data: {
            protocolTitle: this.submissionWorkflowService.submissionFindStudy.value.name,
            sponsor: this.submissionWorkflowService.submissionFindStudy.value.sponsor,
            sponsorProtocolId: this.submissionWorkflowService.submissionFindStudy.value.protocol,
            submissionName: this.submissionWorkflowService.formInfo.value[protocolInfoSite.form]?.data?.
            [protocolInfoSite.fields.submissionName]
          },
          multipleSitesSelected: this.submissionWorkflowService.submissionStudySites.value?.length > 1,
          siteSelectionResponse: this.submissionWorkflowService.siteSelectionResponse.value
        }

      };

      this.submissionWorkflowService.formInfo.next(data);
    }

    if (this.submissionWorkflowService.submissionTypeValue.value === SubmissionTypeNames.siteClosure) {
      const data = {
        ...this.submissionWorkflowService.formInfo.value,
        piName: this.submissionWorkflowService.submissionStudySites.value[0].investigator,
        irbTrackingId: this.submissionWorkflowService.submissionStudySites.value[0].irbTrackingNumber
      };

      this.submissionWorkflowService.formInfo.next(data);
    }

    this.submissionData = {
      data: event.submission || this.submissionWorkflowService.formInfo.value
    };
  }

  public trackNavChanges = (_index, item): number => item.step;

  public closePIModal(): void {
    this.submissionWorkflowService.showPiModal.next(false);
  }

  private checkNavigation(): void {
    if (!this.formIOForm || !this.formIOForm.pages) {
      return null;
    }

    const formPages = this.formIOForm.pages;
    const lastSetupPage = formPages.filter((page) => page.component.tags && page.component.tags.indexOf('lastSetupPage') >= 0);
    this.lastSetupIndex = formPages.indexOf(lastSetupPage[0]);

    this.submissionWorkflowService.formPages.next(this.setNavItems(this.lastSetupIndex));

    const pageNum = this.referralStep ? this.referralStep : this.formIOForm.page;
    this.currentPage = parseInt(pageNum, 10);

    this.prevPageName = this.getPrevPageInfo(this.lastSetupIndex);
    this.nextPageName = this.getNextPageInfo(this.lastSetupIndex);

    if (this.returnToDraft) {
      this.hasNav = false;
    }

    if (!this.hasNav && this.currentPage > this.lastSetupIndex) {
      this.stateService.formNavVisible.next(true);
      this.hasNav = true;
    }

    this.setFooterOptions(this.currentPage, formPages.length - 1);
  }

  private filterPITable(): void {
    this.searchTerm.valueChanges.subscribe((term) => {
      this.filteredPIs = this.utilService.filterTableData(
        this.submissionWorkflowService.submissionStudySites.value,
        term.trim()
      );
    });
  }

  private getContactIRBOrganization(): void {
    this.apiService.getContactIRBOrganizations()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.submissionWorkflowService.irbOrganization.next(res.find((org) => org.abbreviation?.toLowerCase() === 'wirb'));
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'getReviewTypes',
          message: `Could not load IRB Organizations: ${err.details.error.message}`,
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  private getDocumentTypesList(value): void {
    this.apiService.getSubmissionDocumentTypesBySubmissionTypeId(value)
      .subscribe((result) => {
        this.submissionWorkflowService.submissionDocumentTypes.next(result.map((docType) => docType));
      }, (error) => {
        this.toastService.add([{
          closable: true,
          id: 'getSubmissionDocumentType',
          message: error.details.message,
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  private getDraftData(draftId: string): void {
    this.apiService.getDraftSubmission(draftId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.draftResponse.next(res);

        if (res.selectedSites) {
          this.submissionWorkflowService.investigators.next(res.selectedSites.map((site) => site.investigator).join(', '));
        }

        this.loading = false;
        this.submissionWorkflowService.formSubmissionId.next(res.id);
        this.submissionWorkflowService.formName.next(res.formName);
        this.submissionWorkflowService.submissionRegions.next(res.boardReviewTypeIds);
        this.formSRCPath = `${environment.formio.appUrl}/${this.submissionWorkflowService.formName.value}?full=true`;
        this.submissionWorkflowService.setFormIOHeaders(environment);
        this.submissionWorkflowService.defaultDocumentTypeId.next(res.submissionType?.defaultDocumentTypeId);

        if (res.formIOData) {
          this.submissionData = JSON.parse(res.formIOData);
          this.submissionWorkflowService.submissionFlow.next(this.submissionData.submissionFlow);
          this.submissionData.submissionTypeValue = res.submissionType?.value;
          this.submissionWorkflowService.formInfo.next(this.submissionData);
          this.submissionWorkflowService.formData.next({
            data: this.submissionData
          });
          this.submissionWorkflowService.submissionFormType.next(this.submissionWorkflowService.formInfo.value.submissionFormType);
        }
        if (res.submissionType?.value) {
          this.submissionWorkflowService.submissionTypeValue.next(res.submissionType?.value);
        }
        this.submissionWorkflowService.documentModels.next(res.documents);
        this.submissionWorkflowService.filesData$.next(JSON.parse(res.fileUploadGridData));
        this.submissionWorkflowService.submissionTypeId.next(res.submissionType.id);
        this.submissionWorkflowService.submissionStudySites.next(res.selectedSites);
        this.submissionWorkflowService.submissionDocumentStatus.next(this.submissionWorkflowService.setSubmissionDocumentsStatus());
        this.submissionWorkflowService.siteSelectionResponse.next(res.siteSelectionResponse?.length > 0 ? res.siteSelectionResponse : '');
        this.setSubmissionGuid();
        this.setSubmissionFindStudy(res);
        this.setSubmissionContent();
        this.submissionWorkflowService.setDocumentStatus();
      }, (err) => {
        this.loading = false;
        this.loadingError = true;
        this.toastService.add([{
          closable: true,
          id: 'getDraftSubmissionError',
          message: `Could not load draft: ${err.details.error.message}`,
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  private getNextPageInfo(lastSetupPage): string {
    const formPages = this.formIOForm.pages;

    if (this.currentPage < lastSetupPage) {
      return this.translatePipe.transform('submissionWorkflow.continueSetup');
    }

    if (this.currentPage === lastSetupPage) {
      return this.translatePipe.transform('submissionWorkflow.completeSetup');
    }

    return formPages[this.currentPage + 1] ? formPages[this.currentPage + 1].component.title : '';
  }

  private getPrevPageInfo(lastSetupPage): string {
    const formPages = this.formIOForm.pages;

    if (lastSetupPage > this.currentPage - 1) {
      return this.translatePipe.transform('submissionWorkflow.back');
    }

    if (lastSetupPage === this.currentPage - 1) {
      return this.translatePipe.transform('submissionWorkflow.setup');
    }

    return formPages[this.currentPage - 1] ? formPages[this.currentPage - 1].component.title : '';
  }

  private getProtocolId(): string {
    const formInfo = this.submissionWorkflowService.formInfo.value;
    const formioValue = formInfo?.[protocolInfo2.form]?.data?.[protocolInfo2.fields.sponsorsProtocolId] ||
      formInfo.value?.[protocolInfoSite.form]?.data?.[protocolInfoSite.fields.sponsorsProtocolId];

    return this.submissionWorkflowService.submissionFindStudy.value.protocol || formioValue || formInfo.value?.sponsorsProtocolId;
  }

  private getSponsor(): string {
    return this.submissionWorkflowService.formInfo.value[protocolInfo2.form]?.data[protocolInfo2.fields.sponsor] ||
      this.submissionWorkflowService.submissionFindStudy.value.sponsor;
  }

  private getStudyName(): string {
    return this.submissionWorkflowService.formInfo.value[protocolInfo2.form]?.data[protocolInfo2.fields.studyName] ||
      this.submissionWorkflowService.submissionFindStudy.value.name;
  }

  private getSubmissionName(): string {
    const formInfo = this.submissionWorkflowService.formInfo.value;
    const formioValue = formInfo?.[protocolInfo2.form]?.data?.[protocolInfo2.fields.submissionName] ||
      formInfo?.[protocolInfoSite.form]?.data?.[protocolInfoSite.fields.submissionName];

    return formioValue;
  }

  private getQueryParams(): void {
    this.route.queryParams.subscribe((params) => {
      if (this.referralPage) {
        this.hasNav = true;
      }

      this.hideForm = Boolean(this.route.children.length);

      this.draftId = params.submissionId;
      this.referralPage = params.referralPage;
      this.referralStep = parseInt(params.formStep, 10);
      this.returnToDraft = params.returnToDraft;

      if (params.formStep) {
        this.nextPage = this.referralStep + 1;
        this.prevPage = this.referralStep - 1;

        this.navItemClick(this.referralStep);
      }
    });
  }

  private getSubmissionTypes(): void {
    this.apiService.getSubmissionTypeGroups()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.submissionWorkflowService.submissionTypeGroups.next(res);

        this.draftResponse.subscribe((response) => {
          this.submissionWorkflowService.submissionTypeGroup.next(
            this.submissionWorkflowService.getSubmissionGroupByFlowType(response.flowType)
          );
        });
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'getSubmissionTypeGroupsError',
          message: `Could not load Submission Type Groups: ${err.details.error.message}`,
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  private getReviewTypes(): void {
    this.apiService.getReviewTypes()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.submissionWorkflowService.submissionReviewTypes.next(res);
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'getReviewTypes',
          message: `Could not load Submission Review Types: ${err.details.error.message}`,
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  private getTableHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('tableHeaders.name'), 'investigator', true),
      this.utilService.createTableHeader(this.translatePipe.transform('tableHeaders.organization'), 'piOrganizationName', true),
      this.utilService.createTableHeader(this.translatePipe.transform('tableHeaders.country'), 'countryName', true)
    ];
  }

  private onFormReady(): void {
    if (this.formIOForm.components.length) {
      this.submissionWorkflowService.formData.next(this.formIOForm);

      if (this.returnToDraft) {
        this.setFormPage(0);
      }
      else {
        this.setFormPage(this.referralStep);
      }

      this.checkNavigation();
    }
  }

  private setFooterOptions(current: number, total: number): void {
    if (current === total) {
      this.nextButton = false;
      this.nextPageName = '';
      this.documentsButton = true;
    }
    else {
      this.nextButton = true;
      this.documentsButton = false;
    }

    if (current === 0) {
      this.prevButton = false;
    }
    else {
      this.prevButton = true;
    }
  }

  private setFormPage(page: number): void {
    this.formIOForm.setPage(page).then(() => {
      this.formIOForm.checkData(this.formIOForm.submission.data);
      this.submissionWorkflowService.formData.next(this.formIOForm);
    });
  }

  private setFormReady(): void {
    const time = 1500;

    this.submissionWorkflowService.formReady
      .pipe(
        debounceTime(time)
      ).subscribe((val) => {
        if (val) {
          this.formReady = true;

          this.onFormReady();
        }
      });
  }

  private setNavDisplay(): void {
    if (this.referralPage) {
      this.hasNav = true;

      return;
    }

    this.hasNav = false;
  }

  private setNavItem(active: boolean, step: number, visible: boolean, title: string,
    fields: any, status?: NavItemStatus): NavItemObject {
    const statusOptions = status || this.setNavItemStatus(fields, false);
    const requiredNotMet = this.submissionRequirementMet(fields);
    if (requiredNotMet) {
      this.formRequiredNotMet = true;
    }

    const navItem = {
      active: active,
      ...statusOptions,
      requiredNotMet: requiredNotMet,
      step: step,
      title: title,
      visible: visible,
      disabled: this.isStepDisabled()
    };
    this.prevNavItem = {
      ...navItem
    };

    return navItem;
  }

  private setNavItems(lastSetupIndex: number): NavItems {
    if (!this.formIOForm?.pages.length) {
      return [];
    }

    const setupPages = [...this.formIOForm.pages].splice(0, lastSetupIndex + 1);
    const setupFields = [];

    setupPages.map((page) => page.everyComponent((comp) => {
      if (this.submissionComponentVisible(comp)) {
        setupFields.push(this.submissionFieldMap(comp));
      }
    }));

    this.prevNavItem = null;
    const setupOptions = this.setNavItemStatus(setupFields, true);
    const setUpNavItems = setupPages.map((page, idx) => {
      const pageFields = [];
      const active = lastSetupIndex >= this.currentPage;
      const title = page.component?.label || 'Setup';
      const visible = idx === 0;

      page.everyComponent((comp) => {
        if (this.submissionComponentVisible(comp)) {
          pageFields.push(this.submissionFieldMap(comp));
        }
      });

      return this.setNavItem(active, idx, visible, title, pageFields, setupOptions);
    });

    const formPages = [...this.formIOForm.pages].splice(lastSetupIndex + 1);
    const navItems = formPages.map((page, idx) => {
      const isVisible = true;
      const step = idx + setupPages.length;
      const isActive = lastSetupIndex + idx === this.currentPage - 1;
      const pageFields = [];

      page.everyComponent((comp) => {
        if (this.submissionComponentVisible(comp)) {
          pageFields.push(this.submissionFieldMap(comp));
        }
      });

      return this.setNavItem(isActive, step, isVisible, page.component?.label, pageFields);
    });

    return [
      ...setUpNavItems,
      ...navItems
    ];
  }

  private setNavItemStatus(pageFields: PageFields, isSetup: boolean): NavItemStatus {
    const completeFields = pageFields.filter((field) => Boolean(field.value));
    const completeDataGrids = completeFields.filter((field) => field.dataGrid);
    const pageSoftRequiredFields = pageFields.filter((field) => field.statusRequired);
    const statusRequiredMet = pageSoftRequiredFields.filter((field) => Boolean(field.value));
    const hasCompletedFields = completeFields.length > 0 && completeFields.length > completeDataGrids.length;
    const invalidFields = pageFields.filter((field) => !field.valid);

    if (isSetup || hasCompletedFields) {
      if (statusRequiredMet.length === pageSoftRequiredFields.length && invalidFields.length === 0) {
        return this.submissionWorkflowService.statusComplete;
      }

      return this.submissionWorkflowService.statusInProgress;
    }

    return this.submissionWorkflowService.statusNotStarted;
  }

  private setSubmissionContent(): void {
    const data = this.submissionWorkflowService.formInfo.value;
    this.protocolInfo = data[protocolInfo2.form]?.data?.submissionName ?
      data[protocolInfo2.form].data :
      data[protocolInfoSite.form].data;

    this.submission = {
      principalInvestigators: this.submissionWorkflowService.submissionStudySites.value,
      sponsorId: this.protocolInfo.sponsorsProtocolId || this.protocolInfo.sponsorProtocolId,
      status: this.translatePipe.transform('submissionStatus.draft'),
      submissionName: this.protocolInfo.submissionName,
      submissionType: this.submissionWorkflowService.submissionTypeValue?.value || '',
      studyId: this.draftResponse.value?.study?.id,
      submissionTypeGroup: this.submissionWorkflowService.submissionTypeGroup.value?.value
    };
  }

  private setSubmissionFindStudy(res): void {
    if (res.flowType !== 1) {
      this.estimatedOutcomeDate = '';
      this.submissionWorkflowService.submissionFindStudy.next({
        sponsor: res.study?.sponsor,
        protocol: res.study?.protocolNumber,
        name: res.study?.name,
        id: res.study?.id,
        status: res.status?.value
      });
    }
  }

  private setSubmissionGuid(): void {
    if (this.submissionWorkflowService.filesData$.value.length > 0) {
      if (this.submissionWorkflowService.filesData$.value[0].document.storageFilePath.split('/') !== null) {
        const submissionGuid = this.submissionWorkflowService.filesData$.value[0].document.storageFilePath.split('/');
        this.submissionWorkflowService.documentUploadSubmissionGuid.next(submissionGuid[2]);
      }
    }
  }

  private setupDraft(): void {
    if (this.submissionWorkflowService.availableForms.value.length === 0) {
      this.apiService.getAllFormioFormsToDisplay().subscribe((res) => {
        const availableForms = res.map((form) => ({
          title: form.data.formTitle,
          url: form.data.formName,
          pdfFileName: form.data.pdfFileName
        }));

        this.submissionWorkflowService.availableForms.next(availableForms);
        this.getDraftData(this.draftId);
      });
    }
    else {
      this.getDraftData(this.draftId);
    }
  }

  private submissionComponentVisible = (comp): boolean => comp.parentVisible &&
    comp.visible &&
    comp.component.input &&
    comp.component.type !== 'form';

  private submissionFieldMap(comp): PageFieldObject {
    const isSoftRequired = comp.component?.tags?.indexOf('softRequired') >= 0;
    const isHardRequired = comp.component?.validate?.required;

    return {
      dataGrid: comp.component.type === 'datagrid',
      key: comp.key,
      required: isHardRequired,
      statusRequired: isHardRequired || isSoftRequired,
      value: comp.hasValue() && !comp.isEmpty() && !this.utilService.isEmptyObject(comp.dataValue),
      valid: comp.error === null
    };
  }

  private submissionRequirementMet = (fields): boolean => {
    const pageRequiredFields = fields.filter((field) => field.required);
    const requiredMet = pageRequiredFields.filter((field) => Boolean(field.value));

    return pageRequiredFields.length > 0 ? pageRequiredFields.length > requiredMet.length : null;
  }

  private saveChangesWithTimeout(): void {
    const { saveInterval } = this.submissionWorkflowService;

    if (this.currentTimeout === null) {
      this.currentTimeout = setTimeout(() => {
        clearTimeout(this.currentTimeout);
        this.currentTimeout = null;

        if (this.formIOForm.page !== 0) {
          this.submissionWorkflowService.saveChanges();
        }
      }, saveInterval);
    }
  }

  private isStepDisabled(): boolean {
    if (!this.prevNavItem) {
      return false;
    }

    return this.prevNavItem.disabled || this.prevNavItem.requiredNotMet;
  }

  private calculateFormRequiredNotMet(): void {
    let flagSet = false;
    this.submissionWorkflowService.formPages.value.forEach((page) => {
      if (page.requiredNotMet) {
        this.formRequiredNotMet = true;
        flagSet = true;
      }
    });

    if (!flagSet) {
      this.formRequiredNotMet = false;
    }
  }

  private showNavDisabledToast(toastMessage: string = null): void {
    let generalToastMessage = toastMessage;
    const activeNavItem = this.submissionWorkflowService.formPages.value[this.currentPage];
    if (generalToastMessage === null) {
      const isOtherPageOrDraft = this.returnToDraft || (activeNavItem && !activeNavItem.requiredNotMet);
      generalToastMessage = isOtherPageOrDraft ? 'submissionWorkflow.otherPageNavItemDisabled' : 'submissionWorkflow.navItemDisabled';
    }
    this.toastService.add([{
      closable: true,
      timeout: 5000,
      id: 'navigationDisabled',
      message: this.translatePipe.transform(generalToastMessage),
      variant: 'info'
    }]);
  }
}
