import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-workflow-intro',
  templateUrl: './workflow-intro.component.html',
  styleUrls: ['./workflow-intro.component.scss']
})
export class WorkflowIntroComponent {
  @Input() heading: string;
  @Input() title: string;
  @Input() details: string;
  @Input() illustration: string;
  @Input() illustrationDescription: string;

  constructor() { }
}
