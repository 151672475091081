/* eslint-disable max-lines */
import { ContactOrganizations } from './../../../../interfaces/user';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/components/base/base.component';
import {
  UserRoles, ContactCountries, ContactStates, UserRole,
  ContactOrganizationType, UserInfoDetailsMap, UserDetailsWorkspaceGridRecordMap, UserDetailsWorkspaceGridRecord
} from 'src/app/interfaces/user';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Prefixes, Suffixes, PermissionTypeViewValues, PermissionType, DEBOUNCE_TIME } from 'src/app/common/collections';
import { ApiService } from 'src/app/services/api.service';
import { takeUntil, debounce } from 'rxjs/operators';
import { LoggerService } from 'src/app/services/logger.service';
import { UserAdminService } from 'src/app/admin/shared/services/user-admin.service';
import { Organization, OrganizationAutocompleteMap } from '../../../../interfaces/common';
import { ToastService } from 'src/app/services/toast.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { timer } from 'rxjs';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent extends BaseComponent implements OnInit {
  // Edit Modal
  editUserForm: FormGroup;
  currentUserInfo: any;

  showUserEditModal = false;
  showResetPasswordModal = false;
  isAdmin = false;
  isSystemAdmin = false;
  isSelectedUserAdmin = false;

  modalTitle: string;
  stateLabel: string;
  lastActivityDate: string;

  countries: ContactCountries;
  states: ContactStates;
  roles: UserRoles;

  prefixes = Prefixes;
  suffixes = Suffixes;

  user: UserInfoDetailsMap;

  role: UserRole;
  organization: Organization;
  organizations: ContactOrganizations;
  organizationType: ContactOrganizationType;

  allOrganizations: any = [];

  headers = [
    {
      name: 'IRB Tracking ID',
      field: 'IRB Tracking ID',
      sortable: true
    },
    {
      name: 'Sponsor Protocol ID',
      field: 'Sponsor Protocol ID',
      sortable: true
    },
    {
      name: 'PI Name',
      field: 'PI Name',
      sortable: true
    },
    {
      name: 'Permissions',
      field: 'Permissions',
      sortable: true
    }
  ];

  options = {
    rowSelection: false,
    rowsPerPage: 10
  };

  isActive = false;
  workspaces: Array<UserDetailsWorkspaceGridRecordMap>;
  userId: string;
  searchTerm = new FormControl('');
  userFullName: string;
  showEditProfileButton = false;

  loading = false;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private userAdminService: UserAdminService,
    private apiService: ApiService,
    private loggerService: LoggerService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private translatePipe: TranslatePipe,
    private utilService: UtilService
  ) {
    super();

    this.userService.userInfo.subscribe((data) => {
      this.isAdmin = data.canViewUsers || data.canManageUsers;
      this.isSystemAdmin = data.canManageSettings;
      this.currentUserInfo = data;
    });
    this.searchTerm.setValue('');
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.userId = params.id;

      this.getUserDetails();
      this.getUserWorkspaces({
        searchTerm: ''
      });

      this.searchTerm.valueChanges
        .pipe(
          debounce(() => timer(DEBOUNCE_TIME))
        )
        .subscribe((val) => {
          this.getUserWorkspaces({
            searchTerm: val
          });
        });
    });

    this.initializeEditUserForm();
  }

  public getUserWorkspaces(body): void {
    this.loading = true;
    this.apiService.getUserWorkspaces(this.userId, body)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.workspaces = this.mapWorkspaceGridData(data);
        this.loading = false;
      }, (err) => {
        this.loading = false;
        this.toastService.add([{
          closable: true,
          id: 'userWorkspace',
          message: `User Workspaces could not be fetched. ${err}`,
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  public editUser(): void {
    this.showUserEditModal = true;
    this.modalTitle = 'Edit User';
    // Organization
    this.editUserForm.controls.selectedOrganization.valueChanges.pipe(
      debounce(() => timer(DEBOUNCE_TIME))
    ).subscribe((org) => {
      if (org?.value?.length > 1) {
        this.getOrganizations(org.value);
      }
    });

    this.getUserDetails();
    this.getOrganizations(this.user.organization?.name);

    this.editUserForm.controls.country.valueChanges.subscribe((value) => {
      this.updateStates(parseInt(value, 10), true);
    });
  }

  // Edit User Functionality

  public initializeEditUserForm(): void {
    this.editUserForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      prefix: [''],
      suffix: [''],
      degrees: [''],
      phone: ['', Validators.required],
      email: new FormControl({
        value: '',
        disabled: true
      }),
      country: ['', Validators.required],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      province: ['', Validators.required],
      postalCode: ['', Validators.required],
      role: ['', Validators.required],
      selectedOrganization: new FormControl({
        value: '',
        selectedValues: []
      }, [
        (control): any => {
          if (control.value.selectedValues?.length > 0) {
            return;
          }

          return {
            validateSelectedOrganization: {
              valid: false
            }
          };
        }
      ]),
      organizationType: new FormControl({
        value: '',
        disabled: true
      }),
      isEmailNotificationEnabled: ['']
    });
  }


  // #region Events

  public closeEditUserModal(): void {
    this.showUserEditModal = false;
  }

  public saveUser(): void {
    this.userAdminService.postUser(this.mapProfileForPost())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.buildUserFullName();
        this.showUserEditModal = false;
        this.toastService.add([{
          closable: true,
          id: 'saveUserDataSuccessful',
          message: this.translatePipe.transform('adminUser.saveProfileSuccessful'),
          timeout: 5000,
          variant: 'success'
        }]);
      }, (err) => {
        this.loggerService.error(`User Profile could not be saved. ${err}`);
        this.toastService.add([{
          closable: true,
          id: 'saveUserDataFailure',
          message: this.translatePipe.transform('profile.saveProfileFailure'),
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  public resetPassword(): void {
    this.showResetPasswordModal = false;
    this.userAdminService.postResetPassword(this.user.email)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.toastService.add([{
          closable: true,
          id: 'resetPasswordSuccessful',
          message: this.translatePipe.transform('adminUser.resetPasswordSuccessful').replace('{email}', this.user.email),
          timeout: 5000,
          variant: 'success'
        }]);
      }, (err) => {
        this.loggerService.error(`Failed to reset password. ${err}`);
        this.toastService.add([{
          closable: true,
          id: 'resetPasswordFailure',
          message: this.translatePipe.transform('adminUser.resetPasswordFailure').replace('{email}', this.user.email),
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  public statusChange(event): void {
    this.isActive = event.target.checked;
    this.userAdminService.postSetUsersStatus([this.user.externalReferenceId], !this.isActive)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.toastService.add([{
          closable: true,
          id: 'setUserStatusSuccessful',
          message: this.translatePipe.transform('adminUser.setUserStatusSuccessful'),
          timeout: 5000,
          variant: 'success'
        }]);
      }, (err) => {
        this.loggerService.error(`Failed to change user status. ${err}`);
        this.toastService.add([{
          closable: true,
          id: 'setUserStatusFailure',
          message: this.translatePipe.transform('adminUser.setUserStatusFailure'),
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  public handleCountryChange(event): void {
    this.editUserForm.patchValue({ country: event.target.value });
    this.updateStates(parseInt(event.target.value, 10), false);
  }

  public stateChange(event): void {
    if (this.editUserForm.controls.state.value === 'Select') {
      this.editUserForm.patchValue({ province: ' ' });
      this.editUserForm.patchValue({ state: '' });
    }
    else {
      this.editUserForm.patchValue({ state: event.target.value });
    }
  }

  public onSelectChange(event, field: string): void {
    this.editUserForm.value[field] = event.target.value;
  }

  public updateOrganization(event): void {
    if (event.detail.option) {
      const orgType = this.allOrganizations.filter((org) => org.value === event.detail.value);
      if (orgType.length > 0) {
        this.editUserForm.controls.organizationType.setValue(orgType[0].organizationType?.value);
        this.editUserForm.controls.selectedOrganization.setValue({
          value: '',
          selectedValues: [new Option(orgType[0].value, orgType[0].option)]
        });
      }
    }
  }

  //  #endregion

  //  #region LoadData

  public getUserDetails(): void {
    this.userAdminService.getUserDetails(this.userId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.user = data;
        this.isActive = !this.user.isDeleted;

        this.role = this.user.role;
        this.organization = this.user.organization;
        this.organizationType = this.user.organization?.organizationType;
        this.mapUserDataToProfile();
        this.buildUserFullName();

        // Load data for dropdowns
        this.getCountries();
        this.getRoles();
        // this.getOrganizations();
        this.resetActionButtons(this.role?.name, this.userId, this.currentUserInfo?.externalReferenceId, this.isSystemAdmin);
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'fetchUserDetail',
          message: `User Details could not be fetched. ${err}`,
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  public resetActionButtons(roleName: string, userId: string, loggedOnUserId: any, isSystemAdmin: boolean): void {
    this.isSelectedUserAdmin = roleName === 'Client Relations' || roleName === 'System Admin' || roleName === 'IRB Staff';
    if (!isSystemAdmin && this.isSelectedUserAdmin && userId !== loggedOnUserId) {
      this.showEditProfileButton = false;
    }
    else {
      this.showEditProfileButton = true;
    }
  }

  public getCountries(): void {
    this.userAdminService.getCountries()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.countries = data;
        this.updateStates(this.editUserForm.value.country, false);
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'fetchCountry',
          message: `User Countries could not be fetched. ${err}`,
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  public getStates(countryId: number): void {
    this.userAdminService.getStates(countryId.toString())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.states = data;
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'fetchStates',
          message: `User States could not be fetched. ${err}`,
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  public mapOrganizations = (data: Array<Organization>): Array<OrganizationAutocompleteMap> => data.map((record) => ({
    option: record.name,
    value: record.id,
    organizationType: record.organizationType
  }));

  public getRoles(): void {
    this.userAdminService.getUserRoles()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.roles = data;
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'fetchUserRoles',
          message: `User Roles could not be fetched. ${err}`,
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  public updateStates(value: number, change?: boolean): void {
    if (this.countries) {
      const usa = this.countries.find((country) => country.alpha2Code === 'US');
      const canada = this.countries.find((country) => country.alpha2Code === 'CA');
      const found = [usa.id, canada.id].find((country) => country === value);

      this.stateLabel = value === usa.id ? 'profile.profileFormState' : 'profile.profileFormProvince';

      if (found) {
        this.getStates(value);
        // String with a space to pass required field validation
        this.editUserForm.patchValue({ province: ' ' });
        if (change) {
          this.editUserForm.patchValue({ state: '' });
        }
      }
      else {
        if (change) {
          this.editUserForm.patchValue({ province: '' });
          this.editUserForm.patchValue({ state: ' ' });
        }
        this.states = [];
      }
    }
  }

  //  #endregion

  //  #region Helper

  // eslint-disable-next-line complexity
  public mapUserDataToProfile(): void {
    this.editUserForm.setValue(
      {
        firstName: this.user.firstName,
        middleName: this.user.middleName,
        lastName: this.user.lastName,
        prefix: this.user.prefix,
        suffix: this.user.suffix,
        degrees: this.user.degrees,
        phone: this.user.phone,
        email: this.user.email,
        addressLine1: this.user.address?.addressLine1 || '',
        addressLine2: this.user.address?.addressLine2 || '',
        city: this.user.address?.city || '',
        postalCode: this.user.address?.postalCode || '',
        role: this.user.role?.id,
        selectedOrganization: {
          value: '',
          selectedValues: this.user.organization ? [new Option(this.user.organization ?.id, this.user.organization ?.name)] : []
        },
        organizationType: this.user.organization ?.organizationType ?.value || '',
        country: this.user.address?.country?.id || '',
        // String with a space to pass required field validation
        state: this.user.address?.state?.id || ' ',
        province: this.user.address?.province || ' ',
        isEmailNotificationEnabled: this.user.isEmailNotificationEnabled
      }
    );
  }

  public mapProfileForPost(): object {
    const data = this.editUserForm.value;
    const userData = this.user;
    const country = this.countries?.find((item) => item.id === parseInt(data.country, 10));
    const state = this.states?.find((item) => item.id === parseInt(data.state, 10));
    userData.firstName = data.firstName;
    userData.middleName = data.middleName;
    userData.lastName = data.lastName;
    userData.prefix = data.prefix;
    userData.suffix = data.suffix;
    userData.degrees = data.degrees;
    userData.phone = data.phone;
    userData.address = {
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      postalCode: data.postalCode,
      country: country,
      state: state,
      province: data.province
    };

    userData.isEmailNotificationEnabled = this.editUserForm.value.isEmailNotificationEnabled;

    const role = this.roles?.find((item) => item.id === parseInt(data.role, 10));
    const organization = this.organizations?.find((item) => item.id === data.selectedOrganization.selectedValues[0].text);
    userData.role = role;
    userData.organization = organization;

    return userData;
  }

  public mapWorkspaceGridData(data: Array<UserDetailsWorkspaceGridRecord>): Array<UserDetailsWorkspaceGridRecordMap> {
    return data.map((record) => ({
      'IRB Tracking ID': this.utilService.addTableCellWrapperWithSearchTerms(record.irbTrackingNumber, this.searchTerm.value),
      'Sponsor Protocol ID': this.utilService.addTableCellWrapperWithSearchTerms(record.protocolNumber, this.searchTerm.value),
      'PI Name': this.utilService.addTableCellWrapperWithSearchTerms(record.principalInvestigator, this.searchTerm.value),
      Permissions:
      this.utilService.addTableCellWrapperWithSearchTerms(PermissionTypeViewValues[PermissionType[record.permissionType]],
        this.searchTerm.value)
    }));
  }

  public buildUserFullName(): void {
    const firstName = `${this.user.firstName}`;
    const lastName = ` ${this.user.lastName}`;
    let prefix = '';
    let suffix = '';
    let middleName = '';
    let degrees = '';

    if (this.user.prefix) {
      prefix = `${this.user.prefix} `;
    }

    if (this.user.middleName) {
      middleName = ` ${this.user.middleName}`;
    }

    if (this.user.suffix) {
      suffix = ` ${this.user.suffix}`;
    }

    if (this.user.degrees) {
      degrees = `, ${this.user.degrees}`;
    }

    this.userFullName = prefix + firstName + middleName + lastName + suffix + degrees;
  }
  //  #endregion

  private getOrganizations(filter): void {
    this.userAdminService.getOrganizations(filter)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.organizations = data;
        this.allOrganizations = this.mapOrganizations(data);
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'fetchOrganizations',
          message: `User Organizations could not be fetched. ${err}`,
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }
}
