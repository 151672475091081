export class GridOptionsSearchModel {
  public page: number;
  public pageSize: number;
  public searchTerm: string;
  public filterOptions: FilterOptionModel[];
  public sortOptions: SortOptionModel[];
  public startDate: any;
  public endDate: any;
}

export class GridOptionsModel {
  public page: number;
  public pageSize: number;
  public filterOptions: FilterOptionModel[];
  public sortOptions: SortOptionModel[];
}

export class SortOptionModel {
  public field: string;
  public isDescending: boolean;
}

export class FilterOptionModel {
  public field: string;
  public operator: string;
  public values: Array<any>;
}
