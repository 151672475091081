<div class="wcg-container cnxs-loader-container">
  <div *ngIf="(sitesFetched && initialSites?.length)">
    <div>
      <form ngForm class="wcg-row">
        <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-4">
        </div>
        <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
          <ng2-flatpickr class="wcg-input-with-icon" #startDatePicker placeholder="Start Date"
            [config]="datePickerOptions" [formControl]="startDateFilter">
            <wcg-icon class="date-icon" (click)="toggleStartDate()" src="./assets/icons/action/ic_date_range_24px.svg">
            </wcg-icon>
          </ng2-flatpickr>
        </div>
        <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
          <ng2-flatpickr class="wcg-input-with-icon" #endDatePicker placeholder="End Date" [config]="datePickerOptions"
            [formControl]="endDateFilter">
            <wcg-icon class="date-icon" (click)="toggleEndDate()" src="./assets/icons/action/ic_date_range_24px.svg">
            </wcg-icon>
          </ng2-flatpickr>
        </div>
        <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-4">
          <wcg-form-field>
            <wcg-label>{{ 'actions.search' | translate }}</wcg-label>
            <wcg-input [formControl]="searchTerm">
              <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
            </wcg-input>
          </wcg-form-field>
        </div>
      </form>
    </div>

    <div class="wcg-row">
      <div class="wcg-col-xs-4 wcg-col-md-12 wcg-col-lg-12 wcg-rhythm-2">
        <div class="export-button-wrap">
          <app-export-action-button [gridFilters]="gridFilters" [disableButton]="!tableData?.length"
            [exportType]="exportType" [entityId]="id"></app-export-action-button>
        </div>
      </div>
    </div>

    <wcg-table class="wcg-rhythm-4" [ngClass]="{'cnxs-table-loading': loading}" [headers]="headers" [data]="tableData"
      [options]="options" (rowClicked)="handleRowClicked($event)" (sortClicked)="handleSorting($event)">

      <div class="cnxs-table-loader" slot="table-body">
        <wcg-loader [show]="!loaded || loading"></wcg-loader>
      </div>

      <div class="wcg-row cnxs-pagination-wrap wcg-table-footer" *ngIf="loaded">
        <div class="wcg-total-results">
          {{ 'tables.totalResults' | translate}}: {{totalRecords}}
        </div>
        <wcg-pagination class="pagination" *ngIf="loaded && tableData?.length" 
          [current]="currentPage" [pageSize]="currentPageSize"
          [max]="totalPages" (changePage)="handleChangePage($event)">
        </wcg-pagination>
      </div>
    </wcg-table>

    <wcg-modal id="outcomeDocumentModal" [show]="showOutcomeDocumentModal" (close)="showOutcomeDocumentModal = false"
      class="override-modal">
      <wcg-modal-header>{{ 'studySites.outcomeDocumentReviewDetails' | translate }}</wcg-modal-header>
      <wcg-modal-body>
        <div class="wcg-row wcg-between-md cnxs-page-header">
          <div class="wcg-col-xs-4">
            <h1 class="wcg-h2 wcg-rhythm-1 cnxs-wrap-content">{{ utilService.replaceNull(siteDetails?.investigator) }}
            </h1>
            <div class="wcg-body-md">
              <strong>{{ utilService.replaceNull(siteDetails?.piOrganizationName) }}</strong>
            </div>
          </div>
        </div>
        <dl class="wcg-rhythm-3">
          <div class="wcg-row">
            <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2">
              <dt class="wcg-body-label">{{ 'labels.sponsorProtocolId' | translate }}</dt>
              <dd class="cnxs-wrap-content">{{ utilService.replaceNull(study?.sponsorProtocolID) }}</dd>
            </div>

            <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2">
              <dt class="wcg-body-label">{{ 'labels.irbTrackingId' | translate }}</dt>
              <dd class="cnxs-wrap-content">{{ utilService.replaceNull(siteDetails?.irbTrackingNumber) }}</dd>
            </div>
          </div>
        </dl>
        <app-export-confirmation-modal></app-export-confirmation-modal>
        <app-site-outcome-documents *ngIf="showOutcomeDocumentModal" [siteId]="siteId"></app-site-outcome-documents>

      </wcg-modal-body>
      <wcg-modal-footer>
        <button class="wcg-btn wcg-btn-auto" (click)="modalCancelClick()">{{'actions.close' | translate}}</button>
      </wcg-modal-footer>
    </wcg-modal>

  </div>
  <wcg-loader [show]="!sitesFetched"></wcg-loader>
  <app-no-records *ngIf="sitesFetched && !pageUnauthorized && !initialSites?.length">
    <p class="wcg-h2">{{ 'studyDetails.noSites' | translate }}</p>
  </app-no-records>
  <app-no-records *ngIf="sitesFetched && pageUnauthorized">
    <p class="wcg-h2">{{ 'studyDetails.noSitesAssociated' | translate }}</p>
  </app-no-records>
</div>