<div class="wcg-container">
  <div class="wcg-row wcg-between-md cnxs-page-header">
    <div class="wcg-col-xs-4">
      <h1 class="wcg-h2 wcg-rhythm-1 cnxs-wrap-content">{{ utilService.replaceNull(submission?.title) }}</h1>
      <h2 class="wcg-body-sm wcg-rhythm-7">{{ utilService.replaceNull(submission?.type?.value) }}</h2>
    </div>
  </div>


  <app-status-tracker [statuses]="submission?.statuses" [warning]="submission?.actionRequestedDate" class="wcg-rhythm-7"></app-status-tracker>

  <div class="wcg-rhythm-3" *ngIf="submission?.actionRequestedDate">
    <div class="wcg-row">
      <div class="wcg-col-xl-1">
        <wcg-badge class="hold-badge">
          <wcg-icon class="hold-icon" src="../assets/icons/alert/ic_warning_24px.svg"></wcg-icon>
        </wcg-badge>
      </div>
      <div class="wcg-col-xl-11">
        <span class="wcg-h4">
          {{ utilService.replaceNull(submission?.actionReason) }}
        </span>
        <span class="hold-date">
          <dd class="cnxs-wrap-content">{{ utilService.formatDate(submission?.actionRequestedDate) || utilService.replaceNull(submission?.actionRequestedDate) }}</dd>
        </span>
        <span class="hold-comments">
          {{ utilService.replaceNull(submission?.actionComments) }}
        </span>
      </div>
    </div>
  </div>

  <dl class="wcg-rhythm-3">
    <div class="wcg-row">
      <div class="wcg-col-xs-4">
        <dt class="wcg-body-label">{{ 'submissionDetails.studyTitle' | translate }}</dt>
        <dd>
          <a *ngIf="submission?.study?.id" class="wcg-link cnxs-wrap-content" routerLink="/studies/{{ submission?.study?.id }}">
            {{ utilService.replaceNull(submission?.study?.name) }}
          </a>
          <ng-container *ngIf="!submission?.study?.id">{{ utilService.replaceNull(submission?.study?.name) }}</ng-container>
        </dd>
      </div>
    </div>
  </dl>

  <dl class="wcg-rhythm-3">
    <div class="wcg-row">
      <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
        <dt class="wcg-body-label">{{ 'submissionDetails.sponsor' | translate }}</dt>
        <dd class="cnxs-wrap-content">{{ utilService.replaceNull(submission?.study?.sponsor) }}</dd>
      </div>
      <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
        <dt class="wcg-body-label">{{ 'submissionDetails.sponsorProtocolNumber' | translate }}</dt>
        <dd class="cnxs-wrap-content">{{ utilService.replaceNull(submission?.study?.protocolNumber) }}</dd>
      </div>
      <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
        <dt class="wcg-body-label">{{ 'submissionDetails.estimatedOutcomeDate' | translate }}</dt>
        <dd class="cnxs-wrap-content">{{ utilService.formatDate(submission?.estimatedOutcomeDate) || utilService.replaceNull(submission?.estimatedOutcomeDate) }}</dd>
      </div>
    </div>
    <div class="wcg-row">
      <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
        <dt class="wcg-body-label">{{ 'submissionDetails.irbTrackingNumber' | translate }}</dt>
        <dd class="cnxs-wrap-content">{{ utilService.replaceNull(submission?.study?.irbTrackingNumber) }}</dd>
      </div>
      <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
        <dt class="wcg-body-label">{{ 'submissionDetails.reviewOutcome' | translate }}</dt>
        <dd class="cnxs-wrap-content">{{ utilService.replaceNull(submission?.reviewOutcome) }}</dd>
      </div>
      <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
        <dt class="wcg-body-label">{{ 'submissionDetails.confirmationId' | translate }}</dt>
        <dd class="cnxs-wrap-content">{{ utilService.replaceNull(submission?.confirmationId) }}</dd>
      </div>
    </div>
  </dl>
</div>

<section class="cnxs-secondary-section">
  <div class="wcg-container">
    <wcg-tab-bar-section>
      <wcg-tab-bar>
        <a [routerLink]="['sites']" routerLinkActive="wcg-active" class="wcg-link">
          {{ 'submissionDetails.submittedSites' | translate }}
        </a>
        <a [routerLink]="['submitted-documents']" routerLinkActive="wcg-active" class="wcg-link">
          {{ 'submissionDetails.submittedDocuments' | translate }}
        </a>
        <a [routerLink]="['outcome-documents']" routerLinkActive="wcg-active" class="wcg-link">
          {{ 'submissionDetails.outcomeDocuments' | translate }}
        </a>
      </wcg-tab-bar>
      <div>
        <router-outlet></router-outlet>
      </div>
    </wcg-tab-bar-section>
  </div>
</section>
