import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../components/base/base.component';
import { LoggerService } from '../../services/logger.service';
import { ManageContactsService } from './manage-contacts.service';

@Component({
  selector: 'app-manage-contacts',
  templateUrl: './manage-contacts.component.html',
  styleUrls: ['./manage-contacts.component.scss']
})
export class ManageContactsComponent extends BaseComponent implements OnInit {
  id: string;
  isSite: boolean;
  name: string;
  openAccordion: boolean;
  permissions: number[][];
  requests: number;
  teamRequests: number;

  private sitePermissions = [
    [1, 0, 0],
    [1, 1, 0],
    [1, 1, 1],
    [1, 1, 1]
  ];

  private studyPermissions = [
    [1, 1, 1, 0],
    [1, 1, 0, 0],
    [1, 1, 0, 1],
    [1, 1, 1, 0],
    [1, 1, 1, 0],
    [1, 1, 1, 0],
    [1, 1, 1, 0],
    [1, 0, 0, 0],
    [0, 0, 0, 1]
  ];

  constructor(
    private apiService: ApiService,
    private loggerService: LoggerService,
    private manageContactsService: ManageContactsService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.isSite = this.router.url.includes('/sites/');

    this.permissions = this.isSite ? this.sitePermissions : this.studyPermissions;

    this.manageContactsService.userInfoSubject.subscribe((val) => {
      this.requests = val.length;
    });

    this.getDetails(this.isSite, this.manageContactsService.getWorkspaceId(this.router.url));
    this.getWorkspaceRequests(this.manageContactsService.getWorkspaceId(this.router.url));
  }

  private getDetails(isSite, id): void {
    if (isSite) {
      this.apiService.getSite(id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          this.name = data.investigator;
        }, (err) => {
          this.loggerService.error(`Data could not be fetched: ${err}`);
        });
    }
    else {
      this.apiService.getStudy(id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          this.name = data.name;
        }, (err) => {
          this.loggerService.error(`Data could not be fetched: ${err}`);
        });
    }
  }

  private getWorkspaceRequests(id): void {
    this.apiService.getWorkspaceRequests(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.manageContactsService.userInfoSubject.next(data);
      }, (err) => {
        this.loggerService.error(`Data could not be fetched: ${err}`);
      });
  }
}
