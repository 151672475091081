import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  SubmissionTypeGridRecordMap,
  IrisRoutingGroup, SubmissionType, SubmissionTypeGridRecord, FormType, SubmissionTypesGrid
} from '../model/submission-type';
import { IdValueModel } from '../model/sharedmodels';
import { AdminApis } from '../helpers/adminapis';
import { FilterOptionModel, GridOptionsSearchModel } from '../model/grid.model';
import { WebApiServices } from '../helpers/webapiservice';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { UtilService } from '../../../services/util.service';

@Injectable({
  providedIn: 'root'
})
export class SubmissionTypesService {
  service: WebApiServices;

  constructor(private http: HttpClient, private translatePipe: TranslatePipe, private utilService: UtilService) {
    this.service = new WebApiServices(this.http);
  }

  public getSubmissionTypesGrid(gridOptions: GridOptionsSearchModel): Observable<SubmissionTypesGrid> {
    return this.service.postService(AdminApis.SubmissionTypesGrid, gridOptions, null);
  }

  public getDocumentTypes(): Observable<IdValueModel[]> {
    return this.service.getService(AdminApis.DocumentTypes, null);
  }

  public getIrisRoutingGroups(): Observable<IrisRoutingGroup[]> {
    return this.service.getService(AdminApis.IrisRoutingGroup, null);
  }

  public getSubmissionTypeGroup(): Observable<IdValueModel[]> {
    return this.service.getService(AdminApis.SubmissionTypeGroup, null);
  }

  public postSubmissionType(submissionType: SubmissionType): Observable<any> {
    return this.service.postService(AdminApis.SubmissionTypePost, submissionType, null);
  }

  public filterSubmissionTypes(data: SubmissionTypeGridRecord[], filters: FilterOptionModel[], searchTerm: string): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      let filteredData = data;
      filteredData = filteredData.filter(
        (record) => record.value.toLowerCase().includes(searchTerm) ||
          record.envelopeAndEmailType.toLowerCase().includes(searchTerm) ||
          record.groups.map((group) => group.value).some((group) => group.toLowerCase().includes(searchTerm)) ||
          FormType[record.formType].toLowerCase().includes(searchTerm)
      );
      filters.forEach((filter) => {
        if (filter.values[0] && filter.values.toString() !== '-1') {
          /* eslint-disable indent */
          switch (filter.field) {
            case 'SubmissionTypeFormType':
              filteredData = filteredData.filter(
                (record) => record.formType.toString() === filter.values[0]
              );
              break;
            case 'SubmissionTypeGroup':
              filteredData = filteredData.filter(
                (record) => record.groups.map((group) => String(group.id)).includes(filter.values[0])
              );
              break;
            default:
              break;
          }
        }
      });
      if (filteredData && filteredData.length > 0) {
        resolve(this.mapSubmissionTypesGridData(filteredData, { searchTerm: searchTerm }));
      }
      else {
        reject(new Error('No records matching the search criteria'));
      }
    });

    return promise;
  }

  public mapSubmissionTypesGridData(data: SubmissionTypeGridRecord[], highlightData: any): Array<SubmissionTypeGridRecordMap> {
    return data.map((record) => ({
      id: record.id,
      'Submission Type Name': `<button edit-row-button class="wcg-link limit-width" title="${record.value}">\
        ${this.utilService.addTableCellWrapperWithSearchTerms(record.value, highlightData.searchTerm)}</button>`,
      'Envelope and Email Type': `<p class="limit-width" title="${record.envelopeAndEmailType}">\
        ${this.utilService.addTableCellWrapperWithSearchTerms(record.envelopeAndEmailType, highlightData.searchTerm)}</p>`,
      'Submission Type Group': this.mapSubmissionTypeGroups(record.groups, highlightData.searchTerm),
      Enabled: record.isActive ? `<span class="wcg-visually-hidden">
          ${this.translatePipe.transform('statuses.enabled')}
        </span>
        <wcg-icon src="./assets/icons/navigation/ic_check_24px.svg"></wcg-icon>` : `<span class="wcg-visually-hidden">
        ${this.translatePipe.transform('statuses.disabled')}
      </span>`,
      'Form Type': this.utilService.addTableCellWrapperWithSearchTerms(FormType[record.formType], highlightData.searchTerm)
    }));
  }

  private mapSubmissionTypeGroups(groups: IdValueModel[], searchTerm: any): string {
    return groups.map((group) => this.utilService.addTableCellWrapperWithSearchTerms(group.value, searchTerm)).join(', ');
  }
}
