export class DocumentCategory {
  id: string;
  value: string;
  isActive: boolean;
}

export interface DocumentCategoryMap {
  'Document Type Category': string;
  'Enabled': string;
}

export interface DocumentCategoryGrid {
  currentPage: number;
  totalRecords: number;
  pageSize: number;
  records: Array<DocumentCategory>;
}
