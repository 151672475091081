import { AuthGuard } from '../guards/auth.guard';
import { Routes } from '@angular/router';
import { SitesComponent } from '../views/sites/sites.component';
import { SiteComponent } from '../views/site/site.component';
import { SiteOutcomeDocumentsComponent } from '../views/site/site-outcome-documents/site-outcome-documents.component';
import { SiteContactsComponent } from '../views/site/site-contacts/site-contacts.component';
import { SiteSubmissionsComponent } from '../views/site/site-submissions/site-submissions.component';
import { ManageContactsComponent } from '../views/manage-contacts/manage-contacts.component';
import { ManageContactsContactsComponent } from '../views/manage-contacts/manage-contacts-contacts/manage-contacts-contacts.component';
import { ManageContactsRequestsComponent } from '../views/manage-contacts/manage-contacts-requests/manage-contacts-requests.component';
import { RequestAccessEntityType } from '../common/collections';

export const siteRoutes: Routes = [{
  path: 'sites',
  component: SitesComponent,
  canActivate: [
    AuthGuard
  ]
},
{
  path: 'sites/:id',
  component: SiteComponent,
  data: {
    canRequestAccess: true,
    entityType: RequestAccessEntityType.site
  },
  canActivate: [
    AuthGuard
  ],
  children: [
    {
      path: '',
      redirectTo: 'submissions',
      pathMatch: 'full',
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.site
      }
    },
    {
      path: 'outcome-documents',
      component: SiteOutcomeDocumentsComponent,
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.site
      }
    },
    {
      path: 'contacts',
      component: SiteContactsComponent,
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.site
      }
    },
    {
      path: 'submissions',
      component: SiteSubmissionsComponent,
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.site
      }
    }
  ]
},
{
  path: 'sites/:id/manage-contacts',
  component: ManageContactsComponent,
  canActivate: [
    AuthGuard
  ],
  data: {
    canRequestAccess: true,
    entityType: RequestAccessEntityType.site
  },
  children: [
    {
      path: '',
      redirectTo: 'contacts',
      pathMatch: 'full',
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.site
      }
    },
    {
      path: 'contacts',
      component: ManageContactsContactsComponent,
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.site
      }
    },
    {
      path: 'requests',
      component: ManageContactsRequestsComponent,
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.site
      }
    }
  ]
}];
