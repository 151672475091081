import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { UserInfoDetailsMap } from '../interfaces/user';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';
import { AuthenticationService } from '../core/services/authentication/authentication.service';
import { User } from 'oidc-client';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public userFetched = new BehaviorSubject<boolean>(false);

  private userInfoSubject = new BehaviorSubject<UserInfoDetailsMap>({
    isActive: null,
    detailsConcurrencyVersion: null,
    isRegistered: null,
    canViewSubmissions: null,
    canLoginDuringMaintenance: null,
    canViewUsers: null,
    canViewSettings: null,
    canViewTeams: null,
    canManageUsers: null,
    canManageSettings: null,
    canManageTeams: null,
    canViewAccessAuditReport: null,
    canViewManageSubmissions: null,
    email: null,
    externalReferenceId: null,
    isDeleted: null,
    role: null,
    organization: null,
    firstName: null,
    address: null,
    middleName: null,
    lastName: null,
    prefix: null,
    suffix: null,
    degrees: null,
    phone: null,
    isEmailNotificationEnabled: null,
    lastActivityDate: null,
    passwordInfo: null,
    teamExternalReferenceId: null,
    impersonatorUsername: null,
    team: null
  });

  constructor(
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    private loggerService: LoggerService
  ) {}

  public get userInfo(): Observable<UserInfoDetailsMap> {
    return this.userInfoSubject.asObservable();
  }

  public setIsRegistered(registered: boolean): void {
    const partialUserInfo: any = {
      isRegistered: registered
    };
    this.userInfoSubject.next({
      ...this.userInfoSubject.value,
      ...partialUserInfo
    });
  }

  public updateUserInfo(user: User): void{
    forkJoin({
      userInfo: this.apiService.getUserInfo(),
      userDetails: this.apiService.getUserDetails(user.profile.sub)
    }).subscribe((data) => {
      this.userInfoSubject.next({
        ...data.userInfo,
        ...data.userDetails
      });
      this.userFetched.next(true);
    }, (err) => {
      this.loggerService.error(`User Data could not be fetched. ${err}`);
    });
  }

  public getUserInfo(): any {
    const user = this.authenticationService.getUser();

    if ((!this.userInfoSubject.value.email || !this.userInfoSubject.value.firstName) && user) {
      this.updateUserInfo(user);
    }

    return this.userInfoSubject.asObservable();
  }
}
