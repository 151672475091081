<div class="wcg-container cnxs-loader-container">
  <div *ngIf="(documentUrlsFetched && initialDocuments?.length)">
    <form ngForm class="wcg-rhythm-5">
      <wcg-form-field>
        <wcg-label>{{ 'actions.search' | translate }}</wcg-label>
        <wcg-input [formControl]="searchTerm">
          <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
        </wcg-input>
      </wcg-form-field>
    </form>

    <div class="wcg-row">
      <div class="wcg-col-xs-4 wcg-col-md-12 wcg-col-lg-12 wcg-rhythm-2">
        <div class="export-button-wrap">
          <app-export-action-button [gridFilters]="gridFilters" [disableButton]="!tableData?.length"
            [exportType]="exportType" [entityId]="id"></app-export-action-button>
        </div>
      </div>
    </div>

    <wcg-table class="wcg-rhythm-4" [ngClass]="{'cnxs-table-loading': loading}" [headers]="headers" [data]="tableData"
      [options]="options" (rowSelected)="handleRowSelection($event)" (rowClicked)="handleRowClick($event)"
      (sortClicked)="handleSorting($event)">

      <div class="cnxs-table-loader" slot="table-body">
        <wcg-loader [show]="!loaded || loading"></wcg-loader>
      </div>
      <div class="cnxs-pagination-wrap wcg-table-footer wcg-rhythm-2" *ngIf="loaded">
        <div class="wcg-total-results">
          {{ 'tables.totalResults' | translate}}: {{totalRecords}}
        </div>
        <div class="pagination">
          <wcg-pagination *ngIf="tableData?.length" 
            [current]="currentPage" [pageSize]="currentPageSize"
            [max]="totalPages" (changePage)="handleChangePage($event)">
          </wcg-pagination>
        </div>
      </div>
    </wcg-table>

    <div class="wcg-row wcg-rhythm-2">
      <div class="wcg-col-md-2">
        <button class="wcg-btn wcg-btn-fluid"
          [disabled]="!documentUrlsFetched || tableData?.length === 0 || (zipService.isDownloadingAll | async)"
          (click)="zipService.startDownload(tableData, documentRequestTypes['Submission'], 'all')">
          {{ 'actions.downloadAll' | translate }}
          <wcg-loader [show]="zipService.isDownloadingAll | async"></wcg-loader>
        </button>
      </div>
      <div class="wcg-col-md-2">
        <button class="wcg-btn wcg-btn-fluid"
          [disabled]="!documentUrlsFetched || selected?.length === 0 || (zipService.isDownloadingSelected | async)"
          (click)="zipService.startDownload(selected, documentRequestTypes['Submission'], 'selected')">
          {{ 'actions.downloadSelected' | translate }}
          <wcg-loader [show]="zipService.isDownloadingSelected | async"></wcg-loader>
        </button>
      </div>
    </div>
  </div>
  <wcg-loader [show]="!documentUrlsFetched"></wcg-loader>
  <app-no-records *ngIf="documentUrlsFetched && !initialDocuments?.length && !pageUnauthorized">
    <p class="wcg-h2">{{ 'submissionDetails.noSubmittedDocs' | translate }}</p>
  </app-no-records>
  <app-no-records *ngIf="documentUrlsFetched && pageUnauthorized">
    <p class="wcg-h2">{{ 'serverErrors.unautorizedErrorSubmittedDocuments' | translate }}</p>
  </app-no-records>
</div>