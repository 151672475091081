import { ApiService } from '../../../services/api.service';
import { takeUntil } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { SubmissionWorkflowService } from '../../../views/submission-workflow/submission-workflow.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { TableHeader } from '../../../interfaces/components';
import { UtilService } from '../../../services/util.service';
import { piForm } from '../../../common/formio-map';
import { ToastService } from '../../../services/toast.service';
import { SubmissionTypeNames, FlowType } from '../../../common/collections';

@Component({
  selector: 'app-submission-details-modal',
  templateUrl: './submission-details-modal.component.html',
  styleUrls: ['./submission-details-modal.component.scss']
})

export class SubmissionDetailsComponent extends BaseComponent implements OnInit {
  public showModal = false;
  public showPIList = false;
  public showPiInfo = false;
  public showPiTotalInfo = false;
  public headers = [];
  public piList = [];

  constructor(
    public submissionWorkflowService: SubmissionWorkflowService,
    public translatePipe: TranslatePipe,
    public utilService: UtilService,
    private apiService: ApiService,
    private toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this.submissionWorkflowService.showSubmissionDetails.subscribe((res) => {
      if (res) {
        const flowType = this.submissionWorkflowService
          .getFlowTypeIdByName(this.submissionWorkflowService.submissionTypeGroup.value?.value);
        this.piList = [];
        this.showPiInfo = false;

        if (flowType === FlowType.NewStudy || flowType === FlowType.NewSite) {
          this.headers = this.getHeaders()
            .filter((header) => header.field !== 'institutionalTrackingNumber');
          const piFormData = this.submissionWorkflowService.formInfo.value[piForm.form]?.data;

          if (!this.utilService.isEmptyObject(piFormData) && this.piDataPopulated()) {
            this.piList.push({
              piName: this.utilService.formatName(piFormData.first, '', piFormData.last),
              organization: piFormData.addressForm.data?.companyInstitutionOrganization.trim(),
              country: this.submissionWorkflowService.countries.value
                .find((country) => country.alpha3Code === piFormData.addressForm.data?.country)?.name
            });
            this.showPiInfo = true;
          }
        }
        else {
          this.headers = this.getHeaders();
          const selectedStudySites = this.submissionWorkflowService.submissionStudySites.value;

          if (selectedStudySites.length > 0) {
            this.showPiTotalinfo();
            this.piList = selectedStudySites.map((element) => ({
              piName: this.utilService.addTableCellWrapper(element?.investigator),
              organization: this.utilService.addTableCellWrapper(element?.piOrganizationName),
              country: this.utilService.addTableCellWrapper(element?.countryName),
              institutionalTrackingNumber: this.utilService.addTableCellWrapper(element?.institutionalTrackingNumber)
            }));

            this.showPiInfo = true;
          }
        }
      }
      this.showModal = res;
    });
  }

  public cancelClick(): void {
    this.submissionWorkflowService.showSubmissionDetails.next(false);
  }

  private getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('sites.principalInvestigator'), 'piName', true),
      this.utilService.createTableHeader(this.translatePipe.transform('sites.org'), 'organization', true),
      this.utilService.createTableHeader(this.translatePipe.transform('sites.country'), 'country', true),
      this.utilService.createTableHeader(
        this.translatePipe.transform('sites.institutionalTracking'), 'institutionalTrackingNumber', true
      )
    ];
  }

  private showPiTotalinfo(): void {
    const singleSiteSelectForms = [SubmissionTypeNames.changeInInvestigator.toLowerCase(),
      SubmissionTypeNames.hudClinicalUseClosure.toLowerCase(),
      SubmissionTypeNames.siteClosure.toLowerCase()];

    const submissionType = this.submissionWorkflowService.submissionTypeValue.value.trim().toLowerCase();

    if (this.submissionWorkflowService.isSiteOriginSubmission()) {
      this.showPiTotalInfo = false;
    }
    else if (singleSiteSelectForms.indexOf(submissionType) < 0) {
      this.showPiTotalInfo = true;

      if (this.submissionWorkflowService.submissionStudySitesTotal.value === 0) {
        const selectedReviewTypes = this.submissionWorkflowService.submissionRegions.value
          .map((id: any) => parseInt(id, 10)) || [];

        this.postStudySitesSubmit(this.submissionWorkflowService.submissionFindStudy.value?.id, {
          reviewTypes: selectedReviewTypes,
          searchOptions: {
            page: 0,
            pageSize: 0,
            searchTerm: ''
          }
        });
      }
    }
  }

  private postStudySitesSubmit(id, model): void {
    this.apiService.postStudySitesSubmit(id, model)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.submissionWorkflowService.submissionStudySitesTotal.next(data.totalRecords);
      }, () => {
        this.toastService.add([{
          closable: true,
          id: 'postStudySitesSubmit',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          variant: 'error'
        }]);
      });
  }

  private piDataPopulated(): boolean {
    const allValues = [
      this.submissionWorkflowService.formInfo?.value[piForm.form].data[piForm.fields.first],
      this.submissionWorkflowService.formInfo?.value[piForm.form].data[piForm.fields.last],
      this.submissionWorkflowService.formInfo?.value[piForm.form]
        .data[piForm.addressForm.form]?.data[piForm.addressForm.fields.companyInstitutionOrganization],
      this.submissionWorkflowService.formInfo?.value[piForm.form]
        .data[piForm.addressForm.form]?.data[piForm.addressForm.fields.country]
    ];
    if (allValues.every((value) => Boolean(value) === false)) {
      return false;
    }

    return true;
  }
}
