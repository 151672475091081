import { UtilService } from './../../../../../services/util.service';
import { debounce } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { GridOptionsSearchModel } from 'src/app/admin/shared/model/grid.model';
import { DocumentTypeService } from 'src/app/admin/shared/services/document-type.service';
import { DocumentTypeGridModel, DocumentTypeCreateOrUpdateModel, DocumentTypeModel } from 'src/app/admin/shared/model/document-type.models';
import { IdValueModel } from 'src/app/admin/shared/model/sharedmodels';
import { BaseComponent } from 'src/app/components/base/base.component';
import { DocumentCategoryService } from 'src/app/admin/shared/services/document-category.service';
import { DEBOUNCE_TIME } from 'src/app/common/collections';
import { timer } from 'rxjs';

@Component({
  selector: 'app-view-document-types',
  templateUrl: './view-document-types.component.html',
  styleUrls: ['./view-document-types.component.scss']
})
export class ViewDocumentTypesComponent extends BaseComponent implements OnInit {
  allData: DocumentTypeModel[] = [];
  categories: IdValueModel[];
  currentIdToEdit: string;
  currentPage = 1;
  currentPageSize = 10;
  documentTypesFiltered: DocumentTypeGridModel[] = [];
  errorMessage = '';
  isShowLoader = true;
  isUpdateDocumentType = false;
  modalTitle = '';
  newCategoryName = new FormControl('', Validators.required);
  newDocumentTypeDocumentTypeName = new FormControl('');
  newDocumentTypeIsOcr = new FormControl(false);
  newDocumentTypeIsSubmissionDocument = new FormControl(false);
  searchValue = new FormControl('');
  showModal = false;
  totalPages: number;
  totalRecords: number;

  public headers = [
    {
      name: 'Document Type Name',
      field: 'typeName'
    },
    {
      name: 'Document Type Category',
      field: 'categoryName'
    },
    {
      name: 'Submission Document',
      field: 'isSubmissionDocumentMarkUp'
    },
    {
      name: 'OCR',
      field: 'isOcrMarkUp'
    }
  ];

  editableRow: any = {
    record: {}
  };

  options = {
    manualPaginationAndSort: true
  };

  private gridOptions: GridOptionsSearchModel = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortOptions: [],
    filterOptions: [],
    startDate: null,
    endDate: null
  };

  constructor(
    public utilService: UtilService,
    private documentTypesService: DocumentTypeService,
    private documentCategoryService: DocumentCategoryService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getGridDataByGridOptions();
    this.getDocumentTypeCategoriesList();

    this.searchValue.valueChanges
      .pipe(
        debounce(() => timer(DEBOUNCE_TIME))
      )
      .subscribe((val) => {
        this.gridOptions.searchTerm = val;
        this.gridOptions.page = 1;
        this.getGridDataByGridOptions();
      });
  }

  public save(): void {
    this.isShowLoader = true;

    if (this.newDocumentTypeDocumentTypeName.value === '') {
      this.isShowLoader = false;

      return;
    }

    const documentTypeName = this.newDocumentTypeDocumentTypeName.value;
    const documentTypeCategory = this.categories.find((category) => category.id === this.newCategoryName.value);
    const documentTypeIsOcr = this.newDocumentTypeIsOcr.value;
    const documentTypeIsSubmissionDocument = this.newDocumentTypeIsSubmissionDocument.value;

    let documentType: DocumentTypeCreateOrUpdateModel;
    if (this.isUpdateDocumentType) {
      documentType = {
        id: this.editableRow.id,
        documentType: documentTypeName,
        documentTypeCategory: documentTypeCategory,
        isOcr: documentTypeIsOcr,
        isSubmissionDocument: documentTypeIsSubmissionDocument
      };
    }
    else {
      documentType = {
        id: null,
        documentType: documentTypeName,
        documentTypeCategory: documentTypeCategory,
        isOcr: documentTypeIsOcr,
        isSubmissionDocument: documentTypeIsSubmissionDocument
      };
    }

    this.documentTypesService.createOrUpdateDocumentType(documentType).subscribe(() => {
      this.getGridDataByGridOptions();
      this.showModal = false;
      this.isShowLoader = false;
    },
    (error) => {
      this.showModal = false;
      this.isShowLoader = false;
      this.errorMessage = error.error.message;
    });
  }

  public createDocumentType(): void {
    this.errorMessage = '';
    this.modalTitle = 'Add Document Type';
    this.newDocumentTypeIsSubmissionDocument = new FormControl(false);
    this.newDocumentTypeIsOcr = new FormControl(false);
    this.newDocumentTypeDocumentTypeName = new FormControl('');
    this.newCategoryName = new FormControl('', Validators.required);
    this.isUpdateDocumentType = false;

    this.openModal();
  }

  handleRowClicked(event): void {
    if (event.detail.event.target.hasAttribute('edit-row-button') ||
    event.detail.event.target.parentElement.hasAttribute('edit-row-button')) {
      this.errorMessage = '';
      this.modalTitle = 'Edit Document Type';
      this.editableRow = event.detail;
      this.editDocumentType();
    }
  }

  handleChange(event): void {
    this.newCategoryName.setValue(event.target.value);
  }

  handlePageChange(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;
    this.gridOptions.page = event.detail.page;
    this.gridOptions.pageSize = event.detail.pageSize;
    this.getGridDataByGridOptions();
  }

  private editDocumentType(): void {
    this.openModal();
    const documentTypeToEdit = this.allData.find((documentType) => documentType.id === this.editableRow.id);
    this.isUpdateDocumentType = true;

    this.newDocumentTypeIsSubmissionDocument = new FormControl(documentTypeToEdit.isSubmissionDocument);
    this.newDocumentTypeIsOcr = new FormControl(documentTypeToEdit.isOcr);
    this.newDocumentTypeDocumentTypeName = new FormControl(documentTypeToEdit.value);
    let currentCategoryId = '';
    if (documentTypeToEdit.category) {
      currentCategoryId = this.categories.find((category) => documentTypeToEdit.category.id === category.id).id;
    }
    this.newCategoryName.setValue(currentCategoryId);
  }

  private openModal(): void {
    if (!this.categories) {
      this.getDocumentTypeCategoriesList();
    }
    this.showModal = true;
  }

  private getDocumentTypeCategoriesList(): void {
    this.documentCategoryService.getDocumentTypeCategories().subscribe((result) => {
      this.categories = result;
    },
    (error) => {
      this.isShowLoader = false;
      this.errorMessage = error.error.message;
    });
  }

  private fillGrid(dataToShow: DocumentTypeModel[]): void {
    this.documentTypesFiltered = dataToShow.map((dType) => {
      const highlightDocumentType = this.utilService.highlightSearchTerm(dType.value, this.searchValue.value);
      const documentTypeNameMarkUp = `<button title="${dType.value}" class="wcg-link" edit-row-button>${highlightDocumentType}</button>`;
      const isOrc = dType.isOcr ? '<wcg-icon src="./assets/icons/action/ic_done_24px.svg"></wcg-icon>' : '';
      const isSubmissionDocument = dType.isSubmissionDocument ? '<wcg-icon src="./assets/icons/action/ic_done_24px.svg"></wcg-icon>' : '';

      const categoryName = dType.category ? dType.category.value : '';

      const documentTypeGridModel: DocumentTypeGridModel = {
        id: dType.id,
        typeName: documentTypeNameMarkUp,
        categoryName: this.utilService.highlightSearchTerm(categoryName, this.searchValue.value),
        isSubmissionDocumentMarkUp: isSubmissionDocument.toString(),
        isOcrMarkUp: isOrc.toString()
      };

      return documentTypeGridModel;
    });
  }

  private getGridDataByGridOptions(): void {
    this.isShowLoader = true;

    this.documentTypesService.getDocumentTypes(this.gridOptions).subscribe((result) => {
      this.totalRecords = result.totalRecords;
      this.currentPage = result.currentPage;
      this.allData = result.records;
      this.isShowLoader = false;
      this.fillGrid(this.allData);
    },
    (error) => {
      this.errorMessage = error.error.message;
      this.isShowLoader = false;
    });
  }
}
