<wcg-loader [show]="fileUploadArray.length || showRemoveLoader"></wcg-loader>
<app-workflow-container
  hasFooter="true"
  [hasNav]="true"
  [header]="'submissionLanding.headerTitle' | translate"
  [save]="saveState"
  [uploadStatus]="submissionWorkflowService.submissionDocumentStatus.value.status | translate"
  [uploadStatusClass]="submissionWorkflowService.submissionDocumentStatus.value.statusClass">
  <close-button>
    <button class="wcg-btn wcg-btn-icon" (click)="this.submissionWorkflowService.closeWorkflow()">
      <wcg-icon src="../../assets/icons/navigation/ic_close_24px.svg"></wcg-icon>
      <span class="wcg-visually-hidden">{{ 'submissionWorkflow.close' | translate }}</span>
    </button>
  </close-button>
  <nav-content>
    <app-form-nav-item *ngFor="let navItem of submissionWorkflowService.formPages | async; let idx = index" [visible]="navItem.visible">
      <a routerLink=".." [queryParams]="{
      referralPage: 'upload',
      formStep: navItem.step
      }" class="cnxs-submission-step-link">
        <span class="cnxs-submission-step-details wcg-h4">{{navItem.title}}</span>
        <span class="cnxs-submission-status cnxs-status-{{navItem.statusClass}}">
          <wcg-icon
            class="cnxs-status-check"
            src="../assets/icons/check.svg"></wcg-icon>
          <span class="wcg-visually-hidden">{{ navItem.status | translate }}</span>
        </span>
      </a>
    </app-form-nav-item>
  </nav-content>

  <upload-step>
    <div class="cnxs-submission-step-link">
      <span class="cnxs-submission-step-details wcg-h4">{{ 'submissionWorkflow.submissionDocuments' | translate }}</span>

      <span class="cnxs-submission-status cnxs-status-{{ submissionWorkflowService.submissionDocumentStatus.value.statusClass }}">
        <wcg-icon
          class="cnxs-status-check"
          src="../assets/icons/check.svg"></wcg-icon>
        <span class="wcg-visually-hidden">{{ submissionWorkflowService.submissionDocumentStatus.value.status | translate }}</span>
      </span>
    </div>
  </upload-step>

  <review-step>
    <button
      [ngClass]="{ 'cnxs-nav-disabled' : submissionWorkflowService.reviewDisabled }"
      class="wcg-btn wcg-btn-fluid"
      (click)="continueClick()">
      {{ 'submissionWorkflow.review' | translate }}
    </button>
  </review-step>

 <main-content>
    <section class="wcg-container">
      <div class="wcg-row wcg-rhythm-12">
        <div class="wcg-col-xs-3 document-upload-headings">
          <h2 class="wcg-h5">{{ 'documentUpload.submissionDocuments' | translate }}</h2>
          <h3 class="wcg-h2">
            {{ 'documentUpload.documentUploadHeading' | translate }}
          </h3>
          <p>
            {{ 'documentUpload.documentUploadDescription' | translate }}
          </p>
          <p *ngIf="isUploadForm">
            {{'documentUpload.documentRequired' | translate}}
          </p>
        </div>
      </div>

      <hr>

      <div class="wcg-row document-upload">
        <div class="wcg-col-xs-4 cnxs-document-checklist">
          <h2 class="wcg-h3">
            {{ 'documentUpload.documentChecklistHeading' | translate }}
          </h2>
          <hr>
          <div id="formIODoc" class="formio-container"></div>
        </div>
        <div class="wcg-col-xs-4">
          <div class="info-header">
            <h3 class="wcg-h3">{{'documentUpload.documents' | translate}}</h3>
            <span>
              <p>{{'documentUpload.whatToUpload' | translate}}</p>
              <wcg-tooltip>
                <wcg-tooltip-trigger>
                  <wcg-icon src="../assets/icons/action/ic_info_24px.svg"></wcg-icon>
                </wcg-tooltip-trigger>
                <wcg-tooltip-content>{{'documentUpload.fileTypes' | translate}}</wcg-tooltip-content>
              </wcg-tooltip>
            </span>
          </div>
          <form>
            <wcg-form-field class="cnxs-file-upload">
              <wcg-file-upload multiple (change)="handleChange($event)" (error)="handleFileUploadError($event)">
                {{ 'documentUpload.uploadFileMessage1' | translate }} <span
                  class="click-to-upload">{{'documentUpload.uploadFileMessage2' | translate}}</span>
                <br>
                {{'documentUpload.fileSize' | translate}}
              </wcg-file-upload>
            </wcg-form-field>
            <div *ngIf="submissionWorkflowService.filesData$.value.length">
              <button [disabled]="isSelectedBtnDisabled" type="button" class="wcg-link fileUpload-table-buttons"
                (click)="removeSelectedFiles()">
                {{'documentUpload.removeSelectedButton' | translate }}
              </button>
              <wcg-table class="wcg-rhythm-4" id="fileUploadTable"
                [headers]="headers"
                [data]="fileUploadData"
                [options]="options"
                (rowClicked)="handleRowClickedEvents($event)"
                (rowSelected)="handleRowSelected($event)">
              </wcg-table>
            </div>
          </form>
        </div>
      </div>

      <wcg-modal id="removeModal" [show]="showRemoveModal" (close)="showRemoveModal = false">
        <wcg-modal-header>{{ 'documentUpload.deleteFileHeading' | translate }}</wcg-modal-header>
        <wcg-modal-body>
          <p>{{ 'documentUpload.selectedFilesMessage' | translate }}</p>

          <p class="existing-file-message">
            <span class="cnxs-duplicate-file-name" *ngFor="let item of filesToRemove">
              {{item.document?.fileName}}
            </span>
          </p>
        </wcg-modal-body>
        <wcg-modal-footer>
          <button class="wcg-btn wcg-btn-auto" (click)="modalCancelClick()">{{'actions.cancel' | translate}}</button>
          <button class="wcg-btn wcg-btn-auto wcg-btn-primary"
            (click)="removeFilesFromStorage()">{{'actions.delete' | translate}}</button>
        </wcg-modal-footer>
      </wcg-modal>

      <wcg-modal id="fileExistModal" [show]="showFileExistModal" (close)="showFileExistModal = false">
        <wcg-modal-header></wcg-modal-header>
        <wcg-modal-body>
          <p class="existing-file-message">
            {{'documentUpload.fileExists' | translate}}
          </p>

          <p class="existing-file-message">
            <span class="cnxs-duplicate-file-name" *ngFor="let item of duplicateFiles">
              {{item.name}}
            </span>
          </p>
        </wcg-modal-body>
        <wcg-modal-footer>
          <button class="wcg-btn wcg-btn-auto wcg-btn-primary"
            (click)="replaceFile()">{{ 'documentUpload.replaceFile' | translate }}</button>
          <button class="wcg-btn wcg-btn-auto" (click)="skipFile(duplicateFiles)">{{ 'documentUpload.skipFile' | translate }}</button>
        </wcg-modal-footer>
      </wcg-modal>
    </section>
  </main-content>

  <footer-content>
    <div class="cnxs-form-footer-content">
      <a class="wcg-link cnxs-arrow-btn cnxs-prev"
        [routerLink]="['/submission-workflow/details']"
        [queryParams]="{
          referralPage: 'upload',
          formStep: submissionWorkflowService.formPages.value.length - 1
        }">
          <wcg-icon class="cnxs-arrow cnxs-back-arrow" src="../assets/icons/navigation/ic_arrow_back_24px.svg"></wcg-icon>
          {{ prevPageName }}
      </a>
      <button [ngClass]="{ 'cnxs-nav-disabled' : submissionWorkflowService.reviewDisabled }" class="wcg-link cnxs-arrow-btn cnxs-next" (click)="continueClick()">
        {{ 'submissionWorkflow.review' | translate }}
        <wcg-icon class="cnxs-arrow cnxs-next-arrow" src="../assets/icons/navigation/ic_arrow_forward_24px.svg"></wcg-icon>
      </button>
    </div>
  </footer-content>
</app-workflow-container>
