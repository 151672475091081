/* eslint-disable class-methods-use-this */
/* eslint-disable id-length */
import { Injectable } from '@angular/core';
import {
  UserWorkspaceGridRecord,
  UserWorkspaceGridRecordMap,
  UserInfoDetailsMap
} from '../../interfaces/user';
import { FormGroup } from '@angular/forms';
import { PermissionType, PermissionTypeViewValues } from '../../common/collections';
import { UtilService } from '../../services/util.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public searchTerm = new BehaviorSubject('');

  constructor(private utilService: UtilService) {}

  public filter = (data: Array<UserWorkspaceGridRecordMap>, term: string): Array<UserWorkspaceGridRecordMap> => {
    const termsArray = term.split(' ');

    return data.filter((record) => {
      const keys = Object.keys(record);

      const found = keys.filter((key) => {
        if (record[key]) {
          return termsArray.some((currentTerm) => record[key].toLowerCase().includes(currentTerm.toLowerCase()));
        }

        return false;
      });

      if (found.length) {
        return {
          'IRB Tracking ID': record['IRB Tracking ID'],
          'Sponsor Protocol ID': record['Sponsor Protocol ID'],
          'PI Name': record['PI Name'],
          Permissions: record.Permissions
        };
      }

      return null;
    });
  };

  public mapProfileGridData(data: Array<UserWorkspaceGridRecord>): Array<UserWorkspaceGridRecordMap> {
    return data.map((record) => ({
      'IRB Tracking ID': this.utilService.addTableCellWrapperWithSearchTerms(record.irbTrackingNumber, this.searchTerm.value),
      'Sponsor Protocol ID': this.utilService.addTableCellWrapperWithSearchTerms(record.protocolNumber, this.searchTerm.value),
      'PI Name': this.utilService.addTableCellWrapperWithSearchTerms(record.principalInvestigator, this.searchTerm.value),
      Permissions: this.utilService.addTableCellWrapperWithSearchTerms(PermissionTypeViewValues[PermissionType[record.permissionType]],
        this.searchTerm.value)
    }));
  }

  // eslint-disable-next-line complexity
  public mapUserDataToProfile(formData: FormGroup, user: UserInfoDetailsMap): void {
    formData.setValue(
      {
        firstName: user.firstName,
        middleName: user.middleName,
        lastName: user.lastName,
        prefix: user.prefix,
        suffix: user.suffix,
        degrees: user.degrees,
        phone: user.phone,
        email: user.email,
        addressLine1: user.address?.addressLine1 || '',
        addressLine2: user.address?.addressLine2 || '',
        city: user.address?.city || '',
        postalCode: user.address?.postalCode || '',
        organization: {
          value: user.organization?.name,
          data: user.organization,
          selectedValues: []
        },
        country: user.address?.country?.id || '',
        // String with a space to pass required field validation
        state: user.address?.state?.id || ' ',
        province: user.address?.province || ' ',
        isEmailNotificationEnabled: user.isEmailNotificationEnabled
      }
    );
  }
}
