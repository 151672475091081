<div [ngClass]="{
  'cnxs-submission-card-view': card,
  'cnxs-submission-list-view': !card
}">
  <div class="cnxs-submission-list-inner">
    <div class="wcg-row cnxs-list-view-only cnxs-submission-list-view-header">
      <div class="wcg-col-xs-1 wcg-col-md-2 wcg-col-lg-3">{{'dashboard.submissionListHeader1' | translate}}</div>
      <div class="wcg-col-xs-1 wcg-col-md-2 wcg-col-lg-2">{{'dashboard.submissionListHeader2' | translate}}</div>
      <div class="wcg-col-xs-1 wcg-col-md-2 wcg-col-lg-4">{{'dashboard.submissionListHeader3' | translate}}</div>
    </div>

    <ul class="wcg-row cnxs-submissions-list">
      <li class="wcg-col-xs-4 cnxs-submission" [ngClass]="{
        'wcg-col-md-4 wcg-col-lg-4': card
      }" *ngFor="let submission of submissions">
        <app-submission-info
          [submission]="submission"
          [card]="card"
          [showPIOnCard]="true"
          (viewAllPIsClicked)="onViewAllPIsClickedEvent(submission)"
        >

        <div meta>
          <div *ngIf="submission?.tab === 'drafts'" class="cnxs-submission-list-drafts">
            <ng-container *ngTemplateOutlet="dateStarted"></ng-container>
            <ng-container *ngTemplateOutlet="estimatedOutcomeDate"></ng-container>
          </div>

          <div *ngIf="submission?.tab === 'inProgress' || submission?.tab === 'needsAction'">
            <div class="cnxs-submission-dates">
              <ng-container *ngTemplateOutlet="holdDate"></ng-container>

              <span *ngIf="!submission?.onHold">
                <ng-container *ngTemplateOutlet="outcomeDate"></ng-container>

                <span *ngIf="!submission?.outcomeDate">
                  <ng-container *ngTemplateOutlet="dateSubmitted"></ng-container>
                  <ng-container *ngTemplateOutlet="estimatedOutcomeDate"></ng-container>
                </span>
              </span>
            </div>

            <ng-container *ngTemplateOutlet="statusTracker" class="cnxs-status-tracker"></ng-container>

            <ng-container *ngTemplateOutlet="hold"></ng-container>

            <span *ngIf="!submission?.onHold">
              <ng-container *ngTemplateOutlet="outcome"></ng-container>
            </span>
          </div>
      </div>

        <div actions class="cnxs-submission-actions">
          <div *ngIf="submission?.status === 'Draft'" class="cnxs-submission-list-drafts">
            <div class="cnxs-submission-action">
              <a routerLink="/submission-workflow/details/"
                [queryParams]="{
                  submissionId: submission?.id,
                  referralPage: 'review',
                  returnToDraft: 'true'
                }" [ngClass]="{
                  'wcg-btn wcg-btn-primary wcg-btn-fluid': card,
                  'wcg-link': !card
                }">
                {{'actions.continue' | translate}}

                <wcg-icon *ngIf="!card" class="cnxs-submission-action-icon" src="./assets/icons/navigation/ic_arrow_forward_24px.svg"></wcg-icon>
              </a>
            </div>

            <div class="cnxs-submission-action">
              <button (click)="deleteSubmission(submission?.id)" [ngClass]="{
                'wcg-btn wcg-btn-secondary wcg-btn-fluid cnxs-has-icon': card,
                'wcg-link': !card
              }">
                <wcg-icon *ngIf="card" class="cnxs-submission-action-icon" src="./assets/icons/action/ic_delete_forever_24px.svg"></wcg-icon>

                {{'actions.delete' | translate}}

                <wcg-icon *ngIf="!card" class="cnxs-submission-action-icon-secondary" src="./assets/icons/action/ic_delete_forever_24px.svg"></wcg-icon>

              </button>
            </div>
          </div>

          <div class="cnxs-submission-action" *ngIf="submission?.status !== 'Draft'">
            <a *ngIf="!submission?.hasOutcomeDocuments" routerLink="/submissions/{{submission?.id}}" [ngClass]="{
              'wcg-btn wcg-btn-primary wcg-btn-fluid': card,
              'wcg-link': !card
            }">
              {{'actions.viewSubmission' | translate}}

              <wcg-icon *ngIf="!card" class="cnxs-submission-action-icon" src="./assets/icons/navigation/ic_arrow_forward_24px.svg"></wcg-icon>
            </a>

            <a *ngIf="submission?.hasOutcomeDocuments" routerLink="/submissions/{{submission?.id}}/outcome-documents"
              [ngClass]="{
                'wcg-btn wcg-btn-primary wcg-btn-fluid': card,
                'wcg-link': !card
              }">
              {{'actions.viewOutcomeDocuments' | translate}}

              <wcg-icon *ngIf="!card" class="cnxs-submission-action-icon" src="./assets/icons/navigation/ic_arrow_forward_24px.svg"></wcg-icon>
            </a>
          </div>
        </div>

        <ng-template #dateStarted>
          <p class="cnxs-submission-meta-content" *ngIf="submission?.createdDate">
            <span class="wcg-body-label cnxs-submission-meta-title">{{'labels.dateStarted' | translate}}:</span>
            <span class="wcg-body-sm">{{submission?.createdDate}}</span>
          </p>
        </ng-template>
        <ng-template #dateSubmitted>
          <p class="cnxs-submission-meta-content" *ngIf="submission?.dateSubmitted">
            <span class="wcg-body-label cnxs-submission-meta-title">{{'labels.dateSubmitted' | translate}}:</span>
            <span class="wcg-body-sm">{{submission?.dateSubmitted}}</span>
          </p>
        </ng-template>
        <ng-template #estimatedOutcomeDate>
          <p class="cnxs-submission-meta-content" *ngIf="submission?.estimatedOutcomeDate">
            <span class="wcg-body-label cnxs-submission-meta-title">{{'labels.estimatedOutcomeDate' | translate}}:</span>
            <span class="wcg-body-sm">{{submission?.estimatedOutcomeDate}}</span>
          </p>
        </ng-template>
        <ng-template #hold>
          <p class="cnxs-submission-meta-content" *ngIf="submission?.onHold && submission?.holdReason">
            <span class="wcg-body-label cnxs-submission-meta-title">{{'labels.hold' | translate}}:</span>
            <span class="wcg-body-sm" [innerHTML]="submission?.holdReason"></span>
          </p>
        </ng-template>
        <ng-template #holdDate>
          <p class="cnxs-submission-meta-content" *ngIf="submission?.onHold && submission?.holdDate">
            <span class="wcg-body-label cnxs-submission-meta-title">{{'labels.holdDate' | translate}}:</span>
            <span class="wcg-body-sm">{{submission?.holdDate}}</span>
          </p>
        </ng-template>
        <ng-template #outcome>
          <p class="cnxs-submission-meta-content" *ngIf="submission?.outcome">
            <span class="wcg-body-label cnxs-submission-meta-title">{{'labels.outcome' | translate}}:</span>
            <span class="wcg-body-sm">{{submission?.outcome}}</span>
          </p>
        </ng-template>
        <ng-template #outcomeDate>
          <p class="cnxs-submission-meta-content" *ngIf="submission?.outcomeDate">
            <span class="wcg-body-label cnxs-submission-meta-title">{{'labels.outcomeDate' | translate}}:</span>
            <span class="wcg-body-sm">{{submission?.outcomeDate}}</span>
          </p>
        </ng-template>

        <ng-template #statusTracker>
          <div *ngIf="!card">
            <app-mini-status-tracker
              [status]="submission?.status"
              [onHold]="submission?.onHold"
              [outcome]="submission?.outcome">
            </app-mini-status-tracker>
          </div>
        </ng-template>
      </app-submission-info>
      </li>
    </ul>
  </div>
</div>

<wcg-modal [show]="showModal" (close)="showModal = false" class="delete-modal">
    <wcg-modal-header>
        <p>
          {{'deleteDraft.modalTitle' | translate}}
        </p>
    </wcg-modal-header>
    <wcg-modal-body>
        <div>{{'deleteDraft.modalMessage' | translate}}</div>
    </wcg-modal-body>
    <wcg-modal-footer>
      <button class="wcg-btn wcg-btn-auto" (click)="showModal = false">{{'deleteDraft.keepDraft' | translate}}</button>
      <button class="wcg-btn wcg-btn-auto wcg-btn-primary" (click)="dashboardService.deleteDraft(submissionIdToBeDeleted); showModal = false" >
        {{'actions.deleteDraft' | translate}}
         </button>
    </wcg-modal-footer>
</wcg-modal>

<wcg-modal [show]="showPIModal" (close)="showPIModal = false" class="pi-modal">
  <wcg-modal-header>{{'submissionDetails.principalInvestigators' | translate}} ({{submission?.principalInvestigators?.length}})</wcg-modal-header>
  <wcg-modal-body>
    <h2 class="wcg-h3 cnxs-study-name wcg-rhythm-1">
      {{ submission?.submissionName }}
    </h2>
    <h3 class="wcg-h3 cnxs-sponsor-id wcg-rhythm-5">
      {{'sites.sponsorId' | translate}}: {{submission?.sponsorId}}
    </h3>

    <form class="wcg-rhythm-5">
      <wcg-form-field>
        <wcg-label>{{ 'actions.filter' | translate }}</wcg-label>
        <wcg-input [formControl]="searchTerm" [value]="searchTerm.value" ngDefaultControl>
          <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
        </wcg-input>
      </wcg-form-field>
    </form>

   <wcg-table
      [headers]="piTableheaders"
      [data]="filteredPIs"
      [options]="tableOptions"
    >
    </wcg-table>
  </wcg-modal-body>
  <wcg-modal-footer>
    <button class="wcg-btn wcg-btn-primary" (click)="showPIModal = false">{{'actions.close' | translate}}</button>
  </wcg-modal-footer>
</wcg-modal>
