<section class="wcg-container">
  <div class="wcg-row wcg-rhythm-2">
    <div class="wcg-col-xs-2">
      <h1 class="wcg-h1">
        {{ 'resources.resources' | translate }}
      </h1>
      <h1 class="wcg-h2 user-guide-heading">
        {{ 'resources.userGuideHeading' | translate }}
      </h1>
      <p>
        {{ 'resources.userGuideDescription' | translate }}
      </p>
    </div>
  </div>

  <button (click)="onResourceClick(userGuide.storagePath)" class="wcg-btn wcg-btn-large wcg-rhythm-4">
    <wcg-icon src="../assets/icons/action/ic_get_app_24px.svg"></wcg-icon>
    {{ 'resources.userGuideDownload' | translate }}
  </button>


  <hr class="wcg-rhythm-4">

  <h2 class="wcg-h2 wcg-rhythm-1">
    {{ 'resources.howToHeading' | translate }}
  </h2>
  <p class="wcg-rhythm-3">
    {{ 'resources.howToDescription' | translate }}
  </p>

  <div class="wcg-row wcg-rhythm-4">
    <div class="wcg-col-xs-4 wcg-col-md-4 wcg-col-lg-4" *ngFor="let video of videos">
      <h3 class="wcg-h3">{{ video.name }}</h3>
      <a class="image-pointer" (click)="onResourceClick(video.storagePath)" >
        <img [src]="video.videoPreviewImagePath" [alt]="video.videoPreviewImageDescription" />
      </a>
      <p>{{ video.description }}</p>
    </div>
  </div>

  <hr class="wcg-rhythm-4">

  <h2 class="wcg-h2 wcg-rhythm-1">
    {{ 'resources.documentsHeading' | translate }}
  </h2>
  <p class="wcg-rhythm-3">
    {{ 'resources.documentsDescription' | translate }}
  </p>

  <div class="wcg-row wcg-rhythm-4 document-row" *ngFor="let document of documents; let idx = index">
    <div class="wcg-col-xs-2">
      <span class="document-name">{{ document.name }}</span>
    </div>
    <div class="wcg-col-xs-2">
      <button (click)="onResourceClick(document.storagePath, idx)" [disabled]="isDownloading[idx]" class="wcg-btn wcg-btn-medium user-guide-download-btn">
        <wcg-icon src="../assets/icons/action/ic_get_app_24px.svg"></wcg-icon>
        {{ 'resources.documentsDownload' | translate }}
        <wcg-loader [show]="isDownloading[idx]"></wcg-loader>
      </button>
    </div>
  </div>
  <div class="wcg-row wcg-rhythm-4 irb-link">
    <div class="wcg-col-xs-2">
      <h1 class="wcg-h2">
        IRB Link
      </h1>
      <a class="wcg-link" href="https://www.wcgclinical.com/solutions/irb-review/" target="_blank">Click here for IRB Website</a><br>
      <a class="wcg-link" href="https://www.wcgclinical.com/irb-resources/" target="_blank">Click here for IRB Resource Center</a>
    </div>
  </div>
</section>
