import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { dashboardRoutes } from './routes/dashboard.routes';
import { defaultRoutes } from './routes/default.routes';
import { profileRoutes } from './routes/profile.routes';
import { resourcesRoutes } from './routes/resources.routes';
import { siteRoutes } from './routes/site.routes';
import { studyRoutes } from './routes/study.routes';
import { submissionRoutes } from './routes/submission.routes';
import { submissionWorkflowRoutes } from './routes/submission-workflow.routes';
import { teamRoutes } from './routes/team.routes';
import { downloadExportsRoutes } from './routes/download-exports.routes';

const routes: Routes = [
  ...dashboardRoutes,
  ...defaultRoutes,
  ...profileRoutes,
  ...resourcesRoutes,
  ...siteRoutes,
  ...studyRoutes,
  ...submissionRoutes,
  ...submissionWorkflowRoutes,
  ...teamRoutes,
  ...downloadExportsRoutes,
  {
    canActivate: [
      AuthGuard
    ],
    path: '**',
    redirectTo: 'page-not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
