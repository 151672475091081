<app-workflow-container
  [hidden]="hideForm"
  hasFooter="true"
  [hasNav]="hasNav && !returnToDraft"
  [header]="'submissionLanding.headerTitle' | translate"
  [save]="saveState"
>
  <close-button class="close-button-size">
    <button class="wcg-btn wcg-btn-icon" (click)="submissionWorkflowService.closeWorkflow()">
      <wcg-icon src="../../assets/icons/navigation/ic_close_24px.svg"></wcg-icon>
      <span class="wcg-visually-hidden">{{ 'submissionWorkflow.close' | translate }}</span>
    </button>
  </close-button>

  <nav-content *ngIf="hasNav && submissionWorkflowService.formPages.value.length >= 0 && !returnToDraft">
    <app-form-nav-item
      *ngFor="let navItem of submissionWorkflowService.formPages | async; trackBy: trackNavChanges"
      [active]="navItem.active"
      [visible]="navItem.visible">
      <button (click)="navItemClick(navItem.step, navItem.disabled)" class="cnxs-submission-step-link" [ngClass]="{ 'cnxs-nav-disabled' : navItem.disabled }">
        <span class="cnxs-submission-step-details wcg-h4">{{navItem.title}}</span>

        <span class="cnxs-submission-status cnxs-status-{{navItem.statusClass}}">
          <wcg-icon
            class="cnxs-status-check"
            src="../assets/icons/check.svg"></wcg-icon>
          <span class="wcg-visually-hidden">{{ navItem.status | translate }}</span>
        </span>
      </button>
    </app-form-nav-item>
  </nav-content>

  <upload-step>
    <button (click)="continueClick(formRequiredNotMet)" class="cnxs-submission-step-link" [ngClass]="{ 'cnxs-nav-disabled' : formRequiredNotMet }">
      <span class="cnxs-submission-step-details wcg-h4">{{ 'submissionWorkflow.submissionDocuments' | translate }}</span>

      <span class="cnxs-submission-status cnxs-status-{{ submissionWorkflowService.submissionDocumentStatus.value.statusClass }}">
        <wcg-icon
          class="cnxs-status-check"
          src="../assets/icons/check.svg"></wcg-icon>
        <span class="wcg-visually-hidden">{{ submissionWorkflowService.submissionDocumentStatus.value.status | translate }}</span>
      </span>
    </button>
  </upload-step>

  <review-step>
    <button
      [ngClass]="{ 'cnxs-nav-disabled' : submissionWorkflowService.reviewDisabled || formRequiredNotMet }"
      class="wcg-btn wcg-btn-fluid"
      (click)="goToReview()">
      {{ 'submissionWorkflow.review' | translate }}
    </button>
  </review-step>

  <main-content>
    <wcg-loader [show]="!formReady"></wcg-loader>

    <div id="formIO" class="formio-container" [ngClass]="{
      'cnxs-visible': formReady,
      'cnxs-hidden': returnToDraft
    }" *ngIf="formSRCPath">
      <formio
        [src]="formSRCPath"
        [submission]="submissionData"
        (ready)="setUpForm($event)"
        (change)="onFormChange($event)"></formio>
    </div>

    <div *ngIf="returnToDraft">
      <div class="cnxs-review-section cnxs-review-section-intro">
        <h2 class="wcg-h2">{{ 'submissionWorkflow.returnHeading' | translate }}</h2>

        <p *ngIf="loadingError">
          {{ 'submissionWorkflow.returnError' | translate }}<br />
          <a routerLink="/dashboard" class="wcg-btn wcg-btn-large cnxs-return-btn">{{ 'submissionWorkflow.returnToDashboard' | translate }}</a>
        </p>
      </div>

      <div class="cnxs-submission-info">
        <h3 class="wcg-h2">{{ 'submissionWorkflow.mySubmission' | translate }}</h3>

        <div class="wcg-row">
          <div class="wcg-col-xs-4 wcg-col-md-4 wcg-col-lg-5 wcg-col-xl-4">
            <app-submission-info [submission]="submission" [card]="true" [showPIOnCard]="false">
              <div meta>
                <p class="cnxs-submission-meta-content">
                  <span class="wcg-body-label cnxs-submission-meta-title">{{'submissionMeta.studyName' | translate}}:</span>
                  <span class="wcg-body-sm cnxs-submission-meta-value">{{ protocolInfo?.studyName || submissionWorkflowService.submissionFindStudy.value?.name }}</span>
                </p>

                <p class="cnxs-submission-meta-content" *ngIf="protocolInfo?.sponsor">
                  <span class="wcg-body-label cnxs-submission-meta-title">{{'submissionMeta.sponsor' | translate}}:</span>
                  <span class="wcg-body-sm cnxs-submission-meta-value">{{ protocolInfo?.sponsor }}</span>
                </p>

                <p class="cnxs-submission-meta-content" *ngIf="submissionWorkflowService.submissionStudySites.value?.length">
                  <span class="wcg-body-label cnxs-submission-meta-title">{{'submissionMeta.principalInvestigator' | translate}}:</span>
                  <span class="wcg-body-sm cnxs-submission-meta-pi" title="{{this.submissionWorkflowService.investigators.value}}">
                    <span *ngIf="submissionWorkflowService.submissionStudySites.value?.length === 1">
                      {{ submissionWorkflowService.submissionStudySites.value[0]?.investigator }}
                    </span>
                    <span class="cnxs-pi-wrap" *ngIf="submissionWorkflowService.submissionStudySites.value?.length > 1">
                      {{ 'submissionMeta.sites' | translate: { length: submissionWorkflowService.submissionStudySites.value?.length} }}
                      <button class="wcg-link" (click)="submissionWorkflowService.showPiModal.next(true)">
                        <wcg-icon class="cnxs-view-all-icon" src="../../assets/icons/action/ic_launch_24px.svg"></wcg-icon>
                        {{ 'submissionMeta.viewAll' | translate }}
                      </button>
                    </span>
                  </span>
                </p>

                <p class="cnxs-submission-meta-content" *ngIf="draftResponse.value.principalInvestigator?.firstName.trim() || draftResponse.value.principalInvestigator?.lastName.trim()">
                  <span class="wcg-body-label cnxs-submission-meta-title">{{'submissionMeta.principalInvestigator' | translate}}:</span>
                  <span class="wcg-body-sm">
                    {{draftResponse.value.principalInvestigator.firstName}} {{draftResponse.value.principalInvestigator.lastName}}
                  </span>
                </p>
              </div>

              <button actions
                class="wcg-btn wcg-btn-fluid wcg-btn-primary"
                [disabled]="submissionWorkflowService.consolidatedPdfDisabled"
                (click)="formioPDFService.downloadConsolidatedPdf()">
                {{'submissionActions.exportDraft' | translate}}
                <wcg-loader [show]="submissionWorkflowService.consolidatedPdfDisabled && formReady"></wcg-loader>
              </button>
            </app-submission-info>
          </div>

          <div class="wcg-col-xs-4 wcg-col-md-4 wcg-col-lg-5 wcg-col-xl-4">
            <app-estimated-outcome-card
              [estimatedOutcomeDate]="estimatedOutcomeDate">
            </app-estimated-outcome-card>
          </div>
        </div>
      </div>

      <div class="cnxs-review-section cnxs-checklist-section" *ngIf="formReady && !loadingError">
        <h3 class="wcg-h2">{{ 'submissionWorkflow.checklist' | translate }}</h3>

        <ul class="cnxs-submission-checklist cnxs-nav-visible">
          <app-form-nav-item *ngFor="let navItem of submissionWorkflowService.formPages.value; let idx = index" [visible]="navItem.visible">
            <button (click)="returnToDetails(idx, navItem.disabled)" class="cnxs-submission-step-link" [ngClass]="{ 'cnxs-nav-disabled' : navItem.disabled }">
              <span class="cnxs-submission-step-details wcg-h4">{{navItem.title}}</span>

              <span class="cnxs-submission-status cnxs-status-{{navItem.statusClass}}">
                <wcg-icon
                  class="cnxs-status-check"
                  src="../assets/icons/check.svg"></wcg-icon>
                <span class="wcg-visually-hidden">{{ navItem.status | translate }}</span>
              </span>
            </button>
          </app-form-nav-item>

          <app-form-nav-item visible="visible">
            <button (click)="goToUpload(formRequiredNotMet)" class="cnxs-submission-step-link" [ngClass]="{ 'cnxs-nav-disabled' : formRequiredNotMet }">
              <span class="cnxs-submission-step-details wcg-h4">{{ 'submissionWorkflow.submissionDocuments' | translate }}</span>

              <span class="cnxs-submission-status cnxs-status-{{ submissionWorkflowService.submissionDocumentStatus.value.statusClass }}">
                <wcg-icon
                  class="cnxs-status-check"
                  src="../assets/icons/check.svg"></wcg-icon>
                <span class="wcg-visually-hidden">{{ submissionWorkflowService.submissionDocumentStatus.value.status | translate }}</span>
              </span>
            </button>
          </app-form-nav-item>
        </ul>
      </div>
    </div>
  </main-content>

  <footer-content>
    <div class="cnxs-form-footer-content" *ngIf="!returnToDraft">
      <button class="wcg-link cnxs-arrow-btn cnxs-prev" [disabled]="!submissionWorkflowService.formReady" (click)="navItemClick(prevPage)" *ngIf="prevButton">
        <wcg-icon class="cnxs-arrow cnxs-back-arrow" src="../assets/icons/navigation/ic_arrow_back_24px.svg"></wcg-icon>
        {{ prevPageName }}
      </button>

      <button class="wcg-link cnxs-arrow-btn cnxs-next" [disabled]="!submissionWorkflowService.formReady" [ngClass]="{ 'cnxs-nav-disabled' : footerButtonDisabled }" (click)="navItemClick(nextPage, footerButtonDisabled)" *ngIf="nextButton">
        {{ nextPageName }}
        <wcg-icon class="cnxs-arrow cnxs-next-arrow" src="../assets/icons/navigation/ic_arrow_forward_24px.svg"></wcg-icon>
      </button>

      <button class="wcg-link cnxs-arrow-btn cnxs-next" [disabled]="!submissionWorkflowService.formReady" [ngClass]="{ 'cnxs-nav-disabled' : footerButtonDisabled }" (click)="continueClick(footerButtonDisabled)" *ngIf="documentsButton">
        {{ 'submissionWorkflow.submissionDocuments' | translate }}
        <wcg-icon class="cnxs-arrow cnxs-next-arrow" src="../assets/icons/navigation/ic_arrow_forward_24px.svg"></wcg-icon>
      </button>
    </div>

    <div class="cnxs-form-footer-content" *ngIf="returnToDraft">
      <a routerLink="/dashboard" class="wcg-link cnxs-arrow-btn cnxs-prev">
        <wcg-icon class="cnxs-arrow cnxs-back-arrow" src="../assets/icons/navigation/ic_arrow_back_24px.svg"></wcg-icon>
        {{ 'submissionActions.dashboard' | translate }}
      </a>

      <button [disabled]="!formReady" (click)="returnToDetails(0)" class="wcg-btn wcg-btn-primary wcg-btn-large">
        {{ 'submissionActions.return' | translate }}
      </button>
    </div>
  </footer-content>
</app-workflow-container>

<wcg-modal [show]="submissionWorkflowService.showPiModal.value" (close)="closePIModal()">
  <wcg-modal-header>{{'submissionDetails.principalInvestigators' | translate}} ({{submissionWorkflowService.submissionStudySites.value?.length}})</wcg-modal-header>
  <wcg-modal-body>
    <h2 class="wcg-h3 cnxs-study-name wcg-rhythm-1">
      {{ submission?.submissionName }}
    </h2>
    <h3 class="wcg-h3 cnxs-sponsor-id wcg-rhythm-5">
      {{'sites.sponsorId' | translate}}: {{ protocolInfo?.sponsorProtocolId }}
    </h3>

    <form class="wcg-rhythm-5">
      <wcg-form-field>
        <wcg-label>{{ 'actions.filter' | translate }}</wcg-label>
        <wcg-input [formControl]="searchTerm" [value]="searchTerm.value" ngDefaultControl>
          <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
        </wcg-input>
      </wcg-form-field>
    </form>

    <wcg-table
      [headers]="piTableheaders"
      [data]="filteredPIs"
      [options]="tableOptions"
    >
    </wcg-table>
  </wcg-modal-body>
  <wcg-modal-footer>
    <button class="wcg-btn wcg-btn-primary" (click)="closePIModal()">{{'actions.close' | translate}}</button>
  </wcg-modal-footer>
</wcg-modal>

<router-outlet></router-outlet>
