<app-workflow-container
  [isVisible]="true"
  [hasNav]="false"
  [header]="'submissionWorkflow.heading' | translate"
  hasFooter="true">

  <close-button>
    <button class="wcg-btn wcg-btn-icon" (click)="this.submissionWorkflowService.closeWorkflow()">
      <wcg-icon src="../../assets/icons/navigation/ic_close_24px.svg"></wcg-icon>
      <span class="wcg-visually-hidden">{{ 'submissionWorkflow.close' | translate }}</span>
    </button>
  </close-button>

  <main-content>
    <div class="wcg-row">
      <p class="wcg-h5 setup wcg-col-xs-4">{{ 'submissionWorkflow.setup' | translate }}</p>
      <span class="wcg-col-xs-4 wcg-col-lg-8"><h2 class="wcg-h2 header">{{ 'searchByIRB.header' | translate }}</h2></span>
    </div>
    <form ngForm>
      <div class="wcg-row wcg-no-gutter">
        <div class="wcg-col-xs-3 wcg-col-md-6 wcg-col-lg-10">
          <wcg-form-field>
            <wcg-label>{{ 'actions.findStudy' | translate }}</wcg-label>
            <wcg-input [formControl]="searchTerm" [value]="searchTerm.value">
              <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
            </wcg-input>
            <span class="wcg-body-label">{{ 'submissionWorkflow.enterIRBLabel' | translate }}</span>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-1 wcg-col-md-2 wcg-col-lg-2">
          <button class="wcg-btn wcg-btn-fluid wcg-btn-primary search-button" (click)="search()"
            [disabled]="searchTerm.invalid">{{ 'actions.search' | translate }}</button>
        </div>
      </div>
    </form>
    <div class="wcg-row">
      <dl *ngIf="foundStudy" class="wcg-rhythm-3 irb-result">
        <div class="wcg-row">
          <div class="wcg-col-xs-2 wcg-rhythm-3">
            <dt class="wcg-body-label">{{ 'submissionDetails.sponsor' | translate }}</dt>
            <dd>{{ foundStudy.sponsor }}</dd>
          </div>
          <div class="wcg-col-xs-2 wcg-rhythm-3">
            <dt class="wcg-body-label">{{ 'submissionDetails.sponsorProtocolNumber' | translate }}</dt>
            <dd>{{ foundStudy.protocol }}</dd>
          </div>
        </div>
      </dl>
    </div>
    <div *ngIf="!foundStudy && searched" class="wcg-rhythm-3 irb-result">
      <div class="wcg-row">
        <app-no-records>
          <p class="wcg-h2">{{ 'searchByIRB.noResults' | translate }}</p>
        </app-no-records>
      </div>
    </div>
  </main-content>

  <footer-content>
    <div class="cnxs-form-footer-content">
      <a class="wcg-link cnxs-arrow-btn cnxs-prev" routerLink="/submission-workflow/search-study">
        <wcg-icon class="cnxs-arrow cnxs-back-arrow" src="../assets/icons/navigation/ic_arrow_back_24px.svg"></wcg-icon>
        {{ 'submissionWorkflow.back' | translate }}
      </a>

      <button type="submit" class="wcg-link cnxs-arrow-btn cnxs-next" [disabled]="!foundStudy" (click)="continueClick()">
        {{ 'submissionWorkflow.continueSetup' | translate }}
        <wcg-icon class="cnxs-arrow cnxs-next-arrow" src="../assets/icons/navigation/ic_arrow_forward_24px.svg"></wcg-icon>
      </button>
    </div>
  </footer-content>
</app-workflow-container>
