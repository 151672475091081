import { UserManager, UserManagerSettings, WebStorageStateStore, User } from 'oidc-client';
import { Injectable } from '@angular/core';
import { Subject, from, Observable } from 'rxjs';
import { Authentication } from './authentication';
import { LoggerService } from 'src/app/services/logger.service';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage.service';
import { StorageModel, StorageKeys } from 'src/app/models/storage.model';
import { Router } from '@angular/router';

/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
@Injectable()
export class AuthenticationService extends Authentication {
  authState: any;

  private userManager: UserManager;
  private user: User;
  private authStateSubject = new Subject<boolean>();

  constructor(
    private router: Router,
    private loggerService: LoggerService,
    private storageService: StorageService
  ) {
    super();

    this.authState = this.authStateSubject.asObservable();
    this.currentUser = null;

    const settings: UserManagerSettings = {
      authority: environment.identity.authority,
      client_id: environment.identity.client_id,
      redirect_uri: `${environment.identity.redirect_uri}/callback`,
      silent_redirect_uri: `${environment.identity.redirect_uri}/silent-renew`,
      post_logout_redirect_uri: `${environment.identity.redirect_uri}/logout-callback`,
      response_type: 'id_token token',
      scope: 'openid profile wcg-irb-api wcg-irb-document-api',
      automaticSilentRenew: this.isIOSOrSafari() ? false : environment.identity.automaticSilentRenew,
      includeIdTokenInSilentRenew: environment.identity.includeIdTokenInSilentRenew,
      accessTokenExpiringNotificationTime: environment.identity.accessTokenExpiringNotificationTime,
      filterProtocolClaims: environment.identity.filterProtocolClaims,
      loadUserInfo: environment.identity.loadUserInfo,
      silentRequestTimeout: environment.identity.silentRequestTimeout,
      checkSessionInterval: environment.identity.checkSessionInterval,
      monitorSession: this.isIOSOrSafari() ? false : environment.identity.monitorSession,
      stateStore: new WebStorageStateStore({ store: window.localStorage })
    };

    this.userManager = new UserManager(settings);

    this.userManager.getUser().then((user) => {
      if (user && !user.expired) {
        this.user = user;
        this.authenticated = true;
      }
    });

    this.userManager.events.addAccessTokenExpired(() => {
      this.authStateSubject.next(false);
    });

    this.userManager.events.addUserLoaded((user) => {
      if (user !== this.user) {
        this.user = user;
        this.authStateSubject.next(Boolean(user) && !user.expired);
      }
    });

    this.userManager.events.addSilentRenewError((error) => {
      this.loggerService.log(`Error occured while trying to silently renew access token. Error: ${error}`);
    });

    this.userSignedOut();

    this.setKeysInStorage();
  }

  public autoLogin(): void {
    if (!window.location.hash) {
      this.storageService.setKey(new StorageModel(StorageKeys.redirect, window.location.pathname));
      this.router.navigate(['/autologin']);
    }
  }

  public login(): any {
    return this.userManager.signinRedirect();
  }

  public handleRedirectCallback(): any {
    return this.userManager.signinRedirectCallback().then((user) => {
      this.user = user;
      this.authStateSubject.next(Boolean(user) && !user.expired);
    });
  }

  public completeSilentLogin(): any {
    return this.userManager.signinSilentCallback().then((user) => {
      this.user = user;
      this.authStateSubject.next(Boolean(user) && !user.expired);
    });
  }

  public logout(): void {
    this.userManager.signoutRedirect();
  }

  public logoutForImpersonate(): void {
    this.userManager.removeUser();
    this.userManager.revokeAccessToken();
  }

  public completeLogout(): any {
    this.user = null;

    return this.userManager.signoutCallback().then(() => {
      this.userManager.clearStaleState();
      this.clearStorage();
      this.authStateSubject.next(false);
    });
  }

  public getUser(): User {
    return this.user;
  }

  public getAccessToken(): any {
    const user = this.getUser();
    if (user) {
      return user.access_token;
    }

    return from(this.userManager.getUser().then((usr: User) => usr ? usr.access_token : ''));
  }

  public isAuthenticated(): Observable<boolean> {
    return from(this.userManager.getUser().then((user) => {
      const authenticated = Boolean(user) && !user.expired;
      if (this.user !== user) {
        this.authStateSubject.next(authenticated);
      }
      this.user = user;

      return authenticated;
    }));
  }

  /* eslint-disable complexity */
  private setKeysInStorage(): void {
    if (window.location.pathname.length > 1 && window.location.pathname !== '/autologin' && window.location.pathname !== '/callback') {
      if (window.location.pathname.startsWith('/registration')) {
        this.storageService.setKey(new StorageModel(StorageKeys.registrationLinkKey, 'true'));
      }
    }
  }

  private clearStorage(): void {
    const keys = this.storageService.getAllKeys();
    for (let cnt = 0; cnt < keys.length; cnt++) {
      this.storageService.removeKey(keys[cnt]);
    }
  }

  private userSignedOut(): void {
    this.userManager.events.addUserSignedOut(() => {
      this.user = null;
      this.authenticated = false;
      this.authStateSubject.next(false);
    });
  }

  private isIOSOrSafari = (): boolean => {
    if ((/iPad|iPhone|iPod/).test(navigator.userAgent) && !window.MSStream) {
      return true;
    }
    else if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
      return true;
    }

    return false;
  }
}
