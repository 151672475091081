import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddWorkspaceTypeModal, WorkspaceType } from 'src/app/common/collections';
import { SiteTeams } from 'src/app/interfaces/sites';
import { WorkspaceAccessModel } from 'src/app/interfaces/workspaces';
import { WorkspaceOrganizationModel } from 'src/app/models/workspace-organization.model';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { ApiService } from 'src/app/services/api.service';
import { LoggerService } from 'src/app/services/logger.service';
import { StateService } from 'src/app/services/state.service';
import { ToastService } from 'src/app/services/toast.service';
import { TeamService } from 'src/app/views/team/team.service';

@Component({
  selector: 'app-team-workspace-request-modal',
  templateUrl: './team-workspace-request-modal.component.html',
  styleUrls: ['./team-workspace-request-modal.component.scss']
})
export class TeamWorkspaceRequestModalComponent implements OnInit {
  @Input() show: boolean;
  @Input() modalType: string;
  @Input() organizationId: string;
  @Input() isRequest: boolean;

  workspaceType: string;
  requestAccessForm: FormGroup;
  searchResultMessage = '';
  irbResultMessage = '';
  sitesListResultMessage = '';
  workspaceData: WorkspaceAccessModel;
  isMultipleChoices = false;
  showNoMatches = false;
  submitValid = false;
  invalidAttemptsData: any[] = [];

  showRequestAccessConfirmation = false;
  showWarning = false;
  showError = false;
  showRequestSent = false;
  showAlreadyExist = false;
  showSitesList = false;

  addTeamsFailureMessage: string;
  showAddTeamsFailureMessage = false;
  isRequesting = false;
  siteList: SiteTeams[];
  notAvailable = 'n/a';

  constructor(
    public stateService: StateService,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private translatePipe: TranslatePipe,
    private toastService: ToastService,
    private loggerService: LoggerService,
    private teamService: TeamService
  ) { }

  ngOnInit(): void {
    this.initializeRequestAccessForm();
    if (this.modalType === AddWorkspaceTypeModal.study) {
      this.workspaceType = WorkspaceType.Study;
    }
    else {
      this.workspaceType = WorkspaceType.Site;
    }
  }

  public search(isIrb: boolean): void {
    this.resetValues();
    let searchTerm = '';
    let irbTracking = '';

    if (isIrb) {
      irbTracking = this.requestAccessForm.controls.irbTracking.value.trim();
    }
    else {
      this.isMultipleChoices = false;
      this.requestAccessForm.controls.irbTracking.reset();
    }

    searchTerm = this.requestAccessForm.controls.searchTerm.value.trim();

    this.apiService.getWorkspaceSearch(this.modalType, searchTerm, irbTracking)
      .pipe().subscribe((data) => {
        this.workspaceData = data;
        this.searchWorkspaceAccess();
      }, (err) => {
        this.loggerService.error(`Errow while searching. ${err}`);
      });
  }

  public submitAccessRequest(): void {
    const workspaceOrganizationModel = new WorkspaceOrganizationModel();
    workspaceOrganizationModel.TeamExternalReferenceId = this.organizationId;
    workspaceOrganizationModel.WorkspaceExternalReferenceId = this.workspaceData.workspaceExternalReferenceId;
    workspaceOrganizationModel.IsRequested = this.isRequest;

    this.isRequesting = true;
    this.apiService.addWorkspaceOrganization(workspaceOrganizationModel)
      .pipe().subscribe((result) => {
        if (result === true) {
          this.toastService.add([{
            closable: true,
            id: `addTeam${this.organizationId}`,
            message: `${this.translatePipe.transform('teams.teamAddSuccessMessage')} ${this.workspaceType}`,
            timeout: 3000,
            variant: 'success'
          }]);
          this.closeRequestAccessModal();
          this.isRequesting = false;
          this.teamService.reloadTeamDetails.next(true);
          if (this.modalType === 'study') {
            this.teamService.reloadTeamStudyGrid.next(true);
          }
          else {
            this.teamService.reloadTeamSiteGrid.next(true);
          }
        }
        else {
          this.isRequesting = false;
          this.showAddTeamsFailureMessage = true;
          this.addTeamsFailureMessage = `${this.translatePipe.transform('teams.teamWorkspaceExistMessage')}`;
        }
      }, (err) => {
        this.isRequesting = false;
        this.showAddTeamsFailureMessage = true;
        this.addTeamsFailureMessage = `${this.translatePipe.transform('teams.teamAddFailureMessage')} : ${err?.details.error.message}`;
      });
  }

  public closeRequestAccessModal(): void {
    this.resetForm();
    this.stateService.showTeamWorkspaceRequestModal.next(false);
    this.isRequesting = false;
  }

  public noWhitespaceValidator = (control: FormControl): any => {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;

    return isValid ? null : { whitespace: true };
  }

  public changeRadio(event): void {
    this.workspaceData.workspaceExternalReferenceId = event.target.value;
    this.submitValid = true;
  }

  private searchWorkspaceAccess(): void {
    if (this.workspaceData !== null) {
      if (this.workspaceData.studyName === '[EntityNotFound]') {
        this.showNoMatches = true;
        this.workspaceData = null;
      }
      else if (this.workspaceData.studyName === '[AmbiguousResults]' &&
        this.isMultipleChoices) {
        this.getSiteList();
        this.sitesListResultMessage = this.translatePipe.transform('requestAccess.matchesSearch');
        this.showSitesList = true;
      }
      else if (this.workspaceData.studyName === '[AmbiguousResults]') {
        this.isMultipleChoices = true;
        this.submitValid = false;
        this.searchResultMessage = this.translatePipe.transform('requestAccess.matchesMoreThanOneSearch');
        this.workspaceData = null;
      }
      else {
        if (this.isMultipleChoices) {
          this.irbResultMessage = this.translatePipe.transform('requestAccess.matchesSearch');
        }
        else {
          this.isMultipleChoices = false;
          this.searchResultMessage = this.translatePipe.transform('requestAccess.matchesSearch');
        }
        this.submitValid = true;
      }
    }
  }

  private getSiteList(): void {
    const piName = this.requestAccessForm.controls.searchTerm.value.trim();
    const irbTrackingNumber = this.requestAccessForm.controls.irbTracking.value.trim();
    this.apiService.getSitesList({
      piName: piName,
      irbTrackingNumber: irbTrackingNumber
    }).subscribe(
      (data) => {
        this.siteList = data;
      }
    );
  }

  private resetValues(): void {
    this.workspaceData = null;
    this.showNoMatches = false;
    this.searchResultMessage = '';
    this.irbResultMessage = '';
    this.sitesListResultMessage = '';
    this.showWarning = false;
    this.submitValid = false;
    this.addTeamsFailureMessage = '';
    this.showAddTeamsFailureMessage = false;
    this.siteList = [];
    this.showSitesList = false;
  }

  private clearState(): void {
    this.workspaceData = null;
    this.searchResultMessage = '';
    this.irbResultMessage = '';
    this.sitesListResultMessage = '';
    this.isMultipleChoices = false;
    this.requestAccessForm.controls.searchTerm.setValue(' ');
    this.invalidAttemptsData = [];
    this.showNoMatches = false;
    this.submitValid = false;
    this.showAddTeamsFailureMessage = false;
    this.addTeamsFailureMessage = '';
    this.requestAccessForm.reset();
    this.siteList = [];
    this.showSitesList = false;
  }

  private initializeRequestAccessForm(): void {
    const minLength = 8;
    const maxLength = 9;
    const searchMaxLength = 400;

    this.requestAccessForm = this.formBuilder.group({
      searchTerm: ['', [Validators.required, Validators.maxLength(searchMaxLength), this.noWhitespaceValidator]],
      irbTracking: ['', [Validators.pattern('[0-9]*$'),
        Validators.minLength(minLength),
        Validators.maxLength(maxLength),
        Validators.required]]
    });
  }

  private resetForm(): void {
    this.clearState();
  }
}
