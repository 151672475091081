import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { ApiService } from '../../../services/api.service';
import { BaseComponent } from '../../../components/base/base.component';
import { FlowTypeName } from '../../../common/collections';
import { StateService } from '../../../services/state.service';
import { StudySitesSubmissionMap } from '../../../interfaces/studies';
import { SubmissionWorkflowService } from '../submission-workflow.service';
import { TableHeader } from '../../../interfaces/components';
import { ToastService } from '../../../services/toast.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { UtilService } from '../../../services/util.service';

@Component({
  selector: 'app-submission-choose-sites',
  templateUrl: './submission-choose-sites.component.html',
  styleUrls: ['./submission-choose-sites.component.scss']
})

export class SubmissionChooseSitesComponent extends BaseComponent implements OnInit {
  submissionChooseSitesForm = new FormGroup({
    updateSitesGroup: new FormGroup({
      chooseSites: new FormControl(Boolean)
    })
  });

  continue = false;
  rawDocuments: Array<StudySitesSubmissionMap>;
  filteredDocuments: Array<StudySitesSubmissionMap>;
  hasNav = false;
  headers: Array<TableHeader>;
  options = {
    rowSelection: true,
    manualPaginationAndSort: true
  };

  optionsSingleSite = {
    rowSelection: false,
    manualPaginationAndSort: true
  };

  searchTerm = new FormControl('');
  selectedSites = [];
  showOverlay = true;
  studyID = '';
  radioUpdate = '';
  updatingSites = false;
  updatingAllSites = false;

  constructor(
    public router: Router,
    public stateService: StateService,
    public submissionWorkflowService: SubmissionWorkflowService,
    private apiService: ApiService,
    private toastService: ToastService,
    private translatePipe: TranslatePipe,
    public utilService: UtilService
  ) {
    super();
  }

  ngOnInit(): void {
    this.submissionWorkflowService.resetSaveStatus();
    this.studyID = this.submissionWorkflowService.submissionFindStudy.value.id;
    this.headers = this.getHeaders();

    const selectedReviewTypes = this.submissionWorkflowService.submissionRegions.value
      .map((id: any) => parseInt(id, 10)) || [];

    this.postStudySitesSubmit(this.studyID, {
      reviewTypes: selectedReviewTypes,
      searchOptions: {
        page: 0,
        pageSize: 0,
        searchTerm: ''
      }
    });

    this.submissionChooseSitesForm.controls.updateSitesGroup.valueChanges.subscribe((selection) => {
      this.radioUpdate = selection.chooseSites;
      this.updatingSites = this.radioUpdate === 'selected';
      this.updatingAllSites = this.radioUpdate === 'all';
      this.continue = !this.updatingSites;
      this.submissionWorkflowService.siteSelectionResponse.next(this.radioUpdate);
    });
  }

  public backClick(): void {
    if (this.submissionWorkflowService.isStudyOriginSubmission()) {
      if (this.submissionWorkflowService.submissionFlow.value === 'addDocuments' &&
            (this.submissionWorkflowService.submissionTypeValue.value === 'Translation Request' ||
            this.submissionWorkflowService.submissionTypeValue.value === 'Change In Research')) {
        this.router.navigateByUrl('/submission-workflow/setup/regions');
      }
      else {
        this.router.navigateByUrl('/submission-workflow/setup/types');
      }
    }
    else {
      this.router.navigateByUrl('/submission-workflow/search-study');
    }
  }

  public continueClick(): void {
    const { submissionTypeGroups } = this.submissionWorkflowService;
    const [existingSite] = submissionTypeGroups.value.filter((group) => group.value === FlowTypeName.ExistingSite);
    const [existingStudy] = submissionTypeGroups.value.filter((group) => group.value === FlowTypeName.ExistingStudy);

    if (this.updatingAllSites) {
      this.selectedSites = this.filteredDocuments;
    }

    if (this.selectedSites.length) {
      this.submissionWorkflowService.submissionStudySites.next(this.selectedSites);
    }

    if (this.selectedSites.length === 1) {
      this.submissionWorkflowService.submissionTypeGroup.next(existingSite);
    }
    else if (this.selectedSites.length > 1) {
      this.submissionWorkflowService.submissionTypeGroup.next(existingStudy);
    }

    this.router.navigateByUrl('/submission-workflow/details');
  }

  public handleRowSelection(event): void {
    this.addSelectedSites(event.detail || []);
    this.continue = this.selectedSites.length > 0;
  }

  public onSiteRadioChange(event): void {
    this.selectedSites = this.filteredDocuments.filter((site) => site.id === event.target.value);
    this.continue = this.selectedSites.length > 0;
  }

  public search(event: Event, term: string): void {
    event.preventDefault();

    this.rawDocuments = this.rawDocuments.map((doc) => ({
      ...doc,
      selected: Boolean(this.selectedSites.find((selectedSite) => selectedSite.id === doc.id))
    }));

    this.filteredDocuments = [];

    setTimeout(() => {
      this.filteredDocuments = [...this.utilService.filterTableData(this.rawDocuments, term, ['selected'])];
    });
  }

  public removeSelectedSite(event): void {
    const idx = this.selectedSites.findIndex((selectedSite) => selectedSite.id === event.detail.id);

    if (idx > -1) {
      this.selectedSites.splice(idx, 1);
    }
  }

  private addSelectedSites(sites: Array<StudySitesSubmissionMap>): void {
    const newSites = [...this.selectedSites];

    sites.forEach((site) => {
      const idx = this.selectedSites.findIndex((selectedSite) => selectedSite.id === site.id);

      if (idx < 0) {
        newSites.push(site);
      }
    });

    this.selectedSites = newSites;
  }

  private getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('sites.principalInvestigator'), 'piNameWrap', true),
      this.utilService.createTableHeader(this.translatePipe.transform('sites.org'), 'piOrganizationNameWrap', true),
      this.utilService.createTableHeader(this.translatePipe.transform('sites.country'), 'countryNameWrap', true),
      this.utilService.createTableHeader(this.translatePipe.transform('sites.status'), 'status', true),
      this.utilService.createTableHeader(
        this.translatePipe.transform('sites.institutionalTracking'), 'institutionalTrackingNumberWrap', true
      )
    ];
  }

  private postStudySitesSubmit(id, model): void {
    this.apiService.postStudySitesSubmit(id, model)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.rawDocuments = this.submissionWorkflowService.mapSitesDataTable(data.records);
        this.filteredDocuments = [...this.rawDocuments];
        this.submissionWorkflowService.submissionStudySitesTotal.next(data.totalRecords);
      }, () => {
        this.toastService.add([{
          closable: true,
          id: 'postStudySitesSubmit',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          variant: 'error'
        }]);
      });
  }
}
