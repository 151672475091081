import { Component, OnInit } from '@angular/core';
import { DownloadableDocuments, HowToVideos, ResourceType, UserGuide } from '../../interfaces/resources';
import { ApiService } from 'src/app/services/api.service';
import { LoggerService } from 'src/app/services/logger.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  documents: DownloadableDocuments = [];
  isDownloading: boolean[] = [];
  userGuide: UserGuide;
  videos: HowToVideos = [];

  constructor(private apiService: ApiService,
    private loggerService: LoggerService,
    private toastService: ToastService,
    private translatePipe: TranslatePipe) {
  }

  ngOnInit(): void {
    this.apiService.getResources().subscribe((res) => {
      this.documents = res.filter((doc) => doc.type === ResourceType.PDF);
      this.isDownloading = this.documents.map(() => false);
      this.videos = res.filter((vid) => vid.type === ResourceType.Video);
      this.userGuide = res.find((guide) => guide.type === ResourceType.HTML);
    }, (err) => {
      this.toastService.add([{
        closable: true,
        id: 'getResourcesError',
        message: `${this.translatePipe.transform('resources.resourcesRetrievalFailure')} ${err.details.message}`,
        timeout: 5000,
        variant: 'error'
      }]);
    });
  }

  public onResourceClick(storagePath: string, index?: number): void {
    this.isDownloading[index] = true;

    this.apiService.getSasStorageFilePath(storagePath).subscribe((res) => {
      window.open(res, '_blank');

      this.isDownloading[index] = false;
    }, (err) => {
      this.loggerService.error(err);

      this.toastService.add([{
        closable: true,
        id: 'getSasStorageFilePath',
        message: `${this.translatePipe.transform('resources.failureToOpenDoc')} ${err.details.message}`,
        timeout: 5000,
        variant: 'error'
      }]);

      this.isDownloading[index] = false;
    });
  }
}
