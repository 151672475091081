/* eslint-disable max-len, max-lines, complexity */
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { OutcomeDocumentsDetailsMap, SubmissionDocumentsMap, SubmissionOutcomeMap } from '../interfaces/submissions';
import { TableHeader } from '../interfaces/components';
import { TranslatePipe } from '../pipes/translate.pipe';
import { WorkspaceOutcomeGetMap } from '../interfaces/workspaces';
import { PermissionType, PermissionTypeViewValues, RequestStatusLabel, TeamGridType } from '../common/collections';
import * as cssVars from '../../assets/scss-variables.json';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  public dateFormat = 'DD-MMM-YYYY';
  public dateTimeFormat = 'DD-MMM-YYYY h:mm a';
  public fileDownloadDateFormat = 'YYYY-MM-DD-HH_MM_SS';

  constructor(
    private translatePipe: TranslatePipe
  ) { }

  public addTableCellWrapper(name: string, content?: string): string {
    const newName = this.replaceNullAndTrim(name);

    return this.getTableCellWrapper(newName, newName, content);
  }

  public addTableCellWrapperToDate(date: string): string {
    const newName = this.replaceNullAndTrim(date);

    const highlightedDate = `<span class="wcg-text-highlight"> ${newName} </span>`;

    return this.getTableCellWrapper(newName, highlightedDate);
  }

  public addTableCellWrapperWithSearchTerms(name: string, searchTerm: string, url?: string, newWindow: boolean = false): string {
    const newName = this.replaceNullAndTrim(name);
    const result = this.highlightSearchTerm(newName, searchTerm);
    if (url) {
      return this.getTableCellWrapper(newName, result, this.generateLink(url, result, newWindow) || '');
    }

    return this.getTableCellWrapper(newName, result);
  }

  public addTableCellWrapperReadOnly(name: string, content?: string): string {
    const newName = this.replaceNullAndTrim(name);

    return this.getTableCellWrapper(newName, newName, content, false, true);
  }


  public addPermissionsTableCellWrapperWithSearchTerms(value: PermissionType, permissionTypes: PermissionType[], searchTerm: string): string {
    const result = this.highlightSearchTerm(PermissionTypeViewValues[PermissionType[value]], searchTerm);
    let options = '';

    permissionTypes.forEach((permissionType) => {
      options += `<option ${value === permissionType ? 'selected' : ''} value="${permissionType}">
      ${PermissionTypeViewValues[PermissionType[permissionType]]}
      </option>`;
    });

    const dropDownHtml = `<div class="read-only" title="${PermissionTypeViewValues[PermissionType[value]]}">${result}</div>
    <wcg-form-field class="edit-mode-content">
    <wcg-label>${this.translatePipe.transform('teams.permissions')}</wcg-label>
      <wcg-select>
      ${options}
      </wcg-select>
    </wcg-form-field>`;

    return dropDownHtml;
  }

  public addRequestStatusTableCellWrapperWithSearchTerms(name: string, searchTerm: string, gridType: string, canRetry: boolean): string {
    let newName = this.replaceNullAndTrim(name);
    let toolTip = '';

    switch (name) {
    case RequestStatusLabel.Complete: {
      if (gridType === TeamGridType.site) {
        toolTip = this.translatePipe.transform('teams.sitesCompleteTooltip');
      }
      else if (gridType === TeamGridType.study) {
        toolTip = this.translatePipe.transform('teams.studiesCompleteTooltip');
      }
      else {
        toolTip = this.translatePipe.transform('teams.usersCompleteTooltip');
      }
      break;
    }
    case RequestStatusLabel.InProgress: {
      toolTip = this.translatePipe.transform('teams.inProgressTooltip');
      break;
    }
    case RequestStatusLabel.Failed: {
      toolTip = this.translatePipe.transform('teams.failedTooltip');
      break;
    }
    case RequestStatusLabel.Requested: {
      toolTip = this.translatePipe.transform('teams.requestedTooltip');
      break;
    }
    case RequestStatusLabel.Rejected: {
      toolTip = this.translatePipe.transform('teams.rejectedTooltip');
      break;
    }
    default: {
      toolTip = newName;
      break;
    }
    }

    let result = this.highlightSearchTerm(newName, searchTerm);

    if ((name.includes(RequestStatusLabel.Failed) || name.includes(RequestStatusLabel.Rejected)) && canRetry) {
      const requestLabel = name.includes(RequestStatusLabel.Failed) ? RequestStatusLabel.Failed : RequestStatusLabel.Rejected;

      const tryAgain = `<b style="text-decoration:underline;cursor:pointer;" retry-request>${this.translatePipe.transform('teams.tryAgain')}</b>`;
      newName = `<div style="color:${cssVars['$red-500']};">${requestLabel}, ${tryAgain}</div>`;
      result = newName.replace(`${requestLabel}`, result);
    }

    return this.getTableCellWrapper(toolTip, result);
  }

  public addEmailToTableCellWrapperWithSearchTerms(name: string, searchTerm: string, email: string): string {
    const newName = this.replaceNullAndTrim(name);
    const result = this.highlightSearchTerm(newName, searchTerm);

    return this.getTableCellWrapper(newName, result, this.generateLink(`mailto:${email}`, result) || '');
  }

  public createTableHeader = (name, field, sortable?, sortBy?, dateFormat?): TableHeader => ({
    name,
    field,
    sortable,
    sortBy,
    dateFormat
  });

  public filterTableData(data: any, term: string, ignoredKeys?: string[]): any {
    if (!term || !term.trim().length) {
      return data;
    }

    const termsArray = term.trim().split(' ').filter(Boolean);

    return data.filter((record) => {
      const keys = Object.keys(record);


      const matches = termsArray.filter((currentTerm) => keys.find((key) => {
        if (ignoredKeys && ignoredKeys.includes(key)) {
          return false;
        }

        if (record[key]) {
          const stringWithoutHTML = this.stripHTML(record[key]).toString().toLowerCase();

          return stringWithoutHTML.includes(currentTerm.toLowerCase());
        }

        return null;
      }));

      if (matches.length >= termsArray.length) {
        return record;
      }

      return null;
    });
  }

  public formatDate(date: string): string {
    if (date) {
      return moment(date).format(this.dateFormat).toUpperCase();
    }

    return '';
  }

  public formatDateTime(date: string): string {
    if (date && date !== null) {
      return moment(date).format(this.dateTimeFormat).toUpperCase();
    }

    return 'N/A';
  }

  public formatName = (first: string, middle: string, last: string): string => {
    if (last && first) {
      return `${last}, ${first} ${middle || ''}`.trim();
    }

    return last ? last : first;
  };

  public generateLink = (url: string, text: string, newWindow: boolean = false, customElementId: string = null): string => {
    if (text) {
      const elementIdAttribute = `${customElementId ? ` id="${customElementId}"` : ''}`;

      return `<a class="wcg-link" href="${url}"${newWindow ? ' target="_blank"' : ''}${elementIdAttribute}>${text}</a>`;
    }

    return '';
  };

  public generateLinkWithHighlightAndToolTip(url: string, text: string, searchTerm: string, newWindow: boolean = false, customElementId: string = null): string {
    const processedText = this.replaceNullAndTrim(text);
    if (text) {
      const elementIdAttribute = `${customElementId ? ` id="${customElementId}"` : ''}`;

      const highlightedText = this.highlightSearchTerm(processedText, searchTerm);

      const link = `<a class="wcg-link" href="${url}"${newWindow ? ' target="_blank"' : ''}${elementIdAttribute}>${highlightedText}</a>`;

      return `<span class="table-cell-inner" title="${processedText}">${link}</span>`;
    }

    return '';
  }

  public getTableCellWrapper = (title: string,
    value: string,
    content?: string,
    encodeTitle: boolean = false,
    readOnly: boolean = false): string => {
    let encodedTitle = null;

    const formattedTitle = title?.replace(/"/g, '');

    if (encodeTitle) {
      encodedTitle = encodeURI(formattedTitle);
    }

    return `<span class="table-cell-inner" title="${encodedTitle ?? formattedTitle}"${readOnly ? ' style="color:#213341"' : ''}>${content ? content : value}</span>`;
  }

  public highlightDateFields(dateValue: string, startDate: Date, endDate: Date): string {
    const formatedDate = this.formatDate(dateValue);

    const selectedDateMoment = moment(formatedDate, this.dateFormat);
    const startDateMoment = moment(startDate, this.dateFormat);
    const endDateMoment = moment(endDate, this.dateFormat);

    if (startDate && endDate) {
      return this.highlightDatesInBetween(formatedDate, selectedDateMoment, startDateMoment, endDateMoment);
    }

    if (startDate) {
      return this.highlightStartDates(formatedDate, selectedDateMoment, startDateMoment);
    }

    if (endDate) {
      return this.highlightEndDates(formatedDate, selectedDateMoment, endDateMoment);
    }

    return this.addTableCellWrapper(formatedDate);
  }

  public highlightSearchTerm = (stringToSearch: string, stringToFind: string): string => {
    if (stringToFind?.trim().length) {
      const searchTerms = stringToFind.replace(/[\W]+/g, ' ').trim().split(' ').filter((item) => item !== '');
      const searchString = `(${searchTerms.join('|')})`;

      if (stringToSearch) {
        const reText = new RegExp(new RegExp(searchString), 'gi');

        return stringToSearch.replace(reText, '<span class="wcg-text-highlight">$&</span>');
      }
    }

    return stringToSearch;
  }

  public isEmptyObject = (obj: object = {}): boolean => Object.keys(obj).length === 0 && obj.constructor === Object;

  public maxPages = (totalRecords, pageSize): number => Math.ceil(totalRecords / pageSize);

  public pluckLinks = (collection: Array<
    SubmissionDocumentsMap | SubmissionOutcomeMap | WorkspaceOutcomeGetMap | OutcomeDocumentsDetailsMap
  >): Array<string> => collection.map((doc) => doc.link);

  public replaceAzureBlobBaseUrl = (url: string): string => url.replace('.blob.core.windows.net', '')

  public replaceNull(value: null | string): string {
    if (value === null || value?.toString().trim() === '') {
      return this.translatePipe.transform('utility.nullReplacement');
    }

    return value;
  }

  public replaceNullAndTrim(value: string): string {
    let newValue = value;
    newValue = this.replaceNull(newValue);
    newValue = newValue?.replace(/"/g, '')?.replace(/'/g, '\'');

    return newValue?.trim();
  }

  public sortAlphabetically = (
    stringOne: string, stringTwo: string
  ): number => stringOne.toLowerCase().localeCompare(stringTwo.toLowerCase());

  public sortByDate(aItem, bItem, ascending): number {
    const aProp = aItem ? aItem.toString().toLowerCase() : '';
    const bProp = bItem ? bItem.toString().toLowerCase() : '';

    if (bProp === aProp) {
      return 0;
    }

    if (ascending) {
      return this.sortByDateAscending(aProp, bProp);
    }

    return this.sortByDateDescending(aProp, bProp);
  }

  public ignoreCaseComparison = (val: any, searchKey: string): string => Object.keys(val).find((key) => key.toLowerCase() === searchKey.toLowerCase())

  // eslint-disable-next-line prefer-named-capture-group
  public stripHTML = (value: string): string => value.replace(/(<([^>]+)>)/ig, '');

  public stripQueryParamsFromUrl = (url): string => url.split('?')[0];

  public toKebabCase = (string: string): string => string.replace(/\s+/g, '-').toLowerCase();

  public compareDateDescending = (aItem: string, bItem: string): number => {
    if (!aItem && !bItem) {
      return 0;
    }
    if (!aItem) {
      return 1;
    }
    if (!bItem) {
      return -1;
    }

    if (moment(aItem).isBefore(moment(bItem))) {
      return 1;
    }
    if (moment(aItem).isAfter(moment(bItem))) {
      return -1;
    }

    return 0;
  }

  public generateButton = (text: string, customElementId: string = null, customClass: string = null): string => {
    if (text) {
      const elementIdAttribute = `${customElementId ? ` id="${customElementId}"` : ''}`;

      return `<a class="${customClass} wcg-btn wcg-btn-auto wcg-btn-primary"${elementIdAttribute}>${text}</a>`;
    }
  }

  private sortByDateAscending(aProp, bProp): number {
    if (!bProp) {
      return 1;
    }

    if (!aProp) {
      return -1;
    }

    return moment(bProp, this.dateFormat).isAfter(moment(aProp, this.dateFormat)) ? -1 : 1;
  }

  private sortByDateDescending(aProp, bProp): number {
    if (!bProp) {
      return -1;
    }

    if (!aProp) {
      return 1;
    }

    return moment(bProp, this.dateFormat).isAfter(moment(aProp, this.dateFormat)) ? 1 : -1;
  }


  private highlightDatesInBetween(date: string, selectedDate: moment.Moment, startDate: moment.Moment, endDate: moment.Moment): string {
    if (selectedDate.isBetween(startDate, endDate, null, '[]')) {
      return this.addTableCellWrapperToDate(date);
    }

    return this.addTableCellWrapper(date);
  }

  private highlightStartDates(date: string, selectedDate: moment.Moment, startDate: moment.Moment): string {
    if (selectedDate.isSameOrAfter(startDate)) {
      return this.addTableCellWrapperToDate(date);
    }

    return this.addTableCellWrapper(date);
  }

  private highlightEndDates(date: string, selectedDate: moment.Moment, endDate: moment.Moment): string {
    if (selectedDate.isSameOrBefore(endDate)) {
      return this.addTableCellWrapperToDate(date);
    }

    return this.addTableCellWrapper(date);
  }
}
