<div class="wcg-container">
  <div class="wcg-row wcg-between-md cnxs-page-header">
    <div class="wcg-col-xs-4 wcg-col-md-6 wcg-col-lg-8">
      <h1 class="wcg-h2 wcg-rhythm-4 cnxs-wrap-content">{{site?.investigator}}</h1>
    </div>
    <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-4 wcg-end-md">
      <a *ngIf="permissions?.canManageWorkspaceUsers" routerLink="/sites/{{id}}/manage-contacts" class="wcg-btn wcg-btn-large cnxs-page-header-btn">
        {{ 'manageContacts.manageContacts' | translate }}
      </a>
    </div>
  </div>

  <dl class="wcg-rhythm-4">
    <div class="wcg-row">
      <div class="wcg-col-xs-4 wcg-rhythm-3">
        <dt class="wcg-body-label">{{ 'labels.studyName' | translate }}</dt>
        <dd><a class="wcg-link cnxs-wrap-content" href="studies/{{site?.studyId}}">{{ utilService.replaceNull(site?.studyName) }}</a></dd>
      </div>
    </div>

    <div class="wcg-row">
      <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
        <dt class="wcg-body-label">{{ 'labels.sponsor' | translate }}</dt>
        <dd class="cnxs-wrap-content">{{ utilService.replaceNull(site?.sponsor) }}</dd>
      </div>

      <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
        <dt class="wcg-body-label">{{ 'labels.sponsorProtocolId' | translate }}</dt>
        <dd class="cnxs-wrap-content">{{ utilService.replaceNull(site?.sponsorProtocolId) }}</dd>
      </div>

      <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
        <dt class="wcg-body-label">{{ 'labels.initialApproval' | translate }}</dt>
        <dd class="cnxs-wrap-content">{{ utilService.replaceNull(site?.initialApproval) }}</dd>
      </div>

      <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
        <dt class="wcg-body-label">{{ 'labels.lastReview' | translate }}</dt>
        <dd class="cnxs-wrap-content">{{ utilService.replaceNull(site?.lastReview) }}</dd>
      </div>
    </div>

    <div class="wcg-row">
      <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
        <dt class="wcg-body-label">{{ 'labels.siteExpiration' | translate }}</dt>
        <dd class="cnxs-wrap-content">{{ utilService.replaceNull(site?.expirationDate) }}</dd>
      </div>

      <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
        <dt class="wcg-body-label">{{ 'labels.irbTrackingId' | translate }}</dt>
        <dd class="cnxs-wrap-content">{{ utilService.replaceNull(site?.irbTrackingNumber) }}</dd>
      </div>

      <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
        <dt class="wcg-body-label">{{ 'labels.institutionalTrackingId' | translate }}</dt>
        <dd class="cnxs-wrap-content">{{ utilService.replaceNull(site?.institutionalTrackingNumber) }}</dd>
      </div>

      <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
        <dt class="wcg-body-label">{{ 'labels.status' | translate }}</dt>
        <dd class="cnxs-wrap-content">{{ utilService.replaceNull(site?.status) + (site?.closedDate && site?.status === closedStatus ? ": " + site?.closedDate :'')}}</dd>
      </div>
    </div>

    <div class="wcg-row">
      <button type="button" (click)="onAddSiteDocumentsSelect()"
        [disabled]="(this.canStartSubmissionButtonDisabled | async)" *ngIf="canAddDocuments | async"
         class="wcg-btn wcg-btn-x-large cnxs-page-header-btn ng-star-inserted">{{ 'submissionLanding.addToSite' | translate }}</button>
   </div>
 </dl>

  <hr class="wcg-rhythm-4">

  <dl class="wcg-rhythm-5" *ngIf="principalInvestigator">
    <div class="wcg-row">
      <div class="wcg-col-xs-4">
        <dt class="wcg-body-label">{{ 'siteDetails.piInformation' | translate }}</dt>
        <dd class="cnxs-wrap-content">
          <div class="wcg-rhythm-2">
            {{ utilService.replaceNull(principalInvestigator?.name) }}
          </div>

          <div class="wcg-rhythm-2">
            {{ principalInvestigator?.org }} | {{ principalInvestigator?.address }}
          </div>

          <div class="wcg-rhythm-2 pi-links">
            <a class="wcg-link email" href="mailto:{{ principalInvestigator?.email }}">
              <wcg-icon src="../assets/icons/communication/ic_email_24px.svg"></wcg-icon>
              {{ principalInvestigator?.email }}
            </a>
            <a class="wcg-link" href="tel:{{ principalInvestigator?.phone }}">
              <wcg-icon src="../assets/icons/communication/ic_phone_24px.svg"></wcg-icon>
              {{ principalInvestigator?.phone }}
            </a>
          </div>
        </dd>
      </div>
    </div>
  </dl>
</div>

<section class="cnxs-secondary-section">
  <div class="wcg-container">
    <wcg-tab-bar-section>
      <wcg-tab-bar>
        <a [routerLink]="['submissions']" routerLinkActive="wcg-active" class="wcg-link">
          {{ 'tabs.submissions' | translate }}
        </a>
        <a [routerLink]="['outcome-documents']" routerLinkActive="wcg-active" class="wcg-link">
          {{ 'tabs.outcomeDocuments' | translate }}
        </a>
        <a [routerLink]="['contacts']" routerLinkActive="wcg-active" class="wcg-link">
          {{ 'tabs.contacts' | translate }}
        </a>
      </wcg-tab-bar>
      <div>
        <router-outlet></router-outlet>
      </div>
    </wcg-tab-bar-section>
  </div>
</section>
