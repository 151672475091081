<section class="wcg-container">
  <a [routerLink]="['../']" class="wcg-link wcg-rhythm-2">&lt; {{isSite ?  ('manageContacts.backToSite' | translate) : ('manageContacts.backToStudy' | translate)}}</a>

  <h1 class="wcg-h2 wcg-rhythm-8">{{name}}</h1>

  <hr class="wcg-rhythm-8">

  <h2 class="wcg-h2" *ngIf="isSite">{{'manageContacts.manageContactsSite' | translate}}</h2>
  <h2 class="wcg-h2" *ngIf="!isSite">{{'manageContacts.manageContactsStudy' | translate}}</h2>

  <p class="wcg-rhythm-5">{{isSite ?  ('sitePermissions.description' | translate) : ('studyPermissions.description' | translate)}}</p>

  <wcg-accordion class="wcg-rhythm-5" [open]="openAccordion" (toggle)="openAccordion = !openAccordion">
    <wcg-accordion-trigger>
      <h3 *ngIf="!isSite" class="wcg-h4 cnxs-permissions-table-trigger">{{'permissions.learnMoreAboutStudy' | translate}}</h3>
      <h3 *ngIf="isSite" class="wcg-h4 cnxs-permissions-table-trigger">{{'permissions.learnMoreAboutSite' | translate}}
      </h3>
    </wcg-accordion-trigger>
    <wcg-accordion-content>
      <table class="cnxs-permissions-table">
        <thead>
          <td></td>
          <td class="wcg-h4">{{'permissions.manager' | translate}}</td>
          <td class="wcg-h4">{{'permissions.submitter' | translate}}</td>
          <td class="wcg-h4">{{'permissions.readOnly' | translate}}</td>
          <td *ngIf="!isSite" class="wcg-h4">{{'permissions.siteParticipant' | translate}}</td>
        </thead>
        <tbody>
          <tr *ngFor="let item of permissions; let i = index;">
            <td *ngIf="i > 0 || isSite">{{isSite ?  ('sitePermissions.permission' + (i + 1) | translate) : ('studyPermissions.permission' + (i + 1) | translate)}}</td>
            <td *ngIf="i == 0 && !isSite">{{'studyPermissions.permission1'| translate}} <b> {{ 'studyPermissions.permission1All' | translate}}</b> {{'studyPermissions.permission1SecondPart' | translate}}</td>
            <td *ngFor="let value of item">
              <span *ngIf="!value" class="wcg-visually-hidden">{{'actions.no' | translate}}</span>
              <span *ngIf="value" class="wcg-visually-hidden">{{'actions.yes' | translate}}</span>
              <wcg-icon *ngIf="value" src="../assets/icons/action/ic_check_circle_24px.svg"></wcg-icon>
            </td>
          </tr>
          <tr *ngIf="!isSite">
            <div class="details">
              <div class="helpIcon">
                <wcg-icon class="icon lightbulb-icon" src="../assets/icons/action/ic_lightbulb_outline_24px.svg">
                </wcg-icon>
              </div>
              <div class="moreInfo">
                <p class="details-text">{{ 'manageContacts.studyDetailsFirstParagraph' | translate }}
                  <br>{{ 'manageContacts.studyDetailsSecondParagraph' | translate }} </p>
              </div>
            </div>
          </tr>         
          <tr>
            <div class="details">
              <div class="helpIcon">
                <wcg-icon class="icon lightbulb-icon" src="../assets/icons/action/ic_lightbulb_outline_24px.svg">
                </wcg-icon>
              </div>
              <div class="moreInfo">
                <p class="details-text">{{ 'manageContacts.teamStudySitesPermissionsInfo' | translate }}
                  <a class="details-text" routerLink="/resources" routerLink-active="active">
                    <span><u>{{ 'manageContacts.teamStudySitesPermissionsLink' | translate }} </u></span>
                  </a></p>
              </div>
            </div>
          </tr>
        </tbody>
      </table>
    </wcg-accordion-content>
  </wcg-accordion>
</section>

<section class="cnxs-secondary-section">
  <div class="wcg-container">
    <wcg-tab-bar-section>
      <wcg-tab-bar>
        <a [routerLink]="['contacts']" routerLinkActive="wcg-active" class="wcg-link">
          {{ 'manageContacts.contacts' | translate }}
        </a>
        <a [routerLink]="['requests']" routerLinkActive="wcg-active" class="wcg-link">
          {{ 'manageContacts.requests' | translate }}
          <wcg-badge *ngIf="requests">{{requests}}</wcg-badge>
        </a>
      </wcg-tab-bar>    
      <div class="wcg-container">
        <router-outlet></router-outlet>
      </div>
    </wcg-tab-bar-section>
  </div>
</section>