import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SubmissionWorkflowService } from '../submission-workflow.service';
import { fieldsetSelectionValidator } from '../../../validators/fieldset-selection.validator';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-submission-region',
  templateUrl: './submission-region.component.html',
  styleUrls: ['./submission-region.component.scss']
})
export class SubmissionRegionComponent implements OnInit {
  regionForm: FormGroup;
  supportingText: string;

  constructor(
    public router: Router,
    public submissionWorkflowService: SubmissionWorkflowService,
    private translatePipe: TranslatePipe
  ) {}

  ngOnInit(): void {
    const options = {};

    this.submissionWorkflowService.resetSaveStatus();
    this.supportingText = this.translatePipe.transform(
      `submissionRegions.${this.submissionWorkflowService.submissionFlow.value}Region`
    );

    this.submissionWorkflowService.submissionReviewTypes.value.forEach((type) => {
      options[type.id] = new FormControl();
      options[type.id].setValue(this.submissionWorkflowService.submissionRegions.value.find((id) => id === type.id));
    });

    this.regionForm = new FormGroup({
      regions: new FormGroup(options,
        [
          Validators.required,
          fieldsetSelectionValidator
        ])
    });

    if (!this.submissionWorkflowService.submissionFlow.value) {
      this.router.navigateByUrl('/submission-workflow');
    }

    this.regionForm.controls.regions.valueChanges.subscribe((val) => {
      this.parseSelections(val);
    });
  }

  public continueClick(): void {
    if (this.submissionWorkflowService.submissionFlow.value === 'addStudy') {
      this.router.navigateByUrl('/submission-workflow/details');
    }
    else if (this.submissionWorkflowService.isStudySelected() && this.submissionWorkflowService.isStudyOriginSubmission()) {
      this.router.navigateByUrl('/submission-workflow/choose-sites');
    }
    else if (this.submissionWorkflowService.isSiteSelected() && this.submissionWorkflowService.isSiteOriginSubmission()) {
      this.router.navigateByUrl('/submission-workflow/details');
    }
    else {
      this.router.navigateByUrl('/submission-workflow/search-study');
    }
  }

  public backToSubmissionTypes(): void {
    this.submissionWorkflowService.submissionRegions.next([]);
    this.router.navigateByUrl('/submission-workflow/setup/types');
  }

  private parseSelections(selections): void {
    const parsedSelections = Object.entries(selections).map((entry) => entry[1] ? entry[0] : null).filter(Boolean);

    this.submissionWorkflowService.submissionRegions.next(parsedSelections);
  }
}
