<div class="cnxs-workflow-container">
  <header class="cnxs-workflow-header"
    [ngClass]="{'impersonationMarginTop' : stateService.isImpersonationActive.value}">
    <div class="header-content">
      <h1 class="landing-header wcg-h2">{{ header }}</h1>
      <div class="submission-info-data"
        *ngIf="submissionWorkflowService.submissionTypeGroup.value || submissionWorkflowService.submissionSponsorProtocolId.value">
        <p *ngIf="submissionWorkflowService.submissionTypeGroup.value" class="submission-type">
          {{ submissionWorkflowService.submissionName.value ? submissionWorkflowService.submissionName.value : ''  }}
        </p>
        <p *ngIf="submissionWorkflowService.submissionTypeValue.value" class="protocol-id">
          {{ this.submissionWorkflowService.submissionTypeValue.value }}
        </p>
        <p *ngIf="submissionWorkflowService.submissionSponsorProtocolId.value" class="protocol-id">
          | {{ 'submissionWorkflow.sponsorId' | translate }}
          {{ submissionWorkflowService.submissionSponsorProtocolId.value }} |
        </p>
        <span class="wcg-body-sm cnxs-submission-meta-pi"
          *ngIf="submissionWorkflowService.submissionSponsorProtocolId.value">
          <button class="wcg-link" (click)="submissionWorkflowService.showSubmissionDetails.next(true)">
            <wcg-icon class="cnxs-view-all-icon" src="../../assets/icons/action/ic_launch_24px.svg"></wcg-icon>
            {{'submissionWorkflow.fullDetails' | translate}}
          </button>
        </span>
      </div>
    </div>
    <div class="header-content">
      <p class="auto-save wcg-h5" *ngIf="save">
        <wcg-icon *ngIf="save === autosaveAutosaved" class="save-icon checked"
          src='../../assets/icons/action/ic_check_circle_24px.svg'></wcg-icon>
        <wcg-icon *ngIf="save === autosaveSaving" class="save-icon cached"
          src="../../assets/icons/action/ic_cached_24px.svg"></wcg-icon>
        <wcg-icon *ngIf="save === autosaveError" class="save-icon error"
          src="../../assets/icons/alert/ic_error_24px.svg"></wcg-icon>
        <span *ngIf="save" class="save-status">{{ save }}</span>
      </p>
      <ng-content select="close-button"></ng-content>
    </div>
  </header>

  <div class="cnxs-submission-container" [ngClass]="{
    'cnxs-submission-with-nav' : hasNav,
    'cnxs-submission-with-footer' : hasFooter,
    'cnxs-nav-visible' : stateService.formNavVisible.value,
    'impersonationMarginRight' : stateService.isImpersonationActive.value
  }">
    <div class="cnxs-submission-header" *ngIf="hasIntro">
      <ng-content select="intro-content"></ng-content>
    </div>

    <div class="cnxs-submission-body">
      <div class="cnxs-submission-nav"
        [ngClass]="{'impersonationMarginTop' : stateService.isImpersonationActive.value}">
        <ul class="cnxs-submission-checklist" *ngIf="hasNav">
          <li class="cnxs-submission-step">
            <button class="cnxs-submission-nav-toggle" (click)="toggleSidebar()">
              <wcg-icon class="wcg-toggle-icon" [ngClass]="{
                'wcg-toggle-icon-close': stateService.formNavVisible.value
              }" src="../assets/icons/form-nav-open.svg"></wcg-icon>

              <span class="wcg-visually-hidden"
                *ngIf="stateService.formNavVisible.value; else openText">{{ 'submissionWorkflow.expand' | translate }}</span>
              <ng-template #openText>
                <span class="wcg-visually-hidden">{{ 'submissionWorkflow.hide' | translate }}</span>
              </ng-template>

              <span class="cnxs-submission-step-details wcg-h3">{{ 'submissionWorkflow.checklist' | translate }}</span>
            </button>
          </li>

          <ng-content select="nav-content"></ng-content>

          <app-form-nav-item [active]="isUploadPage" visible="visible">
            <ng-content select="upload-step"></ng-content>
          </app-form-nav-item>

          <li class="wcg-review-step">
            <ng-content select="review-step"></ng-content>
          </li>
        </ul>

        <div class="cnxs-help-content" *ngIf="hasNav">
          <h2 class="cnxs-submission-nav-help-header wcg-h5">
            {{ 'submissionWorkflow.needHelp' | translate }}
          </h2>
          <p class="cnxs-submission-nav-help-details">
            <span class="cnxs-submission-nav-help-label">
              {{ 'submissionWorkflow.contact' | translate }}
            </span>
            <a class="wcg-link" href="tel:{{ 'help.phoneValue' | translate }}">
              {{ 'help.phoneValue' | translate }}
            </a>

            <span class="cnxs-submission-nav-help-label">
              {{ 'submissionWorkflow.hours' | translate }}
            </span>
            {{ 'help.hoursValue' | translate }},<br />
            {{ 'help.daysValue' | translate }}

            <a class="wcg-link cnxs-submission-nav-email" href="mailto:{{ supportEmail }}">
               {{ 'submissionWorkflow.email' | translate }}
            </a>
          </p>
        </div>
      </div>

      <div class="cnxs-submission-form cnxs-submission-content">
        <div class="cnxs-submission-scrollable">
          <ng-content select="main-content"></ng-content>
        </div>
      </div>

      <div class="cnxs-form-footer" *ngIf="hasFooter">
        <ng-content select="footer-content"></ng-content>
      </div>
    </div>
  </div>
</div>

<app-submission-details-modal></app-submission-details-modal>
