import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntil, debounce } from 'rxjs/operators';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import * as moment from 'moment';
import { ApiService } from '../../services/api.service';
import { BaseComponent } from '../../components/base/base.component';
import { StudiesService } from './studies.service';
import { StudiesGridRecordMap, StudiesGrid } from '../../interfaces/studies';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { UtilService } from '../../services/util.service';
import { TableHeader } from '../../interfaces/components';
import { ToastService } from '../../services/toast.service';
import { StudiesSortOptions, DEBOUNCE_TIME, flatPickrDateFormat, StudiesFilterOptions } from '../../common/collections';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { GridExportType } from 'src/app/common/reportingCollections';

@Component({
  selector: 'app-studies',
  templateUrl: './studies.component.html',
  styleUrls: ['./studies.component.scss']
})
export class StudiesComponent extends BaseComponent implements OnInit {
  @ViewChild('startDatePicker') startDatePicker;
  @ViewChild('endDatePicker') endDatePicker;

  readonly exportType: GridExportType = GridExportType.Studies;
  gridFilters: any;
  tableData: Array<StudiesGridRecordMap>;
  currentPage = 1;
  currentPageSize = 10;
  currentSort: string = StudiesSortOptions.nameLink;
  currentSortDescending: boolean;
  filteredData: Array<StudiesGridRecordMap>;
  loaded: boolean;
  loading: boolean;
  quickFilter: string | null;
  searchTerm = new FormControl('');
  totalPages: number;
  headers: Array<TableHeader>;
  totalRecords: number;
  startDateFilter = new FormControl('');
  endDateFilter = new FormControl('');
  filterOptions: any[] = [];
  startDateUTC: any = null;
  endDateUTC: any = null;

  options = {
    manualPaginationAndSort: true
  };

  datePickerOptions: FlatpickrOptions = {
    dateFormat: flatPickrDateFormat
  };

  constructor(
    public utilService: UtilService,
    private apiService: ApiService,
    private router: Router,
    private studiesService: StudiesService,
    private toastService: ToastService,
    private translatePipe: TranslatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.headers = this.getHeaders();
    this.currentSortDescending = false;

    this.studiesService.endDate.next(null);
    this.studiesService.startDate.next(null);
    this.studiesService.searchTerm.next('');

    this.postStudiesGrid({
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: '',
      sortOptions: [{
        field: StudiesSortOptions.nameLink,
        isDescending: false
      }]
    });

    this.searchTerm.valueChanges
      .pipe(
        debounce(() => timer(DEBOUNCE_TIME))
      )
      .subscribe((val) => {
        this.studiesService.searchTerm.next(val);
        this.postStudiesGrid({
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: val,
          sortOptions: [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }],
          filterOptions: this.filterOptions,
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });
      });

    this.startDateFilterOptions();

    this.endDateFilterOptions();
  }

  addFilterOptions(): void{
    if (this.startDateFilter.value[0] || this.endDateFilter.value[0]) {
      if (!this.filterOptions.length) {
        const filterOption = [
          {
            field: StudiesFilterOptions.expirationDate
          },
          {
            field: StudiesFilterOptions.lastReviewDate
          }
        ];

        this.filterOptions = filterOption;
      }
    }
    else {
      this.filterOptions = [];
    }
  }

  handlePageChange(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;

    this.postStudiesGrid({
      page: event.detail.page,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }],
      filterOptions: this.filterOptions,
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  handleRowClicked(event): void {
    if (event.detail.event.target.hasAttribute('href')) {
      event.detail.event.preventDefault();

      this.router.navigateByUrl(event.detail.event.target.getAttribute('href'));
    }
  }

  handleSort(event): void {
    this.currentSort = StudiesSortOptions[event.detail.field];
    this.currentSortDescending = !event.detail.ascending;

    this.postStudiesGrid({
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: [{
        field: StudiesSortOptions[event.detail.field],
        isDescending: this.currentSortDescending
      }],
      filterOptions: this.filterOptions,
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  startDateFilterOptions(): void{
    this.startDateFilter.valueChanges
      .subscribe((val) => {
        this.jumpStartDates(val[0]);
        [this.endDatePicker.flatpickr.config.minDate] = val;

        this.studiesService.startDate.next(val[0]);
        if (val[0]) {
          this.startDateUTC = moment(val[0]).utc(false);
        }

        this.addFilterOptions();
        this.postStudiesGrid({
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          sortOptions: [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }],
          filterOptions: this.filterOptions,
          startDate: val[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });
      });
  }

  endDateFilterOptions(): void {
    this.endDateFilter.valueChanges
      .subscribe((val) => {
        this.jumpEndDates(val[0]);
        [this.startDatePicker.flatpickr.config.maxDate] = val;

        this.studiesService.endDate.next(val[0]);
        if (val[0]) {
          this.endDateUTC = moment(val[0]).utc(false);
        }

        this.addFilterOptions();
        this.postStudiesGrid({
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          sortOptions: [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }],
          filterOptions: this.filterOptions,
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: val[0] ? this.endDateUTC : null
        });
      });
  }

  toggleEndDate(): void {
    this.endDatePicker.flatpickr.toggle();
  }

  toggleStartDate(): void {
    this.startDatePicker.flatpickr.toggle();
  }

  jumpStartDates(value: any): void {
    if (value) {
      if (!this.endDateFilter.value[0]) {
        this.endDatePicker.flatpickr.jumpToDate(value);
      }
    }
    else if (this.endDateFilter.value[0]) {
      this.startDatePicker.flatpickr.jumpToDate(this.endDateFilter.value[0]);
    }
    else {
      this.endDatePicker.flatpickr.jumpToDate(new Date());
    }
  }

  jumpEndDates(value: any): void {
    if (value) {
      if (!this.startDateFilter.value[0]) {
        this.startDatePicker.flatpickr.jumpToDate(value);
      }
    }
    else if (this.startDateFilter.value[0]) {
      this.endDatePicker.flatpickr.jumpToDate(this.startDateFilter.value[0]);
    }
    else {
      this.startDatePicker.flatpickr.jumpToDate(new Date());
    }
  }

  private getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('studies.name'), 'nameLink', true),
      this.utilService.createTableHeader(this.translatePipe.transform('studies.sponsor'), 'sponsor', true),
      this.utilService.createTableHeader(this.translatePipe.transform('studies.protocolNumber'), 'protocolNumber', true),
      this.utilService.createTableHeader(this.translatePipe.transform('studies.irbTrackingNumber'), 'irbTrackingNumber', true),
      this.utilService.createTableHeader(
        this.translatePipe.transform('studies.lastReviewDate'), 'lastReviewDate', true, 'date', this.utilService.dateFormat
      ),
      this.utilService.createTableHeader(
        this.translatePipe.transform('studies.expirationDate'), 'expirationDate', true, 'date', this.utilService.dateFormat
      ),
      this.utilService.createTableHeader(this.translatePipe.transform('studies.status'), 'status', true)
    ];
  }

  private postStudiesGrid(body): void {
    this.loading = true;
    this.gridFilters = body;

    this.apiService.postStudiesGrid(body)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: StudiesGrid) => {
        this.loaded = true;
        this.loading = false;
        this.totalRecords = data.totalRecords;
        this.tableData = this.studiesService.mapStudiesGridData(data);
        this.currentPage = data.currentPage;
      }, () => {
        this.loaded = true;
        this.loading = false;

        this.toastService.add([{
          closable: true,
          id: 'postStudiesFailure',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          timeout: this.toastService.ERROR_TIMEOUT,
          variant: 'error'
        }]);
      });
  }
}
