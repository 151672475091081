/* eslint-disable max-lines */
import { Component, OnInit } from '@angular/core';
import {
  SubmissionTypeGridRecordMap, SubmissionTypesGrid, IrisRoutingGroup,
  SubmissionType, SubmissionTypeGridRecord, FormType, SubmissionTypeGridHeaders
} from 'src/app/admin/shared/model/submission-type';
import { FormControl, FormGroup, FormBuilder, FormArray, Validators, ValidatorFn } from '@angular/forms';
import { SubmissionTypesService } from 'src/app/admin/shared/services/submission-types.service';
import { BaseComponent } from 'src/app/components/base/base.component';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { IdValueModel } from 'src/app/admin/shared/model/sharedmodels';
import { GridOptionsSearchModel } from 'src/app/admin/shared/model/grid.model';
import { LoggerService } from '../../../../services/logger.service';
import { RegexPatterns } from 'src/app/admin/shared/helpers/regexPatterns';
import { DEBOUNCE_TIME } from 'src/app/common/collections';
import { UtilService } from '../../../../services/util.service';

@Component({
  selector: 'app-submission-types',
  templateUrl: './submission-types.component.html',
  styleUrls: ['./submission-types.component.scss']
})
export class SubmissionTypesComponent extends BaseComponent implements OnInit {
  filteredData: SubmissionTypeGridRecordMap[];
  allData: SubmissionTypeGridRecord[];
  formTypesFilter: IdValueModel[] = [];
  submissionTypeGroupsFilter: IdValueModel[] = [];
  filtersForm: FormGroup;
  submissionTypeForm: FormGroup;
  formTypes: IdValueModel[] = [];
  submissionsTypeGroup: IdValueModel[] = [];
  irisRoutingGroups: IrisRoutingGroup[] = [];
  documentTypes: IdValueModel[] = [];
  modalTitle: string;
  tableErrorMessage: string;
  modalErrorMessage: string;
  showModalWindow = false;
  isEditAction = false;
  selectedDocumentTypes: string[] = [];
  selectedTypeGroups: string[] = [];
  submissionTypeId = 0;
  headers = SubmissionTypeGridHeaders.headers;
  docTypesSelected: IdValueModel[] = [];
  editableRow: any = {
    record: {}
  };

  options = {
    rowSelection: false,
    rowsPerPage: 10
  };

  loading = true;

  /* eslint no-magic-numbers: 0 */
  private readonly maxLength = 255;

  constructor(
    private submissionTypesService: SubmissionTypesService,
    private loggerService: LoggerService,
    formBuilder: FormBuilder,
    private utilService: UtilService
  ) {
    super();
    this.filtersForm = formBuilder.group({
      formType: new FormControl(),
      submissionGroup: new FormControl(),
      searchTerm: new FormControl()
    });
    this.submissionTypeForm = formBuilder.group({
      submissionTypeName: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.maxLength(this.maxLength),
        Validators.pattern(RegexPatterns.alphaNumericPattern)
      ])),
      envelopeAndEmailType: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.maxLength(this.maxLength),
        Validators.pattern(RegexPatterns.alphaNumericPattern)
      ])),
      isEnabled: new FormControl(),
      documentTypes: new FormArray([], this.minimumSelectedCheckboxes()),
      submissionsTypeGroup: new FormArray([]),
      formTypeControl: new FormControl(null, Validators.min(0)),
      irisRoutingGroupControl: new FormControl(null, Validators.min(0)),
      selectedDefaultDocumentTypeControl: new FormControl(null, Validators.min(0))
    });
  }

  ngOnInit(): void {
    this.postSubmissionsGrid();

    this.formTypesFilter = [
      {
        id: FormType.OnlineForm.toString(),
        value: FormType[FormType.OnlineForm]
      },
      {
        id: FormType.UploadForm.toString(),
        value: FormType[FormType.UploadForm]
      }
    ];
    this.submissionTypesService.getSubmissionTypeGroup().subscribe((result: IdValueModel[]) => {
      this.submissionTypeGroupsFilter = result;
    });
    // Debounce the search input
    this.filtersForm.controls.searchTerm.valueChanges.pipe(debounceTime(DEBOUNCE_TIME)).subscribe(() => {
      this.postSubmissionsGrid();
    });
  }

  handleChange(form, control, event): void {
    form.controls[control].setValue(event.target.value);
    this.postSubmissionsGrid();
  }

  handleRowClicked(event): void {
    if (event.detail.event.target.hasAttribute('edit-row-button') ||
      event.detail.event.target.parentElement.hasAttribute('edit-row-button') ||
      event.detail.event.target.parentElement.parentElement.hasAttribute('edit-row-button')) {
      this.selectedDocumentTypes = [];
      this.selectedTypeGroups = [];
      this.docTypesSelected = [];
      this.populateForm();
      this.isEditAction = true;
      this.modalTitle = 'Edit Submission Type';
      const submissionType = this.allData.find((subType) => subType.id === event.detail.id);
      this.submissionTypeForm.controls.submissionTypeName.setValue(submissionType.value);
      this.submissionTypeForm.controls.envelopeAndEmailType.setValue(submissionType.envelopeAndEmailType);
      this.submissionTypeForm.controls.formTypeControl.setValue(submissionType.formType.toString());
      this.submissionTypeForm.controls.irisRoutingGroupControl.setValue(submissionType.routingGroups.id);
      this.submissionTypeForm.controls.isEnabled.setValue(submissionType.isActive);
      /* eslint-disable max-len */
      if (this.submissionTypeForm.controls.selectedDefaultDocumentTypeControl.value === null && submissionType.defaultDocumentTypeId !== null) {
        this.submissionTypeForm.controls.selectedDefaultDocumentTypeControl.setValue(submissionType.defaultDocumentTypeId.toString());
      }
      else {
        this.submissionTypeForm.controls.selectedDefaultDocumentTypeControl.setValue(-1);
      }

      this.submissionTypeId = submissionType.id;
      submissionType.documentTypes.forEach((element) => {
        this.selectedDocumentTypes.push(element.id);
        this.docTypesSelected.push(element);
      });
      this.docTypesSelected.sort((aItm, bItm) => this.utilService.sortAlphabetically(aItm.value, bItm.value));
      submissionType.groups.forEach((elm) => {
        this.selectedTypeGroups.push(elm.id);
      });
      this.showModalWindow = true;
    }
  }

  closeOverlay(): void {
    const form = document.getElementsByName('submissionTypeForm')[0] as HTMLFormElement;
    form.reset();
    this.showModalWindow = false;
  }

  addNewSubmissionType(): void {
    this.resetForm();
    this.modalTitle = 'Add Submission Type';
    this.isEditAction = false;
    this.populateForm();
    this.showModalWindow = true;
  }

  resetForm(): void {
    this.modalErrorMessage = null;
    this.submissionTypeId = 0;
    this.selectedDocumentTypes = [];
    this.selectedTypeGroups = [];
    this.docTypesSelected = [];
    this.submissionTypeForm.controls.submissionTypeName.setValue(null);
    this.submissionTypeForm.controls.envelopeAndEmailType.setValue(null);
    this.submissionTypeForm.controls.irisRoutingGroupControl.setValue(-1);
    this.submissionTypeForm.controls.formTypeControl.setValue(-1);
    this.submissionTypeForm.controls.isEnabled.setValue(null);
    this.submissionTypeForm.controls.selectedDefaultDocumentTypeControl.setValue(-1);
  }

  populateForm(): void {
    this.formTypes = [
      {
        id: FormType.OnlineForm.toString(),
        value: FormType[FormType.OnlineForm]
      },
      {
        id: FormType.UploadForm.toString(),
        value: FormType[FormType.UploadForm]
      }
    ];
    this.submissionTypesService.getDocumentTypes()
      .subscribe((result: IdValueModel[]) => {
        this.documentTypes = result;
        this.addCheckboxesForDocumentTypes();
      });
    this.submissionTypesService.getIrisRoutingGroups().subscribe((result: IrisRoutingGroup[]) => {
      this.irisRoutingGroups = result;
    });
    this.submissionTypesService.getSubmissionTypeGroup()
      .subscribe((result: IdValueModel[]) => {
        this.submissionsTypeGroup = result;
        this.addCheckboxesForSubmissionsTypeGroup();
      });
  }

  handleChangeFormType(event): void {
    this.submissionTypeForm.controls.formTypeControl.setValue(event.target.value);
  }

  handleChangeIrisRoutingGroup(event): void {
    this.submissionTypeForm.controls.irisRoutingGroupControl.setValue(event.target.value);
  }

  handleChangeDefaultDocumentType(event): void {
    this.submissionTypeForm.controls.selectedDefaultDocumentTypeControl.setValue(event.target.value);
  }

  onSubmit(): void {
    this.modalErrorMessage = null;
    if (this.submissionTypeForm.controls.formTypeControl.value === FormType.UploadForm.toString()) {
      this.submissionTypeForm.controls.selectedDefaultDocumentTypeControl.setErrors(null);
    }
    if (this.submissionTypeForm.valid) {
      const submissionType: SubmissionType = {
        id: this.submissionTypeId,
        value: this.submissionTypeForm.controls.submissionTypeName.value,
        envelopeAndEmailType: this.submissionTypeForm.controls.envelopeAndEmailType.value,
        formType: this.submissionTypeForm.controls.formTypeControl.value,
        groups: this.getSelectedSubmissionTypeGroup(),
        routingGroup: this.submissionTypeForm.controls.irisRoutingGroupControl.value,
        documentTypes: this.getSelectedDocumentTypes(),
        isActive: Boolean(this.submissionTypeForm.controls.isEnabled.value),
        defaultDocumentTypeId: this.submissionTypeForm.controls.selectedDefaultDocumentTypeControl.value === -1 ?
          this.submissionTypeForm.controls.selectedDefaultDocumentTypeControl.setValue(null) :
          this.submissionTypeForm.controls.selectedDefaultDocumentTypeControl.value
      };
      this.submissionTypesService.postSubmissionType(submissionType)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.resetForm();
          this.closeOverlay();
          this.postSubmissionsGrid();
        }, (error) => {
          this.modalErrorMessage = 'An error occured while saving the Submission Type';
          this.loggerService.error(error);
        });
    }
  }

  getSelectedDocumentTypes(): number[] {
    const documentTypes = [];
    const selectedIds = this.submissionTypeForm.value.documentTypes
      .map((elem, index) => {
        if (elem) {
          return this.documentTypes[index].id;
        }

        return null;
      })
      .filter((elem: any) => elem !== null);

    selectedIds.forEach((id: string) => {
      const element = this.documentTypes.find((documentType) => documentType.id === id);
      if (element !== null) {
        documentTypes.push(element.id);
      }
    });

    return documentTypes;
  }

  getSelectedSubmissionTypeGroup(): number[] {
    const submissionTypeGroups = [];
    const selectedIds = this.submissionTypeForm.value.submissionsTypeGroup
      .map((elem, index) => {
        if (elem) {
          return this.submissionsTypeGroup[index].id;
        }

        return null;
      })
      .filter((elem: any) => elem !== null);

    selectedIds.forEach((id: string) => {
      const element = this.submissionsTypeGroup.find((typeGroup) => typeGroup.id === id);
      if (element !== null) {
        submissionTypeGroups.push(element.id);
      }
    });

    return submissionTypeGroups;
  }

  onReset(): void {
    this.submissionTypeForm.reset();
  }

  onSelectDocumentTypesRefreshDefaultDocumentTypes(): void {
    this.docTypesSelected = [];
    const selectedIds = this.submissionTypeForm.value.documentTypes
      .map((elem, index) => {
        if (elem) {
          return this.documentTypes[index].id;
        }
        if (!elem && this.submissionTypeForm.controls.selectedDefaultDocumentTypeControl.value === this.documentTypes[index].id.toString()) {
          this.submissionTypeForm.controls.selectedDefaultDocumentTypeControl.setValue(-1);
        }

        return null;
      })
      .filter((elem: any) => elem !== null);

    selectedIds.forEach((id: string) => {
      const element = this.documentTypes.find((documentType) => documentType.id === id);
      if (element !== null) {
        this.docTypesSelected.push(element);
      }
    });
  }

  private postSubmissionsGrid(): void {
    /* eslint-disable max-len */
    const searchTerm = this.filtersForm.controls.searchTerm.value === null ? '' : this.filtersForm.controls.searchTerm.value.trim().toLowerCase();
    const gridOptions: GridOptionsSearchModel = {
      page: 0,
      pageSize: 0,
      filterOptions: [],
      sortOptions: [],
      searchTerm: searchTerm,
      startDate: null,
      endDate: null
    };

    if (this.filtersForm.controls.formType.value !== null && this.filtersForm.controls.formType.value !== '-1') {
      gridOptions.filterOptions.push({
        field: 'SubmissionTypeFormType',
        operator: 'Equals',
        values: [this.filtersForm.controls.formType.value.toLowerCase()]
      });
    }

    if (this.filtersForm.controls.submissionGroup.value !== null && this.filtersForm.controls.submissionGroup.value !== '-1') {
      gridOptions.filterOptions.push({
        field: 'SubmissionTypeGroup',
        operator: 'Equals',
        values: [this.filtersForm.controls.submissionGroup.value.toLowerCase()]
      });
    }

    this.loading = true;
    this.tableErrorMessage = '';
    this.submissionTypesService.getSubmissionTypesGrid(gridOptions)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: SubmissionTypesGrid) => {
        this.loading = false;

        if (data !== null && data.totalRecords > 0) {
          this.allData = data.records.sort((first, second) => this.utilService.sortAlphabetically(first.value, second.value));
          this.filteredData = this.submissionTypesService.mapSubmissionTypesGridData(data.records, { searchTerm: searchTerm });
        }
        else {
          this.filteredData = [];
          this.tableErrorMessage = 'No records available';
        }
      }, () => {
        this.loading = false;
        this.filteredData = [];
        this.tableErrorMessage = 'Error: grid Data could not be fetched';
      });
  }

  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["minimumSelectedCheckboxes"] }] */
  private minimumSelectedCheckboxes(min = 1): ValidatorFn {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        .map((control) => control.value)
        .reduce((prev, next) => {
          if (next) {
            return prev + next;
          }

          return prev;
        }, 0);

      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

  private addCheckboxesForDocumentTypes(): void {
    const formArray = this.submissionTypeForm.controls.documentTypes as FormArray;
    formArray.clear();

    this.documentTypes.forEach((obj) => {
      if (obj.id !== null && obj.value !== null) {
        const selected = this.selectedDocumentTypes.some((itm) => itm === obj.id.toString());
        const control = new FormControl(selected);
        formArray.push(control);
      }
    });
  }

  private addCheckboxesForSubmissionsTypeGroup(): void {
    const formArray = this.submissionTypeForm.controls.submissionsTypeGroup as FormArray;
    formArray.clear();

    this.submissionsTypeGroup.forEach((obj) => {
      if (obj.id !== null && obj.value !== null) {
        const selected = this.selectedTypeGroups.some((itm) => itm === obj.id.toString());
        const control = new FormControl(selected);
        formArray.push(control);
      }
    });
  }
}
