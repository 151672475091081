<section class="wcg-container">
  <wcg-table class="wcg-rhythm-4" [ngClass]="{'cnxs-table-loading': loading}" [headers]="headers" [data]="tableData"
    [options]="options" (rowClicked)="handleRowClicked($event)">

    <div class="cnxs-table-loader" slot="table-body">
      <wcg-loader [show]="!loaded || loading"></wcg-loader>
    </div>

    <div class="cnxs-pagination-wrap">
      <wcg-pagination *ngIf="loaded && tableData?.length" [current]="currentPage" [pageSize]="currentPageSize"
        [max]="utilService.maxPages(totalRecords, currentPageSize)" (changePage)="handlePageChange($event)"></wcg-pagination>
    </div>
  </wcg-table>
</section>