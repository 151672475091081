import { Observable } from 'rxjs';

export abstract class Authentication {
  protected currentUser: any;
  protected idToken = '';
  protected accessToken = '';
  protected authenticated = false;

  public abstract isAuthenticated(): Observable<boolean>;

  public abstract getAccessToken(): string;

  public abstract async login(): Promise<void>;

  public abstract async handleRedirectCallback(): Promise<void>;

  public abstract logout();
}
