import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { ApiService } from './api.service';
import { ToastService } from './toast.service';
import { UtilService } from './util.service';
import { SubmissionWorkflowService } from '../views/submission-workflow/submission-workflow.service';
import { SubmissionOutcomeMap } from '../interfaces/submissions';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  public estimatedOutcomeDateSubject = new BehaviorSubject<string>('');
  public formNavVisible = new BehaviorSubject<boolean>(false);
  public isWorkflow = new BehaviorSubject<boolean>(false);
  public showRequestAccessModal = new BehaviorSubject<boolean>(false);

  public showOutcomeDetailsModal = new BehaviorSubject<boolean>(false);
  public selectedOutcomeDocumentToDetails = new ReplaySubject<SubmissionOutcomeMap>();
  public showTeamWorkspaceRequestModal = new BehaviorSubject<boolean>(false);

  public showExportCsvModal = new BehaviorSubject<boolean>(false);
  public isImpersonationActive = new BehaviorSubject<boolean>(false);

  public showDownloadModal = new BehaviorSubject<boolean>(false);
  public submissionsWithoutSubmittedDocumentsList: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([]);

  constructor(
    private apiService: ApiService,
    private toastService: ToastService,
    private utilService: UtilService,
    private submissionWorkflowService: SubmissionWorkflowService
  ) { }

  public get estimatedOutcomeDate(): Observable<string> {
    return this.estimatedOutcomeDateSubject.asObservable();
  }

  public getEstimatedOutcomeDate(days: number = this.submissionWorkflowService.defaultEstimatedOutcomeDaysFromToday): Observable<string> {
    if (this.estimatedOutcomeDateSubject.value === '') {
      this.apiService.getSubmissionEstimatedOutcomeDate(days).subscribe((data) => {
        this.estimatedOutcomeDateSubject.next(this.utilService.formatDate(data));
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'getSubmissionEstimatedOutcomeDate',
          message: `Data could not be fetched: ${err.detail.error.message}`,
          timeout: 5000,
          variant: 'error'
        }]);
      });
    }

    return this.estimatedOutcomeDateSubject.asObservable();
  }
}
