export class PageSizeOptions {
  public page: number;
  public pageSize: number;
  public pageSizeDataSource: Array<number>;

  public constructor(page: number, pageSize: number, pageSizeDataSource: Array<number>) {
    this.page = page;
    this.pageSize = pageSize;
    this.pageSizeDataSource = pageSizeDataSource;
  }
}
