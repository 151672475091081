/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { SubmissionInfo } from '../../interfaces/submissions';
import { UtilService } from '../../services/util.service';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { ApiService } from '../../services/api.service';
import { ToastService } from 'src/app/services/toast.service';
import { SubmissionsDashboardStatistic, SubmissionsSortOptions } from '../../common/collections';
import { FormControl } from '@angular/forms';
import { HttpCancelService } from '../../services/http-cancel.service';
import { takeUntil } from 'rxjs/operators';
import { SubmissionsDashboardPost, SubmissionsDashboardRecord } from '../../interfaces/dashboard';
import { SubmissionSelectedSite } from 'src/app/models/submission.model';
import { PaginationValues } from 'src/app/models/pagination-values.mode';
import { PageSizeOptions } from 'src/app/models/page-size-options.model';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {
  public cardView = new BehaviorSubject<boolean>(true);
  public viewListCardTypeUpdated = new BehaviorSubject<boolean>(null);
  public drafts = new BehaviorSubject<SubmissionsDashboardPost>(null);
  public draftsLoaded = new BehaviorSubject<boolean>(false);
  public draftsLoading = new BehaviorSubject<boolean>(true);
  public draftsPagination = new PaginationValues(1, 6);
  public estimatedOutcomeDate: string;
  public inProgress = new BehaviorSubject<SubmissionsDashboardPost>(null);
  public inProgressLoaded = new BehaviorSubject<boolean>(false);
  public inProgressLoading = new BehaviorSubject<boolean>(true);
  public inProgressPagination = new PaginationValues(1, 6);
  public needsAction = new BehaviorSubject<SubmissionsDashboardPost>(null);
  public needsActionLoaded = new BehaviorSubject<boolean>(false);
  public needsActionLoading = new BehaviorSubject<boolean>(true);
  public needsActionPagination = new PaginationValues(1, 6);
  public searchTerm = new FormControl('');
  public statusFilterNeedsAction = new BehaviorSubject<string>('');
  public statusFilterInProgress = new BehaviorSubject<string>('');

  public get paginationDataConfiguration(): { pageSize: number; pageSizeDataSource: Array<number>} {
    return this.cardView.value ?
      {
        pageSize: 6,
        pageSizeDataSource: [6, 12, 24, 48, 96]
      } : {
        pageSize: 10,
        pageSizeDataSource: [10, 25, 50, 100]
      };
  }

  constructor(
    private apiService: ApiService,
    private httpCancelService: HttpCancelService,
    private toastService: ToastService,
    private translatePipe: TranslatePipe,
    private utilService: UtilService
  ) {}

  public GetPaginationConfiguration(pagination: PaginationValues): PageSizeOptions {
    return new PageSizeOptions(pagination.page,
      pagination.pageSize,
      this.paginationDataConfiguration.pageSizeDataSource);
  }

  public deleteDraft(id): void {
    this.apiService.deleteSubmissionsDraft(id)
      .subscribe(() => {
        this.draftsPagination.page = 1;
        this.postDrafts();

        this.toastService.add([{
          closable: true,
          id: 'postSubmissionsDashboardDeleteSuccess',
          message: this.translatePipe.transform('dashboard.draftDeletedSuccess'),
          timeout: 5000,
          variant: 'success'
        }]);
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'postSubmissionsDashboardDeleteError',
          message: err.detail.error.message,
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  public mapDashboardRecords(data: Array<SubmissionsDashboardRecord>, tab: string): Array<SubmissionInfo> {
    return data.map((record) => ({
      createdDate: this.utilService.formatDate(record.createdDate),
      dateSubmitted: this.utilService.formatDate(record.submittedDate),
      estimatedOutcomeDate: this.utilService.formatDate(record.estimatedOutcomeDate),
      icon: this.conditionalIconOptions(record),
      id: record.id,
      flag: this.conditionalFlagOptions(record, tab),
      holdDate: this.utilService.formatDate(record.actionRequestedDate),
      holdReason: this.utilService.highlightSearchTerm(record.actionReason, this.searchTerm.value),
      outcome: record.reviewOutcome,
      outcomeDate: this.utilService.formatDate(record.outcomeDate),
      principalInvestigators: record.submissionStudySites.map((site) => this.mapStudySiteToInvestigator(site)),
      principalInvestigatorSingular: this.utilService.highlightSearchTerm(
        this.mapStudySiteToInvestigator(record.submissionStudySites[0] || {}).investigator,
        this.searchTerm.value
      ),
      principalInvestigatorsHighlighted: this.findSearchTermInPIs(record.submissionStudySites),
      onHold: record.isActionRequired,
      sponsorId: record.protocolNumber,
      sponsorIdHighlighted: this.utilService.highlightSearchTerm(record.protocolNumber, this.searchTerm.value),
      status: this.utilService.highlightSearchTerm(record.status, this.searchTerm.value),
      studyId: record.studyId,
      submissionName: record.title,
      submissionNameHighlighted: this.utilService.highlightSearchTerm(record.title, this.searchTerm.value),
      submissionType: this.utilService.highlightSearchTerm(record.type, this.searchTerm.value),
      hasOutcomeDocuments: record.hasOutcomeDocuments,
      tab
    }));
  }

  public maxPages = (data): number => Math.ceil(data.totalRecords / data.pageSize);

  public postAllDashboardSubmissions(): void {
    this.draftsLoading.next(true);
    this.inProgressLoading.next(true);
    this.needsActionLoading.next(true);

    this.httpCancelService.cancelPostAllDashboardSubmissions();

    this.apiService.postAllDashboardSubmissions({
      page: 1,
      pageSize: this.paginationDataConfiguration.pageSize,
      searchTerm: this.searchTerm.value || '',
      sortOptions: [
        {
          field: SubmissionsSortOptions.submittedDate,
          isDescending: true
        },
        {
          field: SubmissionsSortOptions.createdDate,
          isDescending: true
        }
      ]
    })
      .pipe(
        takeUntil(this.httpCancelService.onCancelPostAllDashboardSubmissions())
      )
      .subscribe((res) => {
        this.needsAction.next(res[0]);
        this.inProgress.next(res[1]);
        this.drafts.next(res[2]);
        this.resetPage();
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'postAllSubmissionsDashboardError',
          message: `Data could not be fetched: ${err.detail?.error?.message || err.details?.message}`,
          timeout: 5000,
          variant: 'error'
        }]);

        this.draftsLoading.next(false);
        this.draftsLoaded.next(true);

        this.inProgressLoading.next(false);
        this.inProgressLoaded.next(true);

        this.needsActionLoading.next(false);
        this.needsActionLoaded.next(true);
      });
  }

  public postDrafts(): void {
    this.draftsLoading.next(true);

    this.httpCancelService.cancelPostSubmissionsDashboardDrafts();

    this.apiService.postSubmissionsDashboard('Draft', null, {
      page: this.draftsPagination.page,
      pageSize: this.draftsPagination.pageSize,
      searchTerm: this.searchTerm.value || '',
      sortOptions: [
        {
          field: SubmissionsSortOptions.createdDate,
          isDescending: true
        }
      ]
    })
      .pipe(
        takeUntil(this.httpCancelService.onCancelPostSubmissionsDashboardDrafts())
      )
      .subscribe((res) => {
        this.drafts.next(res[2]);

        this.draftsLoading.next(false);
        this.draftsLoaded.next(true);
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'postSubmissionsDashboardDraftsError',
          message: `Data could not be fetched: ${err.detail?.error?.message || err.details?.message}`,
          timeout: 5000,
          variant: 'error'
        }]);

        this.draftsLoading.next(false);
        this.draftsLoaded.next(true);
      });
  }

  public postInProgress(): void {
    this.inProgressLoading.next(true);

    this.httpCancelService.cancelPostSubmissionsDashboardInProgress();

    this.apiService.postSubmissionsDashboard('InProgress', this.statusFilterInProgress.value, {
      page: this.inProgressPagination.page,
      pageSize: this.inProgressPagination.pageSize,
      searchTerm: this.searchTerm.value || '',
      sortOptions: [
        {
          field: SubmissionsSortOptions.submittedDate,
          isDescending: true
        }
      ]
    })
      .pipe(
        takeUntil(this.httpCancelService.onCancelPostSubmissionsDashboardInProgress())
      )
      .subscribe((res) => {
        this.inProgress.next(res[1]);

        this.inProgressLoading.next(false);
        this.inProgressLoaded.next(true);
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'postSubmissionsDashboardInProgressError',
          message: `Data could not be fetched: ${err.detail?.error?.message || err.details?.message}`,
          timeout: 5000,
          variant: 'error'
        }]);

        this.inProgressLoading.next(false);
        this.inProgressLoaded.next(true);
      });
  }

  public postNeedsAction(): void {
    this.needsActionLoading.next(true);

    this.httpCancelService.cancelPostSubmissionsDashboardNeedsAction();

    this.apiService.postSubmissionsDashboard('NeedsAction', this.statusFilterNeedsAction.value, {
      page: this.needsActionPagination.page,
      pageSize: this.needsActionPagination.pageSize,
      searchTerm: this.searchTerm.value || '',
      sortOptions: [
        {
          field: SubmissionsSortOptions.submittedDate,
          isDescending: true
        }
      ]
    })
      .pipe(
        takeUntil(this.httpCancelService.onCancelPostSubmissionsDashboardNeedsAction())
      )
      .subscribe((res) => {
        this.needsAction.next(res[0]);

        this.needsActionLoading.next(false);
        this.needsActionLoaded.next(true);
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'postSubmissionsDashboardNeedsActionError',
          message: `Data could not be fetched: ${err.detail?.error?.message || err.details?.message}`,
          timeout: 5000,
          variant: 'error'
        }]);

        this.needsActionLoading.next(false);
        this.needsActionLoaded.next(true);
      });
  }

  private conditionalFlagOptions(record: SubmissionsDashboardRecord, tab: string): string {
    if (tab === 'needsAction' && record.isUpdated) {
      return this.translatePipe.transform('dashboard.new');
    }
  }

  private conditionalIconOptions = (record): string => {
    let icon;

    if (record.isActionRequired) {
      icon = './assets/icons/alert/ic_warning_24px.svg';
    }
    else if (record.reviewOutcome === SubmissionsDashboardStatistic.notFullyApproved) {
      icon = './assets/icons/content/ic_report_24px.svg';
    }
    else if (record.reviewOutcome === SubmissionsDashboardStatistic.fullyApproved) {
      icon = './assets/icons/content/ic_save_alt_24px.svg';
    }

    return icon;
  }

  private findSearchTermInPIs(principalInvestigators: Array<SubmissionSelectedSite>): boolean {
    if (!this.searchTerm.value) {
      return false;
    }
    const searchTerms = this.searchTerm.value.replace(/[\W]+/g, ' ').trim().split(' ').filter((item) => item !== '');
    const searchString = `(${searchTerms.join('|')})`;
    const reText = new RegExp(new RegExp(searchString), 'gi');

    const found = principalInvestigators.find((site) => this.mapStudySiteToInvestigator(site).investigator.search(reText) > -1);

    return Boolean(found);
  }

  private mapStudySiteToInvestigator = (site: any): SubmissionSelectedSite => {
    const mappedSite: SubmissionSelectedSite = {
      countryName: site.countryName || '',
      investigator: `${site.firstName} ${site.lastName}`,
      piOrganizationName: site.piOrganizationName || ''
    };

    return mappedSite;
  }

  private resetPage(): void {
    this.needsActionLoading.next(false);
    this.needsActionLoaded.next(true);
    this.needsActionPagination =
      new PaginationValues(1, this.paginationDataConfiguration.pageSize);
    this.statusFilterNeedsAction.next(null);

    this.inProgressLoading.next(false);
    this.inProgressLoaded.next(true);
    this.inProgressPagination =
      new PaginationValues(1, this.paginationDataConfiguration.pageSize);
    this.statusFilterInProgress.next(null);

    this.draftsLoading.next(false);
    this.draftsLoaded.next(true);
    this.draftsPagination =
      new PaginationValues(1, this.paginationDataConfiguration.pageSize);
  }
}
