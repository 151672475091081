import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base/base.component';
import { DocumentCategoryService } from 'src/app/admin/shared/services/document-category.service';
import { LoggerService } from 'src/app/services/logger.service';
import { DocumentCategory, DocumentCategoryGrid, DocumentCategoryMap } from 'src/app/admin/shared/model/documentcategory';

@Component({
  selector: 'app-view-document-category',
  templateUrl: './view-document-category.component.html',
  styleUrls: ['./view-document-category.component.scss']
})

export class ViewDocumentCategoryComponent extends BaseComponent implements OnInit {
  documentCategories: Array<DocumentCategoryMap>;
  searchForm: FormGroup;
  documentCategoryForm: FormGroup;

  modalTitle: string;
  searchControl: FormControl;
  searchTerm: string;

  showEditRowModal = false;
  editableRow: any = {
    record: {}
  };

  headers = [
    {
      name: 'Document Type Category',
      field: 'Document Type Category',
      sortable: true
    },
    {
      name: 'Enabled',
      field: 'Enabled',
      sortable: false
    }
  ];

  options = {
    rowSelection: false,
    rowsPerPage: 10
  };

  constructor(
    private documentCategoryService: DocumentCategoryService,
    private loggerService: LoggerService, private formBuilder: FormBuilder
  ) {
    super();
  }

  public ngOnInit(): void {
    this.searchForm = new FormGroup({
      searchControl: new FormControl()
    });

    const debTime = 1000;
    this.searchForm.controls.searchControl.valueChanges.pipe(debounceTime(debTime)).subscribe((term) => {
      if (term.replace(/\s/g, '').length < 1 && term.length > 0) {
        return;
      }
      this.onSearchChange(term.trim());
    });

    this.initializeForm();
    this.postDocumentTypeGrid();
  }

  public initializeForm(): void {
    this.documentCategoryForm = this.formBuilder.group({
      documentTypeCategory: [null, Validators.required],
      enabled: [null]
    });
  }

  public addDocumentCategory(): void {
    this.showEditRowModal = true;
    this.documentCategoryForm.controls.documentTypeCategory.setValue('');
    this.documentCategoryForm.controls.enabled.setValue(false);
    this.modalTitle = 'Add new category type';
  }

  public handleRowClicked(event): void {
    if (event.detail.event.target.hasAttribute('edit-row-button')) {
      this.showEditRowModal = true;
      this.editableRow = event.detail;
      const docCategory = this.editableRow.record;
      if (docCategory?.id !== '') {
        this.modalTitle = docCategory.value;
        this.documentCategoryForm.controls.documentTypeCategory.setValue(docCategory.value);
        this.documentCategoryForm.controls.enabled.setValue(docCategory.isActive);
      }
    }
  }

  public saveDocumentCategory(): void {
    const documentCategory = new DocumentCategory();
    documentCategory.value = this.documentCategoryForm.controls.documentTypeCategory.value;
    documentCategory.isActive = this.documentCategoryForm.controls.enabled.value;
    documentCategory.id = this.editableRow.record?.id ? this.editableRow.record.id : 0;
    this.documentCategoryService.createOrUpdateDocumentTypeCategory(documentCategory)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: DocumentCategoryGrid) => {
        this.documentCategories = this.documentCategoryService.mapSubmissionGridData(data);
      });
  }

  public closeClick(): void {
    this.documentCategoryForm.reset();
    this.showEditRowModal = false;
  }

  public onSearchChange(term: string): void {
    this.searchTerm = term;
    this.postDocumentTypeGrid();
  }

  private postDocumentTypeGrid(): void {
    this.documentCategoryService.postDocumentTypeCategory(0, 0, this.searchTerm)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: DocumentCategoryGrid) => {
        this.documentCategories = this.documentCategoryService.mapSubmissionGridData(data);
      }, (err) => {
        this.loggerService.error(`Grid Data could not be fetched: ${err}`);
      });
  }
}
