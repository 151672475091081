/* eslint-disable max-len */
/* eslint-disable linebreak-style */
import { WebApiServices } from '../helpers/webapiservice';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AdminApis } from '../helpers/adminapis';
import { SubmissionAdminInfo, SubmissionAdminInfoMap } from '../model/submissionAdminInfo';
import { UtilService } from 'src/app/services/util.service';
import { Apis } from 'src/app/common/apis';
import { SubmissionSources } from 'src/app/common/collections';

const notAvailable = 'n/a';

@Injectable({
  providedIn: 'root'
})

export class SubmissionAdminService {
  service: WebApiServices;
  public endDate = new BehaviorSubject<Date>(null);
  public startDate = new BehaviorSubject<Date>(null);

  constructor(private http: HttpClient, private utilService: UtilService) {
    this.service = new WebApiServices(this.http);
  }

  public mapAdminSubmissionsData(data: Array<SubmissionAdminInfo>, highlightData: any): Array<SubmissionAdminInfoMap> {
    return data.map((record) => ({
      submittedDate: this.utilService.addTableCellWrapper(this.utilService.formatDateTime(record.submittedDate?.toString())),
      irbTrackingId: this.GenerateIRBTrackingIdLink(record, highlightData, true),
      submitterName: this.utilService.addTableCellWrapperWithSearchTerms(record.submitterName, highlightData.nameSearchTerm),
      submitterCompanyName: this.utilService.addTableCellWrapperWithSearchTerms(record.submitterCompanyName, highlightData.companySearchTerm),
      sponsor: this.utilService.addTableCellWrapperWithSearchTerms(record.sponsor, highlightData.sponsorSearchTerm),
      piName: this.GeneratePINameLink(record, highlightData, true),
      envelopeId: this.utilService.addTableCellWrapperWithSearchTerms(record.envelopeId, highlightData.envelopeSearchTerm),
      confirmationId: this.GenerateConfirmationLink(record, highlightData),
      source: this.utilService.addTableCellWrapper(SubmissionSources.find((item) => item.value === record.source.toString()).viewValue),
      submissionName: this.GenerateSubmissionTitleLink(record, highlightData, true),
      specialInstructions: this.utilService.addTableCellWrapperWithSearchTerms(record.specialInstructions, highlightData.notesSearchTerm),
      submissionType: this.utilService.addTableCellWrapper(record.type),
      totalRecords: record.totalRecords
    }));
  }

  public postAdminSubmissionsInfo(model): Observable<any> {
    return this.service.postService(AdminApis.AdminSubmissions, model, null);
  }

  public getSubmissionTypes(): Observable<any> {
    return this.service.getService(AdminApis.SubmissionTypes, null);
  }

  private GenerateIRBTrackingIdLink(record: SubmissionAdminInfo, highlightData: any, newWindow: boolean): string {
    if (record.irbTrackingNumber === null) {
      return notAvailable;
    }

    return this.utilService.generateLinkWithHighlightAndToolTip(Apis.Study.replace('{id}', record.studyExternalReferenceId).replace('api/', '').toString(), record.irbTrackingNumber, highlightData.irbSearchTerm, newWindow);
  }

  private GenerateConfirmationLink(record: SubmissionAdminInfo, highlightData: any): string {
    if (record.confirmationId === null) {
      return notAvailable;
    }

    return this.utilService.generateLinkWithHighlightAndToolTip(Apis.SubmissionSites.replace('{submissionId}', record.externalReferenceId).replace('api/', ''), record.confirmationId, highlightData.confirmationSearchTerm, true);
  }

  private GeneratePINameLink(record: SubmissionAdminInfo, highlightData: any, newWindow: boolean): string {
    const multiple = 'Multiple';
    if (record.site.value === multiple) {
      const highlightedText = this.utilService.highlightSearchTerm(multiple, highlightData.piNameSearchTerm);

      return highlightedText;
    }
    if (record.site.id === null) {
      return notAvailable;
    }

    return this.utilService.generateLinkWithHighlightAndToolTip(Apis.SiteSubmissions.replace('{id}', record.site.id).replace('api/', '').toString(), record.site.value.toString(), highlightData.piNameSearchTerm, newWindow);
  }

  private GenerateSubmissionTitleLink(record: SubmissionAdminInfo, highlightData: any, newWindow: boolean): string {
    if (record.name === null) {
      return notAvailable;
    }

    return this.utilService.generateLinkWithHighlightAndToolTip(Apis.SubmissionSites.replace('{submissionId}', record.externalReferenceId).replace('api/', ''), record.name, highlightData.titleSearchTerm, newWindow);
  }
}
