export class StorageModel {
  key: string;
  value: object | string;
  days?: number;
  isSessionStorage?: boolean;

  public constructor(key: string, value: object | string, days?: number, isSessionStorage?: boolean) {
    this.key = key;
    this.value = value;
    this.days = days;
    this.isSessionStorage = isSessionStorage;
  }
}

export class StorageKeys {
  public static registrationLinkKey = 'registrationLink';
  public static redirect = 'redirect';
}
