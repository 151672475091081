import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../core/services/authentication/authentication.service';
import { PageTransitionService } from '../services/page.transition.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService,
    private pageTransitionService: PageTransitionService) {
  }

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authenticationService.isAuthenticated().pipe(map((isAuthorized: boolean) => {
      if (isAuthorized) {
        this.pageTransitionService.setLastVisitPageInfo(next.data.canRequestAccess, next.params.id, next.data.entityType);

        return true;
      }
      this.authenticationService.autoLogin();

      return false;
    }));
  }
}
