/* eslint-disable max-lines */
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { takeUntil, debounce } from 'rxjs/operators';
import { timer } from 'rxjs';
import { Router } from '@angular/router';

import { ApiService } from '../../../services/api.service';
import { BaseComponent } from '../../../components/base/base.component';
import { WorkspaceAndTeamUserMap } from '../../../interfaces/user';
import { ManageContactsService } from '../manage-contacts.service';
import { ToastService } from '../../../services/toast.service';
import { LoggerService } from '../../../services/logger.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { PermissionTypeViewValues, PermissionType } from '../../../common/collections';
import { TableHeader } from '../../../interfaces/components';
import { UtilService } from '../../../services/util.service';
import { UserService } from '../../../services/user.service';

const MANAGER = 3;

@Component({
  selector: 'app-manage-contacts-contacts',
  templateUrl: './manage-contacts-contacts.component.html',
  styleUrls: ['./manage-contacts-contacts.component.scss']
})
export class ManageContactsContactsComponent extends BaseComponent implements OnInit {
  allUsers: any = [];
  canViewUsers: boolean;
  id: string;
  inviteFormSubmitted: boolean;
  isSite: boolean;
  headers: Array<TableHeader>;
  selected: Array<WorkspaceAndTeamUserMap> = [];
  showDeleteModal: boolean;
  users: Array<WorkspaceAndTeamUserMap>;
  userSelectedCount: number;
  showHasInactiveUsersNotification: boolean;
  inviteForm = new FormGroup({
    permissions: new FormControl(''),
    selectedUsers: new FormControl({
      value: '',
      selectedValues: []
    }, [
      (control): any => {
        if (control.value.selectedValues.length > 0) {
          return;
        }

        return {
          validateSelectedUsers: {
            valid: false
          }
        };
      }
    ])
  });

  permissionOptionsForStudy = [
    {
      value: '1',
      viewValue: PermissionTypeViewValues[PermissionType[1]]
    },
    {
      value: '2',
      viewValue: PermissionTypeViewValues[PermissionType[2]]
    },
    {
      value: '3',
      viewValue: PermissionTypeViewValues[PermissionType[MANAGER]]
    },
    {
      value: '4',
      viewValue: PermissionTypeViewValues[PermissionType[4]]
    }
  ];

  permissionOptionsForSite = [
    {
      value: '1',
      viewValue: PermissionTypeViewValues[PermissionType[1]]
    },
    {
      value: '2',
      viewValue: PermissionTypeViewValues[PermissionType[2]]
    },
    {
      value: '3',
      viewValue: PermissionTypeViewValues[PermissionType[MANAGER]]
    }
  ];

  options = {
    rowSelection: true,
    rowsPerPage: 10
  };

  selectedTeamToRemoved: any = [];
  loading = false;
  teamUserToast: any;
  private hasError = false;
  private newPermissionType: string;
  private removeTeamsCompleted: boolean;
  private removeUsersCompleted: boolean;
  private refreshGrid: boolean;

  constructor(
    private apiService: ApiService,
    private loggerService: LoggerService,
    private managePermissionsService: ManageContactsService,
    private router: Router,
    private toastService: ToastService,
    private translatePipe: TranslatePipe,
    private utilService: UtilService,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    const DEBOUNCE_TIME = 300;

    this.isSite = this.router.url.includes('/sites/');

    if (this.isSite) {
      [this.id] = this.router.url.split('/sites/')[1].split('/');
    }
    else {
      [this.id] = this.router.url.split('/studies/')[1].split('/');
    }

    this.headers = this.getHeaders();
    this.getWorkspaceUsersById(this.id);

    this.hasError = false;

    this.userService.userInfo.subscribe((data) => {
      this.canViewUsers = data.canViewUsers;
    });

    this.userSelectedCount = 0;

    this.inviteForm.controls.selectedUsers.valueChanges.pipe(
      debounce(() => timer(DEBOUNCE_TIME))
    ).subscribe((val) => {
      const isMultiple = val?.value?.includes(',') || val?.value?.includes(';');

      if (isMultiple) {
        this.setMultipleUsers(val);
      }

      this.validateEmail(val);

      if (this.hasError) {
        this.inviteForm.controls.selectedUsers.setValue(val);
        this.hasError = false;
      }

      this.showHasInactiveUsersNotification = this.inviteForm.controls.selectedUsers.value.selectedValues
        // eslint-disable-next-line no-undefined
        .some((user) => !user.isActive && user.isActive !== undefined);

      if (this.canViewUsers && val?.value?.length > 1 && !isMultiple) {
        this.getUsers(val.value);
      }
    });
  }

  deleteBtnClick(): void {
    const teams = this.selected.filter((user) => user.teamName && user.teamExternalReferenceId);
    const selectedTeamEmails = teams.map((team) => team.teamEmail);
    const teamUsers = this.selected.filter((user) => user.teamName && !user.teamExternalReferenceId &&
      selectedTeamEmails.indexOf(user.teamEmail) < 0);
    this.teamUserToast = null;
    this.teamUserToast = teamUsers.reduce((teamU, item) => ({
      ...teamU,
      // eslint-disable-next-line no-extra-parens
      [item.teamEmail]: [...(teamU[item.teamEmail] || []), item]
    }), {});

    this.selectedTeamToRemoved = [];
    this.selectedTeamToRemoved = teams.map((team) => ({
      ...team,
      members: this.users.filter((user) => user.teamEmail === team.teamEmail && !user.teamExternalReferenceId).length
    }));
    this.showDeleteModal = true;
  }

  delete(selected: Array<WorkspaceAndTeamUserMap>): void {
    this.showDeleteModal = false;
    this.showTeamAndUserRemoveToast();

    const regularUser = selected.filter((user) => !user.teamName && !user.teamExternalReferenceId);
    const teams = selected.filter((user) => user.teamName && user.teamExternalReferenceId);
    const teamsToRemove = teams.map((team) => team.teamExternalReferenceId);

    const selectedUserMap = regularUser.map((user) => ({
      user: {
        externalReferenceId: user.userId
      }
    }));

    this.removeTeamsCompleted = true;
    this.removeUsersCompleted = true;
    this.refreshGrid = false;
    this.removeTeamFromWorkspace(teamsToRemove);
    this.removeUserFromWorkspace(selectedUserMap);
  }

  handleRowClick(event): void {
    const row = event.detail.event.currentTarget;

    const changeCb = (changeEvent): void => {
      this.newPermissionType = changeEvent.target.value;
    };

    const composedPath = event.detail.event.composedPath && event.detail.event.composedPath();
    const eventPath = event.detail.event.path || composedPath;

    //  Start inline editing
    if (eventPath.find((path) => path.attributes && path.attributes.getNamedItem('data-edit-row-button'))) {
      this.newPermissionType = event.detail.permissionType;

      row.classList.add('edit-mode');

      row.querySelector('select').addEventListener('change', changeCb);
    }

    // Cancel permissions change
    if (eventPath.find((path) => path.attributes && path.attributes.getNamedItem('data-cancel-button'))) {
      row.classList.remove('edit-mode');
      row.querySelector('select').removeEventListener('change', changeCb);
    }

    // Submit permissions change
    if (eventPath.find((path) => path.attributes && path.attributes.getNamedItem('data-submit-button'))) {
      row.classList.remove('edit-mode');

      row.querySelector('select').removeEventListener('change', changeCb);

      this.postWorkspaceUserById(this.id, event.detail.userId, this.newPermissionType, event.detail.concurrencyVersion);
    }
  }

  handleRowSelection(event): void {
    this.selected = event.detail;
    this.userSelectedCount = this.selected.length;
  }

  isSingleContactRemoval(): boolean {
    return this.userSelectedCount === 1;
  }

  isMultipleContactRemovals(): boolean {
    return this.userSelectedCount > 1;
  }

  onSubmit(): void {
    // eslint-disable-next-line no-undefined
    const isInvalid = this.inviteForm.controls.selectedUsers.value.selectedValues.find((val) => val.error && val.isActive === undefined);

    if (isInvalid) {
      return this.toastService.add([{
        closable: true,
        id: 'invalidEmail',
        message: this.translatePipe.transform('manageContacts.invalidEmail'),
        timeout: 5000,
        variant: 'error'
      }]);
    }

    this.inviteFormSubmitted = true;

    const usersToAdd = this.inviteForm.controls.selectedUsers.value.selectedValues.filter((user) => user.isActive);

    if (this.inviteForm.valid) {
      this.postWorkspacesInvite(
        this.id,
        this.inviteForm.value.permissions,
        usersToAdd.map((value) => value.email || value.value)
      );
    }
  }

  private getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('tableHeaders.name'), 'name', true),
      this.utilService.createTableHeader('Team Name', 'teamFullName', true),
      this.utilService.createTableHeader(this.translatePipe.transform('tableHeaders.email'), 'email', true),
      this.utilService.createTableHeader(this.translatePipe.transform('tableHeaders.status'), 'status', true),
      this.utilService.createTableHeader(this.translatePipe.transform('tableHeaders.permissions'), 'permissionTypeColumn', true),
      this.utilService.createTableHeader('', 'edit', false)
    ];
  }

  private getUsers(filter): void {
    this.apiService.getUsers(filter)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.allUsers = this.managePermissionsService.mapUsers(data);
      }, (err) => {
        this.loggerService.error(`Data could not be fetched: ${err}`);
      });
  }

  private getWorkspaceUsersById(id): void {
    this.loading = true;
    this.apiService.getWorkspaceUsersById(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.loading = false;
        this.users = this.managePermissionsService.mapWorkspaceUsers(data, this.isSite);
      }, (err) => {
        this.loading = false;
        this.loggerService.error(`Data could not be fetched: ${err}`);
      });
  }

  private postWorkspacesInvite(id, permissionType, emails): void {
    this.apiService.postWorkspacesInvite(id, permissionType, emails)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.getWorkspaceUsersById(this.id);

        this.toastService.add([{
          closable: true,
          id: 'postWorkspacesInviteSuccess',
          message: this.translatePipe.transform('manageContacts.grantPermissionsSuccess'),
          timeout: 5000,
          variant: 'success'
        }]);
      }, (err) => {
        this.loggerService.error(`Data could not be fetched: ${err}`);
      });

    this.inviteFormSubmitted = false;
    this.inviteForm.controls.permissions.setValue('');

    this.inviteForm.controls.selectedUsers.setValue({
      value: '',
      selectedValues: []
    });
  }

  private postWorkspaceUserById(workspaceId, userId, newPermissionType, concurrencyVersion): void {
    this.apiService.postWorkspaceUserById(workspaceId, userId, newPermissionType, concurrencyVersion)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.getWorkspaceUsersById(this.id);

        this.toastService.add([{
          closable: true,
          id: 'postWorkspaceUserByIdSuccess',
          message: this.translatePipe.transform('manageContacts.permissionsUpdatedSuccess'),
          timeout: 5000,
          variant: 'success'
        }]);
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'postWorkspaceUserByIdError',
          message: err.details.error.message,
          timeout: 5000,
          variant: 'error'
        }]);

        this.loggerService.error(`Data could not be fetched: ${err}`);
      });
  }

  private setMultipleUsers(val): void {
    this.inviteForm.controls.selectedUsers.setValue({
      value: '',
      selectedValues: [
        ...this.inviteForm.controls.selectedUsers.value.selectedValues,
        ...val.value.split(/,|;/).filter(Boolean).map((email) => ({
          option: email.trim(),
          value: email.trim()
        }))
      ]
    });
  }

  private isValid = (email): boolean => {
    // eslint-disable-next-line prefer-named-capture-group, max-len
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(String(email).toLowerCase());
  }

  private validateEmail(val): void {
    if (val?.selectedValues?.length) {
      val.selectedValues.forEach((value) => {
        if (!value.error && !this.isValid(value.email || value.option)) {
          value.error = true;
          this.hasError = true;
        }

        // eslint-disable-next-line no-undefined
        if (!value.error && value.isActive === undefined) {
          this.validateUserToRequest(val, value);
        }
      });
    }
  }

  private validateUserToRequest(val, value): void {
    this.apiService.getUserStatus(value.email || value.option)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((isActive) => {
        value.isActive = isActive;
        if (!isActive) {
          value.error = true;
          this.inviteForm.controls.selectedUsers.setValue(val);
        }

        this.showHasInactiveUsersNotification = this.inviteForm.controls.selectedUsers.value.selectedValues
          // eslint-disable-next-line no-undefined
          .some((user) => !user.isActive && user.isActive !== undefined);
      });
  }

  private removeUserFromWorkspace(userIds): void {
    if (userIds.length > 0) {
      this.loading = true;
      this.removeUsersCompleted = false;
      this.apiService.postWorkspaceRestrict(this.id, userIds)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.removeUsersCompleted = true;

          if (this.removeTeamsCompleted) {
            this.refreshGrid = false;
            this.getWorkspaceUsersById(this.id);
            this.selected = [];
          }
          else {
            this.refreshGrid = true;
          }

          this.toastService.add([{
            closable: true,
            id: 'postWorkspaceRestrictSuccess',
            message: this.translatePipe.transform('manageContacts.removeUserSuccess'),
            timeout: 5000,
            variant: 'success'
          }]);
        }, (err) => {
          this.handleRemoveUserError(err);
        });
    }
  }

  private removeTeamFromWorkspace(teamIds): void {
    if (teamIds.length > 0) {
      this.loading = true;
      this.removeTeamsCompleted = false;
      this.apiService.removeTeamsFromWorkspace(this.id, teamIds)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.removeTeamsCompleted = true;
          if (this.removeUsersCompleted) {
            this.refreshGrid = false;
            this.getWorkspaceUsersById(this.id);
            this.selected = [];
          }
          else {
            this.refreshGrid = true;
          }

          this.toastService.add([{
            closable: true,
            id: 'removeTeamSuccess',
            message: this.translatePipe.transform('manageContacts.teamRemoveSuccess'),
            timeout: 5000,
            variant: 'success'
          }]);
        }, (err) => {
          if (this.removeUsersCompleted && this.refreshGrid) {
            this.refreshGrid = false;
            this.getWorkspaceUsersById(this.id);
            this.selected = [];
          }

          if (this.removeUsersCompleted) {
            this.loading = false;
          }

          this.removeTeamsCompleted = true;
          this.toastService.add([{
            closable: true,
            id: 'removeTeamError',
            message: err.details.error.message,
            timeout: 10000,
            variant: 'error'
          }]);
        });
    }
  }

  private showTeamAndUserRemoveToast(): void {
    const StudyOrSite = this.isSite ? 'Site' : 'Study';
    if (this.teamUserToast !== null) {
      Object.keys(this.teamUserToast).forEach((key) => {
        const team = this.teamUserToast[key];
        const users = team.length > 1 ? `${team.length} users` : `${team.length} user`;
        this.toastService.add([{
          closable: true,
          id: key,
          message: this.translatePipe.transform('manageContacts.teamUserRemoveText').replace('{users}', users)
            .replace('{teamName}', team[0].teamName)
            .replace('{StudyOrSite}', StudyOrSite)
            .replace('{StudyOrSite}', StudyOrSite),
          timeout: 10000,
          variant: 'error'
        }]);
      });
    }
  }

  private handleRemoveUserError(err): void {
    this.removeUsersCompleted = true;
    this.toastService.add([{
      closable: true,
      id: 'postWorkspaceRestrictError',
      message: err.details.error.message,
      timeout: 10000,
      variant: 'error'
    }]);

    if (this.translatePipe.transform('manageContacts.automaticDowngradeStudyContactsToSiteParticipant') === err.details.error.message) {
      if (this.removeTeamsCompleted) {
        this.refreshGrid = false;
        this.getWorkspaceUsersById(this.id);
        this.selected = [];
      }
      else {
        this.refreshGrid = true;
      }
    }
    else if (this.removeTeamsCompleted && this.refreshGrid) {
      this.refreshGrid = false;
      this.getWorkspaceUsersById(this.id);
      this.selected = [];
    }

    if (this.removeTeamsCompleted) {
      this.loading = false;
    }
  }
}
