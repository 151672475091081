/* eslint-disable max-lines */
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-export-confirmation-modal',
  templateUrl: './export-confirmation-modal.component.html',
  styleUrls: ['./export-confirmation-modal.component.scss']
})
export class ExportConfirmationModalComponent {
    currentUrl: string;

    constructor(
        public router: Router,
        public translatePipe: TranslatePipe,
        public stateService: StateService
    ) {
    }

    public checkForFileLater(event = null): void {
      if (event) {
        event.stopPropagation();
      }

      this.stateService.showExportCsvModal.next(false);
      this.stateService.showDownloadModal.next(false);
      this.stateService.submissionsWithoutSubmittedDocumentsList.next([]);
    }

    public goToDownloadExportsPage(): void {
      this.stateService.showOutcomeDetailsModal.next(false);
      if (this.stateService.showExportCsvModal.value) {
        this.stateService.showExportCsvModal.next(false);
        setTimeout(() => this.router.navigateByUrl('download/download-exports'), 0);
      }
      else if (this.stateService.showDownloadModal.value) {
        this.stateService.showDownloadModal.next(false);
        this.stateService.submissionsWithoutSubmittedDocumentsList.next([]);
        setTimeout(() => this.router.navigateByUrl('download/download-documents'), 0);
      }
    }
}
