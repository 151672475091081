<div id="viewdocumentcategory">
  <div class="wcg-row">
    <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-3 add-button">
      <button class="wcg-btn wcg-btn-icon" (click)="addDocumentCategory()">
        <wcg-icon src="./assets/icons/content/ic_add_circle_outline_24px.svg"></wcg-icon>
        <span>Add New</span>
      </button>
    </div>
    <div class="wcg-col-xs-4 wcg-col-md-4 wcg-col-lg-3"></div>
    <div class="wcg-col-xs-4 wcg-col-md-6 wcg-col-lg-6">
      <form ngForm [formGroup]="searchForm" class="search-form wcg-rhythm-5">
        <wcg-form-field>
          <wcg-label>Search grid</wcg-label>
          <wcg-input formControlName="searchControl">
            <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
          </wcg-input>
        </wcg-form-field>
      </form>
    </div>
  </div>

  <wcg-table class="wcg-rhythm-4" [headers]="headers" [data]="documentCategories" [options]="options"
    (rowClicked)="handleRowClicked($event)">
  </wcg-table>
</div>


<wcg-modal id="modal" [show]="showEditRowModal" (close)="closeClick()">
  <wcg-modal-header>{{modalTitle}}</wcg-modal-header>
  <wcg-modal-body>
     <div class="wcg-row">
      <div class="wcg-col-sm-4">
        <form ngForm [formGroup]="documentCategoryForm" class="wcg-rhythm-5">
          <wcg-form-field>
            <wcg-label>Document Type Category</wcg-label>
            <wcg-input ngDefaultControl formControlName="documentTypeCategory" maxLength="100"
               [value]="documentCategoryForm.controls.documentTypeCategory.value" required></wcg-input>
            <wcg-error-message *ngIf="documentCategoryForm.controls.documentTypeCategory.invalid">
              Document type category is required
            </wcg-error-message>
          </wcg-form-field>
          <wcg-checkbox formControlName="enabled">Enabled</wcg-checkbox>
        </form>
      </div>
    </div>
  </wcg-modal-body>

  <wcg-modal-footer>
    <button class="wcg-btn wcg-btn-auto" (click)="closeClick()">Cancel</button>
    <button class="wcg-btn wcg-btn-auto wcg-btn-primary" (click)="showEditRowModal = false" (click)="saveDocumentCategory()"
          [disabled]="documentCategoryForm.invalid" class="wcg-btn wcg-btn-primary">Save</button>
  </wcg-modal-footer>
</wcg-modal>
