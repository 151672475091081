import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebApiServices } from '../helpers/webapiservice';
import { Apis } from 'src/app/common/apis';
import { UserDetailsWorkspaceGridRecordMap } from 'src/app/interfaces/user';
import { UserInfoMap, UserInfoGrid, UserInfoGridHighightData, UserAudit, UserAuditMap } from '../model/userinfo';
import { AdminApis } from '../helpers/adminapis';
import { GridOptionsSearchModel, FilterOptionModel } from '../model/grid.model';
import { UtilService } from '../../../services/util.service';

@Injectable()
export class UserAdminService {
  service: WebApiServices;

  constructor(private http: HttpClient, private utilService: UtilService) {
    this.service = new WebApiServices(this.http);
  }

  public filter = (data: Array<UserDetailsWorkspaceGridRecordMap>, term: string): Array<UserDetailsWorkspaceGridRecordMap> => {
    const termsArray = term.split(' ');

    return data.filter((record) => {
      const keys = Object.keys(record);

      const found = keys.filter((key) => {
        if (record[key]) {
          return termsArray.some((currentTerm) => record[key].toLowerCase().includes(currentTerm.toLowerCase()));
        }

        return false;
      });

      if (found.length) {
        return {
          'IRB Tracking ID': record['IRB Tracking ID'],
          'Sponsor Protocol ID': record['Sponsor Protocol ID'],
          'PI Name': record['PI Name'],
          Permissions: record.Permissions
        };
      }

      return null;
    });
  };

  public getUserDetails(id: string): Observable<any> {
    const path = `${Apis.UserDetails}`.replace('{userid}', id);

    return this.service.getService(path, null);
  }

  public getCountries(): Observable<any> {
    return this.service.getService(Apis.ContactCountries, null);
  }

  public getStates(id: string): Observable<any> {
    const path = `${Apis.ContactStates}`.replace('{countryid}', id);

    return this.service.getService(path, null);
  }

  public getOrganizations(filter): Observable<any> {
    const path = `${Apis.ContactOrganizationsSearch}?filter=${filter}`;

    return this.service.getService(path, null);
  }

  public getOrganizationTypes(): Observable<any> {
    return this.service.getService(Apis.ContactOrganizationTypes, null);
  }

  public getUserRoles(): Observable<any> {
    return this.service.getService(Apis.UserRoles, null);
  }

  public postUser(data): Observable<any> {
    return this.service.postService(Apis.UserProfile, data, null);
  }

  public postResetPassword(email: string): Observable<any> {
    return this.service
      .postService(
        Apis.UserResetPassword,
        {
          email: email
        },
        null
      );
  }

  public postSetUsersStatus(userIdList: string[], isDeleted: boolean): Observable<any> {
    const path = `${Apis.SetUsersStatus}`.replace('{isDeleted}', isDeleted.toString());

    return this.service
      .postService(
        path,
        userIdList,
        null
      );
  }

  public postUserInfo(pageIndex: number, pageSize: number,
    sortOptions: any, filterOptions: FilterOptionModel[], searchTerm: string,
    startDate: any, endDate: any): Observable<any> {
    const parameters = this.getParameters(pageIndex, pageSize, sortOptions, filterOptions, searchTerm, startDate, endDate);

    return this.service.postService(AdminApis.GetUserInfo, parameters, null);
  }

  public mapUserInfoGridData(data: UserInfoGrid, highlightData: UserInfoGridHighightData): Array<UserInfoMap> {
    return data.records.map((record) => ({
      id: record.externalReferenceId,
      firstName: `<div class="wcg-link table-cell-inner" edit-row-button title="${record.firstName}">\
                  ${this.utilService.addTableCellWrapperWithSearchTerms(record.firstName, highlightData.searchTerm)}</div>`,
      email: this.utilService.addTableCellWrapperWithSearchTerms(record.email, highlightData.searchTerm),
      lastName: `<div class="wcg-link table-cell-inner" edit-row-button title="${record.lastName}">\
                  ${this.utilService.addTableCellWrapperWithSearchTerms(record.lastName, highlightData.searchTerm)}</div>`,
      organizationName: this.utilService.addTableCellWrapperWithSearchTerms(record.organizationName, highlightData.searchTerm),
      organizationType: this.utilService.addTableCellWrapperWithSearchTerms(record.organizationType, highlightData.searchTerm),
      lastActivityDate: this.utilService.highlightDateFields(record.lastActivityDate?.toString(),
        highlightData.startDate, highlightData.endDate),
      createdDateTimeOffset: this.utilService.highlightDateFields(record.createdDateTimeOffset.toString(),
        highlightData.startDate, highlightData.endDate),
      enabled: record.isDeleted === false ?
        '<div class="done_icon"><wcg-icon src="./assets/icons/action/ic_done_24px.svg"></wcg-icon></div>' :
        '<wcg-icon src="./assets/icons/content/ic_clear_24px.svg"></wcg-icon>',
      isDeleted: record.isDeleted,
      canBeImpersonated: record.canBeImpersonated,
      isActive: record.isActive
    }));
  }

  public getUserAuditData(irbTracking, body): Observable<any> {
    const url = `${Apis.GetUserAuditData}`.replace('{irbTracking}', `${irbTracking}`);

    return this.service.postService(url, body, null);
  }

  public mapUserAuditGridData(data: Array<UserAudit>): Array<UserAuditMap> { // eslint-disable-line class-methods-use-this
    return data.map((record) => ({
      entityId: record.irisId,
      siteName: record.siteName,
      accessLevel: record.permissionType,
      auditAction: record.auditReason,
      modifiedBy: this.utilService.addTableCellWrapper(record.lastModifiedBy),
      modifiedDate: this.utilService.addTableCellWrapper(this.utilService.formatDateTime(record.lastModifiedDateTimeOffset?.toString())),
      downloadDocs: record.downloadedDocs
    }));
  }

  private getParameters = (
    pageIndex: number,
    pageSize: number,
    sortOptions: any,
    filterOptions: FilterOptionModel[],
    searchTerm: string,
    startDate: any,
    endDate: any
  ): GridOptionsSearchModel => {
    const gridOptionsModel: GridOptionsSearchModel = {
      page: pageIndex,
      pageSize: pageSize,
      searchTerm: searchTerm,
      filterOptions: filterOptions,
      sortOptions: sortOptions,
      startDate: startDate,
      endDate: endDate
    };

    return gridOptionsModel;
  }
}
