import { Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { ResourcesComponent } from '../views/resources/resources.component';

export const resourcesRoutes: Routes = [
  {
    canActivate: [
      AuthGuard
    ],
    path: 'resources',
    component: ResourcesComponent
  }
];
