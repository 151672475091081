/* eslint-disable max-len */
const FORMIO_FORMS = {
  administrativeActionForm3: {
    form: 'administrativeActionForm3',
    fields: {
      relatedDocs: 'areAllRelatedDocumentsIncludingResolutionStepsOnFileWithThisIrb'
    }
  },
  acknowledgements: {
    fields: {
      recruitmentBonuses: 'willThePrincipalInvestigatorPiOrResearchTeamBeOfferedRecruitmentBonuses',
      financialDisclosure: 'setupFinancialDisclosurePrincipalInvestigatorRadio'
    }
  },
  changeInResearchForm: {
    fields: {
      whatChangesAreYouMaking: {
        key: 'whatChangesAreYouMakingSelectAllThatApply',
        fields: {
          recruitmentBonuses: 'recruitmentBonusesExtraPaymentsToSitesTiedToTheRateOrTimingOfRecruitmentOrEnrollment'
        }
      }
    }
  },
  clinicalTrialInformation: {
    form: 'clinicalTrialInformation',
    fields: {
      investigatorBrochure: 'theIrbShouldUseTheInvestigatorBrochureSThat',
      productLabeling: 'theIrbShouldUseTheProductLabelingForTheInvestigationalDeviceSThat',
      ind: 'doesTheInvestigatorHoldTheInd',
      ide: 'investigatorHoldTheIde',
      showSrNsrDeterminationName: 'haveYouSubmittedThisDeviceToFdaForAnSrNsrDetermination'
    }
  },
  federalFundingForm: {
    form: 'federalFundingForm',
    fields: {
      fundedResearch: 'panelIsthisresearchfundedsupportedorconductedbyaUnitedStatesfederaldepartmentoragency',
      federalDepartment: 'panelSelectthefederaldepartmentoragencyfundingtheresearch'
    }
  },
  piForm: {
    form: 'PiForm',
    fields: {
      first: 'first',
      last: 'last',
      middleName: 'middleName',
      degrees: 'degrees',
      suffix: 'suffix',
      prefix: 'prefix'
    },
    addressForm: {
      form: 'addressForm',
      fields: {
        addressLine1: 'addressLine1',
        addressLine2: 'addressLine2',
        city: 'city',
        companyInstitutionOrganization: 'companyInstitutionOrganization',
        country: 'country',
        postalCode: 'postalCode',
        province: 'province',
        state: 'state',
        stateProvince: 'stateProvince'
      }
    }
  },
  populationTransferForm: {
    form: 'populationTransferForm',
    fields: {
      transferOversight: 'transferringIrbOversightFromAnotherIrb'
    }
  },
  principalInvestigatorLicensure: {
    form: 'principalInvestigatorLicensure',
    fields: {
      license: 'areAllMedicalLicensesOnFileWithTheIrb'
    }
  },
  consent: {
    form: 'consentForm3',
    fields: {
      provideConsent: 'provideInformedConsent'
    }
  },
  principalInvestigatorPiTransfer3: {
    form: 'principalInvestigatorPiTransfer3',
    fields: {
      oversight: 'isThePrincipalInvestigatorPiTakingOverOversightFromAnotherPi'
    }
  },
  promptlyReportableDisclosure: {
    form: 'promptlyReportableDisclosure',
    fields: {
      problemCategory: 'WhatcategorybestdescribesthisproblemInformationnotlistedbelowdoesnotrequirereportingtotheIrb',
      consentChange: 'willTheProtocolOrConsentFormBeChangedBecauseOfThisReport'
    }
  },
  protocolInfo2: {
    form: 'protocolInfo2',
    fields: {
      submissionName: 'submissionName',
      studyName: 'studyName',
      sponsor: 'sponsor',
      sponsorsProtocolId: 'sponsorsProtocolId'
    }
  },
  protocolInfoSite: {
    form: 'protocolInfoSite',
    fields: {
      submissionName: 'submissionName',
      sponsorsProtocolId: 'sponsorsProtocolId',
      protocolTitle: 'protocolTitle'
    }
  },
  researchLocationFo3: {
    form: 'researchLocationFo3',
    fields: {
      jurisdiction: 'doesALocalIrbHaveJurisdictionOverResearch'
    }
  },
  researchPersonnel: {
    form: 'researchPersonnel',
    fields: {
      training: 'thePrincipalInvestigatorIsResponsibleForEnsuringTrainingOfNewPersonnelAsDescribedInTheInitialReview'
    }
  },
  siteClosureForm: {
    fields: {
      piName: 'piName',
      irbTrackingId: 'irbTrackingId',
      protocolConsent: 'haveYouImplementedAnyChangeToTheProtocolConsentOrMaterialsSeenBySubjectsThatThisIrbHasNotYetApproved',
      policyReporting: 'isThereAnyInformationThatRequiredReportingPerIrbAHrefHttpsWwwWcgirbComPolicyReportingRequirementsAThatHasNotYetBeenReportedToThisIrb'
    }
  },
  translationRequestForm: {
    form: 'Translation Request',
    fields: {
      translatedDocuments: 'willYouNeedTranslatedDocumentsOrApprovalOfTranslatedDocuments'
    }
  },
  translationRequestSubmissionDetails: {
    form: 'translationRequestSubmissionDetails',
    fields: {
      typeOfSubmission: 'typeOfSubmission'
    }
  },
  whatChangesAreYouMaking: {
    form: 'whatChangesAreYouMakingSelectAllThatApply',
    fields: {
      recruitmentBonus: 'recruitmentBonusesExtraPaymentsToSitesTiedToTheRateOrTimingOfRecruitmentOrEnrollment',
      translationRequest: 'translationRequestOrRequestForApprovalOfTranslatedDocuments'
    }
  }
};

export const {
  administrativeActionForm3,
  acknowledgements,
  changeInResearchForm,
  clinicalTrialInformation,
  federalFundingForm,
  piForm,
  populationTransferForm,
  principalInvestigatorLicensure,
  consent,
  principalInvestigatorPiTransfer3,
  promptlyReportableDisclosure,
  protocolInfo2,
  protocolInfoSite,
  researchLocationFo3,
  researchPersonnel,
  siteClosureForm,
  translationRequestForm,
  translationRequestSubmissionDetails,
  whatChangesAreYouMaking
} = FORMIO_FORMS;
