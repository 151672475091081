import { Injectable } from '@angular/core';
import { StorageModel } from 'src/app/models/storage.model';

@Injectable()
export class StorageService {
  public constructor() {
  }

  public setKey(item: StorageModel): void {
    const storage: Storage = this.getStorage(item.isSessionStorage);
    storage.setItem(item.key, this.formatData(item.value));
  }


  public getAllKeys = (): string[] => {
    const keys: string[] = [];

    for (let loc = 0; loc < localStorage.length; loc++) {
      keys.push(localStorage.key(loc));
    }

    return keys;
  }

  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  public getKey<T>(key: string, isSessionStorage?: boolean): T {
    const storage: Storage = this.getStorage(Boolean(isSessionStorage));

    try {
      return JSON.parse(storage.getItem(key));
    }
    catch (exce) {
      return <T><any>storage.getItem(key);
    }
  }

  public removeKey(key: string, isSessionStorage?: boolean): void {
    const storage: Storage = this.getStorage(isSessionStorage);
    storage.removeItem(key);
  }

  private getStorage = (isSessionStorage: boolean): Storage => {
    if (isSessionStorage) {
      return window.sessionStorage;
    }

    return localStorage;
  }

  private formatData = (value: object | string): string => {
    let data: string;

    if (typeof value === 'object') {
      data = JSON.stringify(value);
    }
    else {
      data = value;
    }

    return data;
  }
}
