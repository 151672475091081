<div class="wcg-rhythm-5 pl-140">

  <div class="wcg-row">
    <div class="wcg-col-xs-4 wcg-col-lg-2 users-icon">
      <wcg-icon src="../assets/icons/social/ic_person_24px.svg"></wcg-icon>
      <h1 class="wcg-h1"><span>Users</span></h1>
    </div>
    <div class="wcg-col-xs-1">
      <a routerLink="/admin/users/manage-user-contacts" class="wcg-btn wcg-btn-large cnxs-page-header-btn">
        {{ 'manageContacts.manageContacts' | translate }}
      </a>
    </div>
  </div>

  <form [formGroup]="filtersForm" class="wcg-row" autocomplete="off">

    <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
      <wcg-form-field class="filter-form-field">
        <wcg-label>Organization Type</wcg-label>
        <wcg-select formControlName="organizationType" (change)="organizationTypeChange($event)">
          <option selected>All organization types</option>
          <option *ngFor="let type of organizationTypesFilter" value={{type.value}}>
            {{type.value}}</option>
        </wcg-select>
      </wcg-form-field>
    </div>

    <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
      <wcg-form-field class="filter-form-field">
        <wcg-label>Status</wcg-label>
        <wcg-select formControlName="userStatus" (change)="statusChange($event)">
          <option selected>All status</option>
          <option *ngFor="let status of userStatusFilter" value={{status}}>{{status}}</option>
        </wcg-select>
      </wcg-form-field>
    </div>

    <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
      <ng2-flatpickr class="wcg-input-with-icon" #startDatePicker placeholder="Start Date" [config]="datePickerOptions"
        formControlName="startDateFilter">
        <wcg-icon class="date-icon" (click)="toggleDatePicker('startDatePicker')"
          src="./assets/icons/action/ic_date_range_24px.svg">
        </wcg-icon>
      </ng2-flatpickr>
    </div>

    <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
      <ng2-flatpickr class="wcg-input-with-icon" #endDatePicker placeholder="End Date" [config]="datePickerOptions"
        formControlName="endDateFilter">
        <wcg-icon class="date-icon" (click)="toggleDatePicker('endDatePicker')"
          src="./assets/icons/action/ic_date_range_24px.svg">
        </wcg-icon>
      </ng2-flatpickr>
    </div>

    <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-3">
      <wcg-form-field>
        <wcg-label>Search grid</wcg-label>
        <wcg-input formControlName="searchControl">
          <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
        </wcg-input>
      </wcg-form-field>
    </div>
  </form>

  <div class="wcg-row">
    <div class="wcg-col-xs-4 wcg-col-md-12 wcg-col-lg-12 wcg-rhythm-2">
      <div class="export-button-wrap">
        <app-export-action-button [gridFilters]="gridFilters" [disableButton]="!allData?.length || isExportDisabled" [exportType]="exportType"></app-export-action-button>
      </div>
    </div>
  </div>

  <wcg-card class="wcg-col-xs-4 wcg-col-xl-12 card-selection" *ngIf="selected.length > 0">
    <wcg-card-body>
      <span class="mr-60">{{selectionLabel}}</span>
      <button class="mr-25 w-200 wcg-btn wcg-btn-large wcg-btn-primary" (click)="usersStatusUpdated()">
        {{statusButtonLabel}}
      </button>
      <button class="mr-25 wcg-btn wcg-btn-large wcg-btn-primary" (click)="resetSelected()">Reset Password
      </button>
      <button [disabled]="disabledImpersonationButton" class="mr-25 wcg-btn wcg-btn-large wcg-btn-primary"
        (click)="impersonateSelected()">
        Impersonate
      </button>
    </wcg-card-body>
  </wcg-card>

  <wcg-table class="wcg-rhythm-4" [ngClass]="{'cnxs-table-loading': loading}" [headers]="headers" [data]="allData"
    [options]="options" (rowClicked)="handleRowClicked($event)" (rowSelected)="handleRowSelection($event)"
    (sortClicked)="handleSort($event)">

    <div class="cnxs-table-loader" slot="table-body">
      <wcg-loader [show]="!loaded || loading"></wcg-loader>
    </div>
    
    <div class="cnxs-pagination-wrap">
      <wcg-pagination *ngIf="loaded && allData?.length" [current]="currentPage" [pageSize]="currentPageSize"
        [max]="utilService.maxPages(totalRecords, currentPageSize)" (changePage)="handlePageChange($event)"></wcg-pagination>
    </div>
  </wcg-table>
</div>
