<form [formGroup]="filtersForm" class="wcg-row wcg-rhythm-2" autocomplete="off">
  <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-3 add-button">
    <button type="button" class="wcg-btn wcg-btn-icon" (click)="addNewSubmissionType()">
      <wcg-icon src="../assets/icons/content/ic_add_circle_outline_24px.svg"></wcg-icon>
      <span>Add New</span>
    </button>
  </div>
  <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-3">
    <wcg-form-field class="filter-form-field">
      <wcg-label>Form type</wcg-label>
      <wcg-select formControlName="formType" [value]="filtersForm.controls['formType'].value"
        (change)="handleChange(filtersForm, 'formType', $event)">
        <option selected value="-1">All form types</option>
        <option *ngFor="let type of formTypesFilter" value={{type.id}}>{{type.value}}</option>
      </wcg-select>
    </wcg-form-field>
  </div>
  <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-3">
    <wcg-form-field class="filter-form-field">
      <wcg-label>Submission type group</wcg-label>
      <wcg-select formControlName="submissionGroup" [value]="filtersForm.controls['submissionGroup'].value"
        (change)="handleChange(filtersForm, 'submissionGroup', $event)">
        <option value="-1">All groups</option>
        <option *ngFor="let group of submissionTypeGroupsFilter" value={{group.id}}>{{group.value}}</option>
      </wcg-select>
    </wcg-form-field>
  </div>
  <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-3">
    <wcg-form-field class="search-form">
      <wcg-label>Search grid</wcg-label>
      <wcg-input formControlName="searchTerm">
        <wcg-icon src="../assets/icons/action/ic_search_24px.svg"></wcg-icon>
      </wcg-input>
    </wcg-form-field>
  </div>
</form>

<wcg-table class="wcg-rhythm-4" [ngClass]="{'cnxs-table-loading': loading}" [headers]="headers" [data]="filteredData"
  [options]="options" (rowClicked)="handleRowClicked($event)">
  <div class="cnxs-table-loader" slot="table-body">
    <wcg-loader [show]="loading"></wcg-loader>
  </div>
</wcg-table>

<div class="wcg-rhythm-1" *ngIf="tableErrorMessage">
  <wcg-toast class="wcg-rhythm-1" variant="error">{{tableErrorMessage}}</wcg-toast>
</div>

<!-- "Add New" modal window -->
<wcg-modal [show]="showModalWindow" fullscreen="false" (close)="closeOverlay();">
  <wcg-modal-header>
    <wcg-icon [hidden]="!isEditAction" src="../assets/icons/content/ic_create_24px.svg"></wcg-icon>
    <wcg-icon [hidden]="isEditAction" src="../assets/icons/content/ic_add_circle_outline_24px.svg"></wcg-icon>
    <span class="verticalTop">{{modalTitle}}</span>
  </wcg-modal-header>
  <form #modalForm="ngForm" class="modal-row" name="submissionTypeForm" (ngSubmit)="onSubmit()"
    [formGroup]="submissionTypeForm">
    <wcg-modal-body>
      <wcg-label class="explanation">Required *</wcg-label>
      <div class="modal-row wcg-rhythm-2">
        <div class="width50 left-col">
          <wcg-label>Submission Type Name *</wcg-label>
          <wcg-input type="text" formControlName="submissionTypeName" placeholder="Enter a submission type name"
            required></wcg-input>
          <div *ngIf="modalForm.submitted">
            <wcg-error-message *ngIf="submissionTypeForm.controls.submissionTypeName.hasError('required')">
              Submission Type Name is required
            </wcg-error-message>
            <wcg-error-message *ngIf="submissionTypeForm.controls.submissionTypeName.hasError('pattern')">
              Submission Type Name should contain only alphanumeric values
            </wcg-error-message>
            <wcg-error-message *ngIf="submissionTypeForm.controls.submissionTypeName.hasError('maxlength')">
              Submission Type Name should contain maximum 255 characters
            </wcg-error-message>
          </div>
        </div>
        <div class="width50 right-col">
          <wcg-label>Envelope and Email Type *</wcg-label>
          <wcg-input type="text" placeholder="Enter an envelope and email type" formControlName="envelopeAndEmailType"
            required></wcg-input>
          <div *ngIf="modalForm.submitted">
            <wcg-error-message *ngIf="submissionTypeForm.controls.envelopeAndEmailType.hasError('required')">
              Envelope and Email Type is required
            </wcg-error-message>
            <wcg-error-message *ngIf="submissionTypeForm.controls.envelopeAndEmailType.hasError('pattern')">
              Envelope and Email Type should contain only alphanumeric values
            </wcg-error-message>
            <wcg-error-message *ngIf="submissionTypeForm.controls.envelopeAndEmailType.hasError('maxlength')">
              Envelope and Email Type should contain maximum 255 characters
            </wcg-error-message>
          </div>
        </div>
      </div>

      <div class="modal-row wcg-rhythm-2">
        <div class="width50 left-col">
          <wcg-form-field>
            <wcg-label>Form Type *</wcg-label>
            <wcg-select [formControl]="submissionTypeForm.controls.formTypeControl"
              (change)="handleChangeFormType($event)">
              <option hidden value="-1">Select a form type</option>
              <option *ngFor="let formType of formTypes"
                [selected]="submissionTypeForm.value.formTypeControl == formType.id" [value]="formType.id">
                {{formType.value}}</option>
            </wcg-select>
          </wcg-form-field>
          <wcg-error-message *ngIf="submissionTypeForm.controls.formTypeControl.hasError('min') && modalForm.submitted">
            Form Type is required
          </wcg-error-message>
        </div>
        <div class="width50 right-col">
          <wcg-form-field>
            <wcg-label>IRIS Routing Group *</wcg-label>
            <wcg-select [formControl]="submissionTypeForm.controls.irisRoutingGroupControl"
              (change)="handleChangeIrisRoutingGroup($event)">
              <option hidden value="-1">Select an IRIS Routing Group</option>
              <option *ngFor="let irisRoutingGroup of irisRoutingGroups"
                [selected]="irisRoutingGroup.id == submissionTypeForm.value.irisRoutingGroupControl"
                [value]="irisRoutingGroup.id">
                {{irisRoutingGroup.name}}</option>
            </wcg-select>
          </wcg-form-field>
          <wcg-error-message *ngIf="submissionTypeForm.controls.irisRoutingGroupControl.hasError('min') &&
            modalForm.submitted">
            IRIS Routing Group is required
          </wcg-error-message>
        </div>
      </div>

      <div class="modal-row wcg-rhythm-2">
        <div class="width50 left-col">
          <wcg-label>Submission Type Group</wcg-label>
          <wcg-card class="multipleCheckboxes">
            <wcg-card-body>
              <div formArrayName="submissionsTypeGroup"
                *ngFor="let typeGroup of submissionTypeForm.controls.submissionsTypeGroup['controls']; let i = index">
                <wcg-checkbox [formControlName]="i" ngDefaultControl>{{submissionsTypeGroup[i].value}}
                </wcg-checkbox>
              </div>
            </wcg-card-body>
          </wcg-card>
        </div>
        <div class="width50 right-col">
          <wcg-label>Document Types *</wcg-label>
          <wcg-card class="multipleCheckboxes">
            <wcg-card-body>
              <div formArrayName="documentTypes"
                *ngFor="let documentType of submissionTypeForm.controls.documentTypes['controls']; let i = index">
                <wcg-checkbox required (change)="onSelectDocumentTypesRefreshDefaultDocumentTypes()"
                  [formControlName]="i" ngDefaultControl>
                  {{documentTypes[i].value}}
                </wcg-checkbox>
              </div>
            </wcg-card-body>
          </wcg-card>
          <wcg-error-message *ngIf="submissionTypeForm.controls.documentTypes.invalid && modalForm.submitted">
            Document Types is required
          </wcg-error-message>
        </div>
      </div>
      <div class="modal-row wcg-rhythm-2">
        <div class="width50 left-col">
          <wcg-checkbox formControlName="isEnabled">Enabled</wcg-checkbox>
        </div>
        <div class="width50 right-col">
          <wcg-form-field>
            <wcg-label>Form.io Default Document Types *</wcg-label>
            <wcg-select [formControl]="submissionTypeForm.controls.selectedDefaultDocumentTypeControl"
              (change)="handleChangeDefaultDocumentType($event)">
              <option hidden value="-1">Select the form.io default document type *</option>
              <option *ngFor="let selectedDoc of docTypesSelected"
                 [value]="selectedDoc.id" [selected]="submissionTypeForm.controls.selectedDefaultDocumentTypeControl.value == selectedDoc.id">
                {{selectedDoc.value}}</option>
            </wcg-select>
          </wcg-form-field>
          <wcg-error-message *ngIf="submissionTypeForm.controls.formTypeControl.value==0 && submissionTypeForm.controls.selectedDefaultDocumentTypeControl.hasError('min') &&
            modalForm.submitted">
            Form.io default document type is required
          </wcg-error-message>
        </div>
      </div>
      <div *ngIf="modalErrorMessage">
        <wcg-toast variant="error">{{modalErrorMessage}}</wcg-toast>
      </div>
    </wcg-modal-body>
    <wcg-modal-footer class="footer">
      <button type="submit" class="wcg-btn wcg-btn-auto wcg-btn-primary floatRight">Save</button>
    </wcg-modal-footer>
  </form>
</wcg-modal>