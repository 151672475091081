import { FormsModule } from '@angular/forms';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginComponent } from './components/auto-login.component';
import { LogoutCallbackComponent } from './components/logout-callback.component';
import { LoginCallbackComponent } from './components/login-callback.component';
import { SilentRenewComponent } from './components/silent-renew.component';
import { AuthenticationService } from './services/authentication/authentication.service';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { AuthGuard } from '../guards/auth.guard';
import { SharedModule } from '../shared.module';
import { StorageService } from './services/storage.service';

const routes: Routes = [
  {
    path: 'autologin',
    component: AutoLoginComponent
  },
  {
    path: 'callback',
    component: LoginCallbackComponent
  },
  {
    path: 'logout-callback',
    component: LogoutCallbackComponent
  },
  {
    path: 'silent-renew',
    component: SilentRenewComponent
  },
  {
    path: 'registration',
    component: UserRegistrationComponent,
    canActivate: [
      AuthGuard
    ],
    data: {
      title: 'User registration',
      display: true
    }
  }
];

@NgModule({
  declarations: [
    AutoLoginComponent,
    LoginCallbackComponent,
    LogoutCallbackComponent,
    SilentRenewComponent,
    UserRegistrationComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forChild(routes),
    SharedModule
  ],
  exports: [
    AutoLoginComponent,
    LoginCallbackComponent,
    LogoutCallbackComponent,
    SilentRenewComponent,
    UserRegistrationComponent,
    SharedModule
  ],
  providers: [
    AuthenticationService,
    StorageService
  ]
})
export class CoreModule {
}
