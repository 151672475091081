import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ServerErrorCodes } from 'src/app/common/collections';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { ApiService } from 'src/app/services/api.service';
import { StateService } from 'src/app/services/state.service';
import { ToastService } from 'src/app/services/toast.service';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-outcome-documents-download',
  templateUrl: './outcome-documents-download.component.html',
  styleUrls: ['./outcome-documents-download.component.scss']
})
export class OutcomeDocumentsDownloadComponent extends BaseComponent {
  odsId: string;
  downloadAll = true;
  pageUnauthorized = false;

  constructor(
    private router: Router,
    private stateService: StateService,
    private apiService: ApiService,
    private translatePipe: TranslatePipe,
    private toastService: ToastService
  ) {
    super();

    [this.odsId] = this.router.url.split('/dashboard/')[1].split('/');
    this.downloadRequestForOutcomeDocuments(this.odsId);
  }

  private downloadRequestForOutcomeDocuments(id): void {
    if (id.trim()) {
      this.apiService.downloadDocumentsFromEmail(id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.downloadAll = false;
          this.stateService.showDownloadModal.next(true);
          this.router.navigate(['/dashboard']);
        }, (error) => {
          this.downloadAll = false;
          if (error.details.status === ServerErrorCodes.unauthorizedStatusNumber) {
            this.pageUnauthorized = true;
          }
          else {
            this.toastService.add([{
              closable: true,
              id: 'DownloadOutcomeDocumentsFromEmail',
              message: this.translatePipe.transform('serverErrors.internalServerError'),
              variant: 'error'
            }]);
            this.router.navigate(['/dashboard']);
          }
        });
    }
  }
}
