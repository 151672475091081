<div class="wcg-container cnxs-loader-container">
<wcg-modal class="create-team-modal" id="modal" [show]="addTeamMemeberModal" (close)="closeClick()">
    <wcg-modal-header>
        <span class="verticalTop">{{ 'teams.addNewTeamMember' | translate}}</span>
    </wcg-modal-header>
    <form #modalForm="ngForm" class="modal-row" name="teamMembersForm" [formGroup]="teamMembersForm">
        <wcg-toast variant="error" position="top" *ngIf="addTeamMemberFailed">{{ addTeamMemberFailedMessage }}</wcg-toast>
<wcg-modal-body>
    <div class="wcg-container plr-0">
      <div class="wcg-row wcg-rhythm-5">
        <div class="wcg-col-xs-4">
          <h3 class="wcg-h3 wcg-rhythm-1 cnxs-wrap-content">{{ 'teams.addTeamMemberHeader' | translate}}</h3>
          <wcg-label >{{ 'teams.addTeamMemberDisclaimer' | translate}}</wcg-label>
        </div>
      </div>
        
        <div class="wcg-row wcg-rhythm-5">
          <div class="wcg-col-xs-4">
            <wcg-form-field class="user-dropdown-list">
                <wcg-label class="custom-level" *ngIf="isAdmin">{{ 'teams.addTeamMember' | translate }}</wcg-label>
                <wcg-label class="custom-level" *ngIf="isAdmin === false && canManageUsers === true">{{ 'teams.addTeamMemberByManager' | translate }}</wcg-label>
                <wcg-autocomplete formControlName="teamMember" [multiple]="true" [options]="allTeamUsers" required>
                </wcg-autocomplete>
                <div
                  *ngIf="((teamMembersForm.controls.teamMember.dirty || teamMembersForm.controls.teamMember.touched))">
                  <wcg-error-message *ngIf="!teamMembersForm.controls.teamMember.valid">
                    {{ 'validation.required' | translate: { fieldname: ('teams.teamMember' | translate) } }}
                  </wcg-error-message>
                </div>
                <div *ngIf="hasinvalidEmails">
                  <wcg-error-message>
                    {{ 'teams.addTeamMemberEmailError' | translate }}
                  </wcg-error-message>
                </div>
              </wcg-form-field>
        </div>
        </div>
        <div class="wcg-row wcg-rhythm-3">
          <div class="wcg-col-xs-4 z-index-1">
            <wcg-label> {{ 'teams.addTeamMemberWorkspacePermissionText' | translate }}</wcg-label>
            <wcg-form-field>
                <wcg-label>{{ 'teams.addTeamMemberWorkspacePermissionLabel' | translate }}</wcg-label>
                <wcg-select formControlName="workspacePermission" (change)="handleworkspacePermissionChange($event)">
                  <option value="" [selected]="teamMembersForm.controls.workspacePermission.value==''"></option>
                  <option *ngFor="let workspacePermission of workspacePermissionOptions"
                    [selected]="workspacePermission.id == teamMembersForm.controls.workspacePermission.value" [value]="workspacePermission.id">
                    {{workspacePermission.value}}</option>
                </wcg-select>
                <div *ngIf="
                  (teamMembersForm.controls.workspacePermission.dirty || teamMembersForm.controls.workspacePermission.touched)">
                  <wcg-error-message *ngIf="teamMembersForm.controls.workspacePermission.hasError('required')">
                    Study and Site Permission Level is required
                  </wcg-error-message>
                </div>
              </wcg-form-field>
        </div>
        </div>
        <div class="row">
          <div class="wcg-col-xs-4 plr-0">
            <div class="wcg-rhythm-2">
              <wcg-label >{{ 'teams.teamPermissionLevel' | translate }}</wcg-label>
            </div>
            
            <wcg-radio ngDefaultControl (change)="changeRadio($event)" [checked]="teamPermissionRadio === 'View'" name="team-permission" value="View" selected >{{ 'teams.readOnly' | translate}}</wcg-radio>
            <wcg-radio ngDefaultControl (change)="changeRadio($event)" [checked]="teamPermissionRadio === 'Manage'" name="team-permission" value="Manage">{{ 'teams.manager' | translate}}</wcg-radio>
          </div>
        </div>
      </div>
</wcg-modal-body>
    <wcg-modal-footer class="display-block">
        <button type="button" class="wcg-btn wcg-btn-auto" (click)="closeClick()">{{ 'teams.cancel' | translate}}</button>
        <button class="wcg-btn wcg-btn-auto wcg-btn-primary float-right ml-10" (click)="saveTeamMembers($event, true)" [disabled]="modalForm.invalid || isSaving || hasinvalidEmails" >{{ 'teams.addTeamMemberBtn' | translate}}</button>
        <button class="wcg-btn wcg-btn-auto float-right" (click)="saveTeamMembers($event, false)" [disabled]="modalForm.invalid || isSaving || hasinvalidEmails"  >{{ 'teams.addAnother' | translate}}</button>
        
    </wcg-modal-footer>
</form>
</wcg-modal> 

<div class="wcg-row">
  <div class="wcg-col-xs-3 wcg-col-md-3 wcg-col-lg-3 add-btn">
    <button *ngIf="isTeamActive && !hasSelectedRows && (canManageUsers || isAdmin)" class="wcg-btn wcg-btn-icon" (click)="addTeamMember()">
      <wcg-icon src="./assets/icons/content/ic_add_circle_outline_24px.svg"></wcg-icon>
      <span>{{ 'teams.addUserToTeam' | translate }}</span>
    </button>
    <button *ngIf="isTeamActive && hasSelectedRows && (canManageUsers || isAdmin)" class="wcg-btn wcg-btn-icon remove-user" (click)="deleteSelectedRows()">
      <wcg-icon src="./assets/icons/content/ic_remove_circle_24px.svg"></wcg-icon>
      <span>{{ 'teams.removeUserBtn' | translate }}</span>
    </button>
  </div>
  <div class="wcg-col-xs-9 wcg-col-md-9 wcg-col-lg-9">
    <form ngForm class="wcg-row">
      <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">

      </div>
      <div *ngIf="(teamMembersFetched && initialTeamMembers?.records?.length)" class="wcg-col-xs-3 wcg-col-md-3 wcg-col-lg-3">
        <ng2-flatpickr class="wcg-input-with-icon" #startDatePicker placeholder="Start Date" [config]="datePickerOptions"
          [formControl]="startDateFilter">
          <wcg-icon class="date-icon" (click)="toggleStartDate()" src="./assets/icons/action/ic_date_range_24px.svg">
          </wcg-icon>
        </ng2-flatpickr>
      </div>
      <div *ngIf="(teamMembersFetched && initialTeamMembers?.records?.length)" class="wcg-col-xs-3 wcg-col-md-3 wcg-col-lg-3">
        <ng2-flatpickr class="wcg-input-with-icon" #endDatePicker placeholder="End Date" [config]="datePickerOptions"
          [formControl]="endDateFilter">
          <wcg-icon class="date-icon" (click)="toggleEndDate()" src="./assets/icons/action/ic_date_range_24px.svg">
          </wcg-icon>
        </ng2-flatpickr>
      </div>
      <div *ngIf="(teamMembersFetched && initialTeamMembers?.records?.length)" class="wcg-col-xs-4 wcg-col-md-4 wcg-col-lg-4">
        <wcg-form-field>
          <wcg-label>{{ 'actions.search' | translate }}</wcg-label>
          <wcg-input [formControl]="searchTerm" [value]="searchTerm.value">
            <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
          </wcg-input>
        </wcg-form-field>
      </div>
    </form>
  </div>
</div>
<br>
<br>
<wcg-table
      *ngIf="(teamMembersFetched && initialTeamMembers?.records?.length)" 
      class="wcg-rhythm-4"
      [ngClass]="{'cnxs-table-loading': loading, 'manager-table': canManageUsers || isAdmin, 'user-table': !canManageUsers && !isAdmin}"
      [headers]="headers"
      [data]="tableData"
      [options]="options"
      (sortClicked)="handleSort($event)"
      (rowSelected)="handleRowSelection($event)"
      (rowClicked)="handleRowClicked($event)">
  
      <div class="cnxs-table-loader" slot="table-body">
        <wcg-loader [show]="!loaded || loading"></wcg-loader>
      </div>
      <div class="cnxs-pagination-wrap">
        <wcg-pagination *ngIf="loaded && tableData?.length" 
          [current]="currentPage" [pageSize]="currentPageSize"
          [max]="utilService.maxPages(totalRecords, currentPageSize)" (changePage)="handlePageChange($event)">
        </wcg-pagination>
      </div>
    </wcg-table>

    <wcg-modal id="primaryErrorModal" [show]="showPrimaryUserSelectedModal" (close)="closePrimaryErrorModal()">
      <wcg-modal-header>{{ 'teams.deleteUserModalHeader' | translate  }}
      </wcg-modal-header>
      <wcg-modal-body>
        <p>{{ 'teams.deletePrimaryMessage' | translate }}</p>
      </wcg-modal-body>
      <wcg-modal-footer>
        <button class="wcg-btn" (click)="closePrimaryErrorModal()">{{ 'actions.okay' | translate }}</button>
      </wcg-modal-footer>
    </wcg-modal>
  
    <wcg-modal id="deleteUserModal" [show]="showDeleteModal" (close)="closeDeleteModal()">
      <wcg-modal-header>{{ 'teams.deleteUserModalHeader' | translate  }}
      </wcg-modal-header>
      <wcg-modal-body>
        <div class="wcg-h3">{{ 'teams.deleteUserModalInfo1' | translate }}</div>
        <p>
          {{ 'teams.deleteUserModalInfo2' | translate}}
        </p>
      </wcg-modal-body>
      <wcg-modal-footer>
        <button class="wcg-btn" (click)="closeDeleteModal()">{{ 'teams.cancel' | translate }}</button>
        <button class="wcg-btn wcg-btn-primary" [disabled]="deleteStarted"
          (click)="deleteSelectedUsers()">{{ 'teams.deleteUserBtn' | translate }}</button>
      </wcg-modal-footer>
    </wcg-modal>

    <wcg-loader [show]="!teamMembersFetched"></wcg-loader>
    <app-no-records  *ngIf="teamMembersFetched && !initialTeamMembers?.records?.length">
      <p class="wcg-h2 no-records">{{ 'teams.noTeamMembers' | translate }}</p>
    </app-no-records>
  </div>