<div class="pl-140">
  <div class="wcg-row">
    <div class="wcg-col-xs-4 wcg-col-lg-4 users-icon">
      <wcg-icon src="../assets/icons/social/ic_person_24px.svg"></wcg-icon>
      <h1 class="wcg-h1"><span>User Details</span></h1>
    </div>
  </div>
  <div class="wcg-row wcg-rhythm-2">
    <a class="wcg-link" routerLink="/admin/users" routerLink-active="active">
      <wcg-icon src="../assets/icons/hardware/ic_keyboard_backspace_24px.svg"></wcg-icon>
      <span>Back to Users List</span>
    </a>
  </div>

  <div class="user-info" *ngIf="user">
    <div class="user-card wcg-rhythm-4">
      <div class="wcg-row wcg-rhythm-1">
        <div class="wcg-col-xs-4 wcg-col-lg-12">
          <h2>{{userFullName}}</h2>
          <div class="action-bar">
            <button class="wcg-btn wcg-btn-icon" (click)="editUser()" *ngIf='showEditProfileButton'>
              <wcg-icon src="../assets/icons/image/ic_edit_24px.svg"></wcg-icon>
              <span>Edit</span>
            </button>
            <button class="wcg-btn wcg-btn-icon" (click)="showResetPasswordModal = true">
              <wcg-icon src="../assets/icons/navigation/ic_refresh_24px.svg"></wcg-icon>
              <span>Reset Password</span>
            </button>
          </div>
          <div class="active-toggle">
            <span class="pr-10">{{isActive ? 'Active' : 'Inactive' }}</span>
            <input type="checkbox" id="toggle" class="checkbox" [checked]="isActive" (change)="statusChange($event)" />
            <label for="toggle" class="switch"></label>
          </div>
        </div>
      </div>

      <div class="address-card wcg-rhythm-1">
        <div class="wcg-row">
          <div class="float-left wcg-col-xs-4 wcg-col-lg-6">
            {{user.address?.addressLine1}} {{user.address?.addressLine2}}
          </div>
          <div class="align-right wcg-col-xs-4 wcg-col-lg-6">
            <span class="email-notification" *ngIf="user.isEmailNotificationEnabled">
              <wcg-icon class="enabled-icon" src="../assets/icons/communication/ic_email_24px.svg"></wcg-icon>
              Email Notifications Enabled
            </span>
            <span class="email-notification" *ngIf="!user.isEmailNotificationEnabled">
              <wcg-icon class="disabled-icon" src="../assets/icons/communication/ic_email_24px.svg"></wcg-icon>
              Email Notifications Not Enabled
            </span>
            <span>Last Login: {{user.lastActivityDate | date:'dd-MMM-yyyy'}}</span>
          </div>
        </div>

        <div class="wcg-row wcg-rhythm-2">
          <div class="wcg-col-xs-4 wcg-col-lg-12">
            {{user.address?.city}},
            {{user.address?.state?.code}}
            {{user.address?.province}}
            {{user.address?.postalCode}},
            {{user.address?.country?.name}}
          </div>
        </div>
        <div class="wcg-row wcg-rhythm-3">
          <div class="wcg-col-xs-4 wcg-col-lg-12">
            {{user.email}} | {{user.phone}}
          </div>
        </div>
        <div class="wcg-row wcg-rhythm-1">
          <div class="wcg-col-xs-4 wcg-col-lg-2">
            <div class="wcg-rhythm-1">
              Role
            </div>
            <h4>{{user.role?.name}}</h4>
          </div>
          <div class="wcg-col-xs-4 wcg-col-lg-2">
            <div class="wcg-rhythm-1">
              Organization Type
            </div>
            <h4>{{user.organization?.organizationType?.value}}</h4>
          </div>
          <div class="wcg-col-xs-4 wcg-col-lg-2">
            <div class="wcg-rhythm-1">
              Organization
            </div>
            <h4>{{user.organization?.name}}</h4>
          </div>
          <div class="wcg-col-xs-4 wcg-col-lg-2">
            <div class="wcg-rhythm-1">
              Team Name
            </div>
            <a *ngIf="user?.team?.id" class="wcg-link ml-0" routerLink="/teams/{{user?.team?.id}}/teamMembers">
              <h4 class="fs-5">{{ user?.team?.value }}</h4>
            </a>
            <span *ngIf="!user?.team?.id">N/A</span>
          </div>
        </div>
      </div>
    </div>

    <div class="wcg-row search-card">
      <div class="wcg-col-xs-4">
        <h4>
          {{ 'profile.workspaces' | translate }}
        </h4>
        <form ngForm class="wcg-rhythm-4">
          <wcg-form-field>
            <wcg-label>{{ 'actions.filter' | translate }}</wcg-label>
            <wcg-input [formControl]="searchTerm">
              <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
            </wcg-input>
          </wcg-form-field>
        </form>
      </div>
    </div>

    <wcg-table class="wcg-rhythm-4" [ngClass]="{'cnxs-table-loading': loading}" [headers]="headers" [data]="workspaces" [options]="options">
      <div class="cnxs-table-loader" slot="table-body">
        <wcg-loader [show]="loading"></wcg-loader>
      </div>
    </wcg-table>

  </div>
</div>

<div class="modal" *ngIf="currentUserInfo">
  <wcg-modal [show]="showUserEditModal" (close)="closeEditUserModal()">
    <wcg-modal-header>
      <wcg-icon src="../assets/icons/image/ic_edit_24px.svg"></wcg-icon>
      <span>{{modalTitle}}</span>
    </wcg-modal-header>
    <wcg-modal-body>

      <form #userForm="ngForm" (ngSubmit)="saveUser()" [formGroup]="editUserForm">
        <div class="wcg-row">
          <div class="edit-user-heading wcg-col-xs-4 wcg-rhythm-3">
            <span class="wcg-h5">
              {{ 'profile.profileFormHeadingName' | translate }}
            </span>
          </div>
        </div>
        <div class="wcg-row">
          <div class="wcg-col-xs-4">
            <wcg-form-field *ngIf="roles">
              <wcg-label>{{ 'profile.profileFormRole' | translate }}</wcg-label>
              <wcg-select formControlName="role" (change)="onSelectChange($event, 'role')"
                [disabled]="!currentUserInfo.canManageRoles">
                <option *ngFor="let role of roles" value="{{role.id}}" [selected]="role.id === editUserForm.value.role"
                  required>
                  {{ role.name }}
                </option>
              </wcg-select>
              <div
                *ngIf="(editUserForm.controls.role.invalid && userForm.submitted) ||
             (editUserForm.controls.role.invalid && (editUserForm.controls.role.dirty || editUserForm.controls.role.touched))">
                <wcg-error-message *ngIf="editUserForm.controls.role.invalid">
                  {{ 'validation.required' | translate: { fieldname: ('profile.profileFormRole' | translate) } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
        </div>
        <div class="wcg-row">
          <div class="wcg-col-xs-4 wcg-col-md-1 wcg-col-lg-2">
            <wcg-form-field>
              <wcg-label>{{ 'profile.profileFormPrefix' | translate }}</wcg-label>
              <wcg-select formControlName="prefix">
                <option value="" [selected]="!editUserForm.value.prefix"></option>
                <option *ngFor="let prefix of prefixes" [selected]="prefix.key === editUserForm.value.prefix"
                  [value]="prefix.key">
                  {{ prefix?.value }}
                </option>
              </wcg-select>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-3">
            <wcg-form-field>
              <wcg-label>{{ 'profile.profileFormFirstName' | translate }}</wcg-label>
              <wcg-input formControlName="firstName" maxlength="100" required></wcg-input>
              <div
                *ngIf="(editUserForm.controls.firstName.invalid && userForm.submitted) ||
             (editUserForm.controls.firstName.invalid && (editUserForm.controls.firstName.dirty || editUserForm.controls.firstName.touched))">
                <wcg-error-message *ngIf="editUserForm.controls.firstName.invalid">
                  {{ 'validation.required' | translate: { fieldname: ('profile.profileFormFirstName' | translate) } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>

          </div>
          <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-2">
            <wcg-form-field>
              <wcg-label>{{ 'profile.profileFormMiddleName' | translate }}</wcg-label>
              <wcg-input formControlName="middleName" maxlength="100"></wcg-input>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-3">
            <wcg-form-field>
              <wcg-label>{{ 'profile.profileFormLastName' | translate }}</wcg-label>
              <wcg-input formControlName="lastName" maxlength="100" required></wcg-input>
              <div
                *ngIf="(editUserForm.controls.lastName.invalid && userForm.submitted) ||
             (editUserForm.controls.lastName.invalid && (editUserForm.controls.lastName.dirty || editUserForm.controls.lastName.touched))">
                <wcg-error-message *ngIf="editUserForm.controls.lastName.invalid">
                  {{ 'validation.required' | translate: { fieldname: ('profile.profileFormLastName' | translate) } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-4 wcg-col-md-1 wcg-col-lg-2">
            <wcg-form-field>
              <wcg-label>{{ 'profile.profileFormSuffix' | translate }}</wcg-label>
              <wcg-select formControlName="suffix" (change)="onSelectChange($event, 'suffix')">
                <option value="" [selected]="!editUserForm.value.suffix"></option>
                <option *ngFor="let suffix of suffixes" [selected]="suffix.key === editUserForm.value.suffix"
                  [value]="suffix.key">
                  {{ suffix?.value }}
                </option>
              </wcg-select>
            </wcg-form-field>
          </div>
        </div>
        <div class="wcg-row">
          <div class="edit-user-heading wcg-col-xs-4 wcg-rhythm-3">
            <span class="wcg-h5">
              {{ 'profile.profileFormHeadingContact' | translate }}
            </span>
          </div>
        </div>
        <div class="wcg-row">
          <div class="wcg-col-xs-4 wcg-col-md-3 wcg-col-lg-5">
            <wcg-form-field>
              <wcg-label>{{ 'profile.profileFormEmail' | translate }}</wcg-label>
              <wcg-input formControlName="email"></wcg-input>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-3">
            <wcg-form-field>
              <wcg-label>{{ 'profile.profileFormPhone' | translate }}</wcg-label>
              <wcg-input formControlName="phone" maxlength="20" required></wcg-input>
              <div
                *ngIf="(editUserForm.controls.phone.invalid && userForm.submitted) ||
             (editUserForm.controls.phone.invalid && (editUserForm.controls.phone.dirty || editUserForm.controls.phone.touched))">
                <wcg-error-message *ngIf="editUserForm.controls.phone.invalid">
                  {{ 'validation.required' | translate: { fieldname: ('profile.profileFormPhone' | translate) } }}
                </wcg-error-message>
              </div>
              <div *ngIf="editUserForm.controls.phone.value.length > 0">
                <label>{{20 - editUserForm.controls.phone.value.length}} characters remaining</label>
              </div>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-4 wcg-col-md-3 wcg-col-lg-4 email-notifications-field">
            <wcg-checkbox formControlName="isEmailNotificationEnabled" class="checkboxField">
              {{ 'profile.profileFormEmailNotifications' | translate }}
            </wcg-checkbox>
          </div>
        </div>
        <div class="wcg-row">
          <div class="edit-user-heading wcg-col-xs-4 wcg-rhythm-3">
            <span class="wcg-h5">
              {{ 'profile.profileFormHeadingAddress' | translate }}
            </span>
          </div>
        </div>
        <div class="wcg-row">
          <div class="wcg-col-xs-4">
            <wcg-form-field *ngIf="countries">
              <wcg-label>{{ 'profile.profileFormCountry' | translate }}</wcg-label>
              <wcg-select formControlName="country" (change)="handleCountryChange($event)">
                <option *ngFor="let country of countries" [selected]="country.id == editUserForm.value.country"
                  [value]="country?.id" required>
                  {{ country?.name }}
                </option>
              </wcg-select>
              <div
                *ngIf="(editUserForm.controls.country.invalid && userForm.submitted) ||
             (editUserForm.controls.country.invalid && (editUserForm.controls.country.dirty || editUserForm.controls.country.touched))">
                <wcg-error-message *ngIf="editUserForm.controls.country.invalid">
                  {{ 'validation.required' | translate: { fieldname: ('profile.profileFormCountry' | translate) } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
        </div>
        <div class="wcg-row">
          <div class="wcg-col-xs-4 wcg-col-md-4">
            <wcg-form-field>
              <wcg-label>{{ 'profile.profileFormAddress1' | translate }}</wcg-label>
              <wcg-input formControlName="addressLine1" maxlength="255" required></wcg-input>
              <div
                *ngIf="(editUserForm.controls.addressLine1.invalid && userForm.submitted) ||
             (editUserForm.controls.addressLine1.invalid && (editUserForm.controls.addressLine1.dirty || editUserForm.controls.addressLine1.touched))">
                <wcg-error-message *ngIf="editUserForm.controls.addressLine1.invalid">
                  {{ 'validation.required' | translate: { fieldname: ('profile.profileFormAddress1' | translate) } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-4 wcg-col-md-4">
            <wcg-form-field>
              <wcg-label>{{ 'profile.profileFormAddress2' | translate }}</wcg-label>
              <wcg-input formControlName="addressLine2" maxlength="255"></wcg-input>
            </wcg-form-field>
          </div>
        </div>
        <div class="wcg-row">
          <div class="wcg-col-xs-4 wcg-col-md-3">
            <wcg-form-field>
              <wcg-label>{{ 'profile.profileFormCity' | translate }}</wcg-label>
              <wcg-input formControlName="city" maxlength="75" required></wcg-input>
              <div
                *ngIf="(editUserForm.controls.city.invalid && userForm.submitted) ||
             (editUserForm.controls.city.invalid && (editUserForm.controls.city.dirty || editUserForm.controls.city.touched))">
                <wcg-error-message *ngIf="editUserForm.controls.city.invalid">
                  {{ 'validation.required' | translate: { fieldname: ('profile.profileFormCity' | translate) } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-4 wcg-col-md-3">
            <wcg-form-field *ngIf="states?.length">
              <wcg-label>{{ stateLabel | translate }}</wcg-label>
              <wcg-select formControlName="state" (change)="stateChange($event)">
                <option value=""></option>
                <option *ngFor="let state of states" [selected]="state.id == editUserForm.value.state"
                  [value]="state.id" required>
                  {{ state?.name }}
                </option>
              </wcg-select>
              <div
                *ngIf="(editUserForm.controls.state.invalid && userForm.submitted) ||
             (editUserForm.controls.state.invalid && (editUserForm.controls.state.dirty || editUserForm.controls.state.touched))">
                <wcg-error-message *ngIf="editUserForm.controls.state.invalid">
                  {{ 'validation.required' | translate: { fieldname: (stateLabel | translate) } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
            <wcg-form-field *ngIf="states?.length === 0">
              <wcg-label>{{ 'profile.profileFormStateProvince' | translate }}</wcg-label>
              <wcg-input formControlName="province" maxlength="150" required></wcg-input>
              <div
                *ngIf="(editUserForm.controls.province.invalid && userForm.submitted) ||
             (editUserForm.controls.province.invalid && (editUserForm.controls.province.dirty || editUserForm.controls.province.touched))">
                <wcg-error-message *ngIf="editUserForm.controls.province.invalid">
                  {{ 'validation.required' | translate: { fieldname: ('profile.profileFormStateProvince' | translate) } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-4 wcg-col-md-2">
            <wcg-form-field>
              <wcg-label>{{ 'profile.profileFormPostalCode' | translate }}</wcg-label>
              <wcg-input formControlName="postalCode" maxlength="15" required></wcg-input>
              <div
                *ngIf="(editUserForm.controls.postalCode.invalid && userForm.submitted) ||
             (editUserForm.controls.postalCode.invalid && (editUserForm.controls.postalCode.dirty || editUserForm.controls.postalCode.touched))">
                <wcg-error-message *ngIf="editUserForm.controls.postalCode.invalid">
                  {{ 'validation.required' | translate: { fieldname: ('profile.profileFormPostalCode' | translate) } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
        </div>
        <div class="wcg-row">
          <div class="edit-user-heading wcg-col-xs-4 wcg-rhythm-3">
            <span class="wcg-h5">
              {{ 'profile.profileFormHeadingAdditional' | translate }}
            </span>
          </div>
        </div>
        <div class="wcg-row">
          <div class="wcg-col-xs-4 wcg-col-md-3">
            <wcg-form-field>
              <wcg-label>{{ 'profile.profileFormDegrees' | translate }}</wcg-label>
              <wcg-input formControlName="degrees"></wcg-input>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-4 wcg-col-md-3">
            <wcg-form-field *ngIf="isAdmin">
              <wcg-label>{{ 'profile.profileFormOrganization' | translate }}</wcg-label>
              <wcg-autocomplete formControlName="selectedOrganization" [multiple]="false" [options]="allOrganizations"
                (change)="updateOrganization($event)" required>
              </wcg-autocomplete>
              <div
                *ngIf="(editUserForm.controls.selectedOrganization.invalid && userForm.submitted) ||
           (editUserForm.controls.selectedOrganization.invalid && (editUserForm.controls.selectedOrganization.dirty || editUserForm.controls.selectedOrganization.touched))">
                <wcg-error-message *ngIf="!editUserForm.controls.selectedOrganization.valid">
                  {{ 'validation.required' | translate: { fieldname: ('profile.profileFormOrganization' | translate) } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-4 wcg-col-md-2">
            <wcg-form-field>
              <wcg-label>{{ 'profile.profileFormOrganizationType' | translate }}</wcg-label>
              <wcg-input formControlName="organizationType" [value]="organizationType?.value"></wcg-input>
            </wcg-form-field>
          </div>
        </div>
      </form>
    </wcg-modal-body>
    <wcg-modal-footer>
      <button class="wcg-btn wcg-btn-auto" (click)="closeEditUserModal()">{{ 'actions.cancel' | translate }}</button>
      <button class="wcg-btn wcg-btn-auto wcg-btn-primary" (click)="saveUser()" [disabled]="editUserForm.invalid">
        {{ 'actions.save' | translate }} {{ editUserForm.errors }}</button>
    </wcg-modal-footer>
  </wcg-modal>

  <wcg-modal *ngIf="!!user" class="resetPasswordModal" [show]="showResetPasswordModal"
    (close)="showResetPasswordModal = false">
    <wcg-modal-header>
      <wcg-icon src="../assets/icons/navigation/ic_refresh_24px.svg"></wcg-icon>
      <span>Reset Password</span>
    </wcg-modal-header>
    <wcg-modal-body>
      <p>{{user.prefix}} {{user.firstName}} {{user.middleName}} {{user.lastName}}
        {{user.suffix}} will receive an email containing a password reset link.</p>
      <p>The message will be sent to: <span class="modal-email">{{user.email}}</span></p>
    </wcg-modal-body>
    <wcg-modal-footer>
      <button (click)="resetPassword()" class="wcg-btn wcg-btn-auto wcg-btn-primary"><span class="nowrap">Send reset email</span></button>
    </wcg-modal-footer>
  </wcg-modal>
</div>
