import { Injectable } from '@angular/core';
import { User, UserAutocompleteMap, WorkspaceRequestModel, WorkspaceAndTeamUserMap } from '../../interfaces/user';
import { PermissionTypeViewValues, PermissionType } from '../../common/collections';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { UtilService } from '../../services/util.service';
import { WorkspaceRequestMap } from '../../interfaces/workspaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManageContactsService {
  public userInfoSubject = new BehaviorSubject<Array<WorkspaceRequestModel>>([]);

  constructor(
    private translatePipe: TranslatePipe,
    private utilService: UtilService
  ) {}

  public getWorkspaceId = (url: string): string => {
    if (url.includes('/sites/')) {
      return url.split('/sites/')[1].split('/')[0];
    }

    return url.split('/studies/')[1].split('/')[0];
  }

  public mapUsers = (data: Array<User>): Array<UserAutocompleteMap> => data.map((record) => ({
    email: record.email,
    option: `${[record.firstName, record.lastName].filter(Boolean).join(' ')} (${record.email})`,
    value: [record.firstName, record.lastName].filter(Boolean).join(' ')
  }));

  public mapWorkspaceRequests(data: Array<WorkspaceRequestModel>, isSite): Array<WorkspaceRequestMap> {
    const MANAGER = 3;

    let siteParticipantOption = '';

    if (isSite === false) {
      siteParticipantOption = `<option value="4">${PermissionTypeViewValues[PermissionType[4]]}</option>`;
    }

    const userActions = `<button class="wcg-btn wcg-btn-primary row-action-button" data-approve-btn disabled>
                          ${this.translatePipe.transform('actions.approve')}
                      </button>
                      <button class="wcg-btn row-action-button" data-reject-btn>
                          ${this.translatePipe.transform('actions.reject')}
                      </button>`;

    const teamActions = `<button class="wcg-btn wcg-btn-primary row-action-button" team-approve-btn>
                          ${this.translatePipe.transform('actions.approve')}
                      </button>
                      <button class="wcg-btn row-action-button" team-reject-btn>
                          ${this.translatePipe.transform('actions.reject')}
                      </button>`;

    return data.map((record) => ({
      actions: record.teamExternalReferenceId ? teamActions : userActions,
      concurrencyVersion: record.concurrencyVersion,
      email: record.teamExternalReferenceId ? this.utilService.addTableCellWrapper(record.teamEmail) :
        this.utilService.addTableCellWrapper(record.user.email),
      name: record.teamExternalReferenceId ?
        `<a class="wcg-link team-name" title="${record.teamName}" data-show-teamMembers>${record.teamName}</a>
        <br><wcg-badge> &nbsp; Team &nbsp; </wcg-badge>` :
        this.utilService.addTableCellWrapper(
          this.utilService.formatName(record.user.firstName, record.user.middleName, record.user.lastName)
        ),
      permissionType: record.permissionType,
      permissionTypeColumn: record.teamExternalReferenceId ? 'N/A' :
        `<div class="read-only">${PermissionTypeViewValues[PermissionType[record.permissionType]]}</div>
        <wcg-form-field class="edit-mode-content">
          <wcg-label>${this.translatePipe.transform('manageContacts.permissions')}</wcg-label>
          <wcg-select>
            <option selected hidden>${PermissionTypeViewValues[PermissionType[0]]}</option>
            <option value="1">${PermissionTypeViewValues[PermissionType[1]]}</option>
            <option value="2">${PermissionTypeViewValues[PermissionType[2]]}</option>
            <option value="3">${PermissionTypeViewValues[PermissionType[MANAGER]]}</option> ${siteParticipantOption}
            </wcg-select>
        </wcg-form-field>
      `,
      userId: record.user?.externalReferenceId,
      teamExternalReferenceId: record.teamExternalReferenceId,
      teamName: record.teamName
    }));
  }

  // eslint-disable-next-line max-lines-per-function
  public mapWorkspaceUsers(data: Array<WorkspaceRequestModel>, isSite: boolean): Array<WorkspaceAndTeamUserMap> {
    const hasAccess = this.translatePipe.transform('manageContacts.hasAccess');
    const invited = this.translatePipe.transform('manageContacts.invited');
    const MANAGER = 3;
    let siteParticipantOption = '';

    // eslint-disable-next-line complexity
    return data.map((record) => {
      if (!isSite) {
        siteParticipantOption = `<option ${record.permissionType === PermissionType.SiteParticipant && 'selected'} value="4">
        ${PermissionTypeViewValues[PermissionType[4]]}</option>`;
      }

      let permissionColumn = `<div class="read-only">${PermissionTypeViewValues[PermissionType[record.permissionType]]}
        </div><wcg-form-field class="edit-mode-content">
        <wcg-label>${this.translatePipe.transform('manageContacts.permissions')}</wcg-label> <wcg-select>
        <option ${record.permissionType === 1 && 'selected'} value="1">${PermissionTypeViewValues[PermissionType[1]]}</option>
        <option ${record.permissionType === 2 && 'selected'} value="2">${PermissionTypeViewValues[PermissionType[2]]}</option>
        <option ${record.permissionType === MANAGER && 'selected'} value="3">${PermissionTypeViewValues[PermissionType[MANAGER]]}</option>
        ${siteParticipantOption} </wcg-select> </wcg-form-field>`;
      let name = this.utilService.addTableCellWrapper(this.utilService.formatName(record.user.firstName, null, record.user.lastName));

      if (record.teamExternalReferenceId) {
        name = `<a class="wcg-link team-name" title="${record.teamName}">${record.teamName}</a>
        <br><wcg-badge> &nbsp; Team &nbsp; </wcg-badge>`;
        permissionColumn = 'N/A';
      }

      return {
        concurrencyVersion: record.concurrencyVersion,
        edit: `<button class="wcg-btn wcg-btn-icon read-only edit-btn" ${record.teamName ? 'disabled' : ''} data-edit-row-button>
          <wcg-icon src="../assets/icons/editor/ic_mode_edit_24px.svg"></wcg-icon>
          <span class="wcg-visually-hidden">${this.translatePipe.transform('actions.edit')}</span>
        </button>
        <div class="edit-mode-content edit-mode-actions">
          <button class="wcg-btn wcg-btn-icon submit-btn" data-submit-button>
            <wcg-icon src="../assets/icons/navigation/ic_check_24px.svg"></wcg-icon>
            <span class="wcg-visually-hidden">${this.translatePipe.transform('actions.submit')}</span>
          </button>
          <button class="wcg-btn wcg-btn-icon cancel-btn" data-cancel-button>
            <wcg-icon src="../assets/icons/navigation/ic_close_24px.svg"></wcg-icon>
            <span class="wcg-visually-hidden">${this.translatePipe.transform('actions.cancel')}</span>
          </button>
        </div>
      `,
        email: this.utilService.addTableCellWrapper(record.teamExternalReferenceId ? record.teamEmail : record.user.email),
        name: name,
        permissionType: record.permissionType,
        permissionTypeColumn: permissionColumn,
        status: record.isActive || record.teamExternalReferenceId ? hasAccess : invited,
        userId: record.user.externalReferenceId,
        teamEmail: record.teamEmail,
        teamName: record.teamName,
        teamExternalReferenceId: record.teamExternalReferenceId,
        teamFullName: this.utilService.addTableCellWrapper(record.teamName)
      };
    });
  }

  public mapMembersGridData(data): any {
    return data.records.map((record) => ({
      name: record.isRegistered && record.isLicenseAccepted ?
        this.utilService.addTableCellWrapper(`${record.lastName}, ${record.firstName}`) :
        this.utilService.addTableCellWrapper(this.translatePipe.transform('manageContacts.invited')),
      email: this.utilService.addTableCellWrapper(record.email),
      permissionType: this.utilService.addTableCellWrapper(
        `${PermissionTypeViewValues[PermissionType[record.workspacePermissionType]]}`
      )
    }));
  }
}
