import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { ApiService } from '../../../services/api.service';
import { BaseComponent } from '../../../components/base/base.component';
import { FlowTypeName, SubmissionTypeNames } from '../../../common/collections';
import { FormType } from '../../../admin/shared/model/submission-type';
import { SubmissionType } from '../../../interfaces/submissions';
import { SubmissionWorkflowService } from '../submission-workflow.service';
import { ToastService } from '../../../services/toast.service';
import { fieldsetSelectionValidator } from '../../../validators/fieldset-selection.validator';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-submission-type',
  templateUrl: './submission-type.component.html',
  styleUrls: ['./submission-type.component.scss']
})
export class SubmissionTypeComponent extends BaseComponent implements OnInit {
  submissionTypes = [];
  supportingText: string;
  showClosureTypeLightbulb = false;
  submissionTypesForm = new FormGroup({
    submissionTypes: new FormGroup({
      submissionType: new FormControl('')
    },
    [
      Validators.required,
      fieldsetSelectionValidator
    ])
  });

  private initialSubmissionGroup: SubmissionType;

  constructor(
    public router: Router,
    private apiService: ApiService,
    public submissionWorkflowService: SubmissionWorkflowService,
    private toastService: ToastService,
    private translatePipe: TranslatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    const submissionTypeGroupId = this.submissionWorkflowService.submissionTypeGroup.value?.id;

    this.submissionWorkflowService.resetSaveStatus();
    this.submissionWorkflowService.submissionTypeValue.next('');

    if (!this.submissionWorkflowService.isStudySelected()) {
      this.submissionWorkflowService.submissionSponsorProtocolId.next('');
    }
    else if (this.submissionWorkflowService.isStudySelected() && this.submissionWorkflowService.isStudyOriginSubmission()) {
      this.submissionWorkflowService.submissionStudySites.next([]);
      this.submissionWorkflowService.submissionStudySitesTotal.next(0);
    }

    this.supportingText = this.translatePipe.transform(
      `submissionTypesForm.${this.submissionWorkflowService.submissionFlow.value}SubmissionType`
    );

    if (!submissionTypeGroupId) {
      this.router.navigateByUrl('/submission-workflow');
    }

    this.initialSubmissionGroup = this.submissionWorkflowService.submissionTypeGroup.value;

    this.apiService.getSubmissionTypeGroup(submissionTypeGroupId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.submissionTypes = res;
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'getSubmissionTypeGroupError',
          message: `Could not load Submission Types: ${err.details.error.message}`,
          timeout: 5000,
          variant: 'error'
        }]);
      });

    this.subscribeToSubmissionTypesValueChanges();
  }

  /* eslint-disable no-mixed-operators */
  /* eslint-disable complexity */
  public continueClick(): void {
    if (this.isSetupRegionsRequired()) {
      if (this.submissionWorkflowService.isRegionSelected() &&
          this.submissionWorkflowService.isSiteSelected() &&
          this.submissionWorkflowService.isSiteOriginSubmission()) {
        this.router.navigateByUrl('/submission-workflow/details');
      }
      else {
        this.router.navigateByUrl('/submission-workflow/setup/regions');
      }
    }
    else if (this.submissionWorkflowService.submissionFlow.value === 'addStudy') {
      this.router.navigateByUrl('/submission-workflow/details');
    }
    else if (this.submissionWorkflowService.isStudySelected() &&
       (this.submissionWorkflowService.isSiteOriginSubmission() ||
       this.submissionWorkflowService.isStudyOriginSubmission())) {
      if (this.submissionWorkflowService.isSiteSelected()) {
        this.router.navigateByUrl('/submission-workflow/details');
      }
      else if (this.submissionWorkflowService.submissionFlow.value === 'addDocuments') {
        this.router.navigateByUrl('/submission-workflow/choose-sites');
      }
      else {
        this.router.navigateByUrl('/submission-workflow/details');
      }
    }
    else {
      this.router.navigateByUrl('/submission-workflow/search-study');
    }
  }
  /* eslint-enable no-mixed-operators */
  /* eslint-enable complexity */

  public onChangeSubmissionType(): void {
    this.showClosureTypeLightbulb = this.submissionWorkflowService.submissionTypeValue.value === SubmissionTypeNames.siteClosure ||
      this.submissionWorkflowService.submissionTypeValue.value === SubmissionTypeNames.hudClinicalUseClosure;
  }

  public getFormTypeValue(): void {
    // getting it here as it needs to be sent in formIO setup
    this.apiService.getFormTypeBySubmissionTypeId(this.submissionWorkflowService.submissionTypeId.value)
      .subscribe((result) => {
        this.submissionWorkflowService.submissionFormType.next(FormType[result]);
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'getFormTypeValue',
          message: `Could not get formType value: ${err.details.error.message}`,
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  /* eslint-disable no-mixed-operators */
  private isSetupRegionsRequired(): boolean {
    return this.submissionWorkflowService.submissionFlow.value === 'addStudy' &&
    this.submissionWorkflowService.submissionTypeValue.value === 'A New Study for Initial Review' ||
    this.submissionWorkflowService.submissionFlow.value === 'addDocuments' &&
    (this.submissionWorkflowService.submissionTypeValue.value === 'Translation Request' ||
      this.submissionWorkflowService.submissionTypeValue.value === 'Change In Research');
  }

  /* eslint-enable no-mixed-operators */
  private subscribeToSubmissionTypesValueChanges(): void {
    this.submissionTypesForm.controls.submissionTypes.valueChanges.subscribe((selection) => {
      const typeValue = this.submissionTypes.filter((type) => type.id.toString() === selection.submissionType);
      const { submissionTypeGroups } = this.submissionWorkflowService;
      const [existingSite] = submissionTypeGroups.value.filter((group) => group.value === FlowTypeName.ExistingSite);
      const singleSiteSelectForms = [SubmissionTypeNames.changeInInvestigator.toLowerCase(),
        SubmissionTypeNames.hudClinicalUseClosure.toLowerCase(),
        SubmissionTypeNames.siteClosure.toLowerCase()];
      const selectedSubmissionType = typeValue[0].value.trim().toLowerCase();
      this.submissionWorkflowService.submissionTypeGroup.next(this.initialSubmissionGroup);
      this.submissionWorkflowService.siteSingleSelect.next(false);
      this.submissionWorkflowService.siteRequired.next(false);
      this.submissionWorkflowService.submissionTypeId.next(selection.submissionType);
      this.submissionWorkflowService.submissionTypeValue.next(typeValue[0].value);
      this.submissionWorkflowService.setFormName(typeValue[0].value);
      this.submissionWorkflowService.submissionTypeGroup.next(this.initialSubmissionGroup);
      this.submissionWorkflowService.defaultDocumentTypeId.next(typeValue[0].defaultDocumentTypeId);
      this.submissionWorkflowService.siteSelectionResponse.next('');
      if (this.isSetupRegionsRequired() && this.submissionWorkflowService.isSiteSelected()) {
        this.submissionWorkflowService.submissionRegions.next(this.submissionWorkflowService.submissionStudySiteRegion);
      }
      if (singleSiteSelectForms.indexOf(selectedSubmissionType) >= 0) {
        this.submissionWorkflowService.submissionTypeGroup.next(existingSite);
        this.submissionWorkflowService.siteSingleSelect.next(true);
        this.submissionWorkflowService.siteRequired.next(true);
      }
      else {
        const siteRequiredFlag = this.submissionWorkflowService.isStudySelected() &&
          !this.submissionWorkflowService.canSetSiteSingleSelect.value;
        if (siteRequiredFlag) {
          this.submissionWorkflowService.siteRequired.next(true);
        }
        if (this.submissionWorkflowService.canCreateFullStudySubmission.value) {
          this.submissionWorkflowService.siteSelectionResponse.next('selected');
        }
      }
      this.getFormTypeValue();
    });
  }
}
