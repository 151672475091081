<div *ngIf="isImpersonationActive" class="customLeft"></div>
<div *ngIf="isImpersonationActive" class="customRight"></div>
<div *ngIf="isImpersonationActive" class="customBottom"></div>
<div *ngIf="isImpersonationActive" class="customTop wcg-row">
  <div class="wcg-col-xs-2 impersonationMessage">
    <div class="customHeadBorder">
      You ({{ userInfo.impersonatorUsername }}) are impersonating: <b>{{ userInfo.firstName }}
        {{ userInfo.lastName }}</b>
    </div>
  </div>
  <div class="wcg-col-xs-2">
    <div class=" customHeadDiv">
      <button class="wcg-btn-auto wcg-btn-primary customHeadButton" (click)="stopImpersonation()">
        {{ 'impersonation.stop' | translate }}
      </button>
    </div>
  </div>
</div>
<wcg-header class="wcg-rhythm-5 {{ isAuthenticated ? 'authenticated' : '' }}" [fixed]="true"
  *ngIf="!stateService.isWorkflow.value">

  <div class="wcg-container wcg-no-padding wcg-row wcg-middle-xs wcg-between-xs cnxs-header-container"
    [ngClass]="{'paddingTop' : isImpersonationActive}">
    <wcg-header-logo>
      <button class="btn-logo" (click)="redirectHome()">
        <wcg-icon src="./assets/images/cnxs-logo.svg"></wcg-icon>
        <span class="wcg-visually-hidden">{{ 'nav.home' | translate }}</span>
      </button>
    </wcg-header-logo>

    <wcg-header-utilities>
      <button class="wcg-btn wcg-btn-icon header-help-button" (click)="showHelpModal = true">
        <wcg-icon src="../assets/icons/action/ic_contact_support_24px.svg"></wcg-icon>
        <span class="wcg-visually-hidden">{{ 'nav.help' | translate }}</span>
      </button>

      <button class="wcg-btn wcg-btn-icon header-profile-button" (click)="toggleMenu()">
        <wcg-icon src="../assets/icons/action/ic_account_circle_24px.svg"></wcg-icon>
        <wcg-icon class="header-profile-dropdown-icon" src="../assets/icons/navigation/ic_arrow_drop_down_24px.svg">
        </wcg-icon>
        <span class="wcg-visually-hidden">{{ 'nav.profile' | translate }}</span>
      </button>

      <wcg-header-menu-button [open]="showMenu" (click)="showMenu = !showMenu">
        <span class="wcg-visually-hidden">{{ 'utility.toggle' | translate }}</span>
      </wcg-header-menu-button>
    </wcg-header-utilities>
  </div>

  <wcg-header-menu [open]="showMenu" *ngIf="userInfo?.isRegistered" [ngClass]="{'paddingTop' : isImpersonationActive}">
    <wcg-header-menu-item>
      <a routerLink="dashboard" routerLinkActive="wcg-active">{{ 'nav.dashboard' | translate }}</a>
    </wcg-header-menu-item>
    <wcg-header-menu-item>
      <a routerLink="submissions" routerLinkActive="wcg-active">{{ 'nav.submissions' | translate }}</a>
    </wcg-header-menu-item>
    <wcg-header-menu-item>
      <a routerLink="studies" routerLinkActive="wcg-active">{{ 'nav.studies' | translate }}</a>
    </wcg-header-menu-item>
    <wcg-header-menu-item>
      <a routerLink="sites" routerLinkActive="wcg-active">{{ 'nav.sites' | translate }}</a>
    </wcg-header-menu-item>
    <wcg-header-menu-item *ngIf="userInfo.teamExternalReferenceId">
      <a routerLink="teams/{{userInfo.teamExternalReferenceId}}"
        routerLinkActive="wcg-active">{{ 'nav.teams' | translate }}</a>
    </wcg-header-menu-item>
    <wcg-header-menu-item class="cnxs-user-menu-resources">
      <a routerLink="resources" routerLinkActive="wcg-active">{{ 'nav.resources' | translate }}</a>
    </wcg-header-menu-item>
    <wcg-header-menu-item class="user-menu-item">
      <button class="wcg-link" routerLinkActive="wcg-active"
        (click)="showHelpModal = true">{{ 'nav.help' | translate }}</button>
    </wcg-header-menu-item>
    <wcg-header-menu-item class="user-menu-item">
      <a routerLink="profile" routerLinkActive="wcg-active">{{ 'nav.profile' | translate }}</a>
    </wcg-header-menu-item>
    <wcg-header-menu-item class="user-menu-item">
      <a routerLink="downloads" routerLinkActive="wcg-active">{{ 'nav.downloads' | translate }}</a>
    </wcg-header-menu-item>
    <wcg-header-menu-item class="user-menu-item" *ngIf="isAdmin">
      <a routerLink="admin" routerLinkActive="wcg-active">{{ 'nav.admin' | translate }}</a>
    </wcg-header-menu-item>
    <wcg-header-menu-item class="user-menu-item">
      <button class="wcg-link" (click)="logout()">{{ 'nav.logout' | translate }}</button>
    </wcg-header-menu-item>
  </wcg-header-menu>

  <div class="user-menu-wrap">
    <div class="user-menu-container" *ngIf="showUserMenu" [@slideInOut]>
      <ul class="user-menu">
        <li class="user-menu-item" *ngIf="userInfoLoaded">
          <span class="user-menu-name">
            {{ 'profile.fullname' | translate: { firstname: userInfo?.firstName, lastname: userInfo?.lastName } }}
          </span>
          <span class="wcg-body-sm">
            {{ userInfo?.role?.name }}
          </span>
        </li>
        <li class="user-menu-item" *ngIf="userInfo?.isRegistered">
          <a class="wcg-link" routerLink="profile">
            {{ 'nav.profile' | translate }}
          </a>
        </li>
        <li class="user-menu-item" *ngIf="userInfo?.isRegistered">
          <a class="wcg-link" routerLink="download">
            {{ 'nav.downloads' | translate }}
          </a>
        </li>
        <li class="user-menu-item" *ngIf="isAdmin">
          <a class="wcg-link" [routerLink]="isIRBStaff ? ['admin_irb'] : ['admin']">
            {{ 'nav.admin' | translate }}
          </a>
        </li>
        <li class="user-menu-item">
          <button class="wcg-link" (click)="logout()">
            {{ 'nav.logout' | translate }}
          </button>
        </li>
      </ul>
    </div>
  </div>

</wcg-header>

<wcg-modal [show]="showHelpModal" (close)="showHelpModal = false" class="help-modal">
  <wcg-modal-header>
    {{ 'help.header' | translate }}
  </wcg-modal-header>
  <wcg-modal-body>
    <h3 class="wcg-h3">
      {{ 'help.bodyText' | translate }}
    </h3>
    <dl class="help-modal-contact-details wcg-row">
      <div class="wcg-col-xs-2">
        <dt class="help-modal-contact-label">
          {{ 'help.phoneLabel' | translate }}
        </dt>
        <dd class="help-modal-contact-detail">
          <a class="wcg-link" href="tel:{{ 'help.phoneValue' | translate }}">
            {{ 'help.phoneValue' | translate }}
          </a>
        </dd>
      </div>
      <div class="wcg-col-xs-2">
        <dt class="help-modal-contact-label">
          {{ 'help.hoursLabel' | translate }}
        </dt>
        <dd class="help-modal-contact-detail">
          {{ 'help.hoursValue' | translate }}
        </dd>
      </div>
      <div class="wcg-col-xs-2">
        <dt class="help-modal-contact-label">
          {{ 'help.emailLabel' | translate }}
        </dt>
        <dd class="help-modal-contact-detail">
          <a class="wcg-link" href="mailto:{{ supportEmail }}">
            {{ supportEmail }}
          </a>
        </dd>
      </div>
      <div class="wcg-col-xs-2">
        <dt class="help-modal-contact-label">
          {{ 'help.daysLabel' | translate }}
        </dt>
        <dd class="help-modal-contact-detail">
          {{ 'help.daysValue' | translate }}
        </dd>
      </div>
    </dl>
  </wcg-modal-body>
  <wcg-modal-footer class="help-modal-footer">
    <button (click)="showHelpModal = false" class="wcg-btn wcg-btn-auto wcg-btn-primary">
      {{ 'actions.okay' | translate }}
    </button>
  </wcg-modal-footer>
</wcg-modal>

<router-outlet (activate)="onActivate()"></router-outlet>

<wcg-modal id="modal" [show]="showIdle" (close)="keepSignedIn()" class="idle-modal">
  <wcg-modal-header>{{ 'sessionIdleModalText.heading' | translate }}</wcg-modal-header>
  <wcg-modal-body>
    <p>{{'sessionIdleModalText.firstLine' | translate }}</p>
    <p>{{'sessionIdleModalText.secondLine' | translate }}{{ timeOutMinutes }} seconds.</p>
  </wcg-modal-body>
  <wcg-modal-footer>
    <button class="wcg-btn wcg-btn-primary" (click)="keepSignedIn()">{{ 'actions.staySignedIn' | translate }}</button>
    <button class="wcg-btn" (click)="closeShowIdleModal()">{{ 'actions.signoutNow' | translate }}</button>
  </wcg-modal-footer>
</wcg-modal>


<div class="toast-wrap" [ngClass]="{'impersonationTop' : isImpersonationActive}">
  <wcg-toast *ngFor="let toast of toastService.alerts$ | async" closeable="{{toast.closable}}"
    variant="{{toast.variant || 'info'}}" (close)="toastService.remove(toast)">
    <span [innerHTML]="toast.message"></span>
  </wcg-toast>
</div>


<app-cancel-workflow-modal></app-cancel-workflow-modal>

<app-live-chat-talkdesk></app-live-chat-talkdesk>
