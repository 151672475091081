import { Component, Input, Output, EventEmitter } from '@angular/core';

import { FlowTypeName, SubmissionsDashboardStatistic } from '../../..//common/collections';
import { SubmissionInfo } from '../../../interfaces/submissions';

@Component({
  selector: 'app-submission-info',
  templateUrl: './submission-info.component.html',
  styleUrls: ['./submission-info.component.scss']
})

export class SubmissionInfoComponent {
  newStudy = FlowTypeName.NewStudy;
  submissionsDashboardStatistic = SubmissionsDashboardStatistic;

  @Input() card: boolean;
  @Input() submission: SubmissionInfo;
  @Input() showPIOnCard: boolean;

  @Output() viewAllPIsClicked: EventEmitter<SubmissionInfo> = new EventEmitter();

  onViewAllPIsClickedEvent(selectedSubmission: SubmissionInfo): void {
    this.viewAllPIsClicked.emit(selectedSubmission);
  }
}
