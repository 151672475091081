export type UserGuide = {
  storagePath: string;
  description: string;
}
export type DownloadableDocument = {
  name: string;
  storagePath: string;
}
export type DownloadableDocuments = Array<DownloadableDocument>;

export type HowToVideo = {
  name: string;
  description: string;
  videoPreviewImagePath: string;
  videoPreviewImageDescription: string;
  imageDescription: string;
  storagePath: string;
}

export type HowToVideos = Array<HowToVideo>;

export enum ResourceType{
  Video = 'video',
  HTML = 'html',
  PDF = 'pdf'
}
