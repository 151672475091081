import { Injectable } from '@angular/core';

import { SitesGridRecordMap, SitesGrid } from '../../interfaces/sites';
import { UtilService } from '../../services/util.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SitesService {
  public searchTerm = new BehaviorSubject<string>('');

  constructor(private utilService: UtilService) {}

  public mapSitesGridData(data: SitesGrid): Array<SitesGridRecordMap> {
    return data.records.map((record) => ({
      sponsor: this.utilService.addTableCellWrapperWithSearchTerms(record.sponsor, this.searchTerm.value),
      sponsorProtocolNumber: this.utilService.addTableCellWrapperWithSearchTerms(record.sponsorProtocolNumber, this.searchTerm.value),
      name: record.name,
      nameLink: this.utilService.addTableCellWrapperWithSearchTerms(record.name, this.searchTerm.value,
        `sites/${record.id}`),
      irbTrackingNumber: this.utilService.addTableCellWrapperWithSearchTerms(record.irbTrackingNumber, this.searchTerm.value),
      institutionalTrackingNumber: this.utilService.addTableCellWrapperWithSearchTerms(record.institutionalTrackingNumber,
        this.searchTerm.value),
      status: this.utilService.addTableCellWrapperWithSearchTerms(record.status, this.searchTerm.value)
    }));
  }
}
