<div class="wcg-rhythm-5 pl-140">
  <div class="wrapper">
    <div class="settings-icon"><wcg-icon src="../assets/icons/action/ic_settings_24px.svg"></wcg-icon></div>
    <h1 class="wcg-h1"><span class="text">Settings</span></h1>
  </div>

  <wcg-tab-bar-section>
    <wcg-tab-bar>
      <a class="wcg-link view-settings" routerLink="submissionTypes" routerLinkActive="wcg-active">
        Submission Types</a>    
      <a class="wcg-link view-settings" routerLink="document-types" routerLinkActive="wcg-active">Document
        Types</a>
      <a class="wcg-link view-settings" routerLink="documentcategory" routerLinkActive="wcg-active">
        Document Categories
      </a>
      <a class="wcg-link view-settings" routerLink="systemSettings" routerLinkActive="wcg-active">
        System Settings
      </a>
    </wcg-tab-bar>

    <div class="wcg-container">
      <router-outlet></router-outlet>
    </div>
  </wcg-tab-bar-section>
</div>
