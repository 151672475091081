import { Injectable } from '@angular/core';
import { WebApiServices } from '../helpers/webapiservice';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GridOptionsSearchModel } from '../model/grid.model';
import { AdminApis } from '../helpers/adminapis';
import { PaginatedListModel, DocumentTypeCreateOrUpdateModel, DocumentTypeModel } from '../model/document-type.models';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeService {
  service: WebApiServices;

  constructor(private http: HttpClient) {
    this.service = new WebApiServices(this.http);
  }

  getDocumentTypes(gridOptions: GridOptionsSearchModel): Observable<PaginatedListModel> {
    return this.service.postService(AdminApis.GetDocumentTypes, gridOptions, null);
  }

  createOrUpdateDocumentType(documentTypeModel: DocumentTypeCreateOrUpdateModel): Observable<any> {
    return this.service.postService(AdminApis.CreateOrUpdateDocumentType, documentTypeModel, null);
  }

  public filter = (data: Array<DocumentTypeModel>, term: string): Array<DocumentTypeModel> => data.filter((record) => {
    if (record.value.toLowerCase().includes(term.toLowerCase())) {
      return record;
    }

    return null;
  });
}
