<div class="wcg-rhythm-5 pl-140">

  <div class="wrapper">
    <div>
      <wcg-icon class="users-icon ml-170" src="../../assets/icons/Audits.svg"></wcg-icon>
    </div>
    <h1 class="wcg-h1"><span class="text">User Access Audit</span></h1>
  </div>  
  <div class="body">
    <h4>
      {{'audit.search' | translate}}
    </h4>
    <form ngForm [formGroup]="auditForm">
      <div class="wcg-col-xs-10 wcg-col-md-10 wcg-col-lg-10 pt-15">
        <div class="wcg-row">
          <div class="wcg-col-xs-4 wcg-col-md-4 wcg-col-lg-4 pl-0">
            <wcg-form-field>
              <wcg-label>{{ 'audit.emailAddress' | translate }}</wcg-label>
              <wcg-input ngDefaultControl formControlName="emailAddress"></wcg-input>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-3 wcg-col-md-3 wcg-col-lg-3">
            <wcg-form-field>
              <wcg-label>{{ 'audit.irbTracking' | translate }}</wcg-label>
              <wcg-input ngDefaultControl formControlName="irbTracking"></wcg-input>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-3 wcg-col-md-3 wcg-col-lg-3">
            <button [disabled]="auditForm.invalid" class="wcg-btn wcg-btn-fluid wcg-btn-primary mt-10"
              (click)="run()">{{ 'audit.runAudit' | translate }}</button>
          </div>
        </div>
      </div>
      <div class="wcg-row"  *ngIf="tableData?.length">
        <div class="wcg-col-xs-4 wcg-col-md-12 wcg-col-lg-12 wcg-rhythm-2">
          <div class="export-button-wrap">
            <app-export-action-button [gridFilters]="gridFilters"
              [exportType]="exportType" [entityId]="id"></app-export-action-button>
          </div>
        </div>
      </div>
    </form>

    <wcg-table class="wcg-rhythm-4" [headers]="headers" [data]="tableData" [options]="options" *ngIf="auditForm.valid && totalRecords != 0 && showTable">
      <div class="cnxs-table-loader" slot="table-body">
        <wcg-loader [show]="loading && auditForm.valid"></wcg-loader>
      </div>

      <div class="cnxs-pagination-wrap wcg-table-footer">
        <div class="wcg-total-results">
          {{ 'audit.totalResults' | translate}} {{totalRecords}}
        </div>
        <div class="pagination">
          <wcg-pagination *ngIf="tableData?.length" [current]="currentPage" [pageSize]="currentPageSize" [pageSizeDataSource]="[25, 50, 100, 200]"
            [max]="utilService.maxPages(totalRecords, currentPageSize)" (changePage)="handlePageChange($event)"></wcg-pagination>
        </div>
      </div>
    </wcg-table>
    <app-no-records *ngIf="totalRecords == 0" class="no-records">
      <p class="wcg-h2">{{ 'audit.noRecords' | translate }}</p>
    </app-no-records>
  </div>
</div>