import { PermissionType, RequestStatus } from '../common/collections';
import { Address } from 'src/app/interfaces/common';
import { OrganizationTypeModel } from '../interfaces/teams';

export class TeamOrganizationUserCreateModel {
    email: string;
    permissionType: PermissionType;
    workspacePermissionType: PermissionType;
    isRetry: boolean;
}

export class IdValueModel {
    id?: number;
    value?: string;
}

export class TeamOrganizationModel {
    id: string;
    name: string;
    organizationType: IdValueModel;
    email: string;
    address: Address;
    primaryTeamUserEmail: string;
}

export class UserInfo {
    externalReferenceId: string;
    email: string;
    firstName: string;
    lastName: string;
    userName: string;
    lastActivityDate: Date;
    createdDateTimeOffset: Date;
    organizationName: string;
    organizationType: string;
    isActive: boolean;
    isDeleted: boolean;
}

export class OrganizationModel {
    id: string;
    name: string;
    organizationType: OrganizationTypeModel;
    phone: string;
}

export class OrganizationUserModel extends UserInfo {
    isPrimary: boolean;
    permissionType: PermissionType;
    requestStatus: RequestStatus;
}
export class TeamDetailModel extends OrganizationModel {
    email: string;
    companyId: number;
    teamAddresses: Array<Address>;
    primaryUser: OrganizationUserModel;
    createdDate: Date;
    createdBy: string;
    modifiedDate: Date;
    modifiedBy: string;
    isActive: boolean;
    isPrimaryUser: boolean;
    userPermissionType: PermissionType;
}

export class TeamOrganizationEditModel {
    id: string;
    name: string;
    organizationType: IdValueModel;
    email: string;
    addresses: Address[];
    primaryTeamUserEmail: string;
    companyId: number;
}

export class TeamOrganizationPatchModel {
    active: boolean;
    primaryUserEmail: string;
}
