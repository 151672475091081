<div class="document-types">
  <div *ngIf="errorMessage != ''" class="wcg-rhythm-4">
    <wcg-notification variant="error">{{errorMessage}}</wcg-notification>
  </div>
  <div class="wcg-row">
    <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-3 add-button">
      <button class="wcg-btn wcg-btn-icon" (click)="createDocumentType()">
        <wcg-icon src="./assets/icons/content/ic_add_circle_outline_24px.svg"></wcg-icon>
        <span>Add New</span>
      </button>
    </div>
    <div class="wcg-col-xs-4 wcg-col-md-4 wcg-col-lg-3"></div>
    <div class="wcg-col-xs-4 wcg-col-md-6 wcg-col-lg-6">
      <wcg-form-field class="search-form">
        <wcg-label>Search grid</wcg-label>
        <wcg-input [formControl]="searchValue" [value]="searchValue.value">
          <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
        </wcg-input>
      </wcg-form-field>
    </div>
  </div>

  <wcg-table class="wcg-rhythm-4" [ngClass]="{'cnxs-table-loading': isShowLoader}" [headers]="headers" [data]="documentTypesFiltered" (rowClicked)="handleRowClicked($event)"
    [options]="options">
    <div class="cnxs-pagination-wrap">
      <wcg-pagination *ngIf="!isShowLoader && documentTypesFiltered?.length" [current]="currentPage" [pageSize]="currentPageSize" 
        [max]="utilService.maxPages(totalRecords, currentPageSize)" (changePage)="handlePageChange($event)">
      </wcg-pagination>
    </div>
    <div class="cnxs-table-loader" slot="table-body">
      <wcg-loader [show]="isShowLoader"></wcg-loader>
    </div>
  </wcg-table>

  <div class="modal">
    <wcg-modal id="add-new-modal" [show]="showModal" (close)="showModal = false">
      <wcg-modal-header>
        <p class="modal-title">
          {{modalTitle}}
        </p>
      </wcg-modal-header>
      <wcg-modal-body>
        <p class="explantation">Required *</p>
        <div class="input-fields">
          <div class="input-field">
            <wcg-form-field>
              <wcg-label>Document Type Name *</wcg-label>
              <wcg-input max-length=50 [formControl]="newDocumentTypeDocumentTypeName"
                [value]="newDocumentTypeDocumentTypeName.value">
              </wcg-input>
              <wcg-error-message class="error-message" *ngIf="newDocumentTypeDocumentTypeName.value == ''">Please enter
                document type name.
              </wcg-error-message>
            </wcg-form-field>
          </div>
          <div class="input-field">
            <wcg-form-field>
              <wcg-label>Document Type Category</wcg-label>
              <wcg-select (change)="handleChange($event)" [formControl]="newCategoryName"
                [value]="newCategoryName.value">
                <option selected hidden></option>
                <option *ngFor="let option of categories" [value]="option.id">{{option.value}}
                </option>
              </wcg-select>
            </wcg-form-field>
          </div>
        </div>
        <div class="checkboxes">
          <wcg-checkbox [formControl]="newDocumentTypeIsSubmissionDocument" class="wcg-rhythm-1">
            Submission document</wcg-checkbox>
          <wcg-checkbox [formControl]="newDocumentTypeIsOcr" class="wcg-rhythm-1">OCR
          </wcg-checkbox>
        </div>
      </wcg-modal-body>
      <wcg-modal-footer>
        <button class="wcg-btn wcg-btn-auto" (click)="showModal = false">Cancel</button>
        <button (click)="save()" [disabled]="newDocumentTypeDocumentTypeName.value == ''"
          class="wcg-btn wcg-btn-auto wcg-btn-primary">Save</button>
      </wcg-modal-footer>
    </wcg-modal>
  </div>
</div>