import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../components/base/base.component';
import { Router } from '@angular/router';
import { takeUntil, debounce } from 'rxjs/operators';
import { timer } from 'rxjs';

import { ApiService } from '../../../services/api.service';
import { FormControl } from '@angular/forms';
import { TableHeader } from '../../../interfaces/components';
import { StudiesGridNewSubmission, SubmissionStudiesGrid } from '../../../interfaces/studies';
import { StudiesSortOptions, DEBOUNCE_TIME, FlowTypeName } from '../../../common/collections';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { UtilService } from '../../../services/util.service';
import { ToastService } from '../../../services/toast.service';
import { SubmissionWorkflowService } from '../submission-workflow.service';
import { StateService } from '../../../services/state.service';
import { SubmissionFindStudy, SubmissionType } from '../../../interfaces/submissions';

@Component({
  selector: 'app-submission-find',
  templateUrl: './submission-find.component.html',
  styleUrls: ['./submission-find.component.scss']
})

export class SubmissionFindComponent extends BaseComponent implements OnInit {
  currentPage = 1;
  currentPageSize = 10;
  currentSort: string = StudiesSortOptions.nameLink;
  currentSortDescending: boolean;
  hasNav = false;
  headers: Array<TableHeader>;
  loaded: boolean;
  loading: boolean;
  options = {
    manualPaginationAndSort: true
  };

  searchTerm = new FormControl('');
  selected: string;
  showOverlay = true;
  tableData: Array<StudiesGridNewSubmission>;
  totalRecords: number;
  showRequestAccessConfirmationModal: boolean;
  showEnterIRBTrackingModal: boolean;
  submissionFindStudyValid = true;
  submissionFlow: string;

  private initialSubmissionGroup: SubmissionType;

  constructor(
    public router: Router,
    private apiService: ApiService,
    public utilService: UtilService,
    public stateService: StateService,
    public submissionWorkflowService: SubmissionWorkflowService,
    private toastService: ToastService,
    private translatePipe: TranslatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.submissionWorkflowService.resetSaveStatus();
    this.submissionWorkflowService.submissionSponsorProtocolId.next('');
    this.headers = this.getHeaders();
    this.currentSortDescending = false;
    this.submissionFlow = this.submissionWorkflowService.submissionFlow.value;
    this.initialSubmissionGroup = this.submissionWorkflowService.submissionTypeGroup.value;
    this.postStudiesSubmit({
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: '',
      sortOptions: [{
        field: StudiesSortOptions.nameLink,
        isDescending: false
      }]
    });

    this.searchTerm.valueChanges
      .pipe(
        debounce(() => timer(DEBOUNCE_TIME))
      )
      .subscribe((val) => {
        this.postStudiesSubmit({
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: val,
          sortOptions: [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }]
        });
      });
  }

  public onRadioChange(event): void {
    const row = event.target.value;
    const siteOnlyAccess = this.tableData[row].accessLevel === 'Site';
    const siteRequired = this.submissionWorkflowService.siteSingleSelect.value;
    const newSite = this.submissionWorkflowService.submissionTypeGroups.value.find((object) => object.value === FlowTypeName.NewSite);
    const studyData: SubmissionFindStudy = {
      name: this.tableData[row].nameData,
      protocol: this.tableData[row].protocolData,
      sponsor: this.tableData[row].sponsorData,
      id: this.tableData[row].id,
      status: this.tableData[row].status
    };

    this.submissionWorkflowService.submissionStudySites.next([]);
    this.submissionWorkflowService.submissionStudySitesTotal.next(0);

    this.submissionWorkflowService.siteRequired.next(siteOnlyAccess || siteRequired);
    this.submissionWorkflowService.submissionFindStudy.next(studyData);
    this.submissionWorkflowService.submissionSponsorProtocolId.next(studyData.protocol);
    this.submissionWorkflowService.submissionTypeGroup.next(this.initialSubmissionGroup);

    if (siteOnlyAccess && this.initialSubmissionGroup !== newSite) {
      this.submissionWorkflowService.submissionTypeGroup.next(
        this.submissionWorkflowService.submissionTypeGroups.value.find((object) => object.value === FlowTypeName.ExistingSite)
      );
    }

    const statusClosed = this.submissionWorkflowService.submissionFindStudy.value.status.includes('Closed');
    if (this.submissionWorkflowService.submissionFindStudy.value && !statusClosed) {
      this.submissionFindStudyValid = false;
    }
  }

  public continueClick(): void {
    if (this.submissionFlow === 'addDocuments') {
      this.router.navigateByUrl('/submission-workflow/choose-sites');
    }
    else {
      this.router.navigateByUrl('/submission-workflow/details');
    }
  }

  /* eslint-disable no-mixed-operators */
  public goBack(): void {
    if (this.submissionWorkflowService.submissionFlow.value === 'addStudy' &&
      this.submissionWorkflowService.submissionTypeValue.value === 'A New Study for Initial Review' ||
        this.submissionWorkflowService.submissionFlow.value === 'addDocuments' &&
            (this.submissionWorkflowService.submissionTypeValue.value === 'Translation Request' ||
              this.submissionWorkflowService.submissionTypeValue.value === 'Change In Research')) {
      this.router.navigateByUrl('/submission-workflow/setup/regions');
    }
    else {
      this.router.navigateByUrl('/submission-workflow/setup/types');
    }
  }
  /* eslint-enable no-mixed-operators */

  handlePageChange(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;

    this.postStudiesSubmit({
      page: event.detail.page,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }]
    });
  }

  handleSort(event): void {
    this.currentSort = StudiesSortOptions[event.detail.field];
    this.currentSortDescending = !event.detail.ascending;
    this.postStudiesSubmit({
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: [{
        field: StudiesSortOptions[event.detail.field],
        isDescending: this.currentSortDescending
      }]
    });
  }

  private getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('studies.name'), 'nameLink', true),
      this.utilService.createTableHeader(this.translatePipe.transform('studies.protocolNumber'), 'protocolNumber', true),
      this.utilService.createTableHeader(this.translatePipe.transform('studies.sponsor'), 'sponsor', true),
      this.utilService.createTableHeader(this.translatePipe.transform('studies.status'), 'status', true),
      this.utilService.createTableHeader(this.translatePipe.transform('studies.irbTrackingNumber'), 'irbTrackingNumber', true)
    ];
  }

  private postStudiesSubmit(body): void {
    this.loading = true;
    this.submissionFindStudyValid = true;
    this.tableData = [];

    this.submissionWorkflowService.submissionFindStudy.next({
      name: '',
      protocol: '',
      sponsor: '',
      id: '',
      status: ''
    });

    this.apiService.postStudiesSubmit(body)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: SubmissionStudiesGrid) => {
        this.loaded = true;
        this.loading = false;
        this.totalRecords = data.totalRecords;
        this.tableData = this.submissionWorkflowService.mapStudiesGridData(data);
        this.currentPage = data.currentPage;
      }, (err) => {
        this.loaded = true;
        this.loading = false;

        this.toastService.add([{
          closable: true,
          id: 'postStudiesFailure',
          message: err.details.message,
          timeout: this.toastService.ERROR_TIMEOUT,
          variant: 'error'
        }]);
      });
  }
}
