import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { UtilService } from 'src/app/services/util.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-estimated-outcome-card',
  templateUrl: './estimated-outcome-card.component.html',
  styleUrls: ['./estimated-outcome-card.component.scss']
})
export class EstimatedOutcomeCardComponent extends BaseComponent implements OnInit {
  @Input() estimatedOutcomeDate: any;
  @Input() showHelp = true;

  supportEmail = '';

  constructor(
    public utilService: UtilService,
    public translatePipe: TranslatePipe,
    private apiService: ApiService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getSupportEmail();
  }

  private getSupportEmail(): void {
    this.apiService.getConfigurationValue('settings/SupportEmail')
      .subscribe((setting) => {
        this.supportEmail = setting.value;
      });
  }
}
