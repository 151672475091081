/* eslint-disable max-lines */
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { User } from 'oidc-client';
import { takeUntil } from 'rxjs/operators';
import { style, animate, transition, trigger } from '@angular/animations';

import { UserInfoDetailsMap } from './interfaces/user';
import { BaseComponent } from './components/base/base.component';
import { UserService } from './services/user.service';
import { AuthenticationService } from './core/services/authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { ToastService } from './services/toast.service';
import { TranslatePipe } from './pipes/translate.pipe';
import { ChangePasswordUrl, StopImpersonationUrl } from 'src/app/common/apis';
import { StateService } from './services/state.service';
import { StorageService } from './core/services/storage.service';
import { StorageModel, StorageKeys } from './models/storage.model';
import { ApiService } from './services/api.service';

const TRANSITION = 250;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({
          height: 0
        }),
        animate(TRANSITION, style({
          height: '*'
        }))
      ]),
      transition(':leave', [
        style({
          height: '*'
        }),
        animate(TRANSITION, style({
          height: 0
        }))
      ])
    ])
  ]
})
export class AppComponent extends BaseComponent implements OnInit {
  idleState = 'Not started.';
  isAdmin = false;
  isAuthenticated = false;
  isIRBStaff = false;
  showHelpModal = false;
  showIdle = false;
  showMenu = false;
  showUserMenu = false;
  timedOut = false;
  timeOutMinutes = 0;
  title = 'wcg-connexus-web';
  user: User;
  userFetched = false;
  userInfo: UserInfoDetailsMap;
  userInfoLoaded = false;
  userMenuHeight: number;
  walkMeLoaded = false;
  _window: any;
  isImpersonationActive = false;
  isApplicationInMaintenanceMode = false;
  canLoginDuringMaintenance = false;
  supportEmail = '';

  constructor(
    public stateService: StateService,
    public toastService: ToastService,
    private authenticationService: AuthenticationService,
    private domSanitizer: DomSanitizer,
    private idle: Idle,
    private router: Router,
    private storageService: StorageService,
    private translatePipe: TranslatePipe,
    private userService: UserService,
    private apiService: ApiService
  ) {
    super();
    // Redirect to old application
    this.redirectToOldApplication();
    this._window = window as any;

    this.router.events
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((val) => {
        if (val instanceof NavigationEnd) {
          const urlLength = 20;
          const pageUrl = val.url.slice(1, urlLength);

          this.showMenu = false;

          if (this.showUserMenu) {
            this.toggleMenu();
          }

          this.stateService.isWorkflow.next(
            pageUrl === 'submission-workflow' ||
            pageUrl === 'system-downtime'
          );

          if (this.userFetched && this.canLoginDuringMaintenance !== null && !this.canLoginDuringMaintenance) {
            this.getConfigurationValue();
          }
        }
      });
  }

  public redirectToMaintanance(): void {
    if (
      this.isApplicationInMaintenanceMode &&
      this.canLoginDuringMaintenance !== null &&
      !this.canLoginDuringMaintenance &&
      this.userFetched
    ) {
      this.router.navigate(['/system-downtime']);
    }
    else if (this.router.url === '/system-downtime') {
      this.router.navigate(['']);
    }
  }

  public redirectToOldApplication(): void {
    const redirct = environment.redirect;
    if (redirct === 'true') {
      this.router.navigate(['/redirect']);
    }
  }

  ngOnInit(): void {
    this.configure();
    this.completeRegistration();
  }

  public onActivate = (): void => {
    window.scroll(0, 0);
  }

  public toggleMenu(): void {
    this.showUserMenu = !this.showUserMenu;
    this.showMenu = false;
  }

  public closeShowIdleModal(): void {
    this.showIdle = false;
    this.timedOut = true;
    this.logout();
  }

  public keepSignedIn(): void {
    this.showIdle = false;
    this.reset();
  }

  public logout(): void {
    this.authenticationService.logout();
  }

  public redirectHome(): void {
    if (this.userInfo?.isRegistered === true) {
      this.router.navigate(['/']);
    }
  }

  public stopImpersonation(): void {
    this.isImpersonationActive = false;
    const baseLink = StopImpersonationUrl;
    this.authenticationService.logoutForImpersonate();
    window.location.href = baseLink;
  }

  private configure(): void {
    this.authenticationService.isAuthenticated()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((authenticated) => {
        this.isAuthenticated = authenticated;
      });

    this.authenticationService.authState
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((authenticated) => {
        this.isAuthenticated = authenticated;

        if (this.isAuthenticated && authenticated === false) {
          this.authenticationService.login();
        }
        if (this.isAuthenticated) {
          if (!this.userFetched) {
            this.userService.getUserInfo().subscribe((data) => {
              this.isAdmin = data.canViewSubmissions;
              this.canLoginDuringMaintenance = data.canLoginDuringMaintenance;
              this.isIRBStaff = !data.canViewUsers;
              this.userInfo = data;
              this.userInfoLoaded = Boolean(this.userInfo.role);
              this.startIdle();
              this.userPasswordNotifications(this.userInfo);
              this.alreadyRegisteredNotification(this.userInfo);
              this.getConfigurationValue();
              this.getSupportEmail();
              if (!this.walkMeLoaded && this.userInfo.externalReferenceId !== null) {
                this.setWalkMeVariables(this.userInfo);
                this.runWalkMeSnippet(environment.walkMe.snippetUrl);
                this.walkMeLoaded = true;
              }
              if (this.userInfo.impersonatorUsername !== null &&
                typeof this.userInfo.impersonatorUsername !== 'undefined') {
                this.isImpersonationActive = true;
                this.stateService.isImpersonationActive.next(true);
              }
            });
            this.userFetched = true;
          }
        }
      });
  }

  private getConfigurationValue(): void {
    this.apiService.getConfigurationValue('settings/IsApplicationInMaintenanceMode')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((setting) => {
        this.isApplicationInMaintenanceMode = setting.value === 'true';
      },
      () => {},
      () => this.redirectToMaintanance());
  }

  private getSupportEmail(): void {
    this.apiService.getConfigurationValue('settings/SupportEmail')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((setting) => {
        this.supportEmail = setting.value;
      });
  }

  private alreadyRegisteredNotification(userInfo: UserInfoDetailsMap): void {
    const registrationLink = this.storageService.getKey(StorageKeys.registrationLinkKey);

    if (registrationLink && userInfo?.isRegistered && userInfo?.isActive) {
      this.toastService.remove({ id: 'infoMessage' });
      this.storageService.setKey(new StorageModel(StorageKeys.registrationLinkKey, 'false'));

      this.toastService.add([{
        closable: true,
        id: 'alreadyRegisteredNotification',
        message: this.translatePipe.transform('notifications.alreadyRegistered'),
        variant: 'error'
      }]);
    }
  }

  private completeRegistration(): void {
    if (!this.userInfo || this.userInfo?.isRegistered === null) {
      this.userService.getUserInfo().subscribe((data) => {
        this.userInfo = data;
        this.userPasswordNotifications(this.userInfo);
        this.alreadyRegisteredNotification(this.userInfo);

        if (this.userInfo?.isRegistered === false) {
          this.router.navigate(['/registration']);
        }
      });
    }
    else if (this.userInfo?.isRegistered === false) {
      this.router.navigate(['/registration']);
    }
  }


  private passwordExpired(hasPasswordInfo, showNotification, userInfo, LINK): void {
    if (hasPasswordInfo && showNotification && (userInfo?.passwordInfo.isExpired || userInfo?.passwordInfo.expiratedDays < 0)) {
      this.toastService.add([{
        closable: false,
        id: 'passwordExpired',
        message: `
          ${this.translatePipe.transform('notifications.passwordExpired')}
          ${LINK}.
        `,
        variant: 'error'
      }]);
    }
  }

  private passwordIsExpiring(hasPasswordInfo, showNotification, userInfo, LINK): void {
    if (hasPasswordInfo && showNotification) {
      this.toastService.add([{
        closable: true,
        id: 'passwordIsExpiring',
        message: this.domSanitizer.bypassSecurityTrustHtml(`
  ${this.translatePipe.transform('notifications.passwordIsExpiring')
    .replace('{days}', userInfo?.passwordInfo.expiratedDays.toString())}
  ${LINK}.
        `),
        variant: 'error'
      }]);
    }
  }

  private passwordIsExpiringToday(hasPasswordInfo, userInfo, LINK): void {
    if (hasPasswordInfo && userInfo.passwordInfo.expiratedDays === 0 && Boolean(userInfo?.passwordInfo.showNotification)) {
      this.toastService.add([{
        closable: false,
        id: 'passwordIsExpiringToday',
        message: `
          ${this.translatePipe.transform('notifications.passwordIsExpiringToday')}
          ${LINK}.
        `,
        variant: 'error'
      }]);
    }
  }

  private reset(): void {
    this.idle.watch();
    this.timedOut = false;
  }

  private startIdle(): void {
    // sets an idle time
    this.idle.setIdle(environment.userIdleTime as number);

    // sets an logout notice time
    this.idle.setTimeout(environment.logoutNoticeTime as number);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      this.logout();
    });

    this.idle.onIdleStart.subscribe(() => {
      this.showIdle = true;
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.timeOutMinutes = countdown;
    });

    this.authenticationService.isAuthenticated().subscribe((authenticated) => {
      if (authenticated) {
        this.idle.watch();
        this.timedOut = false;
      }
      else {
        this.idle.stop();
      }
    });
  }

  private userPasswordNotifications(userInfo: UserInfoDetailsMap): void {
    const LINK = `<a class="wcg-link password-notification" href="${ChangePasswordUrl}${window.location.href}">
      <b>${this.translatePipe.transform('notifications.changePassword')}</b>
    </a>`;

    const hasPasswordInfo = Boolean(userInfo?.passwordInfo);
    const showNotification = Boolean(userInfo?.passwordInfo?.showNotification);

    this.passwordIsExpiringToday(hasPasswordInfo, userInfo, LINK);
    this.passwordExpired(hasPasswordInfo, showNotification, userInfo, LINK);
    this.passwordIsExpiring(hasPasswordInfo, showNotification, userInfo, LINK);
  }

  private runWalkMeSnippet(walkmeSnippetUrl): void {
    const walkme = document.createElement('script');
    walkme.type = 'text/javascript';
    walkme.async = true;
    walkme.src = walkmeSnippetUrl;
    const script = document.getElementsByTagName('script').item(0);
    script.parentNode.insertBefore(walkme, script);
    this._window._walkmeConfig = { smartLoad: true };
  }

  private setWalkMeVariables(userInfo: UserInfoDetailsMap): void {
    this._window.WalkMeVariables = {
      Role: userInfo?.role?.name,
      UserId: userInfo.externalReferenceId
    };
  }
}
