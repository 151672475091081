
<wcg-table
  class="wcg-rhythm-4"
  [ngClass]="{'cnxs-table-loading': loading}"
  [headers]="headers"
  [data]="tableData"
  [options]="options"
  (rowClicked)="handleRowClicked($event)">
  <div class="cnxs-table-loader" slot="table-body">
    <wcg-loader [show]="loading"></wcg-loader>
  </div>
</wcg-table>