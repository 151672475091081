import { Routes } from '@angular/router';
import { SubmissionsComponent } from '../views/submissions/submissions.component';
import { AuthGuard } from '../guards/auth.guard';
import { SubmissionsDetailComponent } from '../views/submission/submission.component';
import { SubmittedDocumentsComponent } from '../views/submission/submission-submitted-documents/submission-submitted-documents.component';
import { OutcomeDocumentsComponent } from '../views/submission/submission-outcome-documents/submission-outcome-documents.component';
import { SubmissionSitesComponent } from '../views/submission/submission-sites/submission-sites.component';
import { RequestAccessEntityType } from '../common/collections';

export const submissionRoutes: Routes = [{
  path: 'submissions',
  component: SubmissionsComponent,
  canActivate: [
    AuthGuard
  ]
},
{
  path: 'submissions/:id',
  component: SubmissionsDetailComponent,
  canActivate: [
    AuthGuard
  ],
  data: {
    canRequestAccess: true,
    entityType: RequestAccessEntityType.submission
  },
  children: [
    {
      path: '',
      redirectTo: 'sites',
      pathMatch: 'full',
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.submission
      }
    },
    {
      path: 'sites',
      component: SubmissionSitesComponent,
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.submission
      }
    },
    {
      path: 'submitted-documents',
      component: SubmittedDocumentsComponent,
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.submission
      }
    },
    {
      path: 'outcome-documents',
      component: OutcomeDocumentsComponent,
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.submission
      }
    }
  ]
}];
