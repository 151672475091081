import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Apis } from 'src/app/common/apis';
import { KnownScope } from 'src/app/common/collections';
import { UserAutocompleteMap } from 'src/app/interfaces/user';
import { UtilService } from 'src/app/services/util.service';
import { AdminApis } from '../helpers/adminapis';
import { WebApiServices } from '../helpers/webapiservice';
import { TeamInfo, TeamInfoMap } from '../model/teamInfo';
import { UserInfoMap } from '../model/userinfo';

@Injectable({
  providedIn: 'root'
})
export class TeamAdminService {
  service: WebApiServices;
  public endDate = new BehaviorSubject<Date>(null);
  public startDate = new BehaviorSubject<Date>(null);
  public searchTerm = new BehaviorSubject<string>('');
  public reloadTeamStudyGrid = new BehaviorSubject<boolean>(false);
  public reloadTeamSiteGrid = new BehaviorSubject<boolean>(false);
  public reloadTeamUserGrid = new BehaviorSubject<boolean>(false);
  public isTeamActive = new BehaviorSubject<boolean>(true);
  public isAdmin = new BehaviorSubject<boolean>(false);
  public reloadTeamDetails = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private utilService: UtilService) {
    this.service = new WebApiServices(this.http);
  }

  public getCountries(): Observable<any> {
    return this.service.getService(Apis.ContactCountries, null);
  }

  public getStates(id: string): Observable<any> {
    const path = `${Apis.ContactStates}`.replace('{countryid}', id);

    return this.service.getService(path, null);
  }

  public getTeamOrganizationTypes(): Observable<any> {
    const path = `${Apis.ContactOrganizationTypesByScope}`.replace('{scopeId}', `${KnownScope.Teams}`);

    return this.service.getService(path, null);
  }

  public getUsers(filter, teamExternalReferenceId = null, includeNotRegistered = false): Observable<any> {
    let path = `${Apis.PrimaryUsers}?mask=${encodeURIComponent(filter)}`;

    if (teamExternalReferenceId !== null) {
      path = `${path}&teamExternalReferenceId=${teamExternalReferenceId}`;
    }

    if (includeNotRegistered) {
      path = `${path}&includeNotRegistered=${includeNotRegistered}`;
    }

    return this.service.getService(path, null);
  }


  public IsValidTeamOrganizationEmail(email, teamId: string = null): Observable<any> {
    let path = `${Apis.ValidateEmail}`.replace('{email}', `${encodeURIComponent(email)}`);

    if (teamId !== null && teamId.length > 0) {
      path = `${path}&teamExternalReferenceId=${teamId}`;
    }

    return this.service.getService(path, null);
  }

  public getTeamInfoByTeamId(teamId: string): Observable<any> {
    const path = `${Apis.OrganizationTeam}`.replace('{teamId}', `${teamId}`);

    return this.service.getService(path, null);
  }

  public saveTeamInfo(data): Observable<any> {
    const path = `${Apis.CreateOrganization}`;

    return this.service.postService(path, data, null);
  }

  public mapUsers = (data: Array<UserInfoMap>): Array<UserAutocompleteMap> => data.map((record) => ({
    email: record.email,
    option: `${[record.firstName, record.lastName].filter(Boolean).join(' ')} (${record.email})`,
    value: record.email
  }));

  public getfilteredTeams(model): Observable<any> {
    const url = AdminApis.Teams;

    return this.service.postService(url, model, null);
  }

  public updateTeam(teamId, data): Observable<any> {
    const url = `${AdminApis.UpdateTeam}`.replace('{teamId}', `${teamId}`);

    return this.service.updateService(url, data, null);
  }

  public setTeamOrganizationStatus(teamId, data): Observable<any> {
    const url = `${AdminApis.SetTeamOrganizationStatus}`.replace('{teamId}', `${teamId}`);

    return this.service.patchService(url, data, null);
  }

  public postTeamOrganizationUsers(teamId, data): Observable<any> {
    const url = `${AdminApis.AddTeamUser}`.replace('{teamId}', `${teamId}`);

    return this.service.postService(url, data, null);
  }

  public mapTeamData(data: Array<TeamInfo>): Array<TeamInfoMap> {
    return data.map((record) => ({
      id: record.id,
      name: this.utilService.addTableCellWrapperWithSearchTerms(record.name, this.searchTerm.value, `teams/${record.id}`),
      type: this.utilService.addTableCellWrapperWithSearchTerms(record.organizationType.value, this.searchTerm.value),
      email: this.utilService.addTableCellWrapperWithSearchTerms(record.email, this.searchTerm.value),
      primaryUser: this.utilService.addTableCellWrapperWithSearchTerms(
        record.primaryName, this.searchTerm.value
      ),
      studies: this.utilService.addTableCellWrapperWithSearchTerms(`${record.studyCount}`, this.searchTerm.value),
      sites: this.utilService.addTableCellWrapperWithSearchTerms(`${record.siteCount}`, this.searchTerm.value),
      teamMembers: this.utilService.addTableCellWrapperWithSearchTerms(`${record.teamMemberCount}`, this.searchTerm.value),
      status: record.active === true ?
        '<div class="done_icon"><wcg-icon ' +
        'src="./assets/icons/action/ic_done_24px.svg"></wcg-icon></div>' :
        '<wcg-icon src="./assets/icons/content/ic_clear_24px.svg"></wcg-icon>',
      companyId: this.utilService.addTableCellWrapperWithSearchTerms(`${record.companyId}`, this.searchTerm.value),
      createdDate: record.createdDateTimeOffset ? this.utilService.highlightDateFields(
        record.createdDateTimeOffset, this.startDate.value, this.endDate.value
      ) : null,
      createdBy: this.utilService.addTableCellWrapperWithSearchTerms(record.createdByName, this.searchTerm.value)
    }));
  }

  public deleteTeamWorkspaces(teamId, workspaceIds): Observable<any> {
    const url = `${AdminApis.DeleteTeamWorkspace}`.replace('{teamId}', `${teamId}`);

    return this.service.updateService(url, workspaceIds, null);
  }

  public deleteTeamUsers(teamId, userEmails): Observable<any> {
    const url = `${AdminApis.DeleteTeamUser}`.replace('{teamId}', `${teamId}`);

    return this.service.updateService(url, userEmails, null);
  }
}
