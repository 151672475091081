/* eslint-disable max-lines */
import { StorageKeys } from './../../models/storage.model';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import {
  ContactCountries, ContactStates, ContactOrganizationTypes, ContactOrganizations, UserInfoDetailsMap
} from 'src/app/interfaces/user';
import { UserService } from 'src/app/services/user.service';
import { UserAdminService } from 'src/app/admin/shared/services/user-admin.service';
import { LoggerService } from 'src/app/services/logger.service';
import { Prefixes, Suffixes } from 'src/app/common/collections';
import { Step } from 'src/app/models/step.model';
import { StorageService } from '../services/storage.service';
import { StorageModel } from 'src/app/models/storage.model';
import { ToastService } from 'src/app/services/toast.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserRegistrationComponent implements OnInit {
  basicInfoForm: FormGroup;
  personalInfoForm: FormGroup;
  companyInfoForm: FormGroup;

  currentUserInfo: any;
  userId = '';
  stateLabel: string;

  countries: ContactCountries;
  states: ContactStates;
  organizations: ContactOrganizations;
  organizationTypes: ContactOrganizationTypes;
  prefixes = Prefixes;
  suffixes = [{
    key: '-1',
    value: 'Please select'
  }].concat(Suffixes);

  user = {} as UserInfoDetailsMap;

  steps: Step[] = [];

  public selectedStep = 0;
  public lastStep: number;
  public isLastStep = false;
  public initialStep = 0;
  isRegistered = false;

  public constructor(private userService: UserService, private userAdminService: UserAdminService, private storageService: StorageService,
    private loggerService: LoggerService, private formBuilder: FormBuilder, private router: Router,
    private translatePipe: TranslatePipe, public toastService: ToastService, public authenticationService: AuthenticationService) {
  }

  public ngOnInit(): void {
    this.steps = [
      new Step('Basic Information'),
      new Step('Personal Information'),
      new Step('Company Information')
    ];

    this.toastService.add([{
      closable: true,
      id: 'infoMessage',
      message: this.translatePipe.transform('userRegistration.infoMessage'),
      variant: 'success'
    }]);

    this.selectedStep = this.initialStep;
    this.lastStep = this.calculateLastStepIndex();
    this.isLastStep = this.steps.length === 1;

    this.initializeForms();

    this.userService.userInfo.subscribe((data) => {
      this.currentUserInfo = data;
      this.alreadyRegistered();
      this.userId = this.currentUserInfo.externalReferenceId;
      this.mapUserDataToProfile(this.basicInfoForm, this.currentUserInfo);
    });

    this.getCountries();
    this.getOrganizationTypes();
  }

  public initializeForms(): void {
    this.basicInfoForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      prefix: [''],
      suffix: [''],
      degrees: [''],
      phone: ['', Validators.required]
    });

    this.personalInfoForm = this.formBuilder.group({
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      province: ['', Validators.required],
      postalCode: ['', Validators.required],
      country: ['', Validators.required]
    });

    this.companyInfoForm = this.formBuilder.group({
      company: ['', Validators.required],
      organization: ['', Validators.required],
      phoneAdditional: ['']
    });
  }

  public onSubmit(): void {
    this.userAdminService.postUser(this.mapUserRegistrationForPost())
      .subscribe(() => {
        this.isRegistered = true;
        const user = this.authenticationService.getUser();
        this.userService.updateUserInfo(user);
        this.storageService.setKey(new StorageModel(StorageKeys.registrationLinkKey, 'false'));
        this.toastService.remove({ id: 'alreadyRegisteredNotification' });
        this.toastService.remove({ id: 'infoMessage' });

        this.toastService.add([{
          closable: true,
          id: 'completedMessage',
          message: this.translatePipe.transform('userRegistration.completedMessage'),
          variant: 'success'
        }]);
        this.navigateToDashboard();
      }, (err) => {
        this.loggerService.error(`Error while completing user registration. ${err}`);
      });
  }

  public handleCountryChange(event): void {
    this.personalInfoForm.patchValue({ country: event.target.value });
    this.updateStates(parseInt(event.target.value, 10), true);
  }

  public stateChange(event): void {
    if (this.personalInfoForm.controls.state.value === '-1') {
      this.personalInfoForm.patchValue({ province: ' ' });
      this.personalInfoForm.patchValue({ state: '' });
    }
    else {
      this.personalInfoForm.patchValue({ state: event.target.value });
    }
  }

  public prefixChange(event): void {
    this.basicInfoForm.patchValue({ prefix: event.target.value });
  }

  public suffixChange(event): void {
    this.basicInfoForm.patchValue({ suffix: event.target.value });
  }

  organizationChange(event): void {
    if (this.companyInfoForm.controls.organization.value === '-1') {
      this.companyInfoForm.patchValue({ organization: '' });
    }
    else {
      this.companyInfoForm.patchValue({ organization: event.target.value });
    }
  }

  public mapUserDataToProfile(basicInfoForm: FormGroup, userInfo: any): void {
    basicInfoForm.setValue(
      {
        firstName: userInfo.firstName,
        middleName: null,
        lastName: userInfo.lastName,
        prefix: this.prefixes[0].key,
        suffix: '-1',
        degrees: null,
        phone: null
      }
    );
  }

  public mapUserRegistrationForPost(): object {
    const basicInfo = this.basicInfoForm.value;
    const personalInfo = this.personalInfoForm.value;
    const companyInfo = this.companyInfoForm.value;

    const country = this.countries?.find((item) => item.id === parseInt(personalInfo.country, 10));
    const state = this.states?.find((item) => item.id === parseInt(personalInfo.state, 10));

    this.user.firstName = basicInfo.firstName;
    this.user.middleName = basicInfo.middleName;
    this.user.lastName = basicInfo.lastName;
    this.user.prefix = basicInfo.prefix;
    this.user.suffix = basicInfo.suffix === '-1' ? null : basicInfo.suffix;
    this.user.degrees = basicInfo.degrees;
    this.user.phone = basicInfo.phone;

    this.user.externalReferenceId = this.userId;
    this.user.email = null;
    this.user.isEmailNotificationEnabled = true;

    this.user.concurrencyVersion = null;
    this.user.detailsConcurrencyVersion = null;
    this.user.address = {
      addressLine1: personalInfo.addressLine1,
      addressLine2: personalInfo.addressLine2,
      city: personalInfo.city,
      postalCode: personalInfo.postalCode,
      country: country,
      state: state,
      province: personalInfo.province
    };

    const organizationType = this.organizationTypes?.find((item) => item.value === companyInfo.organization);

    this.user.organization = {
      id: null,
      name: companyInfo.company,
      phone: companyInfo.phoneAdditional,
      organizationType: organizationType
    };

    return this.cleanupUserDetails(this.user);
  }

  public getCountries(): void {
    this.userAdminService.getCountries()
      .subscribe((data) => {
        this.countries = data;
        const usa = this.countries.find((country) => country.alpha2Code === 'US');
        this.updateStates(usa.id, false);
        this.personalInfoForm.controls.country.setValue(usa.id);
      }, (err) => {
        this.loggerService.error(`User Countries could not be fetched. ${err}`);
      });
  }

  public getStates(countryId: number): void {
    this.userAdminService.getStates(countryId.toString())
      .subscribe((data) => {
        this.states = data;
      }, (err) => {
        this.loggerService.error(`User States could not be fetched. ${err}`);
      });
  }

  public getOrganizationTypes(): void {
    this.userAdminService.getOrganizationTypes()
      .subscribe((data) => {
        this.organizationTypes = data;
        this.organizationTypes = this.organizationTypes.filter(({ value }) => value !== 'IRB');
      }, (err) => {
        this.loggerService.error(`User Organization Types could not be fetched. ${err}`);
      });
  }

  public updateStates(value: number, change?: boolean): void {
    if (this.countries) {
      const usa = this.countries.find((country) => country.alpha2Code === 'US');
      const canada = this.countries.find((country) => country.alpha2Code === 'CA');
      const found = [usa.id, canada.id].find((country) => country === value);

      this.stateLabel = value === usa.id ? 'profile.profileFormState' : 'profile.profileFormProvince';

      if (found) {
        this.getStates(value);
        this.personalInfoForm.patchValue({ province: ' ' });
        if (change) {
          this.personalInfoForm.patchValue({ state: '' });
        }
      }
      else {
        if (change) {
          this.personalInfoForm.patchValue({ province: '' });
          this.personalInfoForm.patchValue({ state: ' ' });
        }
        this.states = [];
      }
    }
  }

  // #region  Wizard

  public nextStep(supressValidation?: boolean): void {
    this.allowNextStep(this.selectedStep + 1, supressValidation);
  }

  /* eslint-disable complexity */
  public allowNextStep(step: number, supressValidation?: boolean): void {
    if (this.selectedStep > step) {
      this.setStep(step, supressValidation);
    }
    if (this.selectedStep === 0 && this.basicInfoForm.valid) {
      this.setStep(step, supressValidation);
    }
    if (this.selectedStep === 1 && this.personalInfoForm.valid) {
      this.setStep(step, supressValidation);
    }
    if (this.selectedStep === 2 && this.companyInfoForm.valid) {
      this.setStep(step, supressValidation);
    }
  }

  /* eslint-disable complexity */
  /* eslint-disable max-depth */
  public setStep(step: number, supressValidation?: boolean): void {
    if (this.steps[step].isClickable) {
      if (step < this.steps.length) {
        let canChangeStep = true;

        if (step > this.selectedStep) {
          if (this.isNextStep(step)) {
            if (!supressValidation) {
              canChangeStep = true;
            }

            if (canChangeStep) {
              this.makeStep(step);
            }
          }
        }
        else {
          this.makeStep(step);
        }
      }
    }
  }

  private makeStep(step: number): void {
    this.selectedStep = step;
    this.isLastStep = step === this.lastStep;
    window.scrollTo(0, 0);
  }

  private isNextStep(step: number): boolean {
    return step === this.selectedStep + 1;
  }

  private calculateLastStepIndex(): number {
    for (let cnt = this.steps.length - 1; cnt >= 0; cnt--) {
      if (this.steps[cnt].isClickable) {
        return cnt;
      }
    }

    return 0;
  }

  // #endregion

  private navigateToDashboard(): void {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        registration: 'completed'
      }
    };
    this.router.navigate(['dashboard'], navigationExtras);
  }

  private cleanupUserDetails = (user: any): any => {
    const userData = { ...user };
    if (Object.prototype.hasOwnProperty.call(userData.organization, 'address')) {
      delete userData.organization.address;
    }

    if (Object.prototype.hasOwnProperty.call(userData, 'lastActivityDate')) {
      delete userData.lastActivityDate;
    }

    return userData;
  }

  private alreadyRegistered(): void {
    if (this.currentUserInfo.isRegistered) {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          notificationType: 'alreadyRegistered'
        }
      };
      this.router.navigate(['/dashboard'], navigationExtras);
    }
  }
}
