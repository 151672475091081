import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { LoggerService } from '../../services/logger.service';
import { UtilService } from '../../services/util.service';
import { SitePi, SiteMap, SitePiMap } from '../../interfaces/sites';
import { SiteService } from './site.service';
import { WorkspacePermissions } from '../../interfaces/workspaces';
import { ToastService } from 'src/app/services/toast.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { BaseSubmissionComponent } from 'src/app/components/base-submission/base-submission.component';
import { SubmissionWorkflowService } from '../submission-workflow/submission-workflow.service';
import { StateService } from 'src/app/services/state.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { SubmissionFindStudy } from 'src/app/interfaces/submissions';
import { StudySitesSubmissionMap } from 'src/app/interfaces/studies';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss']
})
export class SiteComponent extends BaseSubmissionComponent implements OnInit {
  id: string;
  permissions: WorkspacePermissions;
  principalInvestigator: SitePiMap;
  principalInfestigatorFullDetails: SitePi;
  site: SiteMap;
  closedStatus = 'Closed';
  canAddDocuments = new BehaviorSubject(false);
  siteDataLoaded = new BehaviorSubject<boolean>(false);
  workspaceDataLoaded = new BehaviorSubject<boolean>(false);
  canStartSubmissionButtonDisabled = new BehaviorSubject<boolean>(true);

  constructor(
    apiService: ApiService,
    private loggerService: LoggerService,
    router: Router,
    private siteService: SiteService,
    public utilService: UtilService,
    toastService: ToastService,
    private translatePipe: TranslatePipe,
    submissionWorkflowService: SubmissionWorkflowService,
    stateService: StateService
  ) {
    super(router, stateService, apiService, submissionWorkflowService, toastService);
    this.submissionDataLoaded.subscribe((loaded) => {
      if (loaded) {
        [this.id] = this.router.url.split('/sites/')[1].split('/');
        this.getUserWorkspacePermissionsById(this.id);
        this.getSitePi(this.id);
      }
    });
    combineLatest([
      this.workspaceDataLoaded,
      this.siteDataLoaded
    ]).subscribe((value) => {
      const allLoaded = value.every((item) => item === true);
      this.canStartSubmissionButtonDisabled.next(!allLoaded);
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public onAddSiteDocumentsSelect(): void {
    this.submissionWorkflowService.submissionFlow.next('addDocuments');
    this.submissionWorkflowService.submissionTypeGroup.next(this.existingSite);
    this.routeToTypes();
  }

  /* eslint-disable max-lines-per-function */
  private getSite(id): void {
    this.apiService.getSite(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.site = this.siteService.mapSiteData(data);
        //  get data for setup/regions step
        const allRegions = this.submissionWorkflowService.submissionReviewTypes.value;
        const siteRegion = this.principalInfestigatorFullDetails.address?.country.name === 'Canada' ?
          allRegions.find((item) => item.value.includes('Canadian')) :
          allRegions.find((item) => item.value.includes('International'));
        const regionData: Array<string> = [siteRegion.id.toString()];
        this.submissionWorkflowService.submissionStudySiteRegion = regionData;
        //  get data for submission-workflow/search-study step
        const studyData: SubmissionFindStudy = {
          name: data.study.value,
          protocol: data.protocolNumber,
          id: data.study.id,
          sponsor: data.sponsor,
          status: data.status
        };
        this.submissionWorkflowService.submissionFindStudy.next(studyData);
        this.submissionWorkflowService.submissionSponsorProtocolId.next(studyData.protocol);
        this.submissionWorkflowService.originUrl.next(this.router.url);
        //  get data for submission-workflow/choose-sites
        //  total number of sites that are displayed in View Details
        this.submissionWorkflowService.submissionStudySitesTotal.next(1);
        const piFullName = this.principalInfestigatorFullDetails.lastName.concat(', ', this.principalInfestigatorFullDetails.firstName);
        //  site data
        const siteData: StudySitesSubmissionMap = {
          countryName: this.principalInfestigatorFullDetails.address?.country.name,
          firstName: this.principalInfestigatorFullDetails.firstName,
          expirationDate: this.site.expirationDate,
          id: this.id,
          initialApprovalDate: null,
          institutionalTrackingNumber: this.site.institutionalTrackingNumber,
          investigator: piFullName,
          irbTrackingNumber: this.site.irbTrackingNumber,
          isMedicalOrInstitutional: false,
          lastName: this.principalInfestigatorFullDetails.lastName,
          lastReviewDate: null,
          piOrganizationName: this.principalInfestigatorFullDetails.organization.name,
          protocolNumber: this.site.sponsorProtocolId,
          selected: false,
          sponsor: null,
          status: this.site.status,
          study: null,
          userId: null
        };
        this.submissionWorkflowService.submissionStudySites.next(Array.of(siteData));
        this.siteDataLoaded.next(true);
      }, () => {
        this.toastService.add([{
          closable: true,
          id: 'getSite',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          variant: 'error'
        }]);
      });
  }
  /* eslint-enable max-lines-per-function */

  private getSitePi(id): void {
    this.apiService.getSitePi(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.principalInfestigatorFullDetails = data;
        this.principalInvestigator = this.siteService.mapSitePiData(data);
        this.getSite(this.id);
      }, (err) => {
        this.loggerService.error(`Data could not be fetched: ${err}`);
      });
  }

  private getUserWorkspacePermissionsById(id): void {
    this.apiService.getUserSitePermissionsById(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.permissions = data;
        this.canAddDocuments.next(data.canAddDocumentsSubmissions);
        this.submissionWorkflowService.canCreateFullStudySubmission.next(
          data.canCreateFullStudySubmission
        );
        this.workspaceDataLoaded.next(true);
      }, (err) => {
        this.loggerService.error(`Data could not be fetched: ${err}`);
      });
  }
}
