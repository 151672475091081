import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from 'src/app/common/apis';

@Injectable()
export class WebApiServices {
  private endpointUrl: string;

  constructor(private httpClient: HttpClient) {
    this.endpointUrl = ApiBaseUrl;
  }

  public getService(path: string, requestParameters: HttpParams): Observable<any> {
    const apiUrl = this.endpointUrl + path;

    return this.httpClient.get(apiUrl, { params: requestParameters })
      .pipe(map((response: any) => response));
  }

  public getServiceWithResponseType(path: string, requestParameters: HttpParams, responseType: any): Observable<any> {
    const apiUrl = this.endpointUrl + path;

    return this.httpClient.get(apiUrl, {
      params: requestParameters,
      responseType: responseType
    }).pipe(map((response: any) => response));
  }

  public getBlobData(path: string, requestParameters: HttpParams): Observable<any> {
    const apiUrl = this.endpointUrl + path;

    return this.httpClient.get(apiUrl,
      {
        params: requestParameters,
        responseType: 'blob'
      }).pipe(map((response: any) => response));
  }

  public getServiceWithStringInput(path: string, key: string, requestParameters: HttpParams): Observable<any> {
    const apiUrl = `${this.endpointUrl} ${path} / ${key}`;

    return this.httpClient.get(apiUrl, { params: requestParameters })
      .pipe(map((response: any) => response));
  }

  public getServiceWithDynamicQueryTerm(path: string, key: string, val: string, requestParameters: HttpParams): Observable<any> {
    const apiUrl = `${this.endpointUrl} ${path} /? ${key} = ${val}`;

    return this.httpClient.get(apiUrl, { params: requestParameters })
      .pipe(map((response: any) => response));
  }

  public getServiceWithMultipleQueryTerm(path: string, query: string, requestParameters: HttpParams): Observable<any> {
    const apiUrl = `${this.endpointUrl} ${path} /? ${query}`;

    return this.httpClient.get(apiUrl, { params: requestParameters })
      .pipe(map((response: any) => response));
  }

  public postService(path: string, body: any, requestParameters: HttpParams): Observable<any> {
    const apiUrl = this.endpointUrl + path;

    return this.httpClient.post(apiUrl, body, { params: requestParameters })
      .pipe(map((response: any) => response));
  }

  public getBlobDataWithBody(path: string, body: any, requestParameters: HttpParams): Observable<any> {
    const apiUrl = this.endpointUrl + path;

    return this.httpClient.post(apiUrl, body,
      {
        params: requestParameters,
        responseType: 'blob'
      }).pipe(map((response: any) => response));
  }

  public postServiceFormData(path: string, body: string, requestParameters: HttpParams): Observable<any> {
    const apiUrl = this.endpointUrl + path;

    return this.httpClient.post(apiUrl, body, { params: requestParameters })
      .pipe(map((response: any) => response));
  }

  public updateService(path: string, body: any, requestParameters: HttpParams): Observable<any> {
    const apiUrl = this.endpointUrl + path;

    return this.httpClient.put(apiUrl, body, { params: requestParameters })
      .pipe(map((response: any) => response));
  }

  public patchService(path: string, body: string, requestParameters: HttpParams): Observable<any> {
    const apiUrl = this.endpointUrl + path;

    return this.httpClient.patch(apiUrl, body, { params: requestParameters })
      .pipe(map((response: any) => response));
  }

  public deleteServiceWithId(path: string, val: number, requestParameters: HttpParams): Observable<any> {
    const apiUrl = `${this.endpointUrl} ${path} / ${val}`;

    return this.httpClient.delete(apiUrl, { params: requestParameters })
      .pipe(map((response: any) => response));
  }
}
