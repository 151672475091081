import { Injectable } from '@angular/core';
import { ExportStatus } from 'src/app/common/reportingCollections';
import { DownloadExportsGrid, DownloadExportsGridMap } from 'src/app/interfaces/download-exports';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { UtilService } from 'src/app/services/util.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadExportsService {
  constructor(
    private utilService: UtilService,
    private translatePipe: TranslatePipe
  ) { }

  public mapDownloadExportsGridData(data: DownloadExportsGrid): Array<DownloadExportsGridMap> {
    return data.records.map((record) => ({
      id: record.id,
      displayName: this.utilService.addTableCellWrapper(record.displayName),
      status: this.translatePipe.transform(`status.${record.status}`),
      downloadFile: this.utilService.getTableCellWrapper(this.translatePipe.transform('actions.downloadFile'), '',
        this.mapExportGridStatus(record.status))
    }));
  }

  public mapExportGridStatus(status: string): string {
    if (status === ExportStatus[ExportStatus.Complete]) {
      return this.utilService.generateButton(this.translatePipe.transform('actions.downloadFile'), 'downloadExport',
        'download-export-grid-btn');
    }

    return '';
  }
}
