<app-workflow-container
  [header]="'submissionLanding.headerTitle' | translate"
  hasFooter="true"
>
  <close-button>
    <button class="wcg-btn wcg-btn-icon" (click)="this.submissionWorkflowService.closeWorkflow()">
      <wcg-icon src="../../assets/icons/navigation/ic_close_24px.svg"></wcg-icon>
      <span class="wcg-visually-hidden">{{ 'submissionWorkflow.close' | translate }}</span>
    </button>
  </close-button>

  <main-content>
    <a routerLink=".." [queryParams]="{referralPage: 'review'}" class="wcg-link cnxs-arrow-btn cnxs-prev">
      <wcg-icon class="cnxs-arrow cnxs-back-arrow" src="../assets/icons/navigation/ic_arrow_back_24px.svg"></wcg-icon>
      {{ 'submissionWorkflow.return' | translate }}
    </a>

    <div class="cnxs-review-section cnxs-review-section-intro">
      <h2 class="wcg-h5">{{ 'submissionWorkflow.reviewHeading' | translate }}</h2>

      <p class="wcg-h2">{{ 'submissionWorkflow.reviewDescription' | translate }}</p>

      <p>{{ 'submissionWorkflow.reviewDescription2' | translate }}</p>
    </div>

    <div class="cnxs-review-section cnxs-checklist-section">
      <h3 class="wcg-h2">{{ 'submissionWorkflow.checklist' | translate }}</h3>

      <div class="cnxs-checklist-help" *ngIf="formIncomplete">
        <app-submission-help icon="../assets/icons/ic_error_24px.svg">
          <p class="cnxs-submission-help-text">{{ 'submissionWorkflow.checklistHelp' | translate }}</p>
        </app-submission-help>
      </div>

      <ul class="cnxs-submission-checklist cnxs-nav-visible">
        <app-form-nav-item *ngFor="let navItem of submissionWorkflowService.formPages | async; let idx = index" [visible]="navItem.visible">
          <a [routerLink]="['..']" [queryParams]="{
            referralPage: 'review',
            formStep: navItem.step
          }" class="cnxs-submission-step-link">
            <span class="cnxs-submission-step-details wcg-h4">{{navItem.title}}</span>

            <span class="cnxs-submission-status cnxs-status-{{navItem.statusClass}}">
              <wcg-icon
                class="cnxs-status-check"
                src="../assets/icons/check.svg"></wcg-icon>
              <span class="wcg-visually-hidden">{{ navItem.status | translate }}</span>
            </span>
          </a>
        </app-form-nav-item>

        <app-form-nav-item visible="visible">
          <a routerLink="/submission-workflow/details/upload" class="cnxs-submission-step-link">
            <span class="cnxs-submission-step-details wcg-h4">{{ 'submissionWorkflow.submissionDocuments' | translate }}</span>

            <span class="cnxs-submission-status cnxs-status-{{ submissionWorkflowService.submissionDocumentStatus.value.statusClass }}">
              <wcg-icon
                class="cnxs-status-check"
                src="../assets/icons/check.svg"></wcg-icon>
              <span class="wcg-visually-hidden">{{ submissionWorkflowService.submissionDocumentStatus.value.status | translate }}</span>
            </span>
          </a>
        </app-form-nav-item>
      </ul>
    </div>

    <div class="cnxs-review-section formio-container">
      <div id="formIoAcknowledgements" class="formIoAcknowledgements"></div>
    </div>

    <div class="cnxs-submission-info">
      <h3 class="wcg-h2">{{ 'submissionWorkflow.mySubmission' | translate }}</h3>
      <p class="wcg-h3">{{ submissionHeading }}</p>

      <div class="wcg-row">
        <div class="wcg-col-xs-4 wcg-col-md-4 wcg-col-lg-5 wcg-col-xl-4">
          <app-submission-info [submission]="submission" [card]="true">
            <div meta>
              <p class="cnxs-submission-meta-content">
                <span class="wcg-body-label cnxs-submission-meta-title">{{ 'submissionMeta.studyName' | translate }}:</span>
                <span class="wcg-body-sm cnxs-submission-meta-value">{{ protocolInfo?.studyName || submissionWorkflowService.submissionFindStudy.value.name }}</span>
              </p>

              <p class="cnxs-submission-meta-content" *ngIf="protocolInfo?.sponsor">
                <span class="wcg-body-label cnxs-submission-meta-title">{{ 'submissionMeta.sponsor' | translate }}:</span>
                <span class="wcg-body-sm cnxs-submission-meta-value">{{ protocolInfo?.sponsor || submissionWorkflowService.submissionFindStudy.value.sponsor }}</span>
              </p>

              <p class="cnxs-submission-meta-content" *ngIf="submissionWorkflowService.submissionStudySites.value?.length">
                <span class="wcg-body-label cnxs-submission-meta-title">{{'submissionMeta.principalInvestigator' | translate}}:</span>
                <span class="wcg-body-sm cnxs-submission-meta-pi" title="{{submissionWorkflowService.investigators.value}}">
                  <span *ngIf="submissionWorkflowService.submissionStudySites.value?.length === 1">
                    {{ submissionWorkflowService.submissionStudySites.value[0]?.investigator }}
                  </span>
                  <span class="cnxs-pi-wrap" *ngIf="submissionWorkflowService.submissionStudySites.value?.length > 1">
                    {{ 'submissionMeta.sites' | translate: { length: submissionWorkflowService.submissionStudySites.value?.length} }}
                    <button class="wcg-link" (click)="submissionWorkflowService.showPiModal.next(true)">
                      <wcg-icon class="cnxs-view-all-icon" src="../../assets/icons/action/ic_launch_24px.svg"></wcg-icon>
                      {{ 'submissionMeta.viewAll' | translate }}
                    </button>
                  </span>
                </span>
              </p>

              <p class="cnxs-submission-meta-content" *ngIf="investigator && !submissionWorkflowService.submissionStudySites.value?.length">
                <span class="wcg-body-label cnxs-submission-meta-title">{{'submissionMeta.principalInvestigator' | translate}}:</span>
                <span class="wcg-body-sm">
                  {{investigator}}
                </span>
              </p>
            </div>

            <button actions
              class="wcg-btn wcg-btn-fluid wcg-btn-primary"
              [disabled]="submissionWorkflowService.consolidatedPdfDisabled"
              (click)="formioPDFService.downloadConsolidatedPdf()">
              {{ 'submissionActions.exportDraft' | translate }}
              <wcg-loader [show]="submissionWorkflowService.consolidatedPdfDisabled"></wcg-loader>
            </button>
          </app-submission-info>
        </div>

        <div class="wcg-col-xs-4 wcg-col-md-4 wcg-col-lg-5 wcg-col-xl-4">
          <app-estimated-outcome-card
            [estimatedOutcomeDate]="this.submissionWorkflowService.submissionFlow.value === 'addStudy' ? estimatedOutcomeDate : ''">
          </app-estimated-outcome-card>
        </div>
      </div>
    </div>

    <div class="cnxs-hidden-form wcg-visually-hidden">
      <formio
        [src]="formSRCPath"
        (ready)="setFormIOForm($event)"></formio>
    </div>
  </main-content>

  <footer-content>
    <div class="cnxs-form-footer-content">
      <a routerLink="/submission-workflow/details" [queryParams]="{referralPage: 'review'}" class="wcg-link cnxs-arrow-btn cnxs-prev">
        <wcg-icon class="cnxs-arrow cnxs-back-arrow" src="../assets/icons/navigation/ic_arrow_back_24px.svg"></wcg-icon>
        {{ 'submissionWorkflow.return' | translate }}
      </a>

      <button class="wcg-btn wcg-btn-primary wcg-btn-large" (click)="toggleSubmitModal()">
        {{ 'submissionWorkflow.submit' | translate }}
      </button>
    </div>
  </footer-content>
</app-workflow-container>

<wcg-modal class="confirmation-modal" [show]="showConfirmation" (close)="toggleSubmitModal()">
  <wcg-loader [show]="showSaveLoader"></wcg-loader>
  <wcg-modal-header>
    <h2 class="wcg-h3">{{ 'submissionConfirmationModal.header' | translate }}</h2>
  </wcg-modal-header>

  <wcg-modal-body>
    <wcg-toast variant="error" position="top" *ngIf="saveSubmissionFailure" closeable="true" (close)="saveSubmissionFailure = false">{{ saveSubmissionFailureMessage }}</wcg-toast>
    <div class="cnxs-modal-body">
      <p class="cnxs-modal-text" *ngIf="formIncomplete">{{ 'submissionConfirmationModal.incomplete' | translate }}</p>
      <p class="cnxs-modal-text">{{ 'submissionConfirmationModal.confirm' | translate }}</p>
    </div>
  </wcg-modal-body>

  <wcg-modal-footer>
    <div class="cnxs-secondary-actions">
      <a routerLink="/submission-workflow/details" [queryParams]="{referralPage: 'review'}" class="wcg-btn wcg-btn-auto">{{ 'submissionWorkflow.return' | translate }}</a>

      <button class="wcg-btn wcg-btn-auto" type="button" (click)="this.submissionWorkflowService.saveDraft()">{{ 'submissionActions.saveExit' | translate }}</button>
    </div>

    <button class="wcg-btn wcg-btn-primary wcg-btn-auto" type="button" (click)="confirmSubmission()">{{ 'submissionWorkflow.submit' | translate }}</button>
  </wcg-modal-footer>
</wcg-modal>
