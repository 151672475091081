import { Injectable } from '@angular/core';
import { KnownFileGenerationStatus } from 'src/app/common/collections';
import { DownloadDocuments, DownloadDocumentsMap } from 'src/app/interfaces/download-documents';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { UtilService } from 'src/app/services/util.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadDocumentService {
  constructor(private utilService: UtilService,
    private translatePipe: TranslatePipe) { }

  public mapDownloadExportsGridData(data: Array<DownloadDocuments>): Array<DownloadDocumentsMap> {
    return data.map((record) => ({
      id: record.id,
      fileName: this.utilService.addTableCellWrapper(record.fileName),
      status: this.mapDownloadDocumentGridStatus(record.status),
      requestedDateTime: this.utilService.addTableCellWrapper(this.utilService.formatDate(record.requestedDateTime)),
      details: this.mapGridDetails(record.status),
      downloadFile: this.utilService.getTableCellWrapper(this.translatePipe.transform('actions.downloadFile'), '',
        this.mapExportGridButton(record.status))
    }));
  }

  public mapExportGridButton(status: any): string {
    if (status === KnownFileGenerationStatus[KnownFileGenerationStatus.Complete] ||
        status === KnownFileGenerationStatus[KnownFileGenerationStatus.Incomplete]) {
      return this.utilService.generateButton(this.translatePipe.transform('actions.downloadFile'), 'downloadDocument',
        'download-document-grid-btn');
    }

    return '';
  }

  public mapDownloadDocumentGridStatus(status: any): string {
    if (status === KnownFileGenerationStatus[KnownFileGenerationStatus.Failed]) {
      return `<div class="iconText"><wcg-icon class="failedIcon "src="../assets/icons/alert/ic_error_24px.svg"></wcg-icon> 
      <div class='statusText failedtext'>${this.utilService.addTableCellWrapper(
    this.translatePipe.transform(`status.${status}`)
  )}</div></div>`;
    }
    else if (status === KnownFileGenerationStatus[KnownFileGenerationStatus.Incomplete]) {
      return `<div class="iconText"><wcg-icon class="incompleteIcon" src="../assets/icons/alert/ic_warning_24px.svg"></wcg-icon> 
      <div class='statusText'>${this.utilService.addTableCellWrapper(
    this.translatePipe.transform(`status.${status}`)
  )}</div></div>`;
    }

    return this.utilService.addTableCellWrapper(this.translatePipe.transform(`status.${status}`));
  }

  public mapGridDetails(status: any): string {
    if (status === KnownFileGenerationStatus[KnownFileGenerationStatus.Failed]) {
      return `<div class="failedtext">${this.utilService.addTableCellWrapper(
        this.translatePipe.transform('documentDownload.allFilesFailedToDownload')
      )}</div>`;
    }
    else if (status === KnownFileGenerationStatus[KnownFileGenerationStatus.Incomplete]) {
      return this.utilService.addTableCellWrapper(
        this.translatePipe.transform('documentDownload.incompleteDocumentDownloads')
      );
    }

    return '';
  }
}
