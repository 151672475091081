<ul class="cnxs-bar-chart-list">
  <li *ngFor="let item of data; let i = index;" class="cnxs-bar-chart-list-item">
    <div class="cnxs-bar-chart-list-item-inner">
      <div class="cnxs-bar-chart-inner cnxs-bar-wrap">
        <button class="cnxs-bar" (click)="handleClick(item, i)" [ngStyle]="{
          height: percents[i] + '%',
          backgroundColor: item.color
        }">

        <div *ngIf="item.icon" class="cnxs-bar-icon-wrap" [ngStyle]="{
          backgroundColor: item.iconBgColor
        }">
          <wcg-icon [src]="item.icon" [ngStyle]="{
            color: item.iconColor,
            height: item.iconHeight,
            width: item.iconWidth
          }"></wcg-icon>
        </div>
      </button>
      </div>

      <div class="cnxs-bar-chart-label-wrap">
        <div class="cnxs-bar-chart-label" [ngClass]="{
          'wcg-bar-active': active === item.id
        }">
          <button class="cnxs-bar-label-btn" (click)="handleClick(item, i)">
            {{item.title}}<span *ngIf="item.value || item.value === 0">:&nbsp;</span>
            {{item.value}}
          </button>
        </div>
      </div>
    </div>
  </li>
</ul>
