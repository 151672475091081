<div class="wcg-row">
    <div class="wcg-col-xs-4">
        <mat-label class="label-before-slide">Maintenance mode</mat-label>
        <mat-slide-toggle (click)="showModalMaintenanceMode($event)" [ngModel]="this.isApplicationInMaintenanceMode">
            {{this.isApplicationInMaintenanceMode? 'On' : 'Off'}}</mat-slide-toggle>
    </div>
</div>

<wcg-modal id="modal" [show]="showMaintenanceModal" (close)="closeClick()">
    <wcg-modal-header>Confirm Switch Maintenance Mode</wcg-modal-header>
    <wcg-modal-body>
         <div class="wcg-row">
            <div class="wcg-col-sm-4">
                <h1>Do you want to turn {{!this.isApplicationInMaintenanceMode? 'on' : 'off'}} maintenance mode?</h1>
                <p>This will display the maintenance page for all non-admin users until it is turned off.</p>
            </div>
        </div>
    </wcg-modal-body>

    <wcg-modal-footer>
        <button class="wcg-btn wcg-btn-auto" (click)="closeClick()">Cancel</button>
        <button class="wcg-btn wcg-btn-auto wcg-btn-primary" (click)="showMaintenanceModal = false"  (click)="switchMaintenanceMode()"
            class="wcg-btn wcg-btn-primary">Switch</button>
    </wcg-modal-footer>
</wcg-modal>