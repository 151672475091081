<app-workflow-container
  [isVisible]="showOverlay"
  [hasNav]="hasNav"
  [header]="'submissionWorkflow.heading' | translate"
  hasFooter="true"
>
<close-button>
  <button class="wcg-btn wcg-btn-icon" (click)="submissionWorkflowService.closeWorkflow()">
    <wcg-icon src="../../assets/icons/navigation/ic_close_24px.svg"></wcg-icon>
    <span class="wcg-visually-hidden">{{ 'submissionWorkflow.close' | translate }}</span>
  </button>
</close-button>

<main-content>
  <div class="wcg-row">
    <div class="wcg-col-xs-4 wcg-col-lg-8" *ngIf="!submissionWorkflowService.siteRequired.value">
      <p class="wcg-h5 setup">{{ 'submissionWorkflow.setup' | translate }}</p>
      <h2 class="wcg-h2 header">{{ 'submissionWorkflow.addSitesHeader' | translate }}</h2>
      <p class="update-details">{{ 'sites.addSitesDetails' | translate }}</p>
      <hr />
      <p class="radio-header">{{ 'submissionWorkflow.addSitesHeaderQuestion' | translate }}</p>
    </div>

    <div class="wcg-col-xs-4 wcg-col-lg-8" 
      *ngIf="submissionWorkflowService.siteRequired.value && !submissionWorkflowService.siteSingleSelect.value">
      <p class="wcg-h5 setup">{{ 'submissionWorkflow.setup' | translate }}</p>
      <h2 class="wcg-h2 header">{{ 'sites.whichSitesRequired' | translate }}</h2>
      <p class="update-details">{{ 'sites.whichSitesDetails' | translate }}</p>
    </div>

    <div class="wcg-col-xs-4 wcg-col-lg-8" 
      *ngIf="submissionWorkflowService.siteRequired.value && submissionWorkflowService.siteSingleSelect.value">
      <p class="wcg-h5 setup">{{ 'submissionWorkflow.setup' | translate }}</p>
      <h2 class="wcg-h2 header">{{ 'sites.siteRequired' | translate }}</h2>
      <p class="update-details">{{ 'sites.siteRequiredDetails' | translate }}</p>
    </div>

    <form
      class="wcg-col-xs-4 wcg-col-lg-12"
      [formGroup]="submissionChooseSitesForm"
      *ngIf="!submissionWorkflowService.siteRequired.value">
      <fieldset class="wcg-row cnxs-fieldset radio-form" formGroupName="updateSitesGroup">
        <wcg-radio class="radio wcg-col-xs-4 wcg-col-lg-12" name="updateSites" value="all" formControlName="chooseSites">{{ 'sites.updateAll' | translate }}</wcg-radio>
        <wcg-radio class="radio wcg-col-xs-4 wcg-col-lg-12" name="updateSites" value="selected" formControlName="chooseSites">{{ 'sites.updateWithSelection' | translate }}</wcg-radio>
        <wcg-radio class="radio wcg-col-xs-4 wcg-col-lg-12" name="updateSites" value="studyOnly" formControlName="chooseSites">{{ 'sites.updateStudyOnly' | translate }}</wcg-radio>
      </fieldset>
    </form>

    <div *ngIf="(updatingSites || submissionWorkflowService.siteRequired.value) && !updatingAllSites" class="wcg-row cnxs-site-selection">
      <h4 class="wcg-col-xs-4 wcg-col-lg-8 wcg-h4" *ngIf="!submissionWorkflowService.siteRequired.value">{{ 'sites.whichSites' | translate }}</h4>
      <p class="wcg-col-xs-4 wcg-col-lg-8" *ngIf="!submissionWorkflowService.siteRequired.value">{{ 'sites.whichSitesDetails' | translate }}</p>

      <form ngForm class="wcg-col-xs-4 wcg-col-lg-8">
        <wcg-form-field>
          <wcg-label>{{ 'sites.findSite' | translate }}</wcg-label>
          <wcg-input [formControl]="searchTerm" [value]="searchTerm.value"
            (input)="search($event, searchTerm.value)">
            <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
          </wcg-input>
        </wcg-form-field>
        <p class="search-description wcg-body-label wcg-col-xs-4 wcg-col-lg-8">{{ 'submissionWorkflow.searchSite' | translate }}</p>
      </form>

      <app-submission-help icon="../assets/icons/lightbulb.svg" class="wcg-col-xs-4 wcg-col-lg-4 tooltip-box">
        <div>
          <p class="wcg-h5 submission-help-text wcg-rhythm-1 wcg-col-xs-4">{{ 'submissionWorkflow.requestAccessTipSites' | translate }}</p>
          <button class="wcg-link"
            (click)="stateService.showRequestAccessModal.next(true)">{{ 'requestAccess.title' | translate }}
          </button>
        </div>
      </app-submission-help>

      <div class="wcg-col-xs-4">
        <wcg-table
          *ngIf="!submissionWorkflowService.siteSingleSelect.value"
          [headers]="headers"
          [data]="filteredDocuments"
          [options]="options"
          (rowSelected)="handleRowSelection($event)"
          (rowUnSelected)="removeSelectedSite($event)">
        </wcg-table>

        <wcg-table
          *ngIf="submissionWorkflowService.siteSingleSelect.value"
          [headers]="headers"
          [data]="filteredDocuments"
          [options]="optionsSingleSite"
          (change)="onSiteRadioChange($event)"
          class="cnxs-single-select-site">
        </wcg-table>
      </div>
    </div>
  </div>
</main-content>

<footer-content>
  <div class="cnxs-form-footer-content">
    <a class="wcg-link cnxs-arrow-btn cnxs-prev" (click)="backClick()">
      <wcg-icon class="cnxs-arrow cnxs-back-arrow" src="../assets/icons/navigation/ic_arrow_back_24px.svg"></wcg-icon>
      {{ 'submissionWorkflow.back' | translate }}
    </a>

    <button type="submit" class="wcg-link cnxs-arrow-btn cnxs-next" [disabled]="!continue" (click)="continueClick()">
      {{ 'submissionWorkflow.continueSetup' | translate }}
      <wcg-icon class="cnxs-arrow cnxs-next-arrow" src="../assets/icons/navigation/ic_arrow_forward_24px.svg"></wcg-icon>
    </button>
  </div>
</footer-content>
</app-workflow-container>

<app-request-access-modal
  [show]="stateService.showRequestAccessModal.value"
></app-request-access-modal>
