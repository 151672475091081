<div class="wcg-container wcg-center-xs">
  <wcg-icon src="./assets/icons/cnx-unavailable.svg"></wcg-icon>
  <h1>WCG IRB Connexus is not <br>available at this time.</h1>
  <!--<p>Until WCG IRB Connexus is restored, please<br>
    <a class="wcg-link" href="https://connexus.wcgclinical.com"> use the legacy MyConnexus portal.</a>
  </p>-->
  <div class="wcg-container wcg-center-xs">
    <p>Need immediate support? Please contact us at:
      <br><a class="wcg-link" href="mailto:{{ supportEmail }}">{{ supportEmail }}</a> <br>
            855-818-2289</p>
  </div>
</div>
