/* eslint-disable max-lines, complexity*/
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { timer } from 'rxjs';
import { debounce, takeUntil } from 'rxjs/operators';
import {
  CITY_LENGTH,
  DEBOUNCE_TIME,
  emailRegex,
  EMAIL_LENGTH,
  MAX_LENGTH,
  COMPANYID_MAX_LIMIT,
  PermissionType,
  POSTAL_LENGTH,
  PROVINCE_LENGTH,
  regexRules,
  RegexTags
} from 'src/app/common/collections';
import { BaseComponent } from 'src/app/components/base/base.component';
import { Address } from 'src/app/interfaces/common';
import { ContactCountries, ContactStates } from 'src/app/interfaces/user';
import { IdValueModel, TeamDetailModel, TeamOrganizationEditModel, TeamOrganizationPatchModel } from 'src/app/models/teams.model';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { UtilService } from 'src/app/services/util.service';
import { numericValidator } from 'src/app/validators/numeric.validator';
import { TeamService } from './team.service';
const timeOut = 2000;
@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent extends BaseComponent implements OnInit {
  teamDetails: TeamDetailModel;
  primaryAddress: Address;

  showEditTeamModal = false;
  showConfirmUpdateCompanyModal = false;
  editTeamForm: FormGroup;
  primaryUserForm: FormGroup;
  teamOrganizationTypes: IdValueModel[] = [];
  countries: ContactCountries = [];
  states: ContactStates = [];
  stateLabel: string;
  allUsers: any = [];
  editTeamsFailure = false;
  editTeamsFailureMessage: string;
  loaded = false;
  loading = true;
  usStates: ContactStates = [];
  canadianProvince: ContactStates = [];
  usCountryId = -1;
  caCountryId = -1;
  additionalAddressesModel: Address[] = [];
  additionalStates: ContactStates[] = [];
  additionalStateLevels: string[] = [];
  additionalAddressForView: Address[] = [];
  showAdditionAddresses = false;
  teamId: string;
  isSaving = false;
  showDeactivateModal = false;
  showActivateConfirmModal = false;
  showPrimaryUserModal = false;
  isAdmin = false;
  isPrimaryUser = false;
  hasManagePermission = false;
  openOutcome: boolean;
  updatingStatus = false;
  canEditTeamEmail = false;

  constructor(public teamService: TeamService,
    private userService: UserService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
    public utilService1: UtilService,
    private translatePipe: TranslatePipe,
    private apiService: ApiService,
    private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.initializeForm();
    this.populateForm();
    this.route.params.subscribe((params) => {
      this.teamId = params.id;
      this.getTeamDetails(params.id);
      this.getGridCount(params.id);
    });

    this.apiService.getTeamOrganizationTypes().subscribe((result) => {
      this.teamOrganizationTypes = result;
    });

    this.userService.userInfo.subscribe((user) => {
      if (user) {
        this.teamService.isAdmin.next(user.canManageTeams && user.canViewTeams);
        this.isAdmin = this.teamService.isAdmin.value;
        this.canEditTeamEmail = user.canManageTeams;
      }
    });

    this.teamService.reloadTeamDetails.subscribe((val) => {
      if (val) {
        this.getTeamDetails(this.teamId);
        this.teamService.reloadTeamDetails.next(false);
      }
    });
  }

  public getTeamDetails(id: string): void {
    this.apiService.getTeamInfoByTeamId(id).subscribe((result) => {
      this.teamDetails = result;
      this.primaryAddress = this.teamDetails.teamAddresses.find((add) => add.isPrimary === true);
      this.additionalAddressForView = this.teamDetails.teamAddresses.filter((add) => add.isPrimary === false);
      this.isPrimaryUser = this.teamDetails.isPrimaryUser;
      this.hasManagePermission = this.teamDetails.userPermissionType === PermissionType.Manage;

      this.teamService.hasManagePermission.next(this.hasManagePermission);
      this.teamService.isTeamActive.next(this.teamDetails.isActive);
      this.prepareEditTeamData();
    }, (err) => {
      this.toastService.add([{
        closable: true,
        id: 'fetchTeamDetails',
        message: `Team Details could not be fetched. ${err}`,
        timeout: 5000,
        variant: 'error'
      }]);
    });
  }

  editTeam(): void {
    this.initializeForm();
    this.closeErrorMessage();
    this.resetForm();
    this.prepareEditTeamData();
    this.showEditTeamModal = true;
    this.showConfirmUpdateCompanyModal = false;
    this.editTeamForm.controls.selectedUsers.valueChanges.pipe(
      debounce(() => timer(DEBOUNCE_TIME))
    ).subscribe((val) => {
      if (val?.value?.length > 2) {
        // email column length in db is 450, trim if above
        const searchValue = val.value.substring(0, EMAIL_LENGTH);
        this.getUsers(searchValue);
      }
    });
    this.setValues();
  }

  get editTeamFormGroup(): any {
    return this.editTeamForm.controls;
  }

  get additionalAddresses(): FormArray {
    return this.editTeamFormGroup.additionalAddresses as FormArray;
  }

  getAdditionAddressfromgroup(count): any {
    const temp = [];
    const rules = regexRules.filter((rule) => rule.tag === RegexTags.AlphaNumericRegex);
    const [regexRule] = rules;
    for (let index = 0; index < count; index++) {
      temp.push(this.formBuilder.group({
        province: [null, [this.addValidators(PROVINCE_LENGTH, regexRule.rule, true)]],
        addressLine1: [null, [this.addValidators(MAX_LENGTH, regexRule.rule, true)]],
        addressLine2: [null, [this.addValidators(MAX_LENGTH, regexRule.rule, false)]],
        city: [null, [this.addValidators(CITY_LENGTH, regexRule.rule, true)]],
        postalCode: [null, [this.addValidators(POSTAL_LENGTH, regexRule.rule, true)]],
        country: [null, [Validators.min(0)]],
        state: [null, [Validators.required]]
      }));
    }

    return temp;
  }

  addAnotherAddress(event): void {
    event.preventDefault();
    const temp = this.getAdditionAddressfromgroup(1);
    this.additionalStateLevels.push('');
    this.additionalAddressesModel.push();
    this.additionalStates.push([]);
    this.additionalAddresses.push(temp[0]);

    const lastIndex = this.additionalAddresses.length - 1;
    const frg = this.additionalAddresses.controls[lastIndex] as FormGroup;
    setTimeout(() => {
      frg.controls.country.patchValue(-1);
      frg.controls.state.patchValue(' ');
    }, DEBOUNCE_TIME);
  }

  deleteAdditionalAddress(event, index): void {
    this.additionalAddresses.removeAt(index);
    this.additionalAddressesModel.splice(index, 1);
    this.additionalStates.splice(index, 1);
    this.additionalStateLevels.splice(index, 1);
    event.preventDefault();
  }

  public setValues(): void {
    this.editTeamForm.controls.teamName.setValue(this.teamDetails.name);
    this.editTeamForm.controls.companyId.setValue(this.teamDetails.companyId);
    this.editTeamForm.controls.teamEmail.setValue(this.teamDetails.email);
    this.editTeamForm.controls.teamType.setValue(this.teamDetails.organizationType.id);
    this.editTeamForm.controls.province.setValue(this.primaryAddress.province);
    this.editTeamForm.controls.country.setValue(this.primaryAddress.country.id);
    this.updateStates(this.primaryAddress.country.id, false);

    this.editTeamForm.controls.addressLine1.setValue(this.primaryAddress.addressLine1);
    this.editTeamForm.controls.addressLine2.setValue(this.primaryAddress.addressLine2);
    this.editTeamForm.controls.city.setValue(this.primaryAddress.city);
    this.editTeamForm.controls.state.patchValue(this.primaryAddress.state?.id);
    this.editTeamForm.controls.postalCode.setValue(this.primaryAddress.postalCode);

    const extraaddress = this.getAdditionAddressfromgroup(this.additionalAddressesModel.length);
    for (let index = 0; index < extraaddress.length; index++) {
      this.additionalAddresses.push(extraaddress[index]);
      const frg = this.additionalAddresses.controls[index] as FormGroup;
      frg.controls.province.setValue(this.additionalAddressesModel[index].province);
      frg.controls.country.setValue(this.additionalAddressesModel[index].country.id);

      this.updateStatesForAdditionAddress(this.additionalAddressesModel[index].country.id, index, false);
      frg.controls.addressLine1.setValue(this.additionalAddressesModel[index].addressLine1);
      frg.controls.addressLine2.setValue(this.additionalAddressesModel[index].addressLine2);
      frg.controls.city.setValue(this.additionalAddressesModel[index].city);
      frg.controls.state.patchValue(this.additionalAddressesModel[index].state?.id);
      frg.controls.postalCode.setValue(this.additionalAddressesModel[index].postalCode);
    }

    if (this.teamDetails.primaryUser === null) {
      this.editTeamForm.controls.selectedUsers.setValue({
        value: '',
        selectedValues: []
      });
    }
    else {
      const primaryUserText = `${this.teamDetails.primaryUser.firstName} 
       ${this.teamDetails.primaryUser.lastName} ( ${this.teamDetails.primaryUser.email} )`;

      this.editTeamForm.controls.selectedUsers.setValue({
        value: '',
        selectedValues: [new Option(this.teamDetails.primaryUser.email, primaryUserText)]
      });
    }
  }

  public initializeForm(): void {
    const rules = regexRules.filter((rule) => rule.tag === RegexTags.AlphaNumericRegex);
    const [alphaNumericRegexRule] = rules;

    this.primaryUserForm = this.formBuilder.group({
      primaryUser: new FormControl({
        value: '',
        selectedValues: []
      }, [
        (control): any => {
          if (control.value?.selectedValues?.length > 0) {
            return;
          }

          return {
            validateSelectedUser: {
              valid: false
            }
          };
        }
      ])
    });

    this.editTeamForm = this.formBuilder.group({
      teamName: new FormControl(null, this.addValidators(MAX_LENGTH, alphaNumericRegexRule.rule, true)),
      companyId: new FormControl(null, [numericValidator(COMPANYID_MAX_LIMIT)]),
      teamEmail: new FormControl(null, this.addValidators(MAX_LENGTH, emailRegex, true)),
      teamType: new FormControl(null, Validators.min(0)),
      country: new FormControl(null, Validators.min(0)),
      state: ['', Validators.required],

      province: new FormControl(null, this.addValidators(PROVINCE_LENGTH, alphaNumericRegexRule.rule, true)),
      addressLine1: new FormControl(null, this.addValidators(MAX_LENGTH, alphaNumericRegexRule.rule, true)),
      addressLine2: new FormControl(null, this.addValidators(MAX_LENGTH, alphaNumericRegexRule.rule, false)),
      city: new FormControl(null, this.addValidators(CITY_LENGTH, alphaNumericRegexRule.rule, true)),
      postalCode: new FormControl(null, this.addValidators(POSTAL_LENGTH, alphaNumericRegexRule.rule, true)),
      additionalAddresses: new FormArray([]),
      selectedUsers: new FormControl({
        value: '',
        selectedValues: []
      }, [
        (control): any => {
          if (control.value?.selectedValues?.length > 0) {
            return;
          }

          return {
            validateSelectedUser: {
              valid: false
            }
          };
        }
      ])
    });
  }

  public closeClick(): void {
    this.closeErrorMessage();
    this.resetForm();
    this.editTeamForm.controls.selectedUsers = new FormControl();
    this.editTeamForm.controls.additionalAddresses = new FormArray([]);
    this.states = [];
    const form = document.getElementsByName('editTeamForm')[0] as HTMLFormElement;
    form.reset();
    this.showEditTeamModal = false;
  }

  public closeErrorMessage(): void {
    this.editTeamsFailure = false;
    this.editTeamsFailureMessage = '';
  }

  public updateTeamCheckCompanyid(event): void {
    if (
      this.isAdmin &&
      this.teamDetails.companyId > 0 &&
      this.teamDetails.companyId.toString() !== this.editTeamForm.controls.companyId.value.toString()
    ) {
      this.showConfirmUpdateCompanyModal = true;
    }
    else {
      this.showConfirmUpdateCompanyModal = false;
    }

    if (this.showConfirmUpdateCompanyModal === false) {
      this.updateTeam();
    }
    else {
      event.preventDefault();
    }
  }

  public cancelTeamUpdate(event = null): void {
    if (event) {
      event.stopPropagation();
    }
    this.showConfirmUpdateCompanyModal = false;
  }

  public updateTeam(): void {
    this.closeErrorMessage();
    this.showConfirmUpdateCompanyModal = false;
    if (this.editTeamForm.valid) {
      this.isSaving = true;
      const email = this.editTeamForm.controls.teamEmail.value;
      this.apiService.IsValidTeamOrganizationEmail(email.trim(), this.teamId).subscribe((res) => {
        if (res === true) {
          const orgModel = this.mapTeamOrganizationEditModel();
          this.apiService.updateTeam(this.teamId, orgModel).subscribe(() => {
            this.isSaving = false;
            this.getTeamDetails(this.teamId);
            this.closeClick();
            this.teamService.reloadTeamUserGrid.next(true);
            this.toastService.add([{
              closable: true,
              id: 'updateTeam',
              message: `${this.translatePipe.transform('teams.teamUpdatedSuccesfully')}`,
              timeout: 5000,
              variant: 'success'
            }]);
            this.updateGrids();
          }, (err) => {
            this.editTeamsFailureMessage = err.details.error.message;
            this.editTeamsFailure = true;
            this.isSaving = false;
            this.showConfirmUpdateCompanyModal = false;
          });
        }
        else {
          this.editTeamsFailureMessage = `${this.translatePipe.transform('teams.emailExists', { email: email })}`;
          this.editTeamsFailure = true;
          this.isSaving = false;
        }
      }, (err) => {
        this.editTeamsFailureMessage = err.details.error.message;
        this.editTeamsFailure = true;
        this.isSaving = false;
        this.showConfirmUpdateCompanyModal = false;
      });
    }
    else {
      this.isSaving = false;
      this.showConfirmUpdateCompanyModal = false;
    }
  }

  public populateForm(): void {
    this.apiService.getTeamOrganizationTypes().subscribe((result) => {
      this.teamOrganizationTypes = result;
    });

    this.apiService.getContactCountries().subscribe((res) => {
      this.countries = res;
      const usa = this.countries.find((country) => country.alpha2Code === 'US');
      const canada = this.countries.find((country) => country.alpha2Code === 'CA');
      this.usCountryId = usa.id;
      this.caCountryId = canada.id;
    });
  }

  public resetForm(): void {
    this.allUsers = [];
    this.editTeamForm.controls.selectedUsers.setValue({
      value: '',
      selectedValues: []
    });
    this.editTeamForm.controls.teamName.setValue(null);
    this.editTeamForm.controls.companyId.setValue(null);
    this.editTeamForm.controls.teamEmail.setValue(null);
    this.editTeamForm.controls.teamType.setValue(-1);
    this.editTeamForm.controls.country.setValue(-1);
    this.editTeamForm.controls.addressLine1.setValue(null);
    this.editTeamForm.controls.addressLine2.setValue(null);
    this.editTeamForm.controls.city.setValue(null);
    this.editTeamForm.controls.state.setValue('');
    this.editTeamForm.controls.postalCode.setValue(null);
    this.editTeamForm.controls.province.setValue(null);
  }

  public handleTeamTypeChange(event): void {
    this.editTeamForm.controls.teamType.setValue(event.target.value);
  }

  public handleCountryChange(event): void {
    this.editTeamForm.patchValue({ country: event.target.value });
    this.updateStates(parseInt(event.target.value, 10), true);
  }

  public handleCountryChangeForAdditionalAddress(event, index): void {
    const frg = this.additionalAddresses.controls[index] as FormGroup;
    frg.controls.country.patchValue(event.target.value);
    this.updateStatesForAdditionAddress(parseInt(event.target.value, 10), index, true);
  }

  public stateChange(event): void {
    if (this.editTeamForm.controls.state.value === 'Select') {
      this.editTeamForm.patchValue({ province: ' ' });
      this.editTeamForm.patchValue({ state: '' });
    }
    else {
      this.editTeamForm.patchValue({ state: event.target.value });
    }
  }

  public additionalStateChange(event, index): void {
    const frg = this.additionalAddresses.controls[index] as FormGroup;
    if (frg.controls.state.value === 'Select') {
      frg.patchValue({ province: ' ' });
      frg.patchValue({ state: '' });
    }
    else {
      frg.patchValue({ state: event.target.value });
    }
  }

  public updateStates(value: number, change?: boolean): void {
    if (this.countries && value > 0) {
      const usa = this.countries.find((country) => country.alpha2Code === 'US');
      const canada = this.countries.find((country) => country.alpha2Code === 'CA');
      const found = [usa.id, canada.id].find((country) => country === value);

      this.stateLabel = value === usa.id ? 'teams.teamState' : 'teams.teamProvince';
      this.states = [];
      if (found) {
        this.setStates(value);
        this.editTeamForm.controls.state.enable();
        // String with a N|A to pass required field validation
        this.editTeamForm.patchValue({ province: 'N|A' });
        const milliSeconds = this.states !== null && this.states.length > 0 ? DEBOUNCE_TIME : timeOut;
        if (change) {
          const editTeamFormToPatch = this.editTeamForm;
          setTimeout(() => {
            editTeamFormToPatch.patchValue({ state: '' });
          }, milliSeconds);
        }
      }
      else {
        this.editTeamForm.controls.state.disable();
        if (change) {
          this.updateProvinceValidation();
          this.editTeamForm.patchValue({ state: ' ' });
        }
        this.states = [];
      }
    }
  }

  public updateStatesForAdditionAddress(value: number, index: number, change?: boolean): void {
    if (this.countries && value > 0) {
      const usa = this.countries.find((country) => country.alpha2Code === 'US');
      const canada = this.countries.find((country) => country.alpha2Code === 'CA');
      const found = [usa.id, canada.id].find((country) => country === value);
      this.additionalStateLevels[index] = value === usa.id ? 'teams.teamState' : 'teams.teamProvince';
      this.additionalStates[index] = [];

      const frg = this.additionalAddresses.controls[index] as FormGroup;

      if (found) {
        frg.controls.state.enable();
        this.setAdditionalStates(value, index);
        frg.controls.province.patchValue('N|A');
        const milliSeconds = this.additionalStates[index] !== null && this.additionalStates[index].length > 0 ? DEBOUNCE_TIME : timeOut;

        if (change) {
          setTimeout(() => {
            frg.controls.state.patchValue('');
          }, milliSeconds);
        }
      }
      else {
        frg.controls.state.disable();
        if (change) {
          this.updateAdditionalProvinceValidation(index);
          frg.controls.state.patchValue(' ');
        }
        this.additionalStates[index] = [];
      }
    }
  }

  public setStates(countryId: number): void {
    if (this.usCountryId === countryId) {
      if (this.usStates.length === 0) {
        this.apiService.getContactStates(this.usCountryId.toString()).subscribe((res) => {
          this.usStates = res;
          this.states = JSON.parse(JSON.stringify(this.usStates)) as ContactStates;
        });
      }
      else {
        this.states = JSON.parse(JSON.stringify(this.usStates)) as ContactStates;
      }
    }

    if (this.caCountryId === countryId) {
      if (this.canadianProvince.length === 0) {
        this.apiService.getContactStates(this.caCountryId.toString()).subscribe((res) => {
          this.canadianProvince = res;
          this.states = JSON.parse(JSON.stringify(this.canadianProvince)) as ContactStates;
        });
      }
      else {
        this.states = JSON.parse(JSON.stringify(this.canadianProvince)) as ContactStates;
      }
    }
  }

  public setAdditionalStates(countryId: number, index): void {
    this.additionalStates[index] = null;
    if (this.usCountryId === countryId) {
      if (this.usStates.length === 0) {
        this.apiService.getContactStates(this.usCountryId.toString()).subscribe((res) => {
          this.usStates = res;
          this.additionalStates[index] = JSON.parse(JSON.stringify(this.usStates)) as ContactStates;
        });
      }
      else {
        this.additionalStates[index] = JSON.parse(JSON.stringify(this.usStates)) as ContactStates;
      }
    }

    if (this.caCountryId === countryId) {
      if (this.canadianProvince.length === 0) {
        this.apiService.getContactStates(this.caCountryId.toString()).subscribe((res) => {
          this.canadianProvince = res;
          this.additionalStates[index] = JSON.parse(JSON.stringify(this.canadianProvince)) as ContactStates;
        });
      }
      else {
        this.additionalStates[index] = JSON.parse(JSON.stringify(this.canadianProvince)) as ContactStates;
      }
    }
  }

  public updateUser(event): void {
    if (event.detail.option) {
      const orgType = this.allUsers.filter((org) => org.value === event.detail.value);
      if (orgType.length > 0) {
        this.editTeamForm.controls.selectedUsers.setValue({
          value: '',
          selectedValues: [new Option(orgType[0].value, orgType[0].option)]
        });
      }
    }
  }

  public updatePrimaryUser(event): void {
    if (event.detail.option) {
      const user = this.allUsers.filter((usr) => usr.value === event.detail.value);
      if (user.length > 0) {
        this.primaryUserForm.controls.primaryUser.setValue({
          value: '',
          selectedValues: [new Option(user[0].value, user[0].option)]
        });
      }
    }
  }

  public noWhitespaceValidator = (control: FormControl): any => {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;

    return isValid ? null : { whitespace: true };
  }

  isActiveStatusChange(event): void {
    const isActive = event.target.checked;
    event.target.checked = !event.target.checked;

    if (isActive) {
      this.showActivateConfirmModal = true;
    }
    else {
      this.showDeactivateModal = true;
    }
  }

  activateTeamConfirm(event): void {
    event.preventDefault();
    this.showActivateConfirmModal = false;
    this.setPrimaryUser();
  }

  updateCompanyConfirm(event): void {
    event.preventDefault();
    this.updateTeam();
    this.showConfirmUpdateCompanyModal = false;
  }

  setPrimaryUser(): void {
    this.showPrimaryUserModal = true;
    this.initializeForm();
    this.allUsers = [];
    this.primaryUserForm.controls.primaryUser.setValue({
      value: '',
      selectedValues: []
    });
    this.primaryUserForm.controls.primaryUser.valueChanges.pipe(
      debounce(() => timer(DEBOUNCE_TIME))
    ).subscribe((val) => {
      if (val?.value?.length > 2) {
        const searchValue = val.value.substring(0, EMAIL_LENGTH);
        this.getUsers(searchValue);
      }
    });
  }

  acivateTeam(event): void {
    event.preventDefault();
    const data = this.primaryUserForm.value;
    const email = data.primaryUser.selectedValues[0].innerText.trim();
    const postData = new TeamOrganizationPatchModel();
    postData.active = true;
    postData.primaryUserEmail = email;
    this.updatingStatus = true;
    this.apiService.setTeamOrganizationStatus(this.teamId, postData).subscribe(() => {
      this.showPrimaryUserModal = false;
      this.updatingStatus = false;
      this.toastService.add([{
        closable: true,
        id: 'activateTeam',
        message: 'Team activated.',
        timeout: 5000,
        variant: 'success'
      }]);
      this.updateGrids();
      this.getTeamDetails(this.teamId);
    }, (err) => {
      this.showPrimaryUserModal = false;
      this.updatingStatus = false;
      this.toastService.add([{
        closable: true,
        id: 'activateTeam',
        message: `Team could not be activate. ${err?.error?.message}`,
        timeout: 5000,
        variant: 'error'
      }]);
    });
  }

  deactivateTeam(event): void {
    event.preventDefault();
    const data = new TeamOrganizationPatchModel();
    data.active = false;
    this.updatingStatus = true;
    this.apiService.setTeamOrganizationStatus(this.teamId, data).subscribe(() => {
      this.showDeactivateModal = false;
      this.updatingStatus = false;
      this.updateGrids();
      this.toastService.add([{
        closable: true,
        id: 'deactivateTeam',
        message: 'Team deactivated.',
        timeout: 5000,
        variant: 'success'
      }]);
      this.getTeamDetails(this.teamId);
    }, (err) => {
      this.showDeactivateModal = false;
      this.updatingStatus = false;
      this.toastService.add([{
        closable: true,
        id: 'deactivateTeam',
        message: `Team could not be deactivate. ${err?.error?.message}`,
        timeout: 5000,
        variant: 'error'
      }]);
    });
  }

  private getUsers(filter): void {
    this.apiService.getPrimaryUsers(filter, this.teamId, false, this.isAdmin)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.allUsers = this.teamService.mapUsers(data);
      });
  }

  private mapTeamOrganizationEditModel(): TeamOrganizationEditModel {
    const data = this.editTeamForm.value;
    const teamOrganization = new TeamOrganizationEditModel();
    const country = this.countries?.find((item) => item.id === parseInt(data.country, 10));
    const state = this.states?.find((item) => item.id === parseInt(data.state, 10));
    const organizationType = this.teamOrganizationTypes?.find((item) => item.id === parseInt(data.teamType, 10));

    teamOrganization.name = data.teamName;
    teamOrganization.companyId = data.companyId;

    teamOrganization.email = data.teamEmail;
    teamOrganization.organizationType = organizationType;

    teamOrganization.addresses = [];
    teamOrganization.addresses.push({
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      postalCode: data.postalCode,
      country: country,
      state: state,
      province: data.province === 'N|A' ? null : data.province,
      isPrimary: true
    });

    data.additionalAddresses.forEach((element, index) => {
      const coun = this.countries?.find((item) => item.id === parseInt(element.country, 10));
      const sta = this.additionalStates[index]?.find((item) => item.id === parseInt(element.state, 10));
      teamOrganization.addresses.push({
        addressLine1: element.addressLine1,
        addressLine2: element.addressLine2,
        city: element.city,
        postalCode: element.postalCode,
        country: coun,
        state: sta,
        province: element.province === 'N|A' ? null : element.province,
        isPrimary: false
      });
    });

    teamOrganization.primaryTeamUserEmail = data.selectedUsers.selectedValues[0].innerText;

    return teamOrganization;
  }

  private addValidators(length: number, pattern: any, required: boolean): any {
    if (required) {
      return Validators.compose([
        Validators.required,
        Validators.maxLength(length),
        Validators.pattern(pattern),
        this.noWhitespaceValidator
      ]);
    }

    return Validators.compose([
      Validators.maxLength(length),
      Validators.pattern(pattern)
    ]);
  }

  private updateProvinceValidation(): void {
    if (!this.editTeamForm.controls.province.value) {
      this.editTeamForm.patchValue({ province: '' });
    }
    if (this.editTeamForm.controls.province.value === 'N|A') {
      this.editTeamForm.patchValue({ province: '' });
    }
  }

  private updateAdditionalProvinceValidation(index: number): void {
    const frg = this.additionalAddresses.controls[index] as FormGroup;
    if (!frg.controls.province.value) {
      frg.patchValue({ province: '' });
    }
    if (frg.controls.province.value === 'N|A') {
      frg.patchValue({ province: '' });
    }
  }

  private prepareEditTeamData(): void {
    this.primaryAddress = this.teamDetails.teamAddresses.find((add) => add.isPrimary === true);
    this.additionalAddressesModel = this.teamDetails.teamAddresses.filter((add) => add.isPrimary === false);
    this.additionalAddressForView = [];

    if (this.additionalAddressesModel.length !== 0) {
      this.additionalAddressForView = JSON.parse(JSON.stringify(this.additionalAddressesModel)) as Address[];
    }
    this.additionalAddressesModel.forEach(() => {
      this.additionalStates.push([]);
      this.additionalStateLevels.push('');
    });
  }

  private updateGrids(): void {
    try {
      const [currentTab] = this.router.url.split(`/${this.teamId}/`)[1].split(null);
      if (currentTab.toLowerCase() === 'teamMembers'.toLowerCase()) {
        this.teamService.reloadTeamUserGrid.next(true);
      }
      else if (currentTab.toLowerCase() === 'teamSites'.toLowerCase()) {
        this.teamService.reloadTeamSiteGrid.next(true);
      }
      else if (currentTab.toLowerCase() === 'teamStudies'.toLowerCase()) {
        this.teamService.reloadTeamStudyGrid.next(true);
      }
    }
    catch {
      this.teamService.reloadTeamUserGrid.next(true);
      this.teamService.reloadTeamSiteGrid.next(true);
      this.teamService.reloadTeamStudyGrid.next(true);
    }
    this.getGridCount(this.teamId);
  }

  private getGridCount(teamId): void {
    this.apiService.postTeamMembersGrid(teamId, {}).subscribe((result) => {
      this.teamService.teamMembers.next(result);
    });

    this.apiService.postTeamSitesGrid(teamId, {}).subscribe((result) => {
      this.teamService.joinedSites.next(result);
    });

    this.apiService.postTeamStudiesGrid(teamId, {}).subscribe((result) => {
      this.teamService.joinedStudies.next(result);
    });
  }
}
