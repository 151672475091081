import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '../../components/base/base.component';
import { debounce } from 'rxjs/operators';
import { timer } from 'rxjs';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';

import { DashboardService } from './dashboard.service';
import { UserService } from '../../services/user.service';
import { UtilService } from '../../services/util.service';
import { UserInfoDetailsMap } from 'src/app/interfaces/user';
import { SubmissionsDashboardPost } from '../../interfaces/dashboard';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { StateService } from '../../services/state.service';
import { SubmissionWorkflowService } from '../submission-workflow/submission-workflow.service';
import { PaginationValues } from 'src/app/models/pagination-values.mode';

let initialLoad = true;
let initialData = true;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseComponent implements OnInit, AfterViewInit {
  drafts: SubmissionsDashboardPost;
  gridView = true;
  inProgress: SubmissionsDashboardPost;
  loaded: boolean;
  loading: boolean;
  needsActions: SubmissionsDashboardPost;
  newUpdates: number;
  showRequestAccessConfirmationModal: boolean;
  userInfo: UserInfoDetailsMap;

  constructor(
    public dashboardService: DashboardService,
    public userService: UserService,
    public utilService: UtilService,
    public stateService: StateService,
    private cdc: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private translatePipe: TranslatePipe,
    private submissionWorkflowService: SubmissionWorkflowService
  ) {
    super();
    //  Reset pagination on navigation
    this.dashboardService.needsActionPagination.pageSize = this.dashboardService.paginationDataConfiguration.pageSize;
    this.dashboardService.needsActionPagination.page = 1;
  }

  ngOnInit(): void {
    const DEBOUNCE_TIME = 300;

    let previousSearchTerm;

    this.userService.userFetched.subscribe((isUserFetched) => {
      if (isUserFetched) {
        this.completeRegistration();
      }
    });

    this.subcribeDasboardSubmissionTabs();

    this.dashboardService.cardView.subscribe(() => {
      if (initialData) {
        initialData = false;
        this.dashboardService.viewListCardTypeUpdated.next(this.dashboardService.cardView.getValue());

        return;
      }

      this.dashboardService.postAllDashboardSubmissions();
      this.dashboardService.viewListCardTypeUpdated.next(this.dashboardService.cardView.getValue());
    });

    this.dashboardService.searchTerm.valueChanges
      .pipe(
        debounce(() => timer(DEBOUNCE_TIME))
      )
      .subscribe((val) => {
        if (!initialLoad && val === previousSearchTerm) {
          return;
        }

        if (initialData) {
          initialData = false;

          return;
        }

        initialLoad = false;

        previousSearchTerm = val;

        this.router.navigate([], {
          queryParams: {
            search: val,
            page: 1
          },
          queryParamsHandling: 'merge'
        });

        this.resetPage();
        this.dashboardService.postAllDashboardSubmissions();
      });

    this.submissionWorkflowService.getAllFormioFormsToDisplay();
    this.submissionWorkflowService.resetSubmissionData();
    this.resetPage();
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.dashboardService.searchTerm.setValue(params.search);

      this.cdc.detectChanges();
    });
  }

  public lastLoginDate(lastLoginDate): string {
    const formattedLastLoginDate = moment(lastLoginDate).format(this.utilService.dateFormat);
    const today = moment(moment(new Date())).format(this.utilService.dateFormat);
    const yesteday = moment(moment(new Date()).subtract(1, 'days')).format(this.utilService.dateFormat);

    const isToday = formattedLastLoginDate === today;
    const isYesterday = formattedLastLoginDate === yesteday;

    if (isToday) {
      return this.translatePipe.transform('dashboard.today');
    }
    else if (isYesterday) {
      return this.translatePipe.transform('dashboard.yesterday');
    }

    return this.utilService.formatDate(lastLoginDate);
  }

  public toggleView(value: boolean): void {
    this.dashboardService.cardView.next(value);
  }

  private subcribeDasboardSubmissionTabs(): void {
    this.dashboardService.needsAction.subscribe((val) => {
      this.needsActions = val;
    });

    this.dashboardService.inProgress.subscribe((val) => {
      this.inProgress = val;
    });

    this.dashboardService.drafts.subscribe((val) => {
      this.drafts = val;
    });
  }

  private completeRegistration(): void {
    if (!this.userInfo || this.userInfo?.isRegistered === null) {
      this.userService.getUserInfo().subscribe((data) => {
        this.userInfo = data;
        if (this.userInfo?.isRegistered === false) {
          this.router.navigate(['/registration']);
        }
      });
    }
    else if (this.userInfo?.isRegistered === false) {
      this.router.navigate(['/registration']);
    }
  }

  private resetPage(): void {
    this.dashboardService.statusFilterNeedsAction.next(null);
    this.dashboardService.statusFilterInProgress.next(null);
    this.dashboardService.needsActionPagination = new PaginationValues(1, this.dashboardService.paginationDataConfiguration.pageSize);
    this.dashboardService.inProgressPagination = new PaginationValues(1, this.dashboardService.paginationDataConfiguration.pageSize);
    this.dashboardService.draftsPagination = new PaginationValues(1, this.dashboardService.paginationDataConfiguration.pageSize);
  }
}
