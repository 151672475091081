<app-workflow-container [header]="'submissionLanding.headerTitle' | translate" hasFooter="true">
  <close-button>
    <button class="wcg-btn wcg-btn-icon" (click)="this.submissionWorkflowService.closeWorkflow()">
      <wcg-icon src="../../assets/icons/navigation/ic_close_24px.svg"></wcg-icon>
      <span class="wcg-visually-hidden">{{ 'submissionWorkflow.close' | translate }}</span>
    </button>
  </close-button>

  <main-content>
    <div class="cnxs-review-section cnxs-review-section-intro">
      <h2 class="wcg-h5">{{ 'submissionWorkflow.setup' | translate }}</h2>
      <p class="wcg-h2">{{ supportingText }}</p>
    </div>

    <div class="cnxs-review-section cnxs-fieldset-section">
      <form name="cnxs-submission-review-selection" [formGroup]="submissionTypesForm">
        <fieldset class="cnxs-fieldset" formGroupName="submissionTypes">
          <legend class="cnxs-legend">{{ 'submissionTypesForm.legend' | translate }}</legend>

          <div *ngFor="let type of submissionTypes">
            <wcg-radio name="submissionType" formControlName="submissionType" [value]="type.id"
              (change)="onChangeSubmissionType()">
              {{ type.value }}
            </wcg-radio>
          </div>
        </fieldset>
      </form>

      <div class="formio-container" *ngIf="showClosureTypeLightbulb">
        <div class="mb-2 card border">
          <div ref="header" aria-expanded="true" role="button" class="card-header bg-info">
            <span class="mb-0 card-title text-light">
              Info
            </span>
          </div>
          <div ref="nested-info1" class="card-body">
            <div ref="component" class="formio-component formio-component-htmlelement formio-component-html1">
              <p ref="html" class="formio-component-htmlelement">{{ 'submissionWorkflow.closureTypeSelectedLightbulb' |
                translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main-content>

  <footer-content>
    <div class="cnxs-form-footer-content">
      <button class="wcg-link cnxs-arrow-btn cnxs-prev" (click)="this.submissionWorkflowService.closeWorkflow()">
        <wcg-icon class="cnxs-arrow cnxs-back-arrow" src="../assets/icons/navigation/ic_arrow_back_24px.svg"></wcg-icon>
        {{ 'submissionWorkflow.back' | translate }}
      </button>

      <button type="submit" class="wcg-link cnxs-arrow-btn cnxs-next"
        [disabled]="this.submissionTypesForm.controls.submissionTypes.invalid" (click)="continueClick()">
        {{ 'submissionWorkflow.continueSetup' | translate }}
        <wcg-icon class="cnxs-arrow cnxs-next-arrow" src="../assets/icons/navigation/ic_arrow_forward_24px.svg">
        </wcg-icon>
      </button>
    </div>
  </footer-content>
</app-workflow-container>