import { Component, Input } from '@angular/core';
import { SubmissionStatus } from '../../interfaces/submissions';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-status-tracker',
  templateUrl: './status-tracker.component.html',
  styleUrls: ['./status-tracker.component.scss']
})
export class StatusTrackerComponent {
  @Input() statuses: Array<SubmissionStatus>;
  @Input() warning = false;

  constructor(public utilService: UtilService) {

  }
}
