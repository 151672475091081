<section class="wcg-container">
  <h1 class="wcg-h1">{{ 'nav.sites' | translate }}</h1>

  <div>
    <form ngForm class="wcg-rhythm-5">
      <wcg-form-field>
        <wcg-label>{{ 'actions.search' | translate }}</wcg-label>
        <wcg-input [formControl]="searchTerm">
          <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
        </wcg-input>
      </wcg-form-field>
    </form>
  </div>

  <div class="wcg-row">
    <div class="wcg-col-xs-4 wcg-col-md-12 wcg-col-lg-12 wcg-rhythm-2">
      <div class="export-button-wrap">
        <app-export-action-button [disableButton]="!tableData?.length" [gridFilters]="gridFilters"
          [exportType]="exportType"></app-export-action-button>
      </div>
    </div>
  </div>

  <wcg-table class="wcg-rhythm-4" [ngClass]="{'cnxs-table-loading': loading}" [headers]="headers" [data]="tableData"
    [options]="options" (rowClicked)="handleRowClicked($event)" (sortClicked)="handleSort($event)">

    <div class="cnxs-pagination-wrap wcg-table-footer" *ngIf="loaded">
      <div class="wcg-total-results">
        {{ 'tables.totalResults' | translate}}: {{totalRecords}}
      </div>
      <div class="pagination" *ngIf="tableData?.length">
        <wcg-pagination [current]="currentPage" [pageSize]="currentPageSize"
          [max]="utilService.maxPages(totalRecords, currentPageSize)" (changePage)="handlePageChange($event)"></wcg-pagination>
      </div>
    </div>

    <div class="cnxs-table-loader" slot="table-body">
      <wcg-loader [show]="!loaded || loading"></wcg-loader>
    </div>
  </wcg-table>
</section>