import { IdValueModel } from './sharedmodels';

export class SubmissionType {
  id: number;
  value: string;
  envelopeAndEmailType: string;
  isActive: boolean;
  formType: number;
  groups: number[];
  routingGroup: IrisRoutingGroup;
  documentTypes: number[];
  defaultDocumentTypeId: number;
}

export interface SubmissionTypeGridRecord {
  id: number;
  value: string;
  groups: IdValueModel[];
  envelopeAndEmailType: string;
  isActive: boolean;
  formType: string;
  routingGroups: IdValueModel;
  documentTypes: IdValueModel[];
  defaultDocumentTypeId: number;
}

export interface SubmissionTypeGridRecordMap {
  id: number;
  'Submission Type Name': string;
  'Envelope and Email Type': string;
  'Submission Type Group': string;
  'Enabled': string;
  'Form Type': string;
}

export interface SubmissionTypesGrid {
  currentPage: number;
  totalRecords: number;
  pageSize: number;
  records: Array<SubmissionTypeGridRecord>;
}

export interface SubmissionTypesGridPostBody {
  page: number;
  pageSize: number;
  filterOptions: {};
  sortOptions: {};
}

export class IrisRoutingGroup {
  id: number;
  name: string;
  irisRoutingGroupId: number;
}

export enum FormType {
  OnlineForm = 0,
  UploadForm = 1
}

export class SubmissionTypeGridHeaders {
  public static headers = [
    {
      name: 'Submission Type Name',
      field: 'Submission Type Name',
      sortable: true
    },
    {
      name: 'Envelope and Email Type',
      field: 'Envelope and Email Type',
      sortable: true
    },
    {
      name: 'Submission Type Group',
      field: 'Submission Type Group',
      sortable: true
    },
    {
      name: 'Enabled',
      field: 'Enabled',
      sortable: true
    },
    {
      name: 'Form Type',
      field: 'Form Type',
      sortable: true
    }
  ];
}
