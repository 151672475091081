import { Component, OnInit } from '@angular/core';
import { TableOptions } from '@epharmasolutions/ui-kit/dist/types/components/wcg-table/wcg-table.model';
import { TableHeader } from 'src/app/interfaces/components';
import { DownloadExport, DownloadExportsGridMap } from 'src/app/interfaces/download-exports';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { ToastService } from 'src/app/services/toast.service';
import { UtilService } from 'src/app/services/util.service';
import { DownloadExportsService } from './download-exports.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ReportingService } from 'src/app/services/reporting.service';
import { ServerErrorCodes } from 'src/app/common/collections';

@Component({
  selector: 'app-download-exports',
  templateUrl: './download-exports.component.html',
  styleUrls: ['./download-exports.component.scss']
})
export class DownloadExportsComponent extends BaseComponent implements OnInit {
  headers: Array<TableHeader>;
  loaded: boolean;
  loading: boolean;
  totalRecords: number;
  currentPage = 1;
  currentPageSize = 10;
  tableData: Array<DownloadExportsGridMap> = [];
  exportsData: Array<DownloadExport> = [];
  options: TableOptions = {
    manualPaginationAndSort: true
  };

  downloadUrl: string;
  filters: any;

  constructor(
    private toastService: ToastService,
    private translatePipe: TranslatePipe,
    public utilService: UtilService,
    private reportingService: ReportingService,
    private downloadExportsService: DownloadExportsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getExportsGrid({
      page: 1,
      pageSize: this.currentPageSize,
      sortOptions: [],
      filterOptions: []
    });
    this.headers = this.getHeaders();
  }

  handlePageChange(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;

    this.getExportsGrid({
      page: event.detail.page,
      pageSize: this.currentPageSize
    });
  }

  handleRowClicked(event): void {
    const downloadExportTargetId: string = event.detail.event.target.id;

    if (downloadExportTargetId === 'downloadExport') {
      event.detail.event.preventDefault();
      const selectedId = event.detail.id;
      const fileName = this.exportsData.find((data) => data.id === event.detail.id).displayName;
      this.downloadExport(selectedId, fileName);
    }
  }

  private getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('exports.fileName'), 'displayName'),
      this.utilService.createTableHeader(this.translatePipe.transform('exports.exportStatus'), 'status'),
      this.utilService.createTableHeader('', 'downloadFile')
    ];
  }

  private downloadExport(exportId: number, fileName: string): void {
    this.loading = true;
    this.reportingService.downloadExportFile(exportId, fileName)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.toastService.add([{
            closable: true,
            id: 'downloadExportFileError',
            message: error.status === ServerErrorCodes.goneClientErrorStatusNumber ?
              this.translatePipe.transform('exports.expiredDownloadErrorMessage') :
              this.translatePipe.transform('serverErrors.internalServerError'),
            timeout: this.toastService.ERROR_TIMEOUT,
            variant: 'error'
          }]);
        }
      );
  }

  private getExportsGrid(body): void {
    this.loading = true;
    this.filters = body;

    this.reportingService.getExportRequestsGrid(this.filters)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: any) => {
        this.loaded = true;
        this.loading = false;
        this.tableData = this.downloadExportsService.mapDownloadExportsGridData(data);
        this.exportsData = data.records;
        this.currentPage = data.currentPage;
        this.totalRecords = data.totalRecords;
      }, () => {
        this.loading = false;

        this.toastService.add([{
          closable: true,
          id: 'postDownloadExportsFailure',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          timeout: this.toastService.ERROR_TIMEOUT,
          variant: 'error'
        }]);
      });
  }
}

