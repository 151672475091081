import { UserService } from './../../services/user.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil, debounce } from 'rxjs/operators';
import { timer } from 'rxjs';
import * as moment from 'moment';
import { ApiService } from '../../services/api.service';
import { SubmissionsGrid, SubmissionsGridRecordMap } from '../../interfaces/submissions';
import { SubmissionsService } from './submissions.service';
import { BaseComponent } from '../../components/base/base.component';
import { UtilService } from '../../services/util.service';
import { TableHeader } from '../../interfaces/components';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { ToastService } from '../../services/toast.service';
import { TableOptions } from '@epharmasolutions/ui-kit/dist/types/components/wcg-table/wcg-table.model';
import { SubmissionsSortOptions, DEBOUNCE_TIME, flatPickrDateFormat, SubmissionsFilterOptions } from '../../common/collections';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { LoggerService } from 'src/app/services/logger.service';
import { GridOptionsSearchModel } from 'src/app/admin/shared/model/grid.model';
import { GridExportType } from 'src/app/common/reportingCollections';

@Component({
  selector: 'app-submissions',
  templateUrl: './submissions.component.html',
  styleUrls: ['./submissions.component.scss']
})
export class SubmissionsComponent extends BaseComponent implements OnInit {
  @ViewChild('startDatePicker') startDatePicker;
  @ViewChild('endDatePicker') endDatePicker;

  currentPage = 1;
  currentPageSize = 10;
  currentSort: string = SubmissionsSortOptions.submittedDate;
  currentSortDescending = true;
  headers: Array<TableHeader>;
  loaded: boolean;
  loading: boolean;
  searchTerm = new FormControl('');
  tableData: Array<SubmissionsGridRecordMap>;
  totalRecords: number;
  selectedRadio = 'mostRecent';
  limitAdminUserCount = true;
  searchRecordsToolTip = '';
  recordCount = 0;
  isAdminUser = false;
  startDateFilter = new FormControl('');
  endDateFilter = new FormControl('');
  filterOptions: any[] = [];
  startDateUTC: any = null;
  endDateUTC: any = null;

  options: TableOptions = {
    manualPaginationAndSort: true
  };

  datePickerOptions: FlatpickrOptions = {
    dateFormat: flatPickrDateFormat
  };

  gridFilters: GridOptionsSearchModel;
  readonly exportType: GridExportType = GridExportType.Submissions;

  constructor(
    public utilService: UtilService,
    private apiService: ApiService,
    private submissionsService: SubmissionsService,
    private router: Router,
    private toastService: ToastService,
    private translatePipe: TranslatePipe,
    private loggerService: LoggerService,
    private userService: UserService

  ) {
    super();

    this.userService.getUserInfo().subscribe((data) => {
      this.isAdminUser = data.canViewUsers || data.canManageUsers || data.canViewSubmissions;
    });
  }

  ngOnInit(): void {
    this.headers = this.getHeaders();
    // get the submission count only if its an admin user.

    this.submissionsService.endDate.next(null);
    this.submissionsService.startDate.next(null);
    this.submissionsService.searchTerm.next('');
    if (this.isAdminUser) {
      this.getSubmissionRecordCount();
    }

    this.postSubmissionsGrid({
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: '',
      sortOptions: [{
        field: SubmissionsSortOptions.submittedDate,
        isDescending: true
      }]
    });

    this.searchTerm.valueChanges
      .pipe(
        debounce(() => timer(DEBOUNCE_TIME))
      )
      .subscribe((val) => {
        this.submissionsService.searchTerm.next(val);
        this.postSubmissionsGrid({
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: val,
          sortOptions: [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }],
          filterOptions: this.filterOptions,
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });
      });
    this.startDateFilterOptions();
    this.endDateFilterOptions();
  }

  addFilterOptions(): void {
    if (this.startDateFilter.value[0] || this.endDateFilter.value[0]) {
      if (!this.filterOptions.length) {
        const filterOption = [
          {
            field: SubmissionsFilterOptions.submissionSubmittedDate
          }
        ];

        this.filterOptions = filterOption;
      }
    }
    else {
      this.filterOptions = [];
    }
  }

  handlePageChange(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;

    this.postSubmissionsGrid({
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }],
      filterOptions: this.filterOptions,
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  handleRowClicked(event): void {
    if (event.detail.event.target.hasAttribute('href')) {
      event.detail.event.preventDefault();

      this.router.navigateByUrl(event.detail.event.target.getAttribute('href'));
    }
  }

  handleSort(event): void {
    this.currentSort = SubmissionsSortOptions[event.detail.field];
    this.currentSortDescending = !event.detail.ascending;

    this.postSubmissionsGrid({
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: [{
        field: SubmissionsSortOptions[event.detail.field],
        isDescending: this.currentSortDescending
      }],
      filterOptions: this.filterOptions,
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  startDateFilterOptions(): void {
    this.startDateFilter.valueChanges
      .subscribe((val) => {
        [this.endDatePicker.flatpickr.config.minDate] = val;
        this.submissionsService.startDate.next(val[0]);
        if (val[0]) {
          this.startDateUTC = moment(val[0]).utc(false);
        }
        this.addFilterOptions();
        this.postSubmissionsGrid({
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          sortOptions: [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }],
          filterOptions: this.filterOptions,
          startDate: val[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });
      });
  }

  endDateFilterOptions(): void {
    this.endDateFilter.valueChanges
      .subscribe((val) => {
        [this.startDatePicker.flatpickr.config.maxDate] = val;
        this.submissionsService.endDate.next(val[0]);
        if (val[0]) {
          this.endDateUTC = moment(val[0]).utc(false);
        }
        this.addFilterOptions();
        this.postSubmissionsGrid({
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          sortOptions: [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }],
          filterOptions: this.filterOptions,
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: val[0] ? this.endDateUTC : null
        });
      });
  }

  toggleEndDate(): void {
    this.endDatePicker.flatpickr.toggle();
  }

  toggleStartDate(): void {
    this.startDatePicker.flatpickr.toggle();
  }

  public changeRadio(event): void {
    this.selectedRadio = event.target.value;
    this.limitAdminUserCount = this.selectedRadio === 'mostRecent';

    this.postSubmissionsGrid({
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }],
      filterOptions: this.filterOptions,
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  private getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('submissions.name'), 'nameLink', true),
      this.utilService.createTableHeader(this.translatePipe.transform('submissions.type'), 'type', true),
      this.utilService.createTableHeader(this.translatePipe.transform('submissions.sponsor'), 'sponsor', true),
      this.utilService.createTableHeader(this.translatePipe.transform('submissions.protocolNumber'), 'protocolNumber', true),
      this.utilService.createTableHeader(this.translatePipe.transform('submissions.piName'), 'piName', true),
      this.utilService.createTableHeader(
        this.translatePipe.transform('submissions.submittedDate'), 'submittedDate', true, 'date', this.utilService.dateFormat
      ),
      this.utilService.createTableHeader(this.translatePipe.transform('submissions.status'), 'status', true),
      this.utilService.createTableHeader(this.translatePipe.transform('submissions.irbTrackingNumber'), 'irbTrackingNumber', true)
    ];
  }

  private getSubmissionRecordCount(): void {
    this.apiService.getConfigurationValue('SubmissionRecordToLoadForAdmin')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (data !== null) {
          this.recordCount = data.value.toLocaleString();
          this.searchRecordsToolTip = this.translatePipe.transform('submissions.searchRecordsToolTip')
            .replace('{recordCount}', this.recordCount);
        }
      }, (err) => {
        this.loggerService.error(`Data could not be fetched: ${err}`);
      });
  }

  private postSubmissionsGrid(body): void {
    this.loading = true;
    this.gridFilters = body;

    this.apiService.postSubmissionsGrid(body, this.limitAdminUserCount, this.recordCount)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: SubmissionsGrid) => {
        this.loaded = true;
        this.loading = false;
        this.totalRecords = data.totalRecords;
        this.tableData = this.submissionsService.mapSubmissionGridData(data);
        this.currentPage = data.currentPage;
      }, (err) => {
        this.loading = false;
        this.toastService.add([{
          closable: true,
          id: 'postSumbmissionsFailure',
          message: err.details.message,
          timeout: this.toastService.ERROR_TIMEOUT,
          variant: 'error'
        }]);
      });
  }
}
