import { PermissionType, RequestStatus } from 'src/app/common/collections';
import { Address } from 'src/app/interfaces/common';
import { IdValueModel } from './sharedmodels';
import { OrganizationModel, UserInfo } from './userinfo';

export class TeamOrganizationModel {
    id: string;
    name: string;
    organizationType: IdValueModel;
    email: string;
    companyId: number;
    address: Address;
    primaryTeamUserEmail: string;
}

export class OrganizationUserModel extends UserInfo {
    isPrimary: boolean;
    permissionType: PermissionType;
    requestStatus: RequestStatus;
}
export class TeamDetailModel extends OrganizationModel {
    email: string;
    teamAddresses: Array<Address>;
    primaryUser: OrganizationUserModel;
    createdDate: Date;
    createdBy: string;
    modifiedDate: Date;
    modifiedBy: string;
    isActive: boolean;
    isPrimaryUser: boolean;
    userPermissionType: PermissionType;
}

export class TeamOrganizationEditModel {
    id: string;
    name: string;
    organizationType: IdValueModel;
    email: string;
    addresses: Address[];
    primaryTeamUserEmail: string;
}

export class TeamOrganizationPatchModel {
    active: boolean;
    primaryUserEmail: string;
}

export class TeamOrganizationUserCreateModel {
    email: string;
    permissionType: PermissionType;
    workspacePermissionType: PermissionType;
}
