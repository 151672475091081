import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { UtilService } from '../../../services/util.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { TableHeader } from '../../../interfaces/components';
import { BaseComponent } from '../../../components/base/base.component';
import { ManageContactsService } from '../manage-contacts.service';
import { Router } from '@angular/router';
import { LoggerService } from '../../../services/logger.service';
import { ApiService } from '../../../services/api.service';
import { ToastService } from '../../../services/toast.service';
import { TeamMembersGrid, TeamMembersMap } from 'src/app/interfaces/teams';

@Component({
  selector: 'app-manage-contacts-requests',
  templateUrl: './manage-contacts-requests.component.html',
  styleUrls: ['./manage-contacts-requests.component.scss']
})
export class ManageContactsRequestsComponent extends BaseComponent implements OnInit {
  headers: Array<TableHeader>;
  users: any;

  options = {
    rowsPerPage: 10
  };

  isSite: boolean;
  membersHeaders: Array<TableHeader>;
  teams: any;
  name: string;
  memberOptions = {
    rowsPerPage: 10
  };

  teamMembersModal = false;
  teamName: string;
  teamMembers: TeamMembersGrid;
  tableData: Array<TeamMembersMap>;
  loaded: boolean;
  loading: boolean;
  totalRecords: number;

  private newPermissionType: string;
  private id: any;

  constructor(
    private apiService: ApiService,
    private loggerService: LoggerService,
    private manageContactsService: ManageContactsService,
    private router: Router,
    private toastService: ToastService,
    private translatePipe: TranslatePipe,
    private utilService: UtilService
  ) {
    super();
  }

  ngOnInit(): void {
    this.headers = this.getHeaders();

    this.isSite = this.router.url.includes('/sites/');

    if (this.isSite) {
      [this.id] = this.router.url.split('/sites/')[1].split('/');
    }
    else {
      [this.id] = this.router.url.split('/studies/')[1].split('/');
    }

    this.manageContactsService.userInfoSubject.subscribe((val) => {
      this.users = this.manageContactsService.mapWorkspaceRequests(val, this.isSite);
    });

    this.membersHeaders = this.getTeamMembersHeaders();
  }

  handleRowClick(event): void {
    const eventPath = this.getEventPath(event);

    if (!eventPath.find((path) => path.attributes && (path.querySelector('[data-approve-btn') ||
    path.querySelector('[team-approve-btn')))) {
      return;
    }
    this.handleUserRowClick(event);
    this.handleTeamRowClick(event);
  }

  handleTeamRowClick(event): void {
    const eventPath = this.getEventPath(event);

    if (eventPath.find((path) => path.attributes && path.attributes.getNamedItem('data-show-teamMembers'))) {
      this.showTeamMembers(event.detail.teamExternalReferenceId, event.detail.teamName);
    }

    if (eventPath.find((path) => path.attributes && path.attributes.getNamedItem('team-approve-btn'))) {
      this.postTeamWorkspaceRequestApprove(this.id, event.detail.teamExternalReferenceId, event.detail.teamName, false);
    }

    if (eventPath.find((path) => path.attributes && path.attributes.getNamedItem('team-reject-btn'))) {
      this.postTeamWorkspaceRequestApprove(this.id, event.detail.teamExternalReferenceId, event.detail.teamName, true);
    }
  }

  handleUserRowClick(event): void {
    const eventPath = this.getEventPath(event);

    const row = event.detail.event.currentTarget;
    const approveBtn = row.querySelector('[data-approve-btn');

    const changeCb = (changeEvent): void => {
      this.newPermissionType = changeEvent.target.value;

      approveBtn.disabled = false;
    };

    if (row.querySelector('select')) {
      row.querySelector('select').addEventListener('change', changeCb);
    }

    if (row.querySelector('select')?.value && row.querySelector('select')?.value.length) {
      changeCb({
        target: {
          value: row.querySelector('select').value
        }
      });
    }

    // Approve
    if (eventPath.find((path) => path.attributes && path.attributes.getNamedItem('data-approve-btn'))) {
      row.querySelector('select').removeEventListener('change', changeCb);

      this.postWorkspaceRequestApprove(
        this.manageContactsService.getWorkspaceId(this.router.url),
        event.detail,
        this.newPermissionType,
        event.detail.concurrencyVersion
      );
    }

    // Reject
    if (eventPath.find((path) => path.attributes && path.attributes.getNamedItem('data-reject-btn'))) {
      row.querySelector('select').removeEventListener('change', changeCb);

      this.postWorkspaceRequestReject(
        this.manageContactsService.getWorkspaceId(this.router.url),
        event.detail,
        event.detail.concurrencyVersion
      );
    }
  }

  closeClick(): void {
    this.teamMembersModal = false;
    this.teamName = '';
    this.tableData = [];
    this.membersHeaders.forEach((headerOptions) => {
      headerOptions._isSorted = false;
    });
  }

  showTeamMembers(teamId, teamName): void {
    this.teamName = teamName;
    this.postTeamMembers({
      page: 1,
      pageSize: 1000,
      searchTerm: ''
    }, teamId);
  }

  private postTeamMembers(body, teamId): void{
    this.apiService.postWorkspaceTeamMembersGrid(this.id, teamId, body)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: TeamMembersGrid) => {
        this.totalRecords = data.totalRecords;
        this.tableData = this.manageContactsService.mapMembersGridData(data);
        this.teamMembersModal = true;
      },
      () => {
        this.toastService.add([{
          closable: true,
          id: 'postTeamMembersFailure',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          timeout: this.toastService.ERROR_TIMEOUT,
          variant: 'error'
        }]);
      });
  }

  private getTeamMembersHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('teamMembers.name'), 'name', true),
      this.utilService.createTableHeader(this.translatePipe.transform('teamMembers.email'), 'email', true),
      this.utilService.createTableHeader(this.translatePipe.transform(this.isSite ? 'teamMembers.sitePermissionHeader' :
        'teamMembers.studyPermissionHeader'), 'permissionType', true)
    ];
  }

  private getEventPath = (event): any[] => {
    const composedPath = event.detail.event.composedPath && event.detail.event.composedPath();
    const eventPath = event.detail.event.path || composedPath;

    return eventPath;
  };

  private getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('tableHeaders.name'), 'name', true),
      this.utilService.createTableHeader(this.translatePipe.transform('tableHeaders.email'), 'email', true),
      this.utilService.createTableHeader(this.translatePipe.transform('tableHeaders.permissions'), 'permissionTypeColumn', true),
      this.utilService.createTableHeader(null, 'actions', false)
    ];
  }

  private getWorkspaceRequests(id): void {
    this.apiService.getWorkspaceRequests(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.manageContactsService.userInfoSubject.next(data);
        this.loading = false;
      }, (err) => {
        this.loading = false;
        this.loggerService.error(`Data could not be fetched: ${err}`);
      });
  }

  private postWorkspaceRequestApprove(workspaceId, user, permissionType, concurrencyVersion): void {
    this.loading = true;
    this.apiService.postWorkspaceRequestApprove(workspaceId, user.userId, permissionType, concurrencyVersion)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.getWorkspaceRequests(this.manageContactsService.getWorkspaceId(this.router.url));

        this.toastService.add([{
          closable: true,
          id: 'workspaceRequestApproveSuccess',
          message: this.translatePipe.transform('manageContacts.workspaceRequestApproveSuccess').replace('{name}', user.name),
          timeout: 5000,
          variant: 'success'
        }]);
      }, (err) => {
        this.loading = false;
        this.loggerService.error(`Data could not be fetched: ${err}`);
      });
  }

  private postWorkspaceRequestReject(workspaceId, user, concurrencyVersion): void {
    this.loading = true;
    this.apiService.postWorkspaceRequestReject(workspaceId, user.userId, concurrencyVersion)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.getWorkspaceRequests(this.manageContactsService.getWorkspaceId(this.router.url));

        this.toastService.add([{
          closable: true,
          id: 'workspaceRequestRejectSuccess',
          message: this.translatePipe.transform('manageContacts.workspaceRequestRejectSuccess').replace('{name}', user.name),
          timeout: 5000,
          variant: 'success'
        }]);
      }, (err) => {
        this.loading = false;
        this.loggerService.error(`Data could not be fetched: ${err}`);
      });
  }

  private postTeamWorkspaceRequestApprove(workspaceId, teamId, teamName, isRejected): void {
    this.loading = true;
    this.apiService.patchTeamWorkspaceRequestApproveOrReject(workspaceId, teamId, isRejected)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.getWorkspaceRequests(this.manageContactsService.getWorkspaceId(this.router.url));
        this.toastService.add([{
          closable: true,
          id: 'workspaceRequestApproveSuccess',
          message: isRejected ?
            this.translatePipe.transform('manageContacts.teamWorkspaceAccessRejectSuccess').replace('{teamName}', teamName) :
            this.translatePipe.transform('manageContacts.teamWorkspaceAccessApproveSuccess').replace('{teamName}', teamName),
          timeout: 5000,
          variant: 'success'
        }]);
      }, (err) => {
        this.loading = false;
        this.loggerService.error(`Data could not be fetched: ${err}`);
      });
  }
}
