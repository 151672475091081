/* eslint-disable max-lines */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { takeUntil, debounce } from 'rxjs/operators';

import { WorkspaceOutcomeGetMap } from '../../../interfaces/workspaces';
import { ApiService } from '../../../services/api.service';
import { StudyService } from '../study.service';
import { ToastService } from '../../../services/toast.service';
import { ZipService } from '../../../services/zip.service';
import { BaseComponent } from '../../../components/base/base.component';
import { UtilService } from '../../../services/util.service';
import { TableHeader } from '../../../interfaces/components';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { FlatpickrOptions } from 'ng2-flatpickr';
import {
  flatPickrDateFormat,
  DEBOUNCE_TIME,
  DocumentRequestTypes,
  StudyOutcomeDocumentFilterOptions,
  ServerErrorCodes,
  WorkspaceTypeSearchGrid,
  StudyWorkspaceOutcomeDocumentsSortOptions
} from 'src/app/common/collections';
import { Observable, timer } from 'rxjs';
import * as moment from 'moment';
import { StateService } from 'src/app/services/state.service';
import { OutcomeDocumentDetailsModalService }
  from 'src/app/components/modals/outcome-document-details-modal/outcome-document-details-modal.service';
import { GridExportType } from 'src/app/common/reportingCollections';

@Component({
  selector: 'app-study-outcome-documents',
  templateUrl: './study-outcome-documents.component.html',
  styleUrls: ['./study-outcome-documents.component.scss']
})
export class StudyOutcomeDocumentsComponent extends BaseComponent implements OnInit {
  tableData: Array<WorkspaceOutcomeGetMap>;
  readonly exportType: GridExportType = GridExportType.StudyDetailsOutcomeDocuments;
  gridFilters: any;
  loaded: boolean;
  loading: boolean;
  id: string;
  searchTerm = new FormControl('');
  documents: Array<WorkspaceOutcomeGetMap>;
  selected: Array<WorkspaceOutcomeGetMap> = [];
  headers: Array<TableHeader>;
  documentsFetched = false;
  pageUnauthorized = false;
  initialDocuments: Array<WorkspaceOutcomeGetMap> = [];

  currentSort: string;
  currentSortDescending = true;

  @ViewChild('startDatePicker') startDatePicker;
  @ViewChild('endDatePicker') endDatePicker;

  startDateFilter = new FormControl('');
  endDateFilter = new FormControl('');
  filterOptions: any[] = [];
  startDateUTC: any = null;
  endDateUTC: any = null;
  datePickerOptions: FlatpickrOptions = {
    dateFormat: flatPickrDateFormat
  };

  isStudyOnly = false;
  currentPageSize = 10;
  options = {
    manualPaginationAndSort: true,
    rowsPerPage: 10,
    rowSelection: true
  };

  documentRequestTypes = DocumentRequestTypes;
  currentTab: string;

  currentPage: number;
  totalRecords: number;
  totalPages: number;
  documentIds: string[] = [];
  downloadingAll = false;

  constructor(
    public utilService: UtilService,
    public zipService: ZipService,
    private apiService: ApiService,
    private router: Router,
    private studyService: StudyService,
    private toastService: ToastService,
    private translatePipe: TranslatePipe,
    private stateService: StateService,
    private outcomeDocumentDetailsModalService: OutcomeDocumentDetailsModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.studyService.endDate.next(null);
    this.studyService.startDate.next(null);
    this.studyService.searchTerm.next('');

    const [id] = this.router.url.split('/studies/')[1].split('/');

    this.currentTab = this.router.url.split('/studies/')[1].split('/')[1].replace('-', ' ');
    this.id = id;

    this.postStudyOutcomeDocumentsGrid(id, {
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: ''
    });

    this.headers = this.getHeaders();

    this.searchTerm.valueChanges
      .pipe(
        debounce(() => timer(DEBOUNCE_TIME))
      )
      .subscribe((val) => {
        this.studyService.searchTerm.next(val);

        this.postStudyOutcomeDocumentsGrid(this.id, {
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: val,
          sortOptions: this.currentSort ? [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }] : null,
          filterOptions: this.filterOptions,
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });
      });

    this.startDateFilterOptions();
    this.endDateFilterOptions();
  }

  setStudyOnlyFilterOptions(values: Array<WorkspaceTypeSearchGrid>): void {
    const [studyOnlyOption] = this.filterOptions.filter((filter) => filter.field === StudyOutcomeDocumentFilterOptions.workspaceType);

    if (studyOnlyOption) {
      const filterOptionsIndex = this.filterOptions.indexOf(studyOnlyOption);

      this.filterOptions[filterOptionsIndex].values = values;
    }
    else {
      this.filterOptions.push({
        field: StudyOutcomeDocumentFilterOptions.workspaceType,
        operator: 'Equals',
        values: values
      });
    }
  }

  startDateFilterOptions(): void {
    this.startDateFilter.valueChanges
      .subscribe((val) => {
        this.jumpStartDates(val[0]);
        [this.endDatePicker.flatpickr.config.minDate] = val;

        this.studyService.startDate.next(val[0]);
        if (val[0]) {
          this.startDateUTC = moment(val[0]).utc(false);
        }

        this.addFilterOptions();
        this.postStudyOutcomeDocumentsGrid(this.id, {
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          sortOptions: this.currentSort ? [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }] : null,
          filterOptions: this.filterOptions,
          startDate: val[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });
      });
  }

  endDateFilterOptions(): void {
    this.endDateFilter.valueChanges
      .subscribe((val) => {
        this.jumpEndDates(val[0]);
        [this.startDatePicker.flatpickr.config.maxDate] = val;

        this.studyService.endDate.next(val[0]);
        if (val[0]) {
          this.endDateUTC = moment(val[0]).utc(false);
        }

        this.addFilterOptions();
        this.postStudyOutcomeDocumentsGrid(this.id, {
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          sortOptions: this.currentSort ? [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }] : null,
          filterOptions: this.filterOptions,
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: val[0] ? this.endDateUTC : null
        });
      });
  }

  toggleEndDate(): void {
    this.endDatePicker.flatpickr.toggle();
  }

  toggleStartDate(): void {
    this.startDatePicker.flatpickr.toggle();
  }

  handleRowSelection(event): void {
    this.selected = event.detail;
  }

  getAllDocuments(): void {
    if (this.isStudyOnly) {
      this.isStudyOnly = false;
      this.setStudyOnlyFilterOptions([WorkspaceTypeSearchGrid.Study, WorkspaceTypeSearchGrid.Site]);

      this.getDocumentsWithSwitcher();
    }
  }

  getStudyOnlyDocuments(): void {
    if (!this.isStudyOnly) {
      this.isStudyOnly = true;
      this.setStudyOnlyFilterOptions([WorkspaceTypeSearchGrid.Study]);

      this.getDocumentsWithSwitcher();
    }
  }

  handleRowClick(event): void {
    if (event.detail.event.target.className.includes('data-download-outcome-document-button')) {
      this.zipService.startDownload([event.detail], this.documentRequestTypes.Outcome);
    }
    if (event.detail.event.target.className.includes('data-details-outcome-document-button')) {
      this.setDateFilters(event);
      this.stateService.selectedOutcomeDocumentToDetails.next(event.detail);
      this.stateService.showOutcomeDetailsModal.next(true);
    }
  }

  jumpStartDates(value: any): void {
    if (value) {
      if (!this.endDateFilter.value[0]) {
        this.endDatePicker.flatpickr.jumpToDate(value);
      }
    }
    else if (this.endDateFilter.value[0]) {
      this.startDatePicker.flatpickr.jumpToDate(this.endDateFilter.value[0]);
    }
    else {
      this.endDatePicker.flatpickr.jumpToDate(new Date());
    }
  }

  jumpEndDates(value: any): void {
    if (value) {
      if (!this.startDateFilter.value[0]) {
        this.startDatePicker.flatpickr.jumpToDate(value);
      }
    }
    else if (this.startDateFilter.value[0]) {
      this.endDatePicker.flatpickr.jumpToDate(this.startDateFilter.value[0]);
    }
    else {
      this.startDatePicker.flatpickr.jumpToDate(new Date());
    }
  }

  addFilterOptions(): void {
    if (this.startDateFilter.value[0] || this.endDateFilter.value[0]) {
      const [existsFilterReviewedDate] = this.filterOptions
        .filter((filters) => filters.field === StudyOutcomeDocumentFilterOptions.reviewedDate);

      if (!existsFilterReviewedDate) {
        this.filterOptions.push({
          field: StudyOutcomeDocumentFilterOptions.reviewedDate
        });
        this.filterOptions.push({
          field: StudyOutcomeDocumentFilterOptions.transmittedDate
        });
      }
    }
    else if (this.filterOptions.length > 1) {
      const [transmittedDateField] = this.filterOptions
        .filter((filters) => filters.field === StudyOutcomeDocumentFilterOptions.transmittedDate);
      const transmittedFilterOption = this.filterOptions.indexOf(transmittedDateField, 0);
      this.filterOptions.splice(transmittedFilterOption, 1);

      const [reviewedDateField] = this.filterOptions
        .filter((filters) => filters.field === StudyOutcomeDocumentFilterOptions.reviewedDate);
      const reviewedFilterOption = this.filterOptions.indexOf(reviewedDateField, 0);
      this.filterOptions.splice(reviewedFilterOption, 1);
    }
  }

  downloadSelectedDocuments(): void {
    this.zipService.setDownloading(true, 'selected');
    if (this.isStudyOnly) {
      this.zipService.startDownload(this.selected, this.documentRequestTypes.Outcome, 'selected');
    }
    else if (!this.isStudyOnly) {
      this.documentIds = [];
      this.selected.forEach((element) => {
        this.documentIds.push(element.documentId);
      });

      this.processAllDownloadFlow(this.apiService.getAllStudyOutcomeDocumentDetails(this.id, this.documentIds), 'selected');
    }
  }

  downloadAllDocuments(): void {
    this.downloadingAll = true;
    if (this.isStudyOnly) {
      this.setStudyOnlyFilterOptions([WorkspaceTypeSearchGrid.Study]);
    }
    const data = {
      page: 1,
      pageSize: 0,
      searchTerm: this.searchTerm.value,
      filterOptions: this.filterOptions,
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    };
    this.apiService.studyOutcomeDocumentDownloadAllRequest(this.id, data, this.isStudyOnly)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.downloadingAll = false;
        this.stateService.showDownloadModal.next(true);
      }, () => {
        this.downloadingAll = false;
      });
  }

  handleChangePage(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;

    this.postStudyOutcomeDocumentsGrid(this.id, {
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      filterOptions: this.filterOptions,
      sortOptions: this.currentSort ? [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }] : null,
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  handleSorting(event): void {
    this.currentSort = StudyWorkspaceOutcomeDocumentsSortOptions[event.detail.field];
    this.currentSortDescending = !event.detail.ascending;
    this.currentPage = 1;

    this.postStudyOutcomeDocumentsGrid(this.id, {
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      filterOptions: this.filterOptions,
      sortOptions: [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }],
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  private setDateFilters(event): void{
    event.detail.startDateFilter = this.startDateFilter.value ? this.startDateFilter.value : null;
    event.detail.endDateFilter = this.endDateFilter.value ? this.endDateFilter.value : null;
  }

  private processAllDownloadFlow(
    requests: Observable<any>,
    option?: 'all' | 'selected'
  ): void {
    const documentsToDownload = [];

    requests
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        const mappedDocument = this.outcomeDocumentDetailsModalService
          .mapAllOutcomeSitesData(data);

        documentsToDownload.push(...mappedDocument);

        this.zipService.startDownload(documentsToDownload, this.documentRequestTypes.Outcome, option);
      });
  }

  private getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('siteOutcomeDocuments.fileName'), 'fileNameLink', true),
      this.utilService.createTableHeader(
        this.translatePipe.transform('outcomeDocumentsDetailsModal.outcomeDocumentDateReviewed'),
        'reviewedDate',
        true,
        'date',
        this.utilService.dateFormat
      ),
      this.utilService.createTableHeader(
        this.translatePipe.transform('outcomeDocumentsDetailsModal.outcomeDocumentDateTransmitted'),
        'transmittedDate',
        true,
        'date',
        this.utilService.dateFormat
      ),
      this.utilService.createTableHeader(this.translatePipe.transform('siteOutcomeDocuments.documentType'), 'documentType', true),
      this.utilService.createTableHeader(
        this.translatePipe.transform('outcomeDocumentsDetailsModal.outcomeDocumentReviewDetails'), 'reviewDetailsLink', false
      )
    ];
  }

  private getDocumentsWithSwitcher(): void {
    this.postStudyOutcomeDocumentsGrid(this.id, {
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      filterOptions: this.filterOptions,
      sortOptions: this.currentSort ? [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }] : null,
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  private postStudyOutcomeDocumentsGrid(id, body): void {
    this.loading = true;
    this.gridFilters = body;
    this.selected = [];

    this.apiService.getStudyOutcome(id, body)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (data.records && data.records.length > 0) {
          this.initialDocuments = data.records;
        }

        this.documents = this.studyService.mapOutcomeDocumentsData(data.records);
        this.documentsFetched = true;
        this.tableData = this.documents;
        this.loaded = true;
        this.loading = false;
        this.totalRecords = data.totalRecords;
        this.totalPages = Math.ceil(data.totalRecords / data.pageSize);
        this.currentPage = data.currentPage;
      }, (error) => {
        this.loaded = true;
        this.loading = false;
        this.documentsFetched = true;
        if (error.details.status === ServerErrorCodes.unauthorizedStatusNumber) {
          this.pageUnauthorized = true;
        }
        else {
          this.toastService.add([{
            closable: true,
            id: 'getWorkspaceOutcome',
            message: this.translatePipe.transform('serverErrors.internalServerError'),
            variant: 'error'
          }]);
        }
      });
  }
}
