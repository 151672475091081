import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpCancelService {
  private pendingPostAllDashboardSubmissions$ = new Subject<void>();
  private pendingPostSubmissionsDashboardDrafts$ = new Subject<void>();
  private pendingPostSubmissionsDashboardInProgress$ = new Subject<void>();
  private pendingPostSubmissionsDashboardNeedsAction$ = new Subject<void>();
  private pendingPostStudySubmissionDetails$ = new Subject<void>();
  private pendingPostSiteSubmissionDetails$ = new Subject<void>();
  private pendingPostAdminUsers$ = new Subject<void>();

  constructor() { }

  public cancelPostAllDashboardSubmissions(): void {
    this.pendingPostAllDashboardSubmissions$.next();
  }

  public onCancelPostAllDashboardSubmissions(): any {
    return this.pendingPostAllDashboardSubmissions$.asObservable();
  }

  public cancelPostSubmissionsDashboardDrafts(): void {
    this.pendingPostSubmissionsDashboardDrafts$.next();
  }

  public onCancelPostSubmissionsDashboardDrafts(): any {
    return this.pendingPostSubmissionsDashboardDrafts$.asObservable();
  }

  public cancelPostSubmissionsDashboardInProgress(): void {
    this.pendingPostSubmissionsDashboardInProgress$.next();
  }

  public onCancelPostSubmissionsDashboardInProgress(): any {
    return this.pendingPostSubmissionsDashboardInProgress$.asObservable();
  }

  public cancelPostSubmissionsDashboardNeedsAction(): void {
    this.pendingPostSubmissionsDashboardNeedsAction$.next();
  }

  public onCancelPostSubmissionsDashboardNeedsAction(): any {
    return this.pendingPostSubmissionsDashboardNeedsAction$.asObservable();
  }

  public cancelPostStudySubmissionDetails(): void {
    this.pendingPostStudySubmissionDetails$.next();
  }

  public onCancelPostStudySubmissionDetails(): any {
    return this.pendingPostStudySubmissionDetails$.asObservable();
  }

  public cancelPostSiteSubmissionDetails(): void{
    this.pendingPostSiteSubmissionDetails$.next();
  }

  public onCancelPostSiteSubmissionDetails(): any {
    return this.pendingPostSiteSubmissionDetails$.asObservable();
  }

  public cancelPostAdminUsers(): void{
    this.pendingPostAdminUsers$.next();
  }

  public onCancelPostAdminUsers(): any {
    return this.pendingPostAdminUsers$.asObservable();
  }
}
