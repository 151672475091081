import { AbstractControl, ValidationErrors } from '@angular/forms';
import { regexRules, RegexTags } from '../common/collections';

export const numericValidator =
  (maxValue: number, minValue: number = 0) => (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;
    if (!value) {
      return null;
    }

    const [numericRegexRole] = regexRules
      .filter((rule) => rule.tag === RegexTags.NumericRegex);

    const isNumeric = new RegExp(numericRegexRole.rule).test(value);
    if (!isNumeric) {
      return {
        pattern: true
      };
    }

    if (Number(value) < minValue) {
      return {
        min: true
      };
    }

    if (Number(value) > maxValue) {
      return {
        max: true
      };
    }

    return null;
  };
