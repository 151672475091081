export class BaseModel {
  id: number;
}

export class UserInfo {
  externalReferenceId: string;
  email: string;
  firstName: string;
  lastName: string;
  userName: string;
  lastActivityDate: Date;
  createdDateTimeOffset: Date;
  organizationName: string;
  organizationType: string;
  isActive: boolean;
  isDeleted: boolean;
  canBeImpersonated: boolean;
}


export interface UserAutocompleteMap {
  email: string;
  option: string;
  value: string;
}

export class OrganizationModel {
  id: string;
  name: string;
  organizationType: OrganizationTypeModel;
  phone: string;
}

export class OrganizationTypeModel extends BaseModel {
  value: string;
}

export interface UserInfoMap {
  email: string;
  firstName: string;
  lastName: string;
  lastActivityDate: string;
  createdDateTimeOffset: string;
  organizationName: string;
  organizationType: string;
  enabled: string;
  isDeleted: boolean;
  isActive: boolean;
  id: string;
  canBeImpersonated: boolean;
}

export interface UserInfoGrid {
  currentPage: number;
  totalRecords: number;
  pageSize: number;
  records: Array<UserInfo>;
}
export interface UserInfoGridHighightData {
  searchTerm: string;
  startDate: Date;
  endDate: Date;
}
export class UserStatus {
  public static readonly userActive = new UserStatus('1', 'Active');
  public static readonly userInactive = new UserStatus('0', 'Inactive');

  private constructor(public readonly id: string, public readonly displayName: string) {
  }
}
export interface UserAuditGrid {
  currentPage: number;
  totalRecords: number;
  pageSize: number;
  records: Array<UserAudit>;
}

export interface UserAudit {
  irisId: string;
  siteName: string;
  permissionType: string;
  auditReason: string;
  lastModifiedBy: string;
  lastModifiedDateTimeOffset: Date;
  downloadedDocs: string;
}

export interface UserAuditMap {
  entityId: string;
  siteName: string;
  accessLevel: string;
  auditAction: string;
  modifiedBy: string;
  modifiedDate: string;
  downloadDocs: string;
}
