import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { BarChartOption } from '../../interfaces/components';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit, OnChanges {
  @Input() active: string;
  @Input() data: Array<BarChartOption> = [];

  @Output() barClicked: EventEmitter<BarChartOption> = new EventEmitter();

  public buttonStates: boolean[];
  public percents: number[];

  ngOnInit(): void {
    this.setup(this.data);
  }

  ngOnChanges(): void {
    this.setup(this.data);
  }

  getPercents = (data): number[] => {
    if (!data || !data.length) {
      return;
    }

    const PERCENT = 100;

    const total: {value: number} = data.reduce((accumulator, currentValue) => {
      if (!accumulator.value) {
        return {
          value: currentValue.value
        };
      }

      if (!currentValue.value) {
        return {
          value: accumulator.value
        };
      }

      return {
        value: accumulator.value + currentValue.value
      };
    });

    const percents = data.map((item) => item.value ? item.value / total.value * PERCENT : 0);

    return percents;
  }

  handleClick(event, idx): void {
    this.buttonStates = new Array(this.data.length);
    this.buttonStates[idx] = true;

    this.barClicked.emit(event);
  }

  private setup(data): void {
    if (!this.data) {
      return;
    }

    this.buttonStates = new Array(data.length);
    this.buttonStates[0] = true;
    this.percents = this.getPercents(data);
  }
}
