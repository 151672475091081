/* eslint-disable max-lines, max-lines-per-function, max-len, complexity */
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { debounce, takeUntil } from 'rxjs/operators';
import {
  DEBOUNCE_TIME, EMAIL_LENGTH, flatPickrDateFormat, TeamMembersSortOptions,
  TeamMembersFilterOptions,
  PermissionTypeViewValues,
  PermissionType
} from 'src/app/common/collections';
import { BaseComponent } from 'src/app/components/base/base.component';
import { TableHeader } from 'src/app/interfaces/components';
import { TeamMembersGrid, TeamMembersMap } from 'src/app/interfaces/teams';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';
import { UtilService } from 'src/app/services/util.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
import * as moment from 'moment';
import { TeamMembersService } from './team-members.service';
import { TeamService } from '../team.service';
import { IdValueModel, TeamOrganizationUserCreateModel } from 'src/app/models/teams.model';
import { UserService } from 'src/app/services/user.service';
import { UserInfoDetailsMap } from 'src/app/interfaces/user';

@Component({
  selector: 'app-team-members-temp',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.scss']
})
export class TeamMembersComponent extends BaseComponent implements OnInit {
  @ViewChild('startDatePicker') startDatePicker;
  @ViewChild('endDatePicker') endDatePicker;
  public organizationId: string;
  addTeamMemeberModal = false;
  teamMembersForm: FormGroup;
  allTeamUsers: any = [];
  currentPage = 1;
  currentPageSize = 10;
  currentSort: string = TeamMembersSortOptions.name;
  currentSortDescending: boolean;
  totalRecords: number;
  addTeamMemberFailed = false;
  addTeamMemberFailedMessage = '';
  isSaving = false;
  tableData: Array<TeamMembersMap>;
  loaded: boolean;
  loading: boolean;
  id: string;
  searchTerm = new FormControl('');
  documentsFetched = false;
  pageUnauthorized = false;
  deleteStarted = false;
  headers: Array<TableHeader>;
  startDateFilter = new FormControl('');
  endDateFilter = new FormControl('');
  filterOptions: any[] = [];
  startDateUTC: any = null;
  endDateUTC: any = null;
  currentTab: string;
  options = {
    manualPaginationAndSort: true,
    rowsPerPage: 10,
    rowSelection: true
  }

  datePickerOptions: FlatpickrOptions = {
    dateFormat: flatPickrDateFormat
  };

  selectedRows: Array<TeamMembersMap> = [];
  hasSelectedRows = false;
  showPrimaryUserSelectedModal = false;
  showDeleteModal = false;
  initialTeamMembers: TeamMembersGrid;
  teamMembersFetched = false;
  isTeamActive = true;
  teamPermissionRadio = 'View';
  workspacePermissionOptions: IdValueModel[] = [];
  canManageUsers = false;
  isAdmin = false;
  hasError = false;
  editModeRows = [];
  currentUser: UserInfoDetailsMap;

  constructor(private teamService: TeamService, private router: Router, private formBuilder: FormBuilder,
    public utilService: UtilService, private apiService: ApiService, private teamMembersService: TeamMembersService,
    private toastService: ToastService, private translatePipe: TranslatePipe, private userService: UserService) {
    super();
  }

  ngOnInit(): void {
    this.workspacePermissionOptions = [
      {
        id: PermissionType.View,
        value: PermissionTypeViewValues.View
      },
      {
        id: PermissionType.Edit,
        value: PermissionTypeViewValues.Edit
      },
      {
        id: PermissionType.Manage,
        value: PermissionTypeViewValues.Manage
      }
    ];

    this.initializeForm();
    this.teamUserAutocompleteChange();
    const [id] = this.router.url.split('/teams/')[1].split('/');
    this.organizationId = id;
    this.headers = this.getHeaders();
    this.currentSortDescending = false;
    this.teamService.reloadTeamUserGrid.subscribe((res) => {
      if (res) {
        this.initialTeamMembers.records = [];
        this.teamMembersFetched = false;
        this.postDefaultGrid();
        this.teamService.reloadTeamUserGrid.next(false);
      }
    });
    this.isTeamActive = this.teamService.isTeamActive.value;
    this.teamService.isTeamActive.subscribe((val) => {
      this.isTeamActive = val;
    });
    this.postDefaultGrid();
    this.searchTerm.valueChanges
      .pipe(
        debounce(() => timer(DEBOUNCE_TIME))
      )
      .subscribe((val) => {
        this.teamMembersService.searchTerm.next(val);
        this.closeEditMode();
        this.postTeamMembersGrid({
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: val,
          sortOptions: [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }],
          filterOptions: this.filterOptions,
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });
      });
    this.startDateFilterOptions();
    this.endDateFilterOptions();
    this.allTeamUsers = [];
    this.teamService.hasManagePermission.subscribe((val) => {
      this.canManageUsers = val;
      this.options.rowSelection = this.canManageUsers || this.isAdmin;
    });

    this.teamService.isAdmin.subscribe((val) => {
      this.isAdmin = val;
      this.options.rowSelection = this.canManageUsers || this.isAdmin;
    });

    this.userService.userInfo.subscribe((val) => {
      this.currentUser = val;
    });
  }

  addFilterOptions(): void {
    if (this.startDateFilter.value[0] || this.endDateFilter.value[0]) {
      if (!this.filterOptions.length) {
        const filterOption = [
          {
            field: TeamMembersFilterOptions.joinedDate
          }
        ];
        this.filterOptions = filterOption;
      }
    }
    else {
      this.filterOptions = [];
    }
  }

  get teamMemebersFormGroup(): any {
    return this.teamMembersForm.controls;
  }

  get teamMembersFromArray(): FormArray {
    return this.teamMemebersFormGroup.teamMembers as FormArray;
  }

  public changeRadio(event): void {
    this.teamPermissionRadio = event.target.value;
  }

  addTeamMember(): void {
    this.initializeForm();
    this.teamUserAutocompleteChange();
    this.addTeamMemberFailed = false;
    this.addTeamMemberFailedMessage = '';
    this.addTeamMemeberModal = true;
    this.allTeamUsers = [];
  }

  public initializeForm(): void {
    this.teamMembersForm = this.formBuilder.group({
      workspacePermission: ['', [Validators.compose([
        Validators.required,
        Validators.min(1)
      ])]],
      teamMember: [{
        value: '',
        selectedValues: []
      }, [
        (control): any => {
          if (control.value?.selectedValues?.length > 0) {
            return;
          }

          return {
            validateSelectedUser: {
              valid: false
            }
          };
        }
      ]]
    });
  }

  teamUserAutocompleteChange(): void {
    this.teamMembersForm.controls.teamMember.valueChanges.pipe(
      debounce(() => timer(DEBOUNCE_TIME))
    ).subscribe((val) => {
      this.hasError = false;

      const isMultiple = val?.value?.includes(',') || val?.value?.includes(';');

      if (isMultiple) {
        this.setMultipleUsers(val);
      }

      this.validateEmail(val);

      if (this.hasError) {
        this.teamMembersForm.controls.teamMember.setValue(val);
        this.hasError = false;
      }

      if (val?.value?.length > 2 && !isMultiple && this.isAdmin) {
        const searchValue = val.value.substring(0, EMAIL_LENGTH);
        this.getUsers(searchValue);
      }
    });
  }

  closeClick(): void {
    this.addTeamMemeberModal = false;
    this.initialTeamMembers.records = [];
    this.teamMembersFetched = false;
    this.searchTerm.reset();
    this.currentSort = TeamMembersSortOptions.name;
    this.currentSortDescending = false;
    this.startDateUTC = null;
    this.endDateUTC = null;
    this.teamMembersService.startDate.next(null);
    this.teamMembersService.endDate.next(null);
    this.postTeamMembersGrid({
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: '',
      sortOptions: [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }]
    });
  }

  get hasinvalidEmails(): boolean {
    const memberControle = this.teamMembersForm.value.teamMember;
    if (memberControle) {
      const invalidEmails = memberControle.selectedValues.filter((item) => item.error);

      return invalidEmails.length > 0;
    }

    return false;
  }

  saveTeamMembers(event, closeModal: boolean): void {
    event.preventDefault();
    this.isSaving = true;
    this.addTeamMemberFailed = false;
    if (this.teamMembersForm.valid) {
      const data = this.getOrganizationUserData();
      const emailList = data.map((item) => item.email);
      const duplicate = emailList.filter((item, index) => emailList.indexOf(item) !== index);
      if (duplicate.length === 0) {
        this.apiService.postTeamOrganizationUsers(this.organizationId, data).subscribe((invalidEmails) => {
          this.addTeamMemberFailed = false;
          this.addTeamMemberFailedMessage = '';
          this.isSaving = false;
          this.allTeamUsers = [];

          if (invalidEmails !== null && invalidEmails.length > 0) {
            this.teamMembersForm.controls.teamMember.setValue({
              value: '',
              selectedValues: [
                ...invalidEmails.map((email) => ({
                  option: email.trim(),
                  value: email.trim(),
                  error: true
                }))
              ]
            });
          }
          else {
            // eslint-disable-next-line no-lonely-if
            if (closeModal) {
              this.closeClick();
            }
            else {
              this.resetForm();
            }
          }
        }, (err) => {
          this.addTeamMemberFailedMessage = err?.error?.message;
          this.addTeamMemberFailed = true;
          this.isSaving = false;
          this.allTeamUsers = [];
        });
      }
      else {
        this.isSaving = false;
        this.addTeamMemberFailed = true;
        this.addTeamMemberFailedMessage = 'Duplicate users are added.';
        this.allTeamUsers = [];
      }
    }
    else {
      this.isSaving = false;
      this.allTeamUsers = [];
    }
  }

  handleworkspacePermissionChange(event): void {
    event.preventDefault();
    this.teamMembersForm.patchValue({ teamPermission: event.target.value });
  }

  deleteUser(event, index): void {
    event.preventDefault();
    this.teamMembersFromArray.removeAt(index);
    this.allTeamUsers.splice(index, 1);
  }

  handlePageChange(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;
    this.closeEditMode();
    this.postTeamMembersGrid({
      page: event.detail.page,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }],
      filterOptions: this.filterOptions,
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  handleSort(event): void {
    this.currentSort = TeamMembersSortOptions[event.detail.field];
    this.currentSortDescending = !event.detail.ascending;
    this.closeEditMode();
    this.postTeamMembersGrid({
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: [{
        field: TeamMembersSortOptions[event.detail.field],
        isDescending: this.currentSortDescending
      }],
      filterOptions: this.filterOptions,
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  handleRowClicked(event): void {
    const row = event.detail.event.currentTarget;
    const composedPath = event.detail.event.composedPath && event.detail.event.composedPath();
    const eventPath = event.detail.event.path || composedPath;
    const [workspacePermission, teamPermission] = row.querySelectorAll('select');

    // Enable permissions change
    if (eventPath.find((path) => path.attributes && path.attributes.getNamedItem('data-edit-row-button'))) {
      row.classList.add('edit-mode');
      this.editModeRows.push(row);
    }

    // Cancel permissions change
    if (eventPath.find((path) => path.attributes && path.attributes.getNamedItem('data-cancel-button'))) {
      row.classList.remove('edit-mode');

      // Reset selected dropdown values
      workspacePermission.value = event.detail.workspacePermissionTypeValue;
      teamPermission.value = event.detail.permissionTypeValue;
    }

    // Submit permissions change
    if (eventPath.find((path) => path.attributes && path.attributes.getNamedItem('data-submit-button'))) {
      row.classList.remove('edit-mode');

      const data = {
        email: event.detail.userEmail,
        permissionType: teamPermission.value,
        workspacePermissionType: workspacePermission.value,
        isRetry: true
      } as TeamOrganizationUserCreateModel;

      this.loading = true;
      this.apiService.postTeamOrganizationUsers(this.organizationId, [data]).subscribe(() => {
        this.loading = false;
        if (this.currentUser.externalReferenceId === event.detail.id && PermissionType[data.permissionType] !== PermissionType[PermissionType.Manage]) {
          // User removes their own manage team permission
          this.teamService.hasManagePermission.next(false);
          this.closeEditMode();
        }

        this.postTeamMembersGrid({
          page: this.currentPage,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          sortOptions: [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }],
          filterOptions: this.filterOptions,
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });

        this.toastService.add([{
          closable: true,
          id: 'editPermissionsSuccessful',
          message: this.translatePipe.transform('teams.editPermissionsSuccessful'),
          timeout: this.toastService.ERROR_TIMEOUT,
          variant: 'success'
        }]);
      }, () => {
        this.loaded = true;
        this.loading = false;
        this.toastService.add([{
          closable: true,
          id: 'editPermissionsFailure',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          timeout: this.toastService.ERROR_TIMEOUT,
          variant: 'error'
        }]);
      });
    }

    // Retry failed request
    if (eventPath.find((path) => path.attributes && path.attributes.getNamedItem('retry-request'))) {
      // Disable retry requests for users without permissions
      if (!this.canManageUsers && !this.isAdmin) {
        return;
      }

      const data = {
        email: event.detail.userEmail,
        permissionType: teamPermission.value,
        workspacePermissionType: workspacePermission.value,
        isRetry: true
      } as TeamOrganizationUserCreateModel;

      this.retryRequest(data);
    }
  }

  handleRowSelection(event): void {
    this.selectedRows = event.detail;
    if (this.selectedRows.length > 0) {
      this.hasSelectedRows = true;
    }
    else {
      this.hasSelectedRows = false;
    }
  }

  deleteSelectedRows(): void {
    if (this.selectedRows && this.selectedRows.length) {
      const isPrimarySelected = this.selectedRows.find((user) => user?.isPrimary === true)?.isPrimary;

      if (isPrimarySelected) {
        this.showPrimaryUserSelectedModal = true;
      }
      else {
        this.showDeleteModal = true;
      }
    }
  }

  closeDeleteModal(): void {
    this.showDeleteModal = false;
    this.searchTerm.reset();
    this.currentSort = TeamMembersSortOptions.name;
    this.currentSortDescending = false;
    this.startDateUTC = null;
    this.endDateUTC = null;
    this.teamMembersService.startDate.next(null);
    this.teamMembersService.endDate.next(null);

    this.postTeamMembersGrid({
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: '',
      sortOptions: [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }],
      filterOptions: this.filterOptions,
      startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
      endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
    });
  }

  deleteSelectedUsers(): void {
    const userEmails = [];
    if (this.selectedRows && this.selectedRows.length) {
      this.selectedRows.forEach((user) => {
        userEmails.push(user.userEmail);
      });

      if (userEmails.length > 0) {
        this.deleteStarted = true;
        this.apiService.deleteTeamUsers(this.organizationId, userEmails)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => {
            if (userEmails.includes(this.currentUser.email)) {
              this.router.navigate(['/']);
            }
            else {
              this.teamService.reloadTeamDetails.next(true);
              this.closeDeleteModal();
              this.initialTeamMembers.records = [];
              this.teamMembersFetched = false;
              this.deleteStarted = false;
            }
            this.deleteStarted = false;
            this.toastService.add([{
              closable: true,
              id: 'deleteUserSuccessful',
              message: this.translatePipe.transform('teams.deleteUserSuccessful'),
              timeout: this.toastService.ERROR_TIMEOUT,
              variant: 'success'
            }]);
          }, () => {
            this.closeDeleteModal();
            this.deleteStarted = false;
            this.toastService.add([{
              closable: true,
              id: 'deleteUserFailure',
              message: this.translatePipe.transform('serverErrors.internalServerError'),
              timeout: this.toastService.ERROR_TIMEOUT,
              variant: 'error'
            }]);
          });
      }
    }
  }

  closePrimaryErrorModal(): void {
    this.showPrimaryUserSelectedModal = false;
  }

  startDateFilterOptions(): void {
    this.startDateFilter.valueChanges
      .subscribe((val) => {
        this.jumpStartDates(val[0]);
        [this.endDatePicker.flatpickr.config.minDate] = val;

        this.teamMembersService.startDate.next(val[0]);
        if (val[0]) {
          this.startDateUTC = moment(val[0]).utc(false);
        }

        this.addFilterOptions();
        this.closeEditMode();
        this.postTeamMembersGrid({
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          sortOptions: [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }],
          filterOptions: this.filterOptions,
          startDate: val[0] ? this.startDateUTC : null,
          endDate: this.endDateFilter.value[0] ? this.endDateUTC : null
        });
      });
  }

  endDateFilterOptions(): void {
    this.endDateFilter.valueChanges
      .subscribe((val) => {
        this.jumpEndDates(val[0]);
        [this.startDatePicker.flatpickr.config.maxDate] = val;

        this.teamMembersService.endDate.next(val[0]);
        if (val[0]) {
          this.endDateUTC = moment(val[0]).utc(false);
        }

        this.addFilterOptions();
        this.closeEditMode();
        this.postTeamMembersGrid({
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: this.searchTerm.value,
          sortOptions: [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }],
          filterOptions: this.filterOptions,
          startDate: this.startDateFilter.value[0] ? this.startDateUTC : null,
          endDate: val[0] ? this.endDateUTC : null
        });
      });
  }

  toggleEndDate(): void {
    this.endDatePicker.flatpickr.toggle();
  }

  toggleStartDate(): void {
    this.startDatePicker.flatpickr.toggle();
  }

  jumpStartDates(value: any): void {
    if (value) {
      if (!this.endDateFilter.value[0]) {
        this.endDatePicker.flatpickr.jumpToDate(value);
      }
    }
    else if (this.endDateFilter.value[0]) {
      this.startDatePicker.flatpickr.jumpToDate(this.endDateFilter.value[0]);
    }
    else {
      this.endDatePicker.flatpickr.jumpToDate(new Date());
    }
  }

  jumpEndDates(value: any): void {
    if (value) {
      if (!this.startDateFilter.value[0]) {
        this.startDatePicker.flatpickr.jumpToDate(value);
      }
    }
    else if (this.startDateFilter.value[0]) {
      this.endDatePicker.flatpickr.jumpToDate(this.startDateFilter.value[0]);
    }
    else {
      this.startDatePicker.flatpickr.jumpToDate(new Date());
    }
  }

  private getUsers(filter): void {
    this.apiService.getPrimaryUsers(filter, null, true, true)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.allTeamUsers = this.teamService.mapUsers(data);
      });
  }

  private resetForm(): void {
    this.initializeForm();
    this.teamUserAutocompleteChange();
    this.teamPermissionRadio = 'View';
    this.hasError = false;
    this.allTeamUsers = [];
  }

  private setMultipleUsers(val): void {
    this.teamMembersForm.controls.teamMember.setValue({
      value: '',
      selectedValues: [
        ...this.teamMembersForm.controls.teamMember.value.selectedValues,
        ...val.value.split(/,|;/).filter(Boolean).map((email) => ({
          option: email.trim(),
          value: email.trim()

        }))
      ]
    });
  }

  private isValid = (email): boolean => {
    // eslint-disable-next-line prefer-named-capture-group, max-len
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(String(email).toLowerCase());
  }

  private validateEmail(val): void {
    if (val?.selectedValues?.length) {
      val.selectedValues.forEach((value) => {
        if (!value.error && !this.isValid(value.email || value.option)) {
          value.error = true;
          this.hasError = true;
        }
      });
    }
  }

  private getOrganizationUserData(): TeamOrganizationUserCreateModel[] {
    const data = this.teamMembersForm.value;
    const teamUsers: TeamOrganizationUserCreateModel[] = [];
    const workspacePermission = PermissionType[data.workspacePermission] as any;

    this.teamMembersForm.controls.teamMember.value.selectedValues.forEach((element) => {
      const email = element.email || element.value;
      teamUsers.push({
        email: email,
        permissionType: this.teamPermissionRadio as any,
        workspacePermissionType: workspacePermission,
        isRetry: false
      });
    });

    return teamUsers;
  }

  private getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('teamMembers.name'), 'name', true),
      this.utilService.createTableHeader(this.translatePipe.transform('teamMembers.email'), 'email', true),
      this.utilService.createTableHeader(this.translatePipe.transform('teamMembers.organizationName'), 'organizationName', true),
      this.utilService.createTableHeader(this.translatePipe.transform('teamMembers.joinedDate'), 'joinedDate', true),
      this.utilService.createTableHeader(this.translatePipe.transform('teamMembers.workspacePermissionType'), 'workspacePermissionType', true),
      this.utilService.createTableHeader(this.translatePipe.transform('teamMembers.permissionType'), 'permissionType', true),
      this.utilService.createTableHeader(this.translatePipe.transform('teams.requestStatus'), 'requestStatus', true),
      this.utilService.createTableHeader('', 'edit', false)
    ];
  }

  private postDefaultGrid(): void {
    this.teamMembersService.searchTerm.next(null);
    this.teamMembersService.startDate.next(null);
    this.teamMembersService.endDate.next(null);
    this.postTeamMembersGrid({
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: '',
      sortOptions: [{
        field: TeamMembersSortOptions.name,
        isDescending: false
      }]
    });
  }

  private postTeamMembersGrid(body): void {
    this.loading = true;
    this.hasSelectedRows = false;
    this.apiService.postTeamMembersGrid(this.organizationId, body)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: TeamMembersGrid) => {
        if (!this.initialTeamMembers?.records?.length) {
          this.initialTeamMembers = data;
        }
        this.teamService.teamMembers.next(data);
        this.loaded = true;
        this.loading = false;
        this.totalRecords = data.totalRecords;
        this.tableData = this.teamMembersService.mapMembersGridData(data);
        this.currentPage = data.currentPage;
        this.teamMembersFetched = true;
      }, () => {
        this.loaded = true;
        this.loading = false;
        this.teamMembersFetched = true;
        this.toastService.add([{
          closable: true,
          id: 'postTeamMembersFailure',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          timeout: this.toastService.ERROR_TIMEOUT,
          variant: 'error'
        }]);
      });
  }

  private retryRequest(data: TeamOrganizationUserCreateModel): void {
    this.loading = true;

    this.apiService.postTeamOrganizationUsers(this.organizationId, [data]).subscribe(() => {
      this.searchTerm.reset();
      this.currentSort = TeamMembersSortOptions.name;
      this.currentSortDescending = false;
      this.startDateUTC = null;
      this.endDateUTC = null;
      this.teamMembersService.startDate.next(null);
      this.teamMembersService.endDate.next(null);
      this.loading = false;
      this.postDefaultGrid();

      this.toastService.add([{
        closable: true,
        id: 'retryRequestSuccessful',
        message: this.translatePipe.transform('teams.retryRequestSuccessful'),
        timeout: this.toastService.ERROR_TIMEOUT,
        variant: 'success'
      }]);
    }, () => {
      this.loaded = true;
      this.loading = false;
      this.toastService.add([{
        closable: true,
        id: 'retryRequestFailure',
        message: this.translatePipe.transform('serverErrors.internalServerError'),
        timeout: this.toastService.ERROR_TIMEOUT,
        variant: 'error'
      }]);
    });
  }

  private closeEditMode(): void {
    this.editModeRows.forEach((row) => {
      row.classList.remove('edit-mode');
    });
    this.editModeRows = [];
  }
}
