<app-bar-chart *ngIf="!dashboardService.cardView.value" [data]="barChartData" [active]="this.dashboardService.statusFilterInProgress.value || 'all'" (barClicked)="filter($event)"></app-bar-chart>

<app-submission-list [submissions]="records" [card]="dashboardService.cardView.value"></app-submission-list>

<app-no-records *ngIf="dashboardService.inProgressLoaded.value && !dashboardService.inProgressLoading.value && !inProgressData?.submissions.records?.length && dashboardService.searchTerm.value">
  <p class="wcg-h2">{{ 'dashboard.noSubmissionsTitle' | translate }}</p>

  <p>
    {{ 'dashboard.noSubmissionsSubTitle' | translate }}

    <a class="wcg-link" [routerLink]="['/submissions']">{{ 'dashboard.submissionPage' | translate }}</a>
  </p>
</app-no-records>

<app-no-records *ngIf="dashboardService.inProgressLoaded.value && !dashboardService.inProgressLoading.value && !inProgressData?.submissions.records?.length && !dashboardService.searchTerm.value">
  <p class="wcg-h2">{{ 'dashboard.noNeedsActionTitle' | translate }}</p>

  <p>
    <a class="wcg-link" routerLink="/submission-workflow">{{ 'dashboard.makeASubmission' | translate }}</a>
    {{ 'dashboard.or' | translate }}
    <button class="wcg-link" (click)="stateService.showRequestAccessModal.next(true)">{{ 'requestAccess.title' | translate }}</button>
    {{ 'dashboard.noNeedsActionSubTitle' | translate }}
  </p>
</app-no-records>

<div class="cnxs-pagination-wrap">
  <wcg-pagination *ngIf="dashboardService.inProgressLoaded && records?.length" 
    [pageSizeDataSource]="pageSizeOptions.pageSizeDataSource"
    [current]="pageSizeOptions.page" [pageSize]="pageSizeOptions.pageSize" 
    [max]="dashboardService.maxPages(inProgressData?.submissions)" 
    (changePage)="handlePageChange($event)"></wcg-pagination>
</div>

<wcg-loader [show]="!dashboardService.inProgressLoaded.value || dashboardService.inProgressLoading.value"></wcg-loader>
