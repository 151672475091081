import { Component, Input } from '@angular/core';
import { SubmissionInfo } from '../../../interfaces/submissions';
import { DashboardService } from '../../../views/dashboard/dashboard.service';
import { FormControl } from '@angular/forms';
import { SubmissionSelectedSite } from 'src/app/models/submission.model';
import { UtilService } from 'src/app/services/util.service';
import { TableHeader } from 'src/app/interfaces/components';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-submission-list',
  templateUrl: './submission-list.component.html',
  styleUrls: ['./submission-list.component.scss']
})

export class SubmissionListComponent {
  @Input() card: boolean;
  @Input() submissions: Array<SubmissionInfo>;

  showModal = false;
  submissionIdToBeDeleted: string;
  showPIModal = false;
  searchTerm = new FormControl('');
  piTableheaders: Array<TableHeader>;
  filteredPIs: Array<SubmissionSelectedSite>;
  submission: SubmissionInfo;
  submissionId: string;

  tableOptions = {
    rowsPerPage: 10
  };

  constructor(public dashboardService: DashboardService,
    private utilService: UtilService,
    private translatePipe: TranslatePipe) {
    this.piTableheaders = this.getTableHeaders();
  }

  deleteSubmission(submissionId): void {
    this.submissionIdToBeDeleted = submissionId;
    this.showModal = true;
  }

  onViewAllPIsClickedEvent(submission: SubmissionInfo): void {
    if (this.submissionId !== submission.id) {
      this.submissionId = submission.id;
      this.tableOptions.rowsPerPage = 10;
    }
    this.searchTerm.setValue('');
    this.filteredPIs = submission?.principalInvestigators;
    this.filterPITable(submission);
    this.submission = submission;
    this.showPIModal = true;
  }

  private filterPITable(submission: SubmissionInfo): void {
    this.searchTerm.valueChanges.subscribe((term) => {
      this.filteredPIs = this.utilService.filterTableData(
        submission?.principalInvestigators,
        term.trim()
      );
    });
  }

  private getTableHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('tableHeaders.name'), 'investigator', true),
      this.utilService.createTableHeader(this.translatePipe.transform('tableHeaders.organization'), 'piOrganizationName', true),
      this.utilService.createTableHeader(this.translatePipe.transform('tableHeaders.country'), 'countryName', true)
    ];
  }
}
