/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
export const environment = {
  name: '#{name}',
  production: false,
  apiBaseUrl: '#{apiBaseUrl}',
  apiServiceUrl: '#{apiServiceUrl}',
  apiReportingUrl: '#{apiReportingUrl}',
  backOfficeBaseUrl: '#{backOfficeBaseUrl}',
  redirect: '#{redirect}',
  identity: {
    authority: '#{authority}',
    redirect_uri: '#{redirect_uri}',
    client_id: '#{client_id}',
    automaticSilentRenew: true,
    includeIdTokenInSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    silentRequestTimeout: 10000,
    checkSessionInterval: 2000,
    monitorSession: true,
    accessTokenExpiringNotificationTime: 60
  },
  userIdleTime: 3600,
  logoutNoticeTime: 120,
  changePasswordUrl: '#{changePasswordUrl}',
  formio: {
    appUrl: '#{appUrl}',
    apiUrl: '#{apiUrl}',
    apiKey: '#{apiKey}',
    fileToken: '#{fileToken}',
    pdfUrl: '#{pdfUrl}'
  },
  walkMe: {
    snippetUrl: '#{walkMeUrl}'
  },
  talkDesk: {
    touchPointId: '#{talkDeskTouchpointId}',
    region: '#{talkDeskRegion}'
  }
};
