import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { WebApiServices } from '../helpers/webapiservice';
import { AdminApis } from '../helpers/adminapis';
import { GridOptionsSearchModel } from '../model/grid.model';
import { DocumentCategoryGrid } from '../model/documentcategory';
import { IdValueModel } from '../model/sharedmodels';
import { DocumentCategory, DocumentCategoryMap } from 'src/app/admin/shared/model/documentcategory';

@Injectable()
export class DocumentCategoryService {
  service: WebApiServices;

  constructor(private http: HttpClient) {
    this.service = new WebApiServices(this.http);
  }

  public createOrUpdateDocumentTypeCategory(docCategory: DocumentCategory): Observable<any> {
    return this.service.postService(AdminApis.CreateOrUpdateDocumentTypeCategory, docCategory, null);
  }

  public getDocumentTypeCategories(): Observable<IdValueModel[]> {
    return this.service.getService(AdminApis.GetDocumentTypeCategoriesFullList, null);
  }

  public mapSubmissionGridData = (data: DocumentCategoryGrid): Array<DocumentCategoryMap> => data.records.map((record) => ({
    'Document Type Category': `<button class="wcg-link" edit-row-button>${record.value}</button>`,
    Enabled: record.isActive ? '<wcg-icon src="./assets/icons/action/ic_done_24px.svg"></wcg-icon>' : '',
    record: record
  }));

  public postDocumentTypeCategory(pageIndex: number, pageSize: number, searchTerm: string): Observable<any> {
    const parameters = this.getParameters(pageIndex, pageSize, searchTerm);

    return this.service.postService(AdminApis.GetDocumentTypeCategory, parameters, null);
  }

  private getParameters = (pageIndex: number, pageSize: number, searchTerm: string): GridOptionsSearchModel => {
    const gridOptionsModel: GridOptionsSearchModel = {
      page: pageIndex,
      pageSize: pageSize,
      searchTerm: searchTerm,
      filterOptions: null,
      sortOptions: null,
      startDate: null,
      endDate: null
    };

    return gridOptionsModel;
  }
}
