<div class="workflow-intro">
  <div class="wcg-row">
    <div class="wcg-col-xs-4 wcg-col-lg-7">
      <p class="intro-title">{{ title }}</p>
      <p class="intro-heading wcg-h1">{{ heading }}</p>
      <p class="intro-details">{{ details }}</p>
      <ng-content></ng-content>
    </div>
  </div>

  <img class="intro-illustration" src="{{ illustration }}" alt="{{ illustrationDescription }}" />
</div>
