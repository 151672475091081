<div *ngIf="card; else listView">
  <wcg-card class="cnxs-submission-info cnxs-submission-card">
    <div class="cnxs-submission-card-body">
      <ng-container *ngTemplateOutlet="status"></ng-container>
      <ng-container *ngTemplateOutlet="sponsorId"></ng-container>
      <ng-container *ngTemplateOutlet="submissionName"></ng-container>
      <ng-container *ngTemplateOutlet="submissionType"></ng-container>
      <ng-container *ngTemplateOutlet="submissionPrincipalInvestigator"></ng-container>
      <ng-container *ngTemplateOutlet="meta"></ng-container>
    </div>
    <div>
      <ng-container *ngTemplateOutlet="actions"></ng-container>
    </div>
  </wcg-card>
</div>
<ng-template #listView>
  <div class="cnxs-submission-list-item">
    <ng-container *ngTemplateOutlet="flag"></ng-container>

    <div class="wcg-row wcg-top-xs">
      <div class="wcg-col-xs-1 wcg-col-md-2 wcg-col-lg-3">
        <ng-container *ngTemplateOutlet="submissionType"></ng-container>
        <ng-container *ngTemplateOutlet="submissionName"></ng-container>
        <ng-container *ngTemplateOutlet="submissionPrincipalInvestigator"></ng-container>
      </div>
      <div class="wcg-col-xs-1 wcg-col-md-2 wcg-col-lg-2">
        <ng-container *ngTemplateOutlet="sponsorId"></ng-container>
      </div>
      <div class="wcg-col-xs-1 wcg-col-md-2 wcg-col-lg-4">
        <ng-container *ngTemplateOutlet="meta"></ng-container>
      </div>
      <div class="wcg-col-xs-1 wcg-col-md-2 wcg-col-lg-3">
        <ng-container *ngTemplateOutlet="actions"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #flag>
    <span class="cnxs-subsmission-flag wcg-text-highlight" *ngIf="submission?.flag">
      {{submission?.flag}}
    </span>
</ng-template>


<ng-template #status>
  <p class="cnxs-submission-card-header" *ngIf="submission?.status || submission?.flag" [ngClass]="{
    'cnxs-submission-complete': submission?.outcome === submissionsDashboardStatistic.fullyApproved
  }">
    <span class="cnxs-submission-status" *ngIf="submission?.status">
      <wcg-icon *ngIf="submission?.icon" [src]="submission?.icon"
        class="cnxs-status-icon cnxs-status-{{submission?.status | lowercase}}"
        [ngClass]="{
          'cnxs-on-hold': submission?.onHold,
          'cnxs-needs-action': submission?.outcome === submissionsDashboardStatistic.notFullyApproved
        }"></wcg-icon>
      <span class="wcg-h5 cnxs-submission-status-text" [innerHTML]="submission?.status"></span>
    </span>

    <ng-container *ngTemplateOutlet="flag"></ng-container>
  </p>
</ng-template>

<ng-template #sponsorId>
  <div *ngIf="submission?.sponsorId" class="cnxs-sponsor-id">
    <div
      class="wcg-body-label cnxs-submission-meta-title cnxs-card-view-only">
      {{'submissions.protocolNumber' | translate}}
    </div>

    <a *ngIf="submission?.studyId && submission?.submissionTypeGroup !== newStudy" routerLink="/studies/{{submission?.studyId}}"
      class="wcg-h4 cnxs-sponsor-id wcg-link"
      title="{{submission?.sponsorId}}"
      [innerHTML]="submission?.sponsorIdHighlighted || submission?.sponsorId">
    </a>

    <span *ngIf="!submission?.studyId || submission?.submissionTypeGroup === newStudy" class="cnxs-sponsor-id"
      title="{{submission?.sponsorId}}"
      [innerHTML]="submission?.sponsorIdHighlighted || submission?.sponsorId">
    </span>
  </div>
</ng-template>

<ng-template #submissionName>
  <p *ngIf="submission?.submissionName" [line-truncation]="2" [innerHTML]="submission?.submissionNameHighlighted || submission?.submissionName" [title]="submission?.submissionName" class="cnxs-submission-name wcg-h3"></p>
</ng-template>

<ng-template #submissionType>
  <p *ngIf="submission?.submissionType" class="wcg-body-label cnxs-submission-type" [innerHTML]="submission?.submissionType"></p>
</ng-template>

<ng-template #submissionPrincipalInvestigator>
    <span class="cnxs-submission-meta-pi wcg-body-sm" *ngIf="showPIOnCard">
      <span *ngIf="submission?.principalInvestigators?.length === 1" [innerHTML]="submission.principalInvestigatorSingular"></span>
      <span *ngIf="submission?.principalInvestigators?.length > 1" class="cnxs-pi-wrap">
        {{ 'submissionMeta.sites' | translate: { length: submission?.principalInvestigators?.length} }}
          <button class="wcg-link" (click)="onViewAllPIsClickedEvent(submission)">
            <wcg-icon class="cnxs-view-all-icon" src="../../assets/icons/action/ic_launch_24px.svg"></wcg-icon>
            <span [ngClass]="{ 'wcg-text-highlight': submission?.principalInvestigatorsHighlighted }">
              {{ 'submissionMeta.viewAll' | translate }}
            </span>
          </button>
      </span>
    </span>
</ng-template>

<ng-template #meta>
  <div class="cnxs-submission-meta">
    <ng-content select="[meta]"></ng-content>
  </div>
</ng-template>

<ng-template #actions>
  <ng-content select="[actions]"></ng-content>
</ng-template>
