import { Component, OnInit } from '@angular/core';
import { UserInfoDetailsMap } from 'src/app/interfaces/user';
import { UserService } from 'src/app/services/user.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-adminbase',
  templateUrl: './adminbase.component.html',
  styleUrls: ['./adminbase.component.scss']
})
export class AdminBaseComponent implements OnInit {
  userInfo$: Observable<UserInfoDetailsMap>;
  selectedItem: string;
  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.userInfo$ = this.userService.userInfo;
  }

  public listClick(_event, newValue): void {
    this.selectedItem = newValue;
  }
}
