export enum GridExportType {
  ManageSubmissions,
  Submissions,
  SubmissionDetailsSites,
  SubmissionDetailsSubmittedDocuments,
  SubmissionDetailsOutcomeDocuments,
  Studies,
  StudyDetailsSubmissions,
  StudyDetailsSites,
  StudyDetailsOutcomeDocuments,
  StudyDetailsOutcomeDocumentsReview,
  StudyDetailsContacts,
  Sites,
  SiteDetailsSubmissions,
  SiteDetailsContacts,
  SiteDetailsOutcomeDocuments,
  Users,
  UserAccessAudit,
  UserWorkspaces
}

export enum ApiReportingUrl {
  '/api/v1.0/submission/export/manageSubmissions' = GridExportType.ManageSubmissions,
  '/api/v1.0/submission/export/submissions' = GridExportType.Submissions,
  '/api/v1.0/submission/export/{id}/sites' = GridExportType.SubmissionDetailsSites,
  '/api/v1.0/submission/export/{id}/documents' = GridExportType.SubmissionDetailsSubmittedDocuments,
  '/api/v1.0/submission/export/{id}/outcome' = GridExportType.SubmissionDetailsOutcomeDocuments,
  '/api/v1.0/study/export/studies' = GridExportType.Studies,
  '/api/v1.0/study/export/{id}/submissions' = GridExportType.StudyDetailsSubmissions,
  '/api/v1.0/study/export/{id}/sites' = GridExportType.StudyDetailsSites,
  '/api/v1.0/study/export/{id}/outcome' = GridExportType.StudyDetailsOutcomeDocuments,
  '/api/v1.0/study/export/{id}/outcome/{secondId}/details' = GridExportType.StudyDetailsOutcomeDocumentsReview,
  '/api/v1.0/study/export/{id}/contacts' = GridExportType.StudyDetailsContacts,
  '/api/v1.0/site/export/sites' = GridExportType.Sites,
  '/api/v1.0/site/export/{id}/submissions' = GridExportType.SiteDetailsSubmissions,
  '/api/v1.0/site/export/{id}/contacts' = GridExportType.SiteDetailsContacts,
  '/api/v1.0/site/export/{id}/outcome' = GridExportType.SiteDetailsOutcomeDocuments,
  '/api/v1.0/user/export/users' = GridExportType.Users,
  '/api/v1.0/user/export/{id}/audit' = GridExportType.UserAccessAudit,
  '/api/v1.0/workspace/export/workspaces' = GridExportType.UserWorkspaces
}

export enum ExportStatus {
  InProgress = 0,
  Complete = 1,
  Failed = 2
}


