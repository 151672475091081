  <div *ngIf="isAdmin" class="wcg-row wcg-rhythm-2 pl-10">
    <a class="wcg-link back-link" routerLink="/admin/teams" routerLink-active="active">
      <wcg-icon src="../assets/icons/hardware/ic_keyboard_backspace_24px.svg"></wcg-icon>
      <span>Back to Teams List</span>
    </a>
  </div>
  <div *ngIf="teamDetails">
  <div *ngIf="!isAdmin" class="wcg-container">
    <div class = "wcg-row">
      <div class = "wcg-col-md-7"></div>
      <div class = "wcg-col-md-5">
        <div class="float-right">
        <wcg-accordion class="wcg-rhythm-5 team-info-accordion" [open]="openOutcome" (toggle)="openOutcome = !openOutcome">
        <wcg-accordion-trigger>
         <h3 class = "margin-bottom"> {{ 'teams.welcomeToTeam' | translate }}</h3>
        </wcg-accordion-trigger>
      <wcg-accordion-content>
        <h4>{{ 'teams.teamDefinition' | translate }}</h4>
        {{ 'teams.teamDefinitionDetailed' | translate }} 
      <h4>{{ 'teams.teamPermissions' | translate }}</h4>
      {{ 'teams.teamPermissionsDetailed' | translate }}
      <br>
      <br>
        <a class="wcg-link" routerLink="/resources" routerLink-active="active">
          <wcg-icon src="../assets/icons/action/ic_launch_24px.svg"></wcg-icon>
          <span>{{ 'teams.goToPermissionsInfo' | translate }}</span>
        </a>
      </wcg-accordion-content>
      </wcg-accordion>
    </div>
    </div>
    </div>
</div>
    <div class="wcg-container">
      <div class="wcg-row wcg-between-md cnxs-page-header">
        <div class="wcg-col-xs-12 wcg-col-md-12 wcg-col-lg-12">
          <h2 class="wcg-h2 wcg-rhythm-1 cnxs-wrap-content inline-flex">{{teamDetails?.name}}</h2>         
          <div class="action-bar inline-flex pl-10">
            <button  *ngIf="teamDetails.isActive && (isPrimaryUser || isAdmin)" class="wcg-btn wcg-btn-icon inline-flex" (click)="editTeam()" title="Edit">
              <wcg-icon src="../assets/icons/image/ic_edit_24px.svg"></wcg-icon>
            </button>
          </div>      
          <div *ngIf="isAdmin" class="action-bar inline-flex pl-10 float-right">
            <div class="active-toggle">
              <span class="pr-10">{{teamDetails.isActive ? 'Active' : 'Inactive' }}</span>
              <input type="checkbox" id="toggle" class="checkbox" [checked]="teamDetails.isActive" (change)="isActiveStatusChange($event)"/>
              <label for="toggle" class="switch"></label>
            </div>
          </div>
        </div>
      </div>
      <div class="wcg-row wcg-between-md cnxs-page-header">
        <div class="wcg-col-xs-4 wcg-col-md-6 wcg-col-lg-8">
          <div class="wcg-rhythm-1">
            <div class="wcg-row">
              <div class="float-left wcg-col-xs-4 wcg-col-lg-6 cnxs-wrap-content">
                {{primaryAddress?.addressLine1}} {{primaryAddress?.addressLine2}}
              </div>
            </div>
            <div class="wcg-row wcg-rhythm-2">
              <div class="wcg-col-xs-4 wcg-col-lg-12 cnxs-wrap-content">
                {{primaryAddress?.city}},
                {{primaryAddress?.state?.code}}
                {{primaryAddress?.province}}
                {{primaryAddress?.postalCode}},
                {{primaryAddress?.country?.name}}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="wcg-row">       
        <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
          <dt class="wcg-body-label">{{ 'teams.teamEmail' | translate }}</dt>
          <dd class="cnxs-wrap-content">
            <a class="wcg-link pi-links" href="mailto:{{teamDetails?.email}}">
              <wcg-icon src="../assets/icons/communication/ic_email_24px.svg"></wcg-icon>
              {{teamDetails?.email}}
            </a>
          </dd>
        </div>
        <div class="wcg-col-xs-4 wcg-col-sm-1 wcg-col-md-1 wcg-rhythm-3">
          <dt class="wcg-body-label">Team Type</dt>
          <dd class="cnxs-wrap-content">{{teamDetails?.organizationType?.value}}</dd>
        </div>
        <div *ngIf="isAdmin" class="wcg-col-xs-4 wcg-col-sm-1 wcg-col-md-1 wcg-rhythm-3">
          <dt class="wcg-body-label">Company ID</dt>
          <dd class="cnxs-wrap-content">{{teamDetails?.companyId}}</dd>
        </div>
        <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
          <dt class="wcg-body-label">Primary Team Member</dt>
          <dd class="cnxs-wrap-content" *ngIf="teamDetails.primaryUser !== null">
            {{teamDetails?.primaryUser?.lastName}},
            {{teamDetails?.primaryUser?.firstName}}</dd>
        </div>
        <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-rhythm-3">
          <dt class="wcg-body-label">Additional Address</dt>
          <dd class="cnxs-wrap-content" *ngIf="additionalAddressForView.length">
            <div class="wcg-row">
              <div class="float-left wcg-col-xs-12 wcg-col-lg-12 cnxs-wrap-content">
                {{additionalAddressForView[0].addressLine1}} {{additionalAddressForView[0].addressLine2}}
              </div>
            </div>
            <div class="wcg-row ">
              <div class="wcg-col-xs-4 wcg-col-lg-12 cnxs-wrap-content">
                {{additionalAddressForView[0].city}},
                {{additionalAddressForView[0].state?.code}}
                {{additionalAddressForView[0].province}}
                {{additionalAddressForView[0].postalCode}},
                {{additionalAddressForView[0].country?.name}}
              </div>
            </div>
          </dd>
          <div class="wcg-row wcg-rhythm-2" *ngIf="additionalAddressForView.length > 1">
            <button class="wcg-btn wcg-btn-icon inline-flex" (click)="showAdditionAddresses = !showAdditionAddresses"
              title="Edit">
              <span>+{{(additionalAddressForView.length - 1)}} more addresses</span>
              <wcg-icon src="../assets/icons/navigation/ic_chevron_right_24px.svg" *ngIf="!showAdditionAddresses">
              </wcg-icon>
              <wcg-icon src="../assets/icons/navigation/ic_expand_more_24px.svg" *ngIf="showAdditionAddresses">
              </wcg-icon>
            </button>
          </div>
          <div *ngFor="let addr of additionalAddressForView; let i = index">
            <div *ngIf="i>0 && showAdditionAddresses">
              <dt class="wcg-body-label">Additional Address {{(i +1)}}</dt>
              <dd>
                <div class="wcg-row">
                  <div class="float-left wcg-col-xs-12 wcg-col-lg-12 cnxs-wrap-content">
                    {{addr.addressLine1}} {{addr.addressLine2}}
                  </div>
                </div>
                <div class="wcg-row wcg-rhythm-2">
                  <div class="wcg-col-xs-12 wcg-col-lg-12 cnxs-wrap-content">
                    {{addr.city}},
                    {{addr.state?.code}}
                    {{addr.province}}
                    {{addr.postalCode}},
                    {{addr.country?.name}}
                  </div>
                </div>
              </dd>
            </div>
          </div>
        </div>
      </div>
    </div>
<section class="cnxs-secondary-section">
  <div class="wcg-container cnxs-loader-container">
    <wcg-tab-bar-section>
      <wcg-tab-bar>
        <a [routerLink]="['teamStudies']" routerLinkActive="wcg-active" class="wcg-link">
          Joined Studies
          <wcg-badge *ngIf="teamService.joinedStudies.value?.totalRecords">{{teamService.joinedStudies.value?.totalRecords}}</wcg-badge>
        </a>
        <a [routerLink]="['teamSites']" routerLinkActive="wcg-active" class="wcg-link">
          Joined Sites
          <wcg-badge *ngIf="teamService.joinedSites.value?.totalRecords">{{teamService.joinedSites.value?.totalRecords}}</wcg-badge>
        </a>
        <a [routerLink]="['teamMembers']" routerLinkActive="wcg-active" class="wcg-link">
          Team Members
          <wcg-badge *ngIf="teamService.teamMembers.value?.totalRecords">{{teamService.teamMembers.value?.totalRecords}}</wcg-badge>
        </a>
      </wcg-tab-bar>
      <div>
        <router-outlet></router-outlet>
      </div>
    </wcg-tab-bar-section>
    <div class="team-created-details">
      <span> Team Created On {{teamDetails?.createdDate | date:'dd-MMM-yyyy' | uppercase}} </span>
      <span> by <b>{{teamDetails?.createdBy}}</b></span>
      <span *ngIf="teamDetails.modifiedDate"> <b> |</b> Updated On {{teamDetails?.modifiedDate | date:'dd-MMM-yyyy' |
        uppercase}} </span>
      <span *ngIf="teamDetails.modifiedBy"> by <b>{{teamDetails?.modifiedBy}}</b></span>
    </div>
  </div>
</section>
</div>

<wcg-modal class="create-team-modal" id="modal" [show]="showEditTeamModal" (close)="closeClick()">
  <wcg-modal-header>
    <span class="verticalTop">{{ 'teams.editModalTitle' | translate }}</span>
  </wcg-modal-header>
  <form #modalForm="ngForm" class="modal-row" name="editTeamForm" [formGroup]="editTeamForm">
    <wcg-toast variant="error" position="top" *ngIf="editTeamsFailure">{{ editTeamsFailureMessage }}</wcg-toast>
    <wcg-modal-body>
      <wcg-modal id="confirmation-modal-updateCompanyID" [show]="showConfirmUpdateCompanyModal" (close)="cancelTeamUpdate($event)">
        <wcg-modal-header>{{ 'teams.updateTeamConfirmModalHeader' | translate }}</wcg-modal-header>
        <wcg-modal-body>
      <p>{{ 'teams.updateTeamConfirmModalBody' | translate }}</p>
        </wcg-modal-body>
        <wcg-modal-footer>
          <button class="wcg-btn" (click)="cancelTeamUpdate()">{{ 'teams.cancel' | translate }}</button>
      <button class="wcg-btn wcg-btn-primary" (click)="updateTeam()"  [disabled]="modalForm.invalid || isSaving">{{ 'actions.yesSaveCompanyId' | translate }}</button>
      
        </wcg-modal-footer>
      </wcg-modal>
      <div class="wcg-row">
        <div class=" wcg-col-xs-4 wcg-rhythm-3">
          <span class="wcg-h3">
            {{ 'teams.teamInfo' | translate }}
          </span>
        </div>
      </div>
      <div class="wcg-row">
        <div class="wcg-col-xs-1">
          <wcg-form-field>
            <wcg-label>{{ 'teams.teamType' | translate }}</wcg-label>
            <wcg-select [formControl]="editTeamForm.controls.teamType" (change)="handleTeamTypeChange($event)">
              <option hidden value="-1"></option>
              <option *ngFor="let teamType of teamOrganizationTypes"
                [selected]="teamType.id == editTeamForm.value.teamType" [value]="teamType.id">
                {{teamType.value}}</option>
            </wcg-select>
            <div *ngIf="modalForm.submitted ||
              (editTeamForm.controls.teamType.dirty || editTeamForm.controls.teamType.touched)">
              <wcg-error-message *ngIf="editTeamForm.controls.teamType.hasError('min')">
                {{ 'teams.teamType' | translate }} is required
              </wcg-error-message>
            </div>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-3">
          <wcg-form-field>
            <wcg-label>{{ 'teams.teamName' | translate }}</wcg-label>
            <wcg-input type="text" formControlName="teamName" placeholder="Team Name" required></wcg-input>
            <div *ngIf="(modalForm.submitted) ||
             (editTeamForm.controls.teamName.dirty || editTeamForm.controls.teamName.touched)">
              <wcg-error-message *ngIf="editTeamForm.controls.teamName.hasError('required') ||
                editTeamForm.controls.teamName.hasError('whitespace')">
                {{ 'validation.required' | translate: { fieldname: ('teams.teamName' | translate) } }}
              </wcg-error-message>
              <wcg-error-message *ngIf="editTeamForm.controls.teamName.hasError('pattern')">
                {{ 'validation.invalidSpecialCharacters' | translate: { fieldname: ('teams.teamName' | translate) } }}
              </wcg-error-message>
              <wcg-error-message *ngIf="editTeamForm.controls.teamName.hasError('maxlength')">
                {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamName' | translate), charLength: 255 } }}
              </wcg-error-message>
            </div>
          </wcg-form-field>
        </div>        
      </div>
      <div class="wcg-row">
        <div class="wcg-col-xs-2">
          <wcg-form-field>
            <wcg-label>{{ 'teams.teamEmail' | translate }}</wcg-label>
            <wcg-input type="text" formControlName="teamEmail" placeholder="Team Email" 
              [disabled]="!canEditTeamEmail" required></wcg-input>
            <div class="email-info">
              <wcg-icon src="./assets/icons/alert/ic_error_24px.svg"></wcg-icon>
              <p>{{ 'teams.emailInfo' | translate }}</p>
            </div>
            <div *ngIf="( modalForm.submitted) ||
            ( editTeamForm.controls.teamEmail.dirty || editTeamForm.controls.teamEmail.touched)">
              <wcg-error-message *ngIf="editTeamForm.controls.teamEmail.hasError('required') ||
                editTeamForm.controls.teamEmail.hasError('whitespace')">
                {{ 'validation.required' | translate: { fieldname: ('teams.teamEmail' | translate) } }}
              </wcg-error-message>
              <wcg-error-message *ngIf="editTeamForm.controls.teamEmail.hasError('pattern')">
                {{ 'validation.email' | translate: { fieldname: ('teams.teamEmail' | translate) } }}
              </wcg-error-message>
              <wcg-error-message *ngIf="editTeamForm.controls.teamEmail.hasError('maxlength')">
                {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamEmail' | translate), charLength: 255 } }}
              </wcg-error-message>
            </div>
          </wcg-form-field>
        </div>
        <div *ngIf="isAdmin" class="wcg-col-xs-1">
          <wcg-form-field>
            <wcg-label>{{ 'teams.companyId' | translate }} (optional)</wcg-label>
            <wcg-input type="text" formControlName="companyId"></wcg-input>
            <div *ngIf="(modalForm.submitted) ||
             (editTeamForm.controls.companyId.dirty || editTeamForm.controls.companyId.touched)">
              <wcg-error-message *ngIf="editTeamForm.controls.companyId.hasError('pattern')">
                {{ 'validation.numeric' | translate: { fieldname: ('teams.companyId' | translate) } }}
              </wcg-error-message>
              <wcg-error-message *ngIf="editTeamForm.controls.companyId.hasError('max')">
                {{ 'validation.maxNumber' | translate: { fieldname: ('teams.companyId' | translate) } }}
              </wcg-error-message>
            </div>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-1">
          <wcg-form-field *ngIf="countries">
            <wcg-label>{{ 'teams.teamCountry' | translate }}</wcg-label>
            <wcg-select formControlName="country" (change)="handleCountryChange($event)">
              <option hidden value="-1"></option>
              <option *ngFor="let country of countries" [selected]="country.id == editTeamForm.value.country"
                [value]="country?.id" required>
                {{ country?.name }}
              </option>
            </wcg-select>
            <div *ngIf="modalForm.submitted ||
              (editTeamForm.controls.country.dirty || editTeamForm.controls.country.touched)">
              <wcg-error-message *ngIf="editTeamForm.controls.country.hasError('min')">
                {{ 'validation.required' | translate: { fieldname: ('teams.teamCountry' | translate) } }}
              </wcg-error-message>
            </div>
          </wcg-form-field>
        </div>
      </div>
      <div class="wcg-row">
        <div class="wcg-col-xs-4 wcg-col-md-4">
          <wcg-form-field>
            <wcg-label>{{ 'teams.teamAddress1' | translate }}</wcg-label>
            <wcg-input formControlName="addressLine1" required></wcg-input>
            <div *ngIf="modalForm.submitted ||
              (editTeamForm.controls.addressLine1.dirty || editTeamForm.controls.addressLine1.touched)">
              <wcg-error-message *ngIf="editTeamForm.controls.addressLine1.hasError('required') ||
                editTeamForm.controls.addressLine1.hasError('whitespace')">
                {{ 'validation.required' | translate: { fieldname: ('teams.teamAddress1' | translate) } }}
              </wcg-error-message>
              <wcg-error-message *ngIf="editTeamForm.controls.addressLine1.hasError('pattern')">
                {{ 'validation.invalidSpecialCharacters' | translate: { fieldname: ('teams.teamAddress1' | translate) }
                }}
              </wcg-error-message>
              <wcg-error-message *ngIf="editTeamForm.controls.addressLine1.hasError('maxlength')">
                {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamAddress1' | translate), charLength: 255 }
                }}
              </wcg-error-message>
            </div>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-4 wcg-col-md-4">
          <wcg-form-field>
            <wcg-label>{{ 'teams.teamAddress2' | translate }} (optional)</wcg-label>
            <wcg-input formControlName="addressLine2"></wcg-input>
            <div *ngIf="modalForm.submitted ||
           (editTeamForm.controls.addressLine2.dirty || editTeamForm.controls.addressLine2.touched)">
              <wcg-error-message *ngIf="editTeamForm.controls.addressLine2.hasError('pattern')">
                {{ 'validation.invalidSpecialCharacters' | translate: { fieldname: ('teams.teamAddress2' | translate) }
                }}
              </wcg-error-message>
              <wcg-error-message *ngIf="editTeamForm.controls.addressLine2.hasError('maxlength')">
                {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamAddress2' | translate), charLength: 255 }
                }}
              </wcg-error-message>
            </div>
          </wcg-form-field>
        </div>
      </div>
      <div class="wcg-row">
        <div class="wcg-col-xs-4 wcg-col-md-3">
          <wcg-form-field>
            <wcg-label>{{ 'teams.teamCity' | translate }}</wcg-label>
            <wcg-input formControlName="city" required></wcg-input>
            <div *ngIf="modalForm.submitted ||
           (editTeamForm.controls.city.dirty || editTeamForm.controls.city.touched)">
              <wcg-error-message *ngIf="editTeamForm.controls.city.hasError('required') ||
                editTeamForm.controls.city.hasError('whitespace')">
                {{ 'validation.required' | translate: { fieldname: ('teams.teamCity' | translate) } }}
              </wcg-error-message>
              <wcg-error-message *ngIf="editTeamForm.controls.city.hasError('pattern')">
                {{ 'validation.invalidSpecialCharacters' | translate: { fieldname: ('teams.teamCity' | translate) } }}
              </wcg-error-message>
              <wcg-error-message *ngIf="editTeamForm.controls.city.hasError('maxlength')">
                {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamCity' | translate), charLength: 75 } }}
              </wcg-error-message>
            </div>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-4 wcg-col-md-3">
          <wcg-form-field *ngIf="states?.length">
            <wcg-label>{{ stateLabel | translate }}</wcg-label>
            <wcg-select formControlName="state" (change)="stateChange($event)">
              <option value="" [selected]="'' == editTeamForm.value.state"></option>
              <option *ngFor="let state of states" [selected]="state.id == editTeamForm.value.state" [value]="state?.id"
                required>
                {{ state?.name }}
              </option>
            </wcg-select>
            <div *ngIf="modalForm.submitted ||
              (editTeamForm.controls.state.dirty || editTeamForm.controls.state.touched)">
              <wcg-error-message *ngIf="editTeamForm.controls.state.invalid">
                {{ 'validation.required' | translate: { fieldname: (stateLabel | translate) } }}
              </wcg-error-message>
            </div>
          </wcg-form-field>
          <wcg-form-field *ngIf="states?.length === 0">
            <wcg-label>{{ 'teams.teamStateProvince' | translate }}</wcg-label>
            <wcg-input formControlName="province" required></wcg-input>
            <div *ngIf="modalForm.submitted ||
           (editTeamForm.controls.province.dirty || editTeamForm.controls.province.touched)">
              <wcg-error-message *ngIf="editTeamForm.controls.province.hasError('required') ||
                editTeamForm.controls.province.hasError('whitespace')">
                {{ 'validation.required' | translate: { fieldname: ('teams.teamStateProvince' | translate) } }}
              </wcg-error-message>
              <wcg-error-message *ngIf="editTeamForm.controls.province.hasError('pattern')">
                {{ 'validation.invalidSpecialCharacters' | translate: { fieldname: ('teams.teamStateProvince' |
                translate) } }}
              </wcg-error-message>
              <wcg-error-message *ngIf="editTeamForm.controls.province.hasError('maxlength')">
                {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamStateProvince' | translate), charLength:
                150 } }}
              </wcg-error-message>
            </div>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-4 wcg-col-md-2">
          <wcg-form-field>
            <wcg-label>{{ 'teams.teamPostal' | translate }}</wcg-label>
            <wcg-input formControlName="postalCode" required></wcg-input>
            <div *ngIf="modalForm.submitted ||
           (editTeamForm.controls.postalCode.dirty || editTeamForm.controls.postalCode.touched)">
              <wcg-error-message *ngIf="editTeamForm.controls.postalCode.hasError('required') ||
                editTeamForm.controls.postalCode.hasError('whitespace')">
                {{ 'validation.required' | translate: { fieldname: ('teams.teamPostal' | translate) } }}
              </wcg-error-message>
              <wcg-error-message *ngIf="editTeamForm.controls.postalCode.hasError('pattern')">
                {{ 'validation.invalidSpecialCharacters' | translate: { fieldname: ('teams.teamPostal' | translate) } }}
              </wcg-error-message>
              <wcg-error-message *ngIf="editTeamForm.controls.postalCode.hasError('maxlength')">
                {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamPostal' | translate), charLength: 30 } }}
              </wcg-error-message>
            </div>
          </wcg-form-field>
        </div>
      </div>
      <div>
        <div *ngFor="let address of additionalAddresses.controls; let i = index" class="wcg-row" [formGroup]="address">
          <div class="wcg-col-xs-8 wcg-col-md-8">
            <div class="wcg-row">
              <div class="wcg-col-xs-4 wcg-col-md-4">
                <h2 class="wcg-h2 wcg-rhythm-4 cnxs-wrap-content inline-flex mt-0">Additional Address {{i + 1}}</h2>

              </div>
              <div class="wcg-col-xs-4 wcg-col-md-4 wcg-end-xs">
                <button class="wcg-btn wcg-btn-icon inline-flex" (click)="deleteAdditionalAddress($event, i)">
                  <wcg-icon src="../assets/icons/action/ic_delete_forever_24px.svg"></wcg-icon>
                  <span>Remove address</span>
                </button>
              </div>
            </div>
            <div class="wcg-row" [formGroup]="address">
              <div class="wcg-col-xs-8 wcg-col-md-8">
                <wcg-form-field *ngIf="countries">
                  <wcg-label>{{ 'teams.teamCountry' | translate }}</wcg-label>
                  <wcg-select formControlName="country" (change)="handleCountryChangeForAdditionalAddress($event, i)">
                    <option hidden value="-1"></option>
                    <option *ngFor="let country of countries" [selected]="country.id == address.value.country"
                      [value]="country?.id" required>
                      {{ country?.name }}
                    </option>
                  </wcg-select>
                  <div *ngIf="modalForm.submitted ||
                  (address.controls.country.dirty || address.controls.country.touched)">
                    <wcg-error-message *ngIf="address.controls.country.hasError('min')">
                      {{ 'validation.required' | translate: { fieldname: ('teams.teamCountry' | translate) } }}
                    </wcg-error-message>
                  </div>
                </wcg-form-field>
              </div>

            </div>
            <div class="wcg-row" [formGroup]="address">
              <div class="wcg-col-xs-4 wcg-col-md-4">
                <wcg-form-field>
                  <wcg-label>{{ 'teams.teamAddress1' | translate }}</wcg-label>
                  <wcg-input formControlName="addressLine1" required></wcg-input>
                  <div *ngIf="modalForm.submitted ||
                      (address.controls.addressLine1.dirty || address.controls.addressLine1.touched)">
                    <wcg-error-message *ngIf="address.controls.addressLine1.hasError('required') ||
                      address.controls.addressLine1.hasError('whitespace')">
                      {{ 'validation.required' | translate: { fieldname: ('teams.teamAddress1' | translate) } }}
                    </wcg-error-message>
                    <wcg-error-message *ngIf="address.controls.addressLine1.hasError('pattern')">
                      {{ 'validation.invalidSpecialCharacters' | translate: { fieldname: ('teams.teamAddress1' |
                      translate) } }}
                    </wcg-error-message>
                    <wcg-error-message *ngIf="address.controls.addressLine1.hasError('maxlength')">
                      {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamAddress1' | translate), charLength:
                      255 } }}
                    </wcg-error-message>
                  </div>
                </wcg-form-field>
              </div>
              <div class="wcg-col-xs-4 wcg-col-md-4">
                <wcg-form-field>
                  <wcg-label>{{ 'teams.teamAddress2' | translate }} (optional)</wcg-label>
                  <wcg-input formControlName="addressLine2"></wcg-input>
                  <div
                    *ngIf="modalForm.submitted || (address.controls.addressLine2.dirty || address.controls.addressLine2.touched)">
                    <wcg-error-message *ngIf="address.controls.addressLine2.hasError('pattern')">
                      {{ 'validation.invalidSpecialCharacters' | translate: { fieldname: ('teams.teamAddress2' |
                      translate) } }}
                    </wcg-error-message>
                    <wcg-error-message *ngIf="address.controls.addressLine2.hasError('maxlength')">
                      {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamAddress2' | translate), charLength:
                      255 } }}
                    </wcg-error-message>
                  </div>
                </wcg-form-field>
              </div>

            </div>
            <div class="wcg-row">
              <div class="wcg-col-xs-4 wcg-col-md-3">
                <wcg-form-field>
                  <wcg-label>{{ 'teams.teamCity' | translate }}</wcg-label>
                  <wcg-input formControlName="city" required></wcg-input>
                  <div *ngIf="modalForm.submitted ||
                 (address.controls.city.dirty || address.controls.city.touched)">
                    <wcg-error-message *ngIf="address.controls.city.hasError('required') ||
                      address.controls.city.hasError('whitespace')">
                      {{ 'validation.required' | translate: { fieldname: ('teams.teamCity' | translate) } }}
                    </wcg-error-message>
                    <wcg-error-message *ngIf="address.controls.city.hasError('pattern')">
                      {{ 'validation.invalidSpecialCharacters' | translate: { fieldname: ('teams.teamCity' | translate)
                      }
                      }}
                    </wcg-error-message>
                    <wcg-error-message *ngIf="address.controls.city.hasError('maxlength')">
                      {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamCity' | translate), charLength: 75 }
                      }}
                    </wcg-error-message>
                  </div>
                </wcg-form-field>
              </div>
              <div class="wcg-col-xs-4 wcg-col-md-3">
                <wcg-form-field *ngIf="additionalStates[i]?.length">
                  <wcg-label>{{ additionalStateLevels[i] | translate }}</wcg-label>
                  <wcg-select formControlName="state" (change)="additionalStateChange($event, i)">
                    <option value="" [selected]="'' == address.value.state"></option>
                    <option *ngFor="let state of additionalStates[i]" [selected]="state.id == address.value.state"
                      [value]="state?.id" required>
                      {{ state?.name }}
                    </option>
                  </wcg-select>
                  <div *ngIf="modalForm.submitted ||
                    (address.controls.state.dirty || address.controls.state.touched)">
                    <wcg-error-message *ngIf="address.controls.state.invalid">
                      {{ 'validation.required' | translate: { fieldname: (additionalStateLevels[i] | translate) } }}
                    </wcg-error-message>
                  </div>
                </wcg-form-field>
                <wcg-form-field *ngIf="additionalStates[i]?.length === 0">
                  <wcg-label>{{ 'teams.teamStateProvince' | translate }}</wcg-label>
                  <wcg-input formControlName="province" required></wcg-input>
                  <div *ngIf="modalForm.submitted ||
                 (address.controls.province.dirty || address.controls.province.touched)">
                    <wcg-error-message *ngIf="address.controls.province.hasError('required') ||
                      address.controls.province.hasError('whitespace')">
                      {{ 'validation.required' | translate: { fieldname: ('teams.teamStateProvince' | translate) } }}
                    </wcg-error-message>
                    <wcg-error-message *ngIf="address.controls.province.hasError('pattern')">
                      {{ 'validation.invalidSpecialCharacters' | translate: { fieldname: ('teams.teamStateProvince' |
                      translate) } }}
                    </wcg-error-message>
                    <wcg-error-message *ngIf="address.controls.province.hasError('maxlength')">
                      {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamStateProvince' | translate),
                      charLength: 150 } }}
                    </wcg-error-message>
                  </div>
                </wcg-form-field>
              </div>
              <div class="wcg-col-xs-4 wcg-col-md-2">
                <wcg-form-field>
                  <wcg-label>{{ 'teams.teamPostal' | translate }}</wcg-label>
                  <wcg-input formControlName="postalCode" required></wcg-input>
                  <div *ngIf="modalForm.submitted ||
                 (address.controls.postalCode.dirty || address.controls.postalCode.touched)">
                    <wcg-error-message *ngIf="address.controls.postalCode.hasError('required') ||
                      address.controls.postalCode.hasError('whitespace')">
                      {{ 'validation.required' | translate: { fieldname: ('teams.teamPostal' | translate) } }}
                    </wcg-error-message>
                    <wcg-error-message *ngIf="address.controls.postalCode.hasError('pattern')">
                      {{ 'validation.invalidSpecialCharacters' | translate: { fieldname: ('teams.teamPostal' |
                      translate)
                      } }}
                    </wcg-error-message>
                    <wcg-error-message *ngIf="address.controls.postalCode.hasError('maxlength')">
                      {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamPostal' | translate), charLength: 30
                      }
                      }}
                    </wcg-error-message>
                  </div>
                </wcg-form-field>
              </div>

            </div>
          </div>
        </div>
      </div>


      <div class="wcg-row">
        <div class="wcg-col-xs-12">
          <button class="wcg-btn wcg-btn-icon inline-flex" (click)="addAnotherAddress($event)">
            <wcg-icon src="../assets/icons/content/ic_add_24px.svg"></wcg-icon>
            <span>Add another address</span>
          </button>
        </div>
      </div>
      <div class="modal-info-heading wcg-col-xs-4 wcg-rhythm-3">
        <span class="wcg-h3">
          {{ 'teams.teamMemberInfo' | translate }}
        </span>
      </div>
      <div class="wcg-row">
        <div class="wcg-col-xs-4">
          <wcg-form-field>
            <wcg-label *ngIf="isAdmin">{{'teams.addMember' | translate }}</wcg-label>
            <wcg-label *ngIf="!isAdmin">{{'teams.changePrimaryTeamdMember' | translate }}</wcg-label>
            <wcg-autocomplete formControlName="selectedUsers" [multiple]="false" [options]="allUsers"
              (change)="updateUser($event)" required>
            </wcg-autocomplete>
            <div
              *ngIf="(editTeamForm.controls.selectedUsers.invalid && modalForm.submitted) ||
           (editTeamForm.controls.selectedUsers.invalid && (editTeamForm.controls.selectedUsers.dirty || editTeamForm.controls.selectedUsers.touched))">
              <wcg-error-message *ngIf="!editTeamForm.controls.selectedUsers.valid">
                {{ 'validation.required' | translate: { fieldname: ('teams.teamMemberInfo' | translate) } }}
              </wcg-error-message>
            </div>
          </wcg-form-field>
        </div>
      </div>
    </wcg-modal-body>
    <wcg-modal-footer>
      <button type="button" class="wcg-btn wcg-btn-auto" (click)="closeClick()">{{ 'teams.cancel' | translate }}</button>
      <button class="wcg-btn wcg-btn-auto wcg-btn-primary" (click)="updateTeamCheckCompanyid($event)" [disabled]="modalForm.invalid || isSaving">{{
        'teams.updateTeam' |
        translate }}</button>
    </wcg-modal-footer>
  </form>
</wcg-modal>

<wcg-modal id="confirmation-modal-deactivate" [show]="showDeactivateModal" (close)="showDeactivateModal = false">
  <wcg-modal-header>{{ 'teams.deactivateTeamConfirmHeader' | translate }}</wcg-modal-header>
  <wcg-modal-body>
<p>{{ 'teams.deactivateTeamConfirmBody' | translate }}</p>
  </wcg-modal-body>
  <wcg-modal-footer>
    <button class="wcg-btn" (click)="showDeactivateModal = false">{{ 'teams.cancel' | translate }}</button>
<button class="wcg-btn wcg-btn-primary" [disabled]="updatingStatus" (click)="deactivateTeam($event)">{{ 'actions.yes' | translate }}</button>

  </wcg-modal-footer>
</wcg-modal>

<wcg-modal id="confirmation-modal-activate" [show]="showActivateConfirmModal" (close)="showActivateConfirmModal = false">
  <wcg-modal-header>{{ 'teams.activateTeamConfirmModalHeader' | translate }}</wcg-modal-header>
  <wcg-modal-body>
<p>{{ 'teams.activateTeamConfirmModalBody' | translate }}</p>
  </wcg-modal-body>
  <wcg-modal-footer>
    <button class="wcg-btn" (click)="showActivateConfirmModal = false">{{ 'teams.cancel' | translate }}</button>
<button class="wcg-btn wcg-btn-primary" (click)="activateTeamConfirm($event)">{{ 'actions.yes' | translate }}</button>

  </wcg-modal-footer>
</wcg-modal>


<wcg-modal [show]="showPrimaryUserModal" (close)="showPrimaryUserModal = false;">
  <wcg-modal-header>Activating Team</wcg-modal-header>
  <form #primaryUserFormModal="ngForm" class="modal-row" name="primaryUserForm" (ngSubmit)="acivateTeam($event)" [formGroup]="primaryUserForm">
  <wcg-modal-body>
    <div class="wcg-col-xs-4 wcg-rhythm-3">
      <span class="wcg-h3">
        {{ 'teams.teamMemberInfo' | translate }}
      </span>
    </div>
    <div class="wcg-row">
      <div class="wcg-col-xs-4">
        <wcg-form-field>
          <wcg-label>{{ 'teams.addMember' | translate }}</wcg-label>
          <wcg-autocomplete formControlName="primaryUser" [multiple]="false" [options]="allUsers"
            (change)="updatePrimaryUser($event)" required>
          </wcg-autocomplete>
          <div
            *ngIf="(primaryUserForm.controls.primaryUser.invalid && primaryUserFormModal.submitted) ||
         (primaryUserForm.controls.primaryUser.invalid && (primaryUserForm.controls.primaryUser.dirty || primaryUserForm.controls.primaryUser.touched))">
            <wcg-error-message *ngIf="!primaryUserForm.controls.primaryUser.valid">
              {{ 'validation.required' | translate: { fieldname: ('teams.teamMemberInfo' | translate) } }}
            </wcg-error-message>
          </div>
        </wcg-form-field>
      </div>
    </div>
  </wcg-modal-body>
  <wcg-modal-footer>
    <button class="wcg-btn" (click)="showPrimaryUserModal = false;">{{ 'teams.cancel' | translate }}</button>
<button class="wcg-btn wcg-btn-primary" [disabled]="primaryUserFormModal.invalid || updatingStatus" (click)="acivateTeam($event)">{{ 'teams.activateTeam' | translate }}</button>

  </wcg-modal-footer>
</form>
</wcg-modal>