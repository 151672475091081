/* eslint-disable max-lines */
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.scss']
})
export class SystemSettingsComponent extends BaseComponent implements OnInit {
  isApplicationInMaintenanceMode = true;
  showMaintenanceModal = false;

  constructor(private apiService: ApiService) {
    super();
  }

  ngOnInit(): void {
    this.apiService.getConfigurationValue('settings/IsApplicationInMaintenanceMode')
      .subscribe((setting) => {
        this.isApplicationInMaintenanceMode = setting.value === 'true';
      });
  }

  closeClick(): void {
    this.showMaintenanceModal = false;
  }

  showModalMaintenanceMode(event): void {
    event.preventDefault();
    this.showMaintenanceModal = true;
  }

  switchMaintenanceMode(): void {
    this.apiService.updateSetting('IsApplicationInMaintenanceMode', String(!this.isApplicationInMaintenanceMode)).subscribe();
    this.isApplicationInMaintenanceMode = !this.isApplicationInMaintenanceMode;
  }
}
