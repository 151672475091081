import { Toast } from '../interfaces/components';

export const InviteInProgressMessage: Toast = {
  closable: true,
  id: 'InviteInProgress',
  message: 'Inviting in progress.',
  variant: 'info'
};

export const InvitedSuccessMessage: Toast = {
  closable: true,
  id: 'InviteSuccess',
  message: 'Invitations sent.',
  variant: 'success',
  timeout: 5000
};

export const InvitedFailedMessage: Toast = {
  closable: true,
  id: 'InviteFailed',
  message: 'Invitations to Workspace(s) failed. Try again.',
  variant: 'error'
};

export const AlreadyInvitedMessage = (workspaceId: string, alreadyInvitedUsers: string[]): Toast => ({
  closable: true,
  id: `alreadyInvitedTo${workspaceId}}`,
  message: `${alreadyInvitedUsers.join(', ')} already invited to ${workspaceId}`,
  variant: 'error'
});

export const UpdateInProgressMessage: Toast = {
  closable: true,
  id: 'UpdateInProgress',
  message: 'Permission level update in progress.',
  variant: 'info'
};

export const UpdateSuccessMessage: Toast = {
  closable: true,
  id: 'UpdateSuccess',
  message: 'Permission level update is complete.',
  variant: 'success',
  timeout: 5000
};

export const UpdateFailedMessage: Toast = {
  closable: true,
  id: 'UpdateFailed',
  message: 'Permission level update failed. Try again.',
  variant: 'error'
};

export const UsersNotFoundMessage = (workspaceId: string, users: string[]): Toast => ({
  closable: true,
  id: `NotFoundIn${workspaceId}}`,
  message: `${users.join(', ')} not found in ${workspaceId}`,
  variant: 'error'
});

export const RemoveInProgressMessage: Toast = {
  closable: true,
  id: 'RemoveInProgress',
  message: 'Removal from Workspace(s) in progress.',
  variant: 'info'
};

export const RemoveSuccessMessage: Toast = {
  closable: true,
  id: 'RemoveSuccess',
  message: 'Removal from Workspace(s) is complete.',
  variant: 'success',
  timeout: 5000
};

export const RemoveFailedMessage: Toast = {
  closable: true,
  id: 'RemoveFailed',
  message: 'Removal from Workspace(s) failed. Try again.',
  variant: 'error'
};

export const WorspaceNotFound: Toast = {
  closable: true,
  id: 'WorspaceNotFound',
  message: 'There are no matches for your search. Please try again.',
  variant: 'error',
  timeout: 5000
};

export const UsersDemoted = (workspaceId: string, users: string[]): Toast => ({
  closable: true,
  id: `DemotedIn${workspaceId}}`,
  message: `Unable to Delete Access ${users.join(', ')} from ${workspaceId}. Access has been Updated to Site Participant.`,
  variant: 'error'
});

export const TeamMembersNotRemoved = (workspaceId: string, users: string[]): Toast => ({
  closable: true,
  id: `TeamMembersNotRemovedIn${workspaceId}}`,
  message: `The user(s) ${users.join(', ')} belong to Team and cannot be removed individually from ${workspaceId}.`,
  variant: 'error'
});

export const TeamMembersNotUpdated = (workspaceId: string, users: string[]): Toast => ({
  closable: true,
  id: `TeamMembersNotUpdatedIn${workspaceId}}`,
  message: `The user(s) ${users.join(', ')} belong to Team and cannot be updated individually in ${workspaceId}.`,
  variant: 'error'
});

export const WorkspaceAlreadyAdded: Toast = {
  closable: true,
  id: 'WorkspaceAlreadyAdded',
  message: 'This workspace already added.',
  variant: 'error',
  timeout: 5000
};
