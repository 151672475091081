import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { ApiReportingUrl, Apis } from '../common/apis';
import { DownloadExportsGrid } from '../interfaces/download-exports';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {
  constructor(
    private httpClient: HttpClient
  ) { }

  public requestExport(gridOptionsModel, reportingUrl: string, limitAdminUserCount: boolean = null, id: string = null,
    secondId = null): Observable<any> {
    let apiUrl = `${ApiReportingUrl}${reportingUrl}`;

    if (limitAdminUserCount !== null) {
      apiUrl = `${apiUrl}?limitAdminUserCount=${limitAdminUserCount}`;
    }

    if (id) {
      apiUrl = apiUrl.replace('{id}', id);
    }

    if (secondId) {
      apiUrl = apiUrl.replace('{secondId}', secondId);
    }

    return this.httpClient.post(apiUrl,
      gridOptionsModel).pipe(
      map((res) => res),
      catchError(this.handleError)
    );
  }

  public getExportRequestsGrid(gridOptionsModel): Observable<any> {
    const url = `${ApiReportingUrl}${Apis.UserExports}`;

    return this.httpClient.post<DownloadExportsGrid>(url, gridOptionsModel).pipe(
      map((res: DownloadExportsGrid) => res),
      catchError(this.handleError)
    );
  }

  public downloadExportFile(exportId: number, fileName: string): Observable<any> {
    const apiUrl = `${ApiReportingUrl}${Apis.GetExportFileSas}/${exportId}`;

    return this.httpClient.get<any>(apiUrl).pipe(
      concatMap(
        (fileUrl) => this.httpClient
          .get(fileUrl, { responseType: 'blob' })
          .pipe(
            map((resp) => saveAs(resp, fileName)),
            catchError(this.handleError)
          )
      )
    );
  }

  // -----------------------
  // ERROR ----------------
  // -----------------------

  private handleError = (error: HttpErrorResponse): any => {
    const newError: any = new Error(`${error}`);
    newError.details = error;

    return throwError(newError);
  };
}
