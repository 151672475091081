<ul class="cnxs-status-list">
  <li *ngFor="let item of statusGrid" class="cnxs-status-list-item" [ngClass]="{
    'wcg-active': item.active,
    'wcg-current': item.current,
    'wcg-on-hold': onHold,
    'wcg-complete': item.current && !onHold && outcome === fullyApproved,
    'cnxs-needs-action': item.needsAction
  }">

    <wcg-icon *ngIf="item.current && onHold && !item.needsAction" src="./assets/icons/alert/ic_warning_24px.svg"></wcg-icon>
    <wcg-icon *ngIf="item.current && !onHold && outcome === fullyApproved" src="./assets/icons/content/ic_save_alt_24px.svg"></wcg-icon>
    <wcg-icon *ngIf="item.active && item.needsAction" src="./assets/icons/content/ic_report_24px.svg"></wcg-icon>
  </li>
</ul>
