<section class="wcg-container">
    <div class="wcg-row cnxs-page-header">
        <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-6 wcg-col-lg-8">
            <h1 class="wcg-h2">{{ 'nav.downloads' | translate}}</h1>
        </div>
    </div>
    <wcg-tab-bar-section>
        <wcg-tab-bar>
            <a class="wcg-link view-settings" routerLink="download-documents" routerLinkActive="wcg-active">
                {{ 'nav.downloadDocuments' | translate}}</a>
            <a class="wcg-link view-settings" routerLink="download-exports" routerLinkActive="wcg-active">
                {{ 'nav.downloadExports' | translate}}</a>
        </wcg-tab-bar>

        <div class="wcg-container">
            <router-outlet></router-outlet>
        </div>
    </wcg-tab-bar-section>
</section>