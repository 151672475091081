import { Component, OnInit } from '@angular/core';
import { takeUntil, debounce } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

import { BaseComponent } from '../../../components/base/base.component';
import { StudyContactsMap } from '../../../interfaces/studies';
import { ApiService } from '../../../services/api.service';
import { StudyService } from '../study.service';
import { ToastService } from '../../../services/toast.service';
import { UtilService } from '../../../services/util.service';
import { TableHeader } from 'src/app/interfaces/components';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { DEBOUNCE_TIME, ServerErrorCodes, StudyWorkspaceContactSortOptions } from 'src/app/common/collections';
import { timer } from 'rxjs';
import { GridExportType } from 'src/app/common/reportingCollections';

@Component({
  selector: 'app-study-contacts',
  templateUrl: './study-contacts.component.html',
  styleUrls: ['./study-contacts.component.scss']
})

export class StudyContactsComponent extends BaseComponent implements OnInit {
  tableData: Array<StudyContactsMap>;
  loaded: boolean;
  loading: boolean;
  id: string;
  searchTerm = new FormControl('');
  contacts: Array<StudyContactsMap>;
  contactsFetched = false;
  pageUnauthorized = false;
  headers: Array<TableHeader>;
  initialContacts: Array<StudyContactsMap> = [];
  currentTab: string;

  currentPage: number;
  currentPageSize = 10;
  totalPages: number;
  totalRecords: number;

  currentSort: string;
  currentSortDescending = true;

  options = {
    manualPaginationAndSort: true,
    rowsPerPage: 10
  };

  readonly exportType: GridExportType = GridExportType.StudyDetailsContacts;
  gridFilters: any;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private studyService: StudyService,
    public utilService: UtilService,
    private toastService: ToastService,
    private translatePipe: TranslatePipe
  ) {
    super();

    [this.id, this.currentTab] = this.router.url.split('/studies/')[1].split('/');

    this.postStudyContactsGrid(this.id, {
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: ''
    });
    this.headers = this.getHeaders();
  }

  ngOnInit(): void {
    this.searchTerm.valueChanges
      .pipe(
        debounce(() => timer(DEBOUNCE_TIME))
      )
      .subscribe((val) => {
        this.studyService.searchTerm.next(val);
        this.postStudyContactsGrid(this.id, {
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: val
        });
      });
  }

  handleChangePage(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;

    this.postStudyContactsGrid(this.id, {
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: this.currentSort ? [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }] : null
    });
  }

  handleSorting(event): void {
    this.currentSort = StudyWorkspaceContactSortOptions[event.detail.field];
    this.currentSortDescending = !event.detail.ascending;
    this.currentPage = 1;

    this.postStudyContactsGrid(this.id, {
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }]
    });
  }

  private getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('studyContacts.contactName'), 'contactName', true),
      this.utilService.createTableHeader(this.translatePipe.transform('studyContacts.permissionType'), 'permissionType', true),
      this.utilService.createTableHeader(this.translatePipe.transform('studyContacts.email'), 'email', true),
      this.utilService.createTableHeader(this.translatePipe.transform('studyContacts.organizationName'), 'organizationName', true),
      this.utilService.createTableHeader(this.translatePipe.transform('studyContacts.organizationType'), 'organizationType', true)
    ];
  }

  private postStudyContactsGrid(id, body): void {
    this.loading = true;
    this.gridFilters = body;

    this.apiService.getStudyContacts(id, body)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (data.records && data.records.length > 0) {
          this.initialContacts = data.records;
        }

        this.contacts = this.studyService.mapContactsData(data.records);
        this.contactsFetched = true;
        this.tableData = this.contacts;
        this.loaded = true;
        this.loading = false;
        this.totalPages = Math.ceil(data.totalRecords / data.pageSize);
        this.totalRecords = data.totalRecords;
        this.currentPage = data.currentPage;
      }, (error) => {
        this.loaded = true;
        this.loading = false;
        this.contactsFetched = true;
        if (error.details.status === ServerErrorCodes.unauthorizedStatusNumber) {
          this.pageUnauthorized = true;
        }
        else {
          this.toastService.add([{
            closable: true,
            id: 'getStudyContacts',
            message: this.translatePipe.transform('serverErrors.internalServerError'),
            variant: 'error'
          }]);
        }
      });
  }
}
