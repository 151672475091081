import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Component({
  selector: 'app-logout-callback',
  template: ''
})
export class LogoutCallbackComponent implements OnInit {
  constructor(private authenticationService: AuthenticationService, private router: Router) {
  }

  public ngOnInit(): void {
    this.authenticationService.completeLogout().then(() => {
      this.router.navigate(['/'], { replaceUrl: true });
    });
  }
}
