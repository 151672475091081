import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { SubmissionWorkflowService } from '../../../views/submission-workflow/submission-workflow.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { protocolInfo2, protocolInfoSite } from 'src/app/common/formio-map';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-workflow-container',
  templateUrl: './workflow-container.component.html',
  styleUrls: ['./workflow-container.component.scss']
})

export class WorkflowContainerComponent implements OnInit {
  @Input() hasFooter: boolean;
  @Input() hasIntro: boolean;
  @Input() hasNav: boolean;
  @Input() header: string;
  @Input() save: string
  autosaveAutosaved: string;
  autosaveSaving: string;
  autosaveError: string;
  hasUploads: boolean;
  isUploadPage: boolean;
  supportEmail= '';
  constructor(
    public router: Router,
    public stateService: StateService,
    public submissionWorkflowService: SubmissionWorkflowService,
    public translatePipe: TranslatePipe,
    private apiService: ApiService
  ) {
    this.autosaveAutosaved = this.translatePipe.transform('submissionWorkflow.autosaveAutosaved');
    this.autosaveSaving = this.translatePipe.transform('submissionWorkflow.autosaveSaving');
    this.autosaveError = this.translatePipe.transform('submissionWorkflow.autosaveError');
    this.hasUploads = this.submissionWorkflowService.submissionDocumentTypes.value.length > 0;
    this.isUploadPage = this.router.url.includes('/submission-workflow/details/upload');
  }

  ngOnInit(): void {
    this.stateService.getEstimatedOutcomeDate();
    this.getSupportEmail();
  }

  public toggleSidebar(): void {
    this.stateService.formNavVisible.next(!this.stateService.formNavVisible.value);
  }

  public getSponsorProtocolId(): void {
    const formInfo = this.submissionWorkflowService.formInfo.value;
    const formioValue = formInfo?.[protocolInfo2.form]?.data?.[protocolInfo2.fields.submissionName] ||
      formInfo?.[protocolInfoSite.form]?.data?.[protocolInfoSite.fields.submissionName];

    return formioValue;
  }

  private getSupportEmail(): void {
    this.apiService.getConfigurationValue('settings/SupportEmail')
      .subscribe((setting) => {
        this.supportEmail = setting.value;
      });
  }
}
