import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { StorageService } from '../services/storage.service';
import { StorageKeys } from 'src/app/models/storage.model';

@Component({
  selector: 'app-login-callback',
  template: ''
})
export class LoginCallbackComponent implements OnInit {
  constructor(private authenticationService: AuthenticationService, private router: Router, private storageService: StorageService) {
  }

  public ngOnInit(): void {
    this.authenticationService.handleRedirectCallback().then(() => {
      const redirect = this.storageService.getKey(StorageKeys.redirect);
      const redirectPath = redirect ? redirect : '';
      this.storageService.removeKey(StorageKeys.redirect);
      this.router.navigate([redirectPath], { replaceUrl: true });
    });
  }
}
