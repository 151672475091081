import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from '../shared.module';
import { ViewSettingsComponent } from './views/settings/view-settings/view-settings.component';
import { ViewDocumentCategoryComponent } from './views/settings/document-categories/view-document-category.component';
import { SubmissionTypesComponent } from './views/settings/submission-types/submission-types.component';
import { ViewDocumentTypesComponent } from './views/settings/document-types/view-document-types/view-document-types.component';
import { SubmissionTypesService } from './shared/services/submission-types.service';
import { DocumentCategoryService } from './shared/services/document-category.service';
import { UserAdminService } from './shared/services/user-admin.service';
import { ViewUserInfoComponent } from './views/users/view-user-info.component';
import { UserDetailsComponent } from './views/users/user-details/user-details.component';
import { AdminBaseComponent } from './shared/components/adminbase/adminbase.component';
import { TeamsComponent } from './views/teams/teams.component';
import { UserService } from '../services/user.service';
import { AuditAccessComponent } from './views/audit-access/audit-access.component';
import { AdminSubmissionComponent } from './views/admin-submission/admin-submission.component';
import { SystemSettingsComponent } from './views/settings/system-settings/system-settings.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ManageUserContactsComponent } from './views/users/manage-user-contacts/manage-user-contacts.component';

@NgModule({
  declarations: [
    ViewSettingsComponent,
    ViewDocumentCategoryComponent,
    SystemSettingsComponent,
    SubmissionTypesComponent,
    ViewDocumentTypesComponent,
    ViewUserInfoComponent,
    UserDetailsComponent,
    ManageUserContactsComponent,
    AdminBaseComponent,
    TeamsComponent,
    AuditAccessComponent,
    AdminSubmissionComponent
  ],
  imports: [
    AdminRoutingModule,
    SharedModule,
    MatSlideToggleModule
  ],
  providers: [
    DocumentCategoryService,
    SubmissionTypesService,
    UserAdminService,
    UserService
  ],
  exports: [
    SharedModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class AdminModule {
}
