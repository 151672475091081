<wcg-modal [show]="stateService.showOutcomeDetailsModal.value" *ngIf='outcomeDocumentToReview' (close)="close()"
  class="access-modal">
  <wcg-modal-header>{{'outcomeDocumentsDetailsModal.modalTitle' | translate}}</wcg-modal-header>
  <wcg-modal-body>
    <app-export-confirmation-modal></app-export-confirmation-modal>
    <div class="wcg-rhythm-4">
      <h2 class="wcg-h2 wcg-rhythm-1 cnxs-wrap-content">{{outcomeDocumentToReview.fileName}}</h2>
      <wcg-notification *ngIf="reviewedAndTransmittedMessage" variant="success">{{reviewedAndTransmittedMessage}}</wcg-notification>
    </div>
    <div class="cnxs-loader-container wcg-container">
      <div *ngIf="documentsFetched">
        <form ngForm class="wcg-row">
          <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-4">
          </div>

          <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
            <ng2-flatpickr class="wcg-input-with-icon" #startDatePicker placeholder="Start Date"
              [config]="startDatePickerOptions" [formControl]="startDateFilter">
              <wcg-icon class="date-icon" (click)="toggleStartDate()"
                src="./assets/icons/action/ic_date_range_24px.svg">
              </wcg-icon>
            </ng2-flatpickr>
          </div>
          <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
            <ng2-flatpickr class="wcg-input-with-icon" #endDatePicker placeholder="End Date"
              [config]="endDatePickerOptions" [formControl]="endDateFilter">
              <wcg-icon class="date-icon" (click)="toggleEndDate()" src="./assets/icons/action/ic_date_range_24px.svg">
              </wcg-icon>
            </ng2-flatpickr>
          </div>
          <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-4">
            <wcg-form-field>
              <wcg-label>{{ 'actions.search' | translate }}</wcg-label>
              <wcg-input [formControl]="searchTerm">
                <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
              </wcg-input>
            </wcg-form-field>
          </div>
        </form>
        <div class="wcg-row" *ngIf="studyId">
          <div class="wcg-col-xs-4 wcg-col-md-12 wcg-col-lg-12 wcg-rhythm-2">
            <div class="export-button-wrap">
              <app-export-action-button [gridFilters]="gridFilters" [disableButton]="!tableData?.length" [exportType]="exportType" [entityId]="studyId"
              [secondEntityId]="outcomeDocumentToReview.documentId">
              </app-export-action-button>
            </div>
          </div>
        </div>
        <wcg-table class="wcg-rhythm-1" [ngClass]="{'loader': loading}" [headers]="headers"
          [data]="tableData" [options]="options" (rowSelected)="handleRowSelection($event)"
          (sortClicked)="handleSorting($event)">
          <div class="max-height-280" slot="table-body">
            <wcg-loader [show]="!loaded || loading"></wcg-loader>
          </div>
          <div class="cnxs-pagination-wrap wcg-table-footer wcg-rhythm-2" *ngIf="loaded && studyId">
            <div class="wcg-total-results">
              {{ 'tables.totalResults' | translate}}: {{totalRecords}}
            </div>
           
            <div class="pagination" *ngIf="tableData?.length">
              <wcg-pagination [pageSizeDataSource]="pageSizeDataSource"
              [current]="currentPage" [pageSize]="currentPageSize" 
              [max]="totalPages" (changePage)="handleChangePage($event)">
            </wcg-pagination>
            </div>
          </div>
        </wcg-table>
        <div class="wcg-row wcg-rhythm-2">
          <div class="wcg-col-md-2">
            <button class="wcg-btn wcg-btn-fluid"
              [disabled]="tableData?.length === 0 || selected?.length > 0"
              (click)="downloadAllDocuments()">
              {{ 'actions.downloadAll' | translate }}
              <wcg-loader [show]="downloadingAll"></wcg-loader>
            </button>
          </div>
          <div class="wcg-col-md-2">
            <button class="wcg-btn wcg-btn-fluid"
              [disabled]="selected?.length === 0 || (zipService.isDownloadingSelected | async)"
              (click)="zipService.startDownload(this.selected, documentRequestTypes['Outcome'], 'selected')">
              {{ 'actions.downloadSelected' | translate }}
              <wcg-loader [show]="zipService.isDownloadingSelected | async"></wcg-loader>
            </button>
          </div>
          <div class="wcg-col-md-2"></div>
          <wcg-pagination *ngIf="loaded && tableData?.length && submissionId" 
            [pageSizeDataSource]="pageSizeDataSource"
            [current]="currentPage" [pageSize]="currentPageSize" 
            [max]="totalPages" (changePage)="handleChangePage($event)">
          </wcg-pagination>
        </div>
      </div>
      <wcg-loader [show]="!documentsFetched"></wcg-loader>
    </div>
  </wcg-modal-body>
  <wcg-modal-footer>
    <button class="wcg-btn wcg-btn-medium wcg-btn-primary close-button" (click)="close()">Close</button>
  </wcg-modal-footer>
</wcg-modal>