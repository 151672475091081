import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { TableHeader, TableOptions } from 'src/app/interfaces/components';
import { DownloadDocumentsMap } from 'src/app/interfaces/download-documents';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';
import { UtilService } from 'src/app/services/util.service';
import { DownloadDocumentService } from './download-document.service';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ServerErrorCodes } from 'src/app/common/collections';

@Component({
  selector: 'app-download-documents',
  templateUrl: './download-documents.component.html',
  styleUrls: ['./download-documents.component.scss']
})
export class DownloadDocumentsComponent extends BaseComponent implements OnInit {
  public headers: Array<TableHeader>;
  public tableData: Array<DownloadDocumentsMap> = [];
  public options: TableOptions = {
    rowsPerPage: 10
  };

  public loading: boolean;

  constructor(public apiService: ApiService,
    public translatePipe: TranslatePipe,
    public utilService: UtilService,
    public downloadDocumentService: DownloadDocumentService,
    public toastService: ToastService) {
    super();
  }

  ngOnInit(): void {
    this.getDownloadDocuments();
    this.headers = this.getHeaders();
  }

  public getDownloadDocuments(): void {
    this.loading = true;
    this.apiService.getDownloadRequestsGrid().subscribe((data) => {
      this.tableData = this.downloadDocumentService.mapDownloadExportsGridData(data);
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.toastService.add([{
        closable: true,
        id: 'downloadDocumentFileError',
        message: `${this.translatePipe.transform('documentDownloadGrid.errorLoading')} ${error.message}`,
        timeout: this.toastService.ERROR_TIMEOUT,
        variant: 'error'
      }]);
    });
  }

  handleRowClicked(event): void {
    const downloadDocumentTargetId: string = event.detail.event.target.id;
    if (downloadDocumentTargetId === 'downloadDocument') {
      event.detail.event.preventDefault();
      const rowKey = event.detail.id;
      this.downloadDocument(rowKey);
    }
  }

  private downloadDocument(rowKey: string): void {
    this.loading = true;
    this.apiService.downloadDocumentFile(rowKey)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (response) => {
          this.loading = false;
          window.location.assign(response);
        },
        (error) => {
          this.loading = false;
          this.toastService.add([{
            closable: true,
            id: 'downloadDocumentFileError',
            message: error.details.status === ServerErrorCodes.internalServerErrorStatusNumber ||
              error.details.status === ServerErrorCodes.businessLayerErrorStatusNumber ? error.details.error.message :
              this.translatePipe.transform('serverErrors.internalServerError'),
            timeout: this.toastService.ERROR_TIMEOUT,
            variant: 'error'
          }]);
        }
      );
  }

  private getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('documentDownloadGrid.fileName'), 'fileName'),
      this.utilService.createTableHeader(this.translatePipe.transform('documentDownloadGrid.status'), 'status'),
      this.utilService.createTableHeader(this.translatePipe.transform('documentDownloadGrid.requested'), 'requestedDateTime'),
      this.utilService.createTableHeader(this.translatePipe.transform('documentDownloadGrid.details'), 'details'),
      this.utilService.createTableHeader('', 'downloadFile')
    ];
  }
}

