/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { forkJoin, Subject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import * as moment from 'moment';

import { UtilService } from '../services/util.service';
import { DocumentFileMap } from '../interfaces/submissions';
import { ToastService } from './toast.service';
import { ApiService } from './api.service';
import { regexRules, RegexTags } from '../common/collections';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Injectable({
  providedIn: 'root'
})
export class ZipService {
  isDownloading = new Subject<boolean>();
  isDownloadingAll = new Subject<boolean>();
  isDownloadingSelected = new Subject<boolean>();

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private toastService: ToastService,
    private utilService: UtilService,
    private translatePipe: TranslatePipe
  ) { }

  public setDownloading(value: boolean, option: string): any {
    this.isDownloading.next(value);

    if (option === 'all') {
      this.isDownloadingAll.next(value);
    }
    else if (option === 'selected') {
      this.isDownloadingSelected.next(value);
    }
  }

  public startDownload(docs: Array<DocumentFileMap>, documentRequestType: number, option?: 'all' | 'selected'): void {
    // start download
    this.setDownloading(true, option);

    // if we received an emppty list do not go further
    if (docs.length === 0) {
      this.setDownloading(false, option);

      return;
    }

    // a unique list of documents with key as document url and value as an object storing needed data
    const docFiles = {};

    // unique file get requests so we request files only once
    const getRequestsUnique = [];

    const rules = regexRules.filter((rule) => rule.tag === RegexTags.FileNameRegex);
    const [regexRule] = rules;

    docs.forEach((doc) => {
      // get file name without special characters
      const fileNameWithoutSpecialCharacters = doc.fileName.replace(new RegExp(regexRule.rule, 'g'), '_');

      // existing file
      if (doc.link in docFiles) {
        // add document to already existing file entry
        docFiles[doc.link].documents.push({
          documentId: doc.documentId,
          fileName: fileNameWithoutSpecialCharacters
        });
      }
      // new file found
      else {
        // for study and submission outcomes, original file name is being stored
        // in document name, as study and Pi Name is being added to fileName
        const documentName = doc.documentName ?? doc.fileName;
        // add file entry to docFiles only once
        docFiles[doc.link] = {
          documentName: documentName,
          documents: [{
            documentId: doc.documentId,
            fileName: fileNameWithoutSpecialCharacters
          }]
        };

        // add file get request to unique list of get requests
        getRequestsUnique.push(
          this.http.get(
            doc.link,
            {
              responseType: 'arraybuffer',
              observe: 'response',
              headers: new HttpHeaders({ DocumentDownload: '' })
            }
          )
            .pipe(catchError((error) => of(error)))
        );
      }
    });

    forkJoin(getRequestsUnique).subscribe((responses: HttpResponse<ArrayBuffer>[]) => {
      if (docs.length === 1) {
        if (responses[0].ok) {
          const blob = new Blob([responses[0].body], { type: 'application/octet-stream' });
          const contentDisposition = responses[0].headers.get('content-disposition');
          saveAs(blob, this.checkAndAppendFileExtension(docs[0].fileName, contentDisposition));
          // audit: update last download date for  succeded document
          this.apiService.patchDocumentsLastDownloadDate([docs[0].documentId], documentRequestType).subscribe();
        }
        else {
          // show error
          this.showToastError(this.translatePipe.transform('documentDownload.failedToDownloadFile'), 'downloadFailed');
        }
        this.setDownloading(false, option);
      }
      else {
        // start create zip file with all documents
        const zipFileName = `${moment(new Date()).format(this.utilService.fileDownloadDateFormat).toUpperCase()}.zip`;
        const zip = new JSZip();

        // document file names with number of occurences, for when adding same file name to zip
        const fileOccurences = {};
        // use set to store only unique failed document names
        const failedDocuments = new Set();
        let numberOfFailedResponses = 0;

        // store succeeded Ids for audit
        const succededDocumentIds = [];

        responses.forEach((response) => {
          if (response.ok) {
            const fileExtension = response.headers.get('content-disposition');
            // iterate thorough all documents associated to this file
            docFiles[response.url].documents.forEach((document) => {
              const { fileName } = document;
              succededDocumentIds.push(document.documentId);
              // store file name occurences, avoid adding same file name in zip
              if (fileName in fileOccurences) {
                fileOccurences[fileName] += 1;
              }
              else {
                fileOccurences[fileName] = 1;
              }
              // check if same file is being added more than once to zip, if yes append index to name
              const appendToFileName = fileOccurences[fileName] === 1 ? '' : `-${fileOccurences[fileName] - 1}`;
              const documentFileName = `${this.checkAndAppendFileExtension(fileName, fileExtension, appendToFileName)}`;
              zip.file(documentFileName, response.body);
            });
          }
          else {
            numberOfFailedResponses += 1;
            failedDocuments.add(docFiles[decodeURIComponent(response.url)].documentName);
          }
        });

        // add failed data to zip
        if (numberOfFailedResponses > 0) {
          // if all files failed to download
          if (numberOfFailedResponses === responses.length) {
            this.showToastError(this.translatePipe.transform('documentDownload.allFilesFailedToDownload'), 'multipleDownloadFailed');
            this.setDownloading(false, option);

            return;
          }

          const failedTitle = `${this.translatePipe.transform('documentDownload.followingDocumentsFailed')}\n`;
          const failuresFile = new Blob([[failedTitle, ...failedDocuments].join('\n')], {
            type: 'text/plain',
            endings: 'native'
          });
          zip.file(`${this.translatePipe.transform('documentDownload.failedDownloadsFileName')}.txt`, failuresFile);
        }

        zip.generateAsync({ type: 'blob' })
          .then((blob: Blob) => {
            saveAs(blob, zipFileName);
            // audit: update last download date for all succeded documents
            this.apiService.patchDocumentsLastDownloadDate(succededDocumentIds, documentRequestType).subscribe();

            if (failedDocuments.size > 0) {
              this.showToastError(this.translatePipe.transform('documentDownload.someFilesFailedToDownload'), 'someDownloadFailed');
            }
            this.setDownloading(false, option);
          });
      }
    }, (err) => {
      this.showToastError(err.message, 'downloadFailed');
      this.setDownloading(false, option);
    });
  }

  // shows a closable toast error message
  private showToastError(error: string, erorId: string): void {
    this.toastService.add([{
      closable: true,
      id: erorId,
      message: error,
      variant: 'error',
      timeout: 5000
    }]);
  }

  private checkAndAppendFileExtension = (filename: string, contentDisposition: string, appendFileName?: string): string => {
    const contentDispositionExtension = contentDisposition?.split('.').pop();

    if (contentDisposition?.indexOf('.') === -1 || Boolean(contentDispositionExtension) === false) {
      return filename;
    }

    const filenameExtension = filename.split('.').pop();
    let newFileName = contentDispositionExtension === filenameExtension ? filename : `${filename}.${contentDispositionExtension}`;

    if (appendFileName) {
      newFileName = `${newFileName.substring(0, newFileName.lastIndexOf('.'))}${appendFileName}.${contentDispositionExtension}`;
    }

    return newFileName;
  }
}
