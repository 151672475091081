<div>
  <div *ngIf="estimatedOutcomeDate" class="outcome-section">
    <div class="outcome-icon-container">
      <wcg-icon class="outcome-icon" src="../assets/icons/calendar.svg"></wcg-icon>
    </div>

    <p class="wcg-body-label outcome-date">
      {{ 'submissionWorkflow.estimatedOutcome' | translate }}
      <br />
      <span class="wcg-text-highlight">
        {{ estimatedOutcomeDate }}
      </span>
    </p>

    <p>{{ 'submissionWorkflow.estimatedOutcomeDescription' | translate }}</p>
  </div>
  <div *ngIf="showHelp" class="outcome-section">
    <div class="outcome-icon-container">
      <wcg-icon class="outcome-icon" src="../assets/icons/hint.svg"></wcg-icon>
    </div>

    <div class="wcg-rhythm-2">
      <h2 class="wcg-h5">
        {{ 'submissionWorkflow.needHelp' | translate }}
      </h2>
      <span>
        {{ 'submissionWorkflow.contact' | translate }}:
      </span>
      <a class="wcg-link" href="tel:{{ 'help.phoneValue' | translate }}">
        {{ 'help.phoneValue' | translate }}
      </a>
      <br />
      <span>
        {{ 'submissionWorkflow.hours' | translate }}
      </span>
      <span>
        {{ 'help.hoursValue' | translate }},
        {{ 'help.daysValue' | translate }}
      </span>
    </div>

    <a class="wcg-link" href="mailto:{{ supportEmail }}">
            {{ 'submissionWorkflow.email' | translate }}
    </a>

  </div>
</div>
