<div class="wcg-container wcg-center-xs">
  <wcg-icon src="./assets/icons/403-icon.svg"></wcg-icon>
  <h1 *ngIf="canRequestAccess">You do not have access to this page.</h1>
  <h1 *ngIf="!canRequestAccess">Sorry, but you do not have <br> access to this page.</h1>
  <p class="wcg-rhythm-5" *ngIf="canRequestAccess">Request access and you'll receive a notification when <br> the page is made available to you.</p>
  <div class="wcg-row wcg-center-xs wcg-rhythm-5" *ngIf="canRequestAccess" (click)="requestAccess()">
    <button class="wcg-btn wcg-btn-primary wcg-btn-large">Request access</button>
  </div>
  <a class="wcg-link" routerLink="/dashboard">
    <wcg-icon src="../assets/icons/hardware/ic_keyboard_backspace_24px.svg"></wcg-icon>
    Go back to the dashboard
  </a>
</div>
<wcg-loader [show]="isShowLoader"></wcg-loader>