import { Component } from '@angular/core';

@Component({
  selector: 'app-viewsettings',
  templateUrl: 'view-settings.component.html',
  styleUrls: ['./view-settings.component.scss']
})

export class ViewSettingsComponent {
  constructor() {
  }
}
