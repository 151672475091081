/* eslint-disable max-lines */
/* eslint-disable no-negated-condition */
/* eslint-disable max-lines */
import { UserAdminService } from './../../shared/services/user-admin.service';
import { Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { takeUntil, delay, debounce } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base/base.component';
import { LoggerService } from 'src/app/services/logger.service';
import { UserInfoGrid, UserInfoMap, UserStatus } from 'src/app/admin/shared/model/userinfo';
import { IdValueModel } from '../../shared/model/sharedmodels';
import { FilterOptionModel, SortOptionModel, GridOptionsModel, GridOptionsSearchModel } from '../../shared/model/grid.model';
import { Router } from '@angular/router';
import { of, timer } from 'rxjs';
import { UtilService } from '../../../services/util.service';
import { TableHeader } from '../../../interfaces/components';
import { UsersSortOptions, DEBOUNCE_TIME, flatPickrDateFormat, UsersFilterOptions } from 'src/app/common/collections';
import { ToastService } from 'src/app/services/toast.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { FlatpickrOptions } from 'ng2-flatpickr';
import * as moment from 'moment';
import { HttpCancelService } from 'src/app/services/http-cancel.service';
import { StateService } from 'src/app/services/state.service';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { StartImpersonationUrl } from 'src/app/common/apis';
import { DomSanitizer } from '@angular/platform-browser';
import { GridExportType } from 'src/app/common/reportingCollections';

const TIMEOUT = 5000;

@Component({
  selector: 'app-view-user-info',
  templateUrl: './view-user-info.component.html',
  styleUrls: ['./view-user-info.component.scss']
})

export class ViewUserInfoComponent extends BaseComponent implements OnInit {
  @ViewChild('startDatePicker') startDatePicker;
  @ViewChild('endDatePicker') endDatePicker;

  readonly exportType: GridExportType = GridExportType.Users;
  gridFilters: GridOptionsSearchModel;
  userInfos: Array<UserInfoMap>;
  searchForm: FormGroup;
  userInfoForm: FormGroup;
  allData: Array<UserInfoMap>;

  searchControl: FormControl;
  searchTerm = '';

  modalTitle: string;
  organizationTypesFilter: IdValueModel[] = [];
  userStatusFilter: string[] = [];
  filtersForm: FormGroup;
  selectionLabel: string;
  selected: Array<UserInfoMap> = [];
  noSelection: boolean;
  usersToDeactivate: string[] = [];
  usersToActivate: string[] = [];
  statusButtonLabel: string;

  disabledImpersonationButton = true;
  loaded = false;
  loading = true;

  currentPage = 1;
  currentPageSize = 10;
  totalRecords: number;
  headers: Array<TableHeader>;

  sortOptionsDefaultSort: SortOptionModel = {
    field: UsersSortOptions.lastName,
    isDescending: false
  }

  options = {
    manualPaginationAndSort: true,
    rowSelection: true,
    rowsPerPage: 10
  };

  gridOptionsModel: GridOptionsModel = {
    page: 1,
    pageSize: 10,
    sortOptions: [],
    filterOptions: []
  }

  datePickerOptions: FlatpickrOptions = {
    dateFormat: flatPickrDateFormat
  };

  impersonate: string;
  baseLinkImpersonate = StartImpersonationUrl;

  isExportDisabled = true;

  constructor(
    private userAdminService: UserAdminService,
    private router: Router,
    private loggerService: LoggerService,
    private formBuilder: FormBuilder,
    public utilService: UtilService,
    public stateService: StateService,
    private toastService: ToastService,
    private translatePipe: TranslatePipe,
    private httpCancelService: HttpCancelService,
    private authService: AuthenticationService,
    private domSanitizer: DomSanitizer
  ) {
    super();
  }

  public getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('admin.lastName'), 'lastName', true),
      this.utilService.createTableHeader(this.translatePipe.transform('admin.firstName'), 'firstName', true),
      this.utilService.createTableHeader(this.translatePipe.transform('admin.email'), 'email', true),
      this.utilService.createTableHeader(this.translatePipe.transform('admin.organization'), 'organizationName', true),
      this.utilService.createTableHeader(this.translatePipe.transform('admin.organizationType'), 'organizationType', true),
      this.utilService.createTableHeader(
        this.translatePipe.transform('admin.created'), 'createdDateTimeOffset', true, 'date', this.utilService.dateFormat
      ),
      this.utilService.createTableHeader(
        this.translatePipe.transform('admin.lastLogin'), 'lastActivityDate', true, 'date', this.utilService.dateFormat
      ),
      this.utilService.createTableHeader(this.translatePipe.transform('admin.status'), 'enabled', false)
    ];
  }

  public ngOnInit(): void {
    this.headers = this.getHeaders();

    this.filtersForm = this.formBuilder.group({
      organizationType: new FormControl(),
      userStatus: new FormControl(),
      searchControl: new FormControl(),
      startDateFilter: new FormControl(''),
      endDateFilter: new FormControl('')
    });

    this.filtersForm.controls.startDateFilter.valueChanges.subscribe((val) => this.dateFilterValueChanged(val, 'StartDate'));

    this.filtersForm.controls.endDateFilter.valueChanges.subscribe((val) => this.dateFilterValueChanged(val, 'EndDate'));

    this.filtersForm.controls.searchControl.valueChanges.pipe(debounce(() => timer(DEBOUNCE_TIME))).subscribe((term) => {
      if (term.replace(/\s/g, '').length < 1 && term.length > 0) {
        return;
      }
      this.onSearchChange(term.trim());
    });

    this.gridOptionsModel.sortOptions = [this.sortOptionsDefaultSort];
    this.postUserInfoGrid();

    this.userStatusFilter = [UserStatus.userActive.displayName, UserStatus.userInactive.displayName];
    this.userAdminService.getOrganizationTypes().subscribe((result: IdValueModel[]) => {
      this.organizationTypesFilter = result;
    });
  }

  public toggleDatePicker(datePicker: string): void {
    this[datePicker].flatpickr.toggle();
  }

  public onSearchChange(term: string): void {
    this.searchTerm = term;

    this.gridOptionsModel.page = 1;

    this.postUserInfoGrid();
  }

  public handleRowClicked(event): void {
    if (event.detail.event.target.hasAttribute('edit-row-button') ||
      event.detail.event.target.parentElement.hasAttribute('edit-row-button') ||
      event.detail.event.target.parentElement.parentElement.hasAttribute('edit-row-button')) {
      const user = event.detail;
      this.router.navigate(['admin/users/user-details', user.id]);
    }
  }

  /* eslint-disable max-len */
  /* eslint-disable complexity */
  public handleRowSelection(event): void {
    this.selected = event.detail;
    this.selectionLabel = this.selected.length === 1 ? `${this.selected.length} record selected` : `${this.selected.length} records selected`;
    if (this.selected && this.selected.length > 0) {
      this.usersToDeactivate = this.selected.filter((usr) => usr.isDeleted === false).map((ele) => ele.id);
      this.usersToActivate = this.selected.filter((usr) => usr.isDeleted).map((ele) => ele.id);

      if (this.usersToDeactivate && this.usersToDeactivate.length > 0 && this.usersToActivate && this.usersToActivate.length > 0) {
        this.statusButtonLabel = 'Activate and Deactivate';
      }
      else if (this.usersToDeactivate && this.usersToDeactivate.length > 0) {
        this.statusButtonLabel = 'Deactivate';
      }
      else if (this.usersToActivate && this.usersToActivate.length > 0) {
        this.statusButtonLabel = 'Activate';
      }
      if (this.selected.length === 1) {
        const canBeImpersonatedUser = this.selected.filter((usr) => usr.isDeleted === false && usr.canBeImpersonated && usr.isActive);
        if (canBeImpersonatedUser.length === 1) {
          this.disabledImpersonationButton = false;
          this.settingRedirectLink(canBeImpersonatedUser[0].id);
        }
        else {
          this.disabledImpersonationButton = true;
        }
      }
      else {
        this.disabledImpersonationButton = true;
      }
    }
  }

  public handleSort(event): void {
    this.gridOptionsModel.page = 1;
    this.gridOptionsModel.sortOptions = [{
      field: UsersSortOptions[event.detail.field],
      isDescending: !event.detail.ascending
    }];
    this.postUserInfoGrid();
  }

  public handlePageChange(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;
    this.gridOptionsModel.pageSize = event.detail.pageSize;
    this.gridOptionsModel.page = event.detail.page;
    this.postUserInfoGrid();
  }

  public usersStatusUpdated(): void {
    if (this.selected && this.selected.length) {
      if (this.usersToDeactivate.length > 0) {
        this.usersActivatedDeactivated(this.usersToDeactivate, true);
      }

      if (this.usersToActivate.length > 0) {
        this.usersActivatedDeactivated(this.usersToActivate, false);
      }
    }
    else {
      this.noSelection = true;

      of(null).pipe(takeUntil(this.unsubscribe), delay(TIMEOUT))
        .subscribe(() => {
          this.noSelection = false;
        });
    }
  }

  public resetSelected(): void {
    if (this.selected && this.selected.length) {
      this.selected.forEach((usr) => {
        let { email } = usr;
        const spanElement = new DOMParser().parseFromString(this.domSanitizer.sanitize(SecurityContext.HTML, email), 'text/html').querySelector('span');
        if (spanElement) {
          email = spanElement.innerText || spanElement.textContent;
        }
        this.userAdminService.postResetPassword(email)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(() => {
            this.toastService.add([{
              closable: true,
              id: 'usersResetPasswordSuccessful',
              message: this.translatePipe.transform('adminUser.usersResetPasswordSuccessful'),
              timeout: 5000,
              variant: 'success'
            }]);
          }, (err) => {
            this.loggerService.error(`Failed to reset password. ${err}`);
            this.toastService.add([{
              closable: true,
              id: 'usersResetPasswordFailure',
              message: this.translatePipe.transform('adminUser.usersResetPasswordFailure'),
              timeout: 5000,
              variant: 'error'
            }]);
          });
      });

      this.gridOptionsModel.page = 1;
      this.gridOptionsModel.sortOptions = [this.sortOptionsDefaultSort];

      this.postUserInfoGrid();
    }
    else {
      this.noSelection = true;

      of(null)
        .pipe(
          takeUntil(this.unsubscribe),
          delay(TIMEOUT)
        )
        .subscribe(() => {
          this.noSelection = false;
        });
    }
  }

  public organizationTypeChange(event: Event): void {
    event.preventDefault();
    const selectedOrganizationType = this.filtersForm.controls.organizationType.value;

    const filterOptions = this.gridOptionsModel.filterOptions.filter((itm) => itm.field !== 'UserOrganizationType');

    if (selectedOrganizationType !== 'All organization types') {
      const filterOption = new FilterOptionModel();
      filterOption.field = UsersFilterOptions.UserOrganizationType;
      filterOption.operator = 'Equals';
      filterOption.values = [selectedOrganizationType];

      filterOptions.push(filterOption);
    }

    this.gridOptionsModel.page = 1;
    this.gridOptionsModel.sortOptions = [this.sortOptionsDefaultSort];
    this.gridOptionsModel.filterOptions = filterOptions;
    this.postUserInfoGrid();
  }

  public statusChange(event: Event): void {
    event.preventDefault();
    const userStatus = this.filtersForm.controls.userStatus.value;
    const filterOptions = this.gridOptionsModel.filterOptions.filter((itm) => itm.field !== 'UserStatus');

    if (userStatus !== 'All status') {
      const filterOption = new FilterOptionModel();
      filterOption.field = UsersFilterOptions.UserStatus;
      filterOption.operator = 'Equals';
      filterOption.values = [userStatus !== 'Active'];

      filterOptions.push(filterOption);
    }

    this.gridOptionsModel.page = 1;
    this.gridOptionsModel.sortOptions = [this.sortOptionsDefaultSort];
    this.gridOptionsModel.filterOptions = filterOptions;
    this.postUserInfoGrid();
  }

  public settingRedirectLink(userId: string): void {
    this.impersonate = `${this.baseLinkImpersonate}${userId}`;
  }

  public impersonateSelected(): void {
    const baseLink = this.impersonate;
    this.authService.logoutForImpersonate();
    window.location.href = this.domSanitizer.sanitize(SecurityContext.URL, baseLink);
  }

  private dateFilterValueChanged(val: any, filterName: string): void {
    if (filterName === 'StartDate') {
      this.jumpStartDates(val[0]);
      [this.endDatePicker.flatpickr.config.minDate] = val;
    }

    if (filterName === 'EndDate') {
      this.jumpEndDates(val[0]);
      [this.startDatePicker.flatpickr.config.maxDate] = val;
    }

    this.pushDatesToFilterOption('UserCreatedDate', UsersFilterOptions.UserCreatedDate);
    this.pushDatesToFilterOption('UserLastActivityDate', UsersFilterOptions.UserLastActivityDate);

    this.gridOptionsModel.page = 1;
    this.postUserInfoGrid();
  }

  private jumpStartDates(value: any): void {
    if (value) {
      if (!this.filtersForm.controls.endDateFilter.value[0]) {
        this.endDatePicker.flatpickr.jumpToDate(value);
      }
    }
    else if (this.filtersForm.controls.endDateFilter.value[0]) {
      this.startDatePicker.flatpickr.jumpToDate(this.filtersForm.controls.endDateFilter.value[0]);
    }
    else {
      this.endDatePicker.flatpickr.jumpToDate(new Date());
    }
  }

  private jumpEndDates(value: any): void {
    if (value) {
      if (!this.filtersForm.controls.startDateFilter.value[0]) {
        this.startDatePicker.flatpickr.jumpToDate(value);
      }
    }
    else if (this.filtersForm.controls.startDateFilter.value[0]) {
      this.endDatePicker.flatpickr.jumpToDate(this.filtersForm.controls.startDateFilter.value[0]);
    }
    else {
      this.startDatePicker.flatpickr.jumpToDate(new Date());
    }
  }

  private pushDatesToFilterOption(filterName: string, filterField: UsersFilterOptions): void {
    const filterOptions = this.gridOptionsModel.filterOptions.filter((itm) => itm.field !== filterName);

    if (this.filtersForm.controls.startDateFilter.value[0] || this.filtersForm.controls.endDateFilter.value[0]) {
      const filterOption = new FilterOptionModel();
      filterOption.field = filterField;

      filterOptions.push(filterOption);
    }

    this.gridOptionsModel.filterOptions = filterOptions;
  }

  private postUserInfoGrid(): void {
    this.loading = true;

    this.httpCancelService.cancelPostAdminUsers();

    const utcStartDate = this.filtersForm.controls.startDateFilter.value[0] ? moment(this.filtersForm.controls.startDateFilter.value[0]).utc(false) : null;
    const utcEndDate = this.filtersForm.controls.endDateFilter.value[0] ? moment(this.filtersForm.controls.endDateFilter.value[0]).utc(false) : null;

    this.gridFilters = {
      page: this.gridOptionsModel.page,
      pageSize: this.gridOptionsModel.pageSize,
      searchTerm: this.searchTerm,
      filterOptions: this.gridOptionsModel.filterOptions,
      sortOptions: this.gridOptionsModel.sortOptions,
      startDate: utcStartDate,
      endDate: utcEndDate
    };

    this.userAdminService.postUserInfo(this.gridOptionsModel.page, this.gridOptionsModel.pageSize, this.gridOptionsModel.sortOptions, this.gridOptionsModel.filterOptions,
      this.searchTerm, utcStartDate, utcEndDate)
      .pipe(takeUntil(this.httpCancelService.onCancelPostAdminUsers()))
      .subscribe((data: UserInfoGrid) => {
        this.loaded = true;
        this.loading = false;
        this.totalRecords = data.totalRecords;
        this.currentPage = this.gridOptionsModel.page;

        this.allData = this.userAdminService.mapUserInfoGridData(data,
          {
            searchTerm: this.searchTerm,
            startDate: this.filtersForm.controls.startDateFilter.value[0],
            endDate: this.filtersForm.controls.endDateFilter.value[0]
          });
        this.selected = [];
        if (this.gridOptionsModel.filterOptions.length > 0 || this.searchTerm) {
          this.isExportDisabled = false;
        }
        else {
          this.isExportDisabled = true;
        }
      }, (err) => {
        this.loaded = true;
        this.loading = false;
        this.toastService.add([{
          closable: true,
          id: 'postUserInfoFailure',
          message: err !== null && err.statusText ? err.statusText : 'Error while getting users info',
          timeout: this.toastService.ERROR_TIMEOUT,
          variant: 'error'
        }]);
      });
  }

  private usersActivatedDeactivated(items: any, flag: boolean): void {
    this.userAdminService.postSetUsersStatus(items, flag)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.toastService.add([{
          closable: true,
          id: 'usersStatusSuccessful',
          message: this.translatePipe.transform('adminUser.usersStatusSuccessful'),
          timeout: 5000,
          variant: 'success'
        }]);

        this.gridOptionsModel.page = 1;
        this.gridOptionsModel.sortOptions = [this.sortOptionsDefaultSort];
        this.postUserInfoGrid();
      }, (err) => {
        this.loggerService.error(`Failed to change user status. ${err}`);
        this.toastService.add([{
          closable: true,
          id: 'usersStatusFailure',
          message: this.translatePipe.transform('adminUser.usersStatusFailure'),
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }
}
