import { Routes } from '@angular/router';
import { ProfileComponent } from '../views/profile/profile.component';
import { AuthGuard } from '../guards/auth.guard';

export const profileRoutes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [
      AuthGuard
    ]
  }
];
