<wcg-modal [show]="showModal" (close)="cancelClick()">
  <wcg-modal-header>
    <h2 class="wcg-h3">
      {{ this.submissionWorkflowService.saved.value ? ('submissionExitModal.saveAndExitHeader' | translate ) : ('submissionExitModal.exitHeader' | translate ) }}
    </h2>
  </wcg-modal-header>

  <wcg-modal-body>
    <p>
      {{ this.submissionWorkflowService.saved.value ? ('submissionExitModal.saveAndExitContent' | translate ) : ('submissionExitModal.exitContent' | translate ) }}
    </p>
  </wcg-modal-body>

  <wcg-modal-footer>
    <div class="cnxs-secondary-actions">
      <button class="wcg-btn wcg-btn-auto" type="button" (click)="cancelClick()">{{ 'submissionActions.return' | translate }}</button>

      <button *ngIf="this.submissionWorkflowService.saved.value" class="wcg-btn wcg-btn-warn wcg-btn-auto" type="button" (click)="deleteClick()">{{ 'submissionActions.deleteDraft' | translate }}</button>
    </div>

    <button class="wcg-btn wcg-btn-primary wcg-btn-auto" type="button" (click)="saveClick()">
      {{ this.submissionWorkflowService.saved.value ? ('submissionActions.saveExit' | translate) : ('submissionActions.exit' | translate) }}
    </button>
  </wcg-modal-footer>
</wcg-modal>
