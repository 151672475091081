import { Routes } from '@angular/router';
import { StudiesComponent } from '../views/studies/studies.component';
import { AuthGuard } from '../guards/auth.guard';
import { SubmissionsComponent } from '../views/submissions/submissions.component';
import { StudyComponent } from '../views/study/study.component';
import { StudyOutcomeDocumentsComponent } from '../views/study/study-outcome-documents/study-outcome-documents.component';
import { StudyContactsComponent } from '../views/study/study-contacts/study-contacts.component';
import { StudySitesComponent } from '../views/study/study-sites/study-sites.component';
import { StudySubmissionsComponent } from '../views/study/study-submissions/study-submissions.component';
import { ManageContactsComponent } from '../views/manage-contacts/manage-contacts.component';
import { ManageContactsContactsComponent } from '../views/manage-contacts/manage-contacts-contacts/manage-contacts-contacts.component';
import { ManageContactsRequestsComponent } from '../views/manage-contacts/manage-contacts-requests/manage-contacts-requests.component';
import { RequestAccessEntityType } from '../common/collections';

export const studyRoutes: Routes = [{
  path: 'studies',
  component: StudiesComponent,
  canActivate: [
    AuthGuard
  ],
  children: [
    {
      path: 'submissions',
      component: SubmissionsComponent
    }
  ]
},
{
  path: 'studies/:id',
  component: StudyComponent,
  canActivate: [
    AuthGuard
  ],
  data: {
    canRequestAccess: true,
    entityType: RequestAccessEntityType.study
  },
  children: [
    {
      path: '',
      redirectTo: 'sites',
      pathMatch: 'full',
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.study
      }
    },
    {
      path: 'outcome-documents',
      component: StudyOutcomeDocumentsComponent,
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.study
      }
    },
    {
      path: 'contacts',
      component: StudyContactsComponent,
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.study
      }
    },
    {
      path: 'sites',
      component: StudySitesComponent,
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.study
      }
    },
    {
      path: 'submissions',
      component: StudySubmissionsComponent,
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.study
      }
    }
  ]
},
{
  path: 'studies/:id/manage-contacts',
  component: ManageContactsComponent,
  canActivate: [
    AuthGuard
  ],
  children: [
    {
      path: '',
      redirectTo: 'contacts',
      pathMatch: 'full',
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.study
      }
    },
    {
      path: 'contacts',
      component: ManageContactsContactsComponent,
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.study
      }
    },
    {
      path: 'requests',
      component: ManageContactsRequestsComponent,
      data: {
        canRequestAccess: true,
        entityType: RequestAccessEntityType.study
      }
    }
  ]
}];
