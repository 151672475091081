<div class="wcg-container cnxs-loader-container">
  <div *ngIf="(sitesLoaded && initialSites?.length)">
    <form ngForm class="wcg-rhythm-5">
      <wcg-form-field>
        <wcg-label>{{ 'actions.search' | translate }}</wcg-label>
        <wcg-input [formControl]="searchTerm">
          <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
        </wcg-input>
      </wcg-form-field>
    </form>
    <div class="wcg-row">
      <div class="wcg-col-xs-4 wcg-col-md-12 wcg-col-lg-12 wcg-rhythm-2">
        <div class="export-button-wrap">
          <app-export-action-button [gridFilters]="gridFilters" [disableButton]="!tableData?.length"
            [exportType]="exportType" [entityId]="id"></app-export-action-button>
        </div>
      </div>
    </div>
    <wcg-table class="wcg-rhythm-4" [ngClass]="{'cnxs-table-loading': loading}" [headers]="headers" [data]="tableData"
      [options]="options" (sortClicked)="handleSorting($event)">
      <div class="cnxs-table-loader" slot="table-body">
        <wcg-loader [show]="!loaded || loading"></wcg-loader>
      </div>
      <div class="cnxs-pagination-wrap wcg-table-footer" *ngIf="loaded">
        <div class="wcg-total-results">
          {{ 'tables.totalResults' | translate}}: {{totalRecords}}
        </div>
        <div class="pagination">
          <wcg-pagination *ngIf="tableData?.length" 
            [current]="currentPage" [pageSize]="currentPageSize"
            [max]="totalPages" (changePage)="handleChangePage($event)">
          </wcg-pagination>
        </div>
      </div>
    </wcg-table>
  </div>
  <wcg-loader [show]="!sitesLoaded"></wcg-loader>
  <app-no-records *ngIf="sitesLoaded && !initialSites?.length">
    <p class="wcg-h2">{{ 'submissionDetails.noSites' | translate }}</p>
  </app-no-records>
</div>