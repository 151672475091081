import { environment } from '../../environments/environment';

export enum Apis {
  // Contact
  CreateOrganization = '/api/contacts/organizations/createTeam',
  ContactCountries = '/api/contacts/countries',
  ContactIRBOrganizations = '/api/contacts/organizations/irb',
  ContactOrganizations = '/api/contacts/organizations',
  ContactOrganizationsSearch = '/api/contacts/organizations/search',
  ContactOrganizationTypes = '/api/contacts/organizations/types',
  ContactOrganizationTypesByScope = '/api/contacts/organizations/scopes/{scopeId}/types',
  ContactStates = '/api/contacts/countries/{countryid}/states',
  ReviewTypes = '/api/contacts/reviewtypes',
  ValidateEmail = '/api/contacts/organization/email/validate?email={email}',
  OrganizationTeam = '/api/contacts/organizations/teams/{teamId}',
  TeamStudies = '/api/contacts/{organizationId}/studies',
  OrganizationTeamSites = '/api/contacts/{organizationId}/sites',
  TeamMembers = '/api/contacts/organizations/{teamId}/members',

  // Sites
  Site = '/api/sites/{id}',
  SiteContacts = '/api/sites/{id}/contacts',
  SiteDocuments = '/api/sites/{id}/documents',
  SitesGrid = '/api/sites/grid',
  SitePi = '/api/sites/{id}/pi',
  SiteSubmissions = '/api/sites/{id}/submissions',
  SiteOutcome = '/api/sites/{id}/outcome',
  SiteOutcomeDownload = '/api/v1.0/sites/{id}/download',
  SitePermissions = '/api/sites/{id}/permissions',
  SiteList = '/api/sites?piName={piName}&&irbTrackingNumber={irbTrackingNumber}',
  SiteSubmissionDocumentsBulkDownloadSelectedRequest = '/api/v1.0/sites/{siteId}/submissions/downloadrequest',
  SiteSubmissionDocumentsBulkDownloadAllRequest = '/api/v1.0/sites/{siteId}/submissions/downloadAll',

  // Studies
  StudiesGrid = '/api/studies/grid',
  StudyList = '/api/studies/workspaces?searchTerm={searchTerm}',
  Study = '/api/studies/{id}',
  StudyContacts = '/api/studies/{id}/contacts',
  StudyDocuments = '/api/studies/{id}/documents',
  StudySites = '/api/studies/{id}/sites',
  StudySitesSubmit = '/api/studies/{id}/sites/submit',
  StudySubmissions = '/api/studies/{id}/submissions',
  StudiesSubmit = '/api/studies/submitByStudySites',
  StudyOutcome = '/api/studies/{id}/outcome',
  StudyPermissions = '/api/studies/{id}/permissions',
  StudyOutcomeDocumentDetails = '/api/studies/{studyId}/outcome/{documentId}/details',
  StudyOutcomeDocumentDownloadRequest = '/api/v1.0/study/{studyId}/outcome/{documentId}/downloadrequest',
  AllStudyOutcomeDocumentDetails = '/api/studies/{studyId}/outcome/allDetails',
  StudyOutcomeDownloadAll = '/api/v1.0/study/{studyId}/downloadAll/{isStudyOnly}',
  StudySubmissionDocumentsBulkDownloadRequest = '/api/v1.0/study/{studyId}/submission/downloadrequest',
  StudySubmissionDocumentsBulkDownloadAll = '/api/v1.0/study/{studyId}/submissions/downloadAll',

  // Submissions
  GetDraftSubmissionById = '/api/submissions/{submissionId}/draft',
  Submission = '/api/submissions/{submissionId}',
  SubmissionDocuments = '/api/submissions/{submissionId}/documents',
  SubmissionEstimatedOutcomeDate = '/api/submissions/estimated/date?numberOfDays={days}',
  SubmissionOutcome = '/api/submissions/{submissionId}/outcome',
  SubmissionTypeGroups = '/api/submissions/submissionTypeGroups',
  SubmissionTypeGroup = '/api/submissions/types/group/{groupId}',
  SubmissionSites = '/api/submissions/{submissionId}/sites',
  SubmissionsCreate = '/api/submissions/create',
  SubmissionsDashboardNeedsAction = '/api/submissions/dashboard/needsAction',
  SubmissionsDashboardInProgress = '/api/submissions/dashboard/inProgress',
  SubmissionsDashboardDrafts = '/api/submissions/dashboard/drafts',
  SubmissionsCreateDraft = '/api/submissions/create/draft',
  SubmissionsDashboard = '/api/submissions/dashboard',
  SubmissionsDeleteDraft = '/api/submissions/delete/draft/{submissionId}',
  SubmissionsGrid = '/api/submissions/grid',
  SubmissionFormType = '/api/submissions/formType/{submissionTypeId}',
  SubmissionOutcomeDocumentDetails = '/api/submissions/{submissionId}/outcome/{documentId}/details',
  SubmissionRequestAccess = '/api/submissions/{submissionId}/requests',
  SubmissionCheckRequestAccess = '/api/submissions/{submissionId}/validate/request/access',
  AllSubmissionOutcomeDocumentDetails = '/api/submissions/{submissionId}/outcome/allDetails',
  SubmissionOutcomeDocumentDownloadRequest = '/api/v1.0/submission/{submissionId}/outcome/{documentId}/downloadrequest',
  AllSubmissionOutcomeDocumentDownloadAsync = '/api/v1/Submission/{submissionId}/outcome/download/{isStudyOnly}',

  // User
  CurrentUser = '/api/users/current',
  SetUsersStatus = '/api/users/setStatus?isDeleted={isDeleted}',
  UserDetails = '/api/users/{userid}/details',
  UserProfile = '/api/users',
  UserResetPassword = '/api/users/password/forgot',
  UserRoles = '/api/users/roles',
  Users = '/api/users/fo',
  PrimaryUsers = '/api/users/primary',
  UsersStatus = '/api/users/invite/status',

  // Documents
  DocumentDelete = '/api/documents/storage/delete',
  DocumentLinks = '/api/documents/sas',
  DocumentProtected = '/api/documents/file/validateProtection',
  DocumentTypes = '/api/documents/types',
  DocumentTypesBySubmissionTypeId = '/api/documents/types/{submissionTypeId}',
  LastDownloadDateBatch = '/api/documents/updatebatch/lastdownloaddate',
  OutcomeDocumentDownload = '/api/v1/documents',
  SasForDocumentUpload = '/api/documents/sas/documentupload',
  SubmittedDocumentDownload = '/api/documents/sas',

  // Workspaces
  WorkspaceInvite = '/api/workspaces/{id}/invite/{permissionType}',
  WorkspaceRequests = '/api/workspaces/{workspaceId}/requests',
  WorkspaceRequestsApprove = '/api/workspaces/{workspaceId}/requests/{userId}/approve',
  WorkspaceRequestsReject = '/api/workspaces/{workspaceId}/requests/{userId}/reject',
  WorkspaceRestrict = '/api/workspaces/{workspaceId}/restrict',
  WorkspacesUser = '/api/workspaces/user/{userid}',
  WorkspaceUser = '/api/workspaces/{workspaceId}/user/{userId}',
  WorkspaceUsers = '/api/workspaces/{id}/users',
  WorkspaceSearch = '/api/workspaces/search?workspaceType={workspaceType}&&searchTerm={searchTerm}&&irbTrackingNumber={irbTrackingNumber}',
  getWorkspaceByExternalReferenceId = '/api/workspaces/{workspaceExternalReferenceId}',
  WorkSpaceRequestsByAccessModel = '/api/workspaces/requests',
  SendFailedRequests = '/api/workspaces/SendFailedRequests',
  AddWorkspaceOrganization = '/api/workspaces/organizations',
  TeamMembersWorkspaceAccessRequest = '/api/workspaces/{workspaceExternalReferenceId}/teams/{teamExternalReferenceId}/members',
  TeamWorkspaceRequestsApproveOrReject = '/api/workspaces/{workspaceId}/requests/teams/{teamId}/handle',
  GetUserAuditData = '/api/workspaces/auditAccessReport/{irbTracking}',
  BulkWorkspaceInvite = '/api/workspaces/invite/{permissionType}',
  BulkWorkspaceRestrict = '/api/workspaces/restrict',
  BulkWorkspaceUpdate = '/api/workspaces/permissions/{permissionType}',

  // Resources
  Resources = '/api/resource/',
  StorageFilePath = '/api/resource/sas/{storageFilePath}',

  // Configuration
  ConfigurationValue = '/api/configuration/{configurationKey}',
  SettingValue = '/api/configuration/settings/{settingValue}',

  // Teams
  Teams = '/api/contacts/teams',
  UpdateTeam = '/api/workspaces/teamOrganizations/{teamId}',
  AddTeamUser = '/api/contacts/organizations/{teamId}/addUser',
  DeleteTeamWorkspace = '/api/workspaces/delete/organizations/{teamId}',
  DeleteTeamUser = '/api/workspaces/organizations/teams/{teamId}/deleteUser',
  SetTeamOrganizationStatus = '/api/workspaces/organizations/teams/{teamId}/status',
  removeTeamsFromWorkspace = '/api/workspaces/{workspaceId}/teams',

  // Reporting / Downloads
  UserExports = '/api/v1.0/exportgrid/getuserexports',
  GetExportFileSas = '/api/v1.0/exportgrid/getExportFileSas',
  UserDownloads = '/api/v1.0/document/user/downloads',
  GetOutcomeDocumentDownloadUrl = '/api/v1.0/document/outcome/download/{rowKey}/sas',
  OutcomeDocumentsDownloadFromEmail = '/api/v1.0/contact/outcomeDocumentDownload/{outcomeDocumentExternalReferenceId}',

  // Impersonation
  StopImpersonation = 'Account/stopImpersonation',
  StartImpersonation = 'Account/impersonate/'
}

export const ApiBaseUrl = environment.apiBaseUrl;
export const ApiReportingUrl = environment.apiReportingUrl;
export const ApiServiceUrl = environment.apiServiceUrl;
export const BackOfficeBaseUrl = environment.backOfficeBaseUrl;
export const ChangePasswordUrl = environment.changePasswordUrl;
export const StopImpersonationUrl = `${environment.identity.authority}${Apis.StopImpersonation}`;
export const StartImpersonationUrl = `${environment.identity.authority}${Apis.StartImpersonation}`;
