<app-workflow-container
  [header]="'submissionLanding.headerTitle' | translate"
  hasFooter="true"
>
  <close-button>
    <button class="wcg-btn wcg-btn-icon" (click)="this.submissionWorkflowService.closeWorkflow()">
      <wcg-icon src="../../assets/icons/navigation/ic_close_24px.svg"></wcg-icon>
      <span class="wcg-visually-hidden">{{ 'submissionWorkflow.close' | translate }}</span>
    </button>
  </close-button>

  <main-content>
    <div class="cnxs-review-section cnxs-review-section-intro">
      <h2 class="wcg-h5">{{ 'submissionWorkflow.setup' | translate }}</h2>
      <p class="wcg-h2">{{ supportingText }}</p>
    </div>

    <div class="cnxs-review-section cnxs-fieldset-section">
      <form name="cnxs-submission-review-selection" [formGroup]="regionForm">
        <fieldset class="cnxs-fieldset" formGroupName="regions">
          <legend class="cnxs-legend">{{ 'submissionRegions.legend' | translate }}</legend>

          <div *ngFor="let type of submissionWorkflowService.submissionReviewTypes.value">
            <wcg-checkbox formControlName={{type.id}}>{{ type.value }}</wcg-checkbox>
          </div>
        </fieldset>
      </form>
    </div>
  </main-content>

  <footer-content>
    <div class="cnxs-form-footer-content">
      <button class="wcg-link cnxs-arrow-btn cnxs-prev" (click)="backToSubmissionTypes()">
        <wcg-icon class="cnxs-arrow cnxs-back-arrow" src="../assets/icons/navigation/ic_arrow_back_24px.svg"></wcg-icon>
        {{ 'submissionWorkflow.back' | translate }}
      </button>

      <button type="submit" class="wcg-link cnxs-arrow-btn cnxs-next" [disabled]="this.regionForm.controls.regions.invalid" (click)="continueClick()">
        {{ 'submissionWorkflow.continueSetup' | translate }}
        <wcg-icon class="cnxs-arrow cnxs-next-arrow" src="../assets/icons/navigation/ic_arrow_forward_24px.svg"></wcg-icon>
      </button>
    </div>
  </footer-content>
</app-workflow-container>
