/* eslint-disable max-lines */
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { takeUntil, debounce } from 'rxjs/operators';

import { ApiService } from '../../services/api.service';
import { CountryCodes, Prefixes, Suffixes, DEBOUNCE_TIME } from '../../common/collections';
import { LoggerService } from '../../services/logger.service';
import { ChangePasswordUrl } from '../../common/apis';
import {
  ContactCountries,
  ContactOrganizationType,
  ContactOrganizationTypes,
  ContactStates,
  UserInfoDetailsMap,
  UserRole,
  UserRoles,
  UserWorkspaceGridRecordMap,
  ContactCountry,
  ContactOrganizations
} from '../../interfaces/user';
import { BaseComponent } from '../../components/base/base.component';
import { ProfileService } from './profile.service';
import { UserService } from 'src/app/services/user.service';
import { UtilService } from '../../services/util.service';
import { Organization, OrganizationAutocompleteMap } from '../../interfaces/common';
import { noWhitespaceValidator } from '../../validators/no.whitespace.validator';
import { timer } from 'rxjs';
import { UserAdminService } from '../../admin/shared/services/user-admin.service';
import { ToastService } from '../../services/toast.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { GridOptionsSearchModel } from 'src/app/admin/shared/model/grid.model';
import { GridExportType } from 'src/app/common/reportingCollections';

const adminId = 1;
const clientRelationsId = 2;
const adminIds = [adminId, clientRelationsId];
const getLastActivityDate = (date: string): string => date === null ? new Date().toDateString() : date;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BaseComponent implements OnInit {
  tableData: Array<UserWorkspaceGridRecordMap>;
  adminCollectionsFetched = false;
  changePasswordUrl = `${ChangePasswordUrl}${window.location.href}`;
  countries: ContactCountries;
  formData = new FormGroup({
    firstName: new FormControl('', [Validators.required, noWhitespaceValidator]),
    middleName: new FormControl(''),
    lastName: new FormControl('', [Validators.required, noWhitespaceValidator]),
    prefix: new FormControl(''),
    suffix: new FormControl(''),
    degrees: new FormControl(''),
    phone: new FormControl('', [Validators.required, noWhitespaceValidator]),
    country: new FormControl('', [Validators.required, Validators.pattern('^(?!.*placeholder).*$')]),
    addressLine1: new FormControl('', [Validators.required, noWhitespaceValidator]),
    addressLine2: new FormControl(''),
    city: new FormControl('', [Validators.required, noWhitespaceValidator]),
    state: new FormControl('', [Validators.required, Validators.pattern('^(?!.*placeholder).*$')]),
    province: new FormControl('', Validators.required),
    postalCode: new FormControl('', [Validators.required, noWhitespaceValidator]),
    email: new FormControl('', [Validators.required, noWhitespaceValidator]),
    organization: new FormControl({
      value: '',
      selectedValues: []
    }),
    isEmailNotificationEnabled: new FormControl('')
  });

  lastActivityDate: string;
  organizations: ContactOrganizations;
  allOrganizations: Array<OrganizationAutocompleteMap>
  organizationTypes: ContactOrganizationTypes;
  prefixes = Prefixes;
  previousCountry = 0;
  roles: UserRoles;
  saveProfileFailure: boolean;
  searchTerm = new FormControl('');
  showUserDetailsModal = false;
  states: ContactStates;
  suffixes = Suffixes;
  user: UserInfoDetailsMap;
  userDetailsLoaded = false;

  isAdmin = false;
  role: UserRole;
  organization: Organization;
  organizationType: ContactOrganizationType;
  usa: ContactCountry;
  canada: ContactCountry;

  stateLabel: string;

  loaded: boolean;
  loading: boolean;

  headers = [
    {
      name: 'IRB Tracking ID',
      field: 'IRB Tracking ID',
      sortable: true
    },
    {
      name: 'Sponsor Protocol ID',
      field: 'Sponsor Protocol ID',
      sortable: true
    },
    {
      name: 'PI Name',
      field: 'PI Name',
      sortable: true
    },
    {
      name: 'Permissions',
      field: 'Permissions',
      sortable: true
    }
  ];

  options = {
    rowsPerPage: 10
  };

  gridFilters: GridOptionsSearchModel = new GridOptionsSearchModel();
  readonly exportType: GridExportType = GridExportType.UserWorkspaces;

  private selectedOrgObj: OrganizationAutocompleteMap;

  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private loggerService: LoggerService,
    private profileService: ProfileService,
    private translatePipe: TranslatePipe,
    private toastService: ToastService,
    private utilService: UtilService,
    private userService: UserService,
    private userAdminService: UserAdminService
  ) {
    super();
  }

  ngOnInit(): void {
    this.profileService.searchTerm.next('');

    this.formData.controls.organization.setValidators([
      (): any => {
        if (!this.selectedOrgObj?.value) {
          return {
            autoCompleteInvalid: true
          };
        }

        return null;
      }
    ]);

    this.userService.userInfo.subscribe((data) => {
      this.user = data;
      // Check an attribute that is returned by the user details call but not the current user call
      if (data.phone && !this.userDetailsLoaded) {
        this.isAdmin = Boolean(adminIds.find((item) => item === this.user.role.id));
        this.lastActivityDate = this.utilService.formatDate(getLastActivityDate(this.user.lastActivityDate));
        this.organizationType = this.user.organization?.organizationType;
        this.userDetailsLoaded = true;

        this.selectedOrgObj = {
          value: this.user.organization.name,
          option: this.user.organization.id,
          organizationType: this.user.organization.organizationType
        };

        if (this.isAdmin) {
          this.formData.controls.organization.valueChanges.pipe(
            debounce(() => timer(DEBOUNCE_TIME))
          ).subscribe((org) => {
            if (org?.value?.length > 1) {
              this.getOrganizations(org.value);
            }
          });

          this.fetchAdminCollections(this.adminCollectionsFetched);
        }

        this.getCountries(this.countries);
        this.getUserWorkspaces({
          searchTerm: ''
        });

        this.searchOptions();

        this.profileService.mapUserDataToProfile(this.formData, this.user);
        this.cdr.detectChanges();
      }
    });
  }

  searchOptions(): void{
    this.searchTerm.valueChanges
      .pipe(
        debounce(() => timer(DEBOUNCE_TIME))
      )
      .subscribe((val) => {
        this.profileService.searchTerm.next(val);
        this.getUserWorkspaces({
          searchTerm: val
        });

        this.gridFilters.searchTerm = val;
      });
  }

  revertForm(): void {
    this.showUserDetailsModal = false;
    this.profileService.mapUserDataToProfile(this.formData, this.user);
    const userCountry = this.user.address.country.id;

    const found = [this.usa.id, this.canada.id].find((country) => country === userCountry);
    if (found) {
      this.getStates(userCountry);
    }
    else {
      this.states = [];
    }
  }

  handleCountryChange(event): void {
    this.updateStates(parseInt(event.target.value, 10), false);
  }

  fetchAdminCollections(fetched): void {
    if (fetched) {
      return;
    }

    this.getRoles();
    this.getOrganizationTypes();
    this.adminCollectionsFetched = true;
  }

  public doShowUserDetailsModal(): void {
    this.revertForm();
    this.showUserDetailsModal = true;
  }

  public getOrganizationType(): string {
    const org = this.organizations?.find((item) => item.id === this.formData.controls.organization.value.value);

    return org?.organizationType?.value;
  }

  public getCountries(countries): void {
    if (countries) {
      return;
    }

    this.apiService.getContactCountries()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.countries = data;
        this.usa = this.countries.find((country) => country.alpha3Code === CountryCodes.UNITED_STATES);
        this.canada = this.countries.find((country) => country.alpha3Code === CountryCodes.CANADA);
        this.updateStates(this.formData.value.country, true);
      }, (err) => {
        this.loggerService.error(`Countries could not be fetched. ${err}`);
      });
  }

  public getOrganizations(filter): void {
    this.userAdminService.getOrganizations(filter)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.organizations = data;
        this.allOrganizations = this.mapOrganizations(data);
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'fetchOrganizations',
          message: `User Organizations could not be fetched. ${err}`,
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  public updateOrganization(event): void {
    if (event.detail.value === event.detail.option) {
      event.preventDefault();

      return;
    }

    this.selectedOrgObj = this.allOrganizations.find((org) => org.value === event.detail.value);

    if (this.selectedOrgObj) {
      this.organizationType.value = this.selectedOrgObj.organizationType?.value;
      this.formData.controls.organization.setValue({
        value: this.selectedOrgObj.option,
        data: this.selectedOrgObj,
        selectedValues: []
      });
    }
  }

  public getOrganizationTypes(): void {
    this.apiService.getContactOrganizationTypes()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.organizationTypes = data;
      }, (err) => {
        this.loggerService.error(`Organization Types could not be fetched. ${err}`);
      });
  }

  public getRoles(): void {
    this.apiService.getUserRoles()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.roles = data;
      }, (err) => {
        this.loggerService.error(`Roles could not be fetched. ${err}`);
      });
  }

  public getStates(countryId: number): void {
    this.apiService.getContactStates(countryId.toString())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.states = data;
      }, (err) => {
        this.loggerService.error(`States could not be fetched. ${err}`);
      });
  }

  public getUserWorkspaces(body): void {
    this.loading = true;

    this.apiService.getUserWorkspaces(this.user.externalReferenceId, body)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.tableData = this.profileService.mapProfileGridData(data);
        this.loaded = true;
        this.loading = false;
      }, (err) => {
        this.loaded = true;
        this.loading = false;
        this.loggerService.error(`User Workspaces could not be fetched. ${err}`);
      });
  }

  public mapOrganizations = (data: Array<Organization>): Array<OrganizationAutocompleteMap> => data.map((record) => ({
    option: record.name,
    value: record.id,
    organizationType: record.organizationType
  }));

  public mapProfileForPost(): object {
    const data = this.formData.value;
    const userProfilePayload = this.user;
    const country = this.countries?.find((item) => item.id === parseInt(data.country, 10));
    const state = this.states?.find((item) => item.id === parseInt(data.state, 10));

    userProfilePayload.isEmailNotificationEnabled = data.isEmailNotificationEnabled;
    userProfilePayload.firstName = data.firstName;
    userProfilePayload.middleName = data.middleName;
    userProfilePayload.lastName = data.lastName;
    userProfilePayload.prefix = data.prefix === '-1' ? '' : data.prefix;
    userProfilePayload.suffix = data.suffix === '-1' ? '' : data.suffix;
    userProfilePayload.degrees = data.degrees;
    userProfilePayload.phone = data.phone;
    userProfilePayload.email = data.email;
    userProfilePayload.address = {
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      postalCode: data.postalCode,
      country: country,
      state: state,
      province: data.province
    };

    if (this.isAdmin) {
      if (this.formData.controls.organization?.value?.data?.id) {
        userProfilePayload.organization = this.organizations?.find(
          (org) => org.id === this.user.organization.id,
        );
      }
      else {
        userProfilePayload.organization = this.organizations?.find(
          (org) => org.id === this.selectedOrgObj.value,
        );
      }
    }

    return userProfilePayload;
  }

  public postUserProfile(): void {
    this.apiService.postUserProfile(this.mapProfileForPost())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        // To resolve conflict error
        this.user.concurrencyVersion = data.concurrencyVersion;
        this.user.detailsConcurrencyVersion = data.detailsConcurrencyVersion;
        this.showUserDetailsModal = false;
        this.saveProfileFailure = false;
        this.toastService.add([{
          closable: true,
          id: 'saveSuccess',
          message: this.translatePipe.transform('profile.saveProfileSuccessful'),
          timeout: 5000,
          variant: 'success'
        }]);
      }, (err) => {
        this.loggerService.error(`User Profile could not be saved. ${err}`);
        this.saveProfileFailure = true;
      });
  }

  public updateStates(value: number, initial?: boolean): void {
    if (this.countries) {
      const found = [this.usa.id, this.canada.id].find((country) => country === value);
      const foundPrevious = [this.usa.id, this.canada.id].find((country) => country === this.previousCountry);

      this.stateLabel = value === this.usa.id ? 'profile.profileFormState' : 'profile.profileFormProvince';
      this.resetStateValues(initial, value, found, foundPrevious);
      this.previousCountry = value;
    }
  }

  // Reset values on country change to require state selection
  private resetStateValues(initial, value, found, foundPrevious): void {
    if (found) {
      if (!initial) {
        this.formData.patchValue({ province: ' ' });
        this.formData.patchValue({ state: 'placeholder' });
      }
      this.getStates(value);
    }
    else {
      if (!initial) {
        if (isNaN(value)) {
          this.formData.patchValue({ province: '' });
          this.formData.patchValue({ state: ' ' });
        }
        else {
          this.formData.patchValue({ state: ' ' });

          if (foundPrevious) {
            this.formData.patchValue({ province: '' });
          }
        }
      }
      this.states = [];
    }
  }
}
