import { Injectable } from '@angular/core';
import { SubmissionsGrid, SubmissionsGridRecordMap } from '../../interfaces/submissions';
import { UtilService } from '../../services/util.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubmissionsService {
  public endDate = new BehaviorSubject<Date>(null);
  public startDate = new BehaviorSubject<Date>(null);
  public searchTerm = new BehaviorSubject<string>('');
  constructor(private utilService: UtilService) { }

  public mapSubmissionGridData(data: SubmissionsGrid): Array<SubmissionsGridRecordMap> {
    return data.records.map((record) => ({
      id: record.id,
      name: record.title,
      nameLink: this.utilService.addTableCellWrapperWithSearchTerms(
        record.title, this.searchTerm.value,
        `submissions/${record.id}`
      ),
      type: this.utilService.addTableCellWrapperWithSearchTerms(record.type, this.searchTerm.value),
      sponsor: this.utilService.addTableCellWrapperWithSearchTerms(record.sponsor, this.searchTerm.value),
      protocolNumber: this.utilService.addTableCellWrapperWithSearchTerms(record.protocolNumber, this.searchTerm.value),
      piName: this.utilService.addTableCellWrapperWithSearchTerms(record.site.value, this.searchTerm.value),
      submittedDate: this.utilService.highlightDateFields(record.submittedDate, this.startDate.value, this.endDate.value),
      irbTrackingNumber: this.utilService.addTableCellWrapperWithSearchTerms(record.irbTrackingNumber, this.searchTerm.value),
      status: this.utilService.addTableCellWrapperWithSearchTerms(record.status, this.searchTerm.value),
      outcome: this.utilService.addTableCellWrapper(record.outcome)
    }));
  }
}
