import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { Router } from '@angular/router';
import { SubmissionWorkflowService } from '../submission-workflow.service';
import { ToastService } from '../../../services/toast.service';
import { StateService } from 'src/app/services/state.service';
import { BaseSubmissionComponent } from 'src/app/components/base-submission/base-submission.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})

export class LandingComponent extends BaseSubmissionComponent implements OnInit {
  constructor(
    router: Router,
    stateService: StateService,
    apiService: ApiService,
    submissionWorkflowService: SubmissionWorkflowService,
    toastService: ToastService
  ) {
    super(router, stateService, apiService, submissionWorkflowService, toastService);
    this.submissionWorkflowService.originUrl.next('');
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
