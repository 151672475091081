<wcg-table
  class="wcg-rhythm-4"
  [ngClass]="{'cnxs-table-loading': loading}"
  [headers]="headers"
  [data]="users"
  [options]="options"
  (rowClicked)="handleRowClick($event)">
  <div class="cnxs-table-loader" slot="table-body">
    <wcg-loader [show]="loading"></wcg-loader>
  </div>
</wcg-table>
<wcg-modal class="create-team-modal" id="modal" [show]="teamMembersModal" (close)="closeClick()">
  <wcg-modal-header>
    <span>{{ 'teams.viewTeamMembersNr' | translate : {numberOfTeamMembers: this.totalRecords} }}</span>
</wcg-modal-header>
<wcg-modal-body>
  <p class="wcg-h3 wcg-rhythm-1 cnxs-wrap-content inline-flex">{{this.teamName}}</p>
  <wcg-table
    class="wcg-rhythm-4 memberTable"
    [headers]="membersHeaders"
    [data]="tableData"
    [options]="memberOptions">
  </wcg-table>
 </wcg-modal-body>
</wcg-modal> 