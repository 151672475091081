<div class="wcg-container cnxs-loader-container">
  <div class="wcg-row wcg-v-center">
    <div class="wcg-col-xs-8 wcg-col-md-8 wcg-col-lg-8">
      <button *ngIf="isTeamActive && !hasSelectedRows && (canManageStudies || isAdmin)" class="wcg-btn wcg-btn-icon" (click)="stateService.showTeamWorkspaceRequestModal.next(true)">
        <wcg-icon src="./assets/icons/content/ic_add_circle_outline_24px.svg"></wcg-icon>
        <span>{{ 'teams.addWorkspaceLabel' | translate: {workspaceType: 'Study'} }}</span>
      </button>
      <button *ngIf="isTeamActive && hasSelectedRows && (canManageStudies || isAdmin)" class="wcg-btn wcg-btn-icon remove-study" (click)="showDeleteModal = true">
        <wcg-icon src="./assets/icons/content/ic_remove_circle_24px.svg"></wcg-icon>
        <span>{{ 'teams.deleteWorkspaceLabel' | translate: {workspaceType: 'Study'} }}</span>
      </button> 
    </div>
    <form ngForm *ngIf="(studiesFetched && initialStudies?.records?.length)" class="wcg-col-xs-4 wcg-col-md-4 wcg-col-lg-4">
      <wcg-form-field>
        <wcg-label>{{ 'actions.search' | translate }}</wcg-label>
        <wcg-input [formControl]="searchTerm" [value]="searchTerm.value">
          <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
        </wcg-input>
      </wcg-form-field>
    </form>
  </div>
  <app-team-workspace-request-modal [show]="stateService.showTeamWorkspaceRequestModal.value" [organizationId]="organizationId"
  [modalType]="modalType" [isRequest]="!isAdmin"></app-team-workspace-request-modal>
  
  <wcg-table *ngIf="(studiesFetched && initialStudies?.records?.length)" class="wcg-rhythm-4" [ngClass]="{'cnxs-table-loading': loading}" [headers]="headers" [data]="tableData"
    [options]="options" (rowClicked)="handleRowClicked($event)" (rowSelected)="handleRowSelection($event)" (sortClicked)="handleSort($event)">

    <div class="cnxs-table-loader" slot="table-body">
      <wcg-loader [show]="!loaded || loading"></wcg-loader>
    </div>

    <div class="cnxs-pagination-wrap">
      <wcg-pagination *ngIf="loaded && tableData?.length" 
        [current]="currentPage" [pageSize]="currentPageSize"
        [max]="utilService.maxPages(totalRecords, currentPageSize)" (changePage)="handlePageChange($event)"></wcg-pagination>
    </div>
  </wcg-table>

<wcg-modal id="modal" [show]="showDeleteModal" (close)="closeDeleteModal()">
  <wcg-modal-header>{{ 'teams.deleteWorkspaceModalTitle' | translate: {workspaceTypes: 'Study(ies)'} }}</wcg-modal-header>
  <wcg-modal-body>
    <p>{{ 'teams.deleteWorkspaceInfo1' | translate: {workspaceTypes: 'Study(ies)'} }}</p>
    <p>{{ 'teams.deleteWorkspaceInfo2' | translate: {workspaceTypes: 'Study(ies)'} | translate: {workspaceType: 'Study'} }}</p>
  </wcg-modal-body>
  <wcg-modal-footer>
<button class="wcg-btn" (click)="closeDeleteModal()">{{ 'teams.cancel' | translate }}</button>
<button class="wcg-btn wcg-btn-primary" [disabled]="isRemoving" (click)="deleteSelectedRows()">{{ 'teams.deleteWorkspaceBtn' | translate: {workspaceTypes: 'Study(ies)'} }}</button>
  </wcg-modal-footer>
</wcg-modal>

<wcg-loader [show]="!studiesFetched"></wcg-loader>
<app-no-records  *ngIf="studiesFetched && !initialStudies?.records?.length">
  <p class="wcg-h2 no-records">{{ 'teams.noStudies' | translate }}</p>
</app-no-records>
</div>