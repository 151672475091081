<wcg-modal [show]="stateService.showExportCsvModal.value || stateService.showDownloadModal.value" (close)="checkForFileLater($event)">
    <wcg-modal-header>
        <h2 class="wcg-h3">
            {{'downloadModal.submittedRequest' | translate }}
        </h2>
    </wcg-modal-header>

    <wcg-modal-body>
        <div *ngIf="stateService.showExportCsvModal.value">
            <p>
                {{'exportModal.severalMinutesToCompleteExport' | translate }}
            </p>
            <p>
                {{'downloadModal.downloadTheFinishedFileFrom' | translate }}
                <button class="wcg-btn wcg-btn-icon header-profile-button">
                    <wcg-icon src="../assets/icons/action/ic_account_circle_24px.svg"></wcg-icon>
                    <wcg-icon class="header-profile-dropdown-icon"
                        src="../assets/icons/navigation/ic_arrow_drop_down_24px.svg">
                    </wcg-icon>
                </button>
            </p>
        </div>
        
        <div *ngIf="stateService.showDownloadModal.value">
            <p>
                {{ 'downloadModal.severalMinutesToComplete' | translate }}
            </p>
            <p>
                {{'downloadModal.downloadTheFinishedFileFrom' | translate }}
                <button class="wcg-btn wcg-btn-icon header-profile-button">
                    <wcg-icon src="../assets/icons/action/ic_account_circle_24px.svg"></wcg-icon>
                    <wcg-icon class="header-profile-dropdown-icon"
                        src="../assets/icons/navigation/ic_arrow_drop_down_24px.svg">
                    </wcg-icon>
                </button>
            </p>
            <div *ngIf="stateService.submissionsWithoutSubmittedDocumentsList.value.length > 0">
                <p>
                    <b>{{ 'downloadModal.note' | translate }}</b> {{ 'downloadModal.submissionsNotIncludedInTheDownload' | translate }}
                </p> 
                <ul class="submission-list">
                    <li *ngFor="let submission of stateService.submissionsWithoutSubmittedDocumentsList.value" class="submission-item">{{ submission }}</li>
                </ul>      
            </div>
        </div>      
    </wcg-modal-body>

    <wcg-modal-footer>
        <div class="cnxs-secondary-actions">
            <button class="wcg-btn wcg-btn-auto" type="button"
                (click)="checkForFileLater()">{{'downloadModal.checkForFileLater' | translate }}</button>
        </div>
        
        <button class="wcg-btn wcg-btn-primary wcg-btn-auto" type="button" (click)="goToDownloadExportsPage()">
            {{'downloadModal.goToDownloadsPage' | translate}}
        </button>
    </wcg-modal-footer>
</wcg-modal>