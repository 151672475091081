import { Component } from '@angular/core';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

import { Submission, SubmissionOutcome } from '../../interfaces/submissions';
import { UtilService } from '../../services/util.service';
import { ToastService } from '../../services/toast.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-submissions-detail',
  templateUrl: './submission.component.html',
  styleUrls: ['./submission.component.scss']
})
export class SubmissionsDetailComponent extends BaseComponent {
  filteredOutcomeDocuments: Array<SubmissionOutcome>;
  id: string;
  outcomeDocuments: Array<SubmissionOutcome>;
  submission: Submission;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private toastService: ToastService,
    public utilService: UtilService,
    private translatePipe: TranslatePipe
  ) {
    super();

    [this.id] = this.router.url.split('/submissions/')[1].split('/');

    this.getSubmission(this.id);
  }

  private getSubmission(id): void {
    this.apiService.getSubmission(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.submission = data;
      }, () => {
        this.toastService.add([{
          closable: true,
          id: 'getSubmission',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          variant: 'error'
        }]);
      });
  }
}
