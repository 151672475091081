import { Pipe, PipeTransform } from '@angular/core';
import * as data from '../../assets/i18n/en.json';

const getValue = (object: object, path: string): {replace: (key: string, params: string) => null} => path
  .replace(/\[/g, '.')
  .replace(/\]/g, '')
  .split('.')
  // eslint-disable-next-line id-length
  .reduce((o, k) => (o || {})[k], object);

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
  // eslint-disable-next-line no-extra-parens
  strings: any = (data as any).default;

  transform(value: string, params?: object): any {
    let result = getValue(this.strings, value) || value;
    if (params) {
      // eslint-disable-next-line guard-for-in
      for (const key in params) {
        result = result.replace(`{${key}}`, `${params[key]}`);
      }
    }

    return result;
  }
}
