import { Injectable } from '@angular/core';
import {
  Study,
  StudyMap,
  StudyContactsMap,
  StudyContacts,
  StudySites,
  StudySitesMap,
  StudySubmissionsMap,
  StudySubmissions
} from '../../interfaces/studies';
import {
  WorkspaceOutcomeGetRes,
  WorkspaceOutcomeGetMap
} from '../../interfaces/workspaces';
import { UtilService } from '../../services/util.service';
import { PermissionType, PermissionTypeViewValues } from 'src/app/common/collections';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { Apis, BackOfficeBaseUrl } from '../../common/apis';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudyService {
  public endDate = new BehaviorSubject<Date>(null);
  public startDate = new BehaviorSubject<Date>(null);
  public searchTerm = new BehaviorSubject<string>('');


  constructor(
    private utilService: UtilService,
    private translatePipe: TranslatePipe
  ) { }

  public mapStudyData(data: Study): StudyMap {
    return {
      name: data.name,
      sponsor: data.sponsor,
      sponsorProtocolID: data.protocolNumber,
      initialApproval: data.initialApprovalDate ? this.utilService.formatDate(data.initialApprovalDate) : null,
      lastReview: data.lastReviewDate ? this.utilService.formatDate(data.lastReviewDate) : null,
      studyExpiration: data.expirationDate,
      irbTrackingNumber: data.irbTrackingNumber,
      status: data.status.value,
      studyClosedDate: data.closedDate ? this.utilService.formatDate(data.closedDate) : null
    };
  }

  public mapContactsData(data: Array<StudyContacts>): Array<StudyContactsMap> {
    return data.map((record) => ({
      permissionType: this.utilService.addTableCellWrapperWithSearchTerms(
        PermissionTypeViewValues[PermissionType[record.permissionType]], this.searchTerm.value
      ),
      contactName: this.utilService.addEmailToTableCellWrapperWithSearchTerms(record.contact.value, this.searchTerm.value, record.email),
      email: this.utilService.addTableCellWrapperWithSearchTerms(record.email, this.searchTerm.value),
      organizationName: this.utilService.addTableCellWrapperWithSearchTerms(record.organizationName, this.searchTerm.value),
      organizationType: this.utilService.addTableCellWrapperWithSearchTerms(record.organizationType, this.searchTerm.value)
    }));
  }

  public mapOutcomeDocumentsData(data: Array<WorkspaceOutcomeGetRes>): Array<WorkspaceOutcomeGetMap> {
    return data.map((record) => {
      const link = `${BackOfficeBaseUrl}${Apis.OutcomeDocumentDownload}/${record.irbDocumentId}`;
      const term = this.utilService.highlightSearchTerm(record.fileName, this.searchTerm.value);

      return {
        documentId: record.documentId,
        link: link,
        fileNameLink: this.utilService.getTableCellWrapper(
          record.fileName,
          '',
          `<button class="wcg-link data-download-outcome-document-button" title="${record.fileName.replace(/"/g, '')}">${term}</button>`
        ),
        fileName: record.fileName,
        documentType: this.utilService.addTableCellWrapperWithSearchTerms(record.documentType, this.searchTerm.value),
        reviewedDate: record.transmittedDate === null && record.reviewedDate ? this.utilService.highlightDateFields(
          record.reviewedDate, this.startDate.value, this.endDate.value
        ) : this.utilService.addTableCellWrapper(this.utilService.formatDate(record.reviewedDate)),
        transmittedDate: record.transmittedDate ? this.utilService.highlightDateFields(
          record.transmittedDate, this.startDate.value, this.endDate.value
        ) : this.utilService.addTableCellWrapper(this.utilService.formatDate(record.transmittedDate)),
        reviewDetailsLink: this.utilService.getTableCellWrapper(
          record.documentId,
          // eslint-disable-next-line max-len
          `<button class="wcg-link data-details-outcome-document-button">${this.translatePipe.transform('outcomeDocumentsDetailsModal.outcomeDocumentReviewDetails')}</button>`
        )
      };
    });
  }

  public mapSitesData(data: Array<StudySites>): Array<StudySitesMap> {
    return data.map((record) => ({
      countryName: this.utilService.addTableCellWrapperWithSearchTerms(record.countryName, this.searchTerm.value),
      institutionalTrackingNumber:
        this.utilService.addTableCellWrapperWithSearchTerms(record.institutionalTrackingNumber, this.searchTerm.value),
      piName: record.investigator,
      piNameLink: this.utilService.addTableCellWrapperWithSearchTerms(
        record.investigator,
        this.searchTerm.value,
        `sites/${record.id}`
      ),
      expirationDate:
        record.expirationDate ? this.utilService.highlightDateFields(
          record.expirationDate, this.startDate.value, this.endDate.value
        ) : null,
      status: this.utilService.addTableCellWrapperWithSearchTerms(record.status, this.searchTerm.value),
      piOrganizationName: this.utilService.addTableCellWrapperWithSearchTerms(
        record.piOrganizationName, this.searchTerm.value
      ),
      outcomeDocumentsDetails: this.utilService.getTableCellWrapper(this.translatePipe.transform('actions.viewDetails'), '',
        this.utilService.generateLink(record.id, this.translatePipe.transform('actions.viewDetails'), false, 'outcomeDoc'))
    }));
  }

  public mapSubmissionsData(data: Array<StudySubmissions>): Array<StudySubmissionsMap> {
    return data.map((record) => {
      let piString = this.translatePipe.transform('utility.nullReplacement');

      if (record.principalInvestigators && record.principalInvestigators[0] && record.principalInvestigators[0].firstName) {
        piString = record.principalInvestigators[0].firstName;
      }

      return {
        title: this.utilService.addTableCellWrapperWithSearchTerms(
          record.title,
          this.searchTerm.value,
          `/submissions/${record.id}`
        ),
        type: this.utilService.addTableCellWrapperWithSearchTerms(record.type.value, this.searchTerm.value),
        reviewOutcome: this.utilService.addTableCellWrapperWithSearchTerms(record.reviewOutcome, this.searchTerm.value),
        status: record.statuses ? this.utilService.addTableCellWrapperWithSearchTerms(
          record.statuses[0].value, this.searchTerm.value
        ) : null,
        submittedDate:
          record.submittedDate ? this.utilService.highlightDateFields(
            record.submittedDate, this.startDate.value, this.endDate.value
          ) : null,
        principalInvestigators: this.utilService.addTableCellWrapperWithSearchTerms(piString, this.searchTerm.value)
      };
    });
  }
}
