import { Component, OnInit } from '@angular/core';
import { SubmissionInfo } from '../../../interfaces/submissions';
import { SubmissionsDashboardPost } from '../../../interfaces/dashboard';
import { DashboardService } from '../dashboard.service';
import { BaseComponent } from '../../../components/base/base.component';
import { StateService } from '../../../services/state.service';
import * as cssVars from '../../../../assets/scss-variables.json';
import { BarChartOption } from '../../../interfaces/components';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { SubmissionsDashboardStatistic } from '../../../common/collections';
import { UtilService } from 'src/app/services/util.service';
import { PageSizeOptions } from 'src/app/models/page-size-options.model';
import { PaginationValues } from 'src/app/models/pagination-values.mode';

@Component({
  selector: 'app-dashboard-in-progress',
  templateUrl: './dashboard-in-progress.component.html',
  styleUrls: ['./dashboard-in-progress.component.scss']
})
export class DashboardInProgressComponent extends BaseComponent implements OnInit {
  barChartData: Array<BarChartOption>;

  inProgressData: SubmissionsDashboardPost;
  records: Array<SubmissionInfo> = [];
  pageSizeOptions: PageSizeOptions;
  viewTypeCard: boolean;

  constructor(
    public dashboardService: DashboardService,
    public stateService: StateService,
    public utilService: UtilService,
    private translatePipe: TranslatePipe
  ) {
    super();
    this.pageSizeOptions = this.dashboardService.GetPaginationConfiguration(this.dashboardService.inProgressPagination);
    this.viewTypeCard = this.dashboardService.cardView.getValue();
  }

  ngOnInit(): void {
    this.dashboardService.inProgress.subscribe((val) => {
      if (val) {
        this.inProgressData = val;
        this.records = this.dashboardService.mapDashboardRecords(val?.submissions?.records, 'inProgress');
        this.barChartData = this.getBarChartData(val);
        this.pageSizeOptions.page = val?.submissions?.currentPage;
        this.pageSizeOptions.pageSize = val?.submissions?.pageSize;
      }
    });

    this.dashboardService.viewListCardTypeUpdated.subscribe((val) => {
      if (val === null) {
        return;
      }

      if (this.viewTypeCard === this.dashboardService.cardView.getValue()) {
        return;
      }

      this.viewTypeCard = this.dashboardService.cardView.getValue();
      this.dashboardService.inProgressPagination =
        new PaginationValues(1, this.dashboardService.paginationDataConfiguration.pageSize);
      this.pageSizeOptions.page = 1;
      this.pageSizeOptions.pageSize = this.dashboardService.paginationDataConfiguration.pageSize;
      this.pageSizeOptions.pageSizeDataSource = this.dashboardService.paginationDataConfiguration.pageSizeDataSource;
    });
  }

  public filter(event): void {
    if (event.id === 'all') {
      this.dashboardService.statusFilterInProgress.next(null);
      this.setPaginationAndLoad(1, this.dashboardService.paginationDataConfiguration.pageSize);
    }
    else if (event.id !== this.dashboardService.statusFilterInProgress) {
      this.dashboardService.statusFilterInProgress.next(event.id);
      this.setPaginationAndLoad(1, this.dashboardService.paginationDataConfiguration.pageSize);
    }
  }

  public handlePageChange(event): void {
    this.setPaginationAndLoad(event.detail.page, event.detail.pageSize);
  }

  private setPaginationAndLoad(page, pageSize): void{
    this.dashboardService.inProgressPagination = new PaginationValues(page, pageSize);
    this.pageSizeOptions.page = page;
    this.pageSizeOptions.pageSize = pageSize;
    this.dashboardService.postInProgress();
  }

  private getBarChartData(data: SubmissionsDashboardPost): Array<BarChartOption> {
    return [
      {
        id: 'all',
        title: this.translatePipe.transform('dashboard.all')
      },
      {
        id: SubmissionsDashboardStatistic.received,
        title: this.translatePipe.transform('dashboard.received'),
        value: data.statistic[this.utilService.ignoreCaseComparison(data.statistic, SubmissionsDashboardStatistic.received)] || 0,
        iconColor: cssVars['$sea-foam-500'],
        iconHeight: '48px',
        iconWidth: '48px',
        icon: './assets/pictograms/received.svg'
      },
      {
        id: SubmissionsDashboardStatistic.preparingForBoardReview,
        title: this.translatePipe.transform('dashboard.preparingForBoardReview'),
        // eslint-disable-next-line max-len
        value: data.statistic[this.utilService.ignoreCaseComparison(data.statistic, SubmissionsDashboardStatistic.preparingForBoardReview)] || 0,
        iconColor: cssVars['$sea-foam-500'],
        iconHeight: '48px',
        iconWidth: '48px',
        icon: './assets/pictograms/preparing.svg'
      },
      {
        id: SubmissionsDashboardStatistic.boardReview,
        title: this.translatePipe.transform('dashboard.boardReview'),
        value: data.statistic[this.utilService.ignoreCaseComparison(data.statistic, SubmissionsDashboardStatistic.boardReview)] || 0,
        iconColor: cssVars['$sea-foam-500'],
        iconHeight: '48px',
        iconWidth: '48px',
        icon: './assets/pictograms/review.svg'
      },
      {
        id: SubmissionsDashboardStatistic.finalizingDocuments,
        title: this.translatePipe.transform('dashboard.finalizingDocuments'),
        // eslint-disable-next-line max-len
        value: data.statistic[this.utilService.ignoreCaseComparison(data.statistic, SubmissionsDashboardStatistic.finalizingDocuments)] || 0,
        iconColor: cssVars['$sea-foam-500'],
        iconHeight: '48px',
        iconWidth: '48px',
        icon: './assets/pictograms/finalizing.svg'
      }
    ];
  }
}
