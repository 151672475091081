import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../core/services/authentication/authentication.service';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ServerErrorCodes } from '../common/collections';

@Injectable({
  providedIn: 'root'
})
export class OAuthInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = req.headers
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE')
      .set('Access-Control-Allow-Headers', 'Content-Type');

    const token = this.authenticationService.getAccessToken();

    // when fetching documents from azure blob storage, do not use bearer token
    // when uploading document to azure blob storage, do not use bearer token
    if (token !== '' && !req.url.includes('blob') && !req.url.includes('&sig=')) {
      headers = req.headers.set('Authorization', `Bearer ${token}`);
    }

    const request = req.clone({ headers });

    if (req.headers.has('DocumentDownload')) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => event),
      catchError((error: HttpErrorResponse) => {
        if (error.status === ServerErrorCodes.accessDenyErrorStatusNumber) {
          this.router.navigate(['forbidden']);

          return;
        }

        if (error.status === ServerErrorCodes.notFoundErrorStatusNumber) {
          this.router.navigate(['page-not-found']);

          return;
        }

        return throwError(error);
      })
    );
  }
}
