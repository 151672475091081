import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil, debounce } from 'rxjs/operators';
import { SubmissionSitesMap } from '../../../interfaces/submissions';
import { ApiService } from 'src/app/services/api.service';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ToastService } from 'src/app/services/toast.service';
import { SubmissionsDetailService } from '../submission.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { TableHeader } from '../../../interfaces/components';
import { UtilService } from 'src/app/services/util.service';
import { DEBOUNCE_TIME, SubmissionSitesSortOptions } from 'src/app/common/collections';
import { timer } from 'rxjs';
import { GridOptionsSearchModel } from 'src/app/admin/shared/model/grid.model';
import { GridExportType } from 'src/app/common/reportingCollections';

@Component({
  selector: 'app-submission-sites',
  templateUrl: './submission-sites.component.html',
  styleUrls: ['./submission-sites.component.scss']
})
export class SubmissionSitesComponent extends BaseComponent implements OnInit {
  tableData: Array<SubmissionSitesMap>;
  id: string;
  searchTerm = new FormControl('');
  headers: Array<TableHeader>;
  sitesLoaded = false;
  loaded: boolean;
  loading: boolean;
  initialSites: Array<SubmissionSitesMap> = [];

  currentPage: number;
  currentPageSize: number;
  totalPages: number;
  totalRecords: number;
  currentSort: string;
  currentSortDescending = true;

  options = {
    manualPaginationAndSort: true
  };

  gridFilters: GridOptionsSearchModel;
  readonly exportType: GridExportType = GridExportType.SubmissionDetailsSites;

  constructor(
    private apiService: ApiService,
    private submissionDetailService: SubmissionsDetailService,
    private router: Router,
    private translatePipe: TranslatePipe,
    public utilService: UtilService,
    private toastService: ToastService
  ) {
    super();

    [this.id] = this.router.url.split('/submissions/')[1].split('/');
    this.currentPageSize = 10;
    this.postSubmissionSitesGrid(this.id, {
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: ''
    });
    this.headers = this.getHeaders();
  }

  ngOnInit(): void {
    this.searchTerm.valueChanges
      .pipe(
        debounce(() => timer(DEBOUNCE_TIME))
      )
      .subscribe((val) => {
        this.submissionDetailService.searchTerm.next(val);
        this.postSubmissionSitesGrid(this.id, {
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: val,
          sortOptions: this.currentSort ? [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }] : null
        });
      });
  }

  getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(
        this.translatePipe.transform('submissionDetails.submittedSitesInvestigator'), 'investigator', true
      ),
      this.utilService.createTableHeader(
        this.translatePipe.transform('submissionDetails.submittedSitesInvestigatorOrganization'), 'piOrganizationName', true
      ),
      this.utilService.createTableHeader(
        this.translatePipe.transform('submissionDetails.submittedSitesInstitutionTrackingId'), 'institutionalTrackingNumber', true
      ),
      this.utilService.createTableHeader(
        this.translatePipe.transform('submissionDetails.submittedSitesCountry'), 'countryName', true
      )
    ];
  }

  handleChangePage(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;

    this.postSubmissionSitesGrid(this.id, {
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: this.currentSort ? [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }] : null
    });
  }

  handleSorting(event): void {
    this.currentSort = SubmissionSitesSortOptions[event.detail.field];
    this.currentSortDescending = !event.detail.ascending;
    this.currentPage = 1;

    this.postSubmissionSitesGrid(this.id, {
      page: this.currentPage,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }]
    });
  }

  private postSubmissionSitesGrid(id, body): void {
    this.loading = true;
    this.gridFilters = body;

    this.apiService.getSubmissionSites(id, body)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (data.records && data.records.length > 0) {
          this.initialSites = data.records;
        }

        this.tableData = this.submissionDetailService.mapSitesData(data.records);
        this.sitesLoaded = true;
        this.loaded = true;
        this.loading = false;
        this.totalPages = Math.ceil(data.totalRecords / data.pageSize);
        this.totalRecords = data.totalRecords;
        this.currentPage = data.currentPage;
      }, () => {
        this.loaded = true;
        this.loading = false;
        this.sitesLoaded = true;
        this.toastService.add([{
          closable: true,
          id: 'getSubmissionSites',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          variant: 'error'
        }]);
      });
  }
}
