<section class="wcg-container cnxs-a-spot" [ngClass]="{ 'impersonationPaddingTop' : stateService.isImpersonationActive.value}">
  <wcg-icon class="cnxs-a-spot-bg" src="../assets/pictograms/microscope.svg"></wcg-icon>

  <div class="cnxs-a-spot-inner">
    <div class="wcg-row wcg-rhythm-2">
      <h1
        *ngIf="userService.userInfo | async as userInfo"
        class="wcg-h1 wcg-col-md-6">
        <span> {{ 'dashboard.welcomeMessage1' | translate: { name: userInfo.firstName } }} </span>
        <span class="wcg-text-highlight">{{(dashboardService.needsAction | async)?.newUpdates}} {{ 'dashboard.welcomeMessage2' | translate}}{{(dashboardService.needsAction | async)?.newUpdates === 1 ? '' : 's'}}</span> {{ 'dashboard.welcomeMessage3' | translate}}.
      </h1>
    </div>

    <div class="cnxs-last-login-wrap wcg-rhythm-4">
      <p *ngIf="needsActions" class="cnxs-last-login">{{ 'dashboard.lastLogin' | translate: { date: lastLoginDate(needsActions.lastLoginDate)} }}</p>
    </div>

    <div class="wcg-row">
      <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-3 wcg-rhythm-2">
        <a class="wcg-btn wcg-btn-fluid wcg-btn-primary" [routerLink]="['/submission-workflow']">{{ 'dashboard.makeSubmission' | translate}}</a>
      </div>
      <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-3 wcg-rhythm-2">
        <button class="wcg-btn wcg-btn-fluid"
          (click)="stateService.showRequestAccessModal.next(true)">{{ 'requestAccess.title' | translate }}</button>
      </div>
    </div>
  </div>
</section>

<section class="cnxs-secondary-section">
  <div class="wcg-container">
    <div class="wcg-row wcg-middle-xs">
      <form ngForm class="wcg-rhythm-1 wcg-col-xs-4 wcg-col-md-6">
        <wcg-form-field class="cnxs-dashboard-search">
          <wcg-label>{{ 'dashboard.search' | translate }}</wcg-label>
          <wcg-input [formControl]="dashboardService.searchTerm" [value]="dashboardService.searchTerm.value">
            <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
          </wcg-input>
        </wcg-form-field>
      </form>

      <div class="wcg-col-xs-4 wcg-col-md-2 wcg-end-xs">
        <button class="wcg-btn cnsx-toggle" [ngClass]="{'wcg-btn-primary': dashboardService.cardView.value}" (click)="toggleView(true)">
          <wcg-icon src="../assets/icons/navigation/ic_apps_24px.svg"></wcg-icon>
        </button>
        <button class="wcg-btn cnsx-toggle" [ngClass]="{'wcg-btn-primary': !dashboardService.cardView.value}" (click)="toggleView(false)">
          <wcg-icon src="../assets/icons/navigation/ic_menu_24px.svg"></wcg-icon>
        </button>
      </div>
    </div>

    <wcg-tab-bar-section>
      <wcg-tab-bar>
        <div class="cnxs-tab-bar-inner">
          <a [routerLink]="['needs-action']" queryParamsHandling="preserve" routerLinkActive="wcg-active" class="wcg-link">
            {{ 'tabs.needsAction' | translate }}
            <wcg-badge *ngIf="needsActions?.submissions.totalRecords">{{needsActions?.submissions.totalRecords}}</wcg-badge>
          </a>
          <a [routerLink]="['in-progress']" queryParamsHandling="preserve" routerLinkActive="wcg-active" class="wcg-link">
            {{ 'tabs.inProgress' | translate }}
            <wcg-badge *ngIf="inProgress?.submissions.totalRecords">{{inProgress?.submissions.totalRecords}}</wcg-badge>
          </a>
          <a [routerLink]="['drafts']" queryParamsHandling="preserve" routerLinkActive="wcg-active" class="wcg-link">
            {{ 'tabs.drafts' | translate }}
            <wcg-badge *ngIf="drafts?.submissions.totalRecords">{{drafts?.submissions.totalRecords}}</wcg-badge>
          </a>
        </div>
      </wcg-tab-bar>
      <div class="wcg-container cnxs-loader-container">
        <router-outlet></router-outlet>
      </div>
    </wcg-tab-bar-section>
  </div>
</section>

<app-request-access-modal
  [show]="stateService.showRequestAccessModal.value"
></app-request-access-modal>

<app-export-confirmation-modal></app-export-confirmation-modal>
