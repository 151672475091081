<div class="wcg-rhythm-5 pl-140">

    <div class="wrapper">
        <div>
          <wcg-icon class="submissions-icon ml-170" src="../assets/icons/ic_insert_drive_file_24px.svg"></wcg-icon>
        </div>
        <h1 class="wcg-h1 pt-15"><span class="text">Submissions</span></h1>
    </div>
    <div class="body">
      <h4>
        {{'adminSubmissions.searchSubTitle' | translate}}
      </h4>
    </div>
    <div>
      <form #manageForm="ngForm" [formGroup]="submissionsForm" class="" autocomplete="off">
        <div class="wcg-row">
          <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
            <ng2-flatpickr class="wcg-input-with-icon" #startDatePicker placeholder="Start Date"
              [config]="datePickerOptions" formControlName="startDateFilter">
              <wcg-icon class="date-icon" (click)="toggleStartDate()" src="./assets/icons/action/ic_date_range_24px.svg">
              </wcg-icon>
            </ng2-flatpickr>
          </div>

          <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
            <ng2-flatpickr class="wcg-input-with-icon" #endDatePicker placeholder="End Date" [config]="datePickerOptions"
            formControlName="endDateFilter">
              <wcg-icon class="date-icon" (click)="toggleEndDate()" src="./assets/icons/action/ic_date_range_24px.svg">
              </wcg-icon>
            </ng2-flatpickr>
          </div>

          <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
            <wcg-form-field class="search-form">
              <wcg-label>{{ 'adminSubmissions.irbTrackingID' | translate }}</wcg-label>
              <wcg-input ngDefaultControl formControlName="irbTrackingID"></wcg-input>
              <div *ngIf="(manageForm.submitted) ||
                (submissionsForm.controls.irbTrackingID.dirty || submissionsForm.controls.irbTrackingID.touched)">
                <wcg-error-message *ngIf="submissionsForm.controls.irbTrackingID.hasError('maxlength')">
                  {{ 'validation.maxChar' | translate: { fieldname: ('adminSubmissions.irbTrackingID' | translate), charLength: 255  } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>

          <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
            <wcg-form-field class="filter-form-field">
              <wcg-label>{{ 'adminSubmissions.submissionType' | translate }}</wcg-label>
              <wcg-select formControlName="submissionType">
                <option value="" selected></option>
                <option *ngFor="let type of submissionTypeFilter" value={{type.value}}>
                  {{type.value}}
                </option>
              </wcg-select>
            </wcg-form-field>
          </div>

          <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
            <wcg-form-field class="search-form">
              <wcg-label>{{ 'adminSubmissions.submitterName' | translate }}</wcg-label>
              <wcg-input ngDefaultControl formControlName="submitterName">
              </wcg-input>
              <div *ngIf="(manageForm.submitted) ||
                (submissionsForm.controls.submitterName.dirty || submissionsForm.controls.submitterName.touched)">
                <wcg-error-message *ngIf="submissionsForm.controls.submitterName.hasError('maxlength')">
                  {{ 'validation.maxChar' | translate: { fieldname: ('adminSubmissions.submitterName' | translate), charLength: 255  } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
        </div>

        <div class="wcg-row">
          <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
            <wcg-form-field class="search-form">
              <wcg-label>{{ 'adminSubmissions.submitterCompanyName' | translate }}</wcg-label>
              <wcg-input ngDefaultControl formControlName="submitterCompanyName">
              </wcg-input>
              <div *ngIf="(manageForm.submitted) ||
                (submissionsForm.controls.submitterCompanyName.dirty || submissionsForm.controls.submitterCompanyName.touched)">
                <wcg-error-message *ngIf="submissionsForm.controls.submitterCompanyName.hasError('maxlength')">
                  {{ 'validation.maxChar' | translate: { fieldname: ('adminSubmissions.submitterCompanyName' | translate), charLength: 255  } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
            <wcg-form-field class="search-form">
              <wcg-label>{{ 'adminSubmissions.sponsor' | translate }}</wcg-label>
              <wcg-input ngDefaultControl formControlName="sponsor">
              </wcg-input>
              <div *ngIf="(manageForm.submitted) ||
                (submissionsForm.controls.sponsor.dirty || submissionsForm.controls.sponsor.touched)">
                <wcg-error-message *ngIf="submissionsForm.controls.sponsor.hasError('maxlength')">
                  {{ 'validation.maxChar' | translate: { fieldname: ('adminSubmissions.sponsor' | translate), charLength: 255  } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
            <wcg-form-field class="search-form">
              <wcg-label>{{ 'adminSubmissions.piName' | translate }}</wcg-label>
              <wcg-input ngDefaultControl formControlName="piName">
              </wcg-input>
              <div *ngIf="(manageForm.submitted) ||
                (submissionsForm.controls.piName.dirty || submissionsForm.controls.piName.touched)">
                <wcg-error-message *ngIf="submissionsForm.controls.piName.hasError('maxlength')">
                  {{ 'validation.maxChar' | translate: { fieldname: ('adminSubmissions.piName' | translate), charLength: 255  } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
            <wcg-form-field class="search-form">
              <wcg-label>{{ 'adminSubmissions.envelopeId' | translate }}</wcg-label>
              <wcg-input ngDefaultControl formControlName="envelopeId">
              </wcg-input>
              <div *ngIf="(manageForm.submitted) ||
                (submissionsForm.controls.envelopeId.dirty || submissionsForm.controls.envelopeId.touched)">
                <wcg-error-message *ngIf="submissionsForm.controls.envelopeId.hasError('maxlength')">
                  {{ 'validation.maxChar' | translate: { fieldname: ('adminSubmissions.envelopeId' | translate), charLength: 255  } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
            <wcg-form-field class="search-form">
              <wcg-label>{{ 'adminSubmissions.confirmationId' | translate }}</wcg-label>
              <wcg-input ngDefaultControl formControlName="confirmationId">
              </wcg-input>
              <div *ngIf="(manageForm.submitted) ||
                (submissionsForm.controls.confirmationId.dirty || submissionsForm.controls.confirmationId.touched)">
                <wcg-error-message *ngIf="submissionsForm.controls.confirmationId.hasError('maxlength')">
                  {{ 'validation.maxChar' | translate: { fieldname: ('adminSubmissions.confirmationId' | translate), charLength: 255  } }}
                </wcg-error-message>
                <wcg-error-message *ngIf="submissionsForm.controls.confirmationId.hasError('pattern')">
                  {{ 'validation.numeric' | translate: { fieldname: ('adminSubmissions.confirmationId' | translate) } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
        </div>

        <div class="wcg-row">
          <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
            <wcg-form-field class="search-form">
              <wcg-label>{{ 'adminSubmissions.submissionName' | translate }}</wcg-label>
              <wcg-input ngDefaultControl formControlName="submissionName">
              </wcg-input>
              <div *ngIf="(manageForm.submitted) ||
                (submissionsForm.controls.submissionName.dirty || submissionsForm.controls.submissionName.touched)">
                <wcg-error-message *ngIf="submissionsForm.controls.submissionName.hasError('maxlength')">
                  {{ 'validation.maxChar' | translate: { fieldname: ('adminSubmissions.submissionName' | translate), charLength: 255  } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
            <wcg-form-field class="filter-form-field">
              <wcg-label>{{ 'adminSubmissions.submissionSource' | translate }}</wcg-label>
              <wcg-select formControlName='submissionSource' [options]="submissionSourceViewValues">
                <option value="" selected></option>
                <option *ngFor="let type of submissionSourceViewValues" value={{type.viewValue}}>{{type.viewValue}}</option>
              </wcg-select>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-4 wcg-col-md-4 wcg-col-lg-4">
            <wcg-form-field class="search-form">
              <wcg-label>{{ 'adminSubmissions.specialInstructions' | translate }}</wcg-label>
              <wcg-input ngDefaultControl formControlName="specialInstructions">
              </wcg-input>
              <div *ngIf="(manageForm.submitted) ||
                (submissionsForm.controls.specialInstructions.dirty || submissionsForm.controls.specialInstructions.touched)">
                <wcg-error-message *ngIf="submissionsForm.controls.specialInstructions.hasError('maxlength')">
                  {{ 'validation.maxChar' | translate: { fieldname: ('adminSubmissions.specialInstructions' | translate), charLength: 255  } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2 pt-15">
            <button [disabled]="disabledButton" class="wcg-btn wcg-btn-fluid wcg-btn-primary mt-10"
            (click)="search()">
              {{ 'adminSubmissions.search' | translate }}
            </button>
          </div>
        </div>
        <div class="wcg-row pb-20">
          <div class="wcg-col-xs-4 wcg-col-md-4 wcg-col-lg-4"></div>
          <div class="wcg-col-xs-4 wcg-col-md-4 wcg-col-lg-4"></div>
          <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
            <div class="export-button-wrap">
              <app-export-action-button [gridFilters]="gridFilters" [disableButton]="!(submissionsForm.valid && totalRecords != 0 && showTable)"
                [exportType]="exportType" ></app-export-action-button>
            </div>
          </div>
        </div>
      </form>

      <wcg-table class="wcg-rhythm-4" [headers]="headers" [data]="tableData"
      [options]="options" (sortClicked)="handleSort($event)" *ngIf="submissionsForm.valid && showTable">
        <div class="cnxs-table-loader" slot="table-body">
          <wcg-loader [show]="loading && submissionsForm.valid"></wcg-loader>
        </div>

        <div class="cnxs-pagination-wrap wcg-table-footer">
          <div class="wcg-total-results">
            {{ 'adminSubmissions.totalResults' | translate}} {{totalRecords}}
          </div>
          <div class="pagination">
            <wcg-pagination *ngIf="tableData?.length" [current]="currentPage" [pageSizeDataSource]="[25, 50, 100, 200]"
              [max]="utilService.maxPages(totalRecords, currentPageSize)" (changePage)="handlePageChange($event)" [pageSize]="currentPageSize" ></wcg-pagination>
          </div>
        </div>
      </wcg-table>
    </div>
  </div>