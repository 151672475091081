<app-workflow-container
  [isVisible]="showOverlay"
  [hasNav]="hasNav"
  [header]="'submissionWorkflow.heading' | translate"
  hasFooter="true"
>

<close-button>
  <button class="wcg-btn wcg-btn-icon" (click)="this.submissionWorkflowService.closeWorkflow()">
    <wcg-icon src="../../assets/icons/navigation/ic_close_24px.svg"></wcg-icon>
    <span class="wcg-visually-hidden">{{ 'submissionWorkflow.close' | translate }}</span>
  </button>
</close-button>

<main-content>
  <div class="wcg-row">
    <p class="wcg-h5 setup wcg-col-xs-4">{{ 'submissionWorkflow.setup' | translate }}</p>
    <span class="wcg-col-xs-4 wcg-col-lg-8">
      <h2 *ngIf="this.submissionFlow === 'addPI'" class="wcg-h2 header">{{ 'submissionWorkflow.addPIHeader' | translate }}</h2>
      <h2 *ngIf="this.submissionFlow === 'addDocuments'" class="wcg-h2 header">{{ 'submissionWorkflow.addDocumentsHeader' | translate }}</h2>
    </span>
    <form ngForm class="wcg-col-xs-4 wcg-col-lg-8">
      <wcg-form-field>
        <wcg-label>{{ 'actions.findStudy' | translate }}</wcg-label>
        <wcg-input [formControl]="searchTerm" [value]="searchTerm.value">
          <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
        </wcg-input>
      </wcg-form-field>
      <p class="search-description wcg-body-label wcg-col-xs-4 wcg-col-lg-8">{{ 'submissionWorkflow.search' | translate }}</p>
    </form>
    <app-submission-help icon="../assets/icons/lightbulb.svg" class="app-submission-help wcg-col-xs-4 wcg-col-lg-4">
      <div *ngIf="this.submissionFlow === 'addPI'" class="tooltip-box-content">
        <p class="wcg-h5 submission-help-text wcg-rhythm-1 wcg-col-xs-4">{{ 'submissionWorkflow.enterIRBTip' | translate }}</p>
        <a [routerLink]="['/submission-workflow/search-irb']" class="wcg-link">{{ 'submissionWorkflow.enterIRB' | translate }}</a>
      </div>
      <div *ngIf="this.submissionFlow === 'addDocuments'" class="tooltip-box-content">
        <p class="wcg-h5 submission-help-text wcg-rhythm-1 wcg-col-xs-4">{{ 'submissionWorkflow.requestAccessTip' | translate }}</p>
        <button class="wcg-link"
          (click)="stateService.showRequestAccessModal.next(true)">{{ 'requestAccess.title' | translate }}
        </button>
      </div>
    </app-submission-help>
    <wcg-table
      class="wcg-rhythm-1 wcg-col-xs-4"
      [ngClass]="{'cnxs-table-loading': loading}"
      [headers]="headers"
      [data]="tableData"
      [options]="options"
      (sortClicked)="handleSort($event)"
      (change)="onRadioChange($event)">

    <app-no-records *ngIf="!this.loading && !this.tableData?.length" slot="table-body" class="no-records-content">
      <div *ngIf="this.submissionFlow === 'addPI'" class="no-records-wrapper">
        <p class="wcg-h2 no-content-header">{{ 'tables.noResults' | translate }}</p>
        <p class="wcg-body no-content-text">{{ 'submissionWorkflow.enterIRBTip' | translate }}
          <a [routerLink]="['/submission-workflow/search-irb']" class="wcg-link">{{ 'submissionWorkflow.enterIRB' | translate }}</a>
        </p>
      </div>

      <div *ngIf="this.submissionFlow === 'addDocuments'" class="no-records-wrapper">
        <p class="wcg-h2 no-content-header">{{ 'tables.noResults' | translate }}</p>
        <p class="wcg-body no-content-text">{{ 'submissionWorkflow.requestAccessTip' | translate }}
          <button class="wcg-link no-record-button"
            (click)="stateService.showRequestAccessModal.next(true)">{{ 'requestAccess.title' | translate }}
          </button>
        </p>
      </div>
    </app-no-records>

    <div class="cnxs-table-loader" slot="table-body">
      <wcg-loader [show]="!loaded || loading"></wcg-loader>
    </div>

      <div class="cnxs-pagination-wrap">
        <wcg-pagination *ngIf="loaded && tableData?.length" 
          [current]="currentPage" [pageSize]="currentPageSize"
          [max]="utilService.maxPages(totalRecords, currentPageSize)" (changePage)="handlePageChange($event)">
        </wcg-pagination>
      </div>
    </wcg-table>
  </div>
</main-content>

<footer-content>
  <div class="cnxs-form-footer-content">
    <a class="wcg-link cnxs-arrow-btn cnxs-prev" (click)="goBack()">
      <wcg-icon class="cnxs-arrow cnxs-back-arrow" src="../assets/icons/navigation/ic_arrow_back_24px.svg"></wcg-icon>
      {{ 'submissionWorkflow.back' | translate }}
    </a>

    <button type="button" class="wcg-link cnxs-arrow-btn cnxs-next" [disabled]="this.submissionFindStudyValid" (click)="continueClick()">
      {{ 'submissionWorkflow.continueSetup' | translate }}
      <wcg-icon class="cnxs-arrow cnxs-next-arrow" src="../assets/icons/navigation/ic_arrow_forward_24px.svg"></wcg-icon>
    </button>
  </div>
</footer-content>
</app-workflow-container>

<app-request-access-modal
  [show]="stateService.showRequestAccessModal.value"
></app-request-access-modal>
