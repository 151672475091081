import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { ViewSettingsComponent } from './views/settings/view-settings/view-settings.component';
import { AdminBaseComponent } from './shared/components/adminbase/adminbase.component';
import { SubmissionTypesComponent } from './views/settings/submission-types/submission-types.component';
import { ViewDocumentTypesComponent } from './views/settings/document-types/view-document-types/view-document-types.component';
import { ViewDocumentCategoryComponent } from './views/settings/document-categories/view-document-category.component';
import { ViewUserInfoComponent } from './views/users/view-user-info.component';
import { UserDetailsComponent } from './views/users/user-details/user-details.component';
import { TeamsComponent } from './views/teams/teams.component';
import { AuditAccessComponent } from './views/audit-access/audit-access.component';
import { AdminSubmissionComponent } from './views/admin-submission/admin-submission.component';
import { SystemSettingsComponent } from './views/settings/system-settings/system-settings.component';
import { ManageUserContactsComponent } from './views/users/manage-user-contacts/manage-user-contacts.component';

const routes = [
  {
    path: 'admin',
    component: AdminBaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full'
      },
      {
        path: 'settings',
        component: ViewSettingsComponent,
        children: [
          {
            path: '',
            redirectTo: 'submissionTypes',
            pathMatch: 'full'
          },
          {
            path: 'documentcategory',
            component: ViewDocumentCategoryComponent
          },
          {
            path: 'systemSettings',
            component: SystemSettingsComponent
          },
          {
            path: 'submissionTypes',
            component: SubmissionTypesComponent
          },
          {
            path: 'document-types',
            component: ViewDocumentTypesComponent
          }
        ]
      },
      {
        path: 'users',
        component: ViewUserInfoComponent
      },
      {
        path: 'users/manage-user-contacts',
        component: ManageUserContactsComponent
      },
      {
        path: 'users/user-details/:id',
        component: UserDetailsComponent
      },
      {
        path: 'teams',
        component: TeamsComponent
      },
      {
        path: 'audit',
        component: AuditAccessComponent
      },
      {
        path: 'submissions-admin',
        component: AdminSubmissionComponent
      }
    ]
  },
  {
    path: 'admin_irb',
    component: AdminBaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'submissions-admin',
        pathMatch: 'full'
      },
      {
        path: 'submissions-admin',
        component: AdminSubmissionComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false
  })],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
