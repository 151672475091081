/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RequestStatus, RequestStatusLabel, TeamGridType } from 'src/app/common/collections';
import { TeamSitesGrid, TeamSitesGridRecordMap } from 'src/app/interfaces/teams';
import { UtilService } from 'src/app/services/util.service';
import { TeamService } from '../team.service';

@Injectable({
  providedIn: 'root'
})
export class TeamSitesService {
  public searchTerm = new BehaviorSubject<string>('');

  constructor(
    private utilService: UtilService,
    private teamService: TeamService
  ) {}

  public mapSitesGridData(data: TeamSitesGrid): Array<TeamSitesGridRecordMap> {
    return data.records.map((record) => ({
      sponsor: this.utilService.addTableCellWrapperWithSearchTerms(record.sponsor, this.searchTerm.value),
      sponsorProtocolNumber: this.utilService.addTableCellWrapperWithSearchTerms(record.sponsorProtocolNumber, this.searchTerm.value),
      name: record.name,
      nameLink: this.utilService.addTableCellWrapperWithSearchTerms(record.name, this.searchTerm.value,
        `sites/${record.id}`),
      irbTrackingNumber: this.utilService.addTableCellWrapperWithSearchTerms(record.irbTrackingNumber, this.searchTerm.value),
      institutionalTrackingNumber: this.utilService.addTableCellWrapperWithSearchTerms(record.institutionalTrackingNumber,
        this.searchTerm.value),
      status: this.utilService.addTableCellWrapperWithSearchTerms(record.status, this.searchTerm.value),
      requestStatus: `${this.utilService.addRequestStatusTableCellWrapperWithSearchTerms(
        RequestStatusLabel[RequestStatus[record.requestStatus]], this.searchTerm.value, TeamGridType.site,
        this.teamService.hasManagePermission.value || this.teamService.isAdmin.value
      )}`,
      workspaceOrganizationId: record.workspaceOrganizationId,
      workspaceExternalReferenceId: record.workspaceExternalReferenceId
    }));
  }
}
