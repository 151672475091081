import { Component, Input } from '@angular/core';
import { GridOptionsSearchModel } from 'src/app/admin/shared/model/grid.model';
import { ApiReportingUrl, GridExportType } from 'src/app/common/reportingCollections';
import { LoggerService } from 'src/app/services/logger.service';
import { ReportingService } from 'src/app/services/reporting.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-export-action-button',
  templateUrl: './export-action-button.component.html',
  styleUrls: ['./export-action-button.component.scss']
})
export class ExportActionButtonComponent {
  @Input() gridFilters: GridOptionsSearchModel;
  @Input() exportType: GridExportType;
  @Input() disableButton: boolean;
  @Input() entityId: string = null;
  @Input() secondEntityId: string = null;
  @Input() limitAdminUserCount: boolean = null;

  loading = false;
  reportingUrl: string;

  constructor(private stateService: StateService,
    private reportingService: ReportingService,
    private loggerService: LoggerService) {
  }

  exportData(): void {
    this.reportingUrl = ApiReportingUrl[this.exportType];
    this.requestExport();
  }

  requestExport(): void {
    this.loading = true;
    this.reportingService.requestExport(this.gridFilters, this.reportingUrl, this.limitAdminUserCount, this.entityId, this.secondEntityId)
      .subscribe(() => {
        this.loading = false;
        this.stateService.showExportCsvModal.next(true);
      }, (err) => {
        this.loading = false;
        this.loggerService.error(`Data could not be downloaded: ${err.message}`);
      });
  }
}
