import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { WorkspaceAccessModel } from 'src/app/interfaces/workspaces';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { UserService } from 'src/app/services/user.service';
import { LoggerService } from 'src/app/services/logger.service';
import { UtilService } from 'src/app/services/util.service';
import { StateService } from '../../../services/state.service';

@Component({
  selector: 'app-request-access-modal',
  templateUrl: './request-access-modal.component.html',
  styleUrls: ['./request-access-modal.component.scss']
})
export class RequestAccessModalComponent implements OnInit {
  @Input() show: boolean;

  requestAccessForm: FormGroup;
  selectedRadio = 'study';
  searchResultMessage = '';
  irbResultMessage = '';
  workspaceData: WorkspaceAccessModel;
  isMultipleChoices = false;
  showNoMatches = false;
  submitValid = false;
  invalidAttemptsData: any[] = [];

  showRequestAccessConfirmation = false;
  showWarning = false;
  showError = false;
  showRequestSent = false;
  showAlreadyExist = false;

  confirmationMessage1 = '';
  confirmationMessage2 = '';
  confirmationMessage3 = '';
  email: string;
  workspaceReferenceLineLink: string;

  constructor(
    public stateService: StateService,
    private apiService: ApiService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private translatePipe: TranslatePipe,
    private loggerService: LoggerService,
    private utilService: UtilService
  ) {
    this.userService.userInfo.subscribe((data) => {
      this.email = data.email;
    });
  }

  ngOnInit(): void {
    this.initializeRequestAccessForm();
  }

  public changeRadio(event): void {
    this.selectedRadio = event.target.value;
    this.clearState();
  }

  public search(isIrb: boolean): void {
    this.resetValues();
    let searchTerm = '';
    let irbTracking = '';

    if (isIrb) {
      irbTracking = this.requestAccessForm.controls.irbTracking.value.trim();
    }
    else {
      this.isMultipleChoices = false;
    }

    searchTerm = this.requestAccessForm.controls.searchTerm.value.trim();

    this.apiService.getWorkspaceSearch(this.selectedRadio, searchTerm, irbTracking)
      .pipe().subscribe((data) => {
        this.workspaceData = data;
        this.searchWorkspaceAccess();
      }, (err) => {
        this.loggerService.error(`Errow while searching. ${err}`);
      });
  }

  public submitAccessRequest(): void {
    if (this.selectedRadio === 'studysite') {
      this.workspaceData.sponsor = '';
      this.workspaceData.sponsorProtocolNumber = '';
      this.workspaceData.studyName = '';
    }

    this.apiService.workSpaceRequestsByAccessModel(this.workspaceData)
      .pipe().subscribe((data) => {
        if (data !== null) {
          if (data.id === '' && data.value === '') {
            this.saveIncorrectAttempt();
          }
          else {
            this.processSubmitRequest(data);
          }
        }
      }
      , (err) => {
        this.loggerService.error(`Erro while workspace request. ${err}`);
        this.saveIncorrectAttempt();
      });
  }

  public closeRequestAccessModal(): void {
    this.resetForm();
    this.stateService.showRequestAccessModal.next(false);
  }

  public showRequestAccessConfirmationModal(): void {
    this.showRequestAccessConfirmation = true;
  }

  public closeRequestAccessConfirmationModal(): void {
    this.showRequestAccessConfirmation = false;
  }


  private saveIncorrectAttempt(): void {
    const maxLength = 3;

    const irbTracking = this.requestAccessForm.controls.irbTracking.value?.trim();
    const searchTerm = this.requestAccessForm.controls.searchTerm.value?.trim();

    const query = irbTracking ? irbTracking : searchTerm;

    const helpdeskAttemptMessage = `${this.selectedRadio} - ${query}`;
    this.invalidAttemptsData.push(helpdeskAttemptMessage);

    if (this.invalidAttemptsData.length >= maxLength) {
      this.apiService.sendFailedRequests(this.invalidAttemptsData)
        .pipe().subscribe(() => {
          this.showError = true;
          this.showRequestAccessConfirmationModal();
          this.confirmationMessage1 = this.translatePipe.transform('requestAccess.errorLine1');
          this.confirmationMessage3 = this.translatePipe.transform('requestAccess.errorLine3');
        });
      this.closeRequestAccessModal();
    }
    else {
      this.resetValues();
      this.showWarning = true;
    }
  }

  private searchWorkspaceAccess(): void {
    if (this.workspaceData !== null) {
      if (this.workspaceData.studyName === '[EntityNotFound]' && this.workspaceData.sponsorProtocolNumber === '[EntityNotFound]') {
        this.showNoMatches = true;
        this.workspaceData = null;
      }
      else if (this.workspaceData.studyName === '[AmbiguousResults]' && this.workspaceData.sponsorProtocolNumber === '[AmbiguousResults]') {
        this.isMultipleChoices = true;
        this.submitValid = false;
        this.searchResultMessage = this.translatePipe.transform('requestAccess.matchesMoreThanOneSearch');
        this.workspaceData = null;
      }
      else {
        if (this.isMultipleChoices) {
          this.irbResultMessage = this.translatePipe.transform('requestAccess.matchesSearch');
        }
        else {
          this.isMultipleChoices = false;
          this.searchResultMessage = this.translatePipe.transform('requestAccess.matchesSearch');
        }
        this.submitValid = true;
      }
    }
  }

  private processSubmitRequest(data: any): void {
    if (data.value === '[EntityNotFound]') {
      this.saveIncorrectAttempt();
    }
    else if (data.value === '[AmbiguousResults]') {
      this.saveIncorrectAttempt();
    }
    else if (data.value === '[BadRequest]') {
      this.showAlreadyExist = true;
      this.showRequestAccessConfirmationModal();
      const selectedValue = this.selectedRadio === 'studysite' ? 'site' : 'study';
      const hyperLink = selectedValue === 'site' ? `sites/${data.id}` : `studies/${data.id}`;
      const goLink = this.translatePipe.transform('requestAccess.goLink').replace('{workspaceType}', selectedValue);
      const workspaceLink = this.utilService.generateLink(hyperLink, goLink);

      this.confirmationMessage1 = `${this.translatePipe.transform('requestAccess.alreadyExistLine1')} ${selectedValue}.`;
      this.confirmationMessage2 = '';
      this.workspaceReferenceLineLink = this.translatePipe.transform('requestAccess.alreadyExistLine3') + workspaceLink;
      this.closeRequestAccessModal();
    }
    else {
      this.showRequestSent = true;
      this.showRequestAccessConfirmationModal();
      this.confirmationMessage1 = this.translatePipe.transform('requestAccess.requestSentLine1');
      this.confirmationMessage2 = this.translatePipe.transform('requestAccess.requestSentLine2');
      this.confirmationMessage3 = this.translatePipe.transform('requestAccess.requestSentLine3').replace('{email}', this.email);
      this.closeRequestAccessModal();
    }
  }

  private resetValues(): void {
    this.workspaceData = null;
    this.showNoMatches = false;
    this.searchResultMessage = '';
    this.irbResultMessage = '';
    this.showWarning = false;
    this.submitValid = false;
  }

  private clearState(): void {
    this.workspaceData = null;
    this.searchResultMessage = '';
    this.irbResultMessage = '';
    this.isMultipleChoices = false;
    this.requestAccessForm.controls.searchTerm.setValue(' ');
    this.invalidAttemptsData = [];
    this.showNoMatches = false;
    this.submitValid = false;
    this.requestAccessForm.reset();
  }

  private initializeRequestAccessForm(): void {
    const minLength = 8;
    const maxLength = 9;
    this.requestAccessForm = this.formBuilder.group({
      searchTerm: ['', Validators.required],
      irbTracking: ['', [Validators.pattern('[0-9]*$'), Validators.minLength(minLength), Validators.maxLength(maxLength)]]
    });
  }

  private resetForm(): void {
    this.clearState();
    this.selectedRadio = 'study';
  }
}
