import { Component, OnInit } from '@angular/core';
import { LoggerService } from '../../services/logger.service';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Component({
  selector: 'app-silent-renew',
  template: ''
})
export class SilentRenewComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private loggerService: LoggerService
  ) { }

  public ngOnInit(): void {
    this.authenticationService.completeSilentLogin().catch((error) => {
      this.loggerService.error(error);
    });
  }
}
