<div class="wcg-container">
  <div *ngIf="userDetailsLoaded">
    <div class="wcg-row cnxs-page-header">
      <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-6 wcg-col-lg-8">
        <h1 class="wcg-h2" *ngIf="user">{{ 'profile.fullname' | translate: { firstname: user?.firstName, lastname: user?.lastName } }}</h1>
      </div>
      <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-col-lg-4 wcg-end-sm">
        <div class="cnxs-page-header-btn">
          <a class="wcg-btn wcg-btn-icon" [href]="changePasswordUrl">
            <wcg-icon src="../assets/icons/action/ic_settings_24px.svg"></wcg-icon>
            <span>
              {{ 'profile.changePassword' | translate }}
            </span>
          </a>
        </div>
      </div>
    </div>

    <div class="wcg-row wcg-rhythm-4">
      <div class="wcg-col-xs-4">
        <div class="profile-card-label wcg-h3">
          {{ 'profile.latestActivity' | translate }}
        </div>
        <div class="profile-card-detail">
          {{ lastActivityDate }}
        </div>
      </div>
    </div>

    <hr class="wcg-rhythm-4">

    <div class="wcg-row wcg-rhythm-1">
      <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-6 wcg-col-lg-8">
        <h4 class="wcg-h4">
          <span>{{ user?.organization?.organizationType?.value }} {{ user?.organization?.name }}</span>
        </h4>
      </div>
      <div class="wcg-col-xs-4 wcg-col-sm-2 wcg-col-md-2 wcg-col-lg-4 wcg-end-sm">
        <div class="cnxs-icon-btn-wrap">
          <button class="action-button wcg-btn wcg-btn-icon" (click)="doShowUserDetailsModal()" *ngIf="!showUserDetailsModal">
            <wcg-icon src="../assets/icons/image/ic_edit_24px.svg"></wcg-icon>
            <span class="wcg-visually-hidden">{{ 'actions.edit' | translate }}</span>
          </button>
        </div>
      </div>
    </div>

    <div class="wcg-row wcg-rhythm-2">
      <div class="wcg-col-md-2">
        <div class="profile-card-detail">
          {{ user?.prefix }}
          {{ user?.firstName }}
          {{ user?.middleName }}
          {{ user?.lastName }}
          {{ user?.suffix }}<br />
          {{ user?.degrees }}
        </div>
      </div>
      <div class="wcg-col-md-6">
        <div class="profile-card-detail">
          {{ user?.address?.addressLine1 }}
          {{ user?.address?.addressLine2 }}<br />
          {{ user?.address?.city }},
          {{ user?.address?.state?.name }}
          {{ user?.address?.province }}
          {{ user?.address?.postalCode }},
          {{ user?.address?.country?.name }}
        </div>
      </div>
    </div>
  </div>

  <div class="wcg-row wcg-rhythm-2" *ngIf="user?.email">
    <div class="wcg-col-xs-2">
      <div class="cnxs-icon-btn-wrap">
        <a class="wcg-btn wcg-btn-icon contact-button" href="mailto:{{ user?.email }}">
          <wcg-icon src="../assets/icons/communication/ic_email_24px.svg"></wcg-icon>
          <span>{{ user?.email }}</span>
        </a>
      </div>
    </div>
  </div>

  <div class="wcg-row phone-card wcg-rhythm-4" *ngIf="user?.phone">
    <div class="wcg-col-xs-2">
      <div class="cnxs-icon-btn-wrap">
        <a class="wcg-btn wcg-btn-icon contact-button" href="tel:{{ user?.phone }}">
          <wcg-icon src="../assets/icons/communication/ic_call_24px.svg"></wcg-icon>
          <span>{{ user?.phone }}</span>
        </a>
      </div>
    </div>
  </div>
</div>

<section class="cnxs-secondary-section">
  <div class="wcg-container">
    <wcg-tab-bar-section>
      <wcg-tab-bar>
        <h4 class="profile-card-heading">
          {{ 'profile.workspaces' | translate }}
        </h4>
      </wcg-tab-bar>
      <div class="wcg-container">
        <form ngForm class="wcg-rhythm-5">
          <wcg-form-field>
            <wcg-label>{{ 'actions.search' | translate }}</wcg-label>
            <wcg-input [formControl]="searchTerm">
              <wcg-icon src="./assets/icons/action/ic_search_24px.svg"></wcg-icon>
            </wcg-input>
          </wcg-form-field>

          <div class="wcg-row pb-20">
            <div class="wcg-col-xs-4 wcg-col-md-12 wcg-col-lg-12">
              <div class="export-button-wrap">
                <app-export-action-button 
                  [gridFilters]="gridFilters" 
                  [exportType]="exportType" 
                  [disableButton]="!tableData?.length"></app-export-action-button>
              </div>
            </div>
          </div>
        </form>
        <div class="wcg-row table-card">
          <div class="wcg-col-xs-4">
            <wcg-table
              class="wcg-rhythm-4"
              [ngClass]="{'cnxs-table-loading': loading}"
              [headers]="headers"
              [data]="tableData"
              [options]="options">

              <div class="cnxs-table-loader" slot="table-body">
                <wcg-loader [show]="!loaded || loading"></wcg-loader>
              </div>          
              
            </wcg-table>
          </div>
        </div>
      </div>
    </wcg-tab-bar-section>
  </div>
</section>

<wcg-modal [show]="showUserDetailsModal" (close)="revertForm()">
  <wcg-modal-header>
    {{ 'profile.editProfileTitle' | translate }}
  </wcg-modal-header>
  <wcg-modal-body>
    <wcg-toast variant="error" position="top" *ngIf="saveProfileFailure" closeable="true" (close)="saveProfileFailure = false">{{ 'profile.saveProfileFailure' | translate }}</wcg-toast>
    <form ngForm [formGroup]="formData" class="edit-profile-form">
      <div class="wcg-row">
        <div class="edit-profile-heading wcg-col-xs-4 wcg-rhythm-3">
          <span class="wcg-h5">
            {{ 'profile.profileFormHeadingName' | translate }}
          </span>
        </div>
      </div>
      <div class="wcg-row">
        <div class="wcg-col-xs-4">
          <wcg-form-field>
            <wcg-label>{{ 'profile.profileFormRole' | translate }}</wcg-label>
            <wcg-input [value]="user?.role?.name" disabled></wcg-input>
          </wcg-form-field>
        </div>
      </div>
      <div class="wcg-row">
        <div class="wcg-col-xs-4 wcg-col-md-1 wcg-col-lg-2">
          <wcg-form-field>
            <wcg-label>{{ 'profile.profileFormPrefix' | translate }}</wcg-label>
            <wcg-select formControlName="prefix">
              <option value="-1">{{ 'actions.select' | translate }}</option>
              <option *ngFor="let prefix of prefixes" [selected]="prefix.key === formData.value.prefix" [value]="prefix.key">
                {{ prefix?.value }}
              </option>
            </wcg-select>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-3">
          <wcg-form-field>
            <wcg-label>{{ 'profile.profileFormFirstName' | translate }}</wcg-label>
            <wcg-input formControlName="firstName" [value]="formData.value.firstName" required></wcg-input>
            <wcg-error-message *ngIf="formData.controls.firstName.invalid">
              {{ 'validation.required' | translate: { fieldname: ('profile.profileFormFirstName' | translate) } }}
            </wcg-error-message>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-2">
          <wcg-form-field>
            <wcg-label>{{ 'profile.profileFormMiddleName' | translate }}</wcg-label>
            <wcg-input formControlName="middleName" [value]="formData.value.middleName"></wcg-input>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-3">
          <wcg-form-field>
            <wcg-label>{{ 'profile.profileFormLastName' | translate }}</wcg-label>
            <wcg-input formControlName="lastName" [value]="formData.value.lastName" required></wcg-input>
            <wcg-error-message *ngIf="formData.controls.lastName.invalid">
              {{ 'validation.required' | translate: { fieldname: ('profile.profileFormLastName' | translate) } }}
            </wcg-error-message>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-4 wcg-col-md-1 wcg-col-lg-2">
          <wcg-form-field>
            <wcg-label>{{ 'profile.profileFormSuffix' | translate }}</wcg-label>
            <wcg-select formControlName="suffix">
              <option value="-1">{{ 'actions.select' | translate }}</option>
              <option *ngFor="let suffix of suffixes" [selected]="suffix.key === formData.value.suffix" [value]="suffix.key">
                {{ suffix?.value }}
              </option>
            </wcg-select>
          </wcg-form-field>
        </div>
      </div>
      <div class="wcg-row">
        <div class="edit-profile-heading wcg-col-xs-4 wcg-rhythm-3">
          <span class="wcg-h5">
            {{ 'profile.profileFormHeadingContact' | translate }}
          </span>
        </div>
      </div>
      <div class="wcg-row">
        <div class="wcg-col-xs-4 wcg-col-md-3 wcg-col-lg-5">
          <wcg-form-field>
            <wcg-label>{{ 'profile.profileFormEmail' | translate }}</wcg-label>
            <wcg-input [value]="user?.email" disabled></wcg-input>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-4 wcg-col-md-2 wcg-col-lg-3">
          <wcg-form-field>
            <wcg-label>{{ 'profile.profileFormPhone' | translate }}</wcg-label>
            <wcg-input formControlName="phone" [value]="formData.value.phone" maxlength="20" required></wcg-input>
            <wcg-error-message *ngIf="formData.controls.phone.invalid">
              {{ 'validation.required' | translate: { fieldname: ('profile.profileFormPhone' | translate) } }}
            </wcg-error-message>
            <div *ngIf="formData.controls.phone.value.length > 0">
              <label>{{20 - formData.controls.phone.value.length}} characters remaining</label>
            </div>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-4 wcg-col-md-3 wcg-col-lg-4 email-notifications">
          <wcg-checkbox
            ngDefaultControl
            formControlName="isEmailNotificationEnabled"
            >
              {{ 'profile.profileFormEmailNotifications' | translate }}
          </wcg-checkbox>
        </div>
      </div>
      <div class="wcg-row">
        <div class="edit-profile-heading wcg-col-xs-4 wcg-rhythm-3">
          <span class="wcg-h5">
            {{ 'profile.profileFormHeadingAddress' | translate }}
          </span>
        </div>
      </div>
      <div class="wcg-row">
        <div class="wcg-col-xs-4">
          <wcg-form-field *ngIf="countries">
            <wcg-label>{{ 'profile.profileFormCountry' | translate }}</wcg-label>
            <wcg-select
              formControlName="country"
              (change)="handleCountryChange($event)">
              <option value="placeholder">{{ 'actions.select' | translate }}</option>
              <option *ngFor="let country of countries" [selected]="country.id == formData.value.country" [value]="country?.id" required>
                {{ country?.name }}
              </option>
            </wcg-select>
            <wcg-error-message *ngIf="formData.controls.country.invalid">
              {{ 'validation.required' | translate: { fieldname: ('profile.profileFormCountry' | translate) } }}
            </wcg-error-message>
          </wcg-form-field>
        </div>
      </div>
      <div class="wcg-row">
        <div class="wcg-col-xs-4 wcg-col-md-4">
          <wcg-form-field>
            <wcg-label>{{ 'profile.profileFormAddress1' | translate }}</wcg-label>
            <wcg-input formControlName="addressLine1" [value]="formData.value.addressLine1" required></wcg-input>
            <wcg-error-message *ngIf="formData.controls.addressLine1.invalid">
              {{ 'validation.required' | translate: { fieldname: ('profile.profileFormAddress1' | translate) } }}
            </wcg-error-message>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-4 wcg-col-md-4">
          <wcg-form-field>
            <wcg-label>{{ 'profile.profileFormAddress2' | translate }}</wcg-label>
            <wcg-input formControlName="addressLine2" [value]="formData.value.addressLine2"></wcg-input>
          </wcg-form-field>
        </div>
      </div>
      <div class="wcg-row">
        <div class="wcg-col-xs-4 wcg-col-md-3">
          <wcg-form-field>
            <wcg-label>{{ 'profile.profileFormCity' | translate }}</wcg-label>
            <wcg-input formControlName="city" [value]="formData.value.city" required></wcg-input>
            <wcg-error-message *ngIf="formData.controls.city.invalid">
              {{ 'validation.required' | translate: { fieldname: ('profile.profileFormCity' | translate) } }}
            </wcg-error-message>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-4 wcg-col-md-3">
          <wcg-form-field *ngIf="states?.length">
            <wcg-label>{{ stateLabel | translate }}</wcg-label>
            <wcg-select formControlName="state">
              <option value="placeholder">{{ 'actions.select' | translate }}</option>
              <option *ngFor="let state of states" [selected]="state.id == formData.value.state" [value]="state.id" required>
                {{ state?.name }}
              </option>
            </wcg-select>
            <wcg-error-message *ngIf="formData.controls.state.invalid">
              {{ 'validation.required' | translate: { fieldname: (stateLabel | translate) } }}
            </wcg-error-message>
          </wcg-form-field>
          <wcg-form-field *ngIf="states?.length === 0">
            <wcg-label>{{ 'profile.profileFormStateProvince' | translate }}</wcg-label>
            <wcg-input formControlName="province" [value]="formData.value.province" required></wcg-input>
            <wcg-error-message *ngIf="formData.controls.province.invalid">
              {{ 'validation.required' | translate: { fieldname: ('profile.profileFormStateProvince' | translate) } }}
            </wcg-error-message>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-4 wcg-col-md-2">
          <wcg-form-field>
            <wcg-label>{{ 'profile.profileFormPostalCode' | translate }}</wcg-label>
            <wcg-input formControlName="postalCode" [value]="formData.value.postalCode" required></wcg-input>
            <wcg-error-message *ngIf="formData.controls.postalCode.invalid">
              {{ 'validation.required' | translate: { fieldname: ('profile.profileFormPostalCode' | translate) } }}
            </wcg-error-message>
          </wcg-form-field>
        </div>
      </div>
      <div class="wcg-row">
        <div class="edit-profile-heading wcg-col-xs-4 wcg-rhythm-3">
          <span class="wcg-h5">
            {{ 'profile.profileFormHeadingAdditional' | translate }}
          </span>
        </div>
      </div>
      <div class="wcg-row">
        <div class="wcg-col-xs-4 wcg-col-md-3">
          <wcg-form-field>
            <wcg-label>{{ 'profile.profileFormDegrees' | translate }}</wcg-label>
            <wcg-input formControlName="degrees"></wcg-input>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-4 wcg-col-md-3">
          <wcg-form-field *ngIf="isAdmin">
            <wcg-label>{{ 'profile.profileFormOrganization' | translate }}</wcg-label>
            <wcg-autocomplete
              formControlName="organization"
              [multiple]="false"
              [options]="allOrganizations"
              (change)="updateOrganization($event)">
            </wcg-autocomplete>
            <wcg-error-message *ngIf="formData.controls.organization.invalid">
              {{ 'validation.required' | translate: { fieldname: ('profile.profileFormOrganization' | translate) } }}
            </wcg-error-message>
          </wcg-form-field>
          <wcg-form-field *ngIf="!isAdmin">
            <wcg-label>{{ 'profile.profileFormOrganization' | translate }}</wcg-label>
            <wcg-input [value]="user?.organization?.name" disabled></wcg-input>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-4 wcg-col-md-2">
          <wcg-form-field>
            <wcg-label>{{ 'profile.profileFormOrganizationType' | translate }}</wcg-label>
            <wcg-input [value]="organizationType?.value" disabled></wcg-input>
          </wcg-form-field>
        </div>
      </div>
    </form>
  </wcg-modal-body>
  <wcg-modal-footer>
    <button class="wcg-btn" (click)="revertForm()">{{ 'actions.cancel' | translate }}</button>
    <button class="wcg-btn wcg-btn-primary" (click)="postUserProfile()" [disabled]="formData.invalid || formData.controls.organization.invalid">{{ 'actions.save' | translate }} {{ formData.errors }}</button>
  </wcg-modal-footer>
</wcg-modal>