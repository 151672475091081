import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil, debounce } from 'rxjs/operators';
import { timer } from 'rxjs';

import { ApiService } from '../../services/api.service';
import { BaseComponent } from '../../components/base/base.component';
import { SitesService } from './sites.service';
import { SitesGridRecordMap, SitesGrid } from '../../interfaces/sites';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { UtilService } from '../../services/util.service';
import { TableHeader } from '../../interfaces/components';
import { ToastService } from '../../services/toast.service';
import { SitesSortOptions, DEBOUNCE_TIME } from '../../common/collections';
import { GridOptionsSearchModel } from 'src/app/admin/shared/model/grid.model';
import { GridExportType } from 'src/app/common/reportingCollections';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent extends BaseComponent implements OnInit {
  currentPage = 1;
  currentPageSize = 10;
  currentSort: string = SitesSortOptions.nameLink;
  currentSortDescending: boolean;
  headers: Array<TableHeader>;
  loaded: boolean;
  loading: boolean;
  searchTerm = new FormControl('');
  tableData: Array<SitesGridRecordMap>;
  totalRecords: number;

  options = {
    manualPaginationAndSort: true
  };

  gridFilters: GridOptionsSearchModel;
  readonly exportType: GridExportType = GridExportType.Sites;

  constructor(
    public utilService: UtilService,
    private apiService: ApiService,
    private sitesService: SitesService,
    private translatePipe: TranslatePipe,
    private toastService: ToastService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.sitesService.searchTerm.next('');

    this.headers = this.getHeaders();
    this.currentSortDescending = false;

    this.postSitesGrid({
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: '',
      sortOptions: [{
        field: SitesSortOptions.nameLink,
        isDescending: false
      }]
    });

    this.searchTerm.valueChanges
      .pipe(
        debounce(() => timer(DEBOUNCE_TIME))
      )
      .subscribe((val) => {
        this.sitesService.searchTerm.next(val);
        this.postSitesGrid({
          page: 1,
          pageSize: this.currentPageSize,
          searchTerm: val,
          sortOptions: [{
            field: this.currentSort,
            isDescending: this.currentSortDescending
          }]
        });
      });
  }

  handlePageChange(event): void {
    this.currentPage = event.detail.page;
    this.currentPageSize = event.detail.pageSize;

    this.postSitesGrid({
      page: event.detail.page,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: [{
        field: this.currentSort,
        isDescending: this.currentSortDescending
      }]
    });
  }


  handleRowClicked(event): void {
    if (event.detail.event.target.hasAttribute('href')) {
      event.detail.event.preventDefault();

      this.router.navigateByUrl(event.detail.event.target.getAttribute('href'));
    }
  }

  handleSort(event): void {
    this.currentSort = SitesSortOptions[event.detail.field];
    this.currentSortDescending = !event.detail.ascending;

    this.postSitesGrid({
      page: 1,
      pageSize: this.currentPageSize,
      searchTerm: this.searchTerm.value,
      sortOptions: [{
        field: SitesSortOptions[event.detail.field],
        isDescending: this.currentSortDescending
      }]
    });
  }

  private getHeaders(): Array<TableHeader> {
    return [
      this.utilService.createTableHeader(this.translatePipe.transform('sites.piName'), 'nameLink', true),
      this.utilService.createTableHeader(this.translatePipe.transform('sites.sponsor'), 'sponsor', true),
      this.utilService.createTableHeader(this.translatePipe.transform('sites.sponsorId'), 'sponsorProtocolNumber', true),
      this.utilService.createTableHeader(this.translatePipe.transform('sites.irbTracking'), 'irbTrackingNumber', true),
      this.utilService.createTableHeader(this.translatePipe.transform('sites.institutionalTracking'), 'institutionalTrackingNumber', true),
      this.utilService.createTableHeader(this.translatePipe.transform('sites.status'), 'status', true)
    ];
  }

  private postSitesGrid(body): void {
    this.gridFilters = body;
    this.loading = true;

    this.apiService.postSitesGrid(body)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: SitesGrid) => {
        this.loaded = true;
        this.loading = false;
        this.totalRecords = data.totalRecords;
        this.tableData = this.sitesService.mapSitesGridData(data);
        this.currentPage = data.currentPage;
      }, () => {
        this.loading = false;

        this.toastService.add([{
          closable: true,
          id: 'postSitesFailure',
          message: this.translatePipe.transform('serverErrors.internalServerError'),
          timeout: this.toastService.ERROR_TIMEOUT,
          variant: 'error'
        }]);
      });
  }
}
