<wcg-modal [show]="showModal" (close)="cancelClick()">
  <wcg-modal-header>
    <h2 class="wcg-h3">
      {{ 'submissionWorkflow.submissionDetails' | translate }}
    </h2>
  </wcg-modal-header>

  <wcg-modal-body>
    <div class="wcg-h3 cnxs-submission-title">
      {{ submissionWorkflowService.submissionName.value || '' }}
    </div>
    <div class="cnxs-submission-details">
      <p>{{ 'submissionDetailsModal.submissionType' | translate }} <span
          class="cnxs-details-value">{{this.submissionWorkflowService.submissionTypeValue.value}}</span></p>
    </div>
    <div class="cnxs-submission-details">
      <p>{{ 'submissionDetailsModal.sponsorProtocolId' | translate }} <span
          class="cnxs-details-value">{{this.submissionWorkflowService.submissionSponsorProtocolId.value}}</span></p>
    </div>
    <div class="cnxs-submission-details">
      <p>{{ 'submissionDetailsModal.studyName' | translate }} <span
          class="cnxs-details-value">{{this.submissionWorkflowService.studyName.value}}</span></p>
    </div>
    <div class="cnxs-submission-details">
      <p>{{ 'submissionDetailsModal.sponsor' | translate }} <span
          class="cnxs-details-value">{{this.submissionWorkflowService.sponsor.value}}</span></p>
    </div>
    <div *ngIf="showPiInfo">
      <div *ngIf="showPiTotalInfo">
        <div class="cnxs-submission-pi">
          <p>{{ 'submissionDetailsModal.principalInvestigator' | translate }}
            <span class="cnxs-details-value">
              {{ 'submissionDetailsModal.sitesSelected' | translate: { selected: this.submissionWorkflowService.submissionStudySites.value?.length, 
                totalSites: this.submissionWorkflowService.submissionStudySitesTotal.value === 0 ? '' : this.submissionWorkflowService.submissionStudySitesTotal.value} }}</span>
          </p>
        </div>
      </div>
      <wcg-accordion [open]="showPIList" (toggle)="showPIList = !showPIList">
        <wcg-accordion-trigger>
          <h4 class="wcg-h4">{{ 'submissionDetailsModal.showPrincipalInvestigators' | translate}}</h4>
        </wcg-accordion-trigger>
        <wcg-accordion-content>
          <wcg-table [headers]="headers" [data]="piList"> </wcg-table>
        </wcg-accordion-content>
      </wcg-accordion>
    </div>
  </wcg-modal-body>

  <wcg-modal-footer>
    <div class="cnxs-secondary-actions">
    </div>
    <button class="wcg-btn wcg-btn-primary wcg-btn-auto" type="button" (click)="cancelClick()">
      {{'submissionWorkflow.close' | translate }}
    </button>
  </wcg-modal-footer>
</wcg-modal>