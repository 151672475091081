/* eslint-disable max-lines */
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DEBOUNCE_TIME, PermissionType, PermissionTypeViewValues } from 'src/app/common/collections';
import { ToastService } from 'src/app/services/toast.service';
import * as messages from 'src/app/common/messages';
import { ManageContactsService } from 'src/app/views/manage-contacts/manage-contacts.service';

@Component({
  selector: 'app-manage-user-contacts',
  templateUrl: './manage-user-contacts.component.html',
  styleUrls: ['./manage-user-contacts.component.scss']
})

export class ManageUserContactsComponent extends BaseComponent implements OnInit {
  allUsers: any = [];
  originalUsersList: any = [];
  workspaceList: any = [];

  chosenWorkspace: any;
  hasError = false;
  showWorkspacesList = false;
  showSites = false;
  isRemoveEnabled = false;
  showActionButton = false;

  isSelectedWorkspaceTypeEmpty = false;
  isIrbTrackingNumberEmpty = false;
  isSearchTermEmpty = false;
  showIrbTrackingNumberField = false;

  workspaceOptions = [
    {
      value: 'study',
      viewValue: 'Study'
    },
    {
      value: 'studysite',
      viewValue: 'Site'
    }
  ];

  permissionOptions = [
    {
      value: '1',
      viewValue: PermissionTypeViewValues[PermissionType[1]]
    },
    {
      value: '2',
      viewValue: PermissionTypeViewValues[PermissionType[2]]
    },
    {
      value: '3',
      viewValue: PermissionTypeViewValues[PermissionType[3]]
    },
    {
      value: '4',
      viewValue: PermissionTypeViewValues[PermissionType[4]]
    }
  ];

  workspaceFormGroup = new FormGroup({
    searchTerm: new FormControl('', Validators.required),
    irbTrackingNumber: new FormControl(''),
    selectedWorkspaceType: new FormControl('')
  });

  inviteForm = new FormGroup({
    selectedUsers: new FormControl({
      value: '',
      selectedValues: []
    }, [
      (control): any => {
        if (control.value.selectedValues.length > 0) {
          return;
        }

        return {
          validateSelectedUsers: {
            valid: false
          }
        };
      }
    ]),
    selectedPermissionType: new FormControl('', Validators.required),
    selectedWorkspaces: new FormControl({ selectedValues: [] }, [
      (control): any => {
        if (control.value.selectedValues.length > 0) {
          return;
        }

        return {
          required: true
        };
      }
    ])
  });

  constructor(
    private apiService: ApiService,
    private managePermissionsService: ManageContactsService,
    private toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this.inviteForm.controls.selectedWorkspaces.valueChanges
      .subscribe(
        (val) => {
          this.validateInviteForm(val.selectedValues, this.inviteForm.controls.selectedPermissionType.value);

          this.validateRemoveButton(val.selectedValues, this.inviteForm.controls.selectedUsers.value.selectedValues);
        }
      );

    this.inviteForm.controls.selectedPermissionType.valueChanges
      .subscribe(
        (val) => {
          this.validateInviteForm(this.inviteForm.controls.selectedWorkspaces.value.selectedValues, val);
        }
      );

    this.inviteForm.controls.selectedUsers.valueChanges.pipe(
      debounceTime(DEBOUNCE_TIME)
    ).subscribe((val) => {
      const isMultiple = val?.value?.includes(',') || val?.value?.includes(';');

      if (isMultiple) {
        this.setMultipleUsers(val);
      }

      val.selectedValues = val.selectedValues.map((user) => {
        if (!Object.prototype.hasOwnProperty.call(user, 'email')) {
          return {
            ...user,
            email: user.value
          };
        }

        return user;
      });

      this.validateEmail(val);
      this.validateRemoveButton(this.inviteForm.controls.selectedWorkspaces.value.selectedValues, val.selectedValues);

      if (this.hasError) {
        this.inviteForm.controls.selectedUsers.setValue(val);
        this.hasError = false;
      }

      if (val?.value?.length > 1 && !isMultiple) {
        this.getUsers(val.value);
      }

      this.manageUsersList();
    });
  }

  public removeWorkspace(workspaceExternalReferenceId: any): void {
    this.inviteForm.controls.selectedWorkspaces.setValue({
      selectedValues:
        this.inviteForm.controls.selectedWorkspaces.value.selectedValues
          .filter((workspace) => workspace.workspaceExternalReferenceId !== workspaceExternalReferenceId)
    });
  }

  public validateInviteForm(workspaces: any[], permission: any): void {
    if (permission === '4' && workspaces.some((workspace) => workspace.sitePiName !== null)) {
      this.inviteForm.controls.selectedPermissionType.setErrors({ onlyForStudy: true });
    }
    else {
      this.inviteForm.controls.selectedPermissionType.updateValueAndValidity({ emitEvent: false });
    }
  }

  public validateRemoveButton(workspaces: any[], users: any[]): void {
    this.isRemoveEnabled = workspaces.length > 0 && users.length > 0;
  }

  public inviteContacts(): void {
    const workspaces = this.inviteForm.controls.selectedWorkspaces.value.selectedValues
      .map((workspace) => workspace.workspaceExternalReferenceId);
    const userEmailsToAdd = this.inviteForm.controls.selectedUsers.value.selectedValues
      .filter((user) => user.isActive)
      .map((user) => user.email);
    const uniqueUserEmails = [...new Set(userEmailsToAdd)];
    const permission = this.inviteForm.controls.selectedPermissionType.value;

    if (this.inviteForm.valid) {
      this.toastService.add([messages.InviteInProgressMessage]);

      this.apiService
        .postBulkInviteWorkspaceUsers(workspaces, uniqueUserEmails, permission)
        .pipe(
          finalize(() => {
            this.toastService.remove(messages.InviteInProgressMessage);
          })
        ).subscribe((data) => {
          this.toastService.add([messages.InvitedSuccessMessage]);
          if (data !== null) {
            Object.keys(data)
              .forEach((key) => {
                this.toastService.add([messages.AlreadyInvitedMessage(key.toUpperCase(), data[key])]);
              });
          }
        },
        () => {
          this.toastService.add([messages.InvitedFailedMessage]);
        });
    }
  }

  public updateContacts(): void {
    const workspaces = this.inviteForm.controls.selectedWorkspaces.value.selectedValues
      .map((workspace) => workspace.workspaceExternalReferenceId);
    const userEmailsToAdd = this.inviteForm.controls.selectedUsers.value.selectedValues
      .filter((user) => user.isActive)
      .map((user) => user.email);
    const uniqueUserEmails = [...new Set(userEmailsToAdd)];
    const permission = this.inviteForm.controls.selectedPermissionType.value;

    if (this.inviteForm.valid) {
      this.toastService.add([messages.UpdateInProgressMessage]);
      this.apiService.bulkUpdateWorkspaceUsers(
        workspaces,
        uniqueUserEmails,
        permission
      ).pipe(
        finalize(() => {
          this.toastService.remove(messages.UpdateInProgressMessage);
        })
      ).subscribe((data) => {
        this.toastService.add([messages.UpdateSuccessMessage]);
        if (Object.keys(data.teamUsers).length > 0) {
          Object.keys(data.teamUsers)
            .forEach((key) => {
              this.toastService.add([messages.TeamMembersNotUpdated(key.toUpperCase(), data.teamUsers[key])]);
            });
        }
        if (Object.keys(data.notFoundUsers).length > 0) {
          Object.keys(data.notFoundUsers)
            .forEach((key) => {
              this.toastService.add([messages.UsersNotFoundMessage(key.toUpperCase(), data.notFoundUsers[key])]);
            });
        }
      },
      () => {
        this.toastService.add([messages.UpdateFailedMessage]);
      });
    }
  }

  public removeContacts(): void {
    this.toastService.add([messages.RemoveInProgressMessage]);
    const workspaces = this.inviteForm.controls.selectedWorkspaces.value.selectedValues
      .map((workspace) => workspace.workspaceExternalReferenceId);
    const userEmailsToAdd = this.inviteForm.controls.selectedUsers.value.selectedValues
      .filter((user) => user.isActive)
      .map((user) => user.email);
    const uniqueUserEmails = [...new Set(userEmailsToAdd)];

    if (this.isRemoveEnabled) {
      this.apiService.postBulkRestrictWorkspaceUsers(
        workspaces,
        uniqueUserEmails
      ).pipe(
        finalize(() => {
          this.toastService.remove(messages.RemoveInProgressMessage);
        })
      ).subscribe((data) => {
        this.toastService.add([messages.RemoveSuccessMessage]);
        if (Object.keys(data.demotedUsers).length > 0) {
          Object.keys(data.demotedUsers)
            .forEach((key) => {
              this.toastService.add([messages.UsersDemoted(key.toUpperCase(), data.demotedUsers[key])]);
            });
        }
        if (Object.keys(data.teamUsers).length > 0) {
          Object.keys(data.teamUsers)
            .forEach((key) => {
              this.toastService.add([messages.TeamMembersNotRemoved(key.toUpperCase(), data.teamUsers[key])]);
            });
        }
        if (Object.keys(data.notFoundUsers).length > 0) {
          Object.keys(data.notFoundUsers)
            .forEach((key) => {
              this.toastService.add([messages.UsersNotFoundMessage(key.toUpperCase(), data.notFoundUsers[key])]);
            });
        }
      },
      () => {
        this.toastService.add([messages.RemoveFailedMessage]);
      });
    }
  }

  public getWorkspace(): void {
    const searchTerm = this.workspaceFormGroup.controls.searchTerm.value;
    const irbTrackingNumber = this.workspaceFormGroup.controls.irbTrackingNumber.value ?? '';
    const selectedWorkspaceType = this.workspaceFormGroup.controls.selectedWorkspaceType.value;

    if (this.workspaceFormGroup.valid) {
      this.apiService.getWorkspaceSearch(selectedWorkspaceType, searchTerm, irbTrackingNumber)
        .subscribe((data) => {
          if (data.studyName === '[AmbiguousResults]') {
            this.getWorkspaceList();
          }
          else if (data.workspaceId === null || data.studyName === '[EntityNotFound]') {
            if (!this.showWorkspacesList) {
              this.toastService.add([messages.WorspaceNotFound]);
            }
          }
          else {
            const alreadyAdded = this.inviteForm.controls.selectedWorkspaces.value.selectedValues
              .some((item) => item.workspaceExternalReferenceId === data.workspaceExternalReferenceId);
            if (alreadyAdded) {
              this.toastService.add([messages.WorkspaceAlreadyAdded]);
            }
            else {
              this.inviteForm.controls.selectedWorkspaces.setValue({
                selectedValues: [...this.inviteForm.controls.selectedWorkspaces.value.selectedValues, data]
              });
              this.resetWorkspaceFormGroup();
            }
          }
        });
    }
  }

  onWorkspaceTypeChanged(event: any): void {
    this.resetWorkspaceFormGroup();
    if (event.target.value === 'studysite') {
      this.workspaceFormGroup.controls.irbTrackingNumber.setValidators(Validators.required);
      this.showIrbTrackingNumberField = true;
    }
    else {
      this.workspaceFormGroup.controls.irbTrackingNumber.clearValidators();
      this.showIrbTrackingNumberField = false;
    }
    this.workspaceFormGroup.controls.irbTrackingNumber.updateValueAndValidity();
  }

  public getSelectedWorkspace(): void {
    this.apiService.getWorkspaceByExternalReferenceId(this.chosenWorkspace)
      .subscribe((data) => {
        const alreadyAdded = this.inviteForm.controls.selectedWorkspaces.value.selectedValues
          .some((item) => item.workspaceExternalReferenceId === data.workspaceExternalReferenceId);
        if (alreadyAdded) {
          this.toastService.add([messages.WorkspaceAlreadyAdded]);
        }
        else {
          this.inviteForm.controls.selectedWorkspaces.setValue({
            selectedValues: [...this.inviteForm.controls.selectedWorkspaces.value.selectedValues, data]
          });
          this.resetWorkspaceListModal();
          this.resetWorkspaceFormGroup();
        }
      });
  }

  public changeRadio(event: any): void {
    this.chosenWorkspace = event.target.value;
  }

  public resetWorkspaceListModal(): void {
    this.chosenWorkspace = null;
    this.workspaceList = null;
    this.showWorkspacesList = false;
  }

  private getWorkspaceList(): void {
    const selectedWorkspaceType = this.workspaceFormGroup.controls.selectedWorkspaceType.value;
    const workspaces = this.inviteForm.controls.selectedWorkspaces.value.selectedValues
      .map((workspace) => workspace.workspaceExternalReferenceId);
    if (selectedWorkspaceType === 'studysite') {
      this.showSites = true;
      const piName = this.workspaceFormGroup.controls.searchTerm.value.trim();
      const irbTrackingNumber = this.workspaceFormGroup.controls.irbTrackingNumber.value.trim();
      this.apiService.getSitesList({
        piName: piName,
        irbTrackingNumber: irbTrackingNumber
      }).subscribe(
        (data) => {
          this.workspaceList = data
            .filter((workspace) => !workspaces.some((element) => element === workspace.externalReferenceId));

          if (this.workspaceList.length === 0) {
            this.resetWorkspaceListModal();
            this.toastService.add([messages.WorkspaceAlreadyAdded]);
          }
          else {
            this.showWorkspacesList = true;
          }
        }
      );
    }
    else {
      this.showSites = false;
      const searchTerm = this.workspaceFormGroup.controls.searchTerm.value;
      this.apiService.getStudyList(searchTerm).subscribe((data) => {
        this.workspaceList = data
          .filter((workspace) => !workspaces.some((element) => element === workspace.workspaceExternalReferenceId));

        if (this.workspaceList.length === 0) {
          this.resetWorkspaceListModal();
          this.toastService.add([messages.WorkspaceAlreadyAdded]);
        }
        else {
          this.showWorkspacesList = true;
        }
      });
    }
  }

  private resetWorkspaceFormGroup(): void {
    this.workspaceFormGroup.controls.searchTerm.reset();
    this.workspaceFormGroup.controls.irbTrackingNumber.reset();
    this.chosenWorkspace = null;
  }

  private setMultipleUsers(val): void {
    this.inviteForm.controls.selectedUsers.setValue({
      value: '',
      selectedValues: [
        ...this.inviteForm.controls.selectedUsers.value.selectedValues,
        ...val.value.split(/,|;/).filter(Boolean).map((email) => ({
          option: email.trim(),
          value: email.trim()
        }))
      ]
    });
  }

  private getUsers(filter): void {
    this.apiService.getUsers(filter)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.originalUsersList = this.managePermissionsService.mapUsers(data);
        this.manageUsersList();
      });
  }

  private manageUsersList(): void {
    this.allUsers = this.originalUsersList.filter((user) => !this.inviteForm.controls.selectedUsers.value.selectedValues
      .find((selectedUser) => selectedUser.email === user.email));
  }

  private validateEmail(value): void {
    if (value?.selectedValues?.length) {
      value.selectedValues.forEach((user) => {
        if (!user.error && !this.emailIsValid(user.email)) {
          user.error = true;
          this.hasError = true;
        }

        if (!user.error && !Object.prototype.hasOwnProperty.call(user, 'isActive')) {
          this.validateUserToRequest(value, user);
        }
      });

      let errors = null;
      if (value.selectedValues.some((user) => user.error && !Object.prototype.hasOwnProperty.call(user, 'isActive'))) {
        errors = { invalidEmails: true };
      }
      if (value.selectedValues.some((user) => !user.isActive && Object.prototype.hasOwnProperty.call(user, 'isActive'))) {
        errors = {
          ...errors,
          incativeUsers: true
        };
      }
      this.inviteForm.controls.selectedUsers.setErrors(errors);

      if (errors === null) {
        this.showActionButton = true;
      }
      else {
        this.showActionButton = false;
      }
    }
  }

  private validateUserToRequest(val, user): void {
    this.apiService.getUserStatus(user.email)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((isActive) => {
        user.isActive = isActive;
        if (!isActive) {
          user.error = true;
          this.inviteForm.controls.selectedUsers.setValue(val);
        }
      });
  }

  private emailIsValid = (email): boolean => {
    // eslint-disable-next-line prefer-named-capture-group, max-len
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(String(email).toLowerCase());
  }
}
