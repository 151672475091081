<app-workflow-container
  [hasIntro]="true"
  [header]="'submissionLanding.headerTitle' | translate"
  hasFooter="true"
>
  <close-button>
    <a class="wcg-btn wcg-btn-icon" routerLink="/dashboard">
      <wcg-icon src="../../assets/icons/navigation/ic_close_24px.svg"></wcg-icon>
      <span class="wcg-visually-hidden">{{ 'submissionWorkflow.close' | translate }}</span>
    </a>
  </close-button>

  <intro-content>
    <app-workflow-intro
      heading={{workflowIntro}}
      details={{workflowDetails}}>
      <button class="wcg-btn wcg-btn-primary wcg-btn-auto" (click)="this.inviteContacts()" *ngIf="this.submissionWorkflowService.submissionFlow.value !== 'addDocuments'">
        {{ 'submissionSuccess.buttonText' | translate }}
      </button>
    </app-workflow-intro>
  </intro-content>

  <main-content>
    <div class="wcg-row wcg-content-wrapper">
      <app-status-tracker
        [statuses]="statuses"
        class="success-status-tracker wcg-rhythm-8"></app-status-tracker>
    </div>
    <div class="cnxs-submission-info">
      <p class="wcg-h3">{{ submissionHeading }}</p>

      <div class="wcg-row">
        <div class="wcg-col-xs-4 wcg-col-md-4 wcg-col-lg-5 wcg-col-xl-4">
          <app-submission-info [submission]="submission" [card]="true">
            <div meta>
              <p class="cnxs-submission-meta-content">
                <span class="wcg-body-label cnxs-submission-meta-title">{{'submissionMeta.studyName' | translate}}:</span>
                <span class="wcg-body-sm cnxs-submission-meta-value">{{ studyName }}</span>
              </p>

              <p class="cnxs-submission-meta-content">
                <span class="wcg-body-label cnxs-submission-meta-title">{{'submissionMeta.sponsor' | translate}}:</span>
                <span class="wcg-body-sm cnxs-submission-meta-value">{{ studySponsor }}</span>
              </p>
            </div>

            <button actions
              class="wcg-btn wcg-btn-fluid wcg-btn-primary"
              [disabled]="submissionWorkflowService.separatePdfsDisabled"
              (click)="formioPDFService.downloadSeparatedPdfs()">
              {{ 'submissionActions.export' | translate }}
            </button>
          </app-submission-info>
        </div>

        <div class="wcg-col-xs-4 wcg-col-md-4 wcg-col-lg-5 wcg-col-xl-4">
          <app-estimated-outcome-card
            [estimatedOutcomeDate]="estimatedOutcomeDate">
          </app-estimated-outcome-card>
        </div>
      </div>
    </div>

    <div class="cnxs-hidden-form wcg-visually-hidden">
      <formio
        [src]="formSRCPath"
        (ready)="setFormIOForm($event)"></formio>
    </div>
  </main-content>

  <footer-content>
    <div class="cnxs-form-footer-content">
      <button (click)="this.inviteContacts()" class="wcg-link cnxs-arrow-btn cnxs-next">
        {{ 'submissionSuccess.buttonText' | translate }}
        <wcg-icon class="cnxs-arrow cnxs-next-arrow" src="../assets/icons/navigation/ic_arrow_forward_24px.svg"></wcg-icon>
      </button>
    </div>
  </footer-content>
</app-workflow-container>
