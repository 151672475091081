<div class="wcg-rhythm-5 pl-140">

  <div class="wrapper">
    <div class="teams-icon">
      <wcg-icon src="../assets/icons/social/ic_people_24px.svg"></wcg-icon>
    </div>
    <h1 class="wcg-h1"><span class="text">Teams</span></h1>
  </div>
  <div class="wcg-row">
    <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2 add-btn">
      <button class="wcg-btn wcg-btn-icon" (click)="addTeam()">
        <wcg-icon src="./assets/icons/content/ic_add_circle_outline_24px.svg"></wcg-icon>
        <span>{{ 'teams.createModalTitle' | translate }}</span>
      </button>
    </div>
    <form [formGroup]="filterTeamForm" class="wcg-col-xs-10 wcg-col-md-10 wcg-col-lg-10" autocomplete="off">
      <div class="wcg-row">
        <div class="wcg-col-xs-1 wcg-col-md-1 wcg-col-lg-1">

        </div>
        <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
          <wcg-form-field class="filter-form-field">
            <wcg-label>Team Type</wcg-label>
            <wcg-select formControlName="teamTypeFilter" (change)="teamTypeFilterChange($event)">
              <option value="All" selected>All Team Type</option>
              <option *ngFor="let type of teamOrganizationTypes" value={{type.value}}>{{type.value}}</option>
            </wcg-select>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
          <wcg-form-field class="filter-form-field">
            <wcg-label>Status</wcg-label>
            <wcg-select [formControl]="teamStatus" (change)="statusChange($event)">
              <option value="All" selected>All status</option>
              <option *ngFor="let status of teamStatusFilter" value={{status.value}}>{{status.text}}</option>
            </wcg-select>
          </wcg-form-field>
        </div>
        <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
          <ng2-flatpickr class="wcg-input-with-icon" #startDatePicker placeholder="Start Date"
            [config]="datePickerOptions" [formControl]="startDateFilter">
            <wcg-icon class="date-icon" (click)="toggleStartDate()" src="./assets/icons/action/ic_date_range_24px.svg">
            </wcg-icon>
          </ng2-flatpickr>
        </div>

        <div class="wcg-col-xs-2 wcg-col-md-2 wcg-col-lg-2">
          <ng2-flatpickr class="wcg-input-with-icon" #endDatePicker placeholder="End Date" [config]="datePickerOptions"
            [formControl]="endDateFilter">
            <wcg-icon class="date-icon" (click)="toggleEndDate()" src="./assets/icons/action/ic_date_range_24px.svg">
            </wcg-icon>
          </ng2-flatpickr>
        </div>
        <div class="wcg-col-xs-3 wcg-col-md-3 wcg-col-lg-3">
          <wcg-form-field class="search-form">
            <wcg-label>{{ 'actions.search' | translate }}</wcg-label>
            <wcg-input [formControl]="searchTerm">
              <wcg-icon src="../assets/icons/action/ic_search_24px.svg"></wcg-icon>
            </wcg-input>
          </wcg-form-field>
        </div>

      </div>
    </form>
  </div>

  <wcg-table class="wcg-rhythm-4" [ngClass]="{'cnxs-table-loading': loading}" [headers]="headers" [data]="tableData"
    [options]="options" (sortClicked)="handleSort($event)">

    <div class="cnxs-table-loader" slot="table-body">
      <wcg-loader [show]="!loaded || loading"></wcg-loader>
    </div>

    <div class="cnxs-pagination-wrap">
      <wcg-pagination *ngIf="loaded && tableData?.length" [current]="currentPage" [pageSize]="currentPageSize"
        [max]="utilService.maxPages(totalRecords, currentPageSize)" (changePage)="handlePageChange($event)"></wcg-pagination>
    </div>
  </wcg-table>

  <wcg-modal class="create-team-modal" id="modal" [show]="showAddTeamModal" (close)="closeClick()">
    <wcg-modal-header>
      <span class="verticalTop">{{ 'teams.createModalTitle' | translate }}</span>
    </wcg-modal-header>
    <form #modalForm="ngForm" class="modal-row" name="createTeamForm"
      [formGroup]="createTeamForm">
      <wcg-toast variant="error" position="top" *ngIf="saveTeamsFailure">{{ saveTeamsFailureMessage }}</wcg-toast>
      <wcg-modal-body>
        <div class="wcg-row">
          <div class=" wcg-col-xs-4 wcg-rhythm-3">
            <span class="wcg-h3">
              {{ 'teams.teamInfo' | translate }}
            </span>
          </div>
        </div>
        <div class="wcg-row">
          <div class="wcg-col-xs-1">
            <wcg-form-field>
              <wcg-label>{{ 'teams.teamType' | translate }}</wcg-label>
              <wcg-select [formControl]="createTeamForm.controls.teamType" (change)="handleTeamTypeChange($event)">
                <option hidden value="-1"></option>
                <option *ngFor="let teamType of teamOrganizationTypes"
                  [selected]="teamType.id == createTeamForm.value.teamType" [value]="teamType.id">
                  {{teamType.value}}</option>
              </wcg-select>
              <div *ngIf="modalForm.submitted ||
              (createTeamForm.controls.teamType.dirty || createTeamForm.controls.teamType.touched)">
                <wcg-error-message *ngIf="createTeamForm.controls.teamType.hasError('min')">
                  {{ 'teams.teamType' | translate }} is required
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-3">
            <wcg-form-field>
              <wcg-label>{{ 'teams.teamName' | translate }}</wcg-label>
              <wcg-input type="text" formControlName="teamName" placeholder="Team Name" required></wcg-input>
              <div *ngIf="(modalForm.submitted) ||
             (createTeamForm.controls.teamName.dirty || createTeamForm.controls.teamName.touched)">
                <wcg-error-message *ngIf="createTeamForm.controls.teamName.hasError('required')
                 || createTeamForm.controls.teamName.hasError('whitespace')">
                 {{ 'validation.required' | translate: { fieldname: ('teams.teamName' | translate) } }}
                </wcg-error-message>
                <wcg-error-message *ngIf="createTeamForm.controls.teamName.hasError('pattern')">
                  {{ 'validation.invalidSpecialCharacters' | translate: { fieldname: ('teams.teamName' | translate) } }}
                </wcg-error-message>
                <wcg-error-message *ngIf="createTeamForm.controls.teamName.hasError('maxlength')">
                  {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamName' | translate), charLength: 255 } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
        </div>
        <div class="wcg-row">
          <div class="wcg-col-xs-2">
            <wcg-form-field>
              <wcg-label>{{ 'teams.teamEmail' | translate }}</wcg-label>
              <wcg-input type="text" formControlName="teamEmail" placeholder="Team Email" required></wcg-input>
              <div class="email-info">
                <wcg-icon src="./assets/icons/alert/ic_error_24px.svg"></wcg-icon>
                <p>{{ 'teams.emailInfo' | translate }}</p>
              </div>
              <div *ngIf="( modalForm.submitted) ||
            ( createTeamForm.controls.teamEmail.dirty || createTeamForm.controls.teamEmail.touched)">
                <wcg-error-message *ngIf="createTeamForm.controls.teamEmail.hasError('required')
                || createTeamForm.controls.teamEmail.hasError('whitespace')">
                {{ 'validation.required' | translate: { fieldname: ('teams.teamEmail' | translate) } }}
                </wcg-error-message>
                <wcg-error-message *ngIf="createTeamForm.controls.teamEmail.hasError('pattern')">
                  {{ 'validation.email' | translate: { fieldname: ('teams.teamEmail' | translate) } }}
                </wcg-error-message>
                <wcg-error-message *ngIf="createTeamForm.controls.teamEmail.hasError('maxlength')">
                  {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamEmail' | translate), charLength: 255 } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-1">
            <wcg-form-field>
              <wcg-label>{{ 'teams.companyId' | translate }} (optional)</wcg-label>
              <wcg-input type="text" formControlName="companyId"></wcg-input>
              <div *ngIf="(modalForm.submitted) ||
                (createTeamForm.controls.companyId.dirty || createTeamForm.controls.companyId.touched)">
                <wcg-error-message *ngIf="createTeamForm.controls.companyId.hasError('pattern')">
                  {{ 'validation.numeric' | translate: { fieldname: ('teams.companyId' | translate) } }}
                </wcg-error-message>
                <wcg-error-message *ngIf="createTeamForm.controls.companyId.hasError('max')">
                  {{ 'validation.maxNumber' | translate: { fieldname: ('teams.companyId' | translate) } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-1">
            <wcg-form-field *ngIf="countries">
              <wcg-label>{{ 'teams.teamCountry' | translate }}</wcg-label>
              <wcg-select formControlName="country" (change)="handleCountryChange($event)">
                <option hidden value="-1"></option>
                <option *ngFor="let country of countries" [selected]="country.id == createTeamForm.value.country"
                  [value]="country?.id" required>
                  {{ country?.name }}
                </option>
              </wcg-select>
              <div *ngIf="modalForm.submitted ||
              (createTeamForm.controls.country.dirty || createTeamForm.controls.country.touched)">
                <wcg-error-message *ngIf="createTeamForm.controls.country.hasError('min')">
                  {{ 'validation.required' | translate: { fieldname: ('teams.teamCountry' | translate) } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
        </div>
        <div class="wcg-row">
          <div class="wcg-col-xs-4 wcg-col-md-4">
            <wcg-form-field>
              <wcg-label>{{ 'teams.teamAddress1' | translate }}</wcg-label>
              <wcg-input formControlName="addressLine1" required></wcg-input>
              <div *ngIf="modalForm.submitted ||
              (createTeamForm.controls.addressLine1.dirty || createTeamForm.controls.addressLine1.touched)">
                <wcg-error-message *ngIf="createTeamForm.controls.addressLine1.hasError('required')
                || createTeamForm.controls.addressLine1.hasError('whitespace')">
                {{ 'validation.required' | translate: { fieldname: ('teams.teamAddress1' | translate) } }}
                </wcg-error-message>
                <wcg-error-message *ngIf="createTeamForm.controls.addressLine1.hasError('pattern')">
                  {{ 'validation.invalidSpecialCharacters' | translate: { fieldname: ('teams.teamAddress1' | translate) } }}
                </wcg-error-message>
                <wcg-error-message *ngIf="createTeamForm.controls.addressLine1.hasError('maxlength')">
                  {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamAddress1' | translate), charLength: 255 } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-4 wcg-col-md-4">
            <wcg-form-field>
              <wcg-label>{{ 'teams.teamAddress2' | translate }} (optional)</wcg-label>
              <wcg-input formControlName="addressLine2"></wcg-input>
              <div *ngIf="modalForm.submitted ||
           (createTeamForm.controls.addressLine2.dirty || createTeamForm.controls.addressLine2.touched)">
                <wcg-error-message *ngIf="createTeamForm.controls.addressLine2.hasError('pattern')">
                  {{ 'validation.invalidSpecialCharacters' | translate: { fieldname: ('teams.teamAddress2' | translate) } }}
                </wcg-error-message>
                <wcg-error-message *ngIf="createTeamForm.controls.addressLine2.hasError('maxlength')">
                  {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamAddress2' | translate), charLength: 255 } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
        </div>
        <div class="wcg-row">
          <div class="wcg-col-xs-4 wcg-col-md-3">
            <wcg-form-field>
              <wcg-label>{{ 'teams.teamCity' | translate }}</wcg-label>
              <wcg-input formControlName="city" required></wcg-input>
              <div *ngIf="modalForm.submitted ||
           (createTeamForm.controls.city.dirty || createTeamForm.controls.city.touched)">
                <wcg-error-message *ngIf="createTeamForm.controls.city.hasError('required')
                || createTeamForm.controls.city.hasError('whitespace')">
                {{ 'validation.required' | translate: { fieldname: ('teams.teamCity' | translate) } }}
                </wcg-error-message>
                <wcg-error-message *ngIf="createTeamForm.controls.city.hasError('pattern')">
                  {{ 'validation.invalidSpecialCharacters' | translate: { fieldname: ('teams.teamCity' | translate) } }}
                </wcg-error-message>
                <wcg-error-message *ngIf="createTeamForm.controls.city.hasError('maxlength')">
                  {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamCity' | translate), charLength: 75 } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-4 wcg-col-md-3">
            <wcg-form-field *ngIf="states?.length">
              <wcg-label>{{ stateLabel | translate }}</wcg-label>
              <wcg-select formControlName="state" (change)="stateChange($event)">
                <option value=""></option>
                <option *ngFor="let state of states" [selected]="state.id == createTeamForm.value.state"
                  [value]="state?.id" required>
                  {{ state?.name }}
                </option>
              </wcg-select>
              <div *ngIf="modalForm.submitted ||
              (createTeamForm.controls.state.dirty || createTeamForm.controls.state.touched)">
                <wcg-error-message *ngIf="createTeamForm.controls.state.invalid">
                  {{ 'validation.required' | translate: { fieldname: (stateLabel | translate) } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
            <wcg-form-field *ngIf="states?.length === 0">
              <wcg-label>{{ 'teams.teamStateProvince' | translate }}</wcg-label>
              <wcg-input formControlName="province" required></wcg-input>
              <div *ngIf="modalForm.submitted ||
           (createTeamForm.controls.province.dirty || createTeamForm.controls.province.touched)">
                <wcg-error-message *ngIf="createTeamForm.controls.province.hasError('required')
                || createTeamForm.controls.province.hasError('whitespace')">
                {{ 'validation.required' | translate: { fieldname: ('teams.teamStateProvince' | translate) } }}
                </wcg-error-message>
                <wcg-error-message *ngIf="createTeamForm.controls.province.hasError('pattern')">
                  {{ 'validation.invalidSpecialCharacters' | translate: { fieldname: ('teams.teamStateProvince' | translate) } }}
                </wcg-error-message>
                <wcg-error-message *ngIf="createTeamForm.controls.province.hasError('maxlength')">
                  {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamStateProvince' | translate), charLength: 150 } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
          <div class="wcg-col-xs-4 wcg-col-md-2">
            <wcg-form-field>
              <wcg-label>{{ 'teams.teamPostal' | translate }}</wcg-label>
              <wcg-input formControlName="postalCode" required></wcg-input>
              <div *ngIf="modalForm.submitted ||
           (createTeamForm.controls.postalCode.dirty || createTeamForm.controls.postalCode.touched)">
                <wcg-error-message *ngIf="createTeamForm.controls.postalCode.hasError('required')
                || createTeamForm.controls.postalCode.hasError('whitespace')">
                {{ 'validation.required' | translate: { fieldname: ('teams.teamPostal' | translate) } }}
                </wcg-error-message>
                <wcg-error-message *ngIf="createTeamForm.controls.postalCode.hasError('pattern')">
                  {{ 'validation.invalidSpecialCharacters' | translate: { fieldname: ('teams.teamPostal' | translate) } }}
                </wcg-error-message>
                <wcg-error-message *ngIf="createTeamForm.controls.postalCode.hasError('maxlength')">
                  {{ 'validation.maxChar' | translate: { fieldname: ('teams.teamPostal' | translate), charLength: 30 } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
        </div>
        <div class="modal-info-heading wcg-col-xs-4 wcg-rhythm-3">
          <span class="wcg-h3">
            {{ 'teams.teamMemberInfo' | translate }}
          </span>
        </div>
        <div class="wcg-row">
          <div class="wcg-col-xs-4">
            <wcg-form-field>
              <wcg-label>{{ 'teams.addMember' | translate }}</wcg-label>
              <wcg-autocomplete formControlName="selectedUsers" [multiple]="false" [options]="allUsers"
                (change)="updateUser($event)" required>
              </wcg-autocomplete>
              <div
                *ngIf="(createTeamForm.controls.selectedUsers.invalid && modalForm.submitted) ||
           (createTeamForm.controls.selectedUsers.invalid && (createTeamForm.controls.selectedUsers.dirty || createTeamForm.controls.selectedUsers.touched))">
                <wcg-error-message *ngIf="!createTeamForm.controls.selectedUsers.valid">
                  {{ 'validation.required' | translate: { fieldname: ('teams.teamMemberInfo' | translate) } }}
                </wcg-error-message>
              </div>
            </wcg-form-field>
          </div>
        </div>
      </wcg-modal-body>
      <wcg-modal-footer>
        <button type="button" class="wcg-btn wcg-btn-auto" (click)="closeClick()">{{ 'teams.cancel' | translate }}</button>
        <button class="wcg-btn wcg-btn-auto wcg-btn-primary" (click)="saveTeam()" [disabled]="modalForm.invalid || isSaving">{{ 'teams.createMember' | translate }}</button>
      </wcg-modal-footer>
    </form>
  </wcg-modal>
</div>