import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { SubmissionType } from 'src/app/interfaces/submissions';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { StateService } from 'src/app/services/state.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { SubmissionWorkflowService } from 'src/app/views/submission-workflow/submission-workflow.service';
import { ToastService } from 'src/app/services/toast.service';
import { ExampleDocuments, FlowTypeName } from 'src/app/common/collections';
@Component({
  selector: 'app-base-submission',
  templateUrl: './base-submission.component.html',
  styleUrls: ['./base-submission.component.scss']
})
export class BaseSubmissionComponent extends BaseComponent implements OnInit {
  public allDataFetched: boolean;
  appUrl: string;
  cancelMessage: string;
  docExamples: Array<string>;
  exampleDocuments: Array<string>;
  existingStudy: SubmissionType;
  existingSite: SubmissionType;
  newSite: SubmissionType;
  newStudy: SubmissionType;
  openExample: boolean;
  openOutcome: boolean;
  submissionDataLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    public router: Router,
    public stateService: StateService,
    public apiService: ApiService,
    public submissionWorkflowService: SubmissionWorkflowService,
    public toastService: ToastService
  ) {
    super();
    this.appUrl = environment.formio.appUrl;
  }

  ngOnInit(): void {
    this.submissionWorkflowService.resetSubmissionData();
    this.exampleDocuments = ExampleDocuments;
    this.submissionWorkflowService.submissionRegions.next([]);
    combineLatest([
      this.submissionWorkflowService.submissionTypeGroups,
      this.submissionWorkflowService.submissionReviewTypes,
      this.submissionWorkflowService.countries,
      this.submissionWorkflowService.canadaProvinces,
      this.submissionWorkflowService.unitedStates,
      this.stateService.estimatedOutcomeDate.pipe(map((date: string | null | undefined) => date ? [date] : []))
    ]).pipe(takeUntil(this.unsubscribe))
      .subscribe((value) => {
        this.allDataFetched = value.every((item) => Array.isArray(item) ? item.length : false);
        this.submissionDataLoaded.next(this.allDataFetched);
      });
    this.submissionWorkflowService.getContactCountries();
    this.getReviewTypes();
    this.getSubmissionTypeGroups();
    this.stateService.getEstimatedOutcomeDate();
    if (this.submissionWorkflowService.availableForms.value.length === 0) {
      this.submissionWorkflowService.getAllFormioFormsToDisplay();
    }
  }

  public onAddDocumentsSelect(): void {
    this.submissionWorkflowService.submissionFlow.next('addDocuments');
    this.submissionWorkflowService.submissionTypeGroup.next(this.existingStudy);
    this.routeToTypes();
  }

  public onAddPISelect(): void {
    this.submissionWorkflowService.submissionFlow.next('addPI');
    this.submissionWorkflowService.submissionTypeGroup.next(this.newSite);
    this.routeToTypes();
  }

  public onNewStudySelect(): void {
    this.submissionWorkflowService.submissionFlow.next('addStudy');
    this.submissionWorkflowService.submissionTypeGroup.next(this.newStudy);
    this.routeToTypes();
  }

  public routeToTypes(): void {
    this.router.navigate(['/submission-workflow/setup/types']);
  }

  private getReviewTypes(): void {
    this.apiService.getReviewTypes()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.submissionWorkflowService.submissionReviewTypes.next(res);
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'getReviewTypes',
          message: `Could not load Submission Review Types: ${err.details.error.message}`,
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }

  private getSubmissionTypeGroups(): void {
    this.apiService.getSubmissionTypeGroups()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.submissionWorkflowService.submissionTypeGroups.next(res);
        [this.newSite] = res.filter((object) => object.value === FlowTypeName.NewSite);
        [this.newStudy] = res.filter((object) => object.value === FlowTypeName.NewStudy);
        [this.existingStudy] = res.filter((object) => object.value === FlowTypeName.ExistingStudy);
        [this.existingSite] = res.filter((object) => object.value === FlowTypeName.ExistingSite);
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'getSubmissionTypeGroupsError',
          message: `Could not load Submission Type Groups: ${err.details.error.message}`,
          timeout: 5000,
          variant: 'error'
        }]);
      });
  }
}
