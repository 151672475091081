/* eslint-disable max-lines */
/* eslint max-lines-per-function: ["error", {"max": 65}] */
import { Component, OnInit } from '@angular/core';
import { Formio } from 'angular-formio';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

import { SubmissionFlowProtocolInfo, SubmissionInfo } from '../../../interfaces/submissions';
import { ApiService } from '../../../services/api.service';
import { BaseComponent } from '../../../components/base/base.component';
import { FormioPDFService } from '../../../services/formio-pdf.service';
import { StateService } from '../../../services/state.service';
import { SubmissionWorkflowService } from '../submission-workflow.service';
import { ToastService } from '../../../services/toast.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { UserService } from '../../../services/user.service';
import { UtilService } from '../../../services/util.service';
import {
  protocolInfo2,
  protocolInfoSite,
  piForm
} from '../../../common/formio-map';
import { environment } from '../../../../environments/environment';
import { BlobService } from 'angular-azure-blob-service';
import { SubmissionTypesGrid } from 'src/app/admin/shared/model/submission-type';

@Component({
  selector: 'app-submission-review',
  templateUrl: './submission-review.component.html',
  styleUrls: ['./submission-review.component.scss']
})

export class SubmissionReviewComponent extends BaseComponent implements OnInit {
  acknowledgements = [];
  estimatedOutcomeDate: string;
  formIncomplete = true;
  formIOForm: any;
  formSRCPath: string;
  investigator: string;
  protocolInfo: SubmissionFlowProtocolInfo;
  saveSubmissionFailure = false;
  saveSubmissionFailureMessage: string;
  showConfirmation = false;
  showSaveLoader = false;
  submission: SubmissionInfo;
  submissionHeading: string;
  submissionTypes: SubmissionTypesGrid;
  defaultDocumentTypeId: number;

  constructor(
    public formioPDFService: FormioPDFService,
    public router: Router,
    public submissionWorkflowService: SubmissionWorkflowService,
    public translatePipe: TranslatePipe,
    private apiService: ApiService,
    private blob: BlobService,
    private stateService: StateService,
    private toastService: ToastService,
    private userService: UserService,
    private utilService: UtilService
  ) {
    super();
  }

  ngOnInit(): void {
    const piFormData = this.submissionWorkflowService.formInfo.value[piForm.form]?.data;
    this.defaultDocumentTypeId = this.submissionWorkflowService.defaultDocumentTypeId.value;

    this.stateService.estimatedOutcomeDate.subscribe((data) => {
      this.estimatedOutcomeDate = data;
    });

    this.formSRCPath = `${environment.formio.appUrl}/${this.submissionWorkflowService.formName.value}?full=true`;

    this.submissionHeading = this.translatePipe.transform(
      `submissionTypes.${this.submissionWorkflowService.submissionFlow.value || 'addStudy'}CardTitle`
    );

    this.protocolInfo = this.submissionWorkflowService.formInfo.value[protocolInfo2.form] ?
      this.submissionWorkflowService.formInfo.value[protocolInfo2.form].data :
      this.getProtocolInfo();

    if (piFormData?.[piForm.fields.first] || piFormData?.[piForm.fields.last]) {
      this.investigator = `${piFormData?.[piForm.fields.first]} ${piFormData?.[piForm.fields.last]}`
        .trim();
    }

    this.getAcknowledgments();
    this.checkFormStatus();
    this.submissionWorkflowService.setDocumentStatus();

    this.submission = {
      sponsorId: this.protocolInfo.sponsorsProtocolId || this.submissionWorkflowService.submissionFindStudy.value?.protocol,
      status: this.translatePipe.transform('submissionStatus.draft'),
      submissionName: this.protocolInfo.submissionName ||
        this.submissionWorkflowService.formInfo.value[protocolInfoSite.form]?.data[protocolInfoSite.fields.submissionName],
      submissionType: this.submissionWorkflowService.submissionTypeValue?.value,
      studyId: this.submissionWorkflowService.submissionFindStudy.value?.id
    };
  }

  public confirmSubmission(): void {
    const submissionData = this.submissionWorkflowService.setSubmissionModel();
    const { id } = submissionData;
    this.submissionWorkflowService.saveSubmissionClicked.next(true);
    this.showSaveLoader = true;

    // Wait until all PDFs have been downloaded
    this.submissionWorkflowService.separatePdfs
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        // once the PDFs have been downloaded, get SAS upload to Azure
        if (!this.submissionWorkflowService.separatePdfsNotDownloaded()) {
          this.startPdfUpload();
        }
      });

    this.formioPDFService.generatePdfs(false);

    // Wait until PDF has been uploaded to Azure
    this.submissionWorkflowService.pdfFormDocuments
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((docs) => {
        // once PDF has been uploaded, add its data to the submission object and submit
        const allPdfsNotUploaded = this.submissionWorkflowService.pdfFormDocumentIndexes.value.find((value) => !docs[value]);

        if (!allPdfsNotUploaded) {
          Object.keys(docs).forEach((doc) => {
            if (!docs[doc].blobFileName.endsWith(docs[doc].fileName)) {
              docs[doc].blobFileName += `/${docs[doc].fileName}`;
            }

            if (!docs[doc].storageFilePath.endsWith(docs[doc].fileName)) {
              docs[doc].storageFilePath += `/${docs[doc].fileName}`;
            }

            submissionData.documents.push(docs[doc]);
          });

          this.apiService.postCreateSubmissionAsync(submissionData)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((responseData) => {
              this.submissionWorkflowService.successObject.next(responseData);
              this.showSaveLoader = false;
              this.toggleSubmitModal();
              setTimeout(() => this.router.navigate([`/submission-workflow/success/${id}`]), 0);
            }, (err) => {
              this.submissionWorkflowService.saveSubmissionClicked.next(false);
              this.showSaveLoader = false;
              this.saveSubmissionFailure = true;
              this.saveSubmissionFailureMessage = `Data could not be saved: ${err.details.error.message}`;
            });
        }
      });
  }

  public setFormIOForm(event): void {
    this.formIOForm = event.formio;
    this.formIOForm.nosubmit = true;
  }

  public toggleSubmitModal(): void {
    this.showConfirmation = !this.showConfirmation;
  }

  public uploadToAzure(sasToken: string, file: File, name: string): void {
    const filePrefix = this.translatePipe.transform('submissionPdf.pdfFilename');
    const fileSuffix = moment(new Date()).format(this.submissionWorkflowService.pdfFilenameDateFormat).toUpperCase();

    const { externalReferenceId } = this.userService.getUserInfo().source.value;
    const blobFileName = `${externalReferenceId}/${this.submissionWorkflowService.documentUploadSubmissionGuid.value}`;
    const storageFilePath = `draft/${blobFileName}`;
    const config = this.submissionWorkflowService.getAzureConfig(sasToken, storageFilePath);
    const fileName = `${filePrefix}_${name}_${fileSuffix}.pdf`;

    let type = null;
    if (name === 'Billing Information') {
      type = this.submissionWorkflowService.submissionDocumentTypes.value
        .find((docType: any) => docType.value === 'Billing Verification');
    }
    else if (this.defaultDocumentTypeId === null) {
      type = this.submissionWorkflowService.submissionDocumentTypes.value
        .find((docType: any) => docType.value === 'Submission Form');
    }
    else {
      type = this.submissionWorkflowService.submissionDocumentTypes.value
        .find((docType: any) => docType.id === this.defaultDocumentTypeId);
    }

    this.blob.upload({
      baseUrl: this.utilService.replaceAzureBlobBaseUrl(
        this.blob.generateBlobUrl(config, fileName)
      ),
      sasToken: config.sas,
      blockSize: this.submissionWorkflowService.azureBlobSize,
      file: file,
      complete: (): void => {
        const formObject = {};

        formObject[name] = {
          blobFileName,
          fileName,
          storageFilePath,
          type,
          isLinked: false
        };
        // update document metadata for submission
        this.submissionWorkflowService.pdfFormDocuments.next({
          ...this.submissionWorkflowService.pdfFormDocuments.value,
          ...formObject
        });
      },
      error: (err): void => {
        this.toastService.add([{
          closable: true,
          id: 'blobUpload',
          message: err.message,
          timeout: 3000,
          variant: 'error'
        }]);
      }
    });
  }

  private checkFormStatus(): void {
    const incompletePages = this.submissionWorkflowService.formPages.value.filter((page) => page.statusClass !== 'complete');
    const uploadStatus = this.submissionWorkflowService.submissionDocumentStatus.value.statusClass;

    if (incompletePages.length === 0 && uploadStatus === 'complete') {
      this.formIncomplete = false;
    }
  }

  private getAcknowledgments(): void {
    const formData = this.submissionWorkflowService.getAcknowledgementsData();
    this.apiService.getFormioForm('acknowledgementform')
      .subscribe((formResult) => {
        Formio.createForm(document.getElementById('formIoAcknowledgements'), formResult)
          .then((form) => {
            form.submission = {
              data: formData
            };
          });
      }, (err) => {
        this.toastService.add([{
          closable: true,
          id: 'setDocumentChecklist',
          message: err.details.message,
          timeout: 3000,
          variant: 'error'
        }]);
      });
  }


  private getProtocolInfo(): object {
    if (this.utilService.isEmptyObject(this.submissionWorkflowService.formInfo.value)) {
      return {
        sponsor: '',
        sponsorsProtocolId: '',
        studyName: '',
        submissionName: ''
      };
    }

    return {
      sponsor: this.submissionWorkflowService.formInfo.value.sponsor,
      sponsorsProtocolId: this.submissionWorkflowService.formInfo.value.sponsorsProtocolId,
      studyName: this.submissionWorkflowService.formInfo.value.studyName,
      submissionName: this.submissionWorkflowService.formInfo.value.submissionName
    };
  }

  private startPdfUpload(): void {
    this.apiService.getSasForDocumentUpload()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        Object.keys(this.submissionWorkflowService.separatePdfs.value).forEach((res) => {
          this.uploadToAzure(
            data,
            this.submissionWorkflowService.separatePdfs.value[res],
            res
          );
        });
      },
      (err) => {
        this.submissionWorkflowService.saveSubmissionClicked.next(false);
        this.showSaveLoader = false;
        this.saveSubmissionFailure = true;
        this.saveSubmissionFailureMessage = `Data could not be saved: ${err.details.error.message}`;
      });
  }
}
