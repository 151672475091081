import { Injectable } from '@angular/core';
import {
  Site,
  SiteMap,
  SiteContacts,
  SiteContactsMap,
  SiteSubmissions,
  SiteSubmissionsMap,
  SitePiMap,
  SitePi
} from '../../interfaces/sites';
import { UtilService } from '../../services/util.service';
import { PermissionType, PermissionTypeViewValues } from 'src/app/common/collections';
import { WorkspaceOutcomeGetMap, WorkspaceOutcomeGetRes } from '../../interfaces/workspaces';
import { Apis, BackOfficeBaseUrl } from '../../common/apis';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  public endDate = new BehaviorSubject<Date>(null);
  public startDate = new BehaviorSubject<Date>(null);
  public searchTerm = new BehaviorSubject<string>('');

  constructor(private utilService: UtilService) { }

  public mapContactsData(data: Array<SiteContacts>): Array<SiteContactsMap> {
    return data.map((record) => ({
      permissionType: this.utilService.addTableCellWrapperWithSearchTerms(PermissionTypeViewValues[PermissionType[record.permissionType]],
        this.searchTerm.value),
      contactName: this.utilService.addEmailToTableCellWrapperWithSearchTerms(
        record.contact.value, this.searchTerm.value, record.email
      ),
      email: this.utilService.addTableCellWrapperWithSearchTerms(record.email, this.searchTerm.value),
      organizationName: this.utilService.addTableCellWrapperWithSearchTerms(record.organizationName, this.searchTerm.value),
      organizationType: this.utilService.addTableCellWrapperWithSearchTerms(record.organizationType, this.searchTerm.value)
    }));
  }

  public mapOutcomeDocumentsData(data: Array<WorkspaceOutcomeGetRes>): Array<WorkspaceOutcomeGetMap> {
    return data.map((record) => {
      const link = `${BackOfficeBaseUrl}${Apis.OutcomeDocumentDownload}/${record.irbDocumentId}`;
      const term = this.utilService.highlightSearchTerm(record.fileName, this.searchTerm.value);

      return {
        documentId: record.documentId,
        link: link,
        fileNameLink: this.utilService.getTableCellWrapper(
          record.fileName,
          '',
          `<button class="wcg-link data-download-outcome-document-button" title="${record.fileName.replace(/"/g, '')}">${term}</button>`
        ),
        fileName: record.fileName,
        documentType: this.utilService.addTableCellWrapperWithSearchTerms(record.documentType, this.searchTerm.value),

        reviewedDate: record.transmittedDate === null && record.reviewedDate ? this.utilService.highlightDateFields(
          record.reviewedDate, this.startDate.value, this.endDate.value
        ) : this.utilService.addTableCellWrapper(this.utilService.formatDate(record.reviewedDate)),

        transmittedDate: record.transmittedDate ? this.utilService.highlightDateFields(
          record.transmittedDate, this.startDate.value, this.endDate.value
        ) : this.utilService.addTableCellWrapper(this.utilService.formatDate(record.transmittedDate))
      };
    });
  }

  public mapSiteData(data: Site): SiteMap {
    return {
      expirationDate: data.expirationDate ? this.utilService.formatDate(data.expirationDate) : null,
      initialApproval: data.initialApprovalDate ? this.utilService.formatDate(data.initialApprovalDate) : null,
      institutionalTrackingNumber: data.institutionalTrackingNumber,
      investigator: data.investigator,
      irbTrackingNumber: data.irbTrackingNumber,
      lastReview: data.lastReviewDate ? this.utilService.formatDate(data.lastReviewDate) : null,
      sponsor: data.sponsor,
      sponsorProtocolId: data.protocolNumber,
      status: data.status,
      studyExpiration: data.expirationDate ? this.utilService.formatDate(data.expirationDate) : null,
      studyId: data.study.id,
      studyName: data.study.value,
      closedDate: data.closedDate ? this.utilService.formatDate(data.closedDate) : null
    };
  }

  public mapSitePiData = (data: SitePi): SitePiMap => ({
    name: `${data.firstName} ${data.lastName}`,
    org: data.organization?.name,
    address: `${data.address?.country.name || ''}
      ${data.address?.addressLine1 || ''}
      ${data.address?.province || ''}
      ${data.address?.city || ''}
      ${data.address?.state.code || ''}
      ${data.address?.postalCode || ''}`,
    phone: data.phone,
    email: data.email
  });

  public mapSubmissionsData(data: Array<SiteSubmissions>): Array<SiteSubmissionsMap> {
    return data.map((record) => ({
      title: record.title,
      titleLink: this.utilService.addTableCellWrapperWithSearchTerms(
        record.title,
        this.searchTerm.value,
        `/submissions/${record.id}`
      ),
      type: this.utilService.addTableCellWrapperWithSearchTerms(record.type.value, this.searchTerm.value),

      reviewOutcome: this.utilService.addTableCellWrapperWithSearchTerms(record.reviewOutcome, this.searchTerm.value),

      status: record.statuses ? this.utilService.addTableCellWrapperWithSearchTerms(record.statuses[0].value, this.searchTerm.value) :
        this.utilService.addTableCellWrapper(null),

      submittedDate: record.submittedDate ? this.utilService.highlightDateFields(
        record.submittedDate, this.startDate.value, this.endDate.value
      ) : this.utilService.addTableCellWrapper(record.submittedDate)
    }));
  }
}
